const VariantHelper = (color, variant) => {
  let tmpColor = typeof color === "string" ? color : "primary";
  let tmpVariant = typeof variant === "string" ? variant : null;

  if (tmpColor.startsWith("outline-")) {
    tmpColor = tmpColor.substring(8);

    if (!tmpVariant) {
      tmpVariant = "inverted";
    }
  }

  if (Array.isArray(color) && color.length > 0) {
    tmpColor = color[0].codename;
  }

  if (Array.isArray(variant) && variant.length > 0) {
    tmpVariant = variant[0].codename;
  }

  return `${tmpVariant ? `${tmpVariant}-` : ""}${tmpColor}`;
};

export default VariantHelper;
