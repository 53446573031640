import IoBaseApiSlice from "./ioBaseApiSlice";

export const IoEventApiSlice = IoBaseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAllUrnCounter: build.query({
      query: () => ({
        apiRequirements: ["session"],
        url: `${process.env.GATSBY_IO_HOST}/evt/counter?query=urn:dkms:*:virtualdrive:DKMS_${process.env.GATSBY_SITE.toUpperCase()}:*`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
    getUrnCounter: build.query({
      query: (driveId) => ({
        apiRequirements: ["session"],
        url: `${process.env.GATSBY_IO_HOST}/evt/${driveId}/counter`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllUrnCounterQuery,
  useGetUrnCounterQuery,
} = IoEventApiSlice;
