import React from "react";

import intervalToDuration from "date-fns/intervalToDuration";
import isPast from "date-fns/isPast";
import isValid from "date-fns/isValid";
import startOfToday from "date-fns/startOfToday";
import parse from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import * as Yup from "yup";

import { dateFactory } from "../../../utils/dateHelper";

const MAX_LENGTH_ERROR = "form.error.max_length_exact";
const MIN_LENGTH_ERROR = "form.error.min_length_exact";
const GT_OR_EQ_ERROR = "form.error.gt_or_eq";
const LT_OR_EQ_ERROR = "form.error.lt_or_eq";
const REQUIRED_ERROR = "form.required";
const ONLY_ALPHABETIC_CHARACTERS_ERROR = "form.error.only_alphabetic_chars";

const INVALID_DONOR_ID_PATTERN = "form.error.donor_id_pattern";
const INVALID_SOCIAL_SECURITY_NO = "form.error.social_security_number";
const INVALID_PHONE_PATTERN = "form.error.phone_pattern";
const INVALID_RUT_RUN_PATTERN = "form.error.invalid_rut_run_pattern";
const INVALID_RUT_RUN = "form.error.invalid_rut_run";
const INVALID_COUNTRY_CODE_PATTERN = "form.error.invalid_country_code";
const INVALID_MOBILE_PATTERN = "form.error.mobile";
const INVALID_EMAIL_PATTERN = "registration.additional.error.email";
const INVALID_ZIPCODE = "form.error.zipcode";
const INVALID_VALUE = "form.error.invalid_value";
const INVALID_CHOOSE_GENDER = "form.error.choose_gender";
const INVALID_CHOOSE_WHATSAPP = "form.error.choose_whatsapp";
const INVALID_DATE_IN_PAST = "form.error.date_in_past";
const INVALID_DATE_IN_FUTURE = "form.error.date_in_future";
const INVALID_DATE_TO_FAR_IN_FUTURE = "form.error.date_to_far_in_future";

const NO_EDU_ENDING = "form.error.no_edu_domain";

const MAX_LENGTH_VALIDATOR = "max";
const MIN_LENGTH_VALIDATOR = "min";
const PATTERN_VALIDATOR = "matches";
const TEST_VALIDATOR = "test";
const EMAIL_VALIDATOR = "email";
const REQUIRED_VALIDATOR = "required";
const ONE_OF_VALIDATOR = "oneOf";
const WHEN_VALIDATOR = "when";

// const ONLY_ALPHABETIC_CHARACTERS_PATTERN = /^[^\d]+$/;
const ONLY_ALPHABETIC_CHARACTERS_PATTERN = /^[^\d\u0600-\u06FF]+$/;

const zip = Object.freeze({
  cl: [/^\d{7}$/, 7],
  de: [/^\d{5}$/, 5],
  gb: [/\s*([Gg][Ii][Rr] ?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})\s*/, 5],
  in: [/^\d{6}$/, 6],
  pl: [/^(\d{2}-\d{3})$/, 6],
  us: [/^\d{5}$/, 5],
});

const phone = Object.freeze({
  cl: [/^([\d+\s]{12}|[\d+\s]{3})$/, INVALID_PHONE_PATTERN],
  de: [/^[\d\s\-+/]{5,20}$/, INVALID_VALUE],
  gb: [/^[\d\s\-+/]{7,20}$/, INVALID_PHONE_PATTERN],
  in: [/^[\d\s+-]{10,15}$/, INVALID_PHONE_PATTERN],
  pl: [/^\d{9}$/, INVALID_PHONE_PATTERN],
  us: [/^(\d{3}-\d{3}-\d{4})$/, "form.error.invalid_phone_pattern"],
});

const mobile = Object.freeze({
  cl: [/^[\d+\s]{12}$/, INVALID_MOBILE_PATTERN],
  de: [/^[\d\s\-+/]{5,20}$/, INVALID_PHONE_PATTERN],
  gb: [/^[\d\s\-+/]{7,20}$/, INVALID_PHONE_PATTERN],
  in: [/^[\d]{10}$/, INVALID_MOBILE_PATTERN],
  pl: [/^[\d\s\-+/]{5,25}$/, INVALID_PHONE_PATTERN],
  us: [/^(\d{3}-\d{3}-\d{4})$/, INVALID_PHONE_PATTERN],
});

const donorId = Object.freeze({
  cl: [/^((DE|PL|GB|CL|IN|ZA)DKM)?[0-9]+$/i, INVALID_DONOR_ID_PATTERN],
  de: [/^(DEDKM)?[0-9]+$/i, INVALID_DONOR_ID_PATTERN],
  gb: [/^((DE|PL|GB|CL|IN|ZA)DKM)?[0-9]+$/i, INVALID_DONOR_ID_PATTERN],
  in: [/^(IN(DKM|BMST))?(1[0-9][A-Z])?[0-9]+$/i, INVALID_DONOR_ID_PATTERN],
  pl: [/^((DE|PL|GB|CL|IN|ZA)DKM)?[0-9]+$/i, INVALID_DONOR_ID_PATTERN],
  us: [/^[0-9]{1,9}$/i, INVALID_DONOR_ID_PATTERN],
});

let instance = null;

export default class Validators {
  intl = null;

  constructor(intl) {
    if (!instance) {
      instance = this;
    }

    this.intl = intl;

    return instance;
  }

  getZipcodePatern = () => zip[process.env.GATSBY_SITE];

  getPhonePattern = () => phone[process.env.GATSBY_SITE];

  getMobilePattern = () => mobile[process.env.GATSBY_SITE];

  getDonorIdPattern = () => donorId[process.env.GATSBY_SITE];

  createValidator = (validatorType, args) => ({
    params: [...args],
    type: validatorType,
  });

  getValidations = (key) => {
    switch (key) {
      /**
       * Person
       */
      case "gender":
        return [
          this.createValidator(
            REQUIRED_VALIDATOR,
            [this.intl.formatMessage({ id: INVALID_CHOOSE_GENDER })],
          ),
        ];
      case "firstname":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [40, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 40 })],
          ),
          this.createValidator(
            PATTERN_VALIDATOR,
            [
              ONLY_ALPHABETIC_CHARACTERS_PATTERN,
              this.intl.formatMessage({ id: ONLY_ALPHABETIC_CHARACTERS_ERROR }),
            ],
          ),
        ];
      case "lastname":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [100, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 100 })],
          ),
          this.createValidator(
            PATTERN_VALIDATOR,
            [
              ONLY_ALPHABETIC_CHARACTERS_PATTERN,
              this.intl.formatMessage({ id: ONLY_ALPHABETIC_CHARACTERS_ERROR }),
            ],
          ),
        ];
      case "donor_id": {
        const [pattern, errorMsg] = this.getDonorIdPattern();

        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [20, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 20 })],
          ),
          this.createValidator(
            MIN_LENGTH_VALIDATOR,
            [4, this.intl.formatMessage({ id: MIN_LENGTH_ERROR }, { value: 4 })],
          ),
          this.createValidator(
            PATTERN_VALIDATOR,
            [
              pattern,
              {
                message: (
                  <>
                    {parse(sanitizeHtml(
                      this.intl.formatMessage({ id: errorMsg }),
                      { allowedTags: ["a"] },
                    ))}
                  </>
                ),
              },
            ],
          ),
        ];
      }
      case "rut_run":
        return [
          this.createValidator(
            PATTERN_VALIDATOR, [
              /^([0-9]{1,3}(\.?[0-9]{3}){2})-?([\dkK])$/,
              this.intl.formatMessage({ id: INVALID_RUT_RUN_PATTERN })],
          ),
          this.createValidator(
            TEST_VALIDATOR,
            [
              "rut",
              this.intl.formatMessage({ id: INVALID_RUT_RUN }),
              (v) => {
                if (!v) return true;

                function checksumValid(T) {
                  let M = 0;
                  let S = 1;
                  let t = T;

                  for (; t; t = Math.floor(t / 10)) {
                    S = (S + (t % 10) * (9 - (M % 6))) % 11;
                    M += 1;
                  }

                  return S ? `${S - 1}` : "k";
                }

                const tmp = v.split("-");
                let digv = tmp[1];
                const rut = tmp[0];

                if (digv === "K") {
                  digv = "k";
                }

                return (checksumValid(rut) === digv);
              },
            ],
          ),
        ];
      /**
       * Address
       */
      case "info":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [100, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 100 })],
          ),
        ];
      case "apartment":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [100, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 100 })],
          ),
        ];
      case "building":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [100, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 100 })],
          ),
        ];
      case "co":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [100, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 100 })],
          ),
        ];
      case "street":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [50, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 50 })],
          ),
        ];
      case "houseno":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [10, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 10 })],
          ),
        ];
      case "zipcode":
        return [
          this.createValidator(
            PATTERN_VALIDATOR,
            [this.getZipcodePatern()[0], this.intl.formatMessage({ id: INVALID_ZIPCODE })],
          ),
        ];
      case "city":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [50, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 50 })],
          ),
        ];
      case "region":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [100, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 100 })],
          ),
        ];
      case "state":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [100, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { id: 100 })],
          ),
        ];
      case "country":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [100, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 100 })],
          ),
        ];
      case "country_code":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [2, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 2 })],
          ),
          this.createValidator(
            PATTERN_VALIDATOR,
            [/^[A-Z]{2}$/, this.intl.formatMessage({ id: INVALID_COUNTRY_CODE_PATTERN })],
          ),
        ];
      /**
       * Contact
       */
      case "phone": {
        const [pattern, errorMsg] = this.getPhonePattern();

        return [
          this.createValidator(
            PATTERN_VALIDATOR,
            [pattern, this.intl.formatMessage({ id: errorMsg })],
          ),
        ];
      }
      case "mobile": {
        const [pattern, errMsg] = this.getMobilePattern();

        return [
          this.createValidator(
            PATTERN_VALIDATOR,
            [pattern, this.intl.formatMessage({ id: errMsg })],
          ),
        ];
      }
      case "email":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [100, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 100 })],
          ),
          this.createValidator(
            EMAIL_VALIDATOR,
            [this.intl.formatMessage({ id: INVALID_EMAIL_PATTERN })],
          ),
        ];
      case "email_no_edu":
        return [
          this.createValidator(
            TEST_VALIDATOR,
            ["email", this.intl.formatMessage({ id: NO_EDU_ENDING }), (v) => !/@.*\.edu$/.test(v)],
          ),
        ];
      /**
       * General Practitioner
       */
      case "gp_name":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [100, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 100 })],
          ),
        ];
      case "gp_city":
        return [
          this.createValidator(
            MAX_LENGTH_VALIDATOR,
            [50, this.intl.formatMessage({ id: MAX_LENGTH_ERROR }, { value: 50 })],
          ),
        ];
      /**
       * requiredCheckbox
       */
      case "requiredCheckbox":
        return [
          this.createValidator(
            ONE_OF_VALIDATOR, [[true], this.intl.formatMessage({ id: REQUIRED_ERROR })],
          ),
        ];
      /**
       * Registration
       */
      case "weightImperialSt":
        return [
          this.createValidator(WHEN_VALIDATOR, [
            "bmiUnit", {
              is: (value) => value === "imperial",
              then: Yup.number()
                .required(this.intl.formatMessage({ id: REQUIRED_ERROR }))
                .min(0, this.intl.formatMessage({ id: GT_OR_EQ_ERROR }, { value: 0 }))
                .max(47, this.intl.formatMessage({ id: LT_OR_EQ_ERROR }, { value: 47 })),
            },
          ]),
        ];
      case "weightImperialLbs":
        return [
          this.createValidator(WHEN_VALIDATOR, ["bmiUnit", {
            is: (value) => value === "imperial",
            then: Yup.number()
              .required(this.intl.formatMessage({ id: REQUIRED_ERROR }))
              .min(0, this.intl.formatMessage({ id: GT_OR_EQ_ERROR }, { value: 0 }))
              .max(660, this.intl.formatMessage({ id: LT_OR_EQ_ERROR }, { value: 660 })),
          }]),
        ];
      case "heightImperialFeet":
        return [
          this.createValidator(WHEN_VALIDATOR, ["bmiUnit", {
            is: (value) => value === "imperial",
            then: Yup.number()
              .required(this.intl.formatMessage({ id: REQUIRED_ERROR }))
              .min(1, this.intl.formatMessage({ id: GT_OR_EQ_ERROR }, { value: 1 }))
              .max(8, this.intl.formatMessage({ id: LT_OR_EQ_ERROR }, { value: 8 })),
          }]),
        ];
      case "heightImperialInch":
        return [
          this.createValidator(WHEN_VALIDATOR, ["bmiUnit", {
            is: (value) => value === "imperial",
            then: Yup.number()
              .required(this.intl.formatMessage({ id: REQUIRED_ERROR }))
              .min(0, this.intl.formatMessage({ id: GT_OR_EQ_ERROR }, { value: 0 }))
              .max(12, this.intl.formatMessage({ id: LT_OR_EQ_ERROR }, { value: 12 })),
          }]),
        ];
      case "weightMetric":
        return [
          this.createValidator(WHEN_VALIDATOR, ["bmiUnit", {
            is: (value) => value === "metric",
            then: Yup.number()
              .required(this.intl.formatMessage({ id: REQUIRED_ERROR }))
              .min(0, this.intl.formatMessage({ id: GT_OR_EQ_ERROR }, { value: 0 }))
              .max(300, this.intl.formatMessage({ id: LT_OR_EQ_ERROR }, { value: 300 })),
          }]),
        ];
      case "heightMetric":
        return [
          this.createValidator(WHEN_VALIDATOR, ["bmiUnit", {
            is: (value) => value === "metric",
            then: Yup.number()
              .required(this.intl.formatMessage({ id: REQUIRED_ERROR }))
              .min(50, this.intl.formatMessage({ id: GT_OR_EQ_ERROR }, { value: 50 }))
              .max(250, this.intl.formatMessage({ id: LT_OR_EQ_ERROR }, { value: 250 })),
          }]),
        ];
      case "socialSecurityNumber":
        return [
          this.createValidator(
            PATTERN_VALIDATOR,
            [/^(\d{3}-\d{2}-\d{4}|\d{9})$/, this.intl.formatMessage({ id: INVALID_SOCIAL_SECURITY_NO })],
          ),
        ];
      case "whatsapp":
        return [
          this.createValidator(
            REQUIRED_VALIDATOR,
            [this.intl.formatMessage({ id: INVALID_CHOOSE_WHATSAPP })],
          ),
        ];
      case "dateInFuture":
        return [
          this.createValidator(
            TEST_VALIDATOR,
            ["dateInFuture", this.intl.formatMessage({ id: INVALID_DATE_IN_PAST }), (v) => isValid(new Date(v)) && dateFactory(v).getTime() >= startOfToday().getTime()],
          ),
        ];
      case "dateInPast":
        return [
          this.createValidator(
            TEST_VALIDATOR,
            ["dateInPast", this.intl.formatMessage({ id: INVALID_DATE_IN_FUTURE }), (v) => isValid(new Date(v)) && isPast(dateFactory(v))],
          ),
        ];
      case "dateNotToFarInFuture":
        return [
          this.createValidator(
            TEST_VALIDATOR,
            ["dateNotToFarInFuture", this.intl.formatMessage({ id: INVALID_DATE_TO_FAR_IN_FUTURE }), (v) => {
              if (!isValid(new Date(v))) {
                return false;
              }

              const { years } = intervalToDuration({
                end: dateFactory(v),
                start: new Date(),
              });

              return years === 0;
            }],
          ),
        ];
      default:
        // eslint-disable-next-line no-console
        console.error("No Such validator found");

        return {};
    }
  };

  get = (key, required, requiredLabel) => {
    let validations = null;
    const requiredArray = required
      ? [this.createValidator(
        REQUIRED_VALIDATOR,
        [this.intl.formatMessage({ id: requiredLabel || REQUIRED_ERROR })],
      )]
      : [];

    if (key) {
      validations = this.getValidations(key, this.intl);
    }

    return validations ? requiredArray.concat(validations) : requiredArray;
  };
}
