import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import "./missionStatementFact.scss";

const MissionStatementFact = ({ icon, number, description }) => {
  const intl = useIntl();

  return (
    <div className="mission-statement-fact">
      <div className="mission-statement-fact__icon">
        {icon}
      </div>
      <div className="mission-statement-fact__description">
        <div className="typo-h3">{intl.formatNumber(number)}</div>
        <p>{description}</p>
      </div>
    </div>
  );
};

MissionStatementFact.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  number: PropTypes.number.isRequired,
};

export default MissionStatementFact;
