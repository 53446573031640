import React, { useMemo, useState } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setPaymentData } from "../../../features/registrationSlice";
import {
  IconError18,
  IconFillForm24,
  IconFurther18,
  IconFurther24,
  IconPhone18,
} from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import { currency } from "../../../utils/formats";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import AddressInput from "../../addressInput/addressInput";
import Button from "../../button/button";
import IconButton from "../../button/iconButton";
import CollapseBox from "../../collapseBox/collapseBox";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../../registrationDialog/registrationDataHelper";
import RegistrationDialogHeadline from "../../registrationDialog/registrationDialogHeadline";
import ToggleButton from "../../toggleButton/toggleButton";
import PaymentDataHelper from "../paymentDataHelper";

const PaymentDialogStepPersonalInformation = ({ content, next }) => {
  const paymentData = useSelector((state) => state.registration.paymentData);
  const maxDonate = useSelector((state) => state.registration.maxDonate);
  const dispatch = useDispatch();

  const intl = useIntl();

  if (maxDonate) {
    return (
      <Row>
        <Col sm={10}>
          <RegistrationDialogHeadline
            headline={intl.formatMessage(
              { id: "payment_dialog.max_amount_title" },
              {
                amount: currency(PaymentDataHelper
                  .maxAmount[process.env.GATSBY_SITE][paymentData?.interval]),
              },
            )}
          />
        </Col>
        <Col>
          {content.contactPerson}
        </Col>
        <Col xs={12} className="mt-36">
          <Button
            variant="donation"
            href={`tel:${content.contactPerson.props.data.value[0].elements.phone.value}`}
          >
            <IconPhone18 className="mr-12" />
            <FormattedMessage id="payment.dialog.payment.call_now" />
          </Button>
        </Col>
      </Row>
    );
  }

  const [addressShown, setAddressShown] = useState(paymentData.street !== "");
  const [companyAddressShown, setCompanyAddressShown] = useState(paymentData.companyStreet !== "");

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });
  const emailMessage = intl.formatMessage({ id: "registration.additional.error.email" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const validationSchema = useMemo(() => object().shape({
    city: string()
      .when("donorType", {
        is: (value) => value === "private",
        then: string()
          .required(requiredMessage)
          .min(2, requiredMessage)
          .max(50, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 50,
            },
          ))
          .trim()
          .matches(/^[^\u0600-\u06FF]*$/, invalidValueMessage)
          .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
      }),
    companyCity: string()
      .when("donorType", {
        is: (value) => value === "company",
        then: string()
          .required(requiredMessage)
          .min(2, requiredMessage)
          .max(50, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 50,
            },
          ))
          .trim()
          .matches(/^[^\u0600-\u06FF]*$/, invalidValueMessage)
          .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
      }),
    companyHouseNo: string()
      .when("donorType", {
        is: (value) => value === "company",
        then: string()
          .required(requiredMessage)
          .max(10, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 10,
            },
          ))
          .matches(
            /^[0-9]+[a-zA-Z0-9 \-/]*$/,
            invalidValueMessage,
          ),
      }),
    companyName: string()
      .when("donorType", {
        is: (value) => value === "company",
        then: string()
          .required(requiredMessage)
          .min(2, requiredMessage)
          .max(40, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 40,
            },
          ))
          .trim()
          .matches(/^[^\u0600-\u06FF]*$/, invalidValueMessage)
          .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
      }),
    companyPostCode: string()
      .when("donorType", {
        is: (value) => value === "company",
        then: string()
          .required(requiredMessage)
          .max(5, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 5,
            },
          ))
          .matches(
            /^[0-9]{5}$/,
            intl.formatMessage({ id: "form.error.zipcode" }),
          ),
      }),
    companyStreet: string()
      .when("donorType", {
        is: (value) => value === "company",
        then: string()
          .required(requiredMessage)
          .min(2, requiredMessage)
          .max(50, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 50,
            },
          ))
          .trim()
          .matches(/^[^\u0600-\u06FF]*$/, invalidValueMessage)
          .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
      }),
    donorType: string()
      .required(requiredMessage),
    emailAddress: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage),
    firstname: string()
      .when("donorType", {
        is: (value) => value === "private",
        then: string()
          .min(2, requiredMessage)
          .required(requiredMessage)
          .max(40, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 40,
            },
          ))
          .trim()
          .matches(/^[^\u0600-\u06FF]*$/, onlyAlphabeticMessage)
          .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
      }),
    gender: string()
      .when("donorType", {
        is: (value) => value === "private",
        then: string()
          .required(requiredMessage),
      }),
    houseNo: string()
      .when("donorType", {
        is: (value) => value === "private",
        then: string()
          .required(requiredMessage)
          .max(10, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 10,
            },
          ))
          .matches(
            /^[0-9]+[a-zA-Z0-9 \-/]*$/,
            invalidValueMessage,
          ),
      }),
    lastname: string()
      .when("donorType", {
        is: (value) => value === "private",
        then: string()
          .min(2, requiredMessage)
          .required(requiredMessage)
          .max(100, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 100,
            },
          ))
          .trim()
          .matches(/^[^\u0600-\u06FF]*$/, onlyAlphabeticMessage)
          .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
      }),
    postCode: string()
      .when("donorType", {
        is: (value) => value === "private",
        then: string()
          .required(requiredMessage)
          .max(5, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 5,
            },
          ))
          .matches(
            /^[0-9]{5}$/,
            intl.formatMessage({ id: "form.error.zipcode" }),
          ),
      }),
    street: string()
      .when("donorType", {
        is: (value) => value === "private",
        then: string()
          .required(requiredMessage)
          .min(2, requiredMessage)
          .max(50, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            {
              value: 50,
            },
          ))
          .matches(/^[^\u0600-\u06FF]*$/, invalidValueMessage)
          .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
      }),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline={intl.formatMessage(
          {
            id: (paymentData.interval !== "ONCE"
              ? "payment_dialog.personal.title"
              : "payment_dialog.personal.title_once"),
          },
          {
            amount: parseFloat(paymentData.amount),
            interval: intl.formatMessage({ id: `payment_dialog.${paymentData.interval.toLowerCase()}` }).toLowerCase(),
          },
        )}
      />
      <Formik
        initialValues={paymentData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setPaymentData({
              donorType: values.donorType,
              emailAddress: values.emailAddress,

              ...values.donorType === "private" ? {
                apartment: "",
                careOfAddress: "",
                city: values.city,
                companyCity: "",
                companyHouseNo: "",
                companyName: "",
                companyPostCode: "",
                companyStreet: "",
                firstname: values.firstname,
                gender: values.gender,
                houseNo: values.houseNo,
                lastname: values.lastname,
                postCode: values.postCode,
                salutation: values.salutation,
                street: values.street,
              } : {},

              ...values.donorType === "company" ? {
                apartment: "",
                careOfAddress: "",
                city: "",
                companyCity: values.companyCity,
                companyHouseNo: values.companyHouseNo,
                companyName: values.companyName,
                companyPostCode: values.companyPostCode,
                companyStreet: values.companyStreet,
                firstname: "",
                gender: "",
                houseNo: "",
                lastname: "",
                postCode: "",
                salutation: "",
                street: "",
              } : {},
            }),
          );

          DataLayer.pushEvent("pp_donation_step_personal_information", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          setFieldError,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} className="payment-dialog__toggle-buttons">
                <ToggleButton
                  variant="donation"
                  value="private"
                  checked={values.donorType === "private"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mr-12"
                  name="donorType"
                  id="donorTypePrivate"
                  onClick={() => {
                    setFieldValue("donorType", "private");
                    setFieldTouched("donorType");
                  }}
                >
                  {intl.formatMessage({ id: "payment_dialog.donorType.private" })}
                </ToggleButton>
                <ToggleButton
                  variant="donation"
                  value="company"
                  checked={values.donorType === "company"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mr-12"
                  name="donorType"
                  id="donorTypeCompany"
                  onClick={() => {
                    setFieldValue("donorType", "company");
                    setFieldTouched("company");
                  }}
                >
                  {intl.formatMessage({ id: "payment_dialog.donorType.company" })}
                </ToggleButton>
                {errors.donorType && touched.donorType && !values.donorType && (
                  <Form.Text className="invalid-feedback">
                    <IconError18 />{errors.donorType}
                  </Form.Text>
                )}
              </Col>
              {values.donorType === "company" && (
                <>
                  <Col xs={12}>
                    <FormField
                      onChange={(event) => {
                        setFieldTouched("companyName", true);
                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        setFieldTouched("companyName", true);
                        handleBlur(event);
                      }}
                      id="companyName"
                      label={intl.formatMessage({ id: "payment_dialog.company" })}
                      type="text"
                      maxLength={40}
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="emailAddress"
                      label={intl.formatMessage({
                        id: "payment_dialog.business_email",
                      })}
                      type="email"
                      maxLength={100}
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    <AddressInput
                      onResult={(data) => {
                        setCompanyAddressShown(true);

                        setFieldValue("companyStreet", data.street || "");
                        setFieldValue("companyHouseNo", data.houseno || "");
                        setFieldValue("companyPostCode", data.zipcode || "");
                        setFieldValue("companyCity", data.city || "");
                      }}
                    />
                    <Col xs={12}>
                      <CollapseBox
                        opened={companyAddressShown}
                        label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
                        icon={<IconFillForm24 />}
                      >
                        <Row>
                          <Col md={8}>
                            <FormField
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="companyStreet"
                              label={intl.formatMessage({ id: "registration.address.street" })}
                              type="text"
                              maxLength={50}
                              touched={touched}
                              errors={errors}
                              values={values}
                              required
                            />
                          </Col>
                          <Col md={4}>
                            <FormField
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="companyHouseNo"
                              label={intl.formatMessage({ id: "registration.address.houseNo" })}
                              type="text"
                              maxLength={10}
                              touched={touched}
                              errors={errors}
                              values={values}
                              required
                            />
                          </Col>
                          <Col md={4} className="mb-md-0">
                            <FormField
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="companyPostCode"
                              label={intl.formatMessage({ id: "registration.address.postCode" })}
                              type="text"
                              maxLength={5}
                              touched={touched}
                              errors={errors}
                              values={values}
                              required
                            />
                          </Col>
                          <Col md={8} className="mb-0">
                            <FormField
                              onChange={handleChange}
                              onBlur={handleBlur}
                              id="companyCity"
                              label={intl.formatMessage({ id: "registration.address.city" })}
                              type="text"
                              maxLength={50}
                              touched={touched}
                              errors={errors}
                              values={values}
                              required
                            />
                          </Col>
                        </Row>
                      </CollapseBox>
                    </Col>
                  </Col>
                </>
              )}
              {values.donorType !== "company" && (
                <>
                  <Col xs={12}>
                    <div className="typo-label required-sign">
                      <FormattedMessage id="form.salutation" />
                    </div>
                    <Form.Group>
                      <Form.Check
                        type="radio"
                        label={intl.formatMessage({ id: "form.sex.male" })}
                        name="gender"
                        value="M"
                        id="gender-male"
                        checked={values.gender === "M"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inline
                        className={classNames("mb-0", errors.gender && touched.gender ? "is-invalid" : "")}
                      />
                      <Form.Check
                        type="radio"
                        label={intl.formatMessage({ id: "form.sex.female" })}
                        name="gender"
                        value="F"
                        id="gender-female"
                        checked={values.gender === "F"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inline
                        className={classNames("mb-0", errors.gender && touched.gender ? "is-invalid" : "")}
                      />
                      {errors.gender && touched.gender && (
                        <Form.Text className="invalid-feedback">
                          <IconError18 />{errors.gender}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12}>
                    <DropdownFormField
                      label={intl.formatMessage({ id: "registration.personal.salutation" })}
                      placeholder={intl.formatMessage({ id: "registration.please_select" })}
                      onChange={(selectedOption) => {
                        const { target } = selectedOption;

                        if (target && target.value) {
                          setFieldValue("salutation", target.value);
                          setFieldError("salutation", undefined);
                        } else {
                          setFieldValue("salutation", "");
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched("salutation", true);
                      }}
                      touched={touched}
                      id="salutation"
                      errors={errors}
                      values={values}
                      options={RegistrationDataHelper.titleOptionsDe}
                    />
                  </Col>

                  <Col xs={12} lg={6}>
                    <FormField
                      onChange={(event) => {
                        setFieldTouched("gender", true);
                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        setFieldTouched("gender", true);
                        handleBlur(event);
                      }}
                      id="firstname"
                      label={intl.formatMessage({ id: "registration.personal.firstname" })}
                      type="text"
                      maxLength={40}
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>

                  <Col xs={12} lg={6}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="lastname"
                      label={intl.formatMessage({ id: "registration.personal.lastname" })}
                      type="text"
                      maxLength={100}
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>

                  <Col xs={12}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="emailAddress"
                      label={intl.formatMessage({
                        id: "registration.additional.email",
                      })}
                      type="email"
                      maxLength={100}
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>

                  <Col xs={12}>
                    <AddressInput
                      onResult={(data) => {
                        setAddressShown(true);

                        setFieldValue("street", data.street || "");
                        setFieldValue("houseNo", data.houseno || "");
                        setFieldValue("postCode", data.zipcode || "");
                        setFieldValue("city", data.city || "");
                      }}
                    />
                  </Col>

                  <Col xs={12}>
                    <CollapseBox
                      opened={addressShown}
                      label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
                      icon={<IconFillForm24 />}
                    >
                      <Row>
                        <Col md={8}>
                          <FormField
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="street"
                            label={intl.formatMessage({ id: "registration.address.street" })}
                            type="text"
                            maxLength={50}
                            touched={touched}
                            errors={errors}
                            values={values}
                            required
                          />
                        </Col>
                        <Col md={4}>
                          <FormField
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="houseNo"
                            label={intl.formatMessage({ id: "registration.address.houseNo" })}
                            type="text"
                            maxLength={10}
                            touched={touched}
                            errors={errors}
                            values={values}
                            required
                          />
                        </Col>
                        <Col md={4} className="mb-md-0">
                          <FormField
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="postCode"
                            label={intl.formatMessage({ id: "registration.address.postCode" })}
                            type="text"
                            maxLength={5}
                            touched={touched}
                            errors={errors}
                            values={values}
                            required
                          />
                        </Col>
                        <Col md={8} className="mb-0">
                          <FormField
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="city"
                            label={intl.formatMessage({ id: "registration.address.city" })}
                            type="text"
                            maxLength={50}
                            touched={touched}
                            errors={errors}
                            values={values}
                            required
                          />
                        </Col>
                      </Row>
                    </CollapseBox>
                  </Col>
                </>
              )}
              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton
                    type="submit"
                    variant="donation"
                    onClick={() => {
                      if (values.donorType === "private") {
                        setAddressShown(true);
                      } else {
                        setCompanyAddressShown(true);
                      }
                    }}
                  >
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>
                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

PaymentDialogStepPersonalInformation.stepName = "personal_information";

PaymentDialogStepPersonalInformation.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default PaymentDialogStepPersonalInformation;
