import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./learnHeader.scss";

const LearnHeader = (props) => {
  const {
    image,
    headline,
    text,
    button,
  } = props;

  return (
    <Row className="learn-header">
      <Col
        xs={{ offset: 2, span: 8 }}
        sm={{ offset: 1, span: 4 }}
        md={{ offset: 1, span: 4 }}
        className="align-self-sm-center"
      >
        <div className="learn-header__image-container">
          {image}
        </div>
      </Col>
      <Col
        sm={7}
        md={{ offset: 1, span: 6 }}
        className="learn-header__text-container"
      >
        <h1>{headline}</h1>
        {text && <div className="learn-header__text">{text}</div>}
        {button}
      </Col>
    </Row>
  );
};

LearnHeader.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]).isRequired,
  headline: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  text: PropTypes.string,
};

LearnHeader.defaultProps = {
  text: null,
};

export default LearnHeader;
