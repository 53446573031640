/* eslint-disable no-console */
import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";

import "./mapTileContentBlocker.scss";

const MapTileContentBlocker = () => {
  const intl = useIntl();

  return (
    <div className="placeholder-image-container">
      <img src="/images/placeholder/placeholder-map.webp" alt={intl.formatMessage({ id: "consent.map_placeholder_alt" })} />
    </div>
  );
};

export default MapTileContentBlocker;
