import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import ContactPerson from "../components/contactPerson/contactPerson";

const KontentItemContact = (props) => {
  const {
    data,
    stack,
    hideDepartment,
    variant,
    contactHref,
    isAuthor,
  } = props;

  const contact = data?.value ? data?.value[0] : data;

  if (!contact || !contact.elements) {
    return null;
  }

  const intl = useIntl();

  let image = null;

  if (contact.elements.image.value.length > 0) {
    image = (
      <KontentItemImage
        data={contact.elements.image}
        height={300}
        width={300}
        title={contact.elements.name.value}
        alt={contact.elements.name.value}
      />
    );
  }

  return (
    <ContactPerson
      name={`${isAuthor ? intl.formatMessage({ defaultMessage: " ", id: "author.by" }) : ""} ${contact.elements.name.value}`}
      department={!hideDepartment ? contact.elements.description.value : null}
      image={image}
      phone={contact.elements.phone.value}
      mail={contact.elements.e_mail.value}
      stack={stack}
      variant={variant}
      contactHref={contactHref}
      whatsapp={contact.elements.whatsapp.value
        ? parseInt(contact.elements.whatsapp.value, 10)
        : null}
    />
  );
};

export const query = graphql`
  fragment KontentItemContact on kontent_item_contact {
    id
    system {
      codename
    }
    elements {
      name {
        value
      }
      description {
        value
      }
      e_mail {
        value
      }
      phone {
        value
      }
      image {
        value {
          ...KontentItemImage
        }
      }
      whatsapp {
        value
      }
    }
  }
`;

KontentItemContact.propTypes = {
  contactHref: PropTypes.oneOfType([PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hideDepartment: PropTypes.bool,
  isAuthor: PropTypes.bool,
  stack: PropTypes.bool,
  variant: PropTypes.oneOf(["money-donation"]),
};

KontentItemContact.defaultProps = {
  contactHref: null,
  hideDepartment: false,
  isAuthor: false,
  stack: false,
  variant: null,
};

export default KontentItemContact;
