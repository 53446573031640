const rsApos = "['\u2019]";
const reApos = RegExp(rsApos, "g");
const INFINITY = 1 / 0;
const symbolProto = Symbol ? Symbol.prototype : undefined;
const symbolToString = symbolProto ? symbolProto.toString : undefined;
const symbolTag = "[object Symbol]";
const nullTag = "[object Null]";
const undefinedTag = "[object Undefined]";
const symToStringTag = Symbol ? Symbol.toStringTag : undefined;
const objectProto = Object.prototype;
const { hasOwnProperty } = objectProto;
const nativeObjectToString = objectProto.toString;
const rsAstralRange = "\\ud800-\\udfff";
const rsComboMarksRange = "\\u0300-\\u036f";
const reComboHalfMarksRange = "\\ufe20-\\ufe2f";
const rsComboSymbolsRange = "\\u20d0-\\u20ff";
const rsComboRange = rsComboMarksRange + reComboHalfMarksRange + rsComboSymbolsRange;
const rsVarRange = "\\ufe0e\\ufe0f";
const rsZWJ = "\\u200d";
// eslint-disable-next-line no-misleading-character-class
const reHasUnicode = RegExp(`[${rsZWJ}${rsAstralRange}${rsComboRange}${rsVarRange}]`);
const rsAstral = `[${rsAstralRange}]`;
const rsCombo = `[${rsComboRange}]`;
const rsFitz = "\\ud83c[\\udffb-\\udfff]";
const rsModifier = `(?:${rsCombo}|${rsFitz})`;
const rsNonAstral = `[^${rsAstralRange}]`;
const rsRegional = "(?:\\ud83c[\\udde6-\\uddff]){2}";
const rsSurrPair = "[\\ud800-\\udbff][\\udc00-\\udfff]";
const reOptMod = `${rsModifier}?`;
const rsOptVar = `[${rsVarRange}]?`;
const rsOptJoin = `(?:${rsZWJ}(?:${[rsNonAstral, rsRegional, rsSurrPair].join("|")})${rsOptVar}${reOptMod})*`;
const rsSeq = rsOptVar + reOptMod + rsOptJoin;
const rsSymbol = `(?:${[`${rsNonAstral + rsCombo}?`, rsCombo, rsRegional, rsSurrPair, rsAstral].join("|")})`;
const reUnicode = RegExp(`${rsFitz}(?=${rsFitz})|${rsSymbol}${rsSeq}`, "g");
const reLatin = /[\xc0-\xd6\xd8-\xf6\xf8-\xff\u0100-\u017f]/g;
const reComboMark = RegExp(rsCombo, "g");
const rsDingbatRange = "\\u2700-\\u27bf";
const rsLowerRange = "a-z\\xdf-\\xf6\\xf8-\\xff";
const rsMathOpRange = "\\xac\\xb1\\xd7\\xf7";
const rsNonCharRange = "\\x00-\\x2f\\x3a-\\x40\\x5b-\\x60\\x7b-\\xbf";
const rsPunctuationRange = "\\u2000-\\u206f";
const rsSpaceRange = " \\t\\x0b\\f\\xa0\\ufeff\\n\\r\\u2028\\u2029\\u1680\\u180e\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200a\\u202f\\u205f\\u3000";
const rsUpperRange = "A-Z\\xc0-\\xd6\\xd8-\\xde";
const rsBreakRange = rsMathOpRange + rsNonCharRange + rsPunctuationRange + rsSpaceRange;
const rsBreak = `[${rsBreakRange}]`;
const rsDigits = "\\d+";
const rsDingbat = `[${rsDingbatRange}]`;
const rsLower = `[${rsLowerRange}]`;
const rsMisc = `[^${rsAstralRange}${rsBreakRange}${rsDigits}${rsDingbatRange}${rsLowerRange}${rsUpperRange}]`;
const rsUpper = `[${rsUpperRange}]`;
const rsMiscLower = `(?:${rsLower}|${rsMisc})`;
const rsMiscUpper = `(?:${rsUpper}|${rsMisc})`;
const rsOptContrLower = `(?:${rsApos}(?:d|ll|m|re|s|t|ve))?`;
const rsOptContrUpper = `(?:${rsApos}(?:D|LL|M|RE|S|T|VE))?`;
const rsOrdLower = "\\d*(?:1st|2nd|3rd|(?![123])\\dth)(?=\\b|[A-Z_])";
const rsOrdUpper = "\\d*(?:1ST|2ND|3RD|(?![123])\\dTH)(?=\\b|[a-z_])";
const rsEmoji = `(?:${[rsDingbat, rsRegional, rsSurrPair].join("|")})${rsSeq}`;
const reUnicodeWord = RegExp([
  `${rsUpper}?${rsLower}+${rsOptContrLower}(?=${[rsBreak, rsUpper, "$"].join("|")})`,
  `${rsMiscUpper}+${rsOptContrUpper}(?=${[rsBreak, rsUpper + rsMiscLower, "$"].join("|")})`,
  `${rsUpper}?${rsMiscLower}+${rsOptContrLower}`,
  `${rsUpper}+${rsOptContrUpper}`,
  rsOrdUpper,
  rsOrdLower,
  rsDigits,
  rsEmoji,
].join("|"), "g");
// eslint-disable-next-line no-control-regex
const reAsciiWord = /[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g;
const reHasUnicodeWord = /[a-z][A-Z]|[A-Z]{2}[a-z]|[0-9][a-zA-Z]|[a-zA-Z][0-9]|[^a-zA-Z0-9 ]/;
const deburredLetters = {
  // Latin-1 Supplement block.
  À: "A",
  Á: "A",
  Â: "A",
  Ã: "A",
  Ä: "A",
  Å: "A",
  Æ: "Ae",
  Ç: "C",
  È: "E",
  É: "E",
  Ê: "E",
  Ë: "E",
  Ì: "I",
  Í: "I",
  Î: "I",
  Ï: "I",
  Ð: "D",
  Ñ: "N",
  Ò: "O",
  Ó: "O",
  Ô: "O",
  Õ: "O",
  Ö: "O",
  Ø: "O",
  Ù: "U",
  Ú: "U",
  Û: "U",
  Ü: "U",
  Ý: "Y",
  Þ: "Th",
  ß: "ss",
  à: "a",
  á: "a",
  â: "a",
  ã: "a",
  ä: "a",
  å: "a",
  æ: "ae",
  ç: "c",
  è: "e",
  é: "e",
  ê: "e",
  ë: "e",
  ì: "i",
  í: "i",
  î: "i",
  ï: "i",
  ð: "d",
  ñ: "n",
  ò: "o",
  ó: "o",
  ô: "o",
  õ: "o",
  ö: "o",
  ø: "o",
  ù: "u",
  ú: "u",
  û: "u",
  ü: "u",
  ý: "y",
  þ: "th",
  ÿ: "y",
  // Latin Extended-A block.
  Ā: "A",
  ā: "a",
  Ă: "A",
  ă: "a",
  Ą: "A",
  ą: "a",
  Ć: "C",
  ć: "c",
  Ĉ: "C",
  ĉ: "c",
  Ċ: "C",
  ċ: "c",
  Č: "C",
  č: "c",
  Ď: "D",
  ď: "d",
  Đ: "D",
  đ: "d",
  Ē: "E",
  ē: "e",
  Ĕ: "E",
  ĕ: "e",
  Ė: "E",
  ė: "e",
  Ę: "E",
  ę: "e",
  Ě: "E",
  ě: "e",
  Ĝ: "G",
  ĝ: "g",
  Ğ: "G",
  ğ: "g",
  Ġ: "G",
  ġ: "g",
  Ģ: "G",
  ģ: "g",
  Ĥ: "H",
  ĥ: "h",
  Ħ: "H",
  ħ: "h",
  Ĩ: "I",
  ĩ: "i",
  Ī: "I",
  ī: "i",
  Ĭ: "I",
  ĭ: "i",
  Į: "I",
  į: "i",
  İ: "I",
  ı: "i",
  Ĳ: "IJ",
  ĳ: "ij",
  Ĵ: "J",
  ĵ: "j",
  Ķ: "K",
  ķ: "k",
  ĸ: "k",
  Ĺ: "L",
  ĺ: "l",
  Ļ: "L",
  ļ: "l",
  Ľ: "L",
  ľ: "l",
  Ŀ: "L",
  ŀ: "l",
  Ł: "L",
  ł: "l",
  Ń: "N",
  ń: "n",
  Ņ: "N",
  ņ: "n",
  Ň: "N",
  ň: "n",
  ŉ: "'n",
  Ŋ: "N",
  ŋ: "n",
  Ō: "O",
  ō: "o",
  Ŏ: "O",
  ŏ: "o",
  Ő: "O",
  ő: "o",
  Œ: "Oe",
  œ: "oe",
  Ŕ: "R",
  ŕ: "r",
  Ŗ: "R",
  ŗ: "r",
  Ř: "R",
  ř: "r",
  Ś: "S",
  ś: "s",
  Ŝ: "S",
  ŝ: "s",
  Ş: "S",
  ş: "s",
  Š: "S",
  š: "s",
  Ţ: "T",
  ţ: "t",
  Ť: "T",
  ť: "t",
  Ŧ: "T",
  ŧ: "t",
  Ũ: "U",
  ũ: "u",
  Ū: "U",
  ū: "u",
  Ŭ: "U",
  ŭ: "u",
  Ů: "U",
  ů: "u",
  Ű: "U",
  ű: "u",
  Ų: "U",
  ų: "u",
  Ŵ: "W",
  ŵ: "w",
  Ŷ: "Y",
  ŷ: "y",
  Ÿ: "Y",
  Ź: "Z",
  ź: "z",
  Ż: "Z",
  ż: "z",
  Ž: "Z",
  ž: "z",
  ſ: "s",
};

function getRawTag(value) {
  const tmpValue = value;
  const isOwn = hasOwnProperty.call(tmpValue, symToStringTag);
  const tag = tmpValue[symToStringTag];
  let unmasked;
  try {
    tmpValue[symToStringTag] = undefined;
    unmasked = true;
  } catch (e) {
    // ignore
  }

  const result = nativeObjectToString.call(tmpValue);

  if (unmasked) {
    if (isOwn) {
      tmpValue[symToStringTag] = tag;
    } else {
      delete tmpValue[symToStringTag];
    }
  }

  return result;
}

function objectToString(value) {
  return nativeObjectToString.call(value);
}

function baseGetTag(value) {
  if (value == null) {
    return value === undefined ? undefinedTag : nullTag;
  }

  return (symToStringTag && symToStringTag in Object(value))
    ? getRawTag(value)
    : objectToString(value);
}

function isObjectLike(value) {
  return value != null && typeof value === "object";
}

function isSymbol(value) {
  return typeof value === "symbol"
    || (isObjectLike(value) && baseGetTag(value) === symbolTag);
}

function arrayMap(array, iteratee) {
  let index = -1;
  const length = array == null ? 0 : array.length;
  const result = Array(length);

  // eslint-disable-next-line no-plusplus
  while (++index < length) {
    result[index] = iteratee(array[index], index, array);
  }

  return result;
}

function baseToString(value) {
  // Exit early for strings to avoid a performance hit in some environments.
  if (typeof value === "string") {
    return value;
  }

  if (Array.isArray(value)) {
    // Recursively convert values (susceptible to call stack limits).
    return `${arrayMap(value, baseToString)}`;
  }

  if (isSymbol(value)) {
    return symbolToString ? symbolToString.call(value) : "";
  }
  const result = (`${value}`);

  return (result === "0" && (1 / value) === -INFINITY) ? "-0" : result;
}

function toString(value) {
  return value == null ? "" : baseToString(value);
}

function basePropertyOf(object) {
  return function a(key) {
    return object == null ? undefined : object[key];
  };
}

const deburrLetter = basePropertyOf(deburredLetters);

function deburr(string) {
  const tmpString = toString(string);

  return tmpString && tmpString.replace(reLatin, deburrLetter).replace(reComboMark, "");
}

function asciiWords(string) {
  return string.match(reAsciiWord) || [];
}

function unicodeWords(string) {
  return string.match(reUnicodeWord) || [];
}

function hasUnicodeWord(string) {
  return reHasUnicodeWord.test(string);
}

function words(string, pattern, guard) {
  const tmpString = toString(string);
  const tmpPattern = guard ? undefined : pattern;

  if (tmpPattern === undefined) {
    return hasUnicodeWord(tmpString) ? unicodeWords(tmpString) : asciiWords(tmpString);
  }

  return tmpString.match(tmpPattern) || [];
}

function arrayReduce(array, iteratee, accumulator, initAccum) {
  let index = -1;
  const length = array == null ? 0 : array.length;
  let tmpAccumulator = accumulator;

  if (initAccum && length) {
    // eslint-disable-next-line no-plusplus
    tmpAccumulator = array[++index];
  }

  // eslint-disable-next-line no-plusplus
  while (++index < length) {
    tmpAccumulator = iteratee(tmpAccumulator, array[index], index, array);
  }

  return tmpAccumulator;
}

function createCompounder(callback) {
  return function a(string) {
    return arrayReduce(words(deburr(string).replace(reApos, "")), callback, "");
  };
}

function hasUnicode(string) {
  return reHasUnicode.test(string);
}

function asciiToArray(string) {
  return string.split("");
}

function unicodeToArray(string) {
  return string.match(reUnicode) || [];
}

function stringToArray(string) {
  return hasUnicode(string)
    ? unicodeToArray(string)
    : asciiToArray(string);
}

function baseSlice(array, start, end) {
  let index = -1;
  let { length } = array;
  let tmpStart = start;
  let tmpEnd = end;

  if (tmpStart < 0) {
    tmpStart = -tmpStart > length ? 0 : (length + tmpStart);
  }
  tmpEnd = tmpEnd > length ? length : tmpEnd;

  if (tmpEnd < 0) {
    tmpEnd += length;
  }
  // eslint-disable-next-line no-bitwise
  length = tmpStart > tmpEnd ? 0 : ((tmpEnd - tmpStart) >>> 0);
  // eslint-disable-next-line no-bitwise
  tmpStart >>>= 0;

  const result = Array(length);

  // eslint-disable-next-line no-plusplus
  while (++index < length) {
    result[index] = array[index + tmpStart];
  }

  return result;
}

function castSlice(array, start, end) {
  const { length } = array;
  const tmpEnd = end === undefined ? length : end;

  return (!start && tmpEnd >= length) ? array : baseSlice(array, start, tmpEnd);
}

function createCaseFirst(methodName) {
  return function a(string) {
    let tmpString = string;
    tmpString = toString(tmpString);

    const strSymbols = hasUnicode(tmpString)
      ? stringToArray(tmpString)
      : undefined;

    const chr = strSymbols
      ? strSymbols[0]
      : tmpString.charAt(0);

    const trailing = strSymbols
      ? castSlice(strSymbols, 1).join("")
      : tmpString.slice(1);

    return chr[methodName]() + trailing;
  };
}

const upperFirst = createCaseFirst("toUpperCase");

function capitalize(string) {
  return upperFirst(toString(string).toLowerCase());
}
const startCase = createCompounder((result, word, index) => result + (index ? " " : "") + upperFirst(word));

const kebabCase = createCompounder((result, word, index) => result + (index ? "-" : "") + word.toLowerCase());

const camelCase = createCompounder((result, word, index) => {
  const tmpWord = word.toLowerCase();

  return result + (index ? capitalize(tmpWord) : tmpWord);
});

exports.startCase = startCase;
exports.camelCase = camelCase;
exports.kebabCase = kebabCase;
