import React, { useRef } from "react";

import PropTypes from "prop-types";

import MediaLightboxImage from "../lightbox/mediaLightboxImage";

const GalleryImage = ({ image }) => {
  const mediaModal = useRef();

  return (
    <>
      <div
        role="presentation"
        onClick={() => {
          mediaModal.current.show();
        }}
      >
        <div className="article-module-gallery__image">
          {image}
        </div>
      </div>
      <MediaLightboxImage
        media={image}
        ref={mediaModal}
      />
    </>
  );
};

GalleryImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default GalleryImage;
