import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import DonationAccount from "../../components/donationAccount/donationAccount";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemEyecatcherDonate from "../kontentItemEyecatcherDonate";
import KontentItemRelatedLearnContentEmbed from "../kontentItemRelatedLearnContentEmbed";
import MainLayout from "../layout/mainLayout";
import DonationBoxHeader from "../moneyDonation/donationBoxHeader";
import KontentItemKeyFactAreaMoneyDonation from "../moneyDonation/kontentItemKeyFactAreaMoneyDonation";
import KontentItemMoneyPartition from "../moneyDonation/kontentItemMoneyPartition";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import SimpleRichText from "../text/simpleRichText";

const KontentItemMoneyDonationPage = ({ data: { page } }) => (
  <MainLayout>

    <SEO
      title={page.elements.metadata__meta_title.value}
      description={page.elements.metadata__meta_description.value}
      twitterTitle={page.elements.metadata__twitter_title.value}
      twitterSite={page.elements.metadata__twitter_site.value}
      twitterImage={page.elements.metadata__twitter_image.value.length > 0
        ? page.elements.metadata__twitter_image.value.length
        : page.elements.hero_image.value?.[0]?.elements?.image?.value}
      twitterDescription={page.elements.metadata__twitter_description.value}
      twitterCreator={page.elements.metadata__twitter_creator.value}
      ogTitle={page.elements.metadata__og_title.value}
      ogImage={page.elements.metadata__og_image.value.length > 0
        ? page.elements.metadata__og_image.value
        : page.elements.hero_image.value?.[0]?.elements?.image?.value}
      ogDescription={page.elements.metadata__og_description.value}
    />

    <SubNavigationGenerator id={page.id} />

    {page.elements.donation_box.value.length > 0
      ? (
        <DonationBoxHeader
          backgroundImage={page.elements.hero_image}
          donationBoxData={page.elements.donation_box.value}
        />
      )
      : (
        <HeroHeadline imageData={page.elements.hero_image.value[0]}>
          {page.elements.hero_headline.value}
        </HeroHeadline>
      )}

    <BreadcrumbGenerator id={page.id} />

    <DonationAccount
      headline={page.elements.donation_account_title.value}
      iban={page.elements.donation_account_iban.value}
      bic={page.elements.donation_account_bic.value}
      bank={page.elements.donation_account_bank.value}
      text={SimpleRichText({ data: page.elements.donation_account })}
    />

    <KontentItemKeyFactAreaMoneyDonation data={page.elements.key_fact_area} />

    <KontentItemMoneyPartition data={page.elements.data_visualization} />

    <KontentItemEyecatcherDonate data={page.elements.eyecatcher_donate_now} />

    <KontentItemRelatedLearnContentEmbed data={page.elements.related_learn_content} />

    {page.elements.page_sections.value.map((item) => mapKontentItem(item,
      { faqSectionWithImage: true }))}

  </MainLayout>
);

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemMoneyDonationPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        donation_account {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemCtaButton
          }
        }
        donation_account_bank {
          value
        }
        donation_account_bic {
          value
        }
        donation_account_iban {
          value
        }
        donation_account_title {
          value
        }
        key_fact_area {
          value {
            ...KontentItemKeyFactAreaMoneyDonation
          }
        }
        eyecatcher_donate_now {
          value {
            ...KontentItemEyecatcherDonate
          }
        }
        related_learn_content {
          value {
            ...KontentItemRelatedLearnContentEmbed
          }
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherContact
            ...KontentItemFaqSection        
            ...KontentItemIconCardSliderSection
            ...KontentItemKeyFactAreaMoneyDonation
            ...KontentItemMoneyPartition
            ...KontentItemProgramSliderSection
            ...KontentItemRelatedLearnContentEmbed
          }
        }
        data_visualization {
          value {
            ...KontentItemMoneyPartition
          }
        }
        hero_headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        donation_box {
          value {
            ...KontentItemDonationBox
          }
        }
      }
    }
  }
`;

KontentItemMoneyDonationPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemMoneyDonationPage;
