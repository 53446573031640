import React, { useState } from "react";

import classNames from "classnames";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { number, object } from "yup";

import { IconAnchorLeft18, IconExternalLink18 } from "../../icons";
import Button from "../button/button";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import CurrencyIcon from "../currencyIcon/currencyIcon";
import InputField from "../inputField/inputField";
import ToggleButton from "../toggleButton/toggleButton";

import "./donationBox.scss";

const DonationBoxToggle = ({ provider, showHeadline, withRequiredText }) => {
  const intl = useIntl();

  const [qrView, setQrView] = useState(false);
  const [stateProvider, setStateProvider] = useState(provider[0]);

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const integerMessage = intl.formatMessage({ id: "form.error.amount_must_be_integer" });

  const formik = useFormik({
    initialValues: { amount: 10 },
    onSubmit: () => {
      setQrView(true);
      showHeadline(false);
    },
    validationSchema: object().shape({
      amount: number()
        .integer(integerMessage)
        .typeError(integerMessage)
        .positive(integerMessage)
        .min(1, intl.formatMessage(
          { id: "form.error.gt_or_eq" },
          {
            value: 1,
          },
        ))
        .max(99999, intl.formatMessage(
          { id: "form.error.lt_or_eq" },
          {
            value: 99999,
          },
        ))
        .required(requiredMessage),
    }),
  });

  return (
    <>
      {qrView
        ? (
          <div className="donation-box__qr-view">
            <div className="donation-box__qr-view-header">
              <LinkButton
                variant="normal"
                className="mb-0"
                onClick={() => {
                  setQrView(false);
                  showHeadline(true);
                }}
              >
                <IconAnchorLeft18 />
              </LinkButton>
              <div className="donation-box__qr-view-title typo-title3">
                <FormattedMessage id="donation_box.donate_via" defaultMessage="Donate via" /> {stateProvider.name}
              </div>
            </div>
            <div className="typo-title1">
              <FormattedMessage id="donation_box.you_are_donating" /> {`R${formik.values.amount}`}
            </div>
            <IconButton variant="faq" href={stateProvider.urlExtern.replace("amount=", `amount=${formik.values.amount * 100}`)}>
              {stateProvider.externButtonText} {stateProvider.icon}
            </IconButton>
            <div className="d-flex align-items-center">
              <img
                src={stateProvider.urlQr.replace("amount=", `amount=${formik.values.amount * 100}`)}
                alt={stateProvider.externButtonText}
                title={stateProvider.externButtonText}
                width={154}
                height={154}
              />
              <div className="pl-18 typo-body1">
                {intl.formatMessage(
                  { id: "donation_box.qr_code_description_snapscan" },
                  { amount: `R${formik.values.amount}` },
                )}
              </div>
            </div>
          </div>
        )
        : (
          <>
            <div className="donation-box__payment-methods mt-24">
              <FormattedMessage id="donation_box.payment_methods" />
            </div>
            <div className="donation-box__provider">
              {provider.map((item) => (
                <div
                  key={item.name}
                  onClick={() => {
                    setStateProvider(item);
                  }}
                  role="presentation"
                  className={classNames("provider-box", stateProvider?.name === item.name && "active")}
                >
                  {item.logo}
                </div>
              ))}
            </div>

            {stateProvider?.details && (<div className="donation-box__toggle-details">{stateProvider.details}</div>)}

            {stateProvider?.impacts.length > 0
              ? (
                <form onSubmit={formik.submitForm}>
                  <div className="donation-box__toggle-group-headline mt-24">
                    <FormattedMessage id="payment_dialog.choose_amount" />
                  </div>
                  <div className="donation-box__toggle-group row no-gutters mb-24">
                    {stateProvider.impacts?.map((point) => (
                      <ToggleButton
                        checked={formik.values?.amount.toString() === point.toString()}
                        onClick={() => formik.setFieldValue("amount", point)}
                        variant="donation"
                        key={point}
                        className="col"
                      >
                        <FormattedMessage id="currency.amount" values={{ amount: point }} />
                      </ToggleButton>
                    ))}
                  </div>
                  <div>
                    <InputField
                      label={intl.formatMessage({ id: "currency.amount_fieldname" })}
                      required
                      prepend={<CurrencyIcon />}
                      formikInput={{ ...formik.getFieldProps("amount") }}
                      isInvalid={!formik.isValid}
                      errorMessage={formik.errors?.amount}
                    />
                  </div>
                  <div className="donation-box__submit-container">
                    <Button
                      type="submit"
                      variant="secondary"
                    >
                      <FormattedMessage id="donation_box.donate_via" /> {stateProvider?.name}
                    </Button>

                    {withRequiredText && (
                      <div className="footnote--required mt-0">
                        <span className="required-sign" />
                        <FormattedMessage id="registration.required_fields" />
                      </div>
                    )}
                  </div>
                </form>
              )
              : stateProvider?.urlExtern && (
                <IconButton variant="donation" href={stateProvider?.urlExtern} target="_blank">
                  <FormattedMessage id="donation_box.donate_via" />&nbsp;{stateProvider?.name} <IconExternalLink18 />
                </IconButton>
              )}
          </>
        )}
    </>
  );
};

DonationBoxToggle.propTypes = {
  // buttonText: PropTypes.string.isRequired,
  provider: PropTypes.arrayOf(PropTypes.shape({
    details: PropTypes.node,
    externButtonText: PropTypes.string,
    icon: PropTypes.node,
    impacts: PropTypes.arrayOf(PropTypes.number),
    name: PropTypes.string,
    urlExtern: PropTypes.string,
    urlQr: PropTypes.string,
  })).isRequired,
  showHeadline: PropTypes.func.isRequired,
  withRequiredText: PropTypes.bool,
};

DonationBoxToggle.defaultProps = {
  withRequiredText: false,
};

export default DonationBoxToggle;
