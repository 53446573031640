import getInternalLinkById from "./getInternalLinkById";

const LocationMapMarkerCreator = (locationCardsGroups) => {
  const locationsList = [];

  try {
    locationCardsGroups.forEach((locationCardsGroup) => {
      const locations = locationCardsGroup.elements.locations?.value?.length > 0
        ? locationCardsGroup.elements.locations.value : [];
      locations.forEach((location) => {
        locationsList.push({
          address: location.elements.address.value,
          city: location.elements.city.value,
          country: location.elements.country.value,
          description: location.elements.description.value,
          email: location.elements.button_mailto.value,
          external: location.elements.button_url_ext.value,
          fax: location.elements.button_fax.value,
          id: location.id,
          internal: getInternalLinkById(
            location.elements.button_url_internal?.value[0]?.id,
          )?.slug,
          internalText: location.elements.button_url_internal_text?.value,
          lat: JSON.parse(location.elements.coordinates.value).lat,
          lng: JSON.parse(location.elements.coordinates.value).lng,
          phone: location.elements.button_phone.value,
          postalCode: location.elements.postal_code.value,
          title: location.elements.title.value,
        });
      });
    });
  } catch (e) {
    return [];
  }

  return locationsList;
};

export default LocationMapMarkerCreator;
