import React from "react";

import PropTypes from "prop-types";

import SocialMediaShare from "../socialMediaShare/socialMediaShare";

import "./eyecatcher.scss";

const EyecatcherSocialMediaShare = ({ title, media, shareUrl }) => (
  <div className="eyecatcher-old eyecatcher-social-media">
    <div className="eyecatcher-social-media__description">
      {title && <div className="typo-h3">{title}</div>}
      <SocialMediaShare copyUrl onlyIcon shareButtons shareUrl={shareUrl} />
    </div>
    {media && (
      <div className="order-md-first">
        {media}
      </div>
    )}
  </div>
);

EyecatcherSocialMediaShare.propTypes = {
  media: PropTypes.node.isRequired,
  shareUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default EyecatcherSocialMediaShare;
