import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import DonorTopicTiles from "./donorArea/donorTopicTiles";
import RelatedTopics from "../components/relatedTopics/relatedTopics";

const KontentItemDonorTopicsSection = ({ data }) => {
  const donorTopicsSection = data.value ? data.value[0] : data;

  if (!donorTopicsSection || !donorTopicsSection.elements) {
    return null;
  }

  return (
    <RelatedTopics headline={donorTopicsSection.elements.headline.value}>
      {DonorTopicTiles({ data: donorTopicsSection.elements.donor_topics })}
    </RelatedTopics>
  );
};

export const query = graphql`
  fragment KontentItemDonorTopicsSection on kontent_item_donor_topics_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      donor_topics {
        value {
          ...DonorTopicTiles
        }
      }
    }
  } 
`;

KontentItemDonorTopicsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDonorTopicsSection;
