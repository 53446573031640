import React from "react";

import classNames from "classnames";
import Cleave from "cleave.js/react";
import { Field } from "formik";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { IconError18 } from "../../../icons";

import "./cleaveFormField.scss";

const CleaveFormField = (props) => {
  const {
    id,
    errors,
    touched,
    meta,
    label,
    onBlur,
    onChange,
    validateField,
    maxLength,
    options,
    values,
    placeholder,
    required,
    additionalInvalidCondition,
    inputMode,
  } = props;

  return (
    <Field name={id} validate={validateField}>
      {() => (
        <Form.Group
          controlId={id}
          className={classNames(
            { required: required || meta?.requiredFields?.includes(id) },
          )}
        >
          <Cleave
            options={options}
            className={classNames(
              "form-control",
              { "is-invalid": (errors[id] && touched[id]) || additionalInvalidCondition },
            )}
            placeholder={placeholder}
            id={id}
            name={id}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={maxLength}
            inputMode={inputMode}
            value={values?.[id]}
          />
          <Form.Label>{label}</Form.Label>
          {((errors[id] && touched[id]) || !required) && (
            <Form.Text className={classNames({ "invalid-feedback": errors[id] && touched[id] })}>
              {errors[id] && touched[id]
                ? <div><IconError18 />{errors[id]}</div>
                : <>{!required && <FormattedMessage id="registration.optional" />}</>}
            </Form.Text>
          )}
        </Form.Group>
      )}
    </Field>
  );
};

CleaveFormField.propTypes = {
  additionalInvalidCondition: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  inputMode: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  meta: PropTypes.oneOfType([PropTypes.object]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([PropTypes.object]).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  validateField: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

CleaveFormField.defaultProps = {
  additionalInvalidCondition: false,
  inputMode: "numeric",
  maxLength: Number.MAX_SAFE_INTEGER,
  meta: {},
  placeholder: undefined,
  required: false,
  validateField: () => {},
};

export default CleaveFormField;
