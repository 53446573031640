import IoBaseApiSlice from "./ioBaseApiSlice";

export const IoLocationApiSlice = IoBaseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getVerifiedAddressDetails: build.mutation({
      query: ({ addressId, country, language }) => {
        const locationApiSession = sessionStorage.getItem("locationApiSession");

        return {
          apiRequirements: ["session"],
          url: `${process.env.GATSBY_IO_HOST}/loc?id=${addressId}&country=${country}&lang=${language}${locationApiSession ? `&session=${locationApiSession}` : ""}`,
        };
      },
    }),
    getVerifiedAddressPredictions: build.mutation({
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          sessionStorage.setItem("locationApiSession", data.session);
        } catch (err) {
          //
        }
      },
      query: ({ address, country, language }) => {
        const locationApiSession = sessionStorage.getItem("locationApiSession");

        return {
          apiRequirements: ["session"],
          url: `${process.env.GATSBY_IO_HOST}/loc?q=${address}&country=${country}&lang=${language}${locationApiSession ? `&session=${locationApiSession}` : ""}`,
        };
      },
    }),
  }),
});

export const {
  useGetVerifiedAddressDetailsMutation,
  useGetVerifiedAddressPredictionsMutation,
} = IoLocationApiSlice;
