import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ContentHeader from "../../components/contentHeader/contentHeader";
import FilterDropdown from "../../components/filterDropown/filterDropdown";
import SEO from "../../components/seo";
import EventTileCard from "../events/eventTileCard";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemImageCardSliderSection from "../kontentItemImageCardSliderSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemEventsArchivePage = ({ data }) => {
  const {
    page,
  } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <ContentHeader
        headline={page.elements.title.value}
        description={<RichText data={page.elements.text} />}
        media={page.elements.icon_image.value.length > 0
          ? (
            <KontentAssetsImage
              width={400}
              data={page.elements.icon_image.value[0]}
              className="img-fluid"
            />
          )
          : null}
      />

      <FilterDropdown variant="events">
        {EventTileCard({ data: data.events.nodes, onlyPastEvents: true, sortDescendingDate: true })}
      </FilterDropdown>

      <KontentItemImageCardSliderSection data={page.elements.image_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    events: allKontentItemEvent {     
      nodes {
         ...EventTileCard
      } 
    } 
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemEventsArchivePage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        icon_image {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        image_card_slider {
          value {
            ...KontentItemImageCardSliderSection
          }
        }
        title {
          value
        }
        text {
          value
        }           
      }
    }
  }
`;

KontentItemEventsArchivePage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEventsArchivePage;
