import React, { useEffect, useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import CookieBannerContent from "./cookieBannerContent";
import {
  getComplianceSettings,
  getComplianceTypes,
  setShowComplianceSettings,
} from "../../features/complianceConsentSlice";
import DataLayer from "../../utils/dataLayer";
import getInternalLinkById from "../../utils/getInternalLinkById";

import "./cookieBanner.scss";

const CookieBanner = ({ openLinksInNewTab, asModal }) => {
  const show = useSelector((state) => (
    state.complianceConsent.show
  ));

  const complianceTypes = useSelector((state) => (
    state.complianceConsent.complianceTypes
  ));

  const consents = useSelector((state) => (
    state.complianceConsent.consents
  ));

  const data = useStaticQuery(graphql`
    {
      cookie: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          lightbox_variant {
            value {
              codename
            }
          }
          url_legal {
            value {
              id
            }
          }
          url_privacy {
            value {
              id
            }
          }
        }
      }
    }
  `);

  const [settings, setSettings] = useState({});

  const dispatch = useDispatch();

  let privacySlug;
  let legalSlug;

  if (data.cookie) {
    privacySlug = data.cookie.elements.url_privacy.value.length > 0
      ? getInternalLinkById(data.cookie.elements.url_privacy.value[0].id)?.slug
      : null;

    legalSlug = data.cookie.elements.url_legal.value.length > 0
      ? getInternalLinkById(data.cookie.elements.url_legal.value[0].id)?.slug
      : null;
  }

  if (typeof window !== "undefined"
    && window.location.href.indexOf(privacySlug) === -1
    && window.location.href.indexOf(legalSlug) === -1) {
    useEffect(() => {
      dispatch(
        getComplianceTypes(),
      );

      dispatch(
        getComplianceSettings(),
      );
    }, []);

    useEffect(() => {
      if (complianceTypes.some((key) => settings[key] && settings[key].status === -1)) {
        dispatch(
          setShowComplianceSettings(true),
        );
      }
    }, [settings]);
  }

  useEffect(() => {
    const { ppms } = window;

    if (ppms) {
      ppms.cm.api("getNewComplianceTypes", (types) => {
        setSettings({
          ...settings,
          ...Object.keys(consents).reduce((acc, key) => {
            acc[key] = { status: consents[key].status };

            return acc;
          }, {}),
          ...types.reduce((acc, key) => ({ ...acc, [key]: { status: -1 } }), {}),
        });
      });
    }
  }, [consents]);

  useEffect(() => {
    if (show) DataLayer.pushEvent("stg.consentFormViewMain");
  }, [show]);

  if ((data.cookie && data.cookie.elements.lightbox_variant.value.length > 0)
    || asModal) {
    return (
      <div
        id="acceptance-cookie-modal"
        className="cookie-banner-row"
      >
        <Modal
          animation
          show={show}
          className="consent-modal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body className="container--fullwidth">
            {show && (
              <Container fluid>
                <Row className="justify-content-center">
                  <Col md={10} lg={8} className="col-ul-6">
                    <CookieBannerContent
                      openLinksInNewTab={openLinksInNewTab}
                      settings={settings}
                      complianceTypes={complianceTypes}
                      setSettings={setSettings}
                    />
                  </Col>
                </Row>
              </Container>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  return (
    <div id="acceptance-cookie-modal" className="cookie-banner-row cookie-banner--bottom-box">
      {show && (
        <CookieBannerContent
          openLinksInNewTab={openLinksInNewTab}
          settings={settings}
          complianceTypes={complianceTypes}
          setSettings={setSettings}
        />
      )}
    </div>
  );
};

CookieBanner.propTypes = {
  asModal: PropTypes.bool,
  openLinksInNewTab: PropTypes.bool,
};

CookieBanner.defaultProps = {
  asModal: false,
  openLinksInNewTab: false,
};

export default CookieBanner;
