import React, { useMemo, useState } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFillForm24, IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import FormObserver from "../../../utils/formik/formObserver";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import AddressInput from "../../addressInput/addressInput";
import IconButton from "../../button/iconButton";
import CollapseBox from "../../collapseBox/collapseBox";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationResidentialAddress = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [addressShown, setAddressShown] = useState(registrationData.street !== "");

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const warning = useSelector((state) => state.registration.warning);

  let warningInfo;

  if (Object.keys(warning).length > 0) {
    warningInfo = RegistrationDataHelper.generateWarning(warning);
  }

  const validationSchema = useMemo(() => object().shape({
    apartment: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    city: string()
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .required(requiredMessage)
      .trim()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    county: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    houseName: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    houseNo: string()
      .max(10, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 10,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    postCode: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(10, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 10,
        },
      ))
      .matches(
        /^([Gg][Ii][Rr] ?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$/,
        intl.formatMessage({ id: "form.error.zipcode" }),
      ),
    street: string()
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .required(requiredMessage)
      .trim()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.address.title"
        subheadline="registration.address.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              apartment: values.apartment,
              city: values.city,
              county: values.county,
              houseName: values.houseName,
              houseNo: values.houseNo,
              postCode: values.postCode,
              street: values.street,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_residential_address", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormObserver />

            <AddressInput
              onResult={(data) => {
                setAddressShown(true);

                setFieldValue("street", data.thoroughfare || "");
                setFieldValue("houseNo", data.building_number || "");
                setFieldValue("postCode", data.postcode || "");
                setFieldValue("city", data.town_or_city || "");
                setFieldValue("county", data.county || "");
                setFieldValue("houseName", data.building_name || "");
                setFieldValue("apartment", data.sub_building_number || "");
              }}
            />

            <CollapseBox
              opened={addressShown}
              label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
              icon={<IconFillForm24 />}
              warning={Object.keys(warning).length > 0}
            >
              <Row>
                <Col md={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="apartment"
                    label={intl.formatMessage({ id: "registration.address.apartment" })}
                    type="text"
                    warning={warning}
                    maxLength={100}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="houseName"
                    label={intl.formatMessage({ id: "registration.address.houseName" })}
                    type="text"
                    warning={warning}
                    maxLength={100}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={4}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="houseNo"
                    label={intl.formatMessage({ id: "registration.address.houseNo" })}
                    type="text"
                    warning={warning}
                    maxLength={10}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={8}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="street"
                    label={intl.formatMessage({ id: "registration.address.street" })}
                    type="text"
                    warning={warning}
                    maxLength={50}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="city"
                    label={intl.formatMessage({ id: "registration.address.city" })}
                    type="text"
                    maxLength={50}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6} className="mb-md-0">
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="county"
                    label={intl.formatMessage({ id: "registration.address.county" })}
                    type="text"
                    maxLength={100}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6} className="mb-0">
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="postCode"
                    label={intl.formatMessage({ id: "registration.address.postCode" })}
                    type="text"
                    maxLength={10}
                    touched={touched}
                    errors={errors}
                    values={values}
                    required
                  />
                </Col>
                <Col xs={12} className="warning-info">
                  {warningInfo}
                </Col>
              </Row>
            </CollapseBox>

            <ScrollToFieldError />

            <Form.Group>
              <IconButton
                type="submit"
                disabled={isSubmitting}
                onClick={() => {
                  setAddressShown(true);
                }}
              >
                <FormattedMessage id="registration.address.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>

            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationResidentialAddress.stepName = "registration_residential_address";

RegistrationDialogStepRegistrationResidentialAddress.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationResidentialAddress;
