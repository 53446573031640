import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import NavigationLink from "../components/navigationLink/navigationLink";
import UnifyEyecatcher from "../components/unifyEyecatcher/unifyEyecatcher";

const KontentItemEyecatcherDonation = ({ data }) => {
  const eyecatcher = data.value ? data.value[0] : data;

  if (!eyecatcher || !eyecatcher.elements) {
    return null;
  }

  let buttonClass = "btn-donation";

  if (
    eyecatcher.elements.variant.value.length > 0
    && eyecatcher.elements.variant.value[0].codename === "full_color"
  ) {
    buttonClass = "btn-plain-donation";
  }

  return (
    <UnifyEyecatcher
      title={eyecatcher.elements.title.value}
      color="secondary"
      variant={eyecatcher.elements.variant?.value?.[0]?.codename === "full_color" ? "fulltone" : "dualtone"}
    >
      {
        (
          eyecatcher.elements.button_url.value.length > 0
          || eyecatcher.elements.button_url_extern.value !== ""
        )
        && (
          <NavigationLink
            className={`btn ${buttonClass}`}
            href={
              eyecatcher.elements.button_url.value.length > 0
                ? eyecatcher.elements.button_url.value[0].id
                : eyecatcher.elements.button_url_extern.value
            }
          >
            {eyecatcher.elements.button_text.value}
          </NavigationLink>
        )
      }
    </UnifyEyecatcher>
  );
};

export const query = graphql`
  fragment KontentItemEyecatcherDonation on kontent_item_eyecatcher_donation {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      button_text {
        value
      }
      button_url {
        value {
          id
        }
      }
      button_url_extern {
          value
      }
      variant {
        value {
          codename
        }
      }
    }
  }
`;

KontentItemEyecatcherDonation.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEyecatcherDonation;
