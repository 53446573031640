import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import LearnTopicTiles from "./learn/learnTopicTiles";
import RelatedTopics from "../components/relatedTopics/relatedTopics";

const KontentItemLearnTopicsSection = ({ data }) => {
  const learnTopicsSection = data.value ? data.value[0] : data;

  if (!learnTopicsSection || !learnTopicsSection.elements) {
    return null;
  }

  return (
    <RelatedTopics headline={learnTopicsSection.elements.headline.value}>
      {LearnTopicTiles({ data: learnTopicsSection.elements.learn_topics })}
    </RelatedTopics>
  );
};

export const query = graphql`
  fragment KontentItemLearnTopicsSection on kontent_item_learn_topics_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      learn_topics {
        value {
          ...LearnTopicTiles
        }
      }
    }
  } 
`;

KontentItemLearnTopicsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLearnTopicsSection;
