import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationHowDidYouHearUs = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const validationSchema = useMemo(() => object().shape({
    howDidYouHear: string()
      .required(intl.formatMessage({ id: "form.required" })),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.how_did_you_hear.title"
        subheadline="registration.how_did_you_hear.text"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({ howDidYouHear: values.howDidYouHear }),
          );

          DataLayer.pushEvent("pp_registration_step_how_did_you_hear", { event_value: "success" });
          DataLayer.pushEvent("pp_registration_step_how_did_you_hear_value",
            {
              dimension_reg_source: values.howDidYouHear,
              event_value: values.howDidYouHear,
            });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DropdownFormField
              label={intl.formatMessage({ id: "registration.how_did_you_hear.how_did_you_hear" })}
              placeholder={intl.formatMessage({ id: "registration.please_select" })}
              onChange={(selectedOption) => {
                const { target } = selectedOption;

                if (target && target.value) {
                  setFieldValue("howDidYouHear", target.value);
                  setFieldError("howDidYouHear", undefined);
                } else {
                  setFieldValue("howDidYouHear", "");
                }
              }}
              onBlur={() => {
                setFieldTouched("howDidYouHear", true);
              }}
              touched={touched}
              id="howDidYouHear"
              errors={errors}
              values={values}
              options={RegistrationDataHelper.howDidYouHearOptionsUs}
              maxMenuHeight={300}
              required
            />

            <ScrollToFieldError />

            <Form.Group>
              <IconButton type="submit" disabled={isSubmitting}>
                <FormattedMessage id="registration.how_did_you_hear.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>

            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationHowDidYouHearUs.stepName = "registration_how_did_you_hear";

RegistrationDialogStepRegistrationHowDidYouHearUs.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationHowDidYouHearUs;
