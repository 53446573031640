import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./locationCardList.scss";

const LocationCardList = ({ headline, children }) => (
  <div className="location-card-list">
    {headline && <div className="location-card-list__headline">{headline}</div>}
    <Row className="location-card-list__items">
      {children.map((child, index) => (
        <Col
          key={child.key || `lc${index.toString()}`}
          className="location-card-list__item"
          xs={12}
          sm={6}
          md={4}
        >
          {child}
        </Col>
      ))}
    </Row>
  </div>
);

LocationCardList.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string,
};

LocationCardList.defaultProps = {
  headline: "",
};

export default LocationCardList;
