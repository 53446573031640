import React, { useMemo } from "react";

import differenceInYears from "date-fns/differenceInYears";
import isFuture from "date-fns/isFuture";
import isValid from "date-fns/isValid";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setError, setRegistrationData } from "../../../features/registrationSlice";
import { IconError18, IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import { dateFactory, format } from "../../../utils/dateHelper";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DateInput from "../../dateInput/dateInput";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepEligibilityAge = ({ content, next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const error = useSelector((state) => state.registration.error);
  const dispatch = useDispatch();

  const intl = useIntl();

  DataLayer.currentStep = "eligibility_age";

  const validationSchema = useMemo(() => object().shape({
    birthdate: string()
      .required(intl.formatMessage({ id: "registration.age.birthdate_required" })),
  }), []);

  if (error) {
    return (
      <>
        <RegistrationDialogHeadline
          headline="registration.age.error.title"
        />
        <Row>
          <Col sm={10}>

            {intl.formatMessage({ defaultMessage: " ", id: "registration.age.error.text1" }) !== " " && (
              <p>
                <FormattedMessage id="registration.age.error.text1" />
              </p>
            )}

            {intl.formatMessage({ defaultMessage: " ", id: "registration.age.error.text2" }) !== " " && (
              <p>
                <FormattedMessage id="registration.age.error.text2" />
              </p>
            )}

            {intl.formatMessage({ defaultMessage: " ", id: "registration.age.error.text3" }) !== " " && (
              <p>
                <FormattedMessage id="registration.age.error.text3" />
              </p>
            )}

            {content.donationBox}

            {!content.donationBox && content.errorButtonPrimary}

            {content.errorButtonSecondary}
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.age.title"
      />
      <Formik
        initialValues={registrationData}
        validateOnMount
        validationSchema={validationSchema}
        validate={({ birthdate }) => {
          const errors = {};

          const date = new Date(birthdate);

          if (isValid(date) && isFuture(date)) {
            errors.birthdate = intl.formatMessage({ id: "form.error.date_in_future" });
          }

          return errors;
        }}
        onSubmit={({ birthdate }, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({ birthdate }),
          );

          const age = differenceInYears(new Date(), new Date(birthdate));

          let minAge;
          let maxAge;

          switch (process.env.GATSBY_SITE.toLowerCase()) {
            case "in":
              minAge = 18;
              maxAge = 50;
              break;

            case "de":
            case "gb":
            case "za":
              minAge = 17;
              maxAge = 55;
              break;

            default:
              minAge = 18;
              maxAge = 55;
              break;
          }

          if (age < minAge || age > maxAge) {
            DataLayer.pushEvent("pp_registration_step_eligibility_age", { event_value: "fail" });
            dispatch(
              setError(true),
            );
          } else {
            const eventValue = age >= 26 ? "26+" : "<26";
            DataLayer.pushEvent("pp_registration_step_eligibility_age", { event_value: "success" });
            DataLayer.pushEvent("pp_registration_step_eligibility_age_value",
              {
                dimension_reg_age: eventValue,
                event_value: eventValue,
              });
            next();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group onBlur={() => setFieldTouched("birthdate")} className="required">
              <DateInput
                initialValue={dateFactory(values.birthdate)}
                onChange={(value) => {
                  setFieldTouched("birthdate");

                  if (typeof value === "object" && isValid(value)) {
                    setFieldValue("birthdate", format(value, "yyyy-MM-dd"));
                  } else {
                    setFieldValue("birthdate", "");
                  }
                }}
                placeholder={intl.formatMessage({ id: "registration.age.birthdate_placeholder" })}
                isInvalid={(errors.birthdate && touched.birthdate)}
                name="birthdate"
              />
              <Form.Label>
                <FormattedMessage id="registration.age.birthdate" />
              </Form.Label>
              {errors.birthdate && touched.birthdate && (
                <Form.Text className="invalid-feedback">
                  <IconError18 />{errors.birthdate}
                </Form.Text>
              )}
            </Form.Group>

            <ScrollToFieldError />

            <Form.Group>
              <IconButton type="submit" disabled={isSubmitting}>
                <FormattedMessage id="registration.age.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>

            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepEligibilityAge.stepName = "eligibility_age";

RegistrationDialogStepEligibilityAge.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepEligibilityAge;
