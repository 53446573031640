import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import CardSlider from "../cardSlider/cardSlider";

import "./registrationProcess.scss";

const RegistrationProcess = (props) => {
  const {
    sections,
    title,
  } = props;

  const { width } = useWindowDimensions();

  return (
    <div className="registration-process">
      <h2>{title}</h2>
      {sections.map((item) => (
        <div className="registration-process__section" key={`section${Math.random()}`}>
          <div className="registration-process__section-label">
            {item.label}
          </div>
          <div className="registration-process__section-items">
            {width < 992
              ? (
                <CardSlider showViewAllButton={false} showSlideButtons={false}>
                  {item.items.map((item2) => (
                    <div className="registration-process__section-item" key={`sectionitem${Math.random()}`}>
                      {item2}
                    </div>
                  ))}
                </CardSlider>
              )
              : (
                <Row>
                  {item.items.map((item2) => (
                    <Col lg={4} className="registration-process__section-item" key={`sectionitem${Math.random()}`}>
                      {item2}
                    </Col>
                  ))}
                </Row>
              )}
          </div>
        </div>
      ))}
    </div>
  );
};

RegistrationProcess.propTypes = {
  sections: PropTypes.oneOfType([PropTypes.object, PropTypes.element, PropTypes.array]),
  title: PropTypes.string,
};

RegistrationProcess.defaultProps = {
  sections: null,
  title: null,
};

export default RegistrationProcess;
