import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationEthnicity = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const getOptionByValue = (options, value) => options.flatMap(
    (el) => (el.options ? el.options : el),
  ).find((el) => el.value === value);

  const validationSchema = useMemo(() => object().shape({
    ethnicity: string()
      .required(intl.formatMessage({ id: "form.required" })),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.ethnicity.title"
        subheadline="registration.ethnicity.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          const option = getOptionByValue(
            RegistrationDataHelper.ethnicityOptions,
            values.ethnicity,
          );

          dispatch(
            setRegistrationData({
              ethnicity: values.ethnicity,
              ethnicityLabel: option.label,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_ethnicity", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DropdownFormField
              label={intl.formatMessage({ id: "registration.ethnicity.ethnicity" })}
              placeholder={intl.formatMessage({ id: "registration.please_select" })}
              onChange={(selectedOption) => {
                const { target } = selectedOption;

                if (target && target.value) {
                  setFieldValue("ethnicity", target.value);
                  setFieldError("ethnicity", undefined);
                } else {
                  setFieldValue("ethnicity", "");
                }
              }}
              onBlur={() => {
                setFieldTouched("ethnicity", true);
              }}
              touched={touched}
              id="ethnicity"
              errors={errors}
              values={values}
              options={RegistrationDataHelper.ethnicityOptions}
              required
              maxMenuHeight={300}
            />

            <ScrollToFieldError />

            <Form.Group>
              <IconButton type="submit" disabled={isSubmitting}>
                <FormattedMessage id="registration.ethnicity.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>

            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationEthnicity.stepName = "registration_ethnicity";

RegistrationDialogStepRegistrationEthnicity.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationEthnicity;
