import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fields: [],
  formValues: {},
  titleUK: "",
};

const DonateFormSlice = createSlice({
  initialState,
  name: "donateForm",
  reducers: {
    reset: () => initialState,
    setFields: (state, { payload }) => {
      state.fields = payload;
    },
    setFormValues: (state, { payload }) => {
      state.formValues = payload;
    },
    setTitleUK: (state, { payload }) => {
      state.titleUK = payload;
    },
  },
});

export const {
  reset,
  setFields,
  setFormValues,
  setTitleUK,
} = DonateFormSlice.actions;

export default DonateFormSlice.reducer;
