import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import DonationBox from "./moneyDonation/donationBox";

const KontentItemDonationBox = ({ data, ...otherProps }) => {
  const donationBox = Array.isArray(data) ? data[0] : data.value;

  return (
    <DonationBox
      overwriteDonationBoxData={Array.isArray(donationBox) ? donationBox[0] : donationBox}
      {...otherProps}
    />
  );
};

export const query = graphql`
  fragment KontentItemDonationBox on kontent_item_donation_box {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      info {
        value
      }
      button_text {
        value
      }
      button_url {
        value {
          id
        }
      }
      button_url_extern {
        value
      }
      secondary_button_text {
        value
      }
      secondary_button_url {
        value {
          id
        }
      }
      secondary_button_url_extern {
        value
      }
      amount_zero {
        value {
          ...KontentItemDonationAmount
        }
      }
      amount_zero_recurrant {
        value {
          ...KontentItemDonationAmount
        }
      }
      amounts_half_yearly {
        value {
          ...KontentItemDonationAmount
        }
      }
      amounts_monthly {
        value {
          ...KontentItemDonationAmount
        }
      }
      amounts_quarterly {
        value {
          ...KontentItemDonationAmount
        }
      }
      amounts_once {
        value {
          ...KontentItemDonationAmount
        }
      }
      amounts_yearly {
        value {
          ...KontentItemDonationAmount
        }
      }
      payment_providers {
        value {
          ...KontentItemProvider 
        }
      }
    }
  }
`;

KontentItemDonationBox.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDonationBox;
