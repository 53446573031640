import React, { useLayoutEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
  IconCalendarArchive24,
  IconFilter24,
  IconSelect24,
  IconSelectEmpty24,
} from "../../icons";
import Button from "../button/button";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import FilterTag from "../filterTag/filterTag";

import "./filterDropdown.scss";

const FilterDropdown = (props) => {
  const {
    headline,
    variant,
    viewPastEventsLink,
    hideFilter,
    children,
    useOnAuthorPage,
    emptyContentWall,
  } = props;

  const [filterCriteriaState, setFilterCriteriaState] = useState(false);
  const [numberOfArticle, setNumberOfArticle] = useState(0);
  const [numberOfShowArticle, setNumberOfShowArticle] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState([]);
  const labels = useMemo(() => [], []);

  const { width } = useWindowDimensions();

  const applyFilter = () => {
    setFilterCriteriaState([...filterCriteria]);
    setShowDropdown(false);
  };

  useLayoutEffect(() => {
    if (!hideFilter) {
      children.forEach((child) => child.props.category
        && labels.indexOf(child.props.category) === -1
        && labels.push(child.props.category));
    }

    const url = new URL(window.location.href);
    const filterParams = url.searchParams.get("category")
      ? decodeURIComponent(url.searchParams.get("category")).split(",")
      : [];

    if (filterParams.length > 0) {
      filterParams.forEach((param) => {
        let indexOfParam = -1;

        labels.some((element, i) => {
          if (param.toLowerCase() === element.toLowerCase()) {
            indexOfParam = i;

            return true;
          }

          return false;
        });

        if (indexOfParam !== -1 && !filterCriteria.includes(labels[indexOfParam])) {
          filterCriteria.push(labels[indexOfParam]);
        }
      });

      applyFilter();
    }
  }, []);

  const setDefaultItemsLayout = () => {
    if (useOnAuthorPage) {
      setNumberOfArticle(width >= 992 ? 3 : 2);
      setNumberOfShowArticle(width >= 992 ? 3 : 2);
    } else {
      setNumberOfArticle(width >= 576 ? 12 : 6);
      setNumberOfShowArticle(width >= 576 ? 12 : 6);
    }
  };

  useLayoutEffect(() => {
    setDefaultItemsLayout();
  }, [width]);

  const setFilterCriterion = (label) => {
    if (filterCriteria.indexOf(label) !== -1) {
      setFilterCriteria(
        filterCriteria.filter(
          (filterLabel) => filterLabel !== label,
        ),
      );
    } else {
      setFilterCriteria([
        ...filterCriteria,
        label,
      ]);
    }
  };

  const filterChildren = useMemo(() => {
    if (filterCriteriaState.length > 0) {
      return children.filter((child) => (
        filterCriteriaState.indexOf(child.props.category) !== -1
      ));
    }

    return children;
  }, [filterCriteriaState, children]);

  if ((!children || (Array.isArray(children) && children.length === 0)) && variant !== "events") {
    return null;
  }

  const articles = filterChildren;

  let colProps;

  switch (variant) {
    case "events":
      colProps = {
        md: 6,
        sm: 12,
      };
      break;
    case "article-teaser":
      colProps = {
        sm: 6,
      };
      break;
    case "assets":
      colProps = {
        lg: 3,
        sm: 6,
      };
      break;
    default:
      colProps = {
        lg: 4,
        sm: 6,
      };
  }

  if (useOnAuthorPage) {
    colProps = {
      lg: 4,
      md: 6,
    };
  }

  return (
    <div
      className={classNames(
        "filter-dropdown",
        { [`filter-dropdown--${variant}`]: variant },
      )}
    >
      {headline && (
        <div className="filter-dropdown__headline">
          {headline} ({children.length})
        </div>
      )}
      {children.length > 0 && !hideFilter && (
        <>
          <div className="d-lg-flex">
            <LinkButton
              className="filter-dropdown__filter-button"
              onClick={() => {
                setShowDropdown((prevState) => !prevState);
              }}
            >
              <IconFilter24 /><FormattedMessage id="filter.filter" />
            </LinkButton>
            {filterCriteriaState.length > 0 && (
              <div className="d-flex flex-wrap">
                {filterCriteriaState.map((tag) => (
                  <FilterTag
                    key={`filterTag${tag}`}
                    onClick={() => {
                      setFilterCriterion(tag);
                      setFilterCriteriaState(
                        filterCriteriaState.filter(
                          (filterLabel) => filterLabel !== tag,
                        ),
                      );
                    }}
                    variant={variant === "events" ? variant : "primary"}
                  >
                    {tag}
                  </FilterTag>
                ))}
              </div>
            )}
          </div>
          <Row className="position-relative">
            <Col md={6} xl={4} className={classNames("filter-dropdown__menu", { "d-none": !showDropdown })}>
              <div>
                <div className="filter-dropdown__menu-headline">
                  <FormattedMessage
                    id="filter.categories_selected"
                    values={{ count: filterCriteria.length }}
                  />
                </div>
                <div className="filter-dropdown__label-container">
                  {labels.map((label) => (
                    <div
                      className={classNames(
                        "filter-dropdown__label",
                        { active: filterCriteria.indexOf(label) !== -1 },
                      )}
                      key={`filterDropdownLabel${label}`}
                      onClick={() => {
                        setFilterCriterion(label);
                      }}
                      role="presentation"
                    >
                      {label} {
                      (filterCriteria.indexOf(label) !== -1)
                        ? <IconSelect24 />
                        : <IconSelectEmpty24 className="text-grey-light" />
                    }
                    </div>
                  ))}
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <Button
                    onClick={() => applyFilter()}
                    size="sm"
                    variant={variant === "events" ? variant : "primary"}
                  >
                    <FormattedMessage id="filter.apply_filter" />
                  </Button>
                  {filterCriteria.length > 0 ? (
                    <LinkButton
                      onClick={() => {
                        setFilterCriteria([]);
                      }}
                      variant="normal"
                    >
                      <FormattedMessage id="filter.reset" />
                    </LinkButton>
                  ) : (
                    <LinkButton
                      onClick={() => {
                        setShowDropdown(false);
                      }}
                      variant="normal"
                    >
                      <FormattedMessage id="filter.cancel" />
                    </LinkButton>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}

      <Row className="filter-dropdown__article-container">
        {articles.length > 0
          ? articles.map((child, index) => (
            (index < numberOfShowArticle) && (
              (index === 0 && child.props.highlight)
                ? (
                  <Col
                    key={`filterArticleHighlight${child.props.href}`}
                    xs={12}
                  >
                    {child}
                  </Col>
                )
                : (
                  <Col
                    key={`filterArticle${child.props.href}`}
                    {...colProps}
                  >
                    {child}
                  </Col>
                )
            )
          ))
          : <Col xs={12}>{emptyContentWall}</Col>}
      </Row>

      {(articles.length > numberOfArticle || (variant === "events" && viewPastEventsLink)) && (
        <div className="filter-dropdown__button-container">
          {articles.length > numberOfArticle && (numberOfShowArticle < articles.length ? (
            <IconButton
              variant="none"
              className={classNames(
                "filter-dropdown__pagination",
                { [`filter-dropdown__pagination--${variant}`]: variant },
              )}
              onClick={() => {
                setNumberOfShowArticle((prevState) => prevState + numberOfArticle);
              }}
            >
              <FormattedMessage id="filter.show_more" /> <IconAccordionExpand18 />
            </IconButton>
          ) : (
            <IconButton
              variant="none"
              className={classNames(
                "filter-dropdown__pagination",
                { [`filter-dropdown__pagination--${variant}`]: variant },
              )}
              onClick={() => setDefaultItemsLayout()}
            >
              <FormattedMessage id="filter.show_less" /> <IconAccordionMinimize18 />
            </IconButton>
          ))}

          {variant === "events" && viewPastEventsLink && (
            <LinkButton className="filter-dropdown__view-past-events-button ml-md-auto" href={viewPastEventsLink}>
              <FormattedMessage id="filter.view_past_events" />
              <IconCalendarArchive24 />
            </LinkButton>
          )}
        </div>
      )}
    </div>
  );
};

FilterDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  emptyContentWall: PropTypes.oneOfType([PropTypes.object]),
  headline: PropTypes.string,
  hideFilter: PropTypes.bool,
  useOnAuthorPage: PropTypes.bool,
  variant: PropTypes.oneOf(["events", "article-teaser", "assets", "learn-area"]),
  viewPastEventsLink: PropTypes.string,
};

FilterDropdown.defaultProps = {
  emptyContentWall: null,
  headline: null,
  hideFilter: false,
  useOnAuthorPage: false,
  variant: null,
  viewPastEventsLink: null,
};

export default FilterDropdown;
