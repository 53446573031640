import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemRegistrationStep from "./kontentItemRegistrationStep";
import RegistrationProcess from "../components/registrationProcess/registrationProcess";

const KontentItemRegistrationProcessSection = ({ data }) => {
  const registrationSections = data.value ? data.value[0] : data;

  if (!registrationSections || !registrationSections.elements) {
    return null;
  }

  const section1Items = [];
  registrationSections.elements.process_step_cards_section_1.value.forEach((step) => {
    section1Items.push(<KontentItemRegistrationStep data={step} />);
  });

  const section2Items = [];
  registrationSections.elements.process_step_cards_section_2.value.forEach((step) => {
    section2Items.push(<KontentItemRegistrationStep data={step} />);
  });

  return (
    <RegistrationProcess
      sections={[
        {
          items: section1Items,
          label: registrationSections.elements.label_section_1.value,
        },
        {
          items: section2Items,
          label: registrationSections.elements.label_section_2.value,
        },
      ]}
      title={registrationSections.elements.title.value}
    />
  );
};

export const query = graphql`
  fragment KontentItemRegistrationProcessSection on kontent_item_registration_process_section {
    id
    system {
      codename
    }
    elements {
      label_section_1 {
        value
      }
      label_section_2 {
        value
      }
      title {
        value
      }
      process_step_cards_section_1 {
        value {
          ...KontentItemRegistrationStep
        }
      }
      process_step_cards_section_2 {
        value {
          ...KontentItemRegistrationStep
        }
      }
    }
  }
`;

KontentItemRegistrationProcessSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemRegistrationProcessSection;
