import React from "react";

import classNames from "classnames";
import { Field, useFormikContext } from "formik";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import sanitizeHtml from "sanitize-html";

import { IconError18 } from "../../../icons";

import "./formField.scss";

const FormField = (props) => {
  const {
    id,
    info,
    type,
    maxLength,
    errors,
    touched,
    meta,
    label,
    onBlur,
    onChange,
    validateField,
    onKeyPress,
    placeholder,
    values,
    warning,
    required,
    warningUnderSection,
    tooltip,
    additionalInvalidCondition,
    rows,
    as,
  } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={id} validate={validateField}>
      {() => (
        <Form.Group
          controlId={id}
          className={classNames(
            { required: required || meta?.requiredFields?.includes(id) },
          )}
        >
          <InputGroup className={classNames({ "append warning": warning[id] })}>
            <Form.Control
              name={id}
              type={type}
              placeholder={placeholder}
              value={values?.[id]}
              maxLength={maxLength}
              as={as || "input"}
              onBlur={(event) => {
                onBlur(event);
                const { name } = event.target;

                if (typeof values?.[name] === "string") {
                  setFieldValue(name, values?.[name]?.trim() || "");
                } else if (typeof values?.[name] === "number" || type === "number") {
                  setFieldValue(name, values?.[name]);
                } else {
                  setFieldValue(name, values?.[name] || "");
                }
              }}
              onKeyPress={onKeyPress}
              onChange={onChange}
              isInvalid={(errors[id] && touched[id])}
              tooltip={tooltip}
              rows={rows}
            />
            <Form.Label>{label}</Form.Label>
            {warning[id] && (
              <InputGroup.Append>
                <InputGroup.Text>
                  <IconError18 />
                </InputGroup.Text>
              </InputGroup.Append>
            )}
            {((errors[id] && touched[id]) || !required) && (
              <Form.Text
                className={classNames({ "invalid-feedback": (errors[id] && touched[id]) || additionalInvalidCondition })}
              >
                {errors[id] && touched[id]
                  ? <div><IconError18 />{errors[id]}</div>
                  : <>{!required && <FormattedMessage id="registration.optional" />}</>}
              </Form.Text>
            )}
            {info && (
              <Form.Text
                className="text-primary typo-small-label"
              >
                {parse(sanitizeHtml(info, {
                  allowedAttributes: {
                    a: ["href", "name", "target", "class", "rel", "title"],
                  },
                  allowedTags: ["a"],
                }))}
              </Form.Text>
            )}
          </InputGroup>
          {warningUnderSection && warning[id]
            && (
              <div className="warning-info pt-2">
                <IconError18 />
                <div>
                  <FormattedMessage id="update_address.same_value_warning" />&nbsp;
                  {Object.keys(warning).map((item, index) => (
                    <>
                      <div className="warning-field">{warning[item]}</div>
                      {index < (Object.keys(warning).length - 1) && ", "}
                    </>
                  ))}
                </div>
              </div>
            )}
        </Form.Group>
      )}
    </Field>
  );
};

FormField.propTypes = {
  additionalInvalidCondition: PropTypes.bool,
  as: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  info: PropTypes.string,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  meta: PropTypes.oneOfType([PropTypes.object]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  tooltip: PropTypes.bool,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  type: PropTypes.string.isRequired,
  validateField: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  warning: PropTypes.oneOfType([PropTypes.object]),
  warningUnderSection: PropTypes.bool,
};

FormField.defaultProps = {
  additionalInvalidCondition: false,
  as: "",
  info: "",
  maxLength: Number.MAX_SAFE_INTEGER,
  meta: {},
  onKeyPress: () => {},
  placeholder: undefined,
  required: false,
  rows: null,
  tooltip: false,
  validateField: () => {},
  warning: {},
  warningUnderSection: false,
};

export default FormField;
