import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconFurther24 } from "../../icons";
import BackgroundImage from "../backgroundImage/backgroundImage";
import LinkButton from "../button/linkButton";
import NavigationLink from "../navigationLink/navigationLink";

import "./targetGroupImage.scss";

const TargetGroupImage = (props) => {
  const {
    title,
    url,
    backgroundImageStyle,
    style,
  } = props;

  return (
    <NavigationLink className="no-style target-group-image" style={style} href={url}>
      <BackgroundImage
        className="target-group-image__image"
        backgroundImageStyle={backgroundImageStyle}
      />
      <div className="target-group-image__content">
        <div className="target-group-image__title">{title}</div>
        <LinkButton href={url} className="text-white">
          <FormattedMessage id="targetgroupimage.learn_more" />
          <IconFurther24 />
        </LinkButton>
      </div>
    </NavigationLink>
  );
};

TargetGroupImage.propTypes = {
  backgroundImageStyle: PropTypes.oneOfType([PropTypes.object]).isRequired,
  style: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

TargetGroupImage.defaultProps = {
  style: {},
};

export default TargetGroupImage;
