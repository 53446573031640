import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./officeList.scss";

const OfficeList = ({ children, headline }) => (
  <div className="office-list">
    <h3>{headline}</h3>
    <Row>
      {React.Children.map(children, (child) => (
        <Col key={child.props.office} className="office-list__item" md={4}>{child}</Col>
      ))}
    </Row>
  </div>
);

OfficeList.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
};

export default OfficeList;
