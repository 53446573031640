import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemGlobalData from "./global/kontentItemGlobalData";
import FactSheetHeaderData from "../components/factSheetHeader/factSheetHeaderData";
import iconHelper from "../utils/iconHelper";

const KontentItemFactSheetHeaderData = ({ data }) => {
  const factSheet = data.value ? data.value[0] : data;

  if (!factSheet || !factSheet.elements) {
    return null;
  }

  return (
    <FactSheetHeaderData
      image={iconHelper(factSheet.elements.icon.value[0].codename)}
      data={KontentItemGlobalData({ data: JSON.parse(factSheet.elements.data_global.value) })}
      text={factSheet.elements.text.value}
    />
  );
};

export const query = graphql`
  fragment KontentItemFactSheetHeaderData on kontent_item_fact_sheet_header_data {
    id
    system {
      codename
    }
    elements {
      icon {
        value {
          codename
        }
      }
      text {
        value
      }
      data_global {
        value
      }
    }
  } 
`;

KontentItemFactSheetHeaderData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemFactSheetHeaderData;
