import React from "react";

import classNames from "classnames";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import Logo from "../logo";
import Navigation from "../navigation/main/navigation";

const isGroup = process.env.GATSBY_SITE === "group";
const isAsia = process.env.GATSBY_SITE === "asia";

const Header = ({ hideNavigation, headerLogo }) => {
  const intl = useIntl();

  return (
    <header className={classNames("header", { "h-auto": isAsia, "h-auto py-18": hideNavigation })}>
      {hideNavigation
        ? (
          <a href="/" title={intl.formatMessage({ id: "navigation.logo_title" })}>
            {headerLogo || <Logo breakpoint="lg" />}
          </a>
        )
        : <Navigation isAsia={isAsia} isGroup={isGroup} />}
    </header>
  );
};

Header.propTypes = {
  headerLogo: PropTypes.oneOfType([PropTypes.object]),
  hideNavigation: PropTypes.bool,
};

Header.defaultProps = {
  headerLogo: null,
  hideNavigation: false,
};

export default Header;
