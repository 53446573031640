import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Article from "../../components/highlightArticles/highlightArticle";
import KontentItemImage from "../kontentItemImage";

const HighlightArticle = ({ data }) => {
  const newsData = Array.isArray(data) ? data : data.value;

  const newsList = [];

  newsData.forEach((news) => {
    newsList.push(
      <Article
        image={(
          <KontentItemImage
            data={news.elements.hero_image}
            width={700}
            fit="crop"
            className="img-fluid"
            aspectRatio={3 / 4}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        )}
        headline={news.elements.headline.value}
        text={news.elements.lead_text.value}
        url={news.id}
      />,
    );
  });

  return React.Children.toArray(newsList);
};

export const query = graphql`
  fragment HighlightArticle on kontent_item_article_page_learn {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      lead_text {
        value
      }
    }
  }
`;

HighlightArticle.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default HighlightArticle;
