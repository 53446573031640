import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import PressHeroCarouselCards from "./press/pressHeroCarouselCards";
import HeroCarousel from "../components/heroCarousel/heroCarousel";

const KontentItemHeroCarouselPress = ({ data }) => {
  const heroCarouselPress = data.value ? data.value[0] : data;

  if (!heroCarouselPress || !heroCarouselPress.elements) {
    return null;
  }

  return (
    <HeroCarousel notOverwriteButton>
      {PressHeroCarouselCards({
        buttonText: heroCarouselPress.elements.button_text.value,
        data: heroCarouselPress.elements.carousel_press,
      })}
    </HeroCarousel>
  );
};

export const query = graphql`
  fragment KontentItemHeroCarouselPress on kontent_item_hero_carousel_press {
    id
    system {
      codename
    }
    elements {
      carousel_press {
        value {
          ...PressHeroCarouselCards
        }
      }
      button_text {
        value
      }
    }
  } 
`;

KontentItemHeroCarouselPress.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemHeroCarouselPress;
