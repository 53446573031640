import IoBaseApiSlice from "./ioBaseApiSlice";

export const IoDoiConfirmationApiSlice = IoBaseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    submitDoiConfirmation: build.mutation({
      query: ({ data }) => ({
        apiRequirements: ["session", "csrfToken"],
        data: {
          classification: "Restricted",
          payload: data,
        },
        method: "post",
        url: `${process.env.GATSBY_IO_HOST}/inq/${process.env.GATSBY_IO_DOI_CONFIRMATION}/doiconfirmation`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
  }),
});

export const {
  useSubmitDoiConfirmationMutation,
} = IoDoiConfirmationApiSlice;
