import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemLocationCard from "./kontentItemLocationCard";
import LocationCardList from "../components/locationCardList/locationCardList";

const KontentItemLocationCardsGroup = ({ data }) => {
  const locationCardsGroup = data.value ? data.value[0] : data;

  if (!locationCardsGroup || !locationCardsGroup.elements) {
    return null;
  }

  return (
    <LocationCardList headline={locationCardsGroup.elements.headline.value}>
      {locationCardsGroup.elements.locations.value.map((item) => (
        <KontentItemLocationCard
          key={`locationCard-${item.id}`}
          data={item}
        />
      ))}
    </LocationCardList>
  );
};

export const query = graphql`
  fragment KontentItemLocationCardsGroup on kontent_item_location_cards_group {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      locations {
        value {
          ...KontentItemLocationCard
        }
      }
    }
  }
`;

KontentItemLocationCardsGroup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLocationCardsGroup;
