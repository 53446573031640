import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import FileDownload from "../components/fileDownload/fileDownload";
import { IconPdf18, IconZip18 } from "../icons";

const KontentItemAsset = ({ data, usePlaceholder }) => {
  if (!data || !data.elements) {
    return null;
  }

  let fileIcon = (<IconPdf18 />);

  if (data.elements.media_type.value.find((el) => el.codename === "zip_archive")) {
    fileIcon = (<IconZip18 />);
  }

  const image = usePlaceholder ? <KontentItemImage fit="crop" width={400} imageOnlyWidth data={data.elements.image} aspectRatio={21 / 9} placeholderTyp="download" /> : null;

  return (
    <FileDownload
      title={data.elements.title.value} // todo: martin language switch
      size={data.elements.file_size.value}
      href={data.elements.asset_item.value.length > 0 ? data.elements.asset_item.value[0].url : ""}
      image={image}
      fileIcon={fileIcon}
    />
  );
};

export const query = graphql`
  fragment KontentItemAsset on kontent_item_asset {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      file_size {
        value
      }
      asset_item {
        value {
          url
        }
      }
      media_type {
        value {
          codename
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  } 
`;

KontentItemAsset.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  usePlaceholder: PropTypes.bool.isRequired,
};

export default KontentItemAsset;
