import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./factSheetHeader.scss";

const FactSheetHeader = ({ children, headline }) => (
  <div className="container--fullwidth fact-sheet-header">
    <Container fluid>
      <h1 className="fact-sheet-header__headline">
        {headline}
      </h1>
      <Row className="fact-sheet-header__items">
        {React.Children.toArray(children).map((child, index) => (
          <Col
            key={`fact-sheet-header__item${index.toString()}`}
            className="fact-sheet-header__item"
            xs={12}
            sm={6}
            lg={4}
          >
            {child}
          </Col>
        ))}
      </Row>
    </Container>
  </div>
);

FactSheetHeader.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
};

export default FactSheetHeader;
