import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Consent from "../../components/consent/consent";
import SocialMediaEmbed from "../../components/socialMediaEmbed/socialMediaEmbed";

const KontentItemEmbedInstagramPost = ({ data }) => {
  const instagramPost = data.value ? data.value[0] : data;

  if (
    !instagramPost
    || !instagramPost.elements
    || !instagramPost.elements.display_options.value.length > 0
  ) {
    return null;
  }

  return (
    <Consent type="social">
      <SocialMediaEmbed
        url={instagramPost.elements.post_url.value}
        showText={instagramPost.elements.display_options.value[0].codename}
      />
    </Consent>
  );
};

export const query = graphql`
  fragment KontentItemEmbedInstagramPost on kontent_item_embed_instagram_post {
    id
    elements {
      post_url {
        value
      }
      display_options {
        value {
          name
          codename
        }
      }
    }
  }
`;

KontentItemEmbedInstagramPost.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEmbedInstagramPost;
