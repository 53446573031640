import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ArticleClosure from "../../components/articleClosure/articleClosure";
import ArticleHeaderPressRelease from "../../components/articleHeader/articleHeaderPressRelease";
import LinkButton from "../../components/button/linkButton";
import InfoSection from "../../components/infoSection/infoSection";
import SEO from "../../components/seo";
import { IconAnchorRight18 } from "../../icons";
import mapKontentItem from "../../utils/mapKontentItem";
import getAuthorByCodename from "../author/getAuthorByCodename";
import backgroundImageHelper from "../image/backgroundImageHelper";
import KontentItemContact from "../kontentItemContact";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemArticlePress = ({ data, pageContext }) => {
  const headerImage = (data.page.elements.image.value.length > 0
    && data.page.elements.image.value[0].elements.image.value.length > 0)
    ? data.page.elements.image.value[0].elements.image.value
    : [];

  const author = getAuthorByCodename(
    data.page.elements.author_name.value.length > 0
    && data.page.elements.author_name.value[0].codename,
  );

  return (
    <MainLayout pageInfo={{ pageName: data.page.elements.headline.value }}>

      <SEO
        title={data.page.elements.metadata__meta_title.value}
        description={data.page.elements.metadata__meta_description.value}
        twitterTitle={data.page.elements.metadata__twitter_title.value}
        twitterSite={data.page.elements.metadata__twitter_site.value}
        twitterImage={data.page.elements.metadata__twitter_image.value.length > 0
          ? data.page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={data.page.elements.metadata__twitter_description.value}
        twitterCreator={data.page.elements.metadata__twitter_creator.value}
        ogTitle={data.page.elements.metadata__og_title.value}
        ogImage={data.page.elements.metadata__og_image.value.length > 0
          ? data.page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={data.page.elements.metadata__og_description.value}
        isHiddenForRobots={pageContext.seoNoIndex}
      />

      <SubNavigationGenerator id={data.page.id} />

      <BreadcrumbGenerator id={data.page.id} />

      <ArticleHeaderPressRelease
        title={data.page.elements.headline.value}
        description={data.page.elements.lead_text.value}
        backgroundImageStyle={backgroundImageHelper(data.page.elements.image)}
        infoSection={(
          <InfoSection
            hideLastUpdateLabel
            showTableOfContents={data.page.elements.table_of_content?.value[0]?.codename !== "disable"}
            author={author?.elements?.contact_item
              ? (
                <KontentItemContact
                  data={author?.elements?.contact_item}
                  contactHref={(
                    <LinkButton
                      variant="normal"
                      className="btn-light-registration"
                      href={author.id}
                    >
                      <FormattedMessage id="stories.further_articles" /> <IconAnchorRight18 />
                    </LinkButton>
                  )}
                  isAuthor
                />
              )
              : null}
            date={new Date(data.page.elements.publishdate.value)}
          />
        )}
      />

      <RichText data={data.page.elements.body} isArticle />

      <Row>
        <Col md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
          <ArticleClosure />
        </Col>
      </Row>

      {data.page.elements.page_sections.value.map(
        (item) => mapKontentItem(item, { pageId: data.page.id }),
      )}

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemArticlePress(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        table_of_content{
          value {
            name
            codename
          }
        }
        headline {
          value
        }
        lead_text {
          value
        }
        image {
          value {
            ...KontentItemImage
          }
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemRegistryBanner
            ...KontentItemRelatedPressArticles
            ...KontentItemStatementboxGroup
          }
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemStatementboxGroup
            ...KontentItemEmbedExternal
          }
        }
        author_name {
          value {
            codename
          }
        }
        publishdate {
          value(formatString: "YYYY-MM-DD[T]HH:mm:ss")
        }
      }
    }
  }
`;

KontentItemArticlePress.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemArticlePress;
