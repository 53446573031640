import React from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { number, object, string } from "yup";

import { setSetupData } from "../../../features/registrationSlice";
import { IconFurther18 } from "../../../icons";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import IconButton from "../../button/iconButton";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../../registrationDialog/registrationDataHelper";

const VirtualDriveSetupModalDriveTypeInformation = ({ next }) => {
  const setupData = useSelector((state) => state.registration.setupData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const transformUrl = (url) => {
    let modifiedUrl = url;

    if (modifiedUrl !== "" && !modifiedUrl.startsWith("https://")) {
      if (modifiedUrl.startsWith("http://")) {
        modifiedUrl = modifiedUrl.replace("http://", "https://");
      } else {
        modifiedUrl = `https://${modifiedUrl}`;
      }
    }

    return modifiedUrl;
  };

  let validationSchema = null;
  let labelOrganizationName = "";
  let labelNumberPerson = "";
  let headline = "";

  const isPublicDriveType = setupData.driveTypeValue === "Public drive" || setupData.driveTypeValue === "Own idea";
  const isCompanyDriveType = setupData.driveTypeValue === "Company drive";
  const isClubDriveType = setupData.driveTypeValue === "Club drive";
  const isUniversityDriveType = setupData.driveTypeValue === "University drive";
  const isSchoolDriveType = setupData.driveTypeValue === "School drive";

  const notValidUrl = intl.formatMessage({ id: "virtual_drive_setup.not_valid_url" });

  if (isPublicDriveType) {
    validationSchema = object().shape({
      facebook: string()
        .transform((currentValue) => transformUrl(currentValue))
        .url(notValidUrl),
      instagram: string()
        .transform((currentValue) => transformUrl(currentValue))
        .url(notValidUrl),
      twitter: string()
        .transform((currentValue) => transformUrl(currentValue))
        .url(notValidUrl),
    });

    headline = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_public_title" });
  } else {
    const requiredMessage = intl.formatMessage({ id: "form.required" });
    const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

    validationSchema = object().shape({
      facebook: string()
        .transform((currentValue) => transformUrl(currentValue))
        .url(notValidUrl),
      instagram: string()
        .transform((currentValue) => transformUrl(currentValue))
        .url(notValidUrl),
      location: string().required(requiredMessage)
        .trim()
        .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
      numberPersons: number().typeError(intl.formatMessage({ id: "form.error.only_digits" })),
      organizationName: string().required(requiredMessage)
        .trim()
        .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
      twitter: string()
        .transform((currentValue) => transformUrl(currentValue))
        .url(notValidUrl),
      website: string()
        .transform((currentValue) => transformUrl(currentValue))
        .url(notValidUrl),
    });

    if (isCompanyDriveType) {
      labelOrganizationName = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_company_name" });
      headline = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_company_title" });
      labelNumberPerson = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_company_number_employees" });
    }

    if (isClubDriveType) {
      labelOrganizationName = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_club_name" });
      headline = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_club_title" });
      labelNumberPerson = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_club_number_members" });
    }

    if (isUniversityDriveType) {
      labelOrganizationName = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_university_name" });
      headline = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_university_title" });
      labelNumberPerson = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_university_number_students" });
    }

    if (isSchoolDriveType) {
      labelOrganizationName = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_school_name" });
      headline = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_school_title" });
      labelNumberPerson = intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_university_number_pupils" });
    }
  }

  return (
    <>
      <Row>
        <Col sm={10}>
          <h3>
            {headline}
          </h3>
        </Col>
      </Row>
      <Formik
        initialValues={setupData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setSetupData({
              facebook: transformUrl(values.facebook),
              instagram: transformUrl(values.instagram),
              twitter: transformUrl(values.twitter),

              ...!isPublicDriveType ? {
                location: values.location,
                numberPersons: values.numberPersons ? parseInt(values.numberPersons, 10) : "",
                organizationName: values.organizationName,
                socialMedia: values.socialMedia,
                website: transformUrl(values.website),
              } : {},
            }),
          );

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              {!isPublicDriveType && (
                <>
                  <Col xs={12}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="organizationName"
                      label={labelOrganizationName}
                      type="text"
                      maxLength={40}
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="location"
                      label={intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_location" })}
                      type="text"
                      maxLength={40}
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="numberPersons"
                      label={labelNumberPerson}
                      type="text"
                      maxLength={40}
                      touched={touched}
                      errors={errors}
                      values={values}
                    />
                  </Col>
                  <Col xs={12}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="website"
                      label={intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_website" })}
                      type="text"
                      maxLength={40}
                      touched={touched}
                      errors={errors}
                      values={values}
                    />
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        id="socialMedia"
                        name="socialMedia"
                        className="checkbox-body1"
                        checked={values.socialMedia}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={(errors.socialMedia && touched.socialMedia)}
                        label={intl.formatMessage({ id: "virtual_drive_setup.we_are_active_on_social_media" })}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
              {(isPublicDriveType || values.socialMedia) && (
                <>
                  <Col xs={12}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="facebook"
                      label={intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_facebook" })}
                      type="text"
                      maxLength={40}
                      touched={touched}
                      errors={errors}
                      values={values}
                    />
                  </Col>
                  <Col xs={12}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="instagram"
                      label={intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_instagram" })}
                      type="text"
                      maxLength={40}
                      touched={touched}
                      errors={errors}
                      values={values}
                    />
                  </Col>
                  <Col xs={12}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="twitter"
                      label={intl.formatMessage({ id: "virtual_drive_setup.drive_type_information_twitter" })}
                      type="text"
                      maxLength={40}
                      touched={touched}
                      errors={errors}
                      values={values}
                    />
                  </Col>
                </>
              )}
            </Row>

            <ScrollToFieldError />

            <Form.Group className="form-group-button">
              <IconButton type="submit" disabled={isSubmitting}>
                {
                  isPublicDriveType && values.facebook === ""
                  && values.instagram === "" && values.twitter === ""
                    ? <FormattedMessage id="virtual_drive_setup.skip" />
                    : <FormattedMessage id="virtual_drive_setup.next" />
                }
                <IconFurther18 />
              </IconButton>
            </Form.Group>

            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

VirtualDriveSetupModalDriveTypeInformation.stepName = "virtual_drive_type_information";

VirtualDriveSetupModalDriveTypeInformation.propTypes = {
  next: PropTypes.func.isRequired,
};

export default VirtualDriveSetupModalDriveTypeInformation;
