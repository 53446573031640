import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import IconButton from "../../components/button/iconButton";
import HeroCarouselCard from "../../components/heroCarousel/heroCarouselCard";
import { IconFurther18 } from "../../icons";
import backgroundImageHelper from "../image/backgroundImageHelper";

const NewsHeroCarouselCards = ({ data, buttonText }) => {
  const newsData = Array.isArray(data) ? data : data.value;

  const news = [];

  newsData.forEach((newsItem) => {
    news.push(
      <HeroCarouselCard
        backgroundImageStyle={backgroundImageHelper(newsItem.elements.hero_image)}
        button={(
          <IconButton variant="inverted-light" href={newsItem.id}>
            {buttonText} <IconFurther18 />
          </IconButton>
        )}
        menuTitle={newsItem.elements.headline.value}
        category={newsItem.elements.news_category.value.length > 0
          ? newsItem.elements.news_category.value[0].name
          : null}
        date={new Date(newsItem.elements.publishdate.value)}
      >
        {newsItem.elements.headline.value}
      </HeroCarouselCard>,
    );
  });

  return React.Children.toArray(news);
};

export const query = graphql`
  fragment NewsHeroCarouselCards on kontent_item_article_page_news {
    id
    system {
      codename
    }
    elements {
      headline {
          value
      }
      publishdate {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      news_category {
        value {
          name
        }
      }
    }
  }
  
  fragment NewsHeroCarouselCardsMigrated on kontent_item_article_migration {
    id
    system {
      codename
    }
    elements {
      headline {
          value
      }
      publishdate {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      news_category {
        value {
          name
        }
      }
    }
  }
`;

NewsHeroCarouselCards.propTypes = {
  buttonText: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default NewsHeroCarouselCards;
