export { default as SectionIconFaq } from "./a-sectionicon-faq.svg";
export { default as IllustrationBloodCancer } from "./a-bloodcancer-big.svg";
export { default as IllustrationAboutStemcellDonation } from "./a-aboutstemcelldonation-small.svg";
export { default as IllustrationError } from "./a-error.svg";
export { default as IllustrationCheck } from "./a-check.svg";
export { default as DkmsNotaglineWhiteS } from "./a-logo-dkms-notagline-white-s.svg";

// HeaderIcons
export { default as HeaderIcon404 } from "./a-headericon-404.svg";
export { default as HeaderIcon500 } from "./a-headericon-500.svg";
export { default as HeaderIconAccountNoAccess } from "./a-headericon-accountnoaccess.svg";
export { default as HeaderIconCollaboration } from "./a-headericon-collaboration.svg";
export { default as HeaderIconContact } from "./a-headericon-contact.svg";
export { default as HeaderIconContactBlue } from "./a-headericon-contact-blue.svg";
export { default as HeaderIconDataprotection } from "./a-headericon-dataprotection.svg";
export { default as HeaderIconDepartments } from "./a-headericon-departments.svg";
export { default as HeaderIconDkmsInternational } from "./a-headericon-dkmsinternational.svg";
export { default as HeaderIconDkmsServices } from "./a-headericon-dkmsservices.svg";
export { default as HeaderIconError } from "./a-headericon-error.svg";
export { default as HeaderIconEvents } from "./a-headericon-events.svg";
export { default as HeaderIconFactsAndFigures } from "./a-headericon-factsandfigures.svg";
export { default as HeaderIconFaq } from "./a-headericon-faq.svg";
export { default as HeaderIconForms } from "./a-headericon-forms.svg";
export { default as HeaderIconFormsBlue } from "./a-headericon-forms-blue.svg";
export { default as HeaderIconGlossary } from "./a-headericon-glossary.svg";
export { default as HeaderIconPastEvents } from "./a-headericon-pastevents.svg";
export { default as HeaderIconPricing } from "./a-headericon-pricing.svg";
export { default as HeaderIconPublications } from "./a-headericon-publications.svg";
export { default as HeaderIconResearchFunding } from "./a-headericon-researchfunding.svg";
export { default as HeaderIconResearchScope } from "./a-headericon-researchscope.svg";
export { default as HeaderIconResources } from "./a-headericon-resources.svg";
export { default as HeaderIconScienceAward } from "./a-headericon-scienceaward.svg";
export { default as HeaderIconScientificPoster } from "./a-headericon-scientificposter.svg";
export { default as HeaderIconScientificStudies } from "./a-headericon-scientificstudies.svg";
export { default as HeaderIconStandards } from "./a-headericon-standards.svg";
export { default as HeaderIconSuccess } from "./a-headericon-success.svg";
export { default as HeaderIconSupportPrograms } from "./a-headericon-supportprograms.svg";
export { default as HeaderIconThankYouDonation } from "./a-headericon-thankyou-donation.svg";
export { default as HeaderIconThankYouMatch } from "./a-headericon-thankyou-match.svg";
export { default as HeaderIconThankYouThumbs } from "./a-headericon-thankyou-thumb.svg";
export { default as HeaderIconThankYouForm } from "./a-headericon-thankyouform.svg";
export { default as HeaderIconTools } from "./a-headericon-tools.svg";
export { default as HeaderIconVirtualDrives } from "./a-headericon-virtualdrives.svg";
