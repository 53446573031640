import React, { useState } from "react";

import classNames from "classnames";
import Cleave from "cleave.js/react";
import isValid from "date-fns/isValid";
import PropTypes from "prop-types";

import { IconCalendar18 } from "../../icons";
import { format, localeFormats, parse } from "../../utils/dateHelper";

import "./dateInput.scss";

const DateInput = (props) => {
  const {
    initialValue,
    placeholder,
    datepicker,
    isInvalid,
    className,
    onChange,
    onBlur,
    onKeyPress,
    onPaste,
    ...inputProps
  } = props;

  const [value, setValue] = useState(initialValue && isValid(initialValue) ? format(initialValue) : "");

  const {
    dateParts,
    delimiter,
  } = localeFormats();

  const cleaveDatePattern = [];
  dateParts.forEach((localeDatePart) => {
    if (localeDatePart.substring(0, 1).toLowerCase() === "y") {
      cleaveDatePattern.push("Y");

      return;
    }

    cleaveDatePattern.push(localeDatePart.substring(0, 1).toLowerCase());
  });

  const reformatDate = (date) => {
    const tmpDateParts = date.split(delimiter);

    tmpDateParts.forEach((datePart, index) => {
      if (datePart.length === 1 && datePart !== 0) {
        tmpDateParts[index] = `0${datePart}`;
      }
    });

    // year
    if (typeof tmpDateParts[2] !== "undefined" && tmpDateParts[2].length === 2) {
      const currentYear = parseInt(new Date().getFullYear().toString().substring(2), 10);

      if (parseInt(tmpDateParts[2], 10) < currentYear) {
        tmpDateParts[2] = `20${tmpDateParts[2]}`;
      } else {
        tmpDateParts[2] = `19${tmpDateParts[2]}`;
      }
    }

    const tmpValue = tmpDateParts.join(delimiter);

    if (tmpValue.length === 10) {
      onChange(parse(tmpValue));
    } else {
      onChange(null);
    }

    return tmpValue;
  };

  return (
    <div
      className={classNames(
        "date-input",
        {
          "date-input--empty": value.length === 0,
          "date-input--invalid": isInvalid,
        },
        className,
      )}
    >
      <Cleave
        className="form-control"
        placeholder={placeholder}
        value={value}
        options={{
          date: true,
          datePattern: cleaveDatePattern,
          delimiter,
        }}
        onChange={(event) => {
          setValue(event.target.value);

          if (event.target.value.length === 10) {
            onChange(parse(event.target.value));
          } else {
            onChange(null);
          }
        }}
        onBlur={(event) => {
          setValue(reformatDate(value));

          onBlur(event);
        }}
        onPaste={(event) => {
          const paste = (event.clipboardData || window.clipboardData).getData("text");
          setValue(reformatDate(paste));

          onPaste(event);
        }}
        onKeyPress={(event) => {
          if ((event.which || event.keyCode) === delimiter.charCodeAt(0)) {
            setValue(reformatDate(value));
          }

          onKeyPress(event);
        }}
        {...inputProps}
      />
      {datepicker && (
        <IconCalendar18 className="m-12" />
      )}
    </div>
  );
};

DateInput.propTypes = {
  className: PropTypes.string,
  datepicker: PropTypes.bool,
  initialValue: PropTypes.instanceOf(Date),
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onPaste: PropTypes.func,
  placeholder: PropTypes.string,
};

DateInput.defaultProps = {
  className: "",
  datepicker: false,
  initialValue: null,
  isInvalid: false,
  onBlur: () => {},
  onChange: () => {},
  onKeyPress: () => {},
  onPaste: () => {},
  placeholder: null,
};

export default DateInput;
