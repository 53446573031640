import React, { useState } from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { useSubmitIoVirtualDriveMutation } from "../../../features/io/ioVirtualDriveApiSlice";
import { setNavigationHeaderEnabled } from "../../../features/registrationSlice";
import errorHelper from "../../../hooks/errorHelper";
import useURNParams from "../../../hooks/useURNParams";
import { IllustrationError } from "../../../images";
import IconButton from "../../button/iconButton";
import NavigationLink from "../../navigationLink/navigationLink";
import StatusWindow from "../../statusWindow/statusWindow";
import SetupDataHelper from "../setupDataHelper";

const VirtualDriveSetupError = ({ content, error }) => {
  const setupData = useSelector((state) => state.registration.setupData);
  const dispatch = useDispatch();

  const urnParams = useURNParams();

  const [submitIoVirtualDrive, submitIoVirtualDriveResult] = useSubmitIoVirtualDriveMutation({ fixedCacheKey: "submitIoVirtualDriveCacheKey" });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const intl = useIntl();

  dispatch(
    setNavigationHeaderEnabled(false),
  );

  const errorData = errorHelper(error);

  const contactUsLink = (
    <NavigationLink href={content.contactLink}>
      {errorData.status > 400
        ? <FormattedMessage id="io.error.alternative_contact_us" defaultMessage={intl.formatMessage({ id: "io.error.contact_us" })} />
        : <FormattedMessage id="io.error.contact_us" />}
    </NavigationLink>
  );

  return (
    <>
      <Row className="pt-30">
        <Col xs={12} lg={{ offset: 2, span: 8 }} className="text-center">
          <IllustrationError />
          <h3 className="mt-24">
            {error.status >= 400
              ? <FormattedMessage id="io.error.title" />
              : <FormattedMessage id="io.error.default_title" />}
          </h3>
          <p className="text-black">
            {error.status >= 400
              ? (
                <FormattedMessage
                  id="io.error.text"
                  values={{ link: contactUsLink }}
                />
              )
              : (
                <FormattedMessage
                  id="io.error.default_text"
                  values={{ link: contactUsLink }}
                />
              )}
          </p>
          <div>
            {setupData && !(errorData.status < 500 && errorData.status >= 400) && (
              <IconButton
                onClick={() => {
                  submitIoVirtualDrive({
                    data: SetupDataHelper.preparePayload(setupData),
                    urns: urnParams,
                  });

                  setIsButtonDisabled(true);

                  setTimeout(() => {
                    setIsButtonDisabled(false);
                  }, 10000);
                }}
                loading={submitIoVirtualDriveResult.isLoading}
                disabled={submitIoVirtualDriveResult.isLoading || isButtonDisabled}
                className="mt-24"
              >
                <FormattedMessage values={{ countdown: 0 }} id="io.error.retry" />
              </IconButton>
            )}
          </div>
          <div className="pt-15">
            {error && errorData.status >= 400 && errorData.status < 600 && (
              <StatusWindow errorData={errorData} />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

VirtualDriveSetupError.getAnswer = () => false;

VirtualDriveSetupError.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default VirtualDriveSetupError;
