import React, { useState } from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { setError, setRegistrationData } from "../../../features/registrationSlice";
import {
  IconAfrica,
  IconChile,
  IconGermany,
  IconIndia,
  IconPoland,
  IconUk,
  IconUsa,
} from "../../../icons";
import {
  COUNTRY_AFRICA_URL,
  COUNTRY_CHILE_URL,
  COUNTRY_GERMANY_URL,
  COUNTRY_INDIA_URL,
  COUNTRY_POLAND_URL,
  COUNTRY_UK_URL,
  COUNTRY_USA_URL,
} from "../../../utils/constants";
import DataLayer from "../../../utils/dataLayer";
import Button from "../../button/button";
import LinkButton from "../../button/linkButton";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepEligibilityCountry = ({ content, next }) => {
  const error = useSelector((state) => state.registration.error);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = (eligible) => {
    setIsSubmitting(true);

    dispatch(
      setRegistrationData({ eligible }),
    );

    if (!eligible) {
      setIsSubmitting(false);

      dispatch(
        setError(true),
      );
    } else {
      next();
    }
  };

  const site = process.env.GATSBY_SITE.toLowerCase();

  if (error) {
    const countries = [
      {
        icon: <IconGermany />,
        id: "de",
        name: intl.formatMessage({ id: "country.germany" }),
        url: COUNTRY_GERMANY_URL,
      },
      {
        icon: <IconIndia />,
        id: "in",
        name: intl.formatMessage({ id: "country.india" }),
        url: COUNTRY_INDIA_URL,
      },
      {
        icon: <IconChile />,
        id: "cl",
        name: intl.formatMessage({ id: "country.chile" }),
        url: COUNTRY_CHILE_URL,
      },
      {
        icon: <IconPoland />,
        id: "pl",
        name: intl.formatMessage({ id: "country.poland" }),
        url: COUNTRY_POLAND_URL,
      },
      {
        icon: <IconUsa />,
        id: "us",
        name: intl.formatMessage({ id: "country.usa" }),
        url: COUNTRY_USA_URL,
      },
      {
        icon: <IconUk />,
        id: "gb",
        name: intl.formatMessage({ id: "country.uk" }),
        url: COUNTRY_UK_URL,
      },
      {
        icon: <IconAfrica />,
        id: "za",
        name: intl.formatMessage({ id: "country.africa" }),
        url: COUNTRY_AFRICA_URL,
      },
    ];

    return (
      <>
        <RegistrationDialogHeadline
          headline="registration.country.error.title"
        />

        <Row>
          <Col sm={10}>
            <p>
              <FormattedMessage id="registration.country.error.text2" />
            </p>

            {content.donationBox}

            {!content.donationBox
              && content.errorButtonPrimary}

            {content.errorButtonSecondary}

            <p>
              <FormattedMessage id="registration.country.error.text1" />
            </p>

            <p>
              {countries.filter((country) => country.id !== site).map((country) => (
                <LinkButton variant="normal" href={country.url} title={country.name} className="mr-30 mb-18" key={country.id}>
                  {country.name}{country.icon}
                </LinkButton>
              ))}
            </p>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.country.title"
      />
      <Row>
        <Col xs={12}>
          <Form.Group className="form-group-button">
            <Button
              onClick={() => {
                DataLayer.pushEvent("pp_registration_step_eligibility_country", { event_value: "success" });
                submit(true);
              }}
              className="step-next"
              disabled={isSubmitting}
            >
              <FormattedMessage id="registration.country.button_yes" />
            </Button>
          </Form.Group>
          <Form.Group className="form-group-button">
            <Button
              onClick={() => {
                DataLayer.pushEvent("pp_registration_step_eligibility_country", { event_value: "fail" });
                submit(false);
              }}
              className="step-next"
              disabled={isSubmitting}
            >
              <FormattedMessage id="registration.country.button_no" />
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

RegistrationDialogStepEligibilityCountry.stepName = "eligibility_country";

RegistrationDialogStepEligibilityCountry.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepEligibilityCountry;
