import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import LearnCard from "./learn/learnCard";
import CardSlider from "../components/cardSlider/cardSlider";

const KontentItemRelatedLearnArticles = ({ data }) => {
  const latestNewsSection = data.value ? data.value[0] : data;

  if (!latestNewsSection || !latestNewsSection.elements) {
    return null;
  }

  return (
    <CardSlider
      header={latestNewsSection.elements.title.value}
      related
    >
      {LearnCard({ data: latestNewsSection.elements.related_articles.value, variant: "learn-topic" })}
    </CardSlider>
  );
};

export const query = graphql`
  fragment KontentItemRelatedLearnArticles on kontent_item_related_learn_articles {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      related_articles {
        value {
          ...LearnCard
        }
      }
    }
  } 
`;

KontentItemRelatedLearnArticles.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemRelatedLearnArticles;
