import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { IconError18 } from "../../../icons";

import "./textareaFormField.scss";

const TextareaFormField = (props) => {
  const {
    id,
    maxLength,
    errors,
    touched,
    values,
    meta,
    label,
    onBlur,
    onChange,
    placeholder,
  } = props;

  return (
    <>
      <Form.Group
        controlId={id}
        className={classNames(
          { required: meta.requiredFields.includes(id) },
        )}
      >
        <Form.Control
          as="textarea"
          label={label}
          placeholder={placeholder}
          rows={4}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          isInvalid={(errors[id] && touched[id])}
        />
        <Form.Label>{label}</Form.Label>
        {maxLength && !errors[id] && (
          <Form.Text className="hint">
            {`${values[id]?.length}/${maxLength}`}
          </Form.Text>
        )}
        {errors[id] && touched[id] && (
          <Form.Text className="invalid-feedback">
            <IconError18 />{errors[id]}
          </Form.Text>
        )}
      </Form.Group>
    </>
  );
};

TextareaFormField.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  meta: PropTypes.oneOfType([PropTypes.object]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

TextareaFormField.defaultProps = {
  maxLength: Number.MAX_SAFE_INTEGER,
  meta: null,
  placeholder: undefined,
};

export default TextareaFormField;
