import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { bool, object } from "yup";

import { useGetPaymentLinkMutation } from "../../../features/io/ioMoneyDonationApiSlice";
import useURNParams from "../../../hooks/useURNParams";
import { IconCreditCard24, IconPrivate18 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import IconButton from "../../button/iconButton";
import Checkbox from "../../dynamicForm/checkbox/checkbox";
import IconText from "../../iconText/iconText";
import NavigationLink from "../../navigationLink/navigationLink";
import RegistrationDialogHeadline from "../../registrationDialog/registrationDialogHeadline";
import PaymentDataHelper from "../paymentDataHelper";

const PaymentDialogStepOverviewUk = ({ content, next }) => {
  const paymentData = useSelector((state) => state.registration.paymentData);

  const intl = useIntl();

  const urnParams = useURNParams();

  const [getPaymentLink, paymentLinkResult] = useGetPaymentLinkMutation({ fixedCacheKey: "getPaymentLinkCacheKey" });

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    dataProtection: bool()
      .required(requiredMessage)
      .oneOf([true], requiredMessage),
  }), []);

  const {
    amount,
    apartment,
    careOfAddress,
    street,
    houseNo,
    postCode,
    city,
    emailAddress,
    firstname,
    lastname,
    interval,
    salutation,
  } = paymentData;

  return (
    <div className="payment-dialog-overview">
      <RegistrationDialogHeadline headline="payment_dialog.overview.title" />

      <Row className="mb-36">
        <Col lg={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="payment_dialog.donation" />
          </div>
          <div className="typo-body2">
            {`${intl.formatMessage({ id: "currency.amount" }, { amount })} ${interval !== "ONCE" ? intl.formatMessage({ id: `payment_dialog.${interval.toLowerCase()}` }).toLowerCase() : ""}`}
          </div>
        </Col>
        <Col lg={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="payment_dialog.contact_data" />
          </div>
          <div className="typo-body2">
            {salutation} {firstname} {lastname}<br /><br />
            {apartment && (
              <>{apartment}<br /></>
            )}
            {careOfAddress && (
              <>{careOfAddress}<br /></>
            )}
            {houseNo} {street}<br />
            {city} <br />
            {postCode}<br /><br />
            {emailAddress}<br />
          </div>
        </Col>
      </Row>

      <Formik
        initialValues={{
          dataProtection: false,
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          if (!paymentLinkResult.isSuccess) {
            getPaymentLink({
              data: PaymentDataHelper.preparePayload(
                paymentData,
                `${process.env.GATSBY_SITE_URL}${content.successPage}?amount=${paymentData.amount}`,
              ),
              urns: urnParams,
            });

            DataLayer.pushEvent("pp_donation_step_payment_summary", { event_value: "success" });

            next();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className="typo-small-label required-sign mb-9"><FormattedMessage id="payment_dialog.dataprotection_title" /></div>
                <Checkbox
                  id="dataProtection"
                  name="dataProtection"
                  checked={values.dataProtection}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  isInvalid={(errors.dataProtection && touched.dataProtection)}
                  label={intl.formatMessage({ id: "payment_dialog.dataprotection_label" },
                    {
                      link: (
                        <NavigationLink href={content.privacyPolicyLink} target="_blank">
                          <FormattedMessage id="payment_dialog.dataprotection_linktext" />
                        </NavigationLink>),
                    })}
                  errors={errors}
                  feedback={errors.dataProtection}
                  small
                  required
                />
              </Col>
              <Col xs={12}>
                <ScrollToFieldError />

                <div className="payment-dialog__submit">
                  <IconButton
                    type="submit"
                    variant="donation"
                    loading={paymentLinkResult.isLoading}
                  >
                    <FormattedMessage id="payment.overview.button_next" /> <IconCreditCard24 />
                  </IconButton>
                  <IconText
                    icon={<IconPrivate18 />}
                    variant="grey-dark"
                    text={intl.formatMessage({ id: "payment.ssl_encrypted" })}
                  />
                </div>
                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

PaymentDialogStepOverviewUk.stepName = "payment_summary";

PaymentDialogStepOverviewUk.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default PaymentDialogStepOverviewUk;
