import IoBaseApiSlice from "./ioBaseApiSlice";

export const IoUpdateMailAddressApiSlice = IoBaseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    submitIoUpdateMailAddress: build.mutation({
      query: ({ data }) => ({
        apiRequirements: ["session", "csrfToken"],
        data: {
          classification: "Restricted",
          payload: data,
        },
        method: "post",
        url: `${process.env.GATSBY_IO_HOST}/ciu/${process.env.GATSBY_IO_URN_UPDATE_MAIL}`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
  }),
});

export const {
  useSubmitIoUpdateMailAddressMutation,
} = IoUpdateMailAddressApiSlice;
