import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ReactIdSwiper from "react-id-swiper";

import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import { IconAnchorLeft18, IconAnchorRight18 } from "../../icons";
import LinkButton from "../button/linkButton";

import "./heroCarousel.scss";

const HeroCarousel = (props) => {
  const swiperRef = useRef();
  const [nextDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(true);

  const {
    children,
    eyecatcher,
    notOverwriteButton,
    urlLastSlide,
  } = props;

  const [modifiedChildren, setModifiedChildren] = useState(children);
  const isLowerLg = useMediaQuery("(max-width: 991px)");

  const checkButtons = () => {
    if (swiperRef.current?.swiper) {
      setNextDisabled(swiperRef.current.swiper.isEnd);
      setPrevDisabled(swiperRef.current.swiper.isBeginning);
    }
  };

  const slideNext = () => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slideNext();
      checkButtons();
    }
  };

  const slidePrev = () => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slidePrev();
      checkButtons();
    }
  };

  useEffect(() => {
    let tmpChildren = [];

    if (Array.isArray(children)) {
      if (!notOverwriteButton) {
        for (let i = 0; i < children.length - 1; i += 1) {
          const button = React.cloneElement(children[i].props.button, {
            onClick: () => {
              slideNext();
            },
          });
          tmpChildren.push(React.cloneElement(children[i], { button }));
        }

        const button = React.cloneElement(children[children.length - 1].props.button, {
          href: urlLastSlide,
          variant: "registration",
        });

        tmpChildren.push(React.cloneElement(children[children.length - 1], { button }));
      } else {
        tmpChildren = children;
      }
    } else {
      tmpChildren.push(children);
    }

    setModifiedChildren(tmpChildren);
  }, []);

  useEffect(() => {
    checkButtons();
  }, [modifiedChildren]);

  return (
    <div className={classNames("hero-carousel", { "hero-carousel--without-eyecatcher": !eyecatcher })}>
      <div className="container--fullwidth">
        {!isLowerLg && (
          <Container fluid>
            <Row>
              <Col className="d-flex" lg={{ offset: 5, span: 3 }}>
                {!eyecatcher && (Array.isArray(modifiedChildren)
                  && modifiedChildren.length > 1) && (
                    <div className="hero-carousel__buttons">
                      <div className="hero-carousel__control-prev">
                        <LinkButton
                          disabled={prevDisabled}
                          variant="normal"
                          onClick={() => {
                            slidePrev();
                          }}
                        >
                          <IconAnchorLeft18 />
                          <FormattedMessage id="hero_carousel.previous" />
                        </LinkButton>
                      </div>
                      <div className="hero-carousel__control-next">
                        <LinkButton
                          disabled={nextDisabled}
                          variant="normal"
                          onClick={() => {
                            slideNext();
                          }}
                        >
                          <FormattedMessage id="hero_carousel.next" />
                          <IconAnchorRight18 />
                        </LinkButton>
                      </div>
                    </div>
                )}
              </Col>
            </Row>
          </Container>
        )}
        <ReactIdSwiper
          ref={swiperRef}
          noSwiping={!notOverwriteButton}
          mousewheel={notOverwriteButton && { forceToAxis: true, invert: true }}
          pagination={Array.isArray(modifiedChildren) && modifiedChildren.length > 0 && {
            clickable: true,
            el: ".swiper-pagination.container-fluid",
            renderBullet: (index, className) => `
                  <div class="pagination__inner ${className}">
                      <div class="d-lg-block d-none typo-small-label">
                          ${modifiedChildren[index]?.props?.menuTitle}
                      </div>
                  </div>`
            ,
          }}
          slidesPerView={1}
          on={{
            init: () => {
              setTimeout(() => {
                const evt = document.createEvent("Event");
                evt.initEvent("resize", true, true);
                window.dispatchEvent(evt);
              }, 200);
            },
            slideChange: () => {
              checkButtons();
            },
          }}
        >
          {React.Children.map(modifiedChildren, (child) => (
            <div>{child}</div>
          ))}
        </ReactIdSwiper>
      </div>
      {eyecatcher}
    </div>
  );
};

HeroCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  eyecatcher: PropTypes.oneOfType([PropTypes.object]),
  notOverwriteButton: PropTypes.bool,
  urlLastSlide: PropTypes.string,
};

HeroCarousel.defaultProps = {
  eyecatcher: null,
  notOverwriteButton: false,
  urlLastSlide: null,
};

export default HeroCarousel;
