import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Consent from "../../components/consent/consent";
import SocialMediaEmbed from "../../components/socialMediaEmbed/socialMediaEmbed";

const KontentItemTweet = ({ data }) => {
  const tweet = data.value ? data.value[0] : data;

  if (!tweet || !tweet.elements) {
    return null;
  }

  return (
    <Consent type="social">
      <SocialMediaEmbed
        url={tweet.elements.tweet_url.value}
        showText
      />
    </Consent>
  );
};

export const query = graphql`
  fragment KontentItemTweet on kontent_item_tweet {
    id
    elements {
      tweet_url {
        value
      }
      display_options {
        value {
          name
          codename
        }
      }
    }
  }
`;

KontentItemTweet.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemTweet;
