import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ReactIdSwiper from "react-id-swiper";

import { IconAnchorLeft18, IconAnchorRight18, IconFurther24 } from "../../icons";
import RichTextContext from "../../templates/text/richTextContext";
import LinkButton from "../button/linkButton";
import TitleRow from "../titleRow";

import "./cardSlider.scss";

const CardSlider = (props) => {
  const swiperRef = useRef();

  const [nextDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(true);

  const isRichText = useContext(RichTextContext);

  const checkButtons = () => {
    if (swiperRef.current?.swiper) {
      setNextDisabled(swiperRef.current.swiper.isEnd);
      setPrevDisabled(swiperRef.current.swiper.isBeginning);
    }
  };

  useEffect(() => {
    checkButtons();
  }, []);

  const next = () => {
    swiperRef.current?.swiper.slideNext();
  };

  const previous = () => {
    swiperRef.current?.swiper.slidePrev();
  };

  const {
    header,
    children,
    description,
    className,
    showSlideButtons,
    related,
    targetGroup,
    equalCardHeights,
    subHeader,
    twoCols,
    inline,
    moreUrl,
    virtualDrive,
    statementBox,
  } = props;

  const childrenArray = React.Children.toArray(children);

  let colProps = {
    lg: 4,
    sm: 6,
    xs: childrenArray.length === 1 ? 12 : 10,
  };

  let breakpointProps = {
    0: {
      slidesPerView: "auto",
    },
    576: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
  };

  if (statementBox) {
    colProps = {
      xs: 12,
    };

    if (childrenArray.length > 1) {
      colProps = {
        md: 12,
        sm: 8,
        xs: 10,
      };
    }

    breakpointProps = {
      0: {
        slidesPerView: "auto",
      },
    };
  }

  if (twoCols) {
    colProps = {
      md: 6,
      sm: 12,
      xs: childrenArray.length === 1 ? 12 : 10,
    };

    breakpointProps = {
      0: {
        slidesPerView: "auto",
      },
      576: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
    };
  }

  if (virtualDrive) {
    colProps = {
      lg: 4,
      md: 6,
      sm: 12,
      xs: childrenArray.length === 1 ? 12 : 10,
    };

    breakpointProps = {
      0: {
        slidesPerView: "auto",
      },
      576: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    };
  }

  const swiperProps = {
    breakpoints: { ...breakpointProps },
    keyboard: true,
    mousewheel: {
      forceToAxis: true,
    },
    on: {
      slideChange: () => {
        checkButtons();
      },
    },
    shouldSwiperUpdate: true,
  };

  if (childrenArray.length > 0) {
    const showFooter = () => {
      if (related) {
        return false;
      }

      if (!showSlideButtons && !moreUrl) {
        return false;
      }

      if (twoCols && childrenArray.length <= 2) {
        return false;
      }

      if (!twoCols) {
        if (statementBox && childrenArray.length <= 1) {
          return false;
        }

        if (!statementBox && childrenArray.length <= 3) {
          return false;
        }
      }

      return true;
    };

    const getFooter = () => (
      <Row
        className={
            classNames(
              "card-slider__control",
              {
                "card-slider__show-all": moreUrl,
                "justify-content-end": !moreUrl,
              },
            )
          }
      >
        {moreUrl && (
          <Col className="card-slider__control-link">
            <LinkButton href={moreUrl}>
              <FormattedMessage id="cardslider.view_all" />
              <IconFurther24 />
            </LinkButton>
          </Col>
        )}
        {
            showSlideButtons
            && (childrenArray.length > 3 || (statementBox && childrenArray.length > 1))
            && (
              <>
                <Col className="card-slider__control-prev">
                  <LinkButton
                    disabled={prevDisabled}
                    variant="normal"
                    onClick={() => {
                      previous();
                    }}
                  >
                    <IconAnchorLeft18 />
                    <FormattedMessage id="cardslider.previous" />
                  </LinkButton>
                </Col>
                <Col className="card-slider__control-next">
                  <LinkButton
                    disabled={nextDisabled}
                    variant="normal"
                    onClick={() => {
                      next();
                    }}
                  >
                    <FormattedMessage id="cardslider.next" />
                    <IconAnchorRight18 />
                  </LinkButton>
                </Col>
              </>
            )
          }
      </Row>
    );

    return (
      <div
        className={classNames(
          "card-slider",
          className,
          {
            "card-slider--inline": inline,
            "card-slider--no-headers": !header && !subHeader,
            "card-slider--statement-box": statementBox,
            "card-slider--two-cols": twoCols,
          },
        )}
      >
        {header && header !== "" && (
          (isRichText && <h3>{header}</h3>)
          || <TitleRow description={description}>{header}</TitleRow>
        )}
        {subHeader && (
          <Row>
            <Col md={6} lg={7} xl={6} className="card-slider__subheader">
              <h4>{subHeader}</h4>
            </Col>
          </Row>
        )}
        <div className="container--fullwidth card-slider__slider">
          <Container fluid>
            <ReactIdSwiper
              ref={swiperRef}
              className="row"
              {...swiperProps}
            >
              {childrenArray.slice(0, related ? 3 : undefined).map(
                (child, index) => (
                  <Col
                    {...colProps}
                      /* eslint-disable-next-line react/no-array-index-key */
                    key={index.toString()}
                    className={classNames({ "equal-height": equalCardHeights })}
                  >
                    {child}
                  </Col>
                ),
              )}
            </ReactIdSwiper>
          </Container>
        </div>
        {
            showFooter()
            && (
              (!statementBox && getFooter())
              || (statementBox && (
                <div className="container--fullwidth">
                  <Container fluid>
                    {getFooter()}
                  </Container>
                </div>
              ))
            )
          }
        {targetGroup && (
          <div className="card-slider__target-group">
            {targetGroup}
          </div>
        )}
      </div>
    );
  }

  return null;
};

CardSlider.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  equalCardHeights: PropTypes.bool,
  header: PropTypes.string,
  inline: PropTypes.bool,
  moreUrl: PropTypes.string,
  related: PropTypes.bool,
  showSlideButtons: PropTypes.bool,
  statementBox: PropTypes.bool,
  subHeader: PropTypes.string,
  targetGroup: PropTypes.oneOfType([PropTypes.object]),
  twoCols: PropTypes.bool,
  virtualDrive: PropTypes.bool,
};

CardSlider.defaultProps = {
  className: null,
  description: null,
  equalCardHeights: false,
  header: null,
  inline: false,
  moreUrl: null,
  related: false,
  showSlideButtons: true,
  statementBox: false,
  subHeader: null,
  targetGroup: null,
  twoCols: false,
  virtualDrive: false,
};

export default CardSlider;
