import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./relatedTopics.scss";

const RelatedTopics = ({ children, headline }) => (
  <div className="container--fullwidth related-topics">
    <Container fluid>
      {headline && (
        <Row>
          <Col sm={10} md={8} xl={6} className="related-topics__headline">
            {headline}
          </Col>
        </Row>
      )}
      <Row className="related-topics__topics">
        {React.Children.toArray(children).map((child, index) => (
          <Col
            key={`related-topics__topic${index.toString()}`}
            md={6}
            lg={4}
            className="related-topics__topic"
          >
            {child}
          </Col>
        ))}
      </Row>
    </Container>
  </div>
);

RelatedTopics.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string,
};

RelatedTopics.defaultProps = {
  headline: null,
};

export default RelatedTopics;
