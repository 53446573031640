import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import StateAwareAccordionToggle from "../stateAwareAccordionToggle/stateAwareAccordionToggle";

import "./contactAccordion.scss";

const ContactAccordion = (props) => {
  const {
    accordionItems,
    contactFaq,
    headline,
    generalInquiries,
    variant,
  } = props;

  const isLowerMd = useMediaQuery("(max-width: 767px)");

  return (
    <Accordion
      className={classNames(
        "contact-accordion",
        {
          [`contact-accordion--${variant}`]: variant,
        },
      )}
    >
      <div className="contact-accordion__headline">{headline}</div>
      <Row>
        {contactFaq !== null && (
          <Col xs={12} md={6}>
            {contactFaq}
          </Col>
        )}
        {accordionItems.map((item) => (
          <Col key={item.id} xs={12} md={6}>
            <div
              className="contact-accordion__accordion-item"
            >
              {variant !== "update-details" && !isLowerMd && (
                <div className="typo-title2">
                  {item.title}
                </div>
              )}
              {variant !== "update-details" && isLowerMd && (
                <StateAwareAccordionToggle eventKey={item.id} className="typo-title2">
                  {item.title}
                </StateAwareAccordionToggle>
              )}

              <Accordion.Collapse eventKey={item.id}>
                <>
                  <div className="contact-accordion__answer">
                    {item.text}
                    {item.legalModal && <div>{item.legalModal}</div>}
                  </div>
                  {item.contact}
                  <div>
                    {item.button}
                  </div>
                </>
              </Accordion.Collapse>
            </div>
          </Col>
        ))}

        {generalInquiries && (
          <Col xs={12} md={6}>
            <div className="contact-accordion__accordion-item">
              <div className="typo-title2">
                {generalInquiries.title}
              </div>

              <div className="show">
                <div className="contact-accordion__answer">
                  {generalInquiries.text}
                  {generalInquiries.legalModal && <div>{generalInquiries?.legalModal}</div>}
                </div>
                {generalInquiries.contact}
                <div className={classNames({ "mt-md-auto": !generalInquiries.contact })}>
                  {generalInquiries.button}
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </Accordion>
  );
};

ContactAccordion.propTypes = {
  accordionItems: PropTypes.arrayOf(PropTypes.shape({
    button: PropTypes.oneOfType([PropTypes.object]).isRequired,
    contact: PropTypes.oneOfType([PropTypes.object]),
    id: PropTypes.string.isRequired,
    legalModal: PropTypes.oneOfType([PropTypes.object]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  })).isRequired,
  contactFaq: PropTypes.oneOfType([PropTypes.object]),
  generalInquiries: PropTypes.shape({
    button: PropTypes.oneOfType([PropTypes.object]).isRequired,
    contact: PropTypes.oneOfType([PropTypes.object]).isRequired,
    legalModal: PropTypes.oneOfType([PropTypes.object]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }),
  headline: PropTypes.string,
  variant: PropTypes.oneOf(["update-details"]),
};

ContactAccordion.defaultProps = {
  contactFaq: null,
  generalInquiries: null,
  headline: null,
  variant: null,
};

export default ContactAccordion;
