import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import Button from "../../components/button/button";
import CardSlider from "../../components/cardSlider/cardSlider";
import EventHeader from "../../components/eventHeader/eventHeader";
import EventSeriesModule from "../../components/eventSeriesModule/eventSeriesModule";
import Map from "../../components/map/map";
import SEO from "../../components/seo";
import TextWithSidebar from "../../components/textWithSidebar/textWithSidebar";
import { setTracking } from "../../features/trackingSlice";
import mapKontentItem from "../../utils/mapKontentItem";
import MapMarkerCreator from "../../utils/mapMarkerCreator";
import CampaignCard from "../campaigns/campaignCard";
import EventTileCard from "../events/eventTileCard";
import KontentItemContact from "../kontentItemContact";
import KontentItemImage from "../kontentItemImage";
import KontentItemImageCardSliderSection from "../kontentItemImageCardSliderSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemEvent = ({ data, pageContext }) => {
  const dispatch = useDispatch();

  dispatch(
    setTracking({
      URN: data.page.elements.urn_primary.value,
      secondaryURN: data.page.elements.urn_secondary.value,
    }),
  );

  const filteredEvents = EventTileCard({
    filterCategory: data.page.elements.event_category.value?.[0]?.name,
    id: data.page.id,
    onlyFurtherEvents: true,
  });

  const location = (process.env.GATSBY_SITE === "de"
    ? [
      data.page.elements.room.value,
      data.page.elements.address.value,
      `${data.page.elements.postal_code.value} ${data.page.elements.city.value}`,
      data.page.elements.country.value,
    ]
    : [
      data.page.elements.room.value,
      data.page.elements.address.value,
      data.page.elements.postal_code.value,
      data.page.elements.city.value,
      data.page.elements.country.value,
    ]).filter((item) => item).join(", ");

  let coordinates;
  let map = null;
  try {
    coordinates = JSON.parse(data.page.elements.coordinates.value);
  } catch (exception) {
    coordinates = {
      lat: null,
      lng: null,
      timezone: null,
    };
  }

  if (coordinates && coordinates.lat !== null && coordinates.lng !== null) {
    map = (
      <div className="container--fullwidth event-detail-map-container">
        <Map markers={MapMarkerCreator([data.page], true)} infoWindow={false} openMapButton />
      </div>
    );
  }

  const timezone = coordinates?.timezone?.timeZoneId
    || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const headerImage = (data.page.elements.image.value.length > 0
    && data.page.elements.image.value[0].elements.image.value.length > 0)
    ? data.page.elements.image.value[0].elements.image.value
    : [];

  const intl = useIntl();

  const blurImage = data.page.elements.image_object_fit_contain.value.length > 0
    ? data.page.elements.image_object_fit_contain.value[0].codename === "true"
    : false;

  return (
    <MainLayout>

      <SEO
        title={data.page.elements.metadata__meta_title.value}
        description={data.page.elements.metadata__meta_description.value}
        twitterTitle={data.page.elements.metadata__twitter_title.value}
        twitterSite={data.page.elements.metadata__twitter_site.value}
        twitterImage={data.page.elements.metadata__twitter_image.value.length > 0
          ? data.page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={data.page.elements.metadata__twitter_description.value}
        twitterCreator={data.page.elements.metadata__twitter_creator.value}
        ogTitle={data.page.elements.metadata__og_title.value}
        ogImage={data.page.elements.metadata__og_image.value.length > 0
          ? data.page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={data.page.elements.metadata__og_description.value}
        isHiddenForRobots={pageContext.seoNoIndex}
        linkingData={[{
          "@context": "https://schema.org",
          "@type": "Event",
          endDate: data.page.elements.date_end.value,
          image: [
            data.page.elements.metadata__og_image.value.length > 0
              ? data.page.elements.metadata__og_image.value
              : headerImage,
          ],
          location: {
            "@type": "Place",
            address: {
              "@type": "PostalAddress",
              addressCountry: data.page.elements.country.value,
              addressLocality: data.page.elements.room.value,
              postalCode: data.page.elements.postal_code.value,
              streetAddress: data.page.elements.address.value,
            },
          },
          name: data.page.elements.title.value,
          startDate: data.page.elements.date_start.value,
        },
        ]}
      />

      <SubNavigationGenerator id={data.page.id} />

      <BreadcrumbGenerator id={data.page.id} />

      <EventHeader
        headline={data.page.elements.title.value}
        category={
          data.page.elements.event_category.value.length > 0
            ? data.page.elements.event_category.value[0].name
            : null
        }
        location={location.length > 0 ? location : null}
        date={new Date(data.page.elements.date_start.value)}
        endDate={data.page.elements.date_end.value
          ? new Date(data.page.elements.date_end.value)
          : null}
        timezone={timezone}
        image={
          data.page.elements.image.value.length > 0
            ? (
              <KontentItemImage
                data={data.page.elements.image}
                width={600}
                aspectRatio={4 / 3}
                fit="crop"
              />
            )
            : null
        }
        blurImage={blurImage}
        eventUid={data.page.id}
        joinButton={data.page.elements.button_text.value.length > 0
          ? (
            <Button
              variant="events"
              href={data.page.elements.button_url.value.length > 0
                ? data.page.elements.button_url.value[0].id
                : data.page.elements.button_url_extern.value}
            >
              {data.page.elements.button_text.value}
            </Button>
          )
          : null}
      />

      {process.env.GATSBY_SITE !== "us" && map}

      <TextWithSidebar
        text={(
          <RichText data={data.page.elements.event_description} isArticle />
        )}
        contactHeadline={data.page.elements.title_organiser.value}
        contact={
          data.page.elements.contact.value.length > 0
            ? <KontentItemContact data={data.page.elements.contact} />
            : null
        }
        campaignHeadline={data.page.elements.title_related_campaign.value}
        campaign={
          data.page.elements.related_campaign.value.length > 0
            ? CampaignCard({ data: data.page.elements.related_campaign, small: true, tile: true })
            : null
        }
      />

      {process.env.GATSBY_SITE === "us" && map}

      {data.page.elements.related_events.value.length > 0 && (
        <EventSeriesModule headline={data.page.elements.title_related_events.value}>
          {EventTileCard({ data: data.page.elements.related_events.value, mapPreview: true })}
        </EventSeriesModule>
      )}

      {data.page.elements.eyecatcher.value.length > 0
        ? mapKontentItem(data.page.elements.eyecatcher.value[0])
        : null}

      {data.page.elements.hide_further_events.value.length === 0 && filteredEvents.length > 0 && (
        <CardSlider header={intl.formatMessage({ id: "event.further_events" })} twoCols>
          {filteredEvents}
        </CardSlider>
      )}

      <KontentItemImageCardSliderSection data={data.page.elements.image_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemEvent(id: {eq: $id}) {
      id
      system {
        last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
      }
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        coordinates {
            value
        }
        title {
          value
        }
        date_start {
          value
        }
        date_end {
          value
        }
        room {
          value
        }
        address {
          value
        }
        postal_code {
          value
        }
        city {
          value
        }
        country {
          value
        }
        event_category {
          value {
            name
            codename
          }
        }
        eyecatcher {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherEvent
            ...KontentItemEyecatcher
          }
        }
        image_card_slider {
          value {
            ...KontentItemImageCardSliderSection
          }
        }
        related_events {
          value {
            ...EventTileCard
          }
        }
        contact {
          value {
            ...KontentItemContact
          }
        }
        title_organiser {
          value
        }
        related_campaign {
          value {
            ...CampaignCard
          }
        }
        title_related_campaign {
          value
        }
        title_related_events {
          value
        }
        urn_primary {
          value
        }
        urn_secondary {
          value
        }
        image {
          value {
            ...KontentItemImage
          }
        }
        image_object_fit_contain {
          value {
            codename
          }
        }
        button_text {
          value
        }
        button_url {
          value {
            id
          }
        }
        button_url_extern {
          value
        }
        event_description {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemCtaButton
            ...KontentItemLinkCard
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemEmbedExternal
          }
        }
        hide_further_events {
          value {
            codename
          }
        }
      }
    }
  }
`;

KontentItemEvent.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEvent;
