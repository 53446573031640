import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";

import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import SEO from "../../components/seo";
import KontentItemCampaignsSection from "../kontentItemCampaignsSection";
import KontentItemCtaButton from "../kontentItemCtaButton";
import KontentItemEyecatcher from "../kontentItemEyecatcher";
import KontentItemFaqSection from "../kontentItemFaqSection";
import KontentItemIconCardSliderSection from "../kontentItemIconCardSliderSection";
import KontentItemImageCardSliderSection from "../kontentItemImageCardSliderSection";
import KontentItemLatestNewsSliderSection from "../kontentItemLatestNewsSection";
import KontentItemMissionStatement from "../kontentItemMissionStatement";
import KontentItemRegistryBanner from "../kontentItemRegistryBanner";
import KontentItemRelatedLearnContentEmbed from "../kontentItemRelatedLearnContentEmbed";
import MainLayout from "../layout/mainLayout";

const KontentItemHomePage = (data) => {
  const intl = useIntl();

  // eslint-disable-next-line react/destructuring-assignment
  const page = data.data.kontentItemHome;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={intl.formatMessage({ id: "title" })}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <HeroHeadline
        imageData={page.elements.hero_image}
        buttons={page.elements.button.value.map((button) => (
          <KontentItemCtaButton
            data={button}
            key={`ctabutton${button.id}`}
          />
        ))}
      >
        {page.elements.headline.value}
      </HeroHeadline>

      <KontentItemMissionStatement data={page.elements.section_mission_statement} />

      <KontentItemImageCardSliderSection data={page.elements.section_image_card_slider} />

      <KontentItemRelatedLearnContentEmbed data={page.elements.related_learn_material} />

      <KontentItemCampaignsSection data={page.elements.section_campaigns} />

      <KontentItemEyecatcher data={page.elements.eyecatcher_main_funnels} />

      <KontentItemFaqSection data={page.elements.section_faq} />

      <KontentItemLatestNewsSliderSection data={page.elements.section_latest_news} />

      <KontentItemRegistryBanner data={page.elements.join_the_registry_banner} />

      <KontentItemIconCardSliderSection data={page.elements.icon_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  {
    kontentItemHome(system: {type: {eq: "home"}}) {
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        section_mission_statement {
          value {
            ...KontentItemMissionStatement
          }
        }
        join_the_registry_banner {
          value {
            ...KontentItemRegistryBanner
          }
        }
        section_faq {
          value {
            ...KontentItemFaqSection
          }
        }
        section_image_card_slider {
          value {
            ...KontentItemImageCardSliderSection
          }
        }
        section_campaigns {
          value {
            ...KontentItemCampaignsSection
          }
        }
        eyecatcher_main_funnels {
          value {
            ...KontentItemEyecatcher
          }
        }
        icon_card_slider {
          value {
            ...KontentItemIconCardSliderSection
          }
        }
        section_latest_news {
          value {
            ...KontentItemLatestNewsSliderSection
          }
        }
        related_learn_material {
          value {
            ...KontentItemRelatedLearnContentEmbed
          }
        }
        button {
          value {
            ...KontentItemCtaButton
          }
        }
      }
      preferred_language
    }
  }
`;

export default KontentItemHomePage;
