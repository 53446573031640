import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import RelatedTopics from "../../components/relatedTopics/relatedTopics";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import DonorTopicTiles from "../donorArea/donorTopicTiles";
import KontentItemIconCardSliderSection from "../kontentItemIconCardSliderSection";
import KontentItemPathIllustrationStep from "../kontentItemPathIllustrationStep";
import KontentItemRelatedDonorArticlesSection from "../kontentItemRelatedDonorArticlesSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";

const KontentItemDonorAreaOverviewPage = ({ data }) => {
  const { page } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <KontentItemPathIllustrationStep
        data={page.elements.path_illustration_steps}
        headline={page.elements.headline.value}
        description={page.elements.text.value}
      />

      <RelatedTopics>
        {DonorTopicTiles({ data: page.elements.donor_topics })}
      </RelatedTopics>

      {page.elements.eyecatcher_contact.value.length > 0
        ? mapKontentItem(page.elements.eyecatcher_contact.value[0])
        : null}

      <KontentItemRelatedDonorArticlesSection data={page.elements.related_articles} />

      <KontentItemIconCardSliderSection data={page.elements.icon_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemDonorAreaOverviewPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        donor_topics {
          value {
            ...DonorTopicTiles
          }
        }
        eyecatcher_contact {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherContact
            ...KontentItemEyecatcher
          }
        }
        related_articles {
          value {
            ...KontentItemRelatedDonorArticlesSection
          }
        }
        icon_card_slider {
          value {
            ...KontentItemIconCardSliderSection
          }
        }
        path_illustration_steps {
          value {
            ...KontentItemPathIllustrationStep
          }
        }
        text {
          value
        }
        headline {
          value
        }
      }
    }
  }
`;

KontentItemDonorAreaOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDonorAreaOverviewPage;
