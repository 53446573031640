import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconFurther18 } from "../../icons";
import IconButton from "../button/iconButton";
import NavigationLink from "../navigationLink/navigationLink";

import "./quickLinkTile.scss";

const QuickLinkTile = (props) => {
  const {
    icon,
    headline,
    text,
    url,
    variant,
    disabled,
    buttonText,
  } = props;

  return (
    <NavigationLink
      href={url}
      className={classNames(
        "assets-tile no-style",
        { [`assets-tile--${variant}`]: variant },
      )}
    >
      <div className="assets-tile__icon">{icon}</div>
      <div className="assets-tile__headline">{headline}</div>
      <div className="assets-tile__text">{text}</div>
      <div className="assets-tile__button">
        {disabled
          ? (
            <div className="typo-button text-grey-dark">
              <FormattedMessage id="assets_tile.available_soon" />
            </div>
          )
          : (
            <IconButton variant="plain-registration">
              {buttonText} <IconFurther18 />
            </IconButton>
          )}

      </div>
    </NavigationLink>
  );
};

QuickLinkTile.propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  headline: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string,
  variant: PropTypes.oneOf(["ssp"]),
};

QuickLinkTile.defaultProps = {
  buttonText: <FormattedMessage id="assets_tile.learn_more" />,
  disabled: false,
  url: null,
  variant: null,
};

export default QuickLinkTile;
