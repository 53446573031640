import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { IconClose24, IconSuccess24, IconWarning24 } from "../../icons";
import { notificationService, notificationType } from "../../services/notification.service";
import LinkButton from "../button/linkButton";

import "./notification.scss";

const Notification = ({ fade, id }) => {
  const subscription = useRef(null);

  const [notifications, setNotifications] = useState([]);

  const removeNotification = (notification) => {
    if (fade) {
      const notificationWithFade = { ...notification, fade: true };

      setNotifications(notifications.map((x) => (x === notification ? notificationWithFade : x)));

      setTimeout(() => {
        setNotifications(notifications.filter((x) => x !== notification));
      }, 100);
    } else {
      setNotifications(notifications.filter((x) => x !== notification));
    }
  };

  const cssClasses = (notification) => {
    if (!notification) return "";

    const classes = ["notification"];

    const alertTypeClass = {
      [notificationType.success]: "notification--success",
      [notificationType.error]: "notification--error",
      [notificationType.info]: "notification-info",
      [notificationType.warning]: "notification-warning",
    };

    classes.push(alertTypeClass[notification.type]);

    if (notification.fade) {
      classes.push("fade");
    }

    return classes.join(" ");
  };

  useEffect(() => {
    subscription.current = notificationService.onNotification(id)
      .subscribe((notification) => {
        if (!notification.message) {
          const filteredNotifications = notifications.filter(((x) => x.keepAfterRouteChange));
          const tmpNotifications = [];

          filteredNotifications.forEach((x) => {
            const tmpNotification = x;
            delete tmpNotification.keepAfterRouteChange;
            tmpNotifications.push(tmpNotification);
          });

          setNotifications(tmpNotifications);
        }

        setNotifications([...notifications, notification]);

        if (notification.autoClose) {
          if (notification.delay) {
            setTimeout(() => removeNotification(notification), notification.delay);
          } else {
            setTimeout(() => removeNotification(notification), 3000);
          }
        } else if (notification.delay) {
          setTimeout(() => removeNotification(notification), notification.delay);
        }
      });

    return () => {
      subscription.current.unsubscribe();
    };
  }, []);

  if (!notifications.length) return null;

  if (notifications.filter((noti) => noti.message).length === 0) {
    return null;
  }

  return (
    <Container className="notification-container">
      <Row className="align-items-center">
        <Col xl={{ offset: 1, span: 10 }}>
          {notifications.map((notification) => (
            <Row
              key={notification.id}
              className={classNames("no-gutters", cssClasses(notification))}
            >
              <Col md={6} className="d-flex align-items-center typo-label">
                <div className="notification__icon">
                  {notification.type === notificationType.error && <IconWarning24 />}
                  {notification.type === notificationType.success && <IconSuccess24 />}
                </div>
                {notification.message}
              </Col>
              <Col md={6}>
                <Row className="notification__buttons">
                  {notification.action && (
                    <Col className="col-auto">{notification.action}</Col>
                  )}
                  <Col className="col-auto">
                    <LinkButton onClick={() => removeNotification(notification)}>
                      {notification.dismissButtonText || <FormattedMessage id="notification.dismiss" />}
                      <IconClose24 />
                    </LinkButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

Notification.propTypes = {
  fade: PropTypes.bool,
  id: PropTypes.string,
};

Notification.defaultProps = {
  fade: true,
  id: "default-notification",
};

export default Notification;
