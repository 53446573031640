import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import backgroundImageHelper from "./image/backgroundImageHelper";
import TargetGroup from "../components/targetGroup/targetGroup";
import TargetGroupImage from "../components/targetGroupImage/targetGroupImage";
import iconHelper from "../utils/iconHelper";

const KontentItemTargetGroup = ({ data, asImage }) => {
  const targetGroupData = Array.isArray(data) ? data : data.value;

  const targetGroupList = [];

  targetGroupData.forEach((targetGroup) => {
    targetGroupList.push(
      asImage
        ? (
          <TargetGroupImage
            title={targetGroup.elements.title.value}
            url={
              targetGroup.elements.url.value.length > 0
                ? targetGroup.elements.url.value[0].id
                : null
            }
            backgroundImageStyle={
              targetGroup.elements.url.value.length > 0
                ? {
                  ...backgroundImageHelper(targetGroup.elements.url.value[0].elements.hero_image),
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }
                : null
            }
            key={targetGroup.id}
          />
        )
        : (
          <TargetGroup
            title={targetGroup.elements.title.value}
            href={
              targetGroup.elements.url.value.length > 0
                ? targetGroup.elements.url.value[0].id
                : null
            }
            icon={
              targetGroup.elements.icon.value.length > 0
                ? iconHelper(targetGroup.elements.icon.value[0].codename)
                : null
            }
            key={targetGroup.id}
          />
        ),
    );
  });

  return React.Children.toArray(targetGroupList);
};

export const query = graphql`
  fragment KontentItemTargetGroup on kontent_item_target_group {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      icon {
        value {
          codename
        }
      }
      url {
        value {
          id
          ... on kontent_item_target_group_page {
            elements {
              hero_image {
                value {
                  ...KontentItemImage
                }
              }
            }
          }
        }
      }
    }
  }
`;

KontentItemTargetGroup.propTypes = {
  asImage: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

KontentItemTargetGroup.defaultProps = {
  asImage: false,
};

export default KontentItemTargetGroup;
