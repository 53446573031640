import { memo } from "react";

import PropTypes from "prop-types";

import useWindowDimensions from "../hooks/useWindowDimensions";

const AdaptiveIcon = (props) => {
  const {
    sm,
    lg,
  } = props;

  const { width } = useWindowDimensions();

  if (width >= 992) {
    return lg;
  }

  return sm;
};

AdaptiveIcon.propTypes = {
  lg: PropTypes.oneOfType([PropTypes.object]).isRequired,
  sm: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default memo(AdaptiveIcon);
