import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import mapMegaMenuResultItems from "./megaMenu/mapMegaMenuResultItems";
import MegaMenuResultsCampaign from "./megaMenu/megaMenuResultsCampaign";
import MegaMenuResultsEvent from "./megaMenu/megaMenuResultsEvent";
import MegaMenuResultsNews from "./megaMenu/megaMenuResultsNews";

const KontentItemMegaMenuData = ({ data }) => {
  const megaMenuData = data.value ? data.value[0] : data;

  if (!megaMenuData) return null;

  if (megaMenuData.elements.selected_items.value.length > 0) {
    return megaMenuData.elements.selected_items.value.map(
      (item) => mapMegaMenuResultItems(item),
    );
  }

  if (megaMenuData.elements.campaign.value.length > 0) {
    return <MegaMenuResultsCampaign />;
  }

  if (megaMenuData.elements.event_category.value.length > 0) {
    return (
      <MegaMenuResultsEvent
        item={{
          categories: megaMenuData.elements.event_category.value.map((e) => e.codename),
        }}
      />
    );
  }

  if (megaMenuData.elements.news_category.value.length > 0) {
    return (
      <MegaMenuResultsNews
        item={{
          categories: megaMenuData.elements.news_category.value.map((e) => e.codename),
        }}
      />
    );
  }

  return null;
};

export const query = graphql`
  fragment KontentItemMegaMenuData on kontent_item_megamenu_data {
    id
    elements {
      campaign {
        value {
          codename
        }
      }
      event_category {
        value {
          codename
        }
      }
      news_category {
        value {
          codename
        }
      }
      page_description {
        value
      }
      selected_items {
        value {
          internal {
            type
          }
          ...MegaMenuResultProgram
          ...MegaMenuResultLearn
          ...MegaMenuResultDonorArea
          ...MegaMenuResultTargetGroupPage
        }
      }
    }
  }
`;

KontentItemMegaMenuData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemMegaMenuData;
