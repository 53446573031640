import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationEthnicityZa = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    countryFather: string()
      .required(requiredMessage),
    countryMother: string()
      .required(requiredMessage),
    ethnicityFather: string()
      .required(requiredMessage),
    ethnicityMother: string()
      .required(requiredMessage),
    familyAgreement: string(),
    yourEthnicity: string()
      .required(requiredMessage),
    yourNativeLanguage: string()
      .required(requiredMessage),
  }), []);

  const {
    ethnicityOptions,
    stateOptions,
    nativeLanguage,
  } = RegistrationDialogStepRegistrationEthnicityZa;

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.ethnicity.title"
        subheadline="registration.ethnicity.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              countryFather: values.countryFather,
              countryMother: values.countryMother,
              ethnicityFather: values.ethnicityFather,
              ethnicityMother: values.ethnicityMother,
              familyAgreement: values.familyAgreement,
              yourEthnicity: values.yourEthnicity,
              yourNativeLanguage: values.yourNativeLanguage,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_ethnicity", { event_value: "success" });
          DataLayer.pushEvent("pp_registration_step_your_ethnicity_value",
            {
              dimension_reg_your_ethnicity: values.yourEthnicity,
              event_value: values.yourEthnicity,
            });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.your_ethnicity" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("yourEthnicity", target.value);
                      setFieldError("yourEthnicity", undefined);
                    } else {
                      setFieldValue("yourEthnicity", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("yourEthnicity", true);
                  }}
                  touched={touched}
                  id="yourEthnicity"
                  errors={errors}
                  values={values}
                  options={ethnicityOptions}
                  required
                />
              </Col>
              <Col xs={12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.nativeLanguage" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("yourNativeLanguage", target.value);
                      setFieldError("yourNativeLanguage", undefined);
                    } else {
                      setFieldValue("yourNativeLanguage", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("yourNativeLanguage", true);
                  }}
                  touched={touched}
                  id="yourNativeLanguage"
                  errors={errors}
                  values={values}
                  options={nativeLanguage}
                  required
                />
              </Col>
              <Col xs={12} className="registration-dialog__subheader">
                <FormattedMessage id="registration.ethnicity.mother_detail" />
              </Col>
              <Col xs={12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.country_of_origin" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("countryMother", target.value);
                      setFieldError("countryMother", undefined);
                    } else {
                      setFieldValue("countryMother", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("countryMother", true);
                  }}
                  touched={touched}
                  id="countryMother"
                  errors={errors}
                  values={values}
                  options={stateOptions}
                  required
                />
              </Col>
              <Col xs={12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.ethnicity" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("ethnicityMother", target.value);
                      setFieldError("ethnicityMother", undefined);
                    } else {
                      setFieldValue("ethnicityMother", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("ethnicityMother", true);
                  }}
                  touched={touched}
                  id="ethnicityMother"
                  errors={errors}
                  values={values}
                  options={ethnicityOptions}
                  required
                />
              </Col>
              <Col xs={12} className="registration-dialog__subheader">
                <FormattedMessage id="registration.ethnicity.father_detail" />
              </Col>
              <Col xs={12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.country_of_origin" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("countryFather", target.value);
                      setFieldError("countryFather", undefined);
                    } else {
                      setFieldValue("countryFather", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("countryFather", true);
                  }}
                  touched={touched}
                  id="countryFather"
                  errors={errors}
                  values={values}
                  options={stateOptions}
                  required
                />
              </Col>
              <Col xs={12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.ethnicity" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("ethnicityFather", target.value);
                      setFieldError("ethnicityFather", undefined);
                    } else {
                      setFieldValue("ethnicityFather", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("ethnicityFather", true);
                  }}
                  touched={touched}
                  id="ethnicityFather"
                  errors={errors}
                  values={values}
                  options={ethnicityOptions}
                  required
                />
              </Col>

              <Col xs={12} className="typo-body1 registration-dialog__agreement">
                <FormattedMessage id="registration.family_agreement" />
              </Col>
              <Col xs={12} className="registration-dialog__agreement-options">
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.family_agreement.yes" })}
                    name="familyAgreement"
                    value="YES"
                    id="agreement-yes"
                    checked={values.familyAgreement === "YES"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                  />
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.family_agreement.no" })}
                    name="familyAgreement"
                    value="NO"
                    id="agreement-no"
                    checked={values.familyAgreement === "NO"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                  />
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.family_agreement.unknown" })}
                    name="familyAgreement"
                    value="UNKNOWN"
                    id="agreement-unknown"
                    checked={values.familyAgreement === "UNKNOWN"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationEthnicityZa.ethnicityOptions = [
  { label: "Black", value: "BLACK" },
  { label: "Coloured", value: "COLOURED" },
  { label: "Asian/Indian", value: "ASIAN/INDIAN" },
  { label: "White", value: "WHITE" },
  { label: "Other", value: "OTHER" },
  { label: "I don’t know", value: "DONT_KNOW" },
  { label: "I prefer not to tell", value: "DONT_TELL" },
];

RegistrationDialogStepRegistrationEthnicityZa.nativeLanguage = [
  { label: "Afrikaans", value: "AFRIKAANS" },
  { label: "English", value: "ENGLISH" },
  { label: "isiNdebele", value: "ISINDEBELE" },
  { label: "Sepedi", value: "SEPEDI" },
  { label: "Sesotho", value: "SESOTHO" },
  { label: "Setswana", value: "SETSWANA" },
  { label: "Siswati", value: "SISWATI" },
  { label: "Tshivenḓa", value: "TSHIVENḒA" },
  { label: "isiXhosa", value: "ISIXHOSA" },
  { label: "Xitsonga", value: "XITSONGA" },
  { label: "isiZulu", value: "ISIZULU" },
  { label: "Other", value: "OTHER" },
  { label: "I don’t know", value: "DONT_KNOW" },
  { label: "I prefer not to tell", value: "DONT_TELL" },
];

RegistrationDialogStepRegistrationEthnicityZa.stateOptions = [
  { label: "I don’t know", value: "DONT_KNOW" },
  { label: "I prefer not to tell", value: "DONT_TELL" },
  { label: "South Africa", value: "ZA" },
  { label: "Afghanistan", value: "AF" },
  { label: "Åland Islands", value: "AX" },
  { label: "Albania", value: "AL" },
  { label: "Algeria", value: "DZ" },
  { label: "American Samoa", value: "AS" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Antarctica", value: "AQ" },
  { label: "Antigua and Barbuda", value: "AG" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbaijan", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belarus", value: "BY" },
  { label: "Belgium", value: "BE" },
  { label: "Belice", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermuda Islands", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia and Herzegovina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvet Island", value: "BV" },
  { label: "Brazil", value: "BR" },
  { label: "British Virgin Islands", value: "VG" },
  { label: "Brunei", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burma", value: "MM" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "British Indian Ocean Territory", value: "IO" },
  { label: "Cape Verde", value: "CV" },
  { label: "Cambodia", value: "KH" },
  { label: "Cameroon", value: "CM" },
  { label: "Canada", value: "CA" },
  { label: "Cayman Islands", value: "KY" },
  { label: "Central African Republic", value: "CF" },
  { label: "Chad", value: "TD" },
  { label: "China", value: "CN" },
  { label: "Chile", value: "CL" },
  { label: "Cyprus", value: "CY" },
  { label: "Christmas island", value: "CX" },
  { label: "Cocos Keeling Islands", value: "CC" },
  { label: "Colombia", value: "CO" },
  { label: "Comoros", value: "KM" },
  { label: "Cook islands", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Croatia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Czech Republic", value: "CZ" },
  { label: "Democratic Republic of the Congo", value: "CD" },
  { label: "Denmark", value: "DK" },
  { label: "Dominica", value: "DM" },
  { label: "Dominican Republic", value: "DO" },
  { label: "East Timor", value: "TL" },
  { label: "Ecuador", value: "EC" },
  { label: "Egypt", value: "EG" },
  { label: "Eel", value: "AI" },
  { label: "Eritrea", value: "ER" },
  { label: "Estonia", value: "EE" },
  { label: "Ethiopia", value: "ET" },
  { label: "Equatorial Guinea", value: "GQ" },
  { label: "Falkland Islands", value: "FK" },
  { label: "Faroe Islands", value: "FO" },
  { label: "Finland", value: "FI" },
  { label: "Fiji", value: "FJ" },
  { label: "France", value: "FR" },
  { label: "French Guiana", value: "GF" },
  { label: "French Southern and Antarctic Territories", value: "TF" },
  { label: "Gabon", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Germany", value: "DE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Grenada", value: "GD" },
  { label: "Greece", value: "GR" },
  { label: "Greenland", value: "GL" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard and McDonald Islands", value: "HM" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Hungary", value: "HU" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Iraq", value: "IQ" },
  { label: "Iran", value: "IR" },
  { label: "Ireland", value: "IE" },
  { label: "Isle of Man", value: "IM" },
  { label: "Iceland", value: "IS" },
  { label: "Israel", value: "IL" },
  { label: "Ivory coast", value: "CI" },
  { label: "Italy", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japan", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordan", value: "JO" },
  { label: "Kazakhstan", value: "KZ" },
  { label: "Kenya", value: "KE" },
  { label: "Kyrgyzstan", value: "KG" },
  { label: "Kiribati", value: "KI" },
  { label: "Kosovo", value: "XK" },
  { label: "Kuwait", value: "KW" },
  { label: "Laos", value: "LA" },
  { label: "Latvia", value: "LV" },
  { label: "Lesotho", value: "LS" },
  { label: "Lebanon", value: "LB" },
  { label: "Liberia", value: "LR" },
  { label: "Libya", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lithuania", value: "LT" },
  { label: "Luxembourg", value: "LU" },
  { label: "Macau", value: "MO" },
  { label: "Macedonia", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malaysia", value: "MY" },
  { label: "Malawi", value: "MW" },
  { label: "Maldiva's Islands", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Morocco", value: "MA" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauricio", value: "MU" },
  { label: "Mauritania", value: "MR" },
  { label: "Mayotte", value: "YT" },
  { label: "Mexico", value: "MX" },
  { label: "Micronesia", value: "FM" },
  { label: "Moldova", value: "MD" },
  { label: "Monaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Mozambique", value: "MZ" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "New caledonia", value: "NC" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Norfolk island", value: "NF" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "North Korea", value: "KP" },
  { label: "Norway", value: "NO" },
  { label: "New Zealand", value: "NZ" },
  { label: "Netherlands Pakistan", value: "NL" },
  { label: "Occidental Sahara", value: "EH" },
  { label: "Oman", value: "OM" },
  { label: "Pakistan", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestine", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua New Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippines", value: "PH" },
  { label: "Pitcairn islands", value: "PN" },
  { label: "French polynesia", value: "PF" },
  { label: "Poland", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Republic of the Congo", value: "CG" },
  { label: "Reunión", value: "RE" },
  { label: "Rwanda", value: "RW" },
  { label: "Romania", value: "RO" },
  { label: "Russia", value: "RU" },
  { label: "Samoa", value: "WS" },
  { label: "San Bartolome", value: "BL" },
  { label: "Saint Kitts and Nevis", value: "KN" },
  { label: "San Marino", value: "SM" },
  { label: "Saint Martin (France)", value: "MF" },
  { label: "Saint Pierre and Miquelon", value: "PM" },
  { label: "St. Vincent and the Grenadines", value: "VC" },
  { label: "St. Helen", value: "SH" },
  { label: "St. Lucia", value: "LC" },
  { label: "Sao Tome and Principe", value: "ST" },
  { label: "Saudi Arabia", value: "SA" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Singapore", value: "SG" },
  { label: "Syria", value: "SY" },
  { label: "Slovakia", value: "SK" },
  { label: "Slovenia", value: "SI" },
  { label: "Somalia", value: "SO" },
  { label: "Solomon Islands", value: "SB" },
  { label: "South Georgia and the South Sandwich Islands", value: "GS" },
  { label: "South Korea", value: "KR" },
  { label: "Spain", value: "ES" },
  { label: "Sri lanka", value: "LK" },
  { label: "Sudan", value: "SD" },
  { label: "Sweden", value: "SE" },
  { label: "Swiss", value: "CH" },
  { label: "Surinam", value: "SR" },
  { label: "Svalbard and Jan Mayen", value: "SJ" },
  { label: "Swaziland", value: "SZ" },
  { label: "Tajikistan", value: "TJ" },
  { label: "Thailand", value: "TH" },
  { label: "Taiwan", value: "TW" },
  { label: "Tanzania", value: "TZ" },
  { label: "The Savior", value: "SV" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad and Tobago", value: "TT" },
  { label: "Tunisia", value: "TN" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks and Caicos Islands", value: "TC" },
  { label: "Turkey", value: "TR" },
  { label: "Tuvalu", value: "TV" },
  { label: "Ukraine", value: "UA" },
  { label: "Uganda", value: "UG" },
  { label: "United Arab Emirates", value: "AE" },
  { label: "United Kingdom", value: "GB" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "United States of America", value: "US" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistan", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Vatican City", value: "VA" },
  { label: "Venezuela", value: "VE" },
  { label: "Vietnam", value: "VN" },
  { label: "Virgin Islands of the United States", value: "VI" },
  { label: "Wallis and Futuna", value: "WF" },
  { label: "Yemen", value: "YE" },
  { label: "Djibouti", value: "DJ" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabwe", value: "ZW" },
];

RegistrationDialogStepRegistrationEthnicityZa.stepName = "registration_ethnicity";

RegistrationDialogStepRegistrationEthnicityZa.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationEthnicityZa;
