import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import BackgroundImage from "../backgroundImage/backgroundImage";

import "./donationHeader.scss";

const DonationHeader = (props) => {
  const {
    backgroundImage,
    children,
    text,
    icon,
  } = props;

  return (
    <div className="donation-header container--fullwidth">
      <BackgroundImage
        className="donation-header__container"
        backgroundImageStyle={backgroundImage}
      >
        <Container fluid>
          <Row className="donation-header__box-container">
            <Col lg={{ offset: 5, span: 7 }} xl={{ offset: 6, span: 6 }}>
              <Row>
                <Col md={7} lg={12}>
                  {children}
                </Col>
                <Col md={5} lg={12} className="donation-header__impact-container">
                  {text && (
                    <div className="donation-header__impact">
                      {icon}
                      {text}
                    </div>
                  )}
                  <BackgroundImage
                    className="donation-header__image"
                    backgroundImageStyle={backgroundImage}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </BackgroundImage>
    </div>
  );
};

DonationHeader.propTypes = {
  backgroundImage: PropTypes.shape({
    backgroundImage: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  text: PropTypes.string,
};

DonationHeader.defaultProps = {
  backgroundImage: {
    backgroundImage: undefined,
  },
  icon: undefined,
  text: undefined,
};

export default DonationHeader;
