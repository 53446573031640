import React from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";

import { setNavigationHeaderEnabled, setShowRegistrationReminder } from "../../../features/registrationSlice";
import { IllustrationCheck } from "../../../images";
import IconButton from "../../button/iconButton";

const RegistrationDialogStepSuccessPl = ({ content }) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  dispatch(
    setNavigationHeaderEnabled(false),
  );

  dispatch(
    setShowRegistrationReminder(false),
  );

  return (
    <>
      <Row className="mt-30">
        <Col xs={12} lg={{ offset: 1, span: 10 }} className="text-center">
          <IllustrationCheck />
          <h3 className="mt-24">
            <FormattedMessage id="registration.submit.title" />
          </h3>
          {/* eslint-disable-next-line react/no-danger */}
          <p className="text-black" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "registration.submit.text" }) }} />
          <div className="pt-36">
            {content.successButton}
          </div>
          <div className="pt-18">
            <IconButton
              variant="inverted-primary"
              target="_self"
              href="/"
            >
              <FormattedMessage id="registration.submit.button_homepage" />
            </IconButton>
          </div>
        </Col>
      </Row>
    </>
  );
};

RegistrationDialogStepSuccessPl.stepName = "registration_success";

RegistrationDialogStepSuccessPl.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default RegistrationDialogStepSuccessPl;
