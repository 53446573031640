import React from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import "./dkmsNumbers.scss";

const DkmsNumbers = (props) => {
  const {
    yearFounded,
    registeredDonors,
    steamCellDonations,
    icon,
    headline,
  } = props;

  const intl = useIntl();

  return (
    <div className="dkms-numbers">
      <div className="dkms-numbers__headline-container">
        <div className="dkms-numbers__icon">{icon}</div>
        <div className="dkms-numbers__headline">{headline}</div>
      </div>
      <div className="dkms-numbers__number">{yearFounded}</div>
      <div className="dkms-numbers__text"><FormattedMessage id="dkms_numbers.year_founded" /></div>
      <div className="dkms-numbers__number">{intl.formatNumber(registeredDonors)}</div>
      <div className="dkms-numbers__text"><FormattedMessage id="dkms_numbers.registered_donors" /></div>
      <div className="dkms-numbers__number">{intl.formatNumber(steamCellDonations)}</div>
      <div className="dkms-numbers__text"><FormattedMessage id="dkms_numbers.steam_cell_donations" /></div>
    </div>
  );
};

DkmsNumbers.propTypes = {
  headline: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  registeredDonors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  steamCellDonations: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  yearFounded: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DkmsNumbers;
