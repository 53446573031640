import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";

import {
  useGetProfileContactQuery,
  useGetProfileInfoQuery,
  useGetUserAddressQuery,
} from "../../features/io/ioSspApiSlice";
import useMediaQuery from "../../hooks/haiku/useMediaQuery";

const fieldsToExclude = {
  de: ["address", "donor_id", "email", "mobile", "name", "phone"],
  gb: [],
  pl: [],
  us: [],
};

const getUserData = () => {
  const userAddress = useGetUserAddressQuery();
  const profileInfo = useGetProfileInfoQuery();
  const profileContact = useGetProfileContactQuery();

  return {
    city: userAddress?.data?.city,
    donor_id: profileInfo?.data?.donor_id,
    email: profileContact?.data?.email,
    firstname: profileInfo?.data?.firstname,
    houseno: userAddress?.data?.houseno,
    lastname: profileInfo?.data?.lastname,
    mobile: profileContact?.data?.mobile,
    phone: profileContact?.data?.phone,
    salutation: profileInfo?.data?.salutation,
    street: userAddress?.data?.street,
    zipcode: userAddress?.data?.zipcode,
  };
};

export const getSspContactFormData = (fields) => {
  const userData = getUserData();

  const sspContactInitialValues = {
    donor_id: userData.donor_id,
    name: `${userData.salutation} ${userData.firstname} ${userData.lastname}`,
    // eslint-disable-next-line sort-keys
    email: userData.email,
    // eslint-disable-next-line sort-keys
    address: `${userData.street} ${userData.houseno}, ${userData.zipcode} ${userData.city}`,
    message: "",
    mobile: userData.mobile,
    phone: userData.phone,
    privacy_consent: false,
  };

  const inputFields = fields.filter(
    (item) => fieldsToExclude[process.env.GATSBY_SITE].indexOf(item.elements.id.value) === -1,
  );
  const indexOfPrivacyConsent = inputFields.findIndex((x) => x.elements.id.value === "privacy_consent");
  const privacyConsentCheckbox = inputFields[indexOfPrivacyConsent];
  inputFields.splice(indexOfPrivacyConsent, 1);

  return {
    initialValues: sspContactInitialValues,
    inputFields,
    seperateHandledFields: [privacyConsentCheckbox],
  };
};

export const getSspContactFormDataViewList = () => {
  const isXs = useMediaQuery("(max-width: 575px)", true);
  const userData = getUserData();

  const outputObject = {
    donor_id: userData.donor_id,
    name: `${userData.salutation} ${userData.firstname} ${userData.lastname}`,
    // eslint-disable-next-line sort-keys
    email: userData.email,
    // eslint-disable-next-line sort-keys
    address: isXs
      ? (
        <>
          <div>{userData.street} {userData.houseno}</div>
          <div>{userData.zipcode} {userData.city}</div>
        </>
      )
      : `${userData.street} ${userData.houseno} | ${userData.zipcode} ${userData.city}`,
    mobile: userData.mobile,
    phone: userData.phone,
  };

  return (
    <div className="dynamic-form__datalist">
      {Object.keys(outputObject).map((item) => (
        <div className="d-flex" key={item}>
          <div className="dynamic-form__datalist-label">
            <FormattedMessage id={`ssp_contact_form.${item}`} />
          </div>
          <div className="dynamic-form__datalist-value">{outputObject[item] || "—"}</div>
        </div>
      ))}
    </div>
  );
};
