import React from "react";

import PropTypes from "prop-types";

import "./highlightArticles.scss";

const HighlightArticles = ({ children }) => (
  <div className="highlight-articles">
    {React.Children.toArray(children).map((child, index) => (
      <div key={`highlight-articles__item${index.toString()}`} className="highlight-articles__item">
        {React.cloneElement(child, { imageRight: index % 2 !== 0 })}
      </div>
    ))}
  </div>
);

HighlightArticles.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HighlightArticles;
