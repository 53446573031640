import React, { useEffect, useState } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useEventListener from "../../hooks/haiku/useEventListener";
import { IconAccordionExpand18, IconAccordionMinimize18 } from "../../icons";
import Button from "../button/button";
import TitleRow from "../titleRow";

import "./assetsSection.scss";

const AssetsSection = (props) => {
  const {
    children,
    headline,
    description,
    variant,
  } = props;

  const [numberOfShowTiles, setNumberOfShowTiles] = useState(4);
  const [numberOfTiles, setNumberOfTiles] = useState(4);

  const resize = () => {
    let tmpNumberOfTiles;

    if (typeof window !== "undefined") {
      switch (variant) {
        case "virtual-drive-video-embed":
          tmpNumberOfTiles = window.innerWidth >= 992 ? 3 : 4;
          break;
        case "virtual-drive-image":
          if (window.innerWidth >= 992) {
            tmpNumberOfTiles = 8;
          } else if (window.innerWidth < 992 && window.innerWidth >= 768) {
            tmpNumberOfTiles = 6;
          } else {
            tmpNumberOfTiles = 4;
          }
          break;
        default:
          tmpNumberOfTiles = window.innerWidth >= 992 ? 6 : 4;
      }
    }

    setNumberOfTiles(tmpNumberOfTiles);

    if (numberOfShowTiles !== tmpNumberOfTiles) {
      setNumberOfShowTiles(tmpNumberOfTiles);
    }
  };

  useEffect(() => {
    resize();
  }, []);

  useEventListener("resize", resize);

  let colProps = {
    lg: 4,
    md: 6,
  };

  if (variant === "virtual-drive-image") {
    colProps = {
      lg: 3,
      md: 4,
      sm: 6,
    };
  }

  return (
    <div className="asset-section">
      <TitleRow description={description}>{headline}</TitleRow>
      <Row className="asset-section__content">
        {React.Children.map(children, (child, index) => (
          index < numberOfShowTiles && (
            <Col {...colProps}>
              {child}
            </Col>
          )
        ))}
      </Row>
      {!(children.length <= numberOfTiles) && (numberOfShowTiles < children.length ? (
        <Button
          onClick={() => {
            setNumberOfShowTiles(numberOfShowTiles + numberOfTiles);
          }}
          variant="inverted-primary"
        >
          <FormattedMessage id="hubsection.show_more" /> <IconAccordionExpand18 />
        </Button>
      ) : (
        <Button
          onClick={() => {
            setNumberOfShowTiles(numberOfTiles);
          }}
          variant="inverted-primary"
        >
          <FormattedMessage id="hubsection.show_less" /> <IconAccordionMinimize18 />
        </Button>
      ))}
    </div>
  );
};

AssetsSection.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  headline: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["virtual-drive-video-embed", "virtual-drive-image"]),
};

AssetsSection.defaultProps = {
  description: null,
  variant: null,
};

export default AssetsSection;
