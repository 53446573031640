import React from "react";

import PropTypes from "prop-types";

import "./targetGroupSection.scss";

const TargetGroupSection = ({ children, headline }) => (
  <div className="target-group-section">
    <h4>{headline}</h4>
    <div className="d-flex flex-column flex-md-row flex-md-wrap">
      {React.Children.toArray(children)}
    </div>
  </div>
);

TargetGroupSection.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
};

export default TargetGroupSection;
