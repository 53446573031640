import React from "react";

import PropTypes from "prop-types";

import NavigationLink from "../navigationLink/navigationLink";

import "./targetGroup.scss";

const TargetGroup = (props) => {
  const {
    title,
    href,
    icon,
  } = props;

  return (
    <NavigationLink className="target-group" href={href} key={title}>
      <div className="target-group__icon">{icon}</div>
      <div className="target-group__title">{title}</div>
    </NavigationLink>
  );
};

TargetGroup.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
};

export default TargetGroup;
