import React from "react";

import PropTypes from "prop-types";

import "./testimonial.scss";

const Testimonial = (props) => {
  const {
    contactPerson,
    statement,
  } = props;

  return (
    <div className="testimonial">
      <div className="testimonial__contact-person">{contactPerson}</div>
      <div className="testimonial__statement">{statement}</div>
    </div>
  );
};

Testimonial.propTypes = {
  contactPerson: PropTypes.node.isRequired,
  statement: PropTypes.string,
};

Testimonial.defaultProps = {
  statement: null,
};

export default Testimonial;
