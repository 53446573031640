import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Button from "../components/button/button";
import IconButton from "../components/button/iconButton";
import DynamicForm from "../components/dynamicForm/dynamicForm";
import { getSspContactFormData, getSspContactFormDataViewList } from "../components/dynamicForm/sspContactFormHelper";
import ModalView from "../components/modalView/modalView";
import getInternalLinkById from "../utils/getInternalLinkById";

const KontentItemSspContactForm = (props) => {
  const {
    buttonText,
    variant,
    overwriteButton,
    buttonProps,
  } = props;
  const { kontentItemForm } = useStaticQuery(graphql`
    {
      kontentItemForm(elements: {ssp_contact: {value: {elemMatch: {codename: {eq: "true"}}}}}) {
        id
        system {
          id
        }
        elements {
          metadata__meta_title {
            value
          }
          headline {
            value
          }
          button_text {
            value
          }
          url_pattern {
            value
          }
          thank_you_page {
            value {
              id
            }
          }
              fields {
                value {
                  internal {
                    type
                  }
                  ...KontentItemInputTypeText
                  ...KontentItemInputTypeSingleInput
                  ...KontentItemInputTypeCheckbox
                  ...KontentItemInputTypeAddress
                  ...KontentItemInputTypeEmail
                  ...KontentItemInputTypeDropdown
                  ...KontentItemInputTypeDate
                  ...KontentItemInputTypeState
                  ...KontentItemInputTypeCountry
                  ...KontentItemInputTypeName
                  ...KontentItemInputTypeSalutation
                  ...KontentItemSectionHeader
                  ...KontentItemFormRichText
                }
              }
              ssp_contact {
                value {
                  codename
                }
              }
            }
      }
    }
  `);

  let thankYouLink;

  if (kontentItemForm.elements?.thank_you_page?.value.length > 0) {
    thankYouLink = getInternalLinkById(kontentItemForm.elements.thank_you_page.value[0].id);
  }

  const contactFormData = getSspContactFormData(
    kontentItemForm.elements.fields.value,
  );

  return (
    <ModalView
      buttonText={buttonText}
      title={kontentItemForm.elements.metadata__meta_title.value}
      text={(
        <Row className="dynamic-form ssp-contact-form">
          <Col xs={12} className="dynamic-form__title">
            {kontentItemForm.elements.headline.value}
          </Col>
          <Col xs={12}>
            <DynamicForm
              thankYouPageLink={thankYouLink}
              buttonText={kontentItemForm.elements.button_text.value}
              fields={contactFormData.inputFields}
              kontentItemID={kontentItemForm.system.id}
              formSlug={kontentItemForm.elements.url_pattern.value}
              initValues={contactFormData.initialValues}
              seperateHandledFields={contactFormData.seperateHandledFields}
              contactFormDataView={(
                <Col xs={12}>
                  {getSspContactFormDataViewList()}
                </Col>
              )}
            />
          </Col>
        </Row>
      )}
      overWriteButton={overwriteButton
        ? (
          <IconButton
            variant={variant}
            {...buttonProps}
          />
        )
        : (
          <Button
            variant={variant}
            {...buttonProps}
          />
        )}
    />
  );
};

KontentItemSspContactForm.propTypes = {
  buttonProps: PropTypes.node,
  buttonText: PropTypes.string.isRequired,
  overwriteButton: PropTypes.bool,
  variant: PropTypes.string.isRequired,
};

KontentItemSspContactForm.defaultProps = {
  buttonProps: {},
  overwriteButton: false,
};

export default KontentItemSspContactForm;
