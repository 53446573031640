import React, { useRef } from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconDownload24 } from "../../icons";
import downloadFile from "../../utils/downloadFile";
import IconButton from "../button/iconButton";
import TextLightbox from "../lightbox/textLightbox";

import "./imageDownload.scss";

const ImageDownload = (props) => {
  const {
    image,
    category,
    modalTitle,
    modalContent,
    downloadLink,
  } = props;

  const downloadLightbox = useRef(null);
  const intl = useIntl();

  return (
    <>
      <TextLightbox
        ref={downloadLightbox}
        title={modalTitle}
        variant="download"
      >
        {modalContent}
      </TextLightbox>
      <div className="image-download">
        <div
          className="image-download__image"
        >
          {image}
        </div>
        <div
          className="image-download__shadow"
          onClick={() => {
            downloadLightbox.current.show();
          }}
          data-category={category}
          role="presentation"
        />
        <div className="image-download__button">
          <IconButton
            onClick={
              () => downloadFile(downloadLink, image.props.data.elements.image.value[0].name)
            }
            iconOnly
            variant="inverted-light"
            aria-label={intl.formatMessage({ id: "image_download.download.aria_label" })}
          >
            <IconDownload24 />
          </IconButton>
        </div>
      </div>
    </>
  );
};

ImageDownload.propTypes = {
  category: PropTypes.string.isRequired,
  downloadLink: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  modalContent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  modalTitle: PropTypes.string.isRequired,
};

export default ImageDownload;
