import React, { useRef, useState } from "react";

import classNames from "classnames";
import { graphql, StaticQuery } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";

import { IconHelp24, IconHideHelp24, IconMessage24 } from "../../icons";
import SimpleRichText from "../../templates/text/simpleRichText";
import { camelCase } from "../../utils/stringUtilHelper";
import Button from "../button/button";
import LinkButton from "../button/linkButton";
import CookieBanner from "../consent/cookieBanner";
import TextLightbox from "../lightbox/textLightbox";
import Logo from "../logo";
import BottomFooterNavigation from "../navigation/bottomFooterNavigation";
import StateAwareAccordionToggle from "../stateAwareAccordionToggle/stateAwareAccordionToggle";

import "./registrationDialogFaq.scss";

const StaticQueryRegistrationDialogFaq = (props) => {
  const {
    faqData,
    content,
    dialogType,
    stepNames,
    currentStep,
    image,
  } = props;

  const intl = useIntl();

  const [currentFaqItem, setCurrentFaqItem] = useState({});
  const [open, setOpen] = useState(false);
  const stepError = useSelector((state) => state.registration.stepError);

  const faqItems = [];

  faqData?.nodes?.filter((node) => node.elements.faq_category.value.find(
    (el) => el.codename === stepNames[currentStep],
  )).forEach((faq) => {
    let answer = sanitizeHtml(faq.elements.answer.value);
    let truncated = false;

    if (answer.length >= 260) {
      truncated = true;
      answer = `${answer.substring(0, 260)}...`;
    }

    faqItems.push({
      answer,
      fullAnswer: faq.elements.answer,
      id: faq.id,
      question: faq.elements.question.value,
      truncated,
    });
  });

  const faqModal = useRef(null);

  let learnMore;
  let faqStuck;
  let contactUs;
  const lightboxTitle = "registration.faq";

  switch (dialogType) {
    case "virtual-drive-setup":
      learnMore = "virtual_drive_setup.faq_learn_more";
      faqStuck = "virtual_drive_setup.faq_stuck";
      contactUs = "virtual_drive_setup.faq_contact_us";
      break;
    default:
      learnMore = "registration.faq.learn_more";
      faqStuck = "registration.faq.stuck";
      contactUs = "registration.faq.contact_us";
  }

  return (
    <>
      <div
        className={classNames(
          "registration-dialog-faq",
          {
            [`${dialogType}-dialog-faq`]: dialogType !== "registration-dialog",
            open,
            "virtual-drive-setup-dialog-faq--success": (dialogType === "virtual-drive-setup" && currentStep === stepNames.length - 1),
          },
        )}
        style={{ backgroundImage: `url(${image})` }}
      >
        <button
          type="button"
          className="registration-dialog-faq__toggler"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? <IconHideHelp24 /> : <IconHelp24 />}
        </button>
        <Logo breakpoint="lg" white />

        <div className="registration-dialog-faq__scroller">
          {faqItems.length > 0 ? (
            <Accordion className="registration-dialog-faq__accordion">
              {faqItems.map((faqItem) => (
                <div key={faqItem.id} className="registration-dialog-faq__element">
                  <StateAwareAccordionToggle as="div" eventKey={faqItem.id} className="registration-dialog-faq__question">
                    <>{faqItem.question}</>
                  </StateAwareAccordionToggle>
                  <Accordion.Collapse eventKey={faqItem.id} className="registration-dialog-faq__answer">
                    <>
                      {/* eslint-disable-next-line react/no-danger */}
                      <div dangerouslySetInnerHTML={{ __html: faqItem.answer }} />
                      {faqItem.truncated && (
                        <Button
                          variant="inverted-light"
                          onClick={() => {
                            setCurrentFaqItem(faqItem);
                            faqModal.current.show();
                          }}
                        >
                          <FormattedMessage id={learnMore} />
                        </Button>
                      )}
                    </>
                  </Accordion.Collapse>
                </div>
              ))}
            </Accordion>
          ) : (
            <>
              {!stepError && (
                <h4>
                  <FormattedMessage id={faqStuck} />
                </h4>
              )}
            </>
          )}

          {!stepError && (
            <LinkButton
              variant="normal"
              href={content.contactLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconMessage24 />
              <FormattedMessage id={contactUs} />
            </LinkButton>
          )}

          <TextLightbox
            ref={faqModal}
            title={intl.formatMessage({ id: lightboxTitle })}
            variant="registration-dialog"
          >
            <Row className="text-modal-content">
              <Col xs={12}>
                <h3>{currentFaqItem.question}</h3>
                <SimpleRichText data={currentFaqItem.fullAnswer} />
              </Col>
            </Row>
          </TextLightbox>

          <div className="registration-dialog-faq__footer d-none d-lg-flex">
            <BottomFooterNavigation isOpenInNewTab />
          </div>

        </div>
      </div>

      <CookieBanner openLinksInNewTab />
    </>
  );
};

function RegistrationDialogFaq(props) {
  // eslint-disable-next-line react/prop-types
  const { dialogType } = props;

  return (
    <StaticQuery
      query={graphql`
        {
          registrationDialog: allKontentItemFaqRegisterDialogue(filter: {elements: {faq_category: {value: {elemMatch: {codename: {eq: "registration_dialogue"}}}}}}) {
            nodes {
              id
              elements {
                question {
                  value
                }
                answer {
                  value
                }
                faq_category {
                  value {
                    codename
                  }
                }
              }
            }
          }
          
          virtualDriveSetup: allKontentItemFaqRegisterDialogue(filter: {elements: {faq_category: {value: {elemMatch: {codename: {eq: "virtual_drives_setup"}}}}}}) {
            nodes {
              id
              elements {
                question {
                  value
                }
                answer {
                  value
                }
                faq_category {
                  value {
                    codename
                  }
                }
              }
            }
          }
          
          payment: allKontentItemFaqRegisterDialogue(filter: {elements: {faq_category: {value: {elemMatch: {codename: {eq: "payment_dialogue"}}}}}}) {
            nodes {
              id
              elements {
                question {
                  value
                }
                answer {
                  value
                }
                faq_category {
                  value {
                    codename
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <StaticQueryRegistrationDialogFaq
          faqData={data[camelCase(dialogType)]}
          {...props}
        />
      )}
    />
  );
}

StaticQueryRegistrationDialogFaq.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  currentStep: PropTypes.number.isRequired,
  dialogType: PropTypes.oneOf(["virtual-drive-setup", "payment", "registration-dialog"]),
  faqData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  image: PropTypes.string.isRequired,
  stepNames: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

StaticQueryRegistrationDialogFaq.defaultProps = {
  dialogType: "registration-dialog",
};

export default RegistrationDialogFaq;
