import React from "react";

import differenceInSeconds from "date-fns/differenceInSeconds";

import { formatInTimeZone } from "./dateHelper";
import KontentItemImage from "../templates/kontentItemImage";

const MapMarkerCreator = (events, force = false) => {
  const eventList = [];

  const oneDay = 86400;

  try {
    const filteredEvents = events.filter((event) => {
      if (event.elements.coordinates.value === null) {
        return false;
      }

      if (force) {
        return true;
      }

      if (typeof event.elements.date_end !== "undefined") {
        return differenceInSeconds(
          new Date(),
          new Date(event.elements.date_end.value),
        ) < oneDay;
      }

      return differenceInSeconds(
        new Date(),
        new Date(event.elements.date_start.value),
      ) < oneDay;
    });

    filteredEvents.forEach((event) => {
      const timezone = event.elements.coordinates.value?.timezone?.timeZoneId
        || Intl.DateTimeFormat().resolvedOptions().timeZone;

      eventList.push(
        {
          date: formatInTimeZone(new Date(event.elements.date_start.value), "P", timezone),
          id: event.id,
          image: (
            <KontentItemImage
              data={event.elements.image}
              height={102}
              width={79}
              defaultImage
              crop
            />
          ),
          lat: JSON.parse(event.elements.coordinates.value).lat,
          lng: JSON.parse(event.elements.coordinates.value).lng,
          title: event.elements.title.value,
        },
      );
    });
  } catch (e) {
    return [];
  }

  return eventList;
};

export default MapMarkerCreator;
