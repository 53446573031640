import React, { useEffect, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./radioButtonSection.scss";

const RadioButtonSection = (props) => {
  const {
    children,
    notice,
    className,
  } = props;

  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    let maxWidthTmp = 0;

    if (document && window) {
      React.Children.forEach(children, (child) => {
        const child2 = document.getElementById(`${child.props.id}_label`);

        if (child2 !== null && child2 !== undefined && maxWidthTmp < child2.offsetWidth
          + parseInt(window.getComputedStyle(child2).marginRight, 10)) {
          maxWidthTmp = child2.offsetWidth
            + parseInt(window.getComputedStyle(child2).marginRight, 10);
        }
      });
    }

    if (maxWidth < maxWidthTmp) {
      setMaxWidth(maxWidthTmp);
    }
  }, []);

  return (
    <div className={classNames("radio-button-section", className)}>
      <div className="radio-button-section__items">
        {React.Children.map(children, (child) => (
          React.cloneElement(child, { key: child.props.id, width: maxWidth })
        ))}
      </div>
      {notice && (
        <div className="radio-button-section__notice">
          {notice}
        </div>
      )}
    </div>
  );
};

RadioButtonSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  notice: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

RadioButtonSection.defaultProps = {
  className: "",
  notice: null,
};

export default RadioButtonSection;
