import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import { format } from "../../utils/dateHelper";
import BackgroundImage from "../backgroundImage/backgroundImage";

import "./heroCarouselCard.scss";

const HeroCarouselCard = (props) => {
  const {
    children,
    backgroundImageStyle,
    button,
    menuTitle,
    date,
    isCampaign,
    isPress,
    category,
  } = props;

  let tag = category;

  if (isCampaign) {
    tag = <FormattedMessage id="hero_carousel_card.campaign" />;
  } else if (isPress) {
    tag = <FormattedMessage id="hero_carousel_card.press_release" />;
  }

  return (
    <div id={menuTitle}>
      <BackgroundImage
        className={classNames(
          "hero-carousel-card",
          { "hero-carousel-card--highlight": date || tag !== null },
        )}
        backgroundImageStyle={backgroundImageStyle}
      >
        <div className="hero-carousel-card__gradient">
          <Container fluid>
            <Row className="hero-carousel-card__content-container">
              <Col md={10} lg={8}>
                {
                  (date || tag) && (
                    <div className="hero-carousel-card__category-container">
                      {tag && (
                        <div className="hero-carousel-card__category">
                          {tag}
                        </div>
                      )}
                      {date && (
                        <div className="hero-carousel-card__date">
                          {format(date)}
                        </div>
                      )}
                    </div>
                  )
                }
                <div className="typo-h2">{children}</div>
                {button}
              </Col>
            </Row>
          </Container>
        </div>
      </BackgroundImage>
    </div>
  );
};

HeroCarouselCard.propTypes = {
  backgroundImageStyle: PropTypes.oneOfType([PropTypes.object]).isRequired,
  button: PropTypes.oneOfType([PropTypes.object]).isRequired,
  category: PropTypes.string,
  children: PropTypes.node.isRequired,
  date: PropTypes.instanceOf(Date),
  isCampaign: PropTypes.bool,
  isPress: PropTypes.bool,
  menuTitle: PropTypes.string.isRequired,
};

HeroCarouselCard.defaultProps = {
  category: null,
  date: null,
  isCampaign: false,
  isPress: false,
};

export default HeroCarouselCard;
