import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import LearnArticleEmbed from "../../components/learnArticleEmbed/learnArticleEmbed";
import KontentItemImage from "../kontentItemImage";

const TopicArticleCards = ({ data }) => {
  const articleData = Array.isArray(data) ? data : data.value;

  const cards = [];

  articleData.forEach((article) => {
    cards.push(
      <LearnArticleEmbed
        headline={article.elements.headline.value}
        image={
          <KontentItemImage data={article.elements.hero_image} height={200} width={200} />
        }
        href={article.id}
        key={article.id}
      />,
    );
  });

  return cards;
};

export const query = graphql`
  fragment TopicArticleCards on kontent_item_article_page_learn {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

TopicArticleCards.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default TopicArticleCards;
