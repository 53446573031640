import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setPaymentData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import RadioButtonList from "../../radioButtonList/radioButtonList";
import RadioButtonSection from "../../radioButtonSection/radioButtonSection";
import RegistrationDialogHeadline from "../../registrationDialog/registrationDialogHeadline";

const PaymentDialogStepRegistrationKeepInTouch = ({ next }) => {
  const paymentData = useSelector((state) => state.registration.paymentData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const validationSchema = useMemo(() => object().shape({
    keepInTouchEmail: string().required(intl.formatMessage({ id: "form.required" })),
    keepInTouchPost: string().required(intl.formatMessage({ id: "form.required" })),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.keep_in_touch.title"
        subheadline="registration.keep_in_touch.text"
      />
      <Formik
        initialValues={paymentData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setPaymentData({
              keepInTouchEmail: values.keepInTouchEmail,
              keepInTouchPost: values.keepInTouchPost,
            }),
          );

          DataLayer.pushEvent("pp_donation_step_keep_in_touch", { event_value: "success" });

          next();
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <RadioButtonSection notice={intl.formatMessage({ id: "keep_in_touch.notice" })}>
              <RadioButtonList
                values={values}
                inline
                label={intl.formatMessage({ id: "keep_in_touch.email" })}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                name="keepInTouchEmail"
                touched={touched}
                id="keepInTouchEmail"
                options={[
                  {
                    checked: values.keepInTouchEmail === "YES",
                    id: "keep_in_touch_email_yes",
                    label: intl.formatMessage({ id: "keep_in_touch.option.yes" }),
                    value: "YES",
                  },
                  {
                    checked: values.keepInTouchEmail === "NO",
                    id: "keep_in_touch_email_no",
                    label: intl.formatMessage({ id: "keep_in_touch.option.no" }),
                    value: "NO",
                  },
                ]}
                onBlur={handleBlur}
                errors={errors}
                setFieldTouched={setFieldTouched}
                required
              />
              <RadioButtonList
                values={values}
                inline
                label={intl.formatMessage({ id: "keep_in_touch.post" })}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                name="keepInTouchPost"
                touched={touched}
                id="keepInTouchPost"
                options={[
                  {
                    checked: values.keepInTouchPost === "YES",
                    id: "keep_in_touch_post_yes",
                    label: intl.formatMessage({ id: "keep_in_touch.option.yes" }),
                    value: "YES",
                  },
                  {
                    checked: values.keepInTouchPost === "NO",
                    id: "keep_in_touch_post_no",
                    label: intl.formatMessage({ id: "keep_in_touch.option.no" }),
                    value: "NO",
                  },
                ]}
                onBlur={handleBlur}
                errors={errors}
                setFieldTouched={setFieldTouched}
                required
              />
            </RadioButtonSection>

            <Form.Group>
              <IconButton type="submit" variant="donation">
                <FormattedMessage id="registration.keep_in_touch.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>
            <div className="footnote">
              {intl.formatMessage({ id: "registration.keep_in_touch.footnote" })}
            </div>
            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

PaymentDialogStepRegistrationKeepInTouch.stepName = "keep_in_touch";

PaymentDialogStepRegistrationKeepInTouch.propTypes = {
  next: PropTypes.func.isRequired,
};

export default PaymentDialogStepRegistrationKeepInTouch;
