import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FormField from "../../components/dynamicForm/formField/formField";
import { getOnPreventKeypress, RegexPatterns } from "../../components/dynamicForm/validators";
import Tooltip from "../../components/localForms/tooltip";

const KontentItemInputTypeName = (props) => {
  const {
    data,
    errors,
    touched,
    meta,
    onChange,
    onBlur,
    values,
  } = props;

  const { elements } = data;

  return (
    <Col xs={12}>
      <Row>
        <Col xs={12} md={6}>
          <Tooltip
            id={elements.id_firstname.value}
            tooltipMessage={elements.tooltip_firstname.value}
          >
            <FormField
              id={elements.id_firstname.value}
              type="text"
              errors={errors}
              touched={touched}
              meta={meta}
              label={elements.label_firstname.value}
              placeholder={elements.placeholder_firstname.value}
              onChange={onChange}
              onBlur={onBlur}
              onKeyPress={getOnPreventKeypress(RegexPatterns.noNumbers)}
              values={values}
              required={meta?.requiredFields?.includes(elements.id_firstname.value)}
            />
          </Tooltip>
        </Col>
        <Col xs={12} md={6}>
          <Tooltip
            id={elements.id_lastname.value}
            tooltipMessage={elements.tooltip_lastname.value}
          >
            <FormField
              id={elements.id_lastname.value}
              type="text"
              errors={errors}
              touched={touched}
              meta={meta}
              label={elements.label_lastname.value}
              placeholder={elements.placeholder_lastname.value}
              onChange={onChange}
              onBlur={onBlur}
              onKeyPress={getOnPreventKeypress(RegexPatterns.noNumbers)}
              values={values}
              required={meta?.requiredFields?.includes(elements.id_lastname.value)}
            />
          </Tooltip>
        </Col>
      </Row>
    </Col>
  );
};

export const query = graphql`
  fragment KontentItemInputTypeName on kontent_item_input_type_name {
    id
    elements {
      id_firstname {
        value
      }
      id_lastname {
        value
      }
      label_firstname {
        value
      }
      label_lastname {
        value
      }
      required {
        value {
          codename
        }
      }
      tooltip_firstname {
        value
      }
      tooltip_lastname {
        value
      }
      placeholder_firstname {
        value
      }
      placeholder_lastname {
        value
      }
    }
  } 
`;

KontentItemInputTypeName.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemInputTypeName;
