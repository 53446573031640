import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconAnchorRight18, IconArticle18 } from "../../icons";
import { format } from "../../utils/dateHelper";
import LinkButton from "../button/linkButton";
import NavigationLink from "../navigationLink/navigationLink";
import Tag from "../tag";

import "./articleTeaser.scss";

const ArticleTeaser = (props) => {
  const {
    variant,
    url,
    headline,
    image,
    category,
    date,
  } = props;

  return (
    <NavigationLink
      href={url}
      className={classNames("article-teaser no-style", variant && `article-teaser--${variant}`)}
    >
      {image && (
        <div className="article-teaser__image">{image}</div>
      )}
      <div className="article-teaser__content">
        {variant === "story" && category && (
          <div className="article-teaser__tag">
            <Tag>{category}</Tag>
          </div>
        )}
        {(variant === "learn" || variant === "news") && (
          <div className="article-teaser__tag">
            <IconArticle18 />
            <FormattedMessage id="article_teaser.article" />
          </div>
        )}
        {variant === "press-release" && (
          <div className="article-teaser__tag">
            <FormattedMessage id="article_teaser.press_release" />
            {date && <div className="article-teaser__date">{format(date)}</div>}
          </div>
        )}
        <div className="article-teaser__headline">{headline}</div>
        <div className="article-teaser__button">
          <LinkButton variant="normal">
            <FormattedMessage id="article_teaser.read_more" /><IconAnchorRight18 />
          </LinkButton>
        </div>
      </div>
    </NavigationLink>
  );
};

ArticleTeaser.propTypes = {
  category: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  headline: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  url: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["learn", "story", "press-release", "news"]).isRequired,
};

ArticleTeaser.defaultProps = {
  category: null,
  date: null,
  image: null,
};

export default ArticleTeaser;
