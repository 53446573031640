import React from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { IconCooperation48, IconDualtoneNewbadge48, IconPrivate18 } from "../../icons";
import BlurredOverlay from "../blurredOverlay/blurredOverlay";
import IconButton from "../button/iconButton";
import IconText from "../iconText/iconText";
import SocialMediaShare from "../socialMediaShare/socialMediaShare";

import "./virtualDriveCounterHero.scss";

const VirtualDriveCounterHero = (props) => {
  const {
    headline,
    image,
    registrations,
    maxRegistration,
    privateDrive,
    registerUrl,
    blurImage,
  } = props;

  const intl = useIntl();

  const { width } = useWindowDimensions();
  const percantageRegistration = (100 / maxRegistration) * registrations;
  let highlightImage = image;

  if (width >= 768) {
    highlightImage = React.cloneElement(image, { aspectRatio: 1.46 });
  }

  return (
    <Row noGutters className="virtual-drive-counter-hero">
      <Col md={6} className="virtual-drive-counter-hero__image">
        {blurImage && <BlurredOverlay image={highlightImage} />}
        {highlightImage}
      </Col>
      <Col md={6} className={classNames("virtual-drive-counter-hero__container", { "without-limit": !maxRegistration })}>
        <div className="virtual-drive-counter-hero__content">
          <h1 className="virtual-drive-counter-hero__headline">
            {headline}
          </h1>
          {(typeof registrations !== "undefined" && registrations !== null) && (
            <div className="virtual-drive-counter-hero__registrations">
              {registrations > 0
                ? <IconCooperation48 className="text-primary" />
                : <IconDualtoneNewbadge48 />}
              <div className="typo-title1">
                {registrations > 0 ? (
                  <>
                    <div>{registrations} {maxRegistration !== null && [`/ ${maxRegistration}`]}</div>
                    <div className="typo-body1">
                      <FormattedMessage id="virtual_drive.registrations" />
                    </div>
                  </>
                ) : (
                  <>
                    {maxRegistration !== null
                      ? <FormattedMessage id="virtual_drive.counter_help_to_reach" values={{ goal: maxRegistration }} />
                      : <FormattedMessage id="virtual_drive.counter_be_first" />}
                  </>
                )}
              </div>
            </div>
          )}
          <IconButton href={registerUrl}>
            <FormattedMessage id="virtual_drive.register_for_this_drive" />
          </IconButton>
          {!privateDrive && (
            <div className="virtual-drive-counter-hero__share-subtext">
              <FormattedMessage id="virtual_drive.share_subtext" />
            </div>
          )}
          <div className={classNames("virtual-drive-counter-hero__share", { "virtual-drive-private": privateDrive })}>
            {privateDrive ? (
              <IconText
                icon={<IconPrivate18 />}
                variant="grey-dark"
                text={intl.formatMessage({ id: "virtual_drive.private" })}
              />
            ) : (
              <SocialMediaShare
                copyUrl
                onlyIcon
                shareTitle={headline}
              />
            )}
          </div>
        </div>
        {maxRegistration !== null && (
          <div className="virtual-drive-counter-hero__progress-bar">
            <div className="virtual-drive-counter-hero__registered" style={{ width: `${percantageRegistration}%` }} />
            <div
              className="virtual-drive-counter-hero__remaining-capacity"
              style={{ width: `${100 - percantageRegistration}%` }}
            />
          </div>
        )}
      </Col>
    </Row>
  );
};

VirtualDriveCounterHero.propTypes = {
  blurImage: PropTypes.bool,
  headline: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  maxRegistration: PropTypes.number,
  privateDrive: PropTypes.bool,
  registerUrl: PropTypes.string.isRequired,
  registrations: PropTypes.number.isRequired,
};

VirtualDriveCounterHero.defaultProps = {
  blurImage: false,
  maxRegistration: null,
  privateDrive: false,
};

export default VirtualDriveCounterHero;
