import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import "./factSheetHeaderData.scss";

const FactSheetHeaderData = (props) => {
  const {
    image,
    data,
    text,
  } = props;

  const intl = useIntl();

  return (
    <div className="fact-sheet-header-data">
      <div className="fact-sheet-header-data__image">
        {image}
      </div>
      <div className="fact-sheet-header-data__data-container">
        <div className="fact-sheet-header-data__data">{intl.formatNumber(data)}</div>
        <div className="fact-sheet-header-data__text">{text}</div>
      </div>
    </div>
  );
};

FactSheetHeaderData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]).isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  text: PropTypes.string.isRequired,
};

export default FactSheetHeaderData;
