import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ElementHeader from "../../components/elementHeader/elementHeader";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import IconProcess from "../../components/iconProcess/iconProcess";
import SEO from "../../components/seo";
import TextWithSidebar from "../../components/textWithSidebar/textWithSidebar";
import KontentItemEyecatcherImage from "../kontentItemEyecatcherImage";
import KontentItemEyecatcherSidebar from "../kontentItemEyecatcherSidebar";
import KontentItemProcessStep from "../kontentItemProcessStep";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";
import TabNavigationGenerator from "../virtualDrive/tabNavigationGenerator";

const KontentItemVdCreateDrive = ({ data }) => {
  const { page } = data;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
        tabNavigation={<TabNavigationGenerator id={page.id} />}
      >
        {page.elements.hero_headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={page.id} />

      <ElementHeader
        headline={page.elements.headline_1.value}
        description={page.elements.text_1.value}
      />

      <IconProcess>
        {KontentItemProcessStep({ data: page.elements.process_steps.value })}
      </IconProcess>

      <ElementHeader
        headline={page.elements.headline_2.value}
        description={page.elements.text_2.value}
      />

      <KontentItemEyecatcherImage data={page.elements.eyecatcher_image} />

      <TextWithSidebar
        text={<RichText data={page.elements.rich_text} />}
        eyecatcher={page.elements.eyecatcher_sidebar.value.length > 0
          ? <KontentItemEyecatcherSidebar data={page.elements.eyecatcher_sidebar.value[0]} />
          : null}
      />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemVdCreateDrive(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_headline {
          value
        }
        hero_image{
          value {
            ...KontentItemImage
          }
        }
        headline_1 {
          value
        }
        headline_2 {
          value
        }
        text_1 {
          value
        }
        text_2 {
          value
        }
        process_steps {
          value {
            ...KontentItemProcessStep
          }
        }
        eyecatcher_image {
          value {
            ...KontentItemEyecatcherImage
          }
        }
        rich_text {
          value
        }
        eyecatcher_sidebar {
          value {
            ...KontentItemEyecatcherSidebar
          }
        }
      }
    }
  }
`;

KontentItemVdCreateDrive.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemVdCreateDrive;
