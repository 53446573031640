import React, { useEffect, useState } from "react";

import { useFormikContext } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { setCurrentRegion, setPrevRegion } from "../../../features/updateAddressSlice";
import { IconError18, IconFillForm24 } from "../../../icons";
import AddressInput from "../../addressInput/addressInput";
import CollapseBox from "../../collapseBox/collapseBox";
import { REGION_CITY_LIST, REGIONS_CL, STATES_IN } from "../descriptions/general-form-data";
import { build } from "../formBuilder";

const FormAddressInput = (props) => {
  const {
    subFields,
    values,
    errors,
    meta,
    onBlur,
    onChange,
    setFieldTouched,
    setFieldValue,
    touched,
    fieldType,
    warning,
  } = props;

  const dispatch = useDispatch();

  const intl = useIntl();

  const [addressShown, setAddressShown] = useState(false);

  const {
    isSubmitting,
  } = useFormikContext();

  useEffect(() => {
    if (errors && Object.keys(errors).some((v) => v.includes(fieldType))) {
      setAddressShown(true);
    }
  }, [isSubmitting]);

  const sameValues = [];

  if (Object.keys(warning).length > 0) {
    subFields.forEach((value) => {
      if (warning[value.props.id]) {
        sameValues.push(value.props.label);
      }
    });

    if (warning.firstname) {
      sameValues.push(warning.firstname);
    }

    if (warning.lastname) {
      sameValues.push(warning.lastname);
    }
  }

  return (
    <>
      <AddressInput
        onResult={(data) => {
          setAddressShown(true);

          let regionOption;
          let cityOption;
          let tmpStateCode;

          switch (process.env.GATSBY_SITE) {
            case "de":
              setFieldValue(`${fieldType}_street`, data.street || "");
              setFieldValue(`${fieldType}_house_no`, data.houseno || "");
              setFieldValue(`${fieldType}_postcode`, data.zipcode || "");
              setFieldValue(`${fieldType}_city`, data.city || "");
              break;
            case "us":
              setFieldValue(`${fieldType}_street`, data.street || "");
              setFieldValue(`${fieldType}_house_no`, data.houseno || "");
              setFieldValue(`${fieldType}_postcode`, data.zipcode || "");
              setFieldValue(`${fieldType}_city`, data.city || "");
              setFieldValue(`${fieldType}_state`, data.state_code || "");
              break;
            case "pl":
              setFieldValue(`${fieldType}_street`, data.street || "");
              setFieldValue(`${fieldType}_house_no`, data.houseno || "");
              setFieldValue(`${fieldType}_postcode`, data.zipcode || "");
              setFieldValue(`${fieldType}_city`, data.city || "");
              break;
            case "in":
              tmpStateCode = data.state_code === "CG" ? "CT" : data.state_code;

              setFieldValue(`${fieldType}_street`, data.street || "");
              setFieldValue(`${fieldType}_house_no`, data.houseno || "");
              setFieldValue(`${fieldType}_postcode`, data.zipcode || "");
              setFieldValue(`${fieldType}_city`, data.city || "");
              setFieldValue("state", (tmpStateCode && (STATES_IN.find((el) => el.value === tmpStateCode)?.value || "OTHER")) || "");
              setFieldValue(`${fieldType}_district`, data.district || "");
              setFieldValue(`${fieldType}_locality`, data.region || "");
              break;
            case "cl":
              regionOption = REGIONS_CL.find(
                (el) => el.label.toLowerCase().includes(data.state.toLowerCase()),
              );

              cityOption = REGION_CITY_LIST.flatMap(
                (el) => (el.options ? el.options : el),
              ).find((el) => el.label.includes(data.region));

              setFieldValue(`${fieldType}_street`, data.street || "");
              setFieldValue(`${fieldType}_house_no`, data.houseno || "");
              setFieldValue(`${fieldType}_postcode`, data.zipcode || "");
              setFieldValue(`${fieldType}_city`, cityOption.value || "");
              setFieldValue(`${fieldType}_region`, regionOption.value || "");

              if (fieldType === "prev") {
                dispatch(
                  setPrevRegion(regionOption?.value),
                );
              } else {
                dispatch(
                  setCurrentRegion(regionOption?.value),
                );
              }
              break;
            default:
              setFieldValue(`${fieldType}_street`, data.thoroughfare || "");
              setFieldValue(`${fieldType}_house_no`, data.building_number || "");
              setFieldValue(`${fieldType}_postcode`, data.postcode || "");
              setFieldValue(`${fieldType}_city`, data.town_or_city || "");
              setFieldValue(`${fieldType}_county`, data.county || "");
              setFieldValue(`${fieldType}_house_name`, data.building_name || "");
              setFieldValue(`${fieldType}_apartment`, data.sub_building_number || "");
          }
        }}
      />

      <CollapseBox
        opened={addressShown}
        label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
        icon={<IconFillForm24 />}
        warning={sameValues.length > 0}
      >
        <Row>
          {subFields.map((field) => (
            field.responsive.singleLine
              ? (
                <Col style={field.responsive.style} className="p-0" key={field.props.id} xs={12}>
                  <Col xs={field.responsive.xs} md={field.responsive.md}>
                    {build(field, {
                      errors,
                      meta,
                      onBlur,
                      onChange,
                      setFieldTouched,
                      setFieldValue,
                      touched,
                      values,
                      warning,
                    })}
                  </Col>
                </Col>
              )
              : (
                <Col
                  style={field.responsive.style}
                  key={field.props.id}
                  xs={field.responsive.xs}
                  md={field.responsive.md}
                >
                  {build(field, {
                    errors,
                    meta,
                    onBlur,
                    onChange,
                    setFieldTouched,
                    setFieldValue,
                    touched,
                    values,
                    warning,
                  })}
                </Col>
              )
          ))}
          {sameValues.length > 0 && (
            <Col xs={12} className="warning-info">
              <IconError18 />
              <div>
                <FormattedMessage id="update_address.same_value_warning" />&nbsp;
                {sameValues.map((item, index) => (
                  <><div className="warning-field">{item}</div>{index < (sameValues.length - 1) && ", "}</>
                ))}
              </div>
            </Col>
          )}
        </Row>
      </CollapseBox>
    </>
  );
};

FormAddressInput.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  fieldType: PropTypes.oneOf(["prev", "current"]).isRequired,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  subFields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  warning: PropTypes.oneOfType([PropTypes.object]),
};

FormAddressInput.defaultProps = {
  warning: {},
};

export default FormAddressInput;
