import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import "./statusWindow.scss";

const StatusWindow = ({ errorData }) => {
  const intl = useIntl();

  return (
    <div className="error-status-window">
      {`${intl.formatMessage({ id: "registration.error" })} ${errorData.status} - ${errorData.message}`}
    </div>
  );
};

StatusWindow.propTypes = {
  errorData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default StatusWindow;
