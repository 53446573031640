import { useLayoutEffect, useState } from "react";

import Cookies from "js-cookie";

import DataLayer from "../utils/dataLayer";
import { dateFactory } from "../utils/dateHelper";

const useContentABTest = (main, variant) => {
  const [page, setPage] = useState(main);

  useLayoutEffect(() => {
    if (variant && variant.elements?.variant?.value?.[0]) {
      const currentDate = new Date();
      const startDate = dateFactory(variant.elements.start_date.value);
      const endDate = dateFactory(variant.elements.end_date.value);

      if (startDate <= currentDate && endDate >= currentDate) {
        DataLayer.pushEvent("ppd_ab_test", { dimension_ab_name: variant.elements?.experiment_name?.value || "ab-test" });

        if (Cookies.get("test-variant") === "variant") {
          setPage(variant.elements.variant.value[0]);
        }
      }
    }
  }, [main, variant]);

  return page;
};

export default useContentABTest;
