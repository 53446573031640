import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import Col from "react-bootstrap/Col";

import KontentItemImage from "../../templates/kontentItemImage";
import SimpleRichText from "../../templates/text/simpleRichText";

const InfoBottomFooterNavigation = () => {
  const data = useStaticQuery(graphql`
    {
      footerInfo: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          footer_info_block_1 {
            value
          }
          footer_info_block_2 {
            value
          }
          logos {
            value {
              ...KontentItemImage
            }
          }
        }
      }
    }
  `);

  return (
    data.footerInfo
      ? (
        <>
          <Col className="footer-info" sm={6}>
            <SimpleRichText
              data={data.footerInfo.elements.footer_info_block_1}
            />
          </Col>
          <Col className="footer-info" sm={6}>
            <SimpleRichText
              data={data.footerInfo.elements.footer_info_block_2}
            />
          </Col>

          {data.footerInfo.elements.logos.value.length > 0 && (
            <Col className="logos">
              {data.footerInfo.elements.logos.value.map((logo, index) => (
                <KontentItemImage
                  height={60}
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={index}
                  data={logo}
                  defaultImage
                />
              ))}
            </Col>
          )}
        </>
      )
      : null
  );
};

export default InfoBottomFooterNavigation;
