import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconMail18, IconPhone18, IconWhatsapp18 } from "../../icons";

import "./contactPerson.scss";

const ContactPerson = (props) => {
  const {
    name,
    department,
    mail,
    phone,
    image,
    stack,
    variant,
    contactHref,
    whatsapp,
  } = props;

  return (
    <div className={classNames(
      "contact-person",
      {
        "contact-person--stack": stack,
        [`contact-person--${variant}`]: variant,
      },
    )}
    >
      {image && (
        <div className="contact-person__image">
          {image}
        </div>
      )}
      <div className="contact-person__data">
        <div className="contact-person__name">{name}</div>
        {department && <div className="contact-person__department">{department}</div>}
        {(phone || mail) && (
          <div className="contact-person__contact-data">
            {mail && <a href={`mailto:${mail}`} title={name}><IconMail18 /><span>{mail}</span></a>}
            {phone && <a href={`tel:${phone}`} title={name}><IconPhone18 /><span>{phone}</span></a>}
            {whatsapp
              && (
                <a href={`https://wa.me/${whatsapp}`} title={name}>
                  <IconWhatsapp18 /><span><FormattedMessage id="whatsapp" /></span>
                </a>
              )}
          </div>
        )}
        {contactHref}
      </div>
    </div>
  );
};

ContactPerson.propTypes = {
  contactHref: PropTypes.oneOfType([PropTypes.object]),
  department: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
  mail: PropTypes.string,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
  stack: PropTypes.bool,
  variant: PropTypes.oneOf(["money-donation", "testimonial"]),
  whatsapp: PropTypes.number,
};

ContactPerson.defaultProps = {
  contactHref: null,
  department: null,
  image: null,
  mail: null,
  phone: null,
  stack: false,
  variant: null,
  whatsapp: null,
};

export default ContactPerson;
