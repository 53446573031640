import React from "react";

import PropTypes from "prop-types";

import NavigationLink from "../navigationLink/navigationLink";

import "./megaMenuResult.scss";

const MegaMenuResult = (props) => {
  const {
    href,
    image,
    children,
  } = props;

  return (
    <NavigationLink
      href={href}
      className="megamenu-result"
    >
      {image}
      <span className="typo-label">{children}</span>
    </NavigationLink>
  );
};

MegaMenuResult.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default MegaMenuResult;
