import React, { useMemo, useState } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import {
  IconError18,
  IconFillForm24,
  IconFurther18,
  IconFurther24,
} from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import FormObserver from "../../../utils/formik/formObserver";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import AddressInput from "../../addressInput/addressInput";
import IconButton from "../../button/iconButton";
import CollapseBox from "../../collapseBox/collapseBox";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import Tooltip from "../../localForms/tooltip";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationResidentialAddressUs = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [addressShown, setAddressShown] = useState(registrationData.street !== "");

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const warning = useSelector((state) => state.registration.warning);

  const validationSchema = useMemo(() => object().shape({
    apartment: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    city: string()
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 50 },
      ))
      .required(requiredMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    houseNo: string()
      .max(10, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 10 },
      ))
      .required(requiredMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    poBox: string()
      .when("po", {
        is: (value) => value === true,
        then: string()
          .required(requiredMessage)
          .min(2, intl.formatMessage(
            { id: "form.error.po_box" },
            { value: 2 },
          )).max(5, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            { value: 5 },
          ))
          .matches(/^\d{2,5}$/, intl.formatMessage({ id: "form.error.po_box" }))
          .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
      }),
    poCity: string()
      .when("po", {
        is: (value) => value === true,
        then: string()
          .required(requiredMessage)
          .max(50, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            { value: 50 },
          ))
          .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
      }),
    poPostCode: string()
      .when("po", {
        is: (value) => value === true,
        then: string()
          .required(requiredMessage)
          .matches(/^\d{5}$/, intl.formatMessage({ id: "form.error.zipcode" })),
      }),
    poState: string()
      .when("po", {
        is: (value) => value === true,
        then: string()
          .required(requiredMessage)
          .max(100, intl.formatMessage(
            { id: "form.error.max_length_exact" },
            { value: 100 },
          )),
      }),
    postCode: string()
      .matches(/^\d{5}$/, intl.formatMessage({ id: "form.error.zipcode" }))
      .required(requiredMessage),
    state: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      )),
    street: string()
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 50 },
      ))
      .required(requiredMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
  }), []);

  let warningInfo;

  if (Object.keys(warning).length > 0) {
    warningInfo = RegistrationDataHelper.generateWarning(warning);
  }

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.address.title"
        subheadline="registration.address.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              apartment: values.apartment,
              city: values.city,
              houseNo: values.houseNo,
              po: values.po,
              poBox: values.poBox,
              poCity: values.poCity,
              poPostCode: values.poPostCode,
              poState: values.poState,
              postCode: values.postCode,
              state: values.state,
              street: values.street,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_residential_address", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormObserver />
            <AddressInput
              onResult={(data) => {
                setAddressShown(true);

                setFieldValue("street", data.street || "");
                setFieldValue("houseNo", data.houseno || "");
                setFieldValue("postCode", data.zipcode || "");
                setFieldValue("city", data.city || "");
                setFieldValue("state", data.state_code || "");
              }}
            />

            <CollapseBox
              opened={addressShown}
              label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
              icon={<IconFillForm24 />}
              warning={Object.keys(warning).length > 0}
            >
              <Row>
                <Col md={4}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="houseNo"
                    label={intl.formatMessage({ id: "registration.address.houseNo" })}
                    type="text"
                    warning={warning}
                    maxLength={10}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={8}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="street"
                    label={intl.formatMessage({ id: "registration.address.street" })}
                    type="text"
                    warning={warning}
                    maxLength={50}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="apartment"
                    label={intl.formatMessage({ id: "registration.address.apartment" })}
                    type="text"
                    warning={warning}
                    maxLength={100}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="city"
                    label={intl.formatMessage({ id: "registration.address.city" })}
                    type="text"
                    maxLength={50}
                    touched={touched}
                    errors={errors}
                    values={values}
                    required
                  />
                </Col>
                <Col md={6} className="mb-md-0">
                  <DropdownFormField
                    label={intl.formatMessage({ id: "registration.address.state" })}
                    placeholder={intl.formatMessage({ id: "registration.please_select" })}
                    onChange={(selectedOption) => {
                      const { target } = selectedOption;

                      if (target && target.value) {
                        setFieldValue("state", target.value);
                        setFieldError("state", undefined);
                      } else {
                        setFieldValue("state", "");
                      }
                    }}
                    onBlur={() => {
                      setFieldTouched("state", true);
                    }}
                    touched={touched}
                    id="state"
                    errors={errors}
                    values={values}
                    options={RegistrationDataHelper.stateOptionsUs}
                    required
                    maxMenuHeight={300}
                  />
                </Col>
                <Col md={6} className="mb-0">
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="postCode"
                    label={intl.formatMessage({ id: "registration.address.postCode" })}
                    type="text"
                    maxLength={5}
                    touched={touched}
                    errors={errors}
                    values={values}
                    required
                  />
                </Col>
              </Row>
            </CollapseBox>

            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id="po"
                    name="po"
                    className="typo-label"
                    checked={values.po}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={(errors.po && touched.po)}
                    label={intl.formatMessage(
                      { id: "registration.address.po" },
                    )}
                  />
                  {errors.po && touched.po && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.po}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              {values.po && (
                <>
                  <Col md={6}>
                    <Tooltip
                      id="poBox-tooltip"
                      tooltipMessage={intl.formatMessage({ id: "registration.address.poBox.info" })}
                    >
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="poBox"
                        label={intl.formatMessage({ id: "registration.address.poBox" })}
                        type="text"
                        warning={warning}
                        maxLength={5}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Tooltip>
                  </Col>
                  <Col md={6}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="poCity"
                      label={intl.formatMessage({ id: "registration.address.poCity" })}
                      type="text"
                      maxLength={50}
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <DropdownFormField
                      label={intl.formatMessage({ id: "registration.address.poState" })}
                      placeholder={intl.formatMessage({ id: "registration.please_select" })}
                      onChange={(selectedOption) => {
                        const { target } = selectedOption;

                        if (target && target.value) {
                          setFieldValue("poState", target.value);
                          setFieldError("poState", undefined);
                        } else {
                          setFieldValue("poState", "");
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched("poState", true);
                      }}
                      touched={touched}
                      id="poState"
                      errors={errors}
                      values={values}
                      options={RegistrationDataHelper.stateOptionsUs}
                      maxMenuHeight={300}
                      required
                    />
                  </Col>
                  <Col md={6}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="poPostCode"
                      label={intl.formatMessage({ id: "registration.address.poPostCode" })}
                      type="text"
                      maxLength={5}
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>
                </>
              )}
              {warningInfo && (
                <Col xs={12} className="warning-info mt-0 mb-24">
                  {warningInfo}
                </Col>
              )}
            </Row>

            <ScrollToFieldError />

            <Form.Group>
              <IconButton
                type="submit"
                disabled={isSubmitting}
                onClick={() => {
                  setAddressShown(true);
                }}
              >
                <FormattedMessage id="registration.address.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>

            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationResidentialAddressUs.stepName = "registration_residential_address";

RegistrationDialogStepRegistrationResidentialAddressUs.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationResidentialAddressUs;
