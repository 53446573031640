import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationRelationshipDe = ({ next, rootsId }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const getOptionByValue = (options, value) => options.find((el) => el.value === value);

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });

  const validationSchema = useMemo(() => object().shape({
    relationshipFirstname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 40,
        },
      ))
      .matches(/^[^\d\u0600-\u06FF]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    relationshipLastname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 40,
        },
      ))
      .matches(/^[^\d\u0600-\u06FF]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    relationshipType: string()
      .required(requiredMessage),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.relationship.title"
        subheadline="registration.relationship.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          const {
            relationshipType,
            relationshipFirstname,
            relationshipLastname,
          } = values;

          dispatch(
            setRegistrationData({
              relationshipFirstname,
              relationshipLastname,
              relationshipType,
              relationshipTypeLabel: getOptionByValue(
                RegistrationDataHelper.relationshipTypeOptionsDe,
                relationshipType,
              ).label,
              rootsId,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_relationship", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DropdownFormField
              label={intl.formatMessage({ id: "registration.relationship.type" })}
              placeholder={intl.formatMessage({ id: "registration.please_select" })}
              onChange={(selectedOption) => {
                const { target } = selectedOption;

                if (target && target.value) {
                  setFieldValue("relationshipType", target.value);
                  setFieldError("relationshipType", undefined);
                } else {
                  setFieldValue("relationshipType", "");
                }
              }}
              onBlur={() => {
                setFieldTouched("relationshipType", true);
              }}
              touched={touched}
              id="relationshipType"
              errors={errors}
              values={values}
              options={RegistrationDataHelper.relationshipTypeOptionsDe}
              maxMenuHeight={300}
            />

            <Row>
              <Col xs={12} lg={6}>
                <FormField
                  id="relationshipFirstname"
                  label={intl.formatMessage({ id: "registration.relationship.firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  onChange={(event) => {
                    setFieldTouched("relationshipType", true);
                    handleChange(event);
                  }}
                  onBlur={(event) => {
                    setFieldTouched("relationshipType", true);
                    handleBlur(event);
                  }}
                  required
                />
              </Col>
              <Col xs={12} lg={6}>
                <FormField
                  id="relationshipLastname"
                  label={intl.formatMessage({ id: "registration.relationship.lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </Col>
            </Row>

            <ScrollToFieldError />

            <Form.Group>
              <IconButton type="submit" disabled={isSubmitting}>
                <FormattedMessage id="registration.ethnicity.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationRelationshipDe.stepName = "registration_relationship";

RegistrationDialogStepRegistrationRelationshipDe.propTypes = {
  next: PropTypes.func.isRequired,
  rootsId: PropTypes.string.isRequired,
};

export default RegistrationDialogStepRegistrationRelationshipDe;
