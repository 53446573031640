import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemImageCard from "./kontentItemImageCard";
import CardSlider from "../components/cardSlider/cardSlider";

const KontentItemImageCardSliderSection = ({ data }) => {
  const imageCardSlider = data.value ? data.value[0] : data;

  if (!imageCardSlider || !imageCardSlider.elements) {
    return null;
  }

  return (
    <CardSlider
      header={imageCardSlider.elements.title.value}
      description={imageCardSlider.elements.description.value}
      className="text-section__fullwidth"
      moreUrl={imageCardSlider.elements.button_url.value.length > 0
        ? imageCardSlider.elements.button_url.value[0].id
        : null}
    >
      {imageCardSlider.elements.image_cards.value.map((card) => (
        <KontentItemImageCard data={card} key={card.id} disableDescription />
      ))}
    </CardSlider>
  );
};

export const query = graphql`
  fragment KontentItemImageCardSliderSection on kontent_item_image_card_slider_section {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      description {
        value
      }
      image_cards {
        value {
          ...KontentItemImageCard
        }
      }
      button_url {
        value {
          id
        }
      }
    }
  } 
`;

KontentItemImageCardSliderSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemImageCardSliderSection;
