import React from "react";

import classNames from "classnames";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import KontentItemLinkCard from "./kontentItemLinkCard";

const KontentItemLinkCardGroup = ({ data, isSidebarLayout }) => {
  const linkCards = data.value ? data.value[0] : data;

  if (!linkCards || !linkCards.elements) {
    return null;
  }

  let colProps = {
    sm: 6,
  };

  if (linkCards.elements.link_cards.value.length > 2 && !isSidebarLayout) {
    colProps = {
      lg: 4,
      sm: 6,
    };
  }

  return (
    <Row
      className={classNames(
        "link-card-section text-section__fullwidth",
        linkCards.elements.link_cards.value.length < 2 && "justify-content-center",
      )}
    >
      {linkCards.elements.link_cards.value.map((card) => (
        <Col {...colProps} key={card.id}>
          <KontentItemLinkCard data={card} />
        </Col>
      ))}
    </Row>
  );
};

export const query = graphql`
  fragment KontentItemLinkCardGroup on kontent_item_link_card_group {
    id
    system {
      codename
    }
    elements {
      link_cards {
        value {
          ...KontentItemLinkCard
        }
      }
    }
  } 
`;

KontentItemLinkCardGroup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isSidebarLayout: PropTypes.bool,
};

KontentItemLinkCardGroup.defaultProps = {
  isSidebarLayout: false,
};

export default KontentItemLinkCardGroup;
