import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import backgroundImageHelper from "./image/backgroundImageHelper";
import KontentItemCtaButton from "./kontentItemCtaButton";
import ImageLightbox from "../components/imageLightbox/imageLightbox";

const KontentItemImageBannerLightbox = () => {
  const data = useStaticQuery(graphql`
    {
      imageBannerLightboxes: allKontentItemImageBannerLightbox(filter: {system: {workflow_step: {ne: "archived"}}}) {
        nodes {
          id
          system {
            codename
          }
          elements {
            piwik_tag_id {
              type
              name
              value {
                codename
                name
              }
            }
            headline {
              value
            }
            text {
              value
            }
            button {
              value {
                ...KontentItemCtaButton
              }
            }
            image {
              value {
                ...KontentItemImage
              }
            }
          }
        }
      }
    }
  `);

  const imageBannerLightboxes = data && data.imageBannerLightboxes
    && data.imageBannerLightboxes.nodes;

  if (!imageBannerLightboxes || imageBannerLightboxes.length === 0) {
    return null;
  }

  return imageBannerLightboxes.map((item) => {
    if (item.elements?.piwik_tag_id?.value?.length > 0) {
      const backgroundImageStyle = backgroundImageHelper(item.elements.image, { w: 800 });

      return (
        <ImageLightbox
          id={item.elements.piwik_tag_id.value[0].name}
          key={item.elements.piwik_tag_id.value[0].name}
          headline={item.elements.headline.value}
          text={item.elements.text.value}
          backgroundImageStyle={backgroundImageStyle}
          buttons={
            item.elements.button.value.length > 0
              ? <KontentItemCtaButton data={item.elements.button.value[0]} />
              : null
          }
        />
      );
    }

    return null;
  });
};

export default KontentItemImageBannerLightbox;
