import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setSetupData } from "../../../features/registrationSlice";
import { IconError18, IconFurther18 } from "../../../icons";
import KontentItemImage from "../../../templates/kontentItemImage";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import IconButton from "../../button/iconButton";
import ImageRadioButton from "../../imageRadioButton/imageRadioButton";

const VirtualDriveSetupModalDriveMotivation = ({ content, next }) => {
  const setupData = useSelector((state) => state.registration.setupData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    motivation: string().required(requiredMessage),
  }), []);

  return (
    <Row className="virtual-drive-setup-dialog__drive-motivation">
      <Col sm={10}>
        <h3>
          <FormattedMessage id="virtual_drive_setup.motivation_title" />
        </h3>
      </Col>
      <Formik
        initialValues={setupData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setSetupData({ motivation: values.motivation }),
          );

          // Skip one step if motivation is "mission"
          next(values.motivation === "mission");
        }}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          touched,
          errors,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className="col-12">
            <div className="typo-label mb-12 required-sign">
              <FormattedMessage id="form.please_select.label" />
            </div>
            <Form.Group>
              <Row>
                <Col xs={6}>
                  <ImageRadioButton
                    label={intl.formatMessage({ id: "virtual_drive_setup.motivation_patient" })}
                    id="patient"
                    checked={values.motivation === "patient"}
                    value="patient_value"
                    isInvalid={errors.motivation && touched.motivation}
                    name="motivation"
                    image={(
                      <KontentItemImage
                        data={content.imageRadioButtonMotivationPatient}
                        width={400}
                      />
                    )}
                    onClick={() => {
                      setFieldValue("motivation", "patient");
                      setFieldTouched("motivation");
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <ImageRadioButton
                    label={intl.formatMessage({ id: "virtual_drive_setup.motivation_mission" })}
                    id="mission"
                    checked={values.motivation === "mission"}
                    isInvalid={errors.motivation && touched.motivation}
                    value="mission_value"
                    name="motivation"
                    image={(
                      <KontentItemImage
                        data={content.imageRadioButtonMotivationGeneral}
                        width={400}
                      />
                    )}
                    onClick={() => {
                      setFieldValue("motivation", "mission");
                      setFieldTouched("motivation");
                    }}
                  />
                </Col>
                <Col xs={12}>
                  {errors.motivation && touched.motivation && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.motivation}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Row>
              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group className="form-group-button">
                  <IconButton type="submit">
                    <FormattedMessage id="virtual_drive_setup.next" /> <IconFurther18 />
                  </IconButton>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Row>
  );
};

VirtualDriveSetupModalDriveMotivation.stepName = "virtual_drive_motivation";

VirtualDriveSetupModalDriveMotivation.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default VirtualDriveSetupModalDriveMotivation;
