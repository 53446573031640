import React, { useEffect } from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import LinkButton from "../../components/button/linkButton";
import EyecatcherSocialMediaShare from "../../components/eyecatcher/eyecatcherSocialMediaShare";
import SEO from "../../components/seo";
import SocialMediaBox from "../../components/socialMediaBox/socialMediaBox";
import ThankYouHeader from "../../components/thankYouHeader/thankYouHeader";
import { IconFurther24 } from "../../icons";
import DataLayer from "../../utils/dataLayer";
import getInternalLinkById from "../../utils/getInternalLinkById";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentAssetsImage from "../kontentAssetsImage";
import MainLayout from "../layout/mainLayout";
import SimpleRichText from "../text/simpleRichText";

const KontentItemThankYouPageDynamic = ({ data: { page }, pageContext }) => {
  let sharePage = [];
  let pageToBeShared = null;

  if (page.elements.page_to_be_shared.value.length > 0) {
    sharePage = getInternalLinkById(page.elements.page_to_be_shared.value[0].id);
    [pageToBeShared] = page.elements.page_to_be_shared.value;
  }

  useEffect(() => {
    let urlSearch = "";

    if (typeof window !== "undefined") {
      urlSearch = window.location.search;
    }

    const queryParameters = new URLSearchParams(urlSearch);
    const urlAmount = queryParameters.get("amount");

    if (urlAmount) {
      DataLayer.pushEvent("pp_donation_step_thank_you", { event_value: "success", payment_amount: parseInt(urlAmount, 10) });
    }
  }, []);

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
        isHiddenForRobots={pageContext.seoNoIndex}
      />

      <ThankYouHeader
        headline={page.elements.headline.value}
        description={SimpleRichText({ data: page.elements.text })}
        linkButton={page.elements.button_url.value.length > 0
          ? (
            <LinkButton
              href={page.elements.button_url.value[0].id}
            >
              {page.elements.button_text.value} <IconFurther24 />
            </LinkButton>
          )
          : null}
        image={mapKontentItem(
          page.elements.donation_box.value[0],
          {
            aspectRatio: 3 / 4,
            data: page.elements.donation_box,
            renderAsFundraisingBox: true,
          },
        )}
        variant={page.elements.variant.value.length > 0
          ? page.elements.variant.value[0].codename.replace(/_/g, "-")
          : null}
        icon={page.elements.icon.value.length > 0
          ? (
            <img
              src={page.elements.icon.value[0].url}
              alt={page.elements.icon.value[0].name}
              height={60}
              width={60}
            />
          )
          : null}
      />

      {/* eslint-disable-next-line max-len */}
      {page.elements.sharing_box_text.value && pageToBeShared && pageToBeShared.elements && sharePage && (
        <EyecatcherSocialMediaShare
          title={page.elements.sharing_box_text.value}
          shareUrl={`${process.env.GATSBY_SITE_URL}${sharePage?.slug}`}
          media={(
            <SocialMediaBox
              title={process.env.GATSBY_SITE_URL.replace("https://", "").replace("www.", "")}
              description={
                pageToBeShared.elements.metadata__meta_description.value
                || pageToBeShared.elements.metadata__og_description.value
              }
              image={pageToBeShared.elements.metadata__og_image.value.length > 0
                ? (
                  <KontentAssetsImage
                    data={pageToBeShared.elements.metadata__og_image.value[0]}
                    aspectRatio={21 / 9}
                    width={800}
                  />
                )
                : null}
            />
          )}
        />
      )}

      {page.elements.page_sections.value.map((item) => mapKontentItem(item))}

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemThankYouPageDynamic(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        text {
          value
        }
        button_url {
          value {
            id
          }
        }
        button_text {
          value
        }
        icon {
          value {
            url
            height
            width
            description
          }
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemImageCardSliderSection
            ...KontentItemProgramSliderSection
            ...KontentItemEyecatcherImage
            ...KontentItemEyecatcher
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemFaqSection
            ...KontentItemRegistryBanner
            ...KontentItemRegistrationProcessSection
            ...KontentItemMoneyPartition
            ...KontentItemEyecatcherContact
          }
        }
        donation_box {
          value {
            internal {
              type
            }
            ...KontentItemDonationHeader
            ...KontentItemDonationHeaderSimple
            ...KontentItemImage
            ...KontentItemDonationBox
          }
        }
        variant {
          value {
            codename
          }
        }
        sharing_box_text {
          value
        }
        page_to_be_shared {
          value {
            id
            ... on kontent_item_money_donation_page {
              elements {
                metadata__meta_description {
                  value
                }
                metadata__og_title {
                  value
                }
                metadata__og_image {
                  value {
                    url
                  }
                }
                metadata__og_description {
                  value
                }
                metadata__meta_title {
                  value
                }
              }
            }

            ... on kontent_item_registration_dialogue {
              elements {
                metadata__meta_description {
                  value
                }
                metadata__og_title {
                  value
                }
                metadata__og_image {
                  value {
                    url
                    description
                  }
                }
                metadata__og_description {
                  value
                }
                metadata__meta_title {
                  value
                }
              }
            }
            
            ... on kontent_item_landing_page {
              elements {
                metadata__meta_description {
                  value
                }
                metadata__og_title {
                  value
                }
                metadata__og_image {
                  value {
                    url
                    description
                  }
                }
                metadata__og_description {
                  value
                }
                metadata__meta_title {
                  value
                }
              }
            }
            
            ... on kontent_item_vd_overview {
              elements {
                metadata__meta_description {
                  value
                }
                metadata__og_title {
                  value
                }
                metadata__og_image {
                  value {
                    url
                    description
                  }
                }
                metadata__og_description {
                  value
                }
                metadata__meta_title {
                  value
                }
              }
            }
            
            ... on kontent_item_register_page {
              elements {
                metadata__meta_description {
                  value
                }
                metadata__og_title {
                  value
                }
                metadata__og_image {
                  value {
                    url
                    description
                  }
                }
                metadata__og_description {
                  value
                }
                metadata__meta_title {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

KontentItemThankYouPageDynamic.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemThankYouPageDynamic;
