import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import IconButton from "../../components/button/iconButton";
import getRegistrationSteps from "../../components/registrationDialog/registrationDialogSteps";
import RegistrationDialogWrapper from "../../components/registrationDialog/registrationDialogWrapper";
import SEO from "../../components/seo";
import { IconDonate24 } from "../../icons";
import getInternalLinkById from "../../utils/getInternalLinkById";
import mapKontentItem from "../../utils/mapKontentItem";
import SimpleRichText from "../text/simpleRichText";

const KontentItemRegistrationDialogue = ({ data, location }) => {
  const { page } = data;

  const image = page.elements.registration_dialog_background.value;

  const rootsId = location.search.match(/roots=([0-9-]+)/);

  return (
    <>
      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <RegistrationDialogWrapper
        rootsId={rootsId !== null ? rootsId[1] : null}
        steps={getRegistrationSteps(rootsId !== null ? rootsId[1] : null)}
        image={
          image.length > 0
            ? `${image[0].url}?w=1920&h=1080&fm=pjpeg&auto=format`
            : null
        }
        content={{
          buttonExitText: page.elements.button_text_exit.value,
          buttonExitUrl: page.elements.button_url_exit.value.length > 0
            ? page.elements.button_url_exit.value[0].id
            : null,
          buttonTextContinue: page.elements.button_text_continue.value,
          contactLink:
            page.elements.contact.value.length > 0
              ? page.elements.contact.value[0].id
              : null,
          donationBox: page.elements.donation_box.value.length > 0
            ? mapKontentItem(page.elements.donation_box.value[0])
            : null,
          errorButtonPrimary:
            page.elements.button_url_primary.value.length > 0
              ? (
                <Form.Group className="form-group-button">
                  <IconButton
                    variant="donation"
                    target="_self"
                    href={
                      page.elements.button_url_primary.value.length > 0
                        ? page.elements.button_url_primary.value[0].id
                        : null
                    }
                    onClick={() => {
                      window.skipBeforeUnload = true;
                    }}
                  >
                    <FormattedMessage id="registration.error.help_us" />
                    <IconDonate24 />
                  </IconButton>
                </Form.Group>
              )
              : null,
          errorButtonSecondary:
            page.elements.button_url_secondary.value.length > 0
              ? (
                <Form.Group className="form-group-button">
                  <IconButton
                    variant="inverted-registration"
                    target="_self"
                    href={
                      page.elements.button_url_secondary.value.length > 0
                        ? page.elements.button_url_secondary.value[0].id
                        : null
                    }
                    onClick={() => {
                      window.skipBeforeUnload = true;
                    }}
                  >
                    <FormattedMessage id="registration.error.view_all" />
                  </IconButton>
                </Form.Group>
              )
              : null,
          optinHeadline: page.elements.headline_optin.value.length > 0
            ? page.elements.headline_optin.value
            : null,
          optinText: page.elements.text_optin.value.length > 0
            ? <SimpleRichText data={page.elements.text_optin} />
            : null,
          overviewText: <SimpleRichText data={page.elements.text} />,
          privacyPolicyLink:
            page.elements.url_privacy_policy.value.length > 0
              ? page.elements.url_privacy_policy.value[0].id
              : null,
          revocation: page.elements.text_revocation.value.length > 0
            ? <SimpleRichText data={page.elements.text_revocation} />
            : null,
          successButton: (
            <IconButton
              variant="donation"
              target="_self"
              href={
                page.elements.button_successful_url.value.length > 0
                  ? page.elements.button_successful_url.value[0].id
                  : null
              }
            >
              {page.elements.button_successful_text.value}
            </IconButton>
          ),
          successPage: page.elements.success_page.value.length > 0
            ? getInternalLinkById(page.elements.success_page.value[0].id)?.slug
            : null,
          termsText: <SimpleRichText data={page.elements.terms_text} />,
          termsTitle: page.elements.terms_title.value,
          textLeaveIntent: page.elements.text_leave_intent.value,
        }}
      />
    </>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemRegistrationDialogue(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        registration_dialog_background {
          value {
            url
            type
          }
        }
        button_successful_text {
          value
        }
        button_successful_url {
          value {
            id
          }
        }
        contact {
          value {
            id
          }
        }
        url_privacy_policy {
          value {
            id
          }
        }
        button_url_primary {
          value {
            id
          }
        }
        button_url_secondary {
          value {
            id
          }
        }
        text {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
          }
        }
        terms_title {
          value
        }
        terms_text {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
          }
        }
        success_page {
          value {
            id
          }
        }
        text_leave_intent {
          value
        }
        button_text_continue {
          value
        }
        button_text_exit {
          value
        }
        button_url_exit {
          value {
            id
          }
        }
        donation_box {
          value {
            internal {
              type
            }
            ...KontentItemDonationBox
          }
        }
        text_revocation {
          value
          links {
            link_id
            codename
            type
            url_slug
          }
        }
        headline_optin {
          value
        }
        text_optin {
          value
        }
      }
    }
  }
`;

KontentItemRegistrationDialogue.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemRegistrationDialogue;
