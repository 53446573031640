import React, { useEffect, useMemo, useRef } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { bool, object, string } from "yup";

import RegistrationDialogStepError from "./registrationDialogStepError";
import registrationDialogStepRegistrationEthnicityZa from "./registrationDialogStepRegistrationEthnicityZa";
import { useSubmitIoRegistrationMutation } from "../../../features/io/ioRegistrationApiSlice";
import { setRegistrationData, setStepError, setSuccess } from "../../../features/registrationSlice";
import usePrevious from "../../../hooks/usePrevious";
import useURNParams from "../../../hooks/useURNParams";
import { IconError18, IconSubmit18, IconSubmit24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import { dateFactory, format } from "../../../utils/dateHelper";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import LinkButton from "../../button/linkButton";
import Checkbox from "../../dynamicForm/checkbox/checkbox";
import TextLightbox from "../../lightbox/textLightbox";
import NavigationLink from "../../navigationLink/navigationLink";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepOverviewZa = ({ content, next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const lastSuccess = useSelector((state) => state.registration.lastSuccess);
  const stepError = useSelector((state) => state.registration.stepError);
  const dispatch = useDispatch();

  const intl = useIntl();
  const urnParams = useURNParams();

  const [submitIoRegistration, submitIoRegistrationResult] = useSubmitIoRegistrationMutation({ fixedCacheKey: "submitIoRegistrationCacheKey" });

  const getOptionByValue = (options, value) => options.find((el) => el.value === value);

  const prevSubmitIoRegistrationResult = usePrevious(submitIoRegistrationResult);

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const {
    ethnicityOptions,
    stateOptions,
    nativeLanguage,
  } = registrationDialogStepRegistrationEthnicityZa;

  const validationSchema = useMemo(() => object().shape({
    consent: bool()
      .required(requiredMessage)
      .oneOf([true], requiredMessage),
    dataProtection: bool()
      .required(requiredMessage)
      .oneOf([true], requiredMessage),
    electronicSignature: string()
      .trim()
      .required(requiredMessage),
    newsletter: string()
      .required(requiredMessage),
  }), []);

  useEffect(() => {
    if (submitIoRegistrationResult.isSuccess && !prevSubmitIoRegistrationResult.isSuccess) {
      DataLayer.removeUnloadListener();
      DataLayer.pushEvent("pp_registration_step_final", { event_value: "success" });
      DataLayer.pushEvent("pp_registration_step_final_urn", { event_value: urnParams?.refs });
      DataLayer.pushEvent("ppe_registration_success_goal");

      dispatch(
        setSuccess(),
      );

      if (content.successPage) {
        window.skipBeforeUnload = true;
        window.location.href = content.successPage;
      } else {
        next();
      }
    }

    if (submitIoRegistrationResult.isError && !prevSubmitIoRegistrationResult?.isError) {
      DataLayer.pushEvent("pp_registration_step_final", { event_value: "fail" });
    }

    if (submitIoRegistrationResult.isError || prevSubmitIoRegistrationResult?.isError) {
      dispatch(
        setStepError(true),
      );
    }
  }, [submitIoRegistrationResult]);

  const termsLightbox = useRef(null);

  if (stepError) {
    return (
      <RegistrationDialogStepError
        content={content}
        error={submitIoRegistrationResult.error}
      />
    );
  }

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.overview.title"
      />
      <Row className="mb-36">
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.personal_details" />
          </div>
          <div className="typo-body2">
            {[
              registrationData.salutation,
              registrationData.firstname,
              registrationData.middlename,
              registrationData.lastname,
            ].join(" ")}<br />
            {registrationData.passportNo} <br />
            {registrationData.birthdate && (
              <>
                {format(dateFactory(registrationData.birthdate))}<br /><br />
              </>
            )}
            {registrationData.bmiUnit === "metric" && (
              <>
                {registrationData.weightMetric && <>{registrationData.weightMetric} kg</>}
                {registrationData.weightMetric && registrationData.heightMetric && <br />}
                {registrationData.heightMetric && <>{registrationData.heightMetric} cm</>}
              </>
            )}
          </div>
        </Col>
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.contact_data" />
          </div>
          <div className="typo-body2">
            {registrationData.apartment && (
              <>
                {registrationData.apartment}<br />
              </>
            )}
            {registrationData.houseNo} {registrationData.street}<br />
            {registrationData.city}<br />
            {registrationData.postCode}
            {registrationData.province && (
              <>
                {" "}
                {getOptionByValue(
                  RegistrationDataHelper.provinceOptionsZa,
                  registrationData.province,
                ).label}
              </>
            )}
            <br /><br />
            {registrationData.mobileNumber}<br />
            {registrationData.landlineNumber && (
              <>
                {registrationData.landlineNumber}<br />
              </>
            )}
            {registrationData.emailAddress}<br />
          </div>
        </Col>

        {(registrationData.deliveryAddress || registrationData.deliveryInstructions) && (
          <Col xs={6} className="registration-dialog-step__overview-group">
            <div className="registration-dialog-step__section-title">
              <FormattedMessage id="registration.overview.delivery_details" />
            </div>
            <div className="typo-body2">
              {registrationData.deliveryInstructions && (
                <>
                  {getOptionByValue(
                    RegistrationDataHelper.deliveryInstructionsZa,
                    registrationData.deliveryInstructions,
                  ).label}<br /><br />
                </>
              )}
              {registrationData.deliveryAddress && (
                <>
                  {registrationData.deliveryBuilding && (
                    <>
                      {registrationData.deliveryBuilding}<br />
                    </>
                  )}
                  {registrationData.deliveryHouseNo} {registrationData.deliveryStreet}<br />
                  {registrationData.deliveryCity}<br />
                  {registrationData.deliveryPostCode}
                  {registrationData.deliveryProvince && (
                    <>
                      {" "}
                      {getOptionByValue(
                        RegistrationDataHelper.provinceOptionsZa,
                        registrationData.deliveryProvince,
                      ).label}<br />
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        )}
        <Col xs={6} className="registration-dialog-step__overview-group mb-lg-0">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.ethnicity" />
          </div>

          <div className="typo-body2 mb-12">
            {getOptionByValue(ethnicityOptions, registrationData.yourEthnicity)?.label}
            <br />
            {getOptionByValue(nativeLanguage, registrationData.yourNativeLanguage)?.label}
          </div>

          <div className="typo-body2">
            {getOptionByValue(ethnicityOptions, registrationData.ethnicityMother)?.label}
            (<FormattedMessage id="registration.ethnicity.mother" />)
            <br />
            {getOptionByValue(stateOptions, registrationData.countryMother)?.label}
            (<FormattedMessage id="registration.ethnicity.mother" />)
          </div>
          <div className="typo-body2">
            {getOptionByValue(ethnicityOptions, registrationData.ethnicityFather)?.label}
            (<FormattedMessage id="registration.ethnicity.father" />)
            <br />
            {getOptionByValue(stateOptions, registrationData.countryFather)?.label}
            (<FormattedMessage id="registration.ethnicity.father" />)
          </div>
        </Col>
        <Col lg={6}>
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.additional_contact" />
          </div>

          <div className={classNames("typo-body2", { "mb-12": registrationData.personTwoFirstname })}>
            {registrationData.personOneFirstname} {registrationData.personOneLastname}
            {registrationData.personOneMobileNumber
            && <><br /> {registrationData.personOneMobileNumber}</>}
            {registrationData.personOneEmailAddress
            && <><br /> {registrationData.personOneEmailAddress}</>}
          </div>
          {registrationData.personTwoFirstname && (
            <div className="typo-body2">
              {registrationData.personTwoFirstname} {registrationData.personTwoLastname}
              {registrationData.personTwoMobileNumber
              && <><br /> {registrationData.personTwoMobileNumber}</>}
              {registrationData.personTwoEmailAddress
              && <><br /> {registrationData.personTwoEmailAddress}</>}
            </div>
          )}
        </Col>
      </Row>

      {content && content.overviewText && (
        <div className="registration-dialog-step__overview-text">
          {content.overviewText}
        </div>
      )}

      <Formik
        initialValues={{
          consent: false,
          dataProtection: false,
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          // Prevent submitting if last successful submit was less than 60 seconds ago.
          if (((new Date()).getTime() - lastSuccess) / 1000 < 60) {
            dispatch(
              setStepError(true),
            );

            return;
          }

          if (!submitIoRegistrationResult.isSuccess) {
            submitIoRegistration({
              data: RegistrationDataHelper.preparePayload(registrationData),
              urns: urnParams,
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <Checkbox
                  id="consent"
                  name="consent"
                  checked={values.dataProtection}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  isInvalid={(errors.consent && touched.consent)}
                  label={intl.formatMessage(
                    { id: "registration.overview.consent" },
                    {
                      link: (
                        <LinkButton
                          onClick={() => {
                            termsLightbox.current.show();
                          }}
                          title={content.termsTitle}
                        >
                          <FormattedMessage id="registration.overview.consent_link" />
                        </LinkButton>
                      ),
                    },
                  )}
                  errors={errors}
                  feedback={errors.consent}
                  className="checkbox-required"
                  small
                  required
                />
                <Checkbox
                  id="dataProtection"
                  name="dataProtection"
                  checked={values.dataProtection}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  isInvalid={(errors.dataProtection && touched.dataProtection)}
                  label={intl.formatMessage(
                    { id: "registration.overview.dataprotection" },
                    {
                      link: (
                        <NavigationLink href={content.privacyPolicyLink} target="_blank">
                          <FormattedMessage id="registration.overview.dataprotection_link" />
                        </NavigationLink>
                      ),
                    },
                  )}
                  errors={errors}
                  feedback={errors.dataProtection}
                  className="checkbox-required"
                  small
                  required
                />
              </Col>

              <Col xs={12}>
                <div className="registration-dialog-step__overview-text">
                  <p>
                    <b><FormattedMessage id="registration.overview.newsletter_title" /></b><br />
                    <FormattedMessage id="registration.overview.newsletter_text" />
                  </p>
                </div>
              </Col>

              <Col xs={12}>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.family_agreement.yes" })}
                    name="newsletter"
                    value="YES"
                    id="newsletter-yes"
                    checked={values.newsletter === "YES"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        dispatch(
                          setRegistrationData({ newsletter: "YES" }),
                        );
                      }

                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    inline
                    className="mb-0"
                  />
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.family_agreement.no" })}
                    name="newsletter"
                    value="NO"
                    id="newsletter-no"
                    checked={values.newsletter === "NO"}
                    onChange={(event) => {
                      if (event.target.checked) {
                        dispatch(
                          setRegistrationData({ newsletter: "NO" }),
                        );
                      }

                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    inline
                    className="mb-0"
                  />
                </Form.Group>
              </Col>

              <Col xs={12}>
                <div className="registration-dialog-step__overview-text">
                  <p>
                    <b><FormattedMessage id="registration.overview.electronic_signature_title" /></b><br />
                    <FormattedMessage id="registration.overview.electronic_signature_text" />
                  </p>
                </div>
              </Col>

              <Col xs={12} className="mt-18">
                <Form.Group className="required">
                  <Form.Control
                    type="text"
                    name="electronicSignature"
                    value={values.electronicSignature}
                    onChange={handleChange}
                    onBlur={(event) => {
                      handleBlur(event);
                      const { name } = event.target;
                      setFieldValue(name, values?.[name]?.trim() || "");
                    }}
                    isInvalid={(errors.electronicSignature && touched.electronicSignature)}
                  />
                  <Form.Label>
                    <FormattedMessage id="registration.overview.electronic_signature_input" />
                  </Form.Label>
                  {errors.electronicSignature && touched.electronicSignature && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.electronicSignature}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group>
                  <IconButton
                    type="submit"
                    disabled={isSubmitting || submitIoRegistrationResult.isLoading}
                    loading={submitIoRegistrationResult.isLoading}
                  >
                    <FormattedMessage id="registration.overview.button_next" />
                    <AdaptiveIcon
                      sm={<IconSubmit18 />}
                      lg={<IconSubmit24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      <TextLightbox
        title={content.termsTitle}
        ref={termsLightbox}
      >
        <h3 className="mb-18">{content.termsTitle}</h3>
        {content.termsText}
      </TextLightbox>
    </>
  );
};

RegistrationDialogStepOverviewZa.stepName = "registration_final";

RegistrationDialogStepOverviewZa.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepOverviewZa;
