import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import DonationBox from "./donationBox";
import Button from "../../components/button/button";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import UnifyEyecatcher from "../../components/unifyEyecatcher/unifyEyecatcher";

const KontentItemDonationHeaderSimple = (props) => {
  const { data, renderAsFundraisingBox } = props;

  const donationHeader = data.value ? data.value[0] : data;

  if (!donationHeader || !donationHeader.elements) {
    return null;
  }

  if (!renderAsFundraisingBox && donationHeader.elements.impacts.value.length > 0) {
    return (
      <HeroHeadline
        imageData={donationHeader.elements.impacts.value[0].elements.image}
        eyecatcher={(
          <UnifyEyecatcher title={donationHeader.elements.info.value} color="secondary">
            <Button href={donationHeader.elements.button_url_extern.value} variant="donation">
              {donationHeader.elements.button_text.value}
            </Button>
          </UnifyEyecatcher>
        )}
      >
        {donationHeader.elements.title.value}
      </HeroHeadline>
    );
  }

  return (
    <DonationBox
      title={donationHeader.elements.title.value}
      buttonText={donationHeader.elements.button_text.value}
      buttonLink={donationHeader.elements.button_url_extern.value}
      text={donationHeader.elements.info.value}
      variant={process.env.GATSBY_SITE === "za" ? "links" : undefined}
    />
  );
};

export const query = graphql`
  fragment KontentItemDonationHeaderSimple on kontent_item_donation_header_simple {
    id
    system {
      codename
    }
    elements {
      button_text {
        value
      }
      button_url_extern {
        value
      }
      info {
        value
      }
      title {
        value
      }
      impacts {
        value {
          internal {
            type
          }
          system {
            type
          }
          ... on kontent_item_donation_header_impacts {
            id
            elements {
              image {
                value {
                  id
                  ...KontentItemImage
                }
              }
            }
          }
        }
      }
    }
  }
`;

KontentItemDonationHeaderSimple.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  renderAsFundraisingBox: PropTypes.bool,
};

KontentItemDonationHeaderSimple.defaultProps = {
  renderAsFundraisingBox: false,
};

export default KontentItemDonationHeaderSimple;
