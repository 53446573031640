import React from "react";

import classNames from "classnames";
import isValid from "date-fns/isValid";
import { useField } from "formik";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { IconError18 } from "../../../icons";
import { format } from "../../../utils/dateHelper";
import DateInput from "../../dateInput/dateInput";

const DateField = (props) => {
  const {
    id,
    label,
    required,
    value,
    placeholder,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [field, meta, helper] = useField(props);
  const { setValue, setTouched } = helper;

  const intl = useIntl();

  return (
    <Form.Group
      onBlur={() => setTouched(true)}
      className={classNames({
        required,
      })}
    >
      <DateInput
        name={id}
        initialValue={value}
        onChange={(val) => {
          setTouched(true, true);

          if (typeof val === "object" && isValid(val)) {
            setValue(format(val, "yyyy-MM-dd"));
          } else {
            setValue("");
          }
        }}
        placeholder={placeholder || intl.formatMessage({ id: "date_format" })}
        isInvalid={(meta.error && meta.touched)}
      />
      <Form.Label>{label}</Form.Label>
      {!meta.error && !required && (
        <Form.Text>
          {intl.formatMessage({ id: "form.optional" })}
        </Form.Text>
      )}
      {meta.error && meta.touched && (
        <Form.Text className="invalid-feedback">
          <IconError18 />{ meta.error }
        </Form.Text>
      )}
    </Form.Group>
  );
};

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool.isRequired,
  value: PropTypes.instanceOf(Date),
};

DateField.defaultProps = {
  placeholder: undefined,
  value: undefined,
};

export default DateField;
