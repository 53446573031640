import React from "react";

import differenceInSeconds from "date-fns/differenceInSeconds";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import EventTile from "../../components/eventTile/eventTile";
import StaticMap from "../../components/map/staticMap";
import KontentItemImage from "../kontentItemImage";

const EventTileCard = (props) => {
  const {
    data,
    filterCategory,
    onlyFurtherEvents,
    onlyPastEvents,
    mapPreview,
    id,
  } = props;

  let eventData = Array.isArray(data) ? data : data?.value;

  if (!data) {
    const allEvents = useStaticQuery(graphql`
    {
      events: allKontentItemEvent {
        nodes {
           ...EventTileCard
        }
      }
    }
  `);

    eventData = allEvents.events.nodes;
  }

  if (!eventData || eventData.length === 0) {
    return [];
  }

  const eventList = [];

  const oneDay = 86400;

  if (onlyFurtherEvents) {
    eventData = eventData.filter((event) => {
      if (typeof event.elements.date_end !== "undefined") {
        return differenceInSeconds(
          new Date(),
          new Date(event.elements.date_end.value),
        ) < oneDay;
      }

      return differenceInSeconds(
        new Date(),
        new Date(event.elements.date_start.value),
      ) < oneDay;
    }).sort(
      (a, b) => new Date(a.elements.date_start.value) - new Date(b.elements.date_start.value),
    );
  }

  if (onlyPastEvents) {
    eventData = eventData.filter((event) => {
      if (typeof event.elements.date_end !== "undefined") {
        return differenceInSeconds(
          new Date(),
          new Date(event.elements.date_end.value),
        ) >= oneDay;
      }

      return differenceInSeconds(
        new Date(),
        new Date(event.elements.date_start.value),
      ) >= oneDay;
    }).sort(
      (a, b) => new Date(b.elements.date_start.value) - new Date(a.elements.date_start.value),
    );
  }

  if (filterCategory) {
    eventData = eventData.filter((event) => (
      event.elements.event_category.value.length > 0
      && event.elements.event_category.value[0].name === filterCategory
    ));
  }

  let coordinates;

  eventData.forEach((events) => {
    try {
      coordinates = JSON.parse(events.elements.coordinates);
    } catch (exception) {
      coordinates = {
        lat: null,
        lng: null,
        timezone: null,
      };
    }

    const timezone = coordinates?.timezone?.timeZoneId
      || Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (events.id !== id) {
      eventList.push(
        <EventTile
          href={events.id}
          category={
            events.elements.event_category.value.length > 0
              ? events.elements.event_category.value[0].name
              : null
          }
          headline={events.elements.title.value}
          location={events.elements.city.value.length > 0
            ? events.elements.city.value
            : null}
          date={new Date(events.elements.date_start.value)}
          dateEnd={new Date(events.elements.date_end.value)}
          timezone={timezone}
          image={mapPreview && coordinates.lat !== null && coordinates.lng !== null
            ? (
              <StaticMap
                lat={coordinates.lat}
                lng={coordinates.lng}
              />
            ) : (
              <KontentItemImage
                data={events.elements.image}
                width={539}
                imageOnlyWidth
                placeholderTyp="maps"
              />
            )}
          archive={onlyPastEvents}
        />,
      );
    }
  });

  return React.Children.toArray(eventList);
};

export const query = graphql`
  fragment EventTileCard on kontent_item_event {
    id
    system {
      codename     
    }
    elements {
      title {
        value
      }
      image {
        value {
          ...KontentItemImage
        }
      }
      event_category {
        value {
         name
        } 
      }
      city {
        value
      }
      date_start {
        value 
      }
      date_end {
          value
      }
      url_pattern {
        value
      }
      coordinates {
          value
      }
    }
  }
`;

EventTileCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  filterCategory: PropTypes.string,
  id: PropTypes.string,
  mapPreview: PropTypes.bool,
  onlyFurtherEvents: PropTypes.bool,
  onlyPastEvents: PropTypes.bool,
};

EventTileCard.defaultProps = {
  data: null,
  filterCategory: null,
  id: null,
  mapPreview: false,
  onlyFurtherEvents: false,
  onlyPastEvents: false,
};

export default EventTileCard;
