import { useEffect } from "react";

import { useFormikContext } from "formik";

const ScrollToFieldError = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    try {
      if (isValid) return;

      if (errors.length <= 0) return;

      const fieldErrorNames = Object.keys(errors);

      setTimeout(() => {
        const element = document.querySelector(
          `[name='${fieldErrorNames[0]}']`,
        );

        if (!element) return;

        element.parentElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        element.focus({ preventScroll: true });
      }, 400);
    } catch (e) {
      // ignore
    }
  }, [submitCount]);

  return null;
};

export default ScrollToFieldError;
