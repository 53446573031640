import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import PaymentDialogStepError from "./paymentDialogStepError";
import { useGetPaymentLinkMutation } from "../../../features/io/ioMoneyDonationApiSlice";
import { setNavigationHeaderEnabled, setStepError } from "../../../features/registrationSlice";
import DataLayer from "../../../utils/dataLayer";
import NavigationLink from "../../navigationLink/navigationLink";

const PaymentDialogStepSuccess = ({ content }) => {
  const dispatch = useDispatch();

  const [, paymentLinkResult] = useGetPaymentLinkMutation({ fixedCacheKey: "getPaymentLinkCacheKey" });

  const paymentData = useSelector((state) => state.registration.paymentData);

  dispatch(
    setStepError(true),
  );

  if (paymentLinkResult.isError) {
    dispatch(
      setNavigationHeaderEnabled(false),
    );

    DataLayer.pushEvent("pp_donation_step_payment_final", { event_value: "fail" });

    return (<PaymentDialogStepError content={content} error={paymentLinkResult.error} />);
  }

  if (paymentLinkResult.isSuccess && paymentLinkResult.data?.link?.url) {
    DataLayer.pushEvent("pp_donation_step_payment_final", { event_value: "success", payment_amount: parseInt(paymentData.amount, 10) });

    DataLayer.removeUnloadListener();

    setTimeout(() => {
      window.location = paymentLinkResult.data.link.url;
    }, 3000);
  }

  if (paymentData.interval !== "ONCE" && process.env.GATSBY_SITE === "gb" && paymentLinkResult?.data?.payment?.success) {
    setTimeout(() => {
      window.location = `${process.env.GATSBY_SITE_URL}${content.successPage}`;
    }, 3000);
  }

  return (
    <>
      <Row className="align-self-center">
        <Col xs={12} lg={{ offset: 1, span: 10 }} className="text-center">
          <Spinner animation="border" variant="dark" />
          {paymentData.interval === "ONCE" && (
            <>
              <h3 className="mt-24">
                <FormattedMessage id="payment_dialog.submit.title" />
              </h3>
              <p className="text-black">
                <FormattedMessage
                  id="payment_dialog.submit.text"
                  values={{
                    contact: (
                      <NavigationLink href={content.contactLink} target="_blank">
                        <FormattedMessage id="payment_dialog.submit.text_contacttext" />
                      </NavigationLink>
                    ),
                    link: (
                      <NavigationLink href={paymentLinkResult?.data?.link?.url || content.contactLink} target="_blank">
                        <FormattedMessage id="payment_dialog.submit.text_linktext" />
                      </NavigationLink>
                    ),
                  }}
                />
              </p>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

PaymentDialogStepSuccess.stepName = "payment_final";

PaymentDialogStepSuccess.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default PaymentDialogStepSuccess;
