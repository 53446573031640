import React, { useEffect, useRef } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";

import DonorPathPopover from "./donorPathPopover";
import DonorPathStep from "./donorPathStep";
import DonorPathTopic from "./donorPathTopic";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ImgDonorPathAfr from "../../images/a-img-donorpath-AFR.svg";
import ImgDonorPathIn from "../../images/a-img-donorpath-IN.svg";
import ImgDonorPath from "../../images/a-img-donorpath.svg";
import NavigationLink from "../navigationLink/navigationLink";

import "./donorGraphic.scss";

const DonorGraphic = (props) => {
  const {
    headline,
    description,
    topics,
  } = props;

  const stepsRef = useRef([]);
  const donorPathTopics = useRef([]);
  const parentRef = useRef();
  const points = [];
  let donorGraphicSvgRef = {};

  const {
    width,
  } = useWindowDimensions();

  if (typeof document === "undefined") {
    return null;
  }

  for (let i = 0; i < 6; i += 1) {
    points[i] = document.getElementById(`donor-path-point-${i}`);
  }

  donorGraphicSvgRef = document.getElementById("donor-graphic__svg");

  const updatePath = () => {
    points.forEach((item, index) => {
      if (!item || !window) {
        return;
      }

      // DOM
      const current = donorPathTopics.current[index];

      // Rect
      const itemRect = item.getBoundingClientRect();
      const clickableStepRect = stepsRef.current[index].getBoundingClientRect();
      const currentRect = current.getBoundingClientRect();
      const parentRect = parentRef.current.getBoundingClientRect();
      const donorGraphicSvgRefRect = donorGraphicSvgRef.getBoundingClientRect();

      const left = itemRect.x + (itemRect.width / 2) - (48 / 2);
      const top = itemRect.y + (itemRect.height / 2) + window.pageYOffset - (48 / 2);

      stepsRef.current[index].style.left = `${left.toString()}px`;
      stepsRef.current[index].style.top = `${top.toString()}px`;

      if (donorPathTopics.current === null || donorPathTopics.current.length === 0) {
        return;
      }

      // Default values
      current.style.top = `${top}px`;
      current.style.maxWidth = `${parentRect.width + parentRect.left - (clickableStepRect.right + 12)}px`;

      let gap = 12;

      if (width > 1200) {
        gap = 30;
      }

      if (index === 0) {
        current.style.top = `${top - currentRect.height - gap}px`;

        current.style.left = `${left}px`;
      } else if (index % 2 === 0) {
        current.style.right = `${width - left + gap}px`;

        current.style.maxWidth = `${clickableStepRect.left - gap - parentRect.left}px`;

        if (width - parseInt(current.style.right, 10) > donorGraphicSvgRefRect.left) {
          current.style.right = `${width - donorGraphicSvgRefRect.left + gap}px`;
        }
      } else if (index % 2 === 1) {
        current.style.left = `${left + parseInt(clickableStepRect.width, 10) + gap}px`;

        if (parseInt(current.style.left, 10) < donorGraphicSvgRefRect.right) {
          current.style.left = `${donorGraphicSvgRefRect.right + gap}px`;
        }
      }
    });
  };

  useEffect(() => {
    setTimeout(() => updatePath(), 200);
  }, [width]);

  let imgDonorPath = <ImgDonorPath />;

  switch (process.env.GATSBY_SITE) {
    case "in":
      imgDonorPath = <ImgDonorPathIn />;
      break;
    case "za":
      imgDonorPath = <ImgDonorPathAfr />;
      break;
    default:
  }

  return (
    <div ref={parentRef}>
      <Row className="donor-graphic">
        <Col
          xs={12}
          md={{ offset: 1, span: 10 }}
          xl={{ offset: 2, span: 8 }}
        >
          <h1 className="donor-graphic__headline">{headline}</h1>
          <p className="donor-graphic__description">{description}</p>
        </Col>
        <Col
          xs={12}
          md={{ offset: 3, span: 6 }}
          xl={{ offset: 2, span: 8 }}
          className="donor-graphic__image"
        >

          {imgDonorPath}

          {topics.map((item, index) => {
            if (width < 768) {
              return (
                <OverlayTrigger
                  key={item.id}
                  placement="bottom-start"
                  trigger="click"
                  rootClose
                  overlay={(
                    <div className="container-fluid donor-graphic__topic__overlay">
                      <DonorPathPopover
                        stepNumber={item.stepNumber}
                        description={item.description}
                        topic={item.topic}
                        articles={item.articles}
                        onclick={() => {
                          if (typeof document !== "undefined") {
                            document.body.click();
                          }
                        }}
                      />
                    </div>
                  )}
                >
                  <div
                    key={item.id}
                    ref={(ref) => {
                      stepsRef.current[index] = ref;
                    }}
                    className="donor-graphic__step-point"
                  >
                    <DonorPathStep stepNumber={(index + 1).toString()} clickable />
                  </div>
                </OverlayTrigger>
              );
            }

            return (
              <div
                key={item.id}
                ref={(ref) => {
                  stepsRef.current[index] = ref;
                }}
                className="donor-graphic__step-point"
              >
                <DonorPathStep stepNumber={(index + 1).toString()} clickable />
              </div>
            );
          })}
          {topics.map((item, index) => (
            <div
              key={item.id}
              className="no-style donor-graphic__step-point-topic"
              ref={(ref) => {
                donorPathTopics.current[index] = ref;
              }}
            >
              <NavigationLink href={item.href} className="no-style">
                <DonorPathTopic
                  topic={item.topic}
                  stepNumber={item.stepNumber}
                  number={item.number}
                />
              </NavigationLink>
              <DonorPathPopover
                stepNumber={item.stepNumber}
                description={item.description}
                topic={item.topic}
                articles={item.articles}
                className="donor-graphic__topic__overlay"
              />
            </div>
          ))}
        </Col>
        {width < 768 && (
          <Col className="donor-graphic__topics">
            <Row>
              <Col sm={6}>
                {topics.map((item, index) => {
                  if (index < topics.length / 2) {
                    return (
                      <OverlayTrigger
                        key={item.id}
                        placement="bottom-start"
                        trigger="click"
                        rootClose
                        overlay={(
                          {
                            placement, scheduleUpdate, arrowProps,
                            outOfBoundaries, show, ...overlayProps
                          },
                        ) => (
                          <div className="container-fluid donor-graphic__topic__overlay" {...overlayProps}>
                            <DonorPathPopover
                              stepNumber={item.stepNumber}
                              description={item.description}
                              topic={item.topic}
                              articles={item.articles}
                            />
                          </div>
                        )}
                      >
                        <div
                          className={classNames("donor-graphic__topic", { "m-sm-0": (index + 1) === (topics.length / 2) })}
                        >
                          <DonorPathTopic
                            topic={item.topic}
                            stepNumber={item.stepNumber}
                            number={item.number}
                          />
                        </div>
                      </OverlayTrigger>
                    );
                  }

                  return null;
                })}
              </Col>
              <Col sm={6}>
                {topics.map((item, index) => {
                  if (index >= topics.length / 2) {
                    return (
                      <OverlayTrigger
                        key={item.id}
                        placement="bottom"
                        trigger="click"
                        rootClose
                        overlay={(
                          <div className="container-fluid donor-graphic__topic__overlay">
                            <DonorPathPopover
                              stepNumber={item.stepNumber}
                              description={item.description}
                              topic={item.topic}
                              articles={item.articles}
                              onclick={() => {
                                document.body.click();
                              }}
                            />
                          </div>
                        )}
                      >
                        <div
                          className={classNames("donor-graphic__topic", { "m-sm-0": (index + 1) === (topics.length / 2) })}
                        >
                          <DonorPathTopic
                            topic={item.topic}
                            stepNumber={item.stepNumber}
                            number={item.number}
                          />
                        </div>
                      </OverlayTrigger>
                    );
                  }

                  return null;
                })}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

DonorGraphic.propTypes = {
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      articles: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }),
      ),
      description: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      stepNumber: PropTypes.string.isRequired,
      topic: PropTypes.string.isRequired,
      topicText: PropTypes.string.isRequired,
    }),
  ),
};

DonorGraphic.defaultProps = {
  topics: null,
};

export default DonorGraphic;
