import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import saveTrim from "../../utils/saveTrim";

import "./elementHeader.scss";

const ElementHeader = ({ headline, description, isLeadText }) => {
  let colProps = {
    md: 8,
  };

  if (isLeadText) {
    colProps = {
      xs: 12,
    };
  }

  let title;
  const saveHeadline = saveTrim(headline);

  if (saveHeadline) {
    title = (
      <h2 className={classNames({ "typo-h3": isLeadText }) || undefined}>
        {saveHeadline}
      </h2>
    );
  }

  return (
    <Row className="element-header">
      <Col {...colProps}>
        {title}
        {description !== null && (
          <p className={classNames("typo-h4", { "mt-0": !saveHeadline })}>{description}</p>
        )}
      </Col>
    </Row>
  );
};

ElementHeader.propTypes = {
  description: PropTypes.string,
  headline: PropTypes.string,
  isLeadText: PropTypes.bool,
};

ElementHeader.defaultProps = {
  description: null,
  headline: "",
  isLeadText: false,
};

export default ElementHeader;
