import PropTypes from "prop-types";

import NewsCard from "../news/newsCard";

const CampaignRelatedStory = ({ data }) => {
  let storyData = Array.isArray(data) ? data : data.value;

  storyData = storyData.filter((story) => (
    story.elements.related_story.value.length > 0
  ));

  const stories = [];

  storyData.forEach((story) => {
    story.elements.related_story.value.forEach((articleItem) => {
      if (!stories.find((item) => item.id === articleItem.id)) {
        stories.push(articleItem);
      }
    });
  });

  return NewsCard({ currentFirst: true, data: stories });
};

CampaignRelatedStory.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default CampaignRelatedStory;
