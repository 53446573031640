import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import CleaveFormField from "../../dynamicForm/cleaveFormField/cleaveFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationAdditionalInformationZa = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const emailMessage = intl.formatMessage({ id: "registration.additional.error.email" });
  const phoneMessage = intl.formatMessage({ id: "registration.additional.error.phone" });

  const validationSchema = useMemo(() => object().shape({
    emailAddress: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage),
    landlineNumber: string()
      .matches(
        /^(\d{3}-\d{3}-\d{4})$/,
        phoneMessage,
      ),
    mobileNumber: string()
      .required(requiredMessage)
      .matches(
        /^(\d{3}-\d{3}-\d{4})$/,
        phoneMessage,
      ),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.additional.title"
      />
      <Formik
        initialValues={{
          emailAddress: registrationData.emailAddress,
          landlineNumber: registrationData.landlineNumber,
          mobileNumber: registrationData.mobileNumber,
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              emailAddress: values.emailAddress,
              landlineNumber: values.landlineNumber,
              mobileNumber: values.mobileNumber,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_additional_information", { event_value: "success" });

          next();
        }}
        validate={(values) => {
          const errors = {};

          if (values.mobileNumber
            && values.landlineNumber
            && (values.mobileNumber === values.landlineNumber)) {
            errors.mobileNumber = intl.formatMessage({ id: "registration.contact.error.same_number" });
            errors.landlineNumber = intl.formatMessage({ id: "registration.contact.error.same_number" });
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <CleaveFormField
                  label={intl.formatMessage({ id: "registration.additional.mobileNumber" })}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  id="mobileNumber"
                  errors={errors}
                  options={{
                    blocks: [3, 3, 4],
                    delimiter: "-",
                    numericOnly: true,
                  }}
                  maxLength={12}
                  required
                  additionalInvalidCondition={
                  errors.mobileNumber
                    && (touched.mobileNumber || touched.landlineNumber)
                  }
                />
              </Col>
              <Col lg={6}>
                <CleaveFormField
                  label={intl.formatMessage({ id: "registration.additional.additionalPhoneNumber" })}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  id="landlineNumber"
                  errors={errors}
                  options={{
                    blocks: [3, 3, 4],
                    delimiter: "-",
                    numericOnly: true,
                  }}
                  maxLength={12}
                  additionalInvalidCondition={
                    errors.landlineNumber
                    && (touched.mobileNumber || touched.landlineNumber)
                  }
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="emailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.additional.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationAdditionalInformationZa.stepName = "registration_additional_information";

RegistrationDialogStepRegistrationAdditionalInformationZa.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationAdditionalInformationZa;
