import { useEffect } from "react";

import { useFormikContext } from "formik";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { setWarning } from "../../features/registrationSlice";

const FormObserver = ({ updateAddress }) => {
  const {
    values, getFieldProps,
  } = useFormikContext();

  const intl = useIntl();

  const warning = useSelector((state) => state.registration.warning);
  const dispatch = useDispatch();

  let tmpWarning = {};

  const compareTwoValues = (value1, value2) => {
    if ((value1 && value2) && (value1.value && value2.value)) {
      if (value1.value.toString().toLowerCase() === value2.value.toString().toLowerCase()) {
        if (!tmpWarning[value1.id]) {
          tmpWarning[value1.id] = value1.label;
        }

        if (!tmpWarning[value2.id]) {
          tmpWarning[value2.id] = value2.label;
        }
      }
    }
  };
  const compareThreeValues = (value1, value2, value3) => {
    if ((value1 && value2 && value3) && (value1.value && value2.value && value3.value)) {
      if (value1.value.toString().toLowerCase() === value2.value.toString().toLowerCase()
        && value1.value.toString().toLowerCase() === value3.value.toString().toLowerCase()
        && value2.value.toString().toLowerCase() === value3.value.toString().toLowerCase()) {
        if (!tmpWarning[value1.id]) {
          tmpWarning[value1.id] = value1.label;
        }

        if (!tmpWarning[value2.id]) {
          tmpWarning[value2.id] = value2.label;
        }

        if (!tmpWarning[value3.id]) {
          tmpWarning[value3.id] = value3.label;
        }
      }
    }
  };

  const checkValues = () => {
    const a = {};
    Object.keys(values).forEach((id) => {
      const label = document.querySelector(`label[for=${getFieldProps(id).name}]`)?.innerHTML;

      if (label) {
        a[id] = {
          id,
          label,
          value: getFieldProps(id).value,
        };
      }
    });

    switch (process.env.GATSBY_SITE) {
      case "gb":
        if (updateAddress) {
          compareTwoValues(a.prev_street, a.prev_apartment);
          compareTwoValues(a.prev_street, a.prev_house_no);
          compareTwoValues(a.prev_house_no, a.prev_apartment);
          compareTwoValues(a.prev_house_no, a.prev_house_name);
          compareTwoValues(a.prev_street, a.prev_house_name);
          compareThreeValues(a.prev_street, a.prev_apartment, a.prev_house_no);
          compareThreeValues(a.prev_street, a.prev_apartment, a.prev_house_name);
          compareTwoValues(a.current_street, a.current_apartment);
          compareTwoValues(a.current_street, a.current_house_no);
          compareTwoValues(a.current_house_no, a.current_apartment);
          compareTwoValues(a.current_house_no, a.current_house_name);
          compareTwoValues(a.current_street, a.current_house_name);
          compareThreeValues(a.current_street, a.current_apartment, a.current_house_no);
          compareThreeValues(a.current_street, a.current_apartment, a.current_house_name);
        } else {
          compareTwoValues(a.street, a.apartment);
          compareTwoValues(a.street, a.houseNo);
          compareTwoValues(a.houseNo, a.apartment);
          compareTwoValues(a.houseNo, a.houseName);
          compareTwoValues(a.street, a.houseName);
          compareThreeValues(a.street, a.apartment, a.houseNo);
          compareThreeValues(a.street, a.apartment, a.houseName);
        }
        break;
      case "pl":
        if (updateAddress) {
          compareTwoValues(a.prev_street, a.prev_apartment);
          compareTwoValues(a.prev_street, a.prev_house_no);
          compareThreeValues(a.prev_street, a.prev_apartment, a.prev_house_no);
          compareTwoValues(a.prev_house_no, a.prev_apartment);
          compareTwoValues(a.current_street, a.current_apartment);
          compareTwoValues(a.current_street, a.current_house_no);
          compareThreeValues(a.current_street, a.current_apartment, a.current_house_no);
          compareTwoValues(a.current_house_no, a.current_apartment);
        } else {
          compareTwoValues(a.street, a.apartment);
          compareTwoValues(a.street, a.houseNo);
          compareThreeValues(a.street, a.apartment, a.houseNo);
          compareTwoValues(a.houseNo, a.apartment);
        }
        break;
      case "de":
        if (updateAddress) {
          compareTwoValues(a.prev_street, a.prev_house_no);
          compareThreeValues(a.prev_street, a.prev_house_no, a.prev_apartment);
          compareTwoValues(a.prev_apartment, a.prev_house_no);
          compareTwoValues(a.prev_apartment, a.prev_street);
          compareTwoValues(a.additionalPhoneNumber, a.mobileNumber);
          compareTwoValues(a.current_street, a.current_house_no);
          compareThreeValues(a.current_street, a.current_house_no, a.current_apartment);
          compareTwoValues(a.current_apartment, a.current_house_no);
          compareTwoValues(a.current_apartment, a.current_street);
          compareTwoValues(a.lastname, a.current_co);
          compareThreeValues(a.lastname, a.current_co, a.firstname);
        } else {
          compareTwoValues(a.street, a.houseNo);
          compareThreeValues(a.street, a.houseNo, a.apartment);
          compareTwoValues(a.apartment, a.houseNo);
          compareTwoValues(a.apartment, a.street);
          compareTwoValues(a.additionalPhoneNumber, a.mobileNumber);

          const firstname = {
            id: "firstname",
            label: intl.formatMessage({ id: "registration.personal.firstname" }),
            value: values.firstname,
          };
          const lastname = {
            id: "lastname",
            label: intl.formatMessage({ id: "registration.personal.lastname" }),
            value: values.lastname,
          };

          const co = {
            id: "careOfAddress",
            label: intl.formatMessage({ id: "registration.address.careofaddress" }),
            value: values.careOfAddress,
          };

          compareTwoValues(a.lastname || lastname, a.careOfAddress || co);
          compareThreeValues(
            a.firstname || firstname,
            a.lastname || lastname,
            a.careOfAddress || co,
          );
        }
        break;
      case "in":
        compareTwoValues(a.street, a.houseNo);
        compareTwoValues(a.apartment, a.houseNo);
        compareTwoValues(a.apartment, a.houseName);
        compareTwoValues(a.locality, a.city);
        compareTwoValues(a.locality, a.county);

        if (updateAddress) {
          compareTwoValues(a.prev_street, a.prev_house_no);
          compareTwoValues(a.prev_apartment, a.prev_house_no);
          compareTwoValues(a.prev_apartment, a.prev_house_name);
          compareTwoValues(a.prev_locality, a.prev_city);
          compareTwoValues(a.prev_locality, a.prev_district);
          compareTwoValues(a.current_street, a.current_house_no);
          compareTwoValues(a.current_apartment, a.current_house_no);
          compareTwoValues(a.current_apartment, a.current_house_name);
          compareTwoValues(a.current_locality, a.current_city);
          compareTwoValues(a.current_locality, a.current_district);
        }
        break;
      case "us":
        if (updateAddress) {
          compareTwoValues(a.prev_street, a.prev_house_no);
          compareTwoValues(a.prev_apartment, a.prev_street);
          compareTwoValues(a.prev_apartment, a.prev_house_no);
          compareThreeValues(a.prev_street, a.prev_house_no, a.prev_apartment);
          compareTwoValues(a.current_street, a.current_house_no);
          compareTwoValues(a.current_apartment, a.current_street);
          compareTwoValues(a.current_apartment, a.current_house_no);
          compareThreeValues(a.current_street, a.current_house_no, a.current_apartment);
        } else {
          compareTwoValues(a.street, a.houseNo);
          compareTwoValues(a.apartment, a.street);
          compareTwoValues(a.apartment, a.houseNo);
          compareThreeValues(a.street, a.houseNo, a.apartment);
          compareTwoValues(a.street, a.poBox);
          compareTwoValues(a.poBox, a.houseNo);
        }
        break;
      case "cl":
        if (updateAddress) {
          compareTwoValues(a.prev_street, a.prev_house_no);
          compareTwoValues(a.prev_street, a.prev_info);
          compareTwoValues(a.prev_houseNo, a.prev_info);
          compareThreeValues(a.prev_street, a.prev_house_no, a.prev_info);
          compareTwoValues(a.current_street, a.current_house_no);
          compareTwoValues(a.current_street, a.current_info);
          compareTwoValues(a.current_house_no, a.current_info);
          compareThreeValues(a.current_street, a.current_house_no, a.current_info);
        } else {
          compareTwoValues(a.street, a.houseNo);
          compareTwoValues(a.street, a.info);
          compareTwoValues(a.houseNo, a.info);
          compareThreeValues(a.street, a.houseNo, a.info);
        }
        break;
      case "za":
        compareTwoValues(a.apartment, a.suburb);
        compareTwoValues(a.houseNo, a.apartment);
        compareTwoValues(a.street, a.apartment);
        compareTwoValues(a.street, a.suburb);
        compareTwoValues(a.houseNo, a.postCode);
        break;
      default: tmpWarning = {};
    }

    const array2Sorted = Object.keys(tmpWarning).slice().sort();

    if (Object.keys(warning).length !== array2Sorted.length
      || Object.keys(warning).slice().sort().every((value, index) => (
        value !== array2Sorted[index]))) {
      dispatch(setWarning(tmpWarning));
    }
  };

  useEffect(() => {
    checkValues();
  }, [values]);

  return null;
};

FormObserver.propTypes = {
  updateAddress: PropTypes.bool,
};

FormObserver.defaultProps = {
  updateAddress: false,
};

export default FormObserver;
