import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import GetLearnTopicCount from "./getLearnTopicCount";
import TopicTile from "../../components/topicTile/topicTile";
import KontentAssetsImage from "../kontentAssetsImage";

const LearnTopicTiles = ({ data }) => {
  const learnTopicTiles = Array.isArray(data) ? data : data.value;

  if (learnTopicTiles.length === 0) {
    return null;
  }

  const cards = [];

  learnTopicTiles.forEach((learnTopicTile) => {
    cards.push(
      <TopicTile
        articleCount={(
          GetLearnTopicCount({
            codename:
              learnTopicTile.elements.learn_topic.value.length > 0
              && learnTopicTile.elements.learn_topic.value[0].codename,
          })
        )}
        key={learnTopicTile.id}
        headline={learnTopicTile.elements.headline.value}
        href={learnTopicTile.id}
        description={learnTopicTile.elements.lead_text.value}
        image={
          learnTopicTile.elements.topic_illustration_small_tile.value.length > 0
            ? (
              <KontentAssetsImage
                width={200}
                data={learnTopicTile.elements.topic_illustration_small_tile.value[0]}
              />
            )
            : null
        }
      />,
    );
  });

  return cards;
};

export const query = graphql`
  fragment LearnTopicTiles on kontent_item_learn_topics_page {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      lead_text {
        value
      }
      learn_topic {
        value {
          codename
        }
      }
      topic_illustration_small_tile {
        name
        type
        value {
          name
          height
          description
          url
          width
          size
          type
        }
      }
    }
  }
`;

LearnTopicTiles.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default LearnTopicTiles;
