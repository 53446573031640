import { placeholderTypes } from "../../components/imagePlaceholder/imagePlaceholder";

const backgroundImageHelper = (image, imageProps, placeholderTyp) => {
  if (!image
    || !image.value
    || image.value.length === 0
    || !image.value[0].elements.image.value
    || image.value[0].elements.image.value.length === 0) {
    const imageName = placeholderTypes[placeholderTyp] || "placeholder";

    return {
      backgroundColor: "#F2F2F7",
      backgroundImage: `url('/images/placeholder/${imageName}.webp')`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
  }

  const { url } = image.value[0].elements.image.value[0];
  let backgroundPosition = null;

  if (
    image.value[0].elements.set_focal_point__image_transformer
    && image.value[0].elements.set_focal_point__image_transformer.value
  ) {
    const focalPoint = JSON.parse(image.value[0].elements.set_focal_point__image_transformer.value);

    if (focalPoint.length > 0) {
      const kontentFocalPoint = focalPoint[0].transforms.crop.zoom;

      if (kontentFocalPoint) {
        backgroundPosition = {
          backgroundPosition: `${Math.abs(kontentFocalPoint.xFloat * 100)}% ${Math.abs(kontentFocalPoint.yFloat * 100)}%`,
        };
      }
    }
  }

  const imagePropsUrl = {
    auto: "format",
    fm: "pjpg",
    h: 1920,
    w: 1920,
    ...imageProps,
  };

  let tmpRatio = 1;

  // JAD-14063 - Remove HDPI Images
  if (imagePropsUrl.w > 1920) {
    tmpRatio = imagePropsUrl.h / imagePropsUrl.w;

    imagePropsUrl.w = 1920;
    imagePropsUrl.h = tmpRatio * 1920;
  }

  if (imagePropsUrl.h > 2880) {
    tmpRatio = imagePropsUrl.w / imagePropsUrl.h;

    imagePropsUrl.h = 2880;
    imagePropsUrl.w = tmpRatio * 2880;
  }

  const imagePropsConverted = Object.keys(imagePropsUrl).map(
    (key) => `${key}=${imagePropsUrl[key]}`,
  ).join(("&"));

  return {
    backgroundImage: `url('${url}?${imagePropsConverted}')`,
    ...backgroundPosition,
  };
};

export default backgroundImageHelper;
