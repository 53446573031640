import { graphql, useStaticQuery } from "gatsby";

const GetDonorTopicCount = (props) => {
  const data = useStaticQuery(graphql`
    {
      allKontentItemArticleDonorArea {
        group(field: elements___donor_topic___value___codename) {
          totalCount
          fieldValue
        }
      }
    }
  `);

  const {
    codename,
  } = props;

  const topicGroup = data.allKontentItemArticleDonorArea.group.find(
    (el) => el.fieldValue === codename,
  );

  if (topicGroup && topicGroup.totalCount) {
    return topicGroup.totalCount;
  }

  return 0;
};

export default GetDonorTopicCount;
