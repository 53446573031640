import IoBaseApiSlice from "./ioBaseApiSlice";

export const IoMoneyDonationApiSlice = IoBaseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPaymentLink: build.mutation({
      query: ({ data, urns = null }) => {
        let urn = process.env.GATSBY_IO_URN_PAYMENT_LINK;

        if (process.env.GATSBY_SITE === "gb" && data?.md_data?.account) {
          urn = process.env.GATSBY_IO_URN_RECURRING_PAYMENT_LINK;
        }

        return {
          apiRequirements: ["session", "csrfToken"],
          data: {
            classification: "Restricted",
            payload: data,
            ...(urns !== null && { meta: urns }),
          },
          method: "post",
          url: `${process.env.GATSBY_IO_HOST}/md/${urn}`,
        };
      },
      transformResponse: ({ payload }) => payload,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPaymentLinkMutation,
} = IoMoneyDonationApiSlice;
