import React from "react";

import PropTypes from "prop-types";

import "./socialMediaBox.scss";

const SocialMediaBox = ({ image, title, description }) => (
  <div className="social-media-box">
    <div className="social-media-box__image">
      {image}
    </div>
    <div className="social-media-box__content">
      <div className="social-media-box__title">{title}</div>
      <div className="social-media-box__description">&ldquo;{description}&rdquo;</div>
    </div>
  </div>
);

SocialMediaBox.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default SocialMediaBox;
