import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import QuickLinkTile from "../../components/quickLinkTile/quickLinkTile";
import iconHelper from "../../utils/iconHelper";

const QuickLinkTiles = ({ data, variant }) => {
  const quickLinkData = Array.isArray(data) ? data : data.value;

  const quickLinkList = [];

  quickLinkData.forEach((quickLink) => {
    quickLinkList.push(
      <QuickLinkTile
        headline={quickLink.elements.headline.value}
        text={quickLink.elements.text.value}
        icon={
          iconHelper(
            quickLink.elements.icon.value.length > 0
              ? quickLink.elements.icon.value[0].codename
              : null,
          )
        }
        url={
          quickLink.elements.button_url.value.length > 0
            ? quickLink.elements.button_url.value[0].id
            : null
        }
        disabled={quickLink.elements.disabled.value.length > 0 && quickLink.elements.disabled.value[0].codename === "true"}
        variant={variant}
      />
      ,
    );
  });

  return React.Children.toArray(quickLinkList);
};

export const query = graphql`
  fragment QuickLinkTiles on kontent_item_press_quicklinks {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      icon {
        value {
          codename
        }
      }
      button_url {
        value {
          id
        }
      }
      disabled {
        value {
          codename
        }
      }
    }
  }
`;

QuickLinkTiles.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  variant: PropTypes.oneOf(["ssp"]),
};

QuickLinkTiles.defaultProps = {
  variant: null,
};

export default QuickLinkTiles;
