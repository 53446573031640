import React from "react";

import PropTypes from "prop-types";

import "./dropdownItem.scss";

const DropdownItem = (props) => {
  const {
    postIcon,
    preIcon,
    text,
    active,
  } = props;

  return (
    <div className="dropdown-item2">
      {preIcon}
      <div className="dropdown-item__text typo-body2">{text}</div>
      {!active && postIcon}
    </div>
  );
};

DropdownItem.propTypes = {
  active: PropTypes.bool,
  postIcon: PropTypes.oneOfType([PropTypes.object]),
  preIcon: PropTypes.oneOfType([PropTypes.object]),
  text: PropTypes.string.isRequired,
};

DropdownItem.defaultProps = {
  active: false,
  postIcon: null,
  preIcon: null,
};

export default DropdownItem;
