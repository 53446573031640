import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

const KontentItemGlobalData = ({ data }) => {
  const globalData = data.length > 0 ? data[0] : data;

  const collection = useStaticQuery(graphql`
    {
      allKontentItemGlobalData {
        nodes {
          system {
            codename
            id
          }
          elements {
            data {
              value
            }
          }
        }
      }
    }
  `);

  if (collection.allKontentItemGlobalData) {
    const figures = collection.allKontentItemGlobalData.nodes;

    const item = figures.find((el) => el.system.codename === globalData.codename);

    if (item) {
      return item.elements.data.value;
    }
  }

  return null;
};

KontentItemGlobalData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemGlobalData;
