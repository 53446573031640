import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import MegaMenuResult from "../../components/megaMenuResult/megaMenuResult";
import KontentItemImage from "../kontentItemImage";

const MegaMenuResultsCampaign = () => {
  const data = useStaticQuery(graphql`
    {
      allKontentItemArticlePageCampaign(sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          id
          elements {
            hero_image {
              value {
                ...KontentItemImage
              }
            }
            hero_headline {
              value
            }
          }
        }
      }
    }
  `);

  const slicedData = data.allKontentItemArticlePageCampaign.nodes.slice(0, 4);

  return slicedData.map((campaign) => (
    <MegaMenuResult
      key={campaign.id}
      href={campaign.id}
      image={(
        <KontentItemImage
          data={campaign.elements.hero_image}
          width={48}
          height={48}
          aspectRatio={1}
          fit="crop"
          defaultImage
        />
      )}
    >
      {campaign.elements.hero_headline.value}
    </MegaMenuResult>
  ));
};

export default MegaMenuResultsCampaign;
