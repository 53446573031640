import React, { useEffect, useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { bool, object } from "yup";

import RegistrationDialogStepError from "./registrationDialogStepError";
import { useSubmitIoRegistrationMutation } from "../../../features/io/ioRegistrationApiSlice";
import { setRegistrationData, setStepError, setSuccess } from "../../../features/registrationSlice";
import usePrevious from "../../../hooks/usePrevious";
import useURNParams from "../../../hooks/useURNParams";
import { IconSubmit18, IconSubmit24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import { dateFactory, format } from "../../../utils/dateHelper";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import Checkbox from "../../dynamicForm/checkbox/checkbox";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepOverviewDe = ({ content, next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const lastSuccess = useSelector((state) => state.registration.lastSuccess);
  const stepError = useSelector((state) => state.registration.stepError);
  const dispatch = useDispatch();

  const intl = useIntl();
  const urnParams = useURNParams();

  const [submitIoRegistration, submitIoRegistrationResult] = useSubmitIoRegistrationMutation({ fixedCacheKey: "submitIoRegistrationCacheKey" });

  const prevSubmitIoRegistrationResult = usePrevious(submitIoRegistrationResult);

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    activityInformation: bool(),
    dataProtection: bool()
      .required(requiredMessage)
      .oneOf([true], requiredMessage),
    personalizedNewsletter: bool(),
  }), []);

  useEffect(() => {
    if (submitIoRegistrationResult.isSuccess && !prevSubmitIoRegistrationResult.isSuccess) {
      DataLayer.removeUnloadListener();
      DataLayer.pushEvent("pp_registration_step_final", { event_value: "success" });
      DataLayer.pushEvent("pp_registration_step_final_urn", { event_value: urnParams?.refs });
      DataLayer.pushEvent("ppe_registration_success_goal");

      dispatch(
        setSuccess(),
      );

      if (content.successPage) {
        window.skipBeforeUnload = true;
        window.location.href = content.successPage;
      } else {
        next();
      }
    }

    if (submitIoRegistrationResult.isError && !prevSubmitIoRegistrationResult?.isError) {
      DataLayer.pushEvent("pp_registration_step_final", { event_value: "fail" });
    }

    if (submitIoRegistrationResult.isError || prevSubmitIoRegistrationResult?.isError) {
      dispatch(
        setStepError(true),
      );
    }
  }, [submitIoRegistrationResult]);

  if (stepError) {
    return (
      <RegistrationDialogStepError
        content={content}
        error={submitIoRegistrationResult.error}
      />
    );
  }

  const {
    salutation,
    firstname,
    lastname,
    gender,
    birthdate,
    weightMetric,
    heightMetric,
    apartment,
    careOfAddress,
    street,
    houseNo,
    postCode,
    city,
    mobileNumber,
    emailAddress,
    ethnicityLabel,
    additionalPhoneNumber,
    relationshipTypeLabel,
    relationshipFirstname,
    relationshipLastname,
    rootsId,
  } = registrationData;

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.overview.title"
      />
      <Formik
        initialValues={{
          activityInformation: false,
          consentDeclaration: false,
          dataProtection: false,
          personalizedNewsletter: false,
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          // Prevent submitting if last successful submit was less than 60 seconds ago.
          if (((new Date()).getTime() - lastSuccess) / 1000 < 60) {
            dispatch(
              setStepError(true),
            );

            return;
          }

          dispatch(setRegistrationData({
            activityInformation: values.activityInformation,
            personalizedNewsletter: values.personalizedNewsletter,
          }));

          if (!submitIoRegistrationResult.isSuccess) {
            submitIoRegistration({
              data: RegistrationDataHelper.preparePayload({
                ...registrationData,
                activityInformation: values.activityInformation,
                personalizedNewsletter: values.personalizedNewsletter,
              }),
              urns: urnParams,
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} className="mb-36">
                <Row>
                  <Col xs={6} className="registration-dialog-step__overview-group">
                    <div className="registration-dialog-step__section-title">
                      <FormattedMessage id="registration.overview.personal_details" />
                    </div>
                    <div className="typo-body2">
                      {[
                        intl.formatMessage({ id: `form.salutation.mr${gender === "F" ? "s" : ""}` }),
                        salutation,
                        firstname,
                        lastname,
                      ].join(" ")}<br />
                      {birthdate && (
                        <>
                          {format(dateFactory(birthdate))}<br /><br />
                        </>
                      )}
                      {weightMetric} kg<br />
                      {heightMetric} cm
                    </div>
                  </Col>
                  <Col xs={6} className="registration-dialog-step__overview-group">
                    <div className="registration-dialog-step__section-title">
                      <FormattedMessage id="registration.overview.contact_data" />
                    </div>
                    <div className="typo-body2">
                      {apartment && (
                        <>
                          {apartment}<br />
                        </>
                      )}
                      {careOfAddress && (
                        <>
                          {careOfAddress}<br />
                        </>
                      )}
                      {street} {houseNo}<br />
                      {postCode} {city}
                      <br /><br />

                      {mobileNumber && (
                        <>
                          {mobileNumber}<br />
                        </>
                      )}
                      {additionalPhoneNumber && (
                        <>
                          {additionalPhoneNumber}<br />
                        </>
                      )}
                      {emailAddress}<br />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="registration-dialog-step__section-title">
                      <FormattedMessage id="registration.overview.ethnicity" />
                    </div>
                    <div className="typo-body2">
                      {ethnicityLabel}
                    </div>
                  </Col>
                  {rootsId && (
                    <Col lg={6}>
                      <div className="registration-dialog-step__section-title">
                        <FormattedMessage id="registration.overview.relationship" />
                      </div>
                      <div className="typo-body2">
                        {relationshipFirstname} {relationshipLastname} ({relationshipTypeLabel})
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={12}>
                {content && content.overviewText && (
                  <div className="registration-dialog-step__overview-text">
                    {content.overviewText}
                  </div>
                )}
                <Checkbox
                  id="dataProtection"
                  name="dataProtection"
                  checked={values.dataProtection}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  isInvalid={(errors.dataProtection && touched.dataProtection)}
                  label={intl.formatMessage({ id: "registration.overview.dataprotection" })}
                  errors={errors}
                  feedback={errors.dataProtection}
                  className="checkbox-required"
                  small
                  required
                />
              </Col>
              <Col xs={12}>
                <div className="registration-dialog-step__overview-text">
                  <div className="text--simple">
                    <p>
                      {parse(sanitizeHtml(intl.formatMessage({ id: "registration.overview.stay_contact.text" }),
                        { allowedTags: ["br", "b", "a", "strong"] }))}
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Checkbox
                    label={intl.formatMessage({ id: "registration.overview.activityInformation" })}
                    id="activityInformation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.activityInformation}
                    errors={errors}
                    touched={touched}
                    small
                  />
                </Form.Group>

                <Form.Group>
                  <Checkbox
                    label={intl.formatMessage({ id: "registration.overview.personalizedNewsletter" })}
                    id="personalizedNewsletter"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.personalizedNewsletter}
                    errors={errors}
                    touched={touched}
                    small
                  />
                </Form.Group>

                <div className="registration-dialog-step__overview-text">
                  <p><strong><FormattedMessage id="registration.overview.revocation_title" /></strong></p>

                  <p>
                    {parse(sanitizeHtml(intl.formatMessage({ id: "registration.overview.revocation_subtext" }),
                      { allowedTags: ["br", "b", "a"] }))}
                  </p>
                </div>
              </Col>
              {(content.optinHeadline || content.optinText) && (
                <>
                  <Col xs={12}>
                    <div className="registration-dialog-step__overview-text">
                      <div className="text--simple">
                        {content.optinHeadline && (
                          <p>
                            <strong>{content.optinHeadline}</strong>
                          </p>
                        )}
                        {content.optinText && (
                          <p>
                            {content.optinText}
                          </p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <Checkbox
                      id="consentDeclaration"
                      name="consentDeclaration"
                      checked={values.consentDeclaration}
                      onChange={(event) => {
                        dispatch(
                          setRegistrationData(
                            event.target.checked ? {
                              keepInTouchEmail: "YES",
                              keepInTouchPost: "YES",
                            } : {
                              keepInTouchEmail: "",
                              keepInTouchPost: "",
                            },
                          ),
                        );

                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      isInvalid={(errors.consentDeclaration && touched.consentDeclaration)}
                      label={intl.formatMessage({ id: "registration.consent_declaration_email" })}
                      small
                    />
                  </Col>
                </>
              )}
              <Col xs={12} className="registration-dialog-step__text-revocation">
                {content.revocation}
              </Col>
              <Col xs={12} className="registration-dialog-step__sticky">
                <IconButton
                  type="submit"
                  disabled={isSubmitting || submitIoRegistrationResult.isLoading}
                  loading={submitIoRegistrationResult.isLoading}
                >
                  <FormattedMessage id="registration.overview.button_next" />
                  <AdaptiveIcon
                    sm={<IconSubmit18 />}
                    lg={<IconSubmit24 />}
                  />
                </IconButton>
              </Col>
              <Col xs={12} className="registration-dialog-step__text-revocation mt-0">
                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
            <ScrollToFieldError />
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepOverviewDe.stepName = "registration_final";

RegistrationDialogStepOverviewDe.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepOverviewDe;
