import React, { useMemo } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { mixed, object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import {
  IconError18,
  IconFurther18,
  IconFurther24,
  IconLearnMore18,
} from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import FormObserver from "../../../utils/formik/formObserver";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationPersonalInformationDe = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });

  const warning = useSelector((state) => state.registration.warning);

  let warningInfo;

  if (Object.keys(warning).length > 0) {
    warningInfo = RegistrationDataHelper.generateWarning(warning);
  }

  const validationSchema = useMemo(() => object().shape({
    firstname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 40,
        },
      ))
      .trim()
      .matches(/^[^\d\u0600-\u06FF]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    gender: mixed()
      .required(requiredMessage)
      .oneOf(["M", "F"], requiredMessage),
    lastname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .trim()
      .matches(/^[^\d\u0600-\u06FF]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.personal.title"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              firstname: values.firstname,
              gender: values.gender,
              lastname: values.lastname,
              salutation: values.salutation,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_personal_information", { event_value: "success" });
          DataLayer.pushEvent("pp_registration_step_personal_information_value",
            {
              dimension_reg_sex: values.gender,
              event_value: values.gender,
            });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          setFieldError,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormObserver />
            <Row>
              <Col xs={12}>
                <div className="required-sign typo-label">
                  <FormattedMessage id="registration.personal.gender.title" />
                </div>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "form.sex.male" })}
                    name="gender"
                    value="M"
                    id="gender-male"
                    checked={values.gender === "M"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0 typo-body2", errors.gender && touched.gender ? "is-invalid" : "")}
                  />
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "form.sex.female" })}
                    name="gender"
                    value="F"
                    id="gender-female"
                    checked={values.gender === "F"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0", errors.gender && touched.gender ? "is-invalid" : "")}
                  />
                  <OverlayTrigger
                    placement="top"
                    delay={{ hide: 400, show: 100 }}
                    overlay={(param) => (
                      <Tooltip {...param} id="gender-info">
                        <FormattedMessage id="registration.personal.gender.info" />
                      </Tooltip>
                    )}
                  >
                    <IconLearnMore18 className="text-grey-dark" />
                  </OverlayTrigger>
                  {errors.gender && touched.gender && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.gender}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.personal.salutation" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("salutation", target.value);
                      setFieldError("salutation", undefined);
                    } else {
                      setFieldValue("salutation", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("salutation", true);
                  }}
                  touched={touched}
                  id="salutation"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.titleOptionsDe}
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  id="firstname"
                  label={intl.formatMessage({ id: "registration.personal.firstname" })}
                  type="text"
                  warning={warning}
                  maxLength={40}
                  required
                  touched={touched}
                  errors={errors}
                  values={values}
                  onChange={(event) => {
                    setFieldTouched("gender", true);
                    handleChange(event);
                  }}
                  onBlur={(event) => {
                    setFieldTouched("gender", true);
                    handleBlur(event);
                  }}
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  id="lastname"
                  label={intl.formatMessage({ id: "registration.personal.lastname" })}
                  type="text"
                  warning={warning}
                  maxLength={100}
                  required
                  touched={touched}
                  errors={errors}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Col>

              <Col xs={12} className="warning-info">
                {warningInfo}
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationPersonalInformationDe.stepName = "registration_personal_information";

RegistrationDialogStepRegistrationPersonalInformationDe.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationPersonalInformationDe;
