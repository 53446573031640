import React, { useState } from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import EmptyContentWall from "../../components/emptyContentWall/emptyContentWall";
import FilterDropdown from "../../components/filterDropown/filterDropdown";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import IntroSection from "../../components/introSection/introSection";
import Map from "../../components/map/map";
import SEO from "../../components/seo";
import TabNavigation from "../../components/tabNavigation/tabNavigation";
import { IconEvent30, IconList18, IconMaps18 } from "../../icons";
import mapKontentItem from "../../utils/mapKontentItem";
import MapMarkerCreator from "../../utils/mapMarkerCreator";
import EventTileCard from "../events/eventTileCard";
import KontentItemIconCardSliderSection from "../kontentItemIconCardSliderSection";
import KontentItemProgramSliderSection from "../kontentItemProgramSliderSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import SimpleRichText from "../text/simpleRichText";

const KontentItemEventsPage = ({ data }) => {
  const {
    page,
  } = data;

  const intl = useIntl();

  const [listView, setListView] = useState(true);

  const markers = MapMarkerCreator(data.events.nodes);

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
        tabNavigation={(
          <TabNavigation
            items={[
              {
                children: <><IconList18 />{intl.formatMessage({ id: "event_tab_list.list_view" })}</>,
                className: listView ? "active" : "",
                id: "1",
                link: null,
                onClick: () => {
                  setListView(true);
                },
                state: {
                  disableScrollUpdate: true,
                },
                title: intl.formatMessage({ id: "event_tab_list.list_view" }),
              },
              {
                children: <><IconMaps18 />{intl.formatMessage({ id: "event_tab_list.map_view" })}</>,
                className: !listView ? "active" : "",
                id: "2",
                link: null,
                onClick: () => {
                  setListView(false);
                },
                state: {
                  disableScrollUpdate: true,
                },
                title: intl.formatMessage({ id: "event_tab_list.map_view" }),
              },
            ]}
          />
        )}
      >
        {page.elements.title.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={page.id} />

      <IntroSection
        headline={page.elements.title.value}
        text={<SimpleRichText data={page.elements.text} />}
      />

      {listView
        ? (
          <FilterDropdown
            variant="events"
            viewPastEventsLink={
              page.elements.button_url_archive.value.length > 0
                ? page.elements.button_url_archive.value[0].id
                : null
            }
            emptyContentWall={(
              <EmptyContentWall
                headline={page.elements.no_events_headline.value}
                icon={<IconEvent30 />}
                message={page.elements.no_events_message.value}
              />
            )}
          >
            {EventTileCard({
              data: data.events.nodes,
              onlyFurtherEvents: true,
              sortAscendingDate: true,
            })}
          </FilterDropdown>
        )
        : (
          <div className="container--fullwidth event-overview-map-container">
            <Map markers={markers} />
          </div>
        )}

      <KontentItemProgramSliderSection data={page.elements.programs_slider} />

      {page.elements.eyecatcher.value.length > 0
        ? mapKontentItem(page.elements.eyecatcher.value[0])
        : null}

      <KontentItemIconCardSliderSection data={page.elements.icon_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    events: allKontentItemEvent {
      nodes {
         ...EventTileCard
      }
    }
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemEventsPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        icon_image {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        title {
          value
        }
        text {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemCtaButton
          }
        }
        programs_slider {
          value {
            ...KontentItemProgramSliderSection
          }
        }
        eyecatcher {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherContact
            ...KontentItemEyecatcher
          }
        }
        button_url_archive {
          value {
            id
          }
        }
        icon_card_slider {
          value {
            ...KontentItemIconCardSliderSection
          }
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        no_events_message {
          value
        }
        no_events_headline {
          value
        }
      }
    }
  }
`;

KontentItemEventsPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEventsPage;
