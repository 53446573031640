import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import RadioButtonList from "../../radioButtonList/radioButtonList";
import RadioButtonSection from "../../radioButtonSection/radioButtonSection";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationKeepInTouch = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const validationSchema = useMemo(() => object().shape({
    keepInTouchEmail: string().required(intl.formatMessage({ id: "form.required" })),
    keepInTouchPost: string().required(intl.formatMessage({ id: "form.required" })),
    keepInTouchTelephone: string().required(intl.formatMessage({ id: "form.required" })),
    keepInTouchTextMessage: string().required(intl.formatMessage({ id: "form.required" })),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.keep_in_touch.title"
        subheadline="registration.keep_in_touch.text"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              keepInTouchEmail: values.keepInTouchEmail,
              keepInTouchPost: values.keepInTouchPost,
              keepInTouchTelephone: values.keepInTouchTelephone,
              keepInTouchTextMessage: values.keepInTouchTextMessage,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_keep_in_touch", { event_value: "success" });

          next();
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <RadioButtonSection notice={intl.formatMessage({ id: "keep_in_touch.notice" })}>
              <RadioButtonList
                values={values}
                inline
                label={intl.formatMessage({ id: "keep_in_touch.email" })}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                name="keepInTouchEmail"
                touched={touched}
                id="keepInTouchEmail"
                options={[
                  {
                    checked: values.keepInTouchEmail === "YES",
                    id: "keep_in_touch_email_yes",
                    label: intl.formatMessage({ id: "keep_in_touch.option.yes" }),
                    value: "YES",
                  },
                  {
                    checked: values.keepInTouchEmail === "NO",
                    id: "keep_in_touch_email_no",
                    label: intl.formatMessage({ id: "keep_in_touch.option.no" }),
                    value: "NO",
                  },
                ]}
                onBlur={handleBlur}
                errors={errors}
                setFieldTouched={setFieldTouched}
              />
              <RadioButtonList
                values={values}
                inline
                label={intl.formatMessage({ id: "keep_in_touch.telephone" })}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                name="keepInTouchTelephone"
                touched={touched}
                id="keepInTouchTelephone"
                options={[
                  {
                    checked: values.keepInTouchTelephone === "YES",
                    id: "keep_in_touch_telephone_yes",
                    label: intl.formatMessage({ id: "keep_in_touch.option.yes" }),
                    value: "YES",
                  },
                  {
                    checked: values.keepInTouchTelephone === "NO",
                    id: "keep_in_touch_telephone_no",
                    label: intl.formatMessage({ id: "keep_in_touch.option.no" }),
                    value: "NO",
                  },
                ]}
                onBlur={handleBlur}
                errors={errors}
                setFieldTouched={setFieldTouched}
              />
              <RadioButtonList
                values={values}
                inline
                label={intl.formatMessage({ id: "keep_in_touch.text_message" })}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                name="keepInTouchTextMessage"
                touched={touched}
                id="keepInTouchTextMessage"
                options={[
                  {
                    checked: values.keepInTouchTextMessage === "YES",
                    id: "keep_in_touch_text_message_yes",
                    label: intl.formatMessage({ id: "keep_in_touch.option.yes" }),
                    value: "YES",
                  },
                  {
                    checked: values.keepInTouchTextMessage === "NO",
                    id: "keep_in_touch_text_message_no",
                    label: intl.formatMessage({ id: "keep_in_touch.option.no" }),
                    value: "NO",
                  },
                ]}
                onBlur={handleBlur}
                errors={errors}
                setFieldTouched={setFieldTouched}
              />
              <RadioButtonList
                values={values}
                inline
                label={intl.formatMessage({ id: "keep_in_touch.post" })}
                onChange={handleChange}
                setFieldValue={setFieldValue}
                name="keepInTouchPost"
                touched={touched}
                id="keepInTouchPost"
                options={[
                  {
                    checked: values.keepInTouchPost === "YES",
                    id: "keep_in_touch_post_yes",
                    label: intl.formatMessage({ id: "keep_in_touch.option.yes" }),
                    value: "YES",
                  },
                  {
                    checked: values.keepInTouchPost === "NO",
                    id: "keep_in_touch_post_no",
                    label: intl.formatMessage({ id: "keep_in_touch.option.no" }),
                    value: "NO",
                  },
                ]}
                onBlur={handleBlur}
                errors={errors}
                setFieldTouched={setFieldTouched}
              />
            </RadioButtonSection>

            <ScrollToFieldError />

            <Form.Group>
              <IconButton type="submit" disabled={isSubmitting}>
                <FormattedMessage id="registration.keep_in_touch.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>
            <div className="footnote">
              {intl.formatMessage({ id: "registration.keep_in_touch.footnote" })}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationKeepInTouch.stepName = "registration_keep_in_touch";

RegistrationDialogStepRegistrationKeepInTouch.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationKeepInTouch;
