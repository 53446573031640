import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import * as Yup from "yup";

import FormField from "../../components/dynamicForm/formField/formField";
import Tooltip from "../../components/localForms/tooltip";

const KontentItemInputTypeEmail = (props) => {
  const {
    data,
    errors,
    touched,
    meta,
    onChange,
    onBlur,
    values,
  } = props;

  const intl = useIntl();

  const { elements } = data;

  const emailSchema = Yup.object().shape({
    [elements.id]: Yup
      .string()
      .email(intl.formatMessage({ id: "form.error.invalid_email" })),
  });

  const validateEmail = async (value) => {
    let error;
    let e;

    try {
      await emailSchema.validate({ [elements.id]: value });
    } catch (err) {
      [e] = err.errors;
      error = e;
    }

    return error;
  };

  return (
    <>
      <Col xs={12} md={6}>
        <Tooltip
          id={elements.id.value}
          tooltipMessage={elements.tooltip_email.value}
        >
          <FormField
            id={elements.id.value}
            type="email"
            errors={errors}
            touched={touched}
            meta={meta}
            label={elements.label_email.value}
            placeholder={elements.placeholder_email.value}
            onChange={onChange}
            onBlur={onBlur}
            validateField={validateEmail}
            values={values}
            required={meta?.requiredFields?.includes(elements.id.value)}
          />
        </Tooltip>
      </Col>
    </>
  );
};

export const query = graphql`
  fragment KontentItemInputTypeEmail on kontent_item_input_type_email {
    id
    elements {
      id {
        value
      }
      label_email {
        value
      }
      required {
        value {
          codename
        }
      }
      tooltip_email {
        value
      }
      placeholder_email {
        value
      }
    }      
  } 
`;

KontentItemInputTypeEmail.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemInputTypeEmail;
