import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationEthnicityDe = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const getOptionByValue = (options, value) => options.find((el) => el.value === value);

  const validationSchema = useMemo(() => object().shape({
    ethnicity: string()
      .required(intl.formatMessage({ id: "form.required" })),
  }), []);

  const {
    ethnicityOptions,
  } = RegistrationDialogStepRegistrationEthnicityDe;

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.ethnicity.title"
        subheadline="registration.ethnicity.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          const option = getOptionByValue(ethnicityOptions, values.ethnicity);

          dispatch(
            setRegistrationData({
              ethnicity: values.ethnicity,
              ethnicityLabel: option.label,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_ethnicity", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <DropdownFormField
              label={intl.formatMessage({ id: "registration.ethnicity.ethnicity" })}
              placeholder={intl.formatMessage({ id: "registration.please_select" })}
              onChange={(selectedOption) => {
                const { target } = selectedOption;

                if (target && target.value) {
                  setFieldValue("ethnicity", target.value);
                  setFieldError("ethnicity", undefined);
                } else {
                  setFieldValue("ethnicity", "");
                }
              }}
              onBlur={() => {
                setFieldTouched("ethnicity", true);
              }}
              touched={touched}
              id="ethnicity"
              errors={errors}
              values={values}
              options={ethnicityOptions}
              required
              maxMenuHeight={300}
            />

            <ScrollToFieldError />

            <Form.Group>
              <IconButton type="submit" disabled={isSubmitting}>
                <FormattedMessage id="registration.ethnicity.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>

            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationEthnicityDe.ethnicityOptions = [
  { label: "Keine Angabe", value: "**" },
  { label: "Deutschland", value: "DE" },
  { label: "Türkei", value: "TR" },
  { label: "Polen", value: "PL" },
  { label: "Russische Föderation", value: "RU" },
  { label: "Afghanistan", value: "AF" },
  { label: "Ägypten", value: "EG" },
  { label: "Ålandinseln", value: "AX" },
  { label: "Albanien", value: "AL" },
  { label: "Algerien", value: "DZ" },
  { label: "Amerikanisch Samoa", value: "AS" },
  { label: "Amerikanische Jungferninseln", value: "VI" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguilla", value: "AI" },
  { label: "Antarktis", value: "AQ" },
  { label: "Antigua", value: "AG" },
  { label: "Äquatorialguinea", value: "GQ" },
  { label: "Argentinien", value: "AR" },
  { label: "Armenien", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Aserbaidschan", value: "AZ" },
  { label: "Äthiopien", value: "ET" },
  { label: "Australien", value: "AU" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrain", value: "BH" },
  { label: "Bangladesch", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Belgien", value: "BE" },
  { label: "Belize", value: "BZ" },
  { label: "Benin", value: "BJ" },
  { label: "Bermudas", value: "BM" },
  { label: "Bhutan", value: "BT" },
  { label: "Bolivien", value: "BO" },
  { label: "Bosnien-Herzegowina", value: "BA" },
  { label: "Botswana", value: "BW" },
  { label: "Bouvetinsel", value: "BV" },
  { label: "Brasilien", value: "BR" },
  { label: "Britisches Territorium im indischen Ozean", value: "IO" },
  { label: "Brunei", value: "BN" },
  { label: "Bulgarien", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Chile", value: "CL" },
  { label: "China", value: "CN" },
  { label: "Cookinseln", value: "CK" },
  { label: "Costa Rica", value: "CR" },
  { label: "Dänemark", value: "DK" },
  { label: "Demokratische Republik Kongo", value: "CD" },
  { label: "Demokratische Volksrepublik Laos", value: "LA" },
  { label: "Dominica", value: "DM" },
  { label: "Dominikanische Republik", value: "DO" },
  { label: "Dschibuti", value: "DJ" },
  { label: "Ecuador", value: "EC" },
  { label: "El Salvador", value: "SV" },
  { label: "Elfenbeinküste", value: "CI" },
  { label: "Eritrea", value: "ER" },
  { label: "Estland", value: "EE" },
  { label: "Färöer", value: "FO" },
  { label: "Fidschi", value: "FJ" },
  { label: "Finnland", value: "FI" },
  { label: "Frankreich", value: "FR" },
  { label: "Französisch Guayana", value: "GF" },
  { label: "Französisch Polynesien", value: "PF" },
  { label: "Französische Süd- und Antarktisgebiete", value: "TF" },
  { label: "Fürstentum Liechtenstein", value: "LI" },
  { label: "Fürstentum Monaco", value: "MC" },
  { label: "Gabun", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgien", value: "GE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Grenada", value: "GD" },
  { label: "Griechenland", value: "GR" },
  { label: "Grönland", value: "GL" },
  { label: "Großbritannien", value: "GB" },
  { label: "Guadeloupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haiti", value: "HT" },
  { label: "Heard- und McDonald-Inseln", value: "HM" },
  { label: "Honduras", value: "HN" },
  { label: "Hong Kong", value: "HK" },
  { label: "Indien", value: "IN" },
  { label: "Indonesien", value: "ID" },
  { label: "Irak", value: "IQ" },
  { label: "Iran", value: "IR" },
  { label: "Irland", value: "IE" },
  { label: "Island", value: "IS" },
  { label: "Isle of Man", value: "IM" },
  { label: "Israel", value: "IL" },
  { label: "Italien", value: "IT" },
  { label: "Jamaika", value: "JM" },
  { label: "Jan Mayen", value: "SJ" },
  { label: "Japan", value: "JP" },
  { label: "Jemen", value: "YE" },
  { label: "Jersey", value: "JE" },
  { label: "Jordanien", value: "JO" },
  { label: "Jungferninseln", value: "VG" },
  { label: "Kaimaninseln", value: "KY" },
  { label: "Kambodscha", value: "KH" },
  { label: "Kamerun", value: "CM" },
  { label: "Kanada", value: "CA" },
  { label: "Kap Verde", value: "CV" },
  { label: "Kasachstan", value: "KZ" },
  { label: "Katar", value: "QA" },
  { label: "Kenia", value: "KE" },
  { label: "Kirgisistan", value: "KG" },
  { label: "Kiribati", value: "KI" },
  { label: "Kokosinseln", value: "CC" },
  { label: "Kolumbien", value: "CO" },
  { label: "Komoren", value: "KM" },
  { label: "Kosovo", value: "XK" },
  { label: "Kroatien", value: "HR" },
  { label: "Kuba", value: "CU" },
  { label: "Kuwait", value: "KW" },
  { label: "Lesotho", value: "LS" },
  { label: "Lettland", value: "LV" },
  { label: "Libanon", value: "LB" },
  { label: "Liberia", value: "LR" },
  { label: "Libyen", value: "LY" },
  { label: "Litauen", value: "LT" },
  { label: "Luxemburg", value: "LU" },
  { label: "Macau", value: "MO" },
  { label: "Madagaskar", value: "MG" },
  { label: "Malawi", value: "MW" },
  { label: "Malaysia", value: "MY" },
  { label: "Malediven", value: "MV" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Malwinen", value: "FK" },
  { label: "Marokko", value: "MA" },
  { label: "Marshall-Inseln", value: "MH" },
  { label: "Martinique", value: "MQ" },
  { label: "Mauretanien", value: "MR" },
  { label: "Mauritius", value: "MU" },
  { label: "Mayotte", value: "YT" },
  { label: "Mazedonien", value: "MK" },
  { label: "Mexiko", value: "MX" },
  { label: "Mikronesien", value: "FM" },
  { label: "Moldawien", value: "MD" },
  { label: "Mongolei", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Mosambik", value: "MZ" },
  { label: "Myanmar", value: "MM" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Neukaledonien", value: "NC" },
  { label: "Neuseeland", value: "NZ" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niederlande", value: "NL" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Nordkorea", value: "KP" },
  { label: "Nördliche Marianen", value: "MP" },
  { label: "Norfolkinseln", value: "NF" },
  { label: "Norwegen", value: "NO" },
  { label: "Oman", value: "OM" },
  { label: "Österreich", value: "AT" },
  { label: "Osttimor", value: "TL" },
  { label: "Pakistan", value: "PK" },
  { label: "Palästina", value: "PS" },
  { label: "Panama", value: "PA" },
  { label: "Papua Neuguinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Peru", value: "PE" },
  { label: "Philippinen", value: "PH" },
  { label: "Pitcairn-Inseln", value: "PN" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Republik Kongo", value: "CG" },
  { label: "Republik Palau", value: "PW" },
  { label: "Réunion", value: "RE" },
  { label: "Ruanda", value: "RW" },
  { label: "Rumänien", value: "RO" },
  { label: "Saint-Pierre und Miquelon", value: "PM" },
  { label: "Salomonen", value: "SB" },
  { label: "Sambia", value: "ZM" },
  { label: "Samoa", value: "WS" },
  { label: "San Marino", value: "SM" },
  { label: "Sankt Bartholomäus", value: "BL" },
  { label: "Sankt Martin", value: "MF" },
  { label: "Santa Lucia", value: "LC" },
  { label: "São Tomé und Príncipe", value: "ST" },
  { label: "Saudi-Arabien", value: "SA" },
  { label: "Schweden", value: "SE" },
  { label: "Schweiz", value: "CH" },
  { label: "Senegal", value: "SN" },
  { label: "Serbien", value: "RS" },
  { label: "Seychellen", value: "SC" },
  { label: "Sierra Leone", value: "SL" },
  { label: "Simbabwe", value: "ZW" },
  { label: "Singapur", value: "SG" },
  { label: "Slowakische Republik", value: "SK" },
  { label: "Slowenien", value: "SI" },
  { label: "Somalia", value: "SO" },
  { label: "Spanien", value: "ES" },
  { label: "Sri Lanka", value: "LK" },
  { label: "St. Helena, Ascension und Tristan da Cunha", value: "SH" },
  { label: "St. Kitts und Nevis", value: "KN" },
  { label: "St. Vincent", value: "VC" },
  { label: "Südafrika", value: "ZA" },
  { label: "Sudan", value: "SD" },
  { label: "Südgeorgien und die südlichen Sandwichinseln", value: "GS" },
  { label: "Südkorea", value: "KR" },
  { label: "Suriname", value: "SR" },
  { label: "Swasiland", value: "SZ" },
  { label: "Syrien", value: "SY" },
  { label: "Tadschikistan", value: "TJ" },
  { label: "Taiwan", value: "TW" },
  { label: "Tansania", value: "TZ" },
  { label: "Thailand", value: "TH" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad und Tobago", value: "TT" },
  { label: "Tschad", value: "TD" },
  { label: "Tschechische Republik", value: "CZ" },
  { label: "Tunesien", value: "TN" },
  { label: "Turkmenistan", value: "TM" },
  { label: "Turks- und Caicos-Inseln", value: "TC" },
  { label: "Tuvalu", value: "TV" },
  { label: "Uganda", value: "UG" },
  { label: "Ukraine", value: "UA" },
  { label: "Ungarn", value: "HU" },
  { label: "United States Minor Outlying Islands", value: "UM" },
  { label: "Uruguay", value: "UY" },
  { label: "USA", value: "US" },
  { label: "Usbekistan", value: "UZ" },
  { label: "Vanuta", value: "VU" },
  { label: "Vatikan", value: "VA" },
  { label: "Venezuela", value: "VE" },
  { label: "Vereinigte Arabische Emirate", value: "AE" },
  { label: "Vietnam", value: "VN" },
  { label: "Wallis und Futuna", value: "WF" },
  { label: "Weihnachtsinseln", value: "CX" },
  { label: "Weissrussland", value: "BY" },
  { label: "West-Sahara", value: "EH" },
  { label: "Zentralafrikanische Republik", value: "CF" },
  { label: "Zypern", value: "CY" },
];

RegistrationDialogStepRegistrationEthnicityDe.stepName = "registration_ethnicity";

RegistrationDialogStepRegistrationEthnicityDe.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationEthnicityDe;
