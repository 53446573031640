import React from "react";

import PropTypes from "prop-types";

import "./contactFaq.scss";

const ContactFaq = (props) => {
  const {
    question,
    description,
    button,
  } = props;

  return (
    <div className="contact-faq">
      <div className="contact-faq__question">{question}</div>
      <div className="contact-faq__description">{description}</div>
      <div className="mt-md-auto">
        {button}
      </div>
    </div>
  );
};

ContactFaq.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]).isRequired,
  description: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
};

export default ContactFaq;
