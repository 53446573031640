import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { IconDownload18 } from "../../icons";
import downloadFile from "../../utils/downloadFile";
import LinkButton from "../button/linkButton";

import "./lightboxDownload.scss";

const LightboxDownload = (props) => {
  const {
    description,
    image,
    fileSize,
    verticalImage,
    downloadLink,
    copyRight,
    assetLicense,
    fileType,
  } = props;

  let colProps = {
    xs: 12,
  };

  if (verticalImage) {
    colProps = {
      md: 6,
      xs: 8,
    };
  }

  let type;

  switch (fileType) {
    case "image/jpeg":
      type = "JPG";
      break;
    default:
      type = fileType.substring(fileType.lastIndexOf("/") + 1).toUpperCase();
  }

  return (
    <div className="lightbox-download">
      <Row className="justify-content-center">
        <Col {...colProps}>
          {image}
        </Col>
      </Row>
      <div className="lightbox-download__container">
        <div className={classNames("d-md-flex",
          description.length > 0 || copyRight.length > 0
            ? "justify-content-md-between"
            : "justify-content-md-end")}
        >
          {(description.length > 0 || copyRight.length > 0)
            && (
              <div className="lightbox-download__description-container">
                {description.length > 0 && (
                  <div className="lightbox-download__description">
                    {description}
                  </div>
                )}
                {copyRight.length > 0 && (
                  <div className="lightbox-download__copyright typo-small-label">
                    {copyRight}
                  </div>
                )}
              </div>
            )}
          <div>
            <LinkButton
              variant="normal"
              onClick={
                () => downloadFile(downloadLink, image.props.data.elements.image.value[0].name)
              }
            >
              <FormattedMessage id="download_image" />
              <IconDownload18 />
            </LinkButton>
            <div className="lightbox-download__file-size typo-small-label">
              {type}, {fileSize}
            </div>
          </div>
        </div>
        {assetLicense.length > 0 && (
          <div className="typo-body2 lightbox-download__asset-license">
            {assetLicense}
          </div>
        )}
      </div>
    </div>
  );
};

LightboxDownload.propTypes = {
  assetLicense: PropTypes.string.isRequired,
  copyRight: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  downloadLink: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  verticalImage: PropTypes.bool,
};

LightboxDownload.defaultProps = {
  verticalImage: false,
};

export default LightboxDownload;
