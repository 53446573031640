import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import CleaveFormField from "../../dynamicForm/cleaveFormField/cleaveFormField";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationAdditionalContactZa = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });
  const emailMessage = intl.formatMessage({ id: "registration.additional.error.email" });
  const phoneMessage = intl.formatMessage({ id: "registration.additional.error.phone" });

  const validationSchema = useMemo(() => object().shape({
    personOneEmailAddress: string()
      .nullable()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage),
    personOneFirstname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 40,
        },
      ))
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    personOneLastname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    personOneMobileNumber: string()
      .nullable()
      .matches(
        /^(\d{3}-\d{3}-\d{4})$/,
        phoneMessage,
      ),
    personOneRelationShip: string().required(requiredMessage),
    personTwoEmailAddress: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage),
    personTwoFirstname: string()
      .min(2, requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 40,
        },
      ))
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage)
      .when(["personTwoLastname", "personTwoRelationShip"], {
        is: (personTwoLastname, personTwoRelationShip) => (
          personTwoLastname || personTwoRelationShip
        ),
        then: string()
          .required(requiredMessage),
      }),
    personTwoLastname: string()
      .min(2, requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage)
      .when(["personTwoFirstname", "personTwoRelationShip"], {
        is: (personTwoFirstname, personTwoRelationShip) => (
          personTwoFirstname || personTwoRelationShip
        ),
        then: string()
          .required(requiredMessage),
      }),
    personTwoMobileNumber: string()
      .matches(
        /^(\d{3}-\d{3}-\d{4})$/,
        phoneMessage,
      ),
    personTwoRelationShip: string().when(["personTwoFirstname", "personTwoLastname"], {
      is: (personTwoFirstname, personTwoLastname) => (personTwoFirstname || personTwoLastname),
      then: string()
        .required(requiredMessage),
    }),
  }, [
    ["personTwoFirstname", "personTwoLastname"],
    ["personTwoFirstname", "personTwoRelationShip"],
    ["personTwoLastname", "personTwoRelationShip"],
  ]), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.contact.title"
        subheadline="registration.contact.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          if (
            registrationData.firstname.toLowerCase() === values.personOneFirstname.toLowerCase()
            && registrationData.lastname.toLowerCase() === values.personOneLastname.toLowerCase()
          ) {
            errors.personOneLastname = intl.formatMessage({ id: "registration.contact.error.same_name" });
          }

          if (
            (
              registrationData.firstname.toLowerCase() === values.personTwoFirstname.toLowerCase()
              && registrationData.lastname.toLowerCase()
              === values.personTwoLastname.toLowerCase()
            )
            || (
              values.personTwoFirstname.toLowerCase() === values.personOneFirstname.toLowerCase()
              && values.personTwoLastname.toLowerCase() === values.personOneLastname.toLowerCase()
            )
          ) {
            errors.personTwoLastname = intl.formatMessage({ id: "registration.contact.error.same_name" });
          }

          if ((registrationData.mobileNumber
              && registrationData.mobileNumber === values.personOneMobileNumber)
            || (registrationData.landlineNumber
              && registrationData.landlineNumber === values.personOneMobileNumber)) {
            errors.personOneMobileNumber = intl.formatMessage({ id: "registration.contact.error.same_number" });
          }

          if ((registrationData.mobileNumber
              && registrationData.mobileNumber === values.personTwoMobileNumber)
            || (registrationData.landlineNumber
              && registrationData.landlineNumber === values.personTwoMobileNumber)) {
            errors.personTwoMobileNumber = intl.formatMessage({ id: "registration.contact.error.same_number" });
          }

          if (values.personTwoMobileNumber && values.personOneMobileNumber
            && values.personTwoMobileNumber === values.personOneMobileNumber) {
            errors.personTwoMobileNumber = intl.formatMessage({ id: "registration.contact.error.same_number" });
            errors.personOneMobileNumber = intl.formatMessage({ id: "registration.contact.error.same_number" });
          }

          if (registrationData.emailAddress.toLowerCase()
            === values.personOneEmailAddress.toLowerCase()) {
            errors.personOneEmailAddress = intl.formatMessage({ id: "form.error.same_email" });
          }

          if (values.personTwoEmailAddress && registrationData.emailAddress.toLowerCase()
            === values.personTwoEmailAddress.toLowerCase()) {
            errors.personTwoEmailAddress = intl.formatMessage({ id: "form.error.same_email" });
          }

          if (values.personOneEmailAddress.toLowerCase()
            === values.personTwoEmailAddress.toLowerCase()
            && values.personOneEmailAddress.trim() !== "") {
            errors.personTwoEmailAddress = intl.formatMessage({ id: "form.error.same_contact_email" });
            errors.personOneEmailAddress = intl.formatMessage({ id: "form.error.same_contact_email" });
          }

          if (values.personTwoFirstname
            && !values.personTwoEmailAddress
            && !values.personTwoMobileNumber) {
            errors.personTwoEmailAddress = intl.formatMessage({ id: "registration.contact.error.one_contact_option_required" });
            errors.personTwoMobileNumber = intl.formatMessage({ id: "registration.contact.error.one_contact_option_required" });
          }

          if (!values.personOneEmailAddress && !values.personOneMobileNumber) {
            errors.personOneEmailAddress = intl.formatMessage({ id: "registration.contact.error.one_contact_option_required" });
            errors.personOneMobileNumber = intl.formatMessage({ id: "registration.contact.error.one_contact_option_required" });
          }

          return errors;
        }}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          DataLayer.pushEvent("pp_registration_step_additional_contact", { event_value: "success" });

          dispatch(
            setRegistrationData({
              personOneEmailAddress: values.personOneEmailAddress,
              personOneFirstname: values.personOneFirstname,
              personOneLastname: values.personOneLastname,
              personOneMobileNumber: values.personOneMobileNumber,
              personOneRelationShip: values.personOneRelationShip,

              personTwoEmailAddress: values.personTwoEmailAddress,
              personTwoFirstname: values.personTwoFirstname,
              personTwoLastname: values.personTwoLastname,
              personTwoMobileNumber: values.personTwoMobileNumber,
              personTwoRelationShip: values.personTwoRelationShip,
            }),
          );

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} className="registration-dialog__subheader">
                <FormattedMessage id="registration.contact.first" />
              </Col>
              <Col lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="personOneFirstname"
                  label={intl.formatMessage({ id: "registration.personal.firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="personOneLastname"
                  label={intl.formatMessage({ id: "registration.personal.lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12} lg={6}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.contact.relationship" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("personOneRelationShip", target.value);
                      setFieldError("personOneRelationShip", undefined);
                    } else {
                      setFieldValue("personOneRelationShip", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("personOneRelationShip", true);
                  }}
                  touched={touched}
                  id="personOneRelationShip"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.relationshipOptions}
                  required
                />
              </Col>

              <Col lg={6}>
                <CleaveFormField
                  label={intl.formatMessage({ id: "registration.additional.mobileNumber" })}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  id="personOneMobileNumber"
                  errors={errors}
                  options={{
                    blocks: [3, 3, 4],
                    delimiter: "-",
                    numericOnly: true,
                  }}
                  maxLength={12}
                  required
                  additionalInvalidCondition={
                    errors.personOneMobileNumber
                      && (touched.personOneMobileNumber || touched.personOneEmailAddress)
                  }
                />
              </Col>

              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="personOneEmailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  additionalInvalidCondition={(errors.personOneEmailAddress
                    && (touched.personOneEmailAddress || touched.personOneMobileNumber))}
                  required
                />
              </Col>

              <Col xs={12} className="registration-dialog__subheader">
                <FormattedMessage id="registration.contact.second" />
              </Col>
              <Col lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="personTwoFirstname"
                  label={intl.formatMessage({ id: "registration.personal.firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="personTwoLastname"
                  label={intl.formatMessage({ id: "registration.personal.lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>

              <Col xs={12} lg={6}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.contact.relationship" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("personTwoRelationShip", target.value);
                      setFieldError("personTwoRelationShip", undefined);
                    } else {
                      setFieldValue("personTwoRelationShip", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("personTwoRelationShip", true);
                  }}
                  touched={touched}
                  id="personTwoRelationShip"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.relationshipOptions}
                />
              </Col>

              <Col lg={6}>
                <CleaveFormField
                  label={intl.formatMessage({ id: "registration.additional.mobileNumber" })}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  id="personTwoMobileNumber"
                  errors={errors}
                  options={{
                    blocks: [3, 3, 4],
                    delimiter: "-",
                    numericOnly: true,
                  }}
                  maxLength={12}
                  additionalInvalidCondition={
                    errors.personTwoMobileNumber
                    && (touched.personTwoMobileNumber || touched.personTwoEmailAddress)
                  }
                />
              </Col>

              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="personTwoEmailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  additionalInvalidCondition={(errors.personTwoEmailAddress
                    && (touched.personTwoEmailAddress || touched.personTwoMobileNumber))}
                />
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationAdditionalContactZa.stepName = "registration_additional_contact";

RegistrationDialogStepRegistrationAdditionalContactZa.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationAdditionalContactZa;
