import React, { useState } from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { setError, setRegistrationData } from "../../../features/registrationSlice";
import DataLayer from "../../../utils/dataLayer";
import Button from "../../button/button";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepEligibilityRegisteredUs = ({ content, next }) => {
  const error = useSelector((state) => state.registration.error);
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = (eligible) => {
    setIsSubmitting(true);

    dispatch(
      setRegistrationData({ eligible }),
    );

    if (!eligible) {
      setIsSubmitting(false);
      dispatch(
        setError(true),
      );

      DataLayer.pushEvent("pp_registration_step_eligibility_registered", { event_value: "fail" });
    } else {
      DataLayer.pushEvent("pp_registration_step_eligibility_registered", { event_value: "success" });

      next();
    }
  };

  if (error) {
    return (
      <>
        <RegistrationDialogHeadline
          headline="registration.registered.error.title"
          subheadline={intl.formatMessage({ id: "registration.registered.error.text1" })}
        />
        <RegistrationDialogHeadline
          headline="registration.registered.error.title2"
          subheadline={intl.formatMessage({ id: "registration.registered.error.text2" })}
        />
        {content.errorButtonSecondary}
      </>
    );
  }

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.registered.title"
      />
      <Row>
        <Col xs={12}>
          <Form.Group className="form-group-button">
            <Button
              onClick={() => {
                submit(false);
              }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="registration.registered.button_yes" />
            </Button>
          </Form.Group>
          <Form.Group className="form-group-button">
            <Button
              onClick={() => {
                submit(true);
              }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="registration.registered.button_no" />
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

RegistrationDialogStepEligibilityRegisteredUs.stepName = "eligibility_registered";

RegistrationDialogStepEligibilityRegisteredUs.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepEligibilityRegisteredUs;
