import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ElementHeader from "../../components/elementHeader/elementHeader";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import SEO from "../../components/seo";
import KontentItemDownloadSection from "../kontentItemDownloadSection";
import KontentItemImageLibrarySection from "../kontentItemImageLibrarySection";
import KontentItemVideoLibrarySection from "../kontentItemVideoLibrarySection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";
import TabNavigationGenerator from "../virtualDrive/tabNavigationGenerator";

const KontentItemVdResourceLibrary = ({ data }) => {
  const { page } = data;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
        tabNavigation={<TabNavigationGenerator id={page.id} />}
      >
        {page.elements.hero_headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={page.id} />

      <ElementHeader
        headline={page.elements.headline.value}
        description={page.elements.text.value}
      />

      <div className="text virtual-drive">
        <RichText data={page.elements.body} />
      </div>
      <KontentItemImageLibrarySection data={page.elements.image_library} />

      <KontentItemVideoLibrarySection data={page.elements.video_library} />

      <KontentItemDownloadSection data={page.elements.downloads} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemVdResourceLibrary(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        hero_headline {
          value
        }
        headline {
          value
        }
        text {
          value
        }
        body {
          value
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemCtaButton
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemEmbedExternal
          }
        }
        image_library {
          value {
            ...KontentItemImageLibrarySection
          }
        }
        video_library {
          value {
            ...KontentItemVideoLibrarySection
          }
        }
        downloads {
          value {
            ...KontentItemDownloadSection
          }
        }
      }
    }
  }
`;

KontentItemVdResourceLibrary.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemVdResourceLibrary;
