import React, { memo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import Button from "./button";

import "./iconButton.scss";

const IconButton = (props) => {
  const {
    children,
    className,
    iconOnly,
    ...buttonProps
  } = props;

  return (
    <Button
      {...buttonProps}
      typeOverwrite={buttonProps.type}
      className={classNames([
        "icon-btn",
        { "icon-only-btn": iconOnly && !Array.isArray(children) },
        className,
      ])}
    >
      {React.Children.map(children, (item) => {
        if (item === " " || !item) {
          return null;
        }

        if (typeof item !== "object" || (typeof item === "object" && item.type?.displayName === "MemoizedFormattedMessage")) {
          return (<span>{item}</span>);
        }

        return item;
      })}
    </Button>
  );
};

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  iconOnly: PropTypes.bool,
};

IconButton.defaultProps = {
  className: null,
  iconOnly: false,
};

export default memo(IconButton);
