import React from "react";

import PropTypes from "prop-types";

import "./iconProcessStep.scss";

const IconProcessStep = (props) => {
  const {
    icon,
    label,
    text,
  } = props;

  return (
    <div className="icon-process-step">
      <div className="icon-process-step__icon">
        {icon}
      </div>
      <div className="icon-process-step__label">
        {label}
      </div>
      <div className="icon-process-step__text">
        {text}
      </div>
    </div>
  );
};

IconProcessStep.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default IconProcessStep;
