import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import statementBoxes from "./statementBoxes";
import CardSlider from "../../components/cardSlider/cardSlider";

const KontentItemStatementboxGroup = ({ data }) => {
  if (!data || !data.elements) {
    return null;
  }

  return (
    <CardSlider
      header={data.elements.headline.value}
      statementBox
      showSlideButtons
      equalCardHeights
    >
      {statementBoxes({ data: data.elements.statementboxes })}
    </CardSlider>
  );
};

export const query = graphql`
  fragment KontentItemStatementboxGroup on kontent_item_statementbox_group {
    elements {
      headline {
        value
      }
      statementboxes {
        value {
          ...StatementBoxes
        }
      }
    }
  }
`;

KontentItemStatementboxGroup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemStatementboxGroup;
