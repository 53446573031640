import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import IconButton from "../../components/button/iconButton";
import HeroCarouselCard from "../../components/heroCarousel/heroCarouselCard";
import { IconFurther18 } from "../../icons";
import backgroundImageHelper from "../image/backgroundImageHelper";

const PressHeroCarouselCards = ({ data, buttonText }) => {
  const pressData = Array.isArray(data) ? data : data.value;

  const articles = [];

  pressData.forEach((pressArticle) => {
    articles.push(
      <HeroCarouselCard
        backgroundImageStyle={backgroundImageHelper(pressArticle.elements.image)}
        button={(
          <IconButton variant="inverted-light" href={pressArticle.id}>
            {buttonText}<IconFurther18 />
          </IconButton>
        )}
        menuTitle={pressArticle.elements.headline.value}
        date={new Date(pressArticle.elements.publishdate.value)}
        isPress
      >
        {pressArticle.elements.headline.value}
      </HeroCarouselCard>,
    );
  });

  return React.Children.toArray(articles);
};

export const query = graphql`
  fragment PressHeroCarouselCards on kontent_item_article_press {
    id
    system {
      codename
    }
    elements {
        headline {
            value
        }
        image {
          value {
            ...KontentItemImage
          }
        }
        publishdate {
          value
        }
    }
  }
`;

PressHeroCarouselCards.propTypes = {
  buttonText: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default PressHeroCarouselCards;
