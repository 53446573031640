import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemVideoEmbed from "./kontentItemVideoEmbed";

const VideoEmbeds = ({ data }) => {
  const videoData = Array.isArray(data) ? data : data.value;

  const videoList = [];

  videoData.forEach((video) => {
    videoList.push(
      <KontentItemVideoEmbed data={video} variant="16by9" previewImageSizeByParent />,
    );
  });

  return React.Children.toArray(videoList);
};

export const query = graphql`
  fragment VideoEmbed on kontent_item_video_embed {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      url {
        value
      }
      duration {
        value
      }
      image {
        value {
          description
          name
          height
          size
          url
          type
          width
        }
      }
      video_component_style {
        value {
          name
          codename
        }
      }
      press_category {
        value {
          name
        }
      }
    }
  } 
`;

VideoEmbeds.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default VideoEmbeds;
