import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

import { IconClearInput18, IconDropdownExpand18 } from "../../icons";

import "./inputSelect.scss";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <IconDropdownExpand18 />
  </components.DropdownIndicator>
);

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <IconClearInput18 />
  </components.ClearIndicator>
);

const InputSelect = (props) => {
  const {
    placeholder,
    disabled,
    clearable,
    isInvalid,
    items,
    onChange,
    onBlur,
    value,
    inputId,
    maxMenuHeight,
    name,
    isSearchable,
    defaultValue,
  } = props;

  const [tmpValue, setTmpValue] = useState(value);

  return (
    <Select
      defaultValue={defaultValue}
      name={name}
      placeholder={placeholder}
      options={items}
      className={classNames(
        "select-container",
        {
          "is-invalid": isInvalid,
          "select-container--invalid": isInvalid,
          "select-container--placeholder-shown": typeof tmpValue === "undefined",
        },
      )}
      inputId={inputId}
      classNamePrefix="select"
      maxMenuHeight={maxMenuHeight}
      isDisabled={disabled}
      isClearable={clearable}
      blurInputOnSelect={false}
      onChange={(event) => {
        setTmpValue(event ? event.value : undefined);
        onChange(event);
      }}
      onBlur={(event) => {
        onBlur(event);
      }}
      value={value ?? ""}
      components={{ ClearIndicator, DropdownIndicator }}
      menuPosition="absolute"
      menuPlacement="auto"
      isSearchable={isSearchable || items.length > 10}
      captureMenuScroll={false}
    />
  );
};

InputSelect.propTypes = {
  clearable: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object]),
  disabled: PropTypes.bool,
  inputId: PropTypes.string,
  isInvalid: PropTypes.bool,
  isSearchable: PropTypes.bool,
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
  maxMenuHeight: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};

InputSelect.defaultProps = {
  clearable: true,
  defaultValue: null,
  disabled: false,
  inputId: "",
  isInvalid: false,
  isSearchable: false,
  maxMenuHeight: 180,
  onBlur: () => {},
  onChange: () => {},
  value: undefined,
};

export default InputSelect;
