import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemCtaButton from "./kontentItemCtaButton";
import LinkButton from "../components/button/linkButton";
import EyecatcherSidebar from "../components/eyecatcherSidebar/eyecatcherSidebar";
import { IconFurther18 } from "../icons";
import DataLayer from "../utils/dataLayer";
import getInternalLinkById from "../utils/getInternalLinkById";

const KontentItemEyecatcherSidebar = ({ data, apiRole, taskKey }) => {
  const eyecatcherSidebar = data.value ? data.value[0] : data;

  if (!eyecatcherSidebar || !eyecatcherSidebar.elements) {
    return null;
  }

  let eventValue;

  try {
    eventValue = getInternalLinkById(eyecatcherSidebar.elements.button_url.value[0].id).slug;
  } catch (error) {
    // ignore only piwik
  }

  let button;

  if (eyecatcherSidebar.elements.button_url.value.length > 0) {
    button = (
      <LinkButton
        onClick={() => {
          DataLayer.pushEvent("pp_ssp_sidebar_click", {
            dimension_ssp_role: apiRole,
            dimension_ssp_status: taskKey,
            event_value: eventValue,
          });
        }}
        href={eyecatcherSidebar.elements.button_url.value[0].id}
      >
        {eyecatcherSidebar.elements.button_text.value} <IconFurther18 />
      </LinkButton>
    );
  }

  if (eyecatcherSidebar.elements.cta_button.value.length > 0) {
    button = (
      <KontentItemCtaButton data={eyecatcherSidebar.elements.cta_button.value[0]} />
    );
  }

  return (
    <EyecatcherSidebar
      title={eyecatcherSidebar.elements.headline.value}
      text={eyecatcherSidebar.elements.text.value}
      button={button}
    />
  );
};

export const query = graphql`
  fragment KontentItemEyecatcherSidebar on kontent_item_eyecatcher_sidebar {
    id
    system {
      codename
    }
    elements {
      button_text {
        value
      }
      button_url {
        value {
          id
        }
      }
      headline {
        value
      }
      text {
        value
      }
      cta_button {
        value {
          ...KontentItemCtaButton
        }
      }
    }
  }
`;

KontentItemEyecatcherSidebar.propTypes = {
  apiRole: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  taskKey: PropTypes.string.isRequired,
};

export default KontentItemEyecatcherSidebar;
