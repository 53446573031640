import React, { useMemo, useState } from "react";

import classNames from "classnames";
import isValid from "date-fns/isValid";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { mixed, object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconError18, IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import { dateFactory, format } from "../../../utils/dateHelper";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DateInput from "../../dateInput/dateInput";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import NavigationLink from "../../navigationLink/navigationLink";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationPersonalInformationZa = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [gender, setGender] = useState(false);

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });

  const validationSchema = useMemo(() => object().shape({
    estimatedTime: string(),
    firstname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 40,
        },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    gender: mixed()
      .required(requiredMessage)
      .oneOf(["M", "F"], requiredMessage),
    lastname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    passportNo: string()
      .required(requiredMessage)
      .matches(
        /^[0-9]{13}$/, {
          message: (
            <>
              {intl.formatMessage(
                { id: "registration.error.passport_no" },
                {
                  link: (
                    <NavigationLink href="mailto:donors@dkms-africa.org">
                      donors@dkms-africa.org
                    </NavigationLink>
                  ),
                },
              )}
            </>
          ),
        },
      ),
    salutation: string()
      .required(requiredMessage)
      .max(25, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 25,
        },
      )),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.personal.title"
        subheadline="registration.personal.subheadline"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              estimatedTime: values.estimatedTime,
              firstname: values.firstname,
              gender: values.gender,
              lastname: values.lastname,
              passportNo: values.passportNo,
              pregnant: values.pregnant,
              salutation: values.salutation,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_personal_information", { event_value: "success" });
          DataLayer.pushEvent("pp_registration_step_personal_information_value",
            {
              dimension_reg_sex: values.gender,
              event_value: values.gender,
            });

          next();
        }}
        validate={(values) => {
          const errors = {};

          if (values.passportNo.length === 13) {
            let sum = 0;
            let even = false;

            values.passportNo.split("").reverse().forEach((dstr) => {
              const d = parseInt(dstr, 10);

              if (!even) {
                sum += d;
              } else if (d < 5) {
                sum += d * 2;
              } else {
                sum += (d - 5) * 2 + 1;
              }

              even = !even;
            });

            if (sum % 10 !== 0) {
              errors.passportNo = intl.formatMessage(
                { id: "registration.error.passport_no" },
                {
                  link: (
                    <NavigationLink href="mailto:donors@dkms-africa.org">
                      donors@dkms-africa.org
                    </NavigationLink>
                  ),
                },

              );
            }

            const encodedGender = values.passportNo[6] >= 5 ? "M" : "F";

            if (values.passportNo.slice(0, 6)
              !== registrationData.birthdate.substring(2).replace(/-/g, "")) {
              errors.passportNo = intl.formatMessage({ id: "registration.error.not_match_birthdate" });
            }

            if (encodedGender !== values.gender) {
              errors.passportNo = intl.formatMessage({ id: "registration.error.not_match_gender" });
            }
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} lg={6}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.personal.salutation" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("salutation", target.value);
                      setFieldError("salutation", undefined);

                      if (typeof RegistrationDataHelper.titleGenderMapping[target.value] !== "undefined") {
                        setGender(false);
                        setFieldValue("gender", RegistrationDataHelper.titleGenderMapping[target.value]);
                        // Fix to validate gender after change
                        setTimeout(() => setFieldTouched("salutation", true));
                      } else {
                        setGender(true);
                      }
                    } else {
                      setFieldValue("salutation", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("salutation", true);
                  }}
                  touched={touched}
                  id="salutation"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.titleOptions}
                  required
                />
              </Col>

              {gender && (
                <Col xs={12} lg={6}>
                  <div className="required-sign typo-label">
                    <FormattedMessage id="registration.personal.gender.title" />
                  </div>
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    value="M"
                    id="gender-male"
                    checked={values.gender === "M"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    value="F"
                    id="gender-female"
                    checked={values.gender === "F"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="firstname"
                  label={intl.formatMessage({ id: "registration.personal.firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="lastname"
                  label={intl.formatMessage({ id: "registration.personal.lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="passportNo"
                  label={intl.formatMessage({ id: "registration.personal.passport_no" })}
                  type="text"
                  maxLength={13}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              {values.gender === "F" && (
                <Col xs={12}>
                  <Form.Group>
                    <Form.Check
                      type="checkbox"
                      id="pregnant"
                      name="pregnant"
                      checked={values.pregnant}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={(errors.pregnant && touched.pregnant)}
                      label={intl.formatMessage({ id: "registration.personal.pregnant" })}
                    />
                    {errors.pregnant && touched.pregnant && (
                      <Form.Text className="invalid-feedback">
                        <IconError18 />{errors.pregnant}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              )}
              {values.pregnant && (
                <Col xs={12}>
                  <Form.Group onBlur={() => setFieldTouched("estimatedTime")}>
                    <DateInput
                      initialValue={dateFactory(values.estimatedTime)}
                      onChange={(value) => {
                        setFieldTouched("estimatedTime");

                        if (typeof value === "object" && isValid(value)) {
                          setFieldValue("estimatedTime", format(value, "yyyy-MM-dd"));
                        } else {
                          setFieldValue("estimatedTime", "");
                        }
                      }}
                      placeholder={intl.formatMessage({ id: "registration.personal.estimatedTime_placeholder" })}
                      isInvalid={(errors.estimatedTime && touched.estimatedTime)}
                    />
                    <Form.Label>
                      <FormattedMessage id="registration.personal.estimatedTime_placeholder" />
                    </Form.Label>
                    <Form.Text className={classNames({ "invalid-feedback": errors.estimatedTime && touched.estimatedTime })}>
                      {errors.estimatedTime && touched.estimatedTime && (
                        <> ,<IconError18 />{errors.estimatedTime}</>
                      )}
                    </Form.Text>
                  </Form.Group>
                </Col>
              )}

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationPersonalInformationZa.stepName = "registration_personal_information";

RegistrationDialogStepRegistrationPersonalInformationZa.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationPersonalInformationZa;
