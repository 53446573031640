import React, { useState } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { IconAccordionExpand18, IconAccordionMinimize18 } from "../../icons";
import BackgroundImage from "../backgroundImage/backgroundImage";

import "./keyFactCard.scss";

const KeyFactCard = (props) => {
  const {
    title,
    text,
    className,
    backgroundImageStyle,
    image,
    button,
  } = props;

  const [icon, setIcon] = useState(false);

  const handleSelect = (iconState) => {
    setIcon(iconState);
  };

  const { width } = useWindowDimensions();

  return (
    <BackgroundImage
      className={classNames("keyfactcard", className)}
      backgroundImageStyle={backgroundImageStyle || {}}
    >
      <div className="keyfactcard__inner">
        {image && (
          <div className="keyfactcard__image">
            {image}
          </div>
        )}
        <div>
          <div className={backgroundImageStyle ? "typo-title1" : "typo-title3"}>{title}</div>
          {button}
          {text && (width < 992
            ? (
              <Accordion className="d-lg-none">
                <Accordion.Collapse eventKey="0">
                  <div className="keyfactcard__text">{text}</div>
                </Accordion.Collapse>
                <Accordion.Toggle as="div" eventKey="0" onClick={() => handleSelect(!icon)}>
                  <div className="typo-label">
                    {
                      icon
                        ? <><FormattedMessage id="keyfactcard.readless" /><IconAccordionMinimize18 /></>
                        : <><FormattedMessage id="keyfactcard.readmore" /><IconAccordionExpand18 /></>
                    }
                  </div>
                </Accordion.Toggle>
              </Accordion>
            )
            : <div className="keyfactcard__text">{text}</div>)}
        </div>
      </div>
    </BackgroundImage>
  );
};

KeyFactCard.propTypes = {
  backgroundImageStyle: PropTypes.oneOfType([PropTypes.object]),
  button: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

KeyFactCard.defaultProps = {
  backgroundImageStyle: null,
  button: null,
  className: "",
  image: null,
  text: null,
};

export default KeyFactCard;
