import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useMediaQuery from "../../hooks/haiku/useMediaQuery";

import "./infoEmbed.scss";

const InfoEmbedSection = ({ children }) => {
  const isHigherEqualMd = useMediaQuery("(min-width: 768px)");

  return (
    <Row className="info-embed-section">
      {React.Children.map(children, (child, index) => {
        if (index === children.length - 1) {
          return (
            <Col md={12} lg={4}>
              {child}
            </Col>
          );
        }

        if (isHigherEqualMd) {
          return (
            <Col md={6} lg={4}>
              {child}
            </Col>
          );
        }

        return null;
      })}
    </Row>
  );
};

InfoEmbedSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InfoEmbedSection;
