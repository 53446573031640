import React from "react";

import axios from "axios";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo";
import StatusCode from "../../components/statusCode";
import KontentItemIconCardSliderSection from "../kontentItemIconCardSliderSection";
import MainLayout from "../layout/mainLayout";

const KontentItemErrorPage = ({ data }) => {
  const { errorPage } = data;

  // preview redirect fix
  if (process.env.GATSBY_IS_PREVIEW
    && process.env.GATSBY_IS_PREVIEW.toLowerCase() === "true"
    && typeof window !== "undefined"
  ) {
    let interval;

    try {
      const {
        host,
        pathname,
        protocol,
      } = window.top.location;

      const callback = () => {
        axios.get(`${protocol}//${host}/_redirects.json`)
          .then(({ data: redirectData }) => {
            const redirect = redirectData.redirects.find(
              (element) => element.fromPath === pathname,
            );

            if (typeof redirect !== "undefined") {
              window.top.location.href = `${protocol}//${host}${redirect.toPath}`;
            }
          });
      };

      callback();

      interval = setInterval(callback, 5000);
    } catch (e) {
      clearInterval(interval);
    }
  }

  return (
    <MainLayout>
      <SEO title="404: Not found" />

      <Breadcrumb items={[{ title: "404" }]} />

      <StatusCode
        number={404}
        text={errorPage.elements.headline.value}
        description={errorPage.elements.text.value}
      />

      <KontentItemIconCardSliderSection
        data={errorPage.elements.icon_card_slider}
      />
    </MainLayout>
  );
};

export const query = graphql`
  {
    errorPage: kontentItemErrorPage(elements: {status_code: {value: {eq: 404}}}) {
      id
      system {
        codename
      }
      elements {
        status_code {
          value
        }
        headline {
          value
        }
        text {
          value
        }
        icon_card_slider {
          value {
            ...KontentItemIconCardSliderSection
          }
        }
      }
    }
  }
`;

KontentItemErrorPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemErrorPage;
