import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  IconFacebookOriginalColored24,
  IconFacebookWhite24,
  IconLink24,
  IconLinkedInOriginalColored24,
  IconLinkedInWhite24,
  IconThreads24,
  IconThreadsWhite24,
  IconTwitter24,
  IconTwitterWhite24,
  ShareButtonFacebook,
  ShareButtonLink,
  ShareButtonLinkedIn,
  ShareButtonThreads,
  ShareButtonTwitter,
} from "../../icons";
import copyToClipboard from "../../utils/clipboardHelper";

import "./socialMediaShare.scss";

const SocialMediaShare = (props) => {
  const {
    facebook,
    twitter,
    shareUrl,
    shareTitle,
    onlyIcon,
    copyUrl,
    linkedIn,
    shareButtons,
    followLinks,
    white,
    className,
  } = props;

  const intl = useIntl();

  if (followLinks) {
    return (
      <div className="social-media-share">
        {!onlyIcon && <FormattedMessage id="socialmedia.follow" />}
        {followLinks.url_facebook.value && (
          <a
            href={followLinks.url_facebook.value}
            target="_blank"
            rel="noopener noreferrer"
            className="no-style"
            aria-label={intl.formatMessage({ id: "social_media_share.share_on_facebook" })}
          >
            <IconFacebookOriginalColored24 />
          </a>
        )}
        {followLinks.url_twitter.value && (
          <a
            href={followLinks.url_twitter.value}
            target="_blank"
            rel="noopener noreferrer"
            className="no-style"
            aria-label={intl.formatMessage({ id: "social_media_share.share_on_twitter" })}
          >
            <IconTwitter24 />
          </a>
        )}
      </div>
    );
  }

  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (shareUrl !== null) {
      setUrl(shareUrl);
    } else if (typeof window !== "undefined") {
      setUrl(window.location.href);
    }
  }, [shareUrl]);

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = (param) => (
    <Tooltip {...param}>
      {tooltipOpen
        ? <FormattedMessage id="socialmedia.link_copied" />
        : <FormattedMessage id="socialmedia.copy_link" />}
    </Tooltip>
  );

  return (
    <div className={classNames("social-media-share", { "flex-wrap": shareButtons }, className)}>
      {!onlyIcon && <FormattedMessage id="socialmedia.share" />}
      {facebook && (
        <a
          href={process.env.GATSBY_FACEBOOK_URL && process.env.GATSBY_FACEBOOK_URL.replace("{url}", url)}
          target="_blank"
          rel="noopener noreferrer"
          className="no-style"
          aria-label={intl.formatMessage({ id: "social_media_share.share_on_facebook" })}
        >
          {shareButtons
            ? <ShareButtonFacebook />
            : (white && <IconFacebookWhite24 />) || <IconFacebookOriginalColored24 />}
        </a>
      )}
      {twitter && (
        <a
          href={process.env.GATSBY_TWITTER_URL && process.env.GATSBY_TWITTER_URL.replace("{url}", url).replace("{title}", shareTitle)}
          target="_blank"
          rel="noopener noreferrer"
          className="no-style"
          aria-label={intl.formatMessage({ id: "social_media_share.share_on_facebook" })}
        >
          {shareButtons
            ? <ShareButtonTwitter />
            : (white && <IconTwitterWhite24 />) || <IconTwitter24 />}
        </a>
      )}

      {linkedIn && (
        <a
          href={process.env.GATSBY_LINKEDIN_URL && process.env.GATSBY_LINKEDIN_URL.replace("{url}", url)}
          target="_blank"
          rel="noopener noreferrer"
          className="no-style"
          aria-label={intl.formatMessage({ id: "social_media_share.share_on_linkedin" })}
        >
          {shareButtons
            ? <ShareButtonLinkedIn />
            : (white && <IconLinkedInWhite24 />) || <IconLinkedInOriginalColored24 />}
        </a>
      )}
      <a
        href={process.env.GATSBY_THREADS_URL && process.env.GATSBY_THREADS_URL.replace("{url}", url)}
        target="_blank"
        rel="noopener noreferrer"
        className="no-style"
        aria-label={intl.formatMessage({ id: "social_media_share.share_on_threads" })}
      >
        {shareButtons
          ? <ShareButtonThreads />
          : (white && <IconThreadsWhite24 />) || <IconThreads24 />}
      </a>
      {copyUrl && !tooltipOpen && (
        <OverlayTrigger
          placement="top"
          delay={{ hide: 400, show: 100 }}
          overlay={toggle}
        >
          {shareButtons ? (
            <ShareButtonLink onClick={() => {
              copyToClipboard(url, () => setTooltipOpen(!tooltipOpen));
            }}
            />
          ) : (
            <IconLink24 onClick={() => {
              copyToClipboard(url, () => setTooltipOpen(!tooltipOpen));
            }}
            />
          )}
        </OverlayTrigger>
      )}
      {copyUrl && tooltipOpen && (
        <OverlayTrigger
          placement="top"
          delay={{ hide: 400, show: 100 }}
          overlay={toggle}
          onToggle={(visible) => {
            if (!visible) {
              setTimeout(() => {
                setTooltipOpen(false);
              }, 500);
            }
          }}
        >
          {shareButtons
            ? (
              <ShareButtonLink
                onClick={() => {
                  copyToClipboard(url);
                }}
              />
            )
            : (
              <IconLink24
                onClick={() => {
                  copyToClipboard(url);
                }}
              />
            )}
        </OverlayTrigger>
      )}
    </div>
  );
};

SocialMediaShare.propTypes = {
  className: PropTypes.string,
  copyUrl: PropTypes.bool,
  facebook: PropTypes.bool,
  followLinks: PropTypes.oneOfType([PropTypes.object]),
  linkedIn: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  shareButtons: PropTypes.bool,
  shareTitle: PropTypes.string,
  shareUrl: PropTypes.string,
  twitter: PropTypes.bool,
  white: PropTypes.bool,
};

SocialMediaShare.defaultProps = {
  className: undefined,
  copyUrl: false,
  facebook: true,
  followLinks: null,
  linkedIn: true,
  onlyIcon: false,
  shareButtons: false,
  shareTitle: "DKMS",
  shareUrl: null,
  twitter: true,
  white: false,
};

export default SocialMediaShare;
