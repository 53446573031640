import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import ContactPerson from "../components/contactPerson/contactPerson";
import Testimonial from "../components/testimonial/testimonial";

const KontentItemTestimonial = ({ data }) => {
  const testimonial = data.value ? data.value[0] : data;

  if (!testimonial || !testimonial.elements) {
    return null;
  }

  return (
    <Testimonial
      contactPerson={(
        <ContactPerson
          name={testimonial.elements.name.value}
          department={testimonial.elements.description.value}
          image={testimonial.elements.image.value.length > 0
            ? (
              <KontentAssetsImage
                data={testimonial.elements.image.value[0]}
                height={300}
                width={300}
              />
            )
            : null}
        />
      )}
      statement={testimonial.elements.story_text.value}
    />
  );
};

export const query = graphql`
  fragment KontentItemTestimonial on kontent_item_testimonial {
    id
    system {
      codename
    }
    elements {
      name {
        value
      }
      description {
        value
      }
      image {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
      story_text {
        value
      }
    }
  }
`;

KontentItemTestimonial.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemTestimonial;
