import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ImageDownload from "../../components/imageDownload/imageDownload";
import LightboxDownload from "../../components/lightboxDownload/lightboxDownload";
import { convertBytes } from "../../utils/formats";
import KontentItemImage from "../kontentItemImage";

const ImageDownloads = ({ data, usePressFilter }) => {
  let imageData = Array.isArray(data) ? data : data.value;

  if (usePressFilter) {
    imageData = imageData.filter((image) => (image.elements.press_category.value.length > 0));
  }

  const imageList = [];

  imageData.forEach((image) => {
    imageList.push(
      <ImageDownload
        image={<KontentItemImage data={image} aspectRatio={4 / 3} width={700} fit="crop" />}
        category={
          image.elements.press_category.value.length > 0
            ? image.elements.press_category.value[0].name
            : null
        }
        modalTitle={image.elements.title.value.length > 0 ? image.elements.title.value : " "}
        downloadLink={
          image.elements.image.value.length > 0
            ? image.elements.image.value[0].url
            : null
        }
        modalContent={image.elements.image.value.length > 0
          ? (
            <LightboxDownload
              image={<KontentItemImage data={image} aspectRatio={16 / 9} />}
              fileSize={convertBytes(image.elements.image.value[0].size)}
              description={image.elements.caption.value}
              downloadLink={image.elements.image.value[0].url}
              copyRight={image.elements.copyright_author.value}
              assetLicense={image.elements.asset_license__license.value}
              fileType={image.elements.image.value[0].type}
            />
          )
          : null}
      />,
    );
  });

  return React.Children.toArray(imageList);
};

export const query = graphql`
  fragment ImageDownloads on kontent_item_image {
    id
    system {
      codename
    }
    elements {
      image {
        value {
          url
          height
          width
          name
          description
          size
          type
        }
      }
      set_focal_point__image_transformer {
        value
      }
      image_component_style {
        value {
          codename
        }
      }
      media_type {
        value {
          codename
        }
      }
      press_category {
        value {
          name
        }
      }
      caption {
        value
      }
      title {
        value
      }
      copyright_author {
        value
      }
      asset_license__license {
        value
      }
    }
  }
`;

ImageDownloads.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  usePressFilter: PropTypes.bool,
};

ImageDownloads.defaultProps = {
  usePressFilter: false,
};

export default ImageDownloads;
