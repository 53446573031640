import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import "./selectionIconBox.scss";

const SelectionIconBox = (props) => {
  const {
    button,
    checked,
    id,
    children,
    name,
    onBlur,
    onChange,
    type,
    value,
    className,
  } = props;

  return (
    <Form.Check
      type={type}
      id={id}
      name={name}
      className={classNames("selection-icon-box", className)}
      label={(
        <div className="selection-icon-box__content">
          <div className="selection-icon-box__label-container">
            {children}
            {button}
          </div>
        </div>
      )}
      checked={checked}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

SelectionIconBox.propTypes = {
  button: PropTypes.node,
  checked: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["checkbox", "radio"]).isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

SelectionIconBox.defaultProps = {
  button: null,
  checked: false,
  className: null,
  onBlur: () => {},
  onChange: () => {},
  value: true,
};

export default SelectionIconBox;
