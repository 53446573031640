import React, { forwardRef, useEffect, useRef } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import BootstrapForm from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import detailViewBuild from "./detailViewBuilder";
import { build } from "./formBuilder";
import { setDetailsView } from "../../features/updateAddressSlice";
import { IconEdit24, IconFillForm24 } from "../../icons";
import FormObserver from "../../utils/formik/formObserver";
import IconButton from "../button/iconButton";
import SubmitButton from "../dynamicForm/submitButton";

import "./form.scss";

const Form = forwardRef((props, ref) => {
  const {
    fields,
    initialValues,
    validationSchema,
    submitButtonLabel,
    onSubmit,
    loading,
    footNote,
    summaryInfo,
  } = props;

  const meta = {};
  const additionalRequiredSubFields = [];

  const warning = useSelector((state) => state.registration.warning);

  const prevAddressCheckboxChecked = useSelector((state) => (
    state.updateAddress.prevAddressCheckboxChecked
  ));

  const intl = useIntl();

  const dispatch = useDispatch();

  const detailsView = useSelector((state) => state.updateAddress.detailsView);

  const formRef = useRef(null);

  meta.requiredFields = fields.filter(
    (field) => {
      if (field.tagName === "RadioButtonSection") {
        const required = field.props.radioButtonLists.map((item) => item.required);

        return required.length > 0;
      }

      if (field.tagName === "FormAddressInput") {
        const requiredAddress = field.props.subFields.map((item) => item.props.required);

        return requiredAddress.length > 0;
      }

      return field.props.required;
    },
  ).map((field) => {
    if (field.tagName === "RadioButtonSection") {
      field.props.radioButtonLists.forEach((item) => {
        if (item.required) {
          additionalRequiredSubFields.push(item.id);
        }
      });

      return null;
    }

    if (field.tagName === "FormAddressInput") {
      field.props.subFields.forEach((item) => {
        if (item.props.required) {
          additionalRequiredSubFields.push(item.props.id);
        }
      });

      return null;
    }

    return field.props.id;
  });

  const requiredFields = meta.requiredFields.concat(additionalRequiredSubFields);
  const index = requiredFields.indexOf(null);

  if (index > -1) {
    requiredFields.splice(index, 1);
  }

  meta.requiredFields = requiredFields;

  const excludeElements = ["privacy", "data_protection_header", "consentTransfer"];

  const filteredFields = fields.filter((item) => (
    excludeElements.indexOf(item.props.id) === -1
  ));

  const dataProtectionAndAdvertising = fields.filter((field) => (
    excludeElements.indexOf(field.props.id) !== -1));

  excludeElements.push("address_unchanged");

  if (prevAddressCheckboxChecked) {
    excludeElements.push("previous_address_header");
  }

  const filteredLabels = fields.filter((item) => (
    excludeElements.indexOf(item.props.id) === -1
  ));

  useEffect(() => {
    if (window && formRef?.current && detailsView) {
      formRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  }, [detailsView]);

  const indexPrivacy = dataProtectionAndAdvertising.findIndex((item) => (item.props.type === "checkbox" && item.props.id === "privacy"));

  if (dataProtectionAndAdvertising[indexPrivacy]?.props) {
    dataProtectionAndAdvertising[indexPrivacy].props.required = detailsView;
  }

  return (
    <Formik
      innerRef={ref}
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};

        if (process.env.GATSBY_SITE === "de") {
          if (values.mobile && values.landline && values.mobile === values.landline) {
            errors.mobile = intl.formatMessage({ id: "form.error.same_phone.mobile" });
            errors.landline = intl.formatMessage({ id: "form.error.same_phone.landline" });
          }
        }

        return errors;
      }}
    >{({
      values,
      handleSubmit,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      resetForm,
      submitCount,
      errors,
      touched,
    }) => {
      // Validate dynamic inputs like address input on submit
      useEffect(() => {
        try {
          Object.keys(errors).forEach((formField) => setFieldTouched(formField));
        } catch (e) {
          // ignore
        }
      }, [submitCount]);

      return (
        <BootstrapForm onSubmit={handleSubmit} ref={formRef}>
          <FormObserver updateAddress />
          <Row>
            <Col xs={6} md={4} lg={3}>
              <button
                type="button"
                className={classNames("tab no-style", { active: !detailsView })}
                onClick={() => {
                  dispatch(
                    setDetailsView(false),
                  );
                }}
              >
                <IconFillForm24 /> <FormattedMessage id="address_update.details" />
              </button>
            </Col>
            <Col xs={6} md={4} lg={3}>
              <div className={classNames("tab", { active: detailsView })}>
                <IconEdit24 /> <FormattedMessage id="address_update.check" />
              </div>
            </Col>
            {detailsView
              ? (
                <Col xs={12} className="update-address__detail-view">
                  <Row>
                    <Col xs={12} lg={6}>
                      <Row>
                        <Col xs={12} className="typo-h3 detail-view__info">
                          {summaryInfo}
                        </Col>
                        {filteredLabels.map((field) => (
                          detailViewBuild(field, intl, values)
                        ))}
                      </Row>
                    </Col>
                    <Col xs={12} lg={{ offset: 1, span: 5 }}>
                      <div className="update-address__data-protection">
                        <div className="typo-h3">{intl.formatMessage({ id: dataProtectionAndAdvertising[0].props.headline })}</div>
                        <div>
                          {intl.formatMessage(
                            { id: dataProtectionAndAdvertising[0].props.description },
                          )}
                        </div>
                        {dataProtectionAndAdvertising.map((item, i) => {
                          if (i > 0) {
                            return (
                              build(item, {
                                errors,
                                meta,
                                onBlur: handleBlur,
                                onChange: handleChange,
                                resetForm,
                                setFieldTouched,
                                setFieldValue,
                                touched,
                                values,
                              }));
                          }

                          return null;
                        })}
                        <div className="update-address__submit">
                          <SubmitButton
                            label={submitButtonLabel}
                            disabled={loading}
                            loading={loading}
                            onClick={() => {
                              const err = Object.keys(errors);

                              if (err.length > 0) {
                                let input = document.querySelector(
                                  `input[name=${err[0]}]`,
                                );

                                if (!input) {
                                  input = document.querySelector(
                                    `input[id=${err[0]}]`,
                                  );
                                }

                                if (input) {
                                  input.scrollIntoView({
                                    behavior: "smooth",
                                    block: "center",
                                    inline: "start",
                                  });
                                }
                              }
                            }}
                          />
                          <IconButton
                            variant="inverted-primary"
                            onClick={() => {
                              dispatch(
                                setDetailsView(false),
                              );
                            }}
                          >
                            <IconEdit24 /> <FormattedMessage id="address_update.edit_details" />
                          </IconButton>
                        </div>
                        <div className="footnote--required">
                          <span className="required-sign" />
                          <FormattedMessage id="registration.required_fields" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )
              : (
                <>
                  <Col xl={8}>
                    <Row>
                      {[...filteredFields].map((field) => (
                        field.responsive.singleLine
                          ? (
                            <Col style={field.responsive.style} className="p-0" key={field.props.id} xs={12}>
                              <Col xs={field.responsive.xs} md={field.responsive.md}>
                                {build(field, {
                                  errors,
                                  meta,
                                  onBlur: handleBlur,
                                  onChange: handleChange,
                                  resetForm,
                                  setFieldTouched,
                                  setFieldValue,
                                  touched,
                                  values,
                                  warning,
                                })}
                              </Col>
                            </Col>
                          )
                          : (
                            <Col
                              style={field.responsive.style}
                              key={field.props.id}
                              xs={field.responsive.xs}
                              md={field.responsive.md}
                            >
                              {build(field, {
                                errors,
                                meta,
                                onBlur: handleBlur,
                                onChange: handleChange,
                                resetForm,
                                setFieldValue,
                                touched,
                                values,
                                warning,
                              })}
                            </Col>
                          )
                      ))}
                      <Col xs={12}>
                        <Button
                          type="submit"
                          onClick={() => {
                            const err = Object.keys(errors);

                            if (err.length > 0) {
                              let input = document.querySelector(
                                `input[name=${err[0]}]`,
                              );

                              if (!input) {
                                input = document.querySelector(
                                  `input[id=${err[0]}]`,
                                );
                              }

                              if (input) {
                                input.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "start",
                                });
                              }
                            } else {
                              dispatch(
                                setDetailsView(true),
                              );
                            }
                          }}
                        >
                          <FormattedMessage id="address_update.check_details" />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  {footNote && footNote !== " " && (
                    <Col xs={12} className={classNames("footnote")}>
                      {footNote}
                    </Col>
                  )}
                </>
              )}
            <Col xs={12} className={classNames("footnote--required", { "d-none": detailsView })}>
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </Col>
          </Row>
        </BootstrapForm>
      );
    }}
    </Formik>
  );
});

Form.displayName = "Form";

Form.propTypes = {
  fields: PropTypes.oneOfType([PropTypes.array]).isRequired,
  footNote: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  initialValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  summaryInfo: PropTypes.string.isRequired,
  validationSchema: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Form.defaultProps = {
  footNote: null,
  loading: null,
};

export default Form;
