import React, { useMemo, useState } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFillForm24, IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import FormObserver from "../../../utils/formik/formObserver";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import AddressInput from "../../addressInput/addressInput";
import IconButton from "../../button/iconButton";
import CollapseBox from "../../collapseBox/collapseBox";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationResidentialAddressCl = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const getOptionByValue = (options, value) => options.find((el) => el.value === value);

  const initialRegionOption = getOptionByValue(
    RegistrationDataHelper.regionOptionsCl,
    registrationData.region,
  );

  const [addressShown, setAddressShown] = useState(registrationData.street !== "");
  const [region, setRegion] = useState(initialRegionOption?.label || null);

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const warning = useSelector((state) => state.registration.warning);

  let warningInfo;

  if (Object.keys(warning).length > 0) {
    warningInfo = RegistrationDataHelper.generateWarning(warning);
  }

  const validationSchema = useMemo(() => object().shape({
    city: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    houseNo: string()
      .required(requiredMessage)
      .min(1, requiredMessage)
      .max(10, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 10,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    info: string()
      .min(2, requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    postCode: string()
      .matches(/^\d{7}$/, intl.formatMessage(
        { id: "form.error.exact_digits" },
        {
          value: 7,
        },
      )),
    region: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    street: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .trim()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.address.title"
        subheadline="registration.address.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              city: values.city,
              houseNo: values.houseNo,
              info: values.info,
              postCode: values.postCode,
              region: values.region,
              street: values.street,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_residential_address", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormObserver />
            <AddressInput
              onResult={(data) => {
                const regionOption = RegistrationDataHelper.regionOptionsCl.find(
                  (el) => el.label.includes(data.state),
                );
                const cityOption = RegistrationDataHelper.cityOptionsCl.flatMap(
                  (el) => (el.options ? el.options : el),
                ).find((el) => el.label.includes(data.region));

                setAddressShown(true);
                setRegion(regionOption?.label || null);

                setFieldValue("street", data.street || "");
                setFieldValue("houseNo", data.houseno || "");
                setFieldValue("postCode", data.zipcode || "");
                setFieldValue("city", cityOption?.value || "");
                setFieldValue("region", regionOption?.value || "");
              }}
            />

            <CollapseBox
              opened={addressShown}
              label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
              icon={<IconFillForm24 />}
              warning={Object.keys(warning).length > 0}
            >
              <Row>
                <Col md={12}>
                  <DropdownFormField
                    label={intl.formatMessage({ id: "registration.address.region" })}
                    placeholder={intl.formatMessage({ id: "registration.please_select" })}
                    onChange={(selectedOption) => {
                      const { target } = selectedOption;

                      if (target && target.value) {
                        setFieldValue("region", target.value);
                        setFieldError("region", undefined);

                        setRegion(
                          getOptionByValue(
                            RegistrationDataHelper.regionOptionsCl,
                            target.value,
                          ).label,
                        );
                      } else {
                        setFieldValue("region", "");
                      }
                      setFieldValue("city", "");
                    }}
                    onBlur={() => {
                      setFieldTouched("region", true);
                    }}
                    touched={touched}
                    id="region"
                    errors={errors}
                    values={values}
                    options={RegistrationDataHelper.regionOptionsCl}
                    required
                  />
                </Col>
                {region && (
                  <Col md={12}>
                    <DropdownFormField
                      label={intl.formatMessage({ id: "registration.address.city" })}
                      placeholder={intl.formatMessage({ id: "registration.please_select" })}
                      onChange={(selectedOption) => {
                        const { target } = selectedOption;

                        if (target && target.value) {
                          setFieldValue("city", target.value);
                          setFieldError("city", undefined);
                        } else {
                          setFieldValue("city", "");
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched("city", true);
                      }}
                      touched={touched}
                      id="city"
                      errors={errors}
                      values={values}
                      options={RegistrationDataHelper.cityOptionsCl.filter(
                        (item) => (item.label === region),
                      )}
                      required
                    />
                  </Col>
                )}
                <Col md={8}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="street"
                    label={intl.formatMessage({ id: "registration.address.street" })}
                    type="text"
                    warning={warning}
                    maxLength={50}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={4}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="houseNo"
                    label={intl.formatMessage({ id: "registration.address.houseNo" })}
                    type="text"
                    warning={warning}
                    maxLength={10}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6} className="mb-md-0">
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="info"
                    label={intl.formatMessage({ id: "registration.address.info" })}
                    type="text"
                    warning={warning}
                    maxLength={100}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6} className="mb-0">
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="postCode"
                    label={intl.formatMessage({ id: "registration.address.postCode" })}
                    type="text"
                    maxLength={7}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col xs={12} className="warning-info">
                  {warningInfo}
                </Col>
              </Row>
            </CollapseBox>

            <ScrollToFieldError />

            <Form.Group>
              <IconButton
                type="submit"
                disabled={isSubmitting}
                onClick={() => {
                  setAddressShown(true);
                }}
              >
                <FormattedMessage id="registration.address.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>

            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationResidentialAddressCl.stepName = "registration_residential_address";

RegistrationDialogStepRegistrationResidentialAddressCl.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationResidentialAddressCl;
