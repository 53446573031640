import { graphql, useStaticQuery } from "gatsby";

import CampaignCard from "./campaignCard";

const LatestCampaignCards = () => {
  const data = useStaticQuery(graphql`
    {
      allKontentItemArticlePageCampaign(sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          ...CampaignCard
        }
      }
    }
  `);

  if (data.allKontentItemArticlePageCampaign) {
    return CampaignCard({
      data: data.allKontentItemArticlePageCampaign.nodes,
      tile: true,
    });
  }

  return null;
};

export default LatestCampaignCards;
