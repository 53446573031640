import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ContentHeader from "../../components/contentHeader/contentHeader";
import SEO from "../../components/seo";
import WebToCase from "../../components/webToCase/webToCase";
import getInternalLinkById from "../../utils/getInternalLinkById";
import KontentAssetsImage from "../kontentAssetsImage";
import MainLayout from "../layout/mainLayout";

const KontentItemWebToCase = ({ data }) => {
  const { page } = data;

  let thankYouPageLink = null;

  if (page.elements.thank_you_page.value.length > 0) {
    thankYouPageLink = getInternalLinkById(page.elements.thank_you_page.value[0].id);
  }

  return (
    <MainLayout>
      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
        isHiddenForRobots
      />

      <ContentHeader
        headline={page.elements.headline.value}
        description={page.elements.lead_text.value}
        media={page.elements.image.value.length > 0 ? (
          <KontentAssetsImage
            data={page.elements.image.value[0]}
            height={147}
          />
        ) : null}
      />

      <WebToCase thankYouPageLink={thankYouPageLink} formSlug={page.elements.url_pattern.value} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemWebToCase(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        image {
          value {
            url
            height
            width
            description
          }
        }
        thank_you_page {
          value {
            id
          }
        }
        url_pattern {
          value
        }
      }
    }
  }
`;

KontentItemWebToCase.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemWebToCase;
