import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconDirectory18 } from "../../icons";
import IconText from "../iconText/iconText";
import NavigationLink from "../navigationLink/navigationLink";

import "./learnTopicEmbed.scss";

const LearnTopicEmbed = (props) => {
  const {
    title,
    image,
    href,
  } = props;

  const intl = useIntl();

  return (
    <NavigationLink href={href} className="no-style">
      <div className="learn-topic-embed">
        <div className="learn-topic-embed__content">
          <IconText
            icon={<IconDirectory18 />}
            variant="learn-area"
            text={intl.formatMessage({ id: "learn_topic_embed.topic" })}
          />
          <div className="learn-topic-embed__title">{title}</div>
        </div>
        {
          image
          && (
            <div className="learn-topic-embed__image">
              {image}
            </div>
          )
        }
      </div>
    </NavigationLink>
  );
};

LearnTopicEmbed.propTypes = {
  href: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  title: PropTypes.string.isRequired,
};

LearnTopicEmbed.defaultProps = {
  image: null,
};

export default LearnTopicEmbed;
