import React, { memo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import BootstrapButton from "react-bootstrap/Button";

// eslint-disable-next-line import/no-cycle
import NavigationLink from "../navigationLink/navigationLink";

import "./button.scss";

const Button = (props) => {
  const {
    children,
    className,
    compressed,
    size,
    ...buttonProps
  } = props;

  return (
    <BootstrapButton
      as={NavigationLink}
      typeOverwrite={buttonProps.type}
      className={classNames(className, { compressed: compressed && size === "sm" })}
      size={size}
      {...buttonProps}
    >
      {children}
    </BootstrapButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  compressed: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg"]),
};

Button.defaultProps = {
  className: null,
  compressed: false,
  size: null,
};

export default memo(Button);
