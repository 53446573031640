import React, { useContext } from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

import KontentItemGlobalData from "./global/kontentItemGlobalData";
import KontentItemVideoEmbed from "./kontentItemVideoEmbed";
import RichTextContext from "./text/richTextContext";
import MissionStatementBanner from "../components/banner/missionStatementBanner";
import iconHelper from "../utils/iconHelper";

const KontentItemMissionStatement = ({ data }) => {
  const missionStatement = data.value ? data.value[0] : data;

  const isRichText = useContext(RichTextContext);

  const missionStatementComponent = (
    <MissionStatementBanner
      title={missionStatement.elements.title.value}
      cardDescription={missionStatement.elements.link_text.value}
      linkUrl={missionStatement.elements.link_url.value}
      iconLeft={iconHelper(missionStatement.elements.icon_left.value[0].codename)}
      numberLeft={
        missionStatement.elements.number_left.value
        || KontentItemGlobalData(
          { data: JSON.parse(missionStatement.elements.number_left___global_data.value) },
        )
      }
      descriptionLeft={missionStatement.elements.description_left.value}
      iconRight={iconHelper(missionStatement.elements.icon_right.value[0].codename)}
      numberRight={
        missionStatement.elements.number_right.value
        || KontentItemGlobalData(
          { data: JSON.parse(missionStatement.elements.number_right___global_data.value) },
        )
      }
      descriptionRight={missionStatement.elements.description_right.value}
      media={(<KontentItemVideoEmbed data={missionStatement.elements.video} variant="banner" />)}
    />
  );

  if (isRichText) {
    return (
      <div className="container--fullwidth">
        <Container fluid>
          {missionStatementComponent}
        </Container>
      </div>
    );
  }

  return missionStatementComponent;
};

export const query = graphql`
  fragment KontentItemMissionStatement on kontent_item_mission_statement {
    id
    system {
      codename
    }
    elements {
      description_left {
        value
      }
      description_right {
        value
      }
      icon_left {
        value {
          codename
          name
        }
      }
      icon_right {
        value {
          codename
          name
        }
      }
      link_text {
        value
      }
      link_url {
        value
      }
      number_left {
        value
      }
      number_right {
        value
      }
      title {
        value
      }
      video {
        value {
          ...KontentItemVideoEmbed
        }
      }
      number_left___global_data {
        value
      }
      number_right___global_data {
        value
      }
    }
  } 
`;

KontentItemMissionStatement.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemMissionStatement;
