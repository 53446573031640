import React from "react";

import { camelCase } from "./stringUtilHelper";
import GlobalTemplates from "../templates/global/globalTemplates";

const mapGlobalKontentItem = (jsonString) => {
  let output = [];

  const data = JSON.parse(jsonString);

  data.forEach((item) => {
    if (item.type) {
      // eslint-disable-next-line no-underscore-dangle
      const type = camelCase(item.type);
      const TagName = GlobalTemplates[`KontentItem${type.charAt(0).toUpperCase() + type.slice(1)}`];

      if (!TagName) return (<div className="alert alert-warning">## missing element ##</div>);

      try {
        output.push((TagName({ data: item, key: `g${item.id}` })));
      } catch (e) {
        output = ("Error");
      }
    }

    return null;
  });

  return output;
};

export default mapGlobalKontentItem;
