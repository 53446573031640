import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import LearnArticleEmbedComponent from "../../components/learnArticleEmbed/learnArticleEmbed";
import KontentItemImage from "../kontentItemImage";

const DonorArticleEmbed = ({ data }) => {
  const donorArticleEmbed = data.value ? data.value[0] : data;

  if (!donorArticleEmbed || !donorArticleEmbed.elements) {
    return null;
  }

  return (
    <LearnArticleEmbedComponent
      headline={donorArticleEmbed.elements.headline.value}
      border
      href={donorArticleEmbed.id}
      image={(
        <KontentItemImage
          data={donorArticleEmbed.elements.hero_image}
          width={78}
          aspectRatio={1}
        />
      )}
    />
  );
};

export const query = graphql`
  fragment DonorArticleEmbed on kontent_item_article_donor_area {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

DonorArticleEmbed.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default DonorArticleEmbed;
