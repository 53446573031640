import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ProgramTileComponent from "../../components/programTile/programTile";
import backgroundImageHelper from "../image/backgroundImageHelper";

const ProgramTile = ({ data }) => {
  const programData = Array.isArray(data) ? data : data.value;

  const programs = [];

  programData.forEach((program) => {
    programs.push(
      <ProgramTileComponent
        label={program.elements.headline.value}
        backgroundImageStyle={backgroundImageHelper(program.elements.hero_image, { w: 800 })}
        href={program.id}
        key={program.id}
      />,
    );
  });

  return React.Children.toArray(programs);
};

export const query = graphql`
  fragment ProgramTile on kontent_item_program {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      program_category {
        value {
          codename
        }
      }
    }
  }
`;

ProgramTile.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ProgramTile;
