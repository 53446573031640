import React, { memo, useRef } from "react";

import { ImageElement } from "@kentico/gatsby-kontent-components";
import classNames from "classnames";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ImagePlaceholder from "../components/imagePlaceholder/imagePlaceholder";
import MediaLightboxImage from "../components/lightbox/mediaLightboxImage";

const KontentItemImage = (props) => {
  const {
    data,
    fixed,
    height,
    width,
    style,
    className,
    enableCaption,
    enableComponentStyle,
    directDescription,
    defaultImage,
    aspectRatio,
    imageOnlyWidth,
    fit,
    placeholderTyp,
  } = props;

  let image = null;
  let imageWidth = width;
  let imageHeight = height;
  let description = directDescription;
  let defaultAspectRatio = null;
  let extraClasses = "";
  let imgStyle = null;
  let isModalEnabled = false;
  let originalHeight = null;
  let originalWidth = null;
  let isGraphic = false;
  let caption = null;

  let imageValue = null;

  image = data?.value ? data.value[0] : data;

  if (!image) {
    if (height || width) {
      return (
        <ImagePlaceholder
          height={height}
          width={width}
          aspectRatio={aspectRatio}
          typ={placeholderTyp}
          className={className}
        />
      );
    }

    return null;
  }

  if (image && image.elements) {
    if (image.elements.image.value.length === 0) {
      return null;
    }

    if (enableCaption && image.elements.caption.value) {
      caption = (
        <div className="text-center typo-small-label text-grey-dark py-4">
          {image.elements.caption.value}
        </div>
      );
    }

    let tmpRatio = 1;
    [imageValue] = image.elements.image.value;

    if (height) {
      tmpRatio = imageValue.width / imageValue.height;

      imageValue.height = height;
      imageValue.width = tmpRatio * height;
    }

    if (width) {
      tmpRatio = imageValue.height / imageValue.width;

      imageValue.width = width;
      imageValue.height = tmpRatio * width;
    }

    // JAD-14063 - Remove HDPI Images
    if (imageValue.width > 1920) {
      tmpRatio = imageValue.height / imageValue.width;

      imageValue.width = 1920;
      imageValue.height = tmpRatio * 1920;
    }

    if (imageValue.height > 2880) {
      tmpRatio = imageValue.width / imageValue.height;

      imageValue.height = 2880;
      imageValue.width = tmpRatio * 2880;
    }

    defaultAspectRatio = imageValue.width / imageValue.height;
    originalWidth = imageValue.width;
    originalHeight = imageValue.height;
    description = imageValue.description;

    if (enableComponentStyle && image.elements.image_component_style.value.length > 0) {
      switch (image.elements.image_component_style.value[0].codename) {
        case "panorama_21_9":
          imageWidth = 1920;
          defaultAspectRatio = 21 / 9;
          extraClasses = "text-section__fullwidth";
          break;
        case "flexible_height":
          imageWidth = 1000;
          break;
        case "block_image_right":
          extraClasses = "float-right mt-0 p-0 pl-30 col-12 col-md-5";
          imageWidth = 400;
          isModalEnabled = true;
          break;
        case "block_image_left":
          extraClasses = "float-left mt-0 p-0 pr-30 col-12 col-md-5";
          imageWidth = 400;
          isModalEnabled = true;
          break;
        case "upright_3_4":
          extraClasses = "col-md-6 mx-auto";
          imageWidth = 400;
          imageHeight = (imageWidth / 3) * 4;
          isModalEnabled = true;
          break;
        default:
      }
    }

    const mediaType = image.elements.media_type.value;
    isGraphic = mediaType.length > 0 && mediaType[0].codename === "graphic";

    if (isGraphic) {
      imgStyle = {
        objectFit: "contain",
      };
    }

    if (image.elements.set_focal_point__image_transformer.value) {
      const focalPoint = JSON.parse(image.elements.set_focal_point__image_transformer.value);

      if (focalPoint.length > 0) {
        const kontentFocalPoint = focalPoint[0].transforms.crop.zoom;

        if (kontentFocalPoint) {
          imgStyle = {
            objectPosition: `${Math.abs(kontentFocalPoint.xFloat * 100)}% ${Math.abs(kontentFocalPoint.yFloat * 100)}%`,
            ...imgStyle,
          };
        }
      }
    }
  }

  if (imageWidth && imageHeight) {
    defaultAspectRatio = imageWidth / imageHeight;
  }

  let defaultStyle = null;

  if (
    imageHeight
    && !fixed
    && (!imageOnlyWidth || ((originalHeight * imageWidth) / originalWidth) < imageHeight)
  ) {
    imageWidth = width * 3;
    defaultStyle = {
      height,
      width,
    };
  }

  if (
    imageWidth
    && (!imageOnlyWidth || ((originalHeight * imageWidth) / originalWidth) >= imageHeight)
  ) {
    imageHeight = height * 3;
    defaultStyle = {
      height,
      width,
    };
  }

  const imageProps = {
    alt: description,
    aspectRatio: aspectRatio || defaultAspectRatio,
    className,
    height: imageHeight,
    image: imageValue,
    layout: "constrained",
    style: style || defaultStyle,
    title: description,
    width: imageWidth,
  };

  if (typeof imageValue !== "object" || !imageValue) {
    return (
      <ImagePlaceholder
        height={imageProps.height}
        width={imageProps.width}
        aspectRatio={imageProps.aspectRatio}
        typ={placeholderTyp}
        className={className}
      />
    );
  }

  if (fixed) {
    return (
      <ImageElement
        {...imageProps}
        imgStyle={imgStyle}
        objectFit="cover"
        options={{
          fit: fit || "clip",
          lossless: true,
        }}
      />
    );
  }

  if (defaultImage || (!imageHeight && !imageWidth)) {
    if (defaultImage && !imageWidth && imageHeight) {
      imageProps.style.width = imageHeight * (originalWidth / originalHeight);
    }

    if (defaultImage && !imageHeight && imageWidth) {
      imageProps.style.height = imageWidth * (originalHeight / originalWidth);
    }

    if (enableComponentStyle && image.elements.image_component_style.value.length === 0) {
      return (
        <div className="img-container">
          <ImageElement
            {...imageProps}
            // imgStyle={imgStyle}
            options={{
              fit: fit || "crop",
              lossless: true,
            }}
          />
          {caption}
        </div>
      );
    }

    return (
      <ImageElement
        {...imageProps}
        // imgStyle={imgStyle}
        options={{
          fit: fit || "crop",
          lossless: true,
        }}
      />
    );
  }

  const img = (
    <ImageElement
      {...imageProps}
      style={imageOnlyWidth ? style : null}
      imgStyle={imgStyle}
      options={{
        fit: fit || "cover",
        lossless: true,
      }}
    />
  );

  if (isModalEnabled) {
    const mediaModal = useRef(null);

    const showModal = () => {
      if (isModalEnabled && mediaModal.current) {
        mediaModal.current.show();
      }
    };

    return (
      <div
        tabIndex="0"
        role="button"
        className={classNames(extraClasses, "img-container")}
        onClick={() => showModal()}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            showModal();
            event.stopPropagation();
          }
        }}
        aria-label={imageProps.image.description || imageProps.image.name}
      >
        {img}
        {caption}
        <MediaLightboxImage
          media={(
            <ImageElement
              {...imageProps}
              style={imageOnlyWidth ? style : null}
              layout="fullWidth"
            />
          )}
          ref={mediaModal}
        />
      </div>
    );
  }

  return (
    <div className={classNames(extraClasses, "img-container")}>
      {img}
      {caption}
    </div>
  );
};

KontentItemImage.propTypes = {
  aspectRatio: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]),
  defaultImage: PropTypes.bool,
  directDescription: PropTypes.string,
  enableCaption: PropTypes.bool,
  enableComponentStyle: PropTypes.bool,
  fit: PropTypes.string,
  fixed: PropTypes.bool,
  height: PropTypes.number,
  imageOnlyWidth: PropTypes.bool,
  placeholderTyp: PropTypes.oneOf(["maps", "events", "icon", "news", "virtual-drive", "download"]),
  style: PropTypes.oneOfType([PropTypes.object]),
  width: PropTypes.number,
};

KontentItemImage.defaultProps = {
  aspectRatio: null,
  className: "",
  data: undefined,
  defaultImage: false,
  directDescription: "",
  enableCaption: false,
  enableComponentStyle: false,
  fit: null,
  fixed: false,
  height: null,
  imageOnlyWidth: false,
  placeholderTyp: null,
  style: null,
  width: null,
};

export const query = graphql`
  fragment KontentItemImage on kontent_item_image {
    id
    system {
      codename
    }
    elements {
      image {
        value {
          description
          size
          name
          type
          url
          width
          height
        }
      }
      set_focal_point__image_transformer {
        value
      }
      image_component_style {
        value {
          codename
        }
      }
      media_type {
        value {
          codename
        }
      }
      caption {
        value
      }
    }
  }
`;

export default memo(KontentItemImage);
