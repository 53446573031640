import React, { useEffect } from "react";

import classNames from "classnames";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import { number, object, string } from "yup";

import PaymentMethods from "./paymentMethods";
import { setIsSepa, setPaymentData } from "../../features/registrationSlice";
import KontentAssetsImage from "../../templates/kontentAssetsImage";
import Button from "../button/button";
import CurrencyIcon from "../currencyIcon/currencyIcon";
import InputField from "../inputField/inputField";
import SelectionIconBox from "../selectionIconBox/selectionIconBox";
import StyledTab from "../tabList/styledTab";
import TabList from "../tabList/tabList";
import ToggleButton from "../toggleButton/toggleButton";

import "./donationBox.scss";

const DonationBoxDefault = (props) => {
  const {
    buttonURL,
    initialValues,
    onChange,
    onInit,
    buttonText,
    onSubmit,
    paymentOptions,
    withRequiredText,
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const intervalOptions = {
    de: [
      { label: intl.formatMessage({ id: "payment_dialog.monthly" }), value: "MONTHLY" },
      { label: intl.formatMessage({ id: "payment_dialog.quarterly" }), value: "QUARTERLY" },
      { label: intl.formatMessage({ id: "payment_dialog.halfyearly" }), value: "HALFYEARLY" },
      { label: intl.formatMessage({ id: "payment_dialog.yearly" }), value: "YEARLY" },
    ],
    gb: [
      { label: intl.formatMessage({ id: "payment_dialog.monthly" }), value: "MONTHLY" },
      { label: intl.formatMessage({ id: "payment_dialog.quarterly" }), value: "QUARTERLY" },
      { label: intl.formatMessage({ id: "payment_dialog.yearly" }), value: "YEARLY" },
    ],
  };

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const integerMessage = intl.formatMessage({ id: "form.error.amount_must_be_integer" });

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(
        setPaymentData({
          amount: values.amount,
          interval: values.interval,
        }),
      );

      if (typeof onSubmit !== "undefined" && !values.isSepa) {
        onSubmit(values);
      } else if (buttonURL && !values.isSepa) {
        window.location.href = `${buttonURL}?amount=${values.amount}&interval=${values.interval}`;
      } else {
        dispatch(setIsSepa(true));
      }
    },
    validationSchema: object().shape({
      amount: number()
        .integer(integerMessage)
        .typeError(integerMessage)
        .positive(integerMessage)
        .min(1, intl.formatMessage(
          { id: "form.error.gt_or_eq" },
          {
            value: 1,
          },
        ))
        .max(99999, intl.formatMessage(
          { id: "form.error.lt_or_eq" },
          {
            value: 99999,
          },
        ))
        .required(requiredMessage),
      interval: string()
        .required(requiredMessage),
      isSepa: string()
        .bool,
    }),
  });

  useEffect(() => {
    onInit({
      ...formik.values,
      impact: paymentOptions[formik.values.interval][0].impact,
    });
  }, []);

  useEffect(() => {
    const impactArray = [
      ...paymentOptions[formik.values.interval],
      ...(formik.values.interval === "ONCE" ? paymentOptions.ZERO : paymentOptions.ZERORECURRENT),
    ]
      .sort((a, b) => (a.amount < b.amount ? 1 : -1))
      .find((item) => item.amount <= formik.values.amount);

    onChange({
      ...formik.values,
      impact: impactArray?.impact,
    });
  }, [formik.values]);

  const amountToggles = (
    <>
      <div
        className={classNames(
          "donation-box__toggle-group-headline",
          { "mt-24": !intervalOptions[process.env.GATSBY_SITE] },
        )}
      >
        <FormattedMessage id="payment_dialog.choose_amount" />
      </div>
      <div className="donation-box__toggle-group row no-gutters">
        {paymentOptions[formik.values.interval].length > 0 && (
          paymentOptions[formik.values.interval]
            .sort((a, b) => (a.amount > b.amount ? 1 : -1))
            .map((item) => (
              <ToggleButton
                checked={formik.values?.amount.toString() === item.amount.toString()}
                onClick={() => {
                  formik.setFieldValue("amount", item.amount);
                }}
                variant="donation"
                key={item.amount}
                className="col"
              >
                <FormattedMessage id="currency.amount" values={{ amount: item.amount }} />
              </ToggleButton>
            ))
        )}
      </div>
    </>
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      {intervalOptions[process.env.GATSBY_SITE] ? (
        <TabList
          items={[
            {
              content: null,
              key: "once",
              tab: <StyledTab
                eventKey="once"
                className="styled-tab--secondary"
                title={intl.formatMessage({ id: "payment_dialog.once" })}
              />,
            },
            {
              content: (
                <>
                  <div className="donation-box__toggle-group-headline">
                    <FormattedMessage id="payment_dialog.choose_interval" />
                  </div>
                  <div className="donation-box__intervals mb-24">
                    {intervalOptions[process.env.GATSBY_SITE]?.map((interval) => (
                      paymentOptions[interval.value].length > 0 ? (
                        <ToggleButton
                          key={interval.value}
                          onClick={() => {
                            formik.setFieldValue("interval", interval.value);
                            formik.setFieldValue("amount", paymentOptions[interval.value][0].amount);
                          }}
                          variant="donation"
                          checked={formik.values.interval === interval.value}
                        >
                          {interval.label}
                        </ToggleButton>
                      ) : null
                    ))}
                  </div>
                </>
              ),
              key: "regularly",
              tab: <StyledTab
                eventKey="regularly"
                className="styled-tab--secondary"
                title={intl.formatMessage({ id: "payment_dialog.regularly" })}
              />,
            },
          ]}
          defaultActiveKey={formik?.values?.interval === "ONCE" ? "once" : "regularly"}
          onSelect={(key) => {
            if (formik.values.interval === "ONCE" && key === "regularly") {
              formik.setFieldValue("interval", "MONTHLY");
              formik.setFieldValue("amount", paymentOptions.MONTHLY[0].amount);
              formik.setFieldValue("isSepa", process.env.GATSBY_SITE === "de");
            } else if (formik.values.interval !== "ONCE" && key === "once") {
              formik.setFieldValue("interval", "ONCE");
              formik.setFieldValue("amount", paymentOptions.ONCE[0].amount);
              formik.setFieldValue("isSepa", false);
            }
          }}
        />
      ) : null}

      {amountToggles}

      <div className="mt-24">
        <InputField
          label={intl.formatMessage({ id: "payment_dialog.amount" })}
          required
          type="number"
          prepend={process.env.GATSBY_SITE === "gb" ? <CurrencyIcon /> : null}
          append={process.env.GATSBY_SITE !== "gb" ? <CurrencyIcon /> : null}
          formikInput={{
            ...formik.getFieldProps("amount"),
            onChange: (event) => {
              formik.getFieldProps("amount").onChange(event);
            },
          }}
          isInvalid={!formik.isValid}
          errorMessage={formik.errors?.amount || formik.errors?.interval}
        />
      </div>
      <Row className="mt-24">
        {((formik.values.interval === "ONCE" && paymentOptions.oneTimePaymentProvider?.length > 0)
            || (formik.values.interval !== "ONCE" && paymentOptions.recurrentPaymentProvider?.length > 0))
          && (
            <Col xs={12}>
              <div className="donation-box__payment-methods">
                <FormattedMessage
                  id={formik?.values?.interval === "ONCE" ? "payment_dialog.possible_payment_methods" : "payment_dialog.possible_payment_method"}
                />
              </div>
              {process.env.GATSBY_SITE === "de" && formik.values.interval === "ONCE"
                ? (
                  <div className="donation-box__payment-methods-select">
                    <SelectionIconBox
                      name="isSepa"
                      id="isSepa"
                      type="radio"
                      checked={!formik.values.isSepa}
                      onChange={() => formik.setFieldValue("isSepa", false)}
                      onBlur={formik.handleBlur}
                      value={false}
                    >
                      <PaymentMethods>
                        {paymentOptions.oneTimePaymentProvider?.filter((item) => item.name !== "a-logo-sepa_@2x.png").map((item) => (
                          <KontentAssetsImage height={36} width={36} fit="contain" key={item.name} data={item} />
                        ))}
                      </PaymentMethods>
                    </SelectionIconBox>
                    <SelectionIconBox
                      name="isSepa"
                      id="isNotSepa"
                      type="radio"
                      checked={formik.values.isSepa}
                      onChange={() => formik.setFieldValue("isSepa", true)}
                      onBlur={formik.handleBlur}
                    >
                      <PaymentMethods>
                        {paymentOptions.oneTimePaymentProvider?.filter((item) => item.name === "a-logo-sepa_@2x.png").map((item) => (
                          <KontentAssetsImage height={36} width={36} fit="contain" key={item.name} data={item} />
                        ))}
                      </PaymentMethods>
                    </SelectionIconBox>
                  </div>
                )
                : (
                  <>
                    <PaymentMethods>
                      {formik.values.interval === "ONCE" && paymentOptions.oneTimePaymentProvider
                        .map((item) => (
                          <KontentAssetsImage
                            height={36}
                            width={36}
                            fit="contain"
                            key={item.description}
                            data={item}
                          />
                        ))}
                      {formik.values.interval !== "ONCE" && paymentOptions.recurrentPaymentProvider
                        .map((item) => (
                          <KontentAssetsImage
                            height={36}
                            width={36}
                            fit="contain"
                            key={item.description}
                            data={item}
                          />
                        ))}
                    </PaymentMethods>
                    {process.env.GATSBY_SITE === "de" && (
                      <div className="typo-body2 donation-box__interval-hint">
                        <FormattedMessage id="payment_dialog.interval_hint" />
                      </div>
                    )}
                  </>
                )}

            </Col>
          )}
      </Row>
      <div className="donation-box__submit-container">
        <Button
          type="submit"
          variant="secondary"
          onClick={() => {
            window.skipBeforeUnload = true;
          }}
        >
          {process.env.GATSBY_SITE === "de" && formik.values.isSepa ? intl.formatMessage({ id: "payment_dialog.go_to_sepa" }) : buttonText}
        </Button>

        {withRequiredText && (
          <div className="footnote--required mt-0">
            <span className="required-sign" />
            <FormattedMessage id="payment.dialog.donation_box.required" />
          </div>
        )}
      </div>
    </form>
  );
};

DonationBoxDefault.propTypes = {
  buttonText: PropTypes.node.isRequired,
  buttonURL: PropTypes.string,
  initialValues: PropTypes.shape({
    amount: PropTypes.number,
    interval: PropTypes.string,
    isSepa: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
  onInit: PropTypes.func,
  onSubmit: PropTypes.func,
  paymentOptions: PropTypes.shape({
    HALFYEARLY: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      impact: PropTypes.shape(
        {
          icon: PropTypes.node,
          text: PropTypes.string.isRequired,
        },
      ),
    })).isRequired,
    MONTHLY: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      impact: PropTypes.shape(
        {
          icon: PropTypes.node,
          text: PropTypes.string.isRequired,
        },
      ),
    })).isRequired,
    ONCE: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      impact: PropTypes.shape(
        {
          icon: PropTypes.node,
          text: PropTypes.string.isRequired,
        },
      ),
    })).isRequired,
    QUARTERLY: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      impact: PropTypes.shape(
        {
          icon: PropTypes.node,
          text: PropTypes.string.isRequired,
        },
      ),
    })).isRequired,
    YEARLY: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      impact: PropTypes.shape(
        {
          icon: PropTypes.node,
          text: PropTypes.string.isRequired,
        },
      ),
    })).isRequired,
    ZERO: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      impact: PropTypes.shape(
        {
          icon: PropTypes.node,
          text: PropTypes.string.isRequired,
        },
      ),
    })).isRequired,
    ZERORECURRENT: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      impact: PropTypes.shape(
        {
          icon: PropTypes.node,
          text: PropTypes.string.isRequired,
        },
      ),
    })).isRequired,
    oneTimePaymentProvider: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
      height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      url: PropTypes.string,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    recurrentPaymentProvider: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
      height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      size: PropTypes.number,
      type: PropTypes.string,
      url: PropTypes.string,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
  }).isRequired,
  withRequiredText: PropTypes.bool,
};

DonationBoxDefault.defaultProps = {
  buttonURL: undefined,
  onChange: () => {},
  onInit: () => {},
  onSubmit: undefined,
  withRequiredText: false,
};

export default DonationBoxDefault;
