import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import SimpleRichText from "./text/simpleRichText";
import ModalView from "../components/modalView/modalView";

const KontentItemModalView = (props) => {
  const {
    data,
    removePostIcon,
    usePreIcon,
    buttonVariant,
  } = props;
  const modalView = data.value ? data.value[0] : data;

  if (!modalView || !modalView.elements) {
    return null;
  }

  return (
    <ModalView
      variant="registration-dialog"
      buttonText={modalView.elements.button_text.value}
      title={modalView.elements.title.value}
      text={<SimpleRichText data={modalView.elements.text} />}
      removePostIcon={removePostIcon}
      usePreIcon={usePreIcon}
      buttonVariant={buttonVariant}
    />
  );
};

export const query = graphql`
  fragment KontentItemModalView on kontent_item_modal_view {
    id
    system {
      codename
    }
    elements {
      text {
        value
        type
        modular_content {
          id
          system {
            codename
          }
          internal {
            type
          }
          ...KontentItemImageButton
        }
      }
      button_text {
        value
      }
      title {
        value
      }
    }
  }
`;

KontentItemModalView.propTypes = {
  buttonVariant: PropTypes.oneOf(["button", "normal", "text-button-small", "text-button-large", "typo-button"]),
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  removePostIcon: PropTypes.bool,
  usePreIcon: PropTypes.bool,
};

KontentItemModalView.defaultProps = {
  buttonVariant: "normal",
  removePostIcon: false,
  usePreIcon: false,
};

export default KontentItemModalView;
