import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setSetupData } from "../../../features/registrationSlice";
import { IconFurther18 } from "../../../icons";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";

const VirtualDriveSetupModalRelationShipToPatient = ({ next }) => {
  const setupData = useSelector((state) => state.registration.setupData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const getOptionByValue = (options, value) => options.find((el) => el.value === value);

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    otherRelationship: string(),
    relationship: string().required(requiredMessage),
  }), []);

  const relationshipOptions = [
    { label: intl.formatMessage({ id: "virtual_drive_setup.relationship_relative" }), value: "relative" },
    { label: intl.formatMessage({ id: "virtual_drive_setup.relationship_partner" }), value: "partner" },
    { label: intl.formatMessage({ id: "virtual_drive_setup.relationship_friend" }), value: "friend" },
    { label: intl.formatMessage({ id: "virtual_drive_setup.relationship_coworker" }), value: "coworker" },
    { label: intl.formatMessage({ id: "virtual_drive_setup.relationship_other" }), value: "other" },
  ];

  return (
    <>
      <Row>
        <Col sm={10}>
          <h3>
            <FormattedMessage id="virtual_drive_setup.patient_relationship_title" />
          </h3>
        </Col>
      </Row>
      <Formik
        initialValues={setupData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          const option = getOptionByValue(relationshipOptions, values.relationship);

          if (typeof values.otherRelationship !== "undefined" && values.otherRelationship !== "") {
            dispatch(
              setSetupData({
                relationship: values.otherRelationship,
                relationshipLabel: values.otherRelationship,
              }),
            );
          } else {
            dispatch(
              setSetupData({
                relationship: values.relationship,
                relationshipLabel: option.label,
              }),
            );
          }

          next();
        }}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} lg={values.relationship === "other" ? 6 : 12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "virtual_drive_setup.relationship_to_patient" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;
                    setFieldValue("relationship", target !== null ? target.value : "");
                    setFieldError("relationship", undefined);
                  }}
                  onBlur={() => {
                    setFieldTouched("relationship", true);
                  }}
                  touched={touched}
                  id="relationship"
                  errors={errors}
                  values={values}
                  options={relationshipOptions}
                  required
                  maxMenuHeight={300}
                />
              </Col>

              {values.relationship === "other" && (
                <Col xs={12} lg={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="otherRelationship"
                    label={intl.formatMessage({ id: "virtual_drive_setup.relationship_other" })}
                    type="text"
                    maxLength={25}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
              )}

              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group className="form-group-button">
                  <IconButton type="submit">
                    <FormattedMessage id="virtual_drive_setup.next" /> <IconFurther18 />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

VirtualDriveSetupModalRelationShipToPatient.stepName = "virtual_drive_relationship";

VirtualDriveSetupModalRelationShipToPatient.propTypes = {
  next: PropTypes.func.isRequired,
};

export default VirtualDriveSetupModalRelationShipToPatient;
