import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import BackgroundImage from "../backgroundImage/backgroundImage";

import "./articleHeader.scss";

const ArticleHeader = (props) => {
  const {
    title,
    description,
    backgroundImageStyle,
    tag,
    articleCount,
    infoSection,
  } = props;

  return (
    <div className="article-header">
      <Row>
        <Col sm={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
          <div className="d-flex">
            {tag}
            {articleCount !== null && (
              <div className="article-header__article-count">
                <FormattedMessage
                  id="article_header.article_count"
                  values={{ articleCount: parseInt(articleCount, 10) }}
                />
              </div>
            )}
          </div>
          <h1 className="typo-h2">{title}</h1>
          <p className="article-header__description typo-h4">{description}</p>
        </Col>
      </Row>
      {backgroundImageStyle && (
        <BackgroundImage
          className="container--fullwidth article-header__image-container"
          backgroundImageStyle={backgroundImageStyle}
          placeholderTyp="news"
        />
      )}
      {infoSection}
    </div>
  );
};

ArticleHeader.propTypes = {
  articleCount: PropTypes.number,
  backgroundImageStyle: PropTypes.oneOfType([PropTypes.object]),
  description: PropTypes.string.isRequired,
  infoSection: PropTypes.node,
  tag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  title: PropTypes.string.isRequired,
};

ArticleHeader.defaultProps = {
  articleCount: null,
  backgroundImageStyle: null,
  infoSection: null,
  tag: null,
};

export default ArticleHeader;
