import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Swiper from "react-id-swiper";

import "./learnEmbedModule.scss";

const LearnEmbedModule = (props) => {
  const {
    headline,
    children,
    buttonLearnArea,
    buttonDonorArea,
    subHeadlineDonorArea,
  } = props;

  const childrenArray = React.Children.toArray(children);

  const colProps = {
    lg: 4,
    sm: 6,
    xs: childrenArray.length === 1 ? 12 : 10,
  };

  const swiperProps = {
    breakpoints: {
      0: {
        allowTouchMove: true,
        slidesPerView: "auto",
      },
      576: {
        allowTouchMove: true,
        slidesPerView: 2,
      },
      992: {
        allowTouchMove: false,
        slidesPerView: 3,
      },
    },
    keyboard: true,
    mousewheel: {
      forceToAxis: true,
    },
    shouldSwiperUpdate: true,
  };

  return (
    <div className="container--fullwidth learn-module">
      <Container fluid>
        <h3>{headline}</h3>
        <div className="learn-module__content">
          <Row>
            <Swiper
              {...swiperProps}
            >
              {React.Children.map(children, (child, index) => (
                <Col
                  {...colProps}
                  key={`learn-module-item${index.toString()}`}
                >
                  <div className="learn-module__content-item">{child}</div>
                </Col>
              ))}
            </Swiper>
          </Row>
        </div>
        <div className="learn-module__button-container">
          <div className="align-self-sm-center">
            {buttonLearnArea}
          </div>
          <div className="learn-module__donor-button">
            <div className="typo-small-label">{subHeadlineDonorArea}</div>
            {buttonDonorArea}
          </div>
        </div>
      </Container>
    </div>
  );
};

LearnEmbedModule.propTypes = {
  buttonDonorArea: PropTypes.node.isRequired,
  buttonLearnArea: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
  subHeadlineDonorArea: PropTypes.string.isRequired,
};

export default LearnEmbedModule;
