import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import IconProcessStep from "../components/iconProcess/iconProcessStep";
import iconHelper from "../utils/iconHelper";

const KontentItemProcessStep = ({ data }) => {
  const processSteps = Array.isArray(data) ? data : data.value;

  const stepList = [];

  processSteps.forEach((step) => {
    let icon = null;

    if (step.elements.icon_upload.value.length > 0) {
      icon = (
        <KontentAssetsImage
          data={step.elements.icon_upload.value[0]}
          width={30}
          aspectRatio={1}
        />
      );
    } else if (step.elements.icon.value.length > 0) {
      icon = iconHelper(step.elements.icon.value[0].codename);
    }

    stepList.push(
      <IconProcessStep
        icon={icon}
        label={step.elements.headline.value}
        text={step.elements.text.value}
      />,
    );
  });

  return React.Children.toArray(stepList);
};

export const query = graphql`
  fragment KontentItemProcessStep on kontent_item_process_step {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      icon {
        value {
          codename
        }
      }
      icon_upload {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
    }
  }
`;

KontentItemProcessStep.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemProcessStep;
