import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import SubNavPoint from "../subNavPoint/subNavPoint";

const SspNavigation = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      sspNavigation: kontentItemNavigationItem(system: { codename: { eq: "ssp_navigation" } } ) {
        id
        elements {
          elements {
            value {
              id
              typename: __typename
              preferred_language
              ... on kontent_item_navigation_item {
                id
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                  icon {
                    value {
                      codename
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const sspNavigation = [];

  if (data.sspNavigation) {
    data.sspNavigation?.elements?.elements?.value.forEach((item) => {
      sspNavigation.push(
        <SubNavPoint
          {...item}
          className={className}
          key={item?.elements?.title.value}
        >
          {item.children}
        </SubNavPoint>,
      );
    });
  }

  return sspNavigation;
};

SspNavigation.propTypes = {
  className: PropTypes.string,
};

SspNavigation.defaultProps = {
  className: null,
};

export default SspNavigation;
