import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ImageDownloads from "./pressAndMediaAssets/ImageDownloads";
import AssetsSection from "../components/assetsSection/assetsSection";

const KontentItemImageLibrarySection = ({ data }) => {
  const imageLibrarySection = data.value ? data.value[0] : data;

  if (!imageLibrarySection || !imageLibrarySection.elements) {
    return null;
  }

  return (
    <AssetsSection
      headline={imageLibrarySection.elements.headline.value}
      description={imageLibrarySection.elements.description.value}
      variant="virtual-drive-image"
    >
      {ImageDownloads({ data: imageLibrarySection.elements.images })}
    </AssetsSection>
  );
};

export const query = graphql`
  fragment KontentItemImageLibrarySection on kontent_item_image_library_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      description {
        value
      }
      images {
        value {
          ...ImageDownloads
        }
      }
    }
  } 
`;

KontentItemImageLibrarySection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemImageLibrarySection;
