import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";

import { isRequired } from "../../components/dynamicForm/dynamicFormUtils";
import FormField from "../../components/dynamicForm/formField/formField";
import Tooltip from "../../components/localForms/tooltip";

const KontentItemInputTypeAddress = (props) => {
  const {
    data,
    errors,
    touched,
    meta,
    onChange,
    onBlur,
    values,
  } = props;

  const { elements } = data;

  return (
    <>
      <Col xs={12} md={6}>
        <Tooltip
          id={elements.id_street.value}
          tooltipMessage={elements.tooltip_street.value}
        >
          <FormField
            id={elements.id_street.value}
            type="text"
            errors={errors}
            touched={touched}
            meta={meta}
            label={elements.label_street.value}
            required={isRequired("id_street", elements.required.value)}
            onChange={onChange}
            onBlur={onBlur}
            values={values}
          />
        </Tooltip>
      </Col>
      <Col xs={12} md={6}>
        <Tooltip
          id={elements.id_street2.value}
          tooltipMessage={elements.tooltip_street2.value}
        >
          <FormField
            id={elements.id_street2.value}
            type="text"
            errors={errors}
            touched={touched}
            meta={meta}
            label={elements.label_street2.value}
            required={isRequired("id_street2", elements.required.value)}
            onChange={onChange}
            onBlur={onBlur}
            values={values}
          />
        </Tooltip>
      </Col>
      <Col xs={12} md={6}>
        <Tooltip
          id={elements.id_city.value}
          tooltipMessage={elements.tooltip_city.value}
        >
          <FormField
            id={elements.id_city.value}
            type="text"
            errors={errors}
            touched={touched}
            meta={meta}
            label={elements.label_city.value}
            required={isRequired("id_city", elements.required.value)}
            onChange={onChange}
            onBlur={onBlur}
            values={values}
          />
        </Tooltip>
      </Col>
      <Col xs={12} md={6}>
        <Tooltip
          id={elements.id_postcode.value}
          tooltipMessage={elements.tooltip_postcode.value}
        >
          <FormField
            id={elements.id_postcode.value}
            type="text"
            errors={errors}
            touched={touched}
            meta={meta}
            label={elements.label_postcode.value}
            required={isRequired("id_postcode", elements.required.value)}
            onChange={onChange}
            onBlur={onBlur}
            values={values}
          />
        </Tooltip>
      </Col>
    </>
  );
};

export const query = graphql`
  fragment KontentItemInputTypeAddress on kontent_item_input_type_address {
    id
    elements {
      id_street {
        value
      }
      id_street2 {
        value
      }
      id_postcode {
        value
      }
      id_city {
        value
      }
      label_city {
        value
      }
      label_postcode {
        value
      }
      label_street {
        value
      }
      label_street2 {
        value
      }
      required {
        value {
          codename
        }
      }
      tooltip_street {
        value
      }
      tooltip_city {
        value
      }
      tooltip_postcode {
        value
      }
      tooltip_street2 {
        value
      }
    }    
  } 
`;

KontentItemInputTypeAddress.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemInputTypeAddress;
