import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import SocialMediaShare from "../socialMediaShare/socialMediaShare";

import "./thankYouHeader.scss";

const ThankYouHeader = (props) => {
  const {
    headline,
    description,
    linkButton,
    shareText,
    icon,
    image,
    variant,
  } = props;

  let colPropsMedia = {
    lg: { offset: 1, span: 4 },
  };

  let colPropsContent = {
    lg: 7,
  };

  if (image?.type?.type?.name?.toLowerCase()?.indexOf("kontentitemimage") !== -1) {
    colPropsContent = {
      lg: 5,
    };

    colPropsMedia = {
      lg: { offset: 1, span: 6 },
    };
  }

  return (
    <Row
      className={classNames(
        "thank-you-header",
        { [`thank-you-header--${variant}`]: variant },
      )}
    >
      <Col {...colPropsContent} className="align-self-center">
        {icon && (
          <div className="thank-you-header__icon">
            {icon}
          </div>
        )}
        <h2>{headline}</h2>
        <div className="thank-you-header__description">
          {description}
        </div>
        {linkButton}
        {shareText && (
          <SocialMediaShare
            shareTitle={shareText}
            copyUrl
          />
        )}
      </Col>
      <Col {...colPropsMedia} className="align-self-center">
        {image}
      </Col>
    </Row>
  );
};

ThankYouHeader.propTypes = {
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  headline: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]),
  image: PropTypes.oneOfType([PropTypes.object]),
  linkButton: PropTypes.oneOfType([PropTypes.object]),
  shareText: PropTypes.string,
  variant: PropTypes.oneOf(["donation-payment-thanks"]),
};

ThankYouHeader.defaultProps = {
  icon: null,
  image: null,
  linkButton: null,
  shareText: null,
  variant: null,
};

export default ThankYouHeader;
