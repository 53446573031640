import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import InfoEmbedSection from "../infoEmbed/infoEmbedSection";

import "./donationAccount.scss";

const DonationAccount = (props) => {
  const {
    headline,
    iban,
    bic,
    bank,
    text,
    infoEmbed,
  } = props;

  if (!iban && !bic && !bank && !text) {
    return null;
  }

  let innerHtml = text;

  if (iban || bic || bank) {
    innerHtml = (
      <>
        {headline && (<h4>{headline}</h4>)}
        <div className="d-lg-flex flex-lg-wrap">
          {iban && (
            <div className="donation-account__item">
              <span><FormattedMessage id="money_donation_page.iban" /></span>
              {iban}
            </div>
          )}
          {bic && (
            <div className="donation-account__item">
              <span><FormattedMessage id="money_donation_page.bic" /></span>
              {bic}
            </div>
          )}
          {bank && (
            <div className="donation-account__item">
              <span><FormattedMessage id="money_donation_page.bank" /></span>
              {bank}
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="donation-account">
      {innerHtml}

      {infoEmbed && (
        <InfoEmbedSection>
          {infoEmbed}
        </InfoEmbedSection>
      )}
    </div>
  );
};

DonationAccount.propTypes = {
  bank: PropTypes.string,
  bic: PropTypes.string,
  headline: PropTypes.string,
  iban: PropTypes.string,
  infoEmbed: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  text: PropTypes.oneOfType([PropTypes.object]),
};

DonationAccount.defaultProps = {
  bank: null,
  bic: null,
  headline: null,
  iban: null,
  infoEmbed: null,
  text: null,
};

export default DonationAccount;
