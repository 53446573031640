import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { IconError18 } from "../../icons";

import "./radioButtonList.scss";

const RadioButtonList = (props) => {
  const {
    id,
    label,
    inline,
    name,
    options,
    touched,
    errors,
    onChange,
    onBlur,
    setFieldValue,
    setFieldTouched,
    width,
    required,
    values,
  } = props;

  return (
    <div className={classNames("radio-button-list", { "radio-button-list--inline": inline })}>
      <div>
        {label && (
          <div
            className={classNames(
              "radio-button-list__label",
              { "required-sign": required },
            )}
            id={`${id}_label`}
            style={width > 0 ? { width } : {}}
          >
            {label}
          </div>
        )}
        <Form.Group className="radio-button-list__options">
          {options.map((item) => (
            <Form.Check
              key={item.id}
              type="radio"
              checked={values && values[name] === item.value}
              label={item.label}
              name={name}
              value={item.value}
              id={item.id}
              onChange={(e) => {
                setFieldValue(name, e.target.value);
                setFieldTouched(name, true);
                onChange(e);
              }}
              onBlur={onBlur}
              inline
              className={classNames("mb-0", errors[id] && touched[id] ? "is-invalid" : "", item.className ? item.className : "")}
            />
          ))}
        </Form.Group>
      </div>
      {errors[id] && touched[id] && (
        <Form.Text className="invalid-feedback">
          <IconError18 />{errors[id]}
        </Form.Text>
      )}
    </div>
  );
};

RadioButtonList.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  required: PropTypes.bool,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  width: PropTypes.number,
};

RadioButtonList.defaultProps = {
  inline: false,
  required: false,
  width: 0,
};

export default RadioButtonList;
