import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Initiator from "../../components/initiator/initiator";
import VirtualDriveTile from "../../components/virtualDriveTile/virtualDriveTile";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemImage from "../kontentItemImage";

const VirtualDriveCards = (props) => {
  const {
    data,
    isHighlight,
    virtualDriveCounter,
    showCounter,
  } = props;

  const drivesData = Array.isArray(data) ? data : data.value;

  const drivesList = [];

  drivesData.forEach((drive) => {
    if (drive.elements.vd_type.value.length > 0 && drive.elements.vd_type.value[0].codename === "normal") {
      let counter = [];

      if (drive.elements.urn_primary.value.length > 0 && virtualDriveCounter !== null) {
        counter = virtualDriveCounter.filter(
          (item) => item.urn === drive.elements.urn_primary.value,
        );
      }

      const blurImage = drive.elements.image_object_fit_contain.value.length > 0 && drive.elements.image_object_fit_contain.value[0].codename === "true";

      drivesList.push(
        <VirtualDriveTile
          registrations={counter.length > 0 ? counter[0].value : 0}
          maxRegistration={drive.elements.goal.value}
          image={(
            <KontentItemImage
              data={drive.elements.hero_image}
              aspectRatio={4 / 3}
              width={695}
              fit={blurImage ? "clip" : "crop"}
            />
          )}
          blurImage={blurImage}
          headline={drive.elements.hero_headline.value}
          href={drive.id}
          description={drive.elements.lead_text.value}
          initiator={(
            <Initiator
              firstName={drive.elements.name_first.value}
              lastName={drive.elements.name_last.value}
              image={drive.elements.image_initiator.value.length > 0
                ? (
                  <KontentAssetsImage
                    data={drive.elements.image_initiator.value[0]}
                    height={300}
                    width={300}
                    className="rounded-circle"
                  />
                )
                : null}
              alias={
                  drive.elements.name_alias.value
                  || drive.elements.name_company.value
                  || drive.elements.name_university.value
                }
            />
            )}
          highlight={isHighlight}
          showCounter={showCounter}
        />,
      );
    }
  });

  return React.Children.toArray(drivesList);
};

export const query = graphql`
  fragment VirtualDriveCards on kontent_item_virtual_drive {
    id
    system {
      codename
    }
    elements {
      hero_headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      vd_type {
        value {
          codename
        }
      }
      publishdate {
        value
      }
      image_initiator {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
      name_company {
        value
      }
      name_alias {
        value
      }
      name_first {
        value
      }
      name_last {
        value
      }
      name_university {
        value
      }
      lead_text {
        value
      }
      goal {
        value
      }
      urn_primary {
        value
      }
      image_object_fit_contain {
        value {
          codename
        }
      }
    }
  }
`;

VirtualDriveCards.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isHighlight: PropTypes.bool,
  showCounter: PropTypes.bool.isRequired,
  virtualDriveCounter: PropTypes.oneOfType([PropTypes.object]),
};

VirtualDriveCards.defaultProps = {
  isHighlight: false,
  virtualDriveCounter: null,
};

export default VirtualDriveCards;
