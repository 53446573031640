/* eslint-disable camelcase, sort-keys */
import * as Yup from "yup";

import { keepInTouchOptions, salutationUk } from "./general-form-data";
import { getOnPreventKeypress, RegexPatterns } from "../../dynamicForm/validators";
import { clean } from "../formBuilder";
import Validators from "../validations/validators";

const getDonateForm = (intl) => {
  const validators = new Validators(intl);
  const REQUIRED_ERROR = intl.formatMessage({ id: "form.required" });
  const salutations = salutationUk(intl);

  /* eslint-disable sort-keys */
  return [
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "donate_header",
        headline: "donate_form.amount.title",
        description: "donate_form.amount.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "6",
        singleLine: true,
      },
      tagName: "TextInput",
      props: {
        id: "amount",
        name: "amount",
        label: intl.formatMessage({ id: "amount" }),
        type: "number",
        required: true,
        maxLength: 20,
      },
      validation: {
        type: "string",
        validators: [
        ],
      },
      tooltipMessage: null,
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "donate_person_header",
        headline: "donate_form.person.title",
        description: "donate_form.person.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: true,
      },
      tagName: "SelectInput",
      props: {
        id: "salutation",
        name: "salutation",
        label: intl.formatMessage({ id: "registration.personal.salutation" }),
        type: "text",
        required: true,
      },
      options: salutations,
      validation: {
        type: "string",
        validators: [
          ...validators.get("", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "firstname",
        name: "firstname",
        label: intl.formatMessage({ id: "registration.personal.firstname" }),
        type: "text",
        required: true,
        maxLength: 40,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("firstname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "lastname",
        name: "lastname",
        label: intl.formatMessage({ id: "registration.personal.lastname" }),
        type: "text",
        required: true,
        maxLength: 100,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "prev_apartment",
        name: "prev_apartment",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        type: "text",
        required: false,
        maxLength: 100,
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "prev_house_name",
        name: "prev_house_name",
        label: intl.formatMessage({ id: "registration.address.houseName" }),
        type: "text",
        required: false,
        maxLength: 100,
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "4",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "house_no",
        name: "house_no",
        label: intl.formatMessage({ id: "registration.address.houseNo" }),
        type: "text",
        required: false,
        maxLength: 10,
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "8",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "prev_street",
        name: "prev_street",
        label: intl.formatMessage({ id: "registration.address.street" }),
        type: "text",
        required: true,
        maxLength: 50,
      },
      validation: {
        type: "string",
        validators: [
          {
            type: "when",
            params: ["address_unchanged", {
              is: false,
              then: Yup.string().required(REQUIRED_ERROR),
            }],
          },
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "city",
        name: "city",
        label: intl.formatMessage({ id: "registration.address.city" }),
        type: "text",
        required: true,
        maxLength: 50,
      },
      validation: {
        type: "string",
        validators: [
          {
            type: "when",
            params: ["address_unchanged", {
              is: false,
              then: Yup.string().required(REQUIRED_ERROR),
            }],
          },
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "county",
        name: "county",
        label: intl.formatMessage({ id: "registration.address.county" }),
        type: "text",
        required: false,
        maxLength: 100,
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "postcode",
        name: "postcode",
        label: intl.formatMessage({ id: "registration.address.postCode" }),
        type: "text",
        required: true,
        maxLength: 10,
      },
      validation: {
        type: "string",
        validators: [
          {
            type: "when",
            params: ["address_unchanged", {
              is: false,
              then: Yup.string().required(REQUIRED_ERROR),
            }],
          },
          ...validators.get("zipcode", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "other_details_header",
        headline: "other_details.title",
        description: "",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "email",
        name: "email",
        label: intl.formatMessage({ id: "registration.additional.email" }),
        type: "text",
        required: true,
        maxLength: 100,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("email", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "keep_in_touch_header",
        headline: "keep_in_touch.title",
        description: "keep_in_touch.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        singleLine: true,
      },
      tagName: "RadioButtonSection",
      props: {
        id: "keep_in_touch",
        notice: intl.formatMessage({ id: "keep_in_touch.notice" }),
        radioButtonLists: [
          {
            key: "keep_in_touch_email",
            inline: true,
            id: "keep_in_touch_email",
            label: intl.formatMessage({ id: "keep_in_touch.email" }),
            name: "keep_in_touch_email",
            required: true,
            options: keepInTouchOptions(intl, "email"),
            initialValue: "",
            validation: {
              type: "string",
              validators: [
                ...validators.get(null, true),
              ],
            },
          },
          {
            key: "keep_in_touch_telephone",
            inline: true,
            id: "keep_in_touch_telephone",
            label: intl.formatMessage({ id: "keep_in_touch.telephone" }),
            name: "keep_in_touch_telephone",
            required: true,
            options: keepInTouchOptions(intl, "telephone"),
            initialValue: "",
            validation: {
              type: "string",
              validators: [
                ...validators.get(null, true),
              ],
            },
          },
          {
            key: "keep_in_touch_text_message",
            inline: true,
            id: "keep_in_touch_text_message",
            label: intl.formatMessage({ id: "keep_in_touch.text_message" }),
            name: "keep_in_touch_text_message",
            required: true,
            options: keepInTouchOptions(intl, "text_message"),
            initialValue: "",
            validation: {
              type: "string",
              validators: [
                ...validators.get(null, true),
              ],
            },
          },
          {
            key: "keep_in_touch_post",
            inline: true,
            id: "keep_in_touch_post",
            label: intl.formatMessage({ id: "keep_in_touch.post" }),
            name: "keep_in_touch_post",
            required: true,
            options: keepInTouchOptions(intl, "post"),
            initialValue: "",
            validation: {
              type: "string",
              validators: [
                ...validators.get(null, true),
              ],
            },
          },
        ],
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get(null, false),
        ],
      },
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "data_protection_header",
        headline: "dataprotection.title",
        description: "dataprotection.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "CheckboxInput",
      props: {
        id: "privacy",
        name: "privacy",
        label: intl.formatMessage({ id: "registration.dataprotection.dataprotection" }),
        type: "checkbox",
        required: true,
        lg: true,
      },
      validation: {
        type: "bool",
        validators: [
          ...validators.get("requiredCheckbox", false),
        ],
      },
      initialValue: false,
    },
  ];
};

const getTitleGb = (salutation) => (["Mr.", "Mrs.", "Ms.", "Miss"].includes(salutation) ? salutation : "");

const getSalutationGb = (salutation) => (["Dr.", "Prof."].includes(salutation) ? salutation : "");

const generatePayload = (values) => {
  /* eslint-disable sort-keys */
  const payload = {
    person: {
      birthdate: values.birthdate,
      firstname: values.firstname,
      lastname: values.lastname,
      former_lastname: values.former_lastname,
      donor_id: values.donor_id,
      salutation: getTitleGb(values.salutation),
      title: getSalutationGb(values.salutation),
    },
    former_address: {
      apartment: values.prev_apartment,
      building: values.prev_house_name,
      houseno: values.prev_house_no,
      street: values.prev_street,
      city: values.prev_city,
      region: values.prev_county,
      zipcode: values.prev_postcode,
    },
    private_address: {
      apartment: values.current_apartment,
      building: values.current_house_name,
      houseno: values.current_house_no,
      street: values.current_street,
      city: values.current_city,
      region: values.current_county,
      zipcode: values.current_postcode,
    },
    private_contact: {
      mobile: values.mobile,
      phone: values.landline,
      email: values.email,
      email2: values.additional_email,
    },
    general_practitioner: {
      name: values.gp_name,
      city: values.gp_city,
      phone: values.gp_phone,
    },
    marketing_consent: {
      email: values.keep_in_touch_email,
      phone: values.keep_in_touch_telephone,
      message: values.keep_in_touch_text_message,
      post: values.keep_in_touch_post,
    },
    ciu_data: {
      validity_date: values.validity_date,
    },
  };

  return clean(payload);
};

export default { getDonateForm, generatePayload };
