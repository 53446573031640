import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import ArticleCard from "../../components/articleCard/articleCard";
import KontentItemImage from "../kontentItemImage";

const NewsCard = (props) => {
  const {
    data,
    currentFirst,
    articleMigration,
    limit,
    author,
  } = props;

  const intl = useIntl();

  let newsData = Array.isArray(data) ? data : data.value;

  if (articleMigration && articleMigration.length > 0) newsData = newsData.concat(articleMigration);

  if (currentFirst) {
    newsData = newsData.sort(
      (a, b) => new Date(b.elements.publishdate.value) - new Date(a.elements.publishdate.value),
    );
  }

  if (author) {
    newsData = newsData.filter((item) => (
      item.elements.author_name.value.length > 0
      && item.elements.author_name.value[0].codename === author));
  }

  const newsList = [];

  newsData.forEach((news) => {
    let image = null;
    let category;

    if (news.elements?.article_card_image?.value.length > 0) {
      image = news.elements.article_card_image;
    } else if (news.elements?.hero_image?.value.length > 0) {
      image = news.elements.hero_image;
    }

    category = news.elements?.news_category?.value.length > 0
      ? news.elements.news_category.value[0].name
      : null;

    // Press Release Cards
    if (news?.system?.type === "article_press") {
      category = intl.formatMessage({ id: "article_teaser.press_release" });

      if (news.elements?.image?.value.length > 0) {
        image = news.elements.image;
      }
    }

    newsList.push(
      <ArticleCard
        image={image
          ? (
            <KontentItemImage
              data={image}
              width={400}
              aspectRatio={400 / 225}
              fit="crop"
            />
          )
          : null}
        category={category}
        date={new Date(news.elements.publishdate.value)}
        headline={news.elements.headline.value}
        href={news.id}
        excerpt={news.elements.lead_text.value}
      />,
    );
  });

  if (limit) {
    return React.Children.toArray(newsList.slice(0, limit));
  }

  return React.Children.toArray(newsList);
};

export const query = graphql`
  fragment NewsCard on kontent_item_article_page_news {
    id
    system {
      codename
    }
    elements {
      author_name {
        value {
          codename
        }
      }
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      lead_text {
        value
      }
      news_category {
        value {
          codename
          name
        }
      }
      publishdate {
        value
      }
      article_card_image {
        value {
          ...KontentItemImage
        }
      }
    }
  }

  fragment NewsCardMigrated on kontent_item_article_migration {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      lead_text {
        value
      }
      news_category {
        value {
          codename
          name
        }
      }
      publishdate {
        value
      }
    }
  }
  
  fragment NewsCardPress on kontent_item_article_press {
    id
    system {
      codename
      type
    }
    elements {
      headline {
        value
      }
      image {
        value {
          ...KontentItemImage
        }
      }
      lead_text {
        value
      }
      publishdate {
        value
      }
      news_category {
        value {
          codename
          name
        }
      }
    }
  }
`;

NewsCard.propTypes = {
  articleMigration: PropTypes.oneOfType([PropTypes.array]),
  author: PropTypes.string,
  currentFirst: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  limit: PropTypes.number,
};

NewsCard.propTypes = {
  articleMigration: null,
  author: null,
  currentFirst: false,
  limit: null,
};

export default NewsCard;
