import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";

import "./styledTab.scss";

const StyledTab = (props) => {
  const {
    icon,
    title,
    eventKey,
    active,
    className,
  } = props;

  return (
    <NavItem className={classNames("styled-tab", className)}>
      <NavLink eventKey={eventKey} className={classNames({ active })}>
        <div className="styled-tab__content">
          {icon && (<div className="styled-tab__icon">{icon}</div>)}
          <div className="styled-tab__title">{title}</div>
        </div>
      </NavLink>
    </NavItem>
  );
};

StyledTab.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  eventKey: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
};

StyledTab.defaultProps = {
  active: false,
  className: undefined,
  eventKey: false,
};

export default StyledTab;
