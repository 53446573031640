import React, { memo, useEffect, useState } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import Button from "./button";
import IconButton from "./iconButton";
import LinkButton from "./linkButton";

const componentWhitelist = [Button, LinkButton, IconButton];

const TimeoutButton = (props) => {
  const {
    as,
    intlId,
    timeoutSeconds,
    iconOnly,
    icon,
    loading,
    disabled,
    onClick,
    ...buttonProps
  } = props;

  if (!componentWhitelist.includes(as)) {
    return null;
  }

  const TagName = as;

  const [retryCountdown, setRetryCountdown] = useState(timeoutSeconds);

  useEffect(() => {
    if (retryCountdown > 0) {
      setTimeout(() => {
        if (!loading) {
          setRetryCountdown(retryCountdown - 1);
        }
      }, 1000);
    }
  }, [retryCountdown, loading]);

  return (
    <TagName
      {...buttonProps}
      iconOnly={iconOnly}
      loading={loading}
      disabled={disabled || retryCountdown > 0}
      onClick={() => {
        onClick();
        setRetryCountdown(timeoutSeconds);
      }}
    >
      {!iconOnly && <FormattedMessage id={intlId} values={{ countdown: retryCountdown }} />}
      {icon}
    </TagName>
  );
};

TimeoutButton.propTypes = {
  as: PropTypes.oneOf(componentWhitelist),
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.object]),
  iconOnly: PropTypes.bool,
  intlId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  timeoutSeconds: PropTypes.number,
};

TimeoutButton.defaultProps = {
  as: Button,
  disabled: false,
  icon: null,
  iconOnly: false,
  loading: false,
  onClick: () => {},
  timeoutSeconds: 30,
};

export default memo(TimeoutButton);
