import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemCtaButton from "../kontentItemCtaButton";
import KontentItemFaqSection from "../kontentItemFaqSection";
import KontentItemIconCardSliderSection from "../kontentItemIconCardSliderSection";
import KontentItemKeyFactArea from "../kontentItemKeyFactArea";
import KontentItemProgramSliderSection from "../kontentItemProgramSliderSection";
import KontentItemRegistrationProcessSection from "../kontentItemRegistrationProcessSection";
import KontentItemRelatedLearnContentEmbed from "../kontentItemRelatedLearnContentEmbed";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";

const KontentItemRegisterPage = ({ data }) => {
  const { page } = data;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
        buttons={page.elements.button.value.map(
          (item) => (<KontentItemCtaButton key={item.id} data={item} />),
        )}
      >
        {page.elements.headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={page.id} lastItemAs="h1" />

      <KontentItemKeyFactArea data={page.elements.key_facts} />

      {page.elements.eyecatcher_registry.value.length > 0
        ? mapKontentItem(page.elements.eyecatcher_registry.value[0])
        : null}

      <KontentItemRegistrationProcessSection data={page.elements.registration_donation_process} />

      <KontentItemRelatedLearnContentEmbed data={page.elements.related_learn_content} />

      <KontentItemProgramSliderSection data={page.elements.programs_slider} />

      {page.elements.eyecatcher_registry_last.value.length > 0
        ? mapKontentItem(page.elements.eyecatcher_registry_last.value[0])
        : null}

      <KontentItemFaqSection data={page.elements.faq_registry} />

      <KontentItemIconCardSliderSection data={page.elements.icon_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemRegisterPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        key_facts {
          value {
            ...KontentItemKeyFactArea
          }
        }
        eyecatcher_registry {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcher
          }
        }
        related_learn_content {
          value {
            ...KontentItemRelatedLearnContentEmbed
          }
        }
        programs_slider {
          value {
            ...KontentItemProgramSliderSection
          }
        }
        icon_card_slider {
          value {
            ...KontentItemIconCardSliderSection
          }
        }
        eyecatcher_registry_last {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcher
          }
        }
        faq_registry {
          value {
            ...KontentItemFaqSection
          }
        }
        registration_donation_process {
          value {
            ...KontentItemRegistrationProcessSection
          }
        }
        button {
          value {
            ...KontentItemCtaButton
          }
        }
        headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
      }
    }
  }
`;

KontentItemRegisterPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemRegisterPage;
