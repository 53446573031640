import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import TitleRow from "../titleRow";

import "./learnArticleList.scss";

const LearnArticleList = ({ children, headline }) => (
  <>
    {headline && (
      <TitleRow>{headline}</TitleRow>
    )}
    <Row className="learn-article-list">
      {React.Children.map(children, (child) => (
        <Col sm={6} lg={4}>{child}</Col>
      ))}
    </Row>
  </>
);

LearnArticleList.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string,
};

LearnArticleList.defaultProps = {
  headline: null,
};

export default LearnArticleList;
