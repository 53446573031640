import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import KontentItemLocationCardsGroup from "./kontentItemLocationCardsGroup";
import ContentHeader from "../components/contentHeader/contentHeader";
import Map from "../components/map/map";
import EventTabList from "../components/tabList/eventTabList";
import LocationMapMarkerCreator from "../utils/locationMapMarkerCreator";

const KontentItemLocationsSection = ({ data }) => {
  const locationsSection = data.value ? data.value[0] : data;

  if (!locationsSection || !locationsSection.elements) {
    return null;
  }

  return (
    <div>
      <ContentHeader
        media={locationsSection.elements.image.value.length > 0
          ? (
            <KontentAssetsImage
              data={locationsSection.elements.image.value[0]}
              height={150}
            />
          )
          : null}
        description={locationsSection.elements.text.value}
        headline={locationsSection.elements.headline.value}
      />
      <EventTabList
        listContent={locationsSection.elements.location_cards_groups.value.map(
          (item, index) => (
            <KontentItemLocationCardsGroup
              key={`kilc-${index.toString()}`}
              data={item}
            />
          ),
        )}
        mapContent={(
          <div className="container--fullwidth">
            <Map
              markers={LocationMapMarkerCreator(
                locationsSection.elements.location_cards_groups?.value?.length > 0
                  ? locationsSection.elements.location_cards_groups.value
                  : [],
              )}
              variant="location"
            />
          </div>
        )}
      />
    </div>
  );
};

export const query = graphql`
  fragment KontentItemLocationsSection on kontent_item_locations_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      image {
        value {
          description
          width
          height
          name
          size
          type
          url
        }
      }
      location_cards_groups {
        value {
          ...KontentItemLocationCardsGroup
        }
      }
    }
  }
`;

KontentItemLocationsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLocationsSection;
