import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import "./contentHeader.scss";

const ContentHeader = (props) => {
  const {
    className,
    headline,
    description,
    media,
    variant,
    button,
    tag,
    socialMediaShare,
  } = props;

  const { width } = useWindowDimensions();

  let tmpMedia = media;

  if (media && variant === "image-embed" && width >= 992) {
    tmpMedia = React.cloneElement(media, { aspectRatio: 3 / 4 });
  }

  return (
    <div
      className={classNames(
        "container--fullwidth content-header",
        className,
        {
          [`content-header--${variant}`]: variant,
          "content-header-with-button": button !== null,
        },
      )}
    >
      <Container fluid>
        <Row className="content-header__content">
          {tmpMedia && (
            <Col xs={12} sm={6} lg={{ offset: 1, span: 4 }} className="order-lg-11">
              <div className="content-header__media">{tmpMedia}</div>
            </Col>
          )}
          <Col xs={12} lg={7}>
            {tag}
            <h1 className="content-header__headline">{headline}</h1>
            <div className={classNames(
              "content-header__description",
              { "typo-h4": variant !== "faq-detail" },
            )}
            >
              {description}
            </div>
            {button && (<div className="content-header__button">{button}</div>)}
          </Col>
          {socialMediaShare && (
            <Col xs={7} className="order-lg-last">
              {socialMediaShare}
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

ContentHeader.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  headline: PropTypes.string.isRequired,
  media: PropTypes.oneOfType([PropTypes.object]),
  socialMediaShare: PropTypes.oneOfType([PropTypes.object]),
  tag: PropTypes.oneOfType([PropTypes.object]),
  variant: PropTypes.oneOf(["faq", "thank-you", "video-embed", "image-embed", "faq-detail"]),
};

ContentHeader.defaultProps = {
  button: null,
  className: null,
  media: null,
  socialMediaShare: null,
  tag: null,
  variant: null,
};

export default ContentHeader;
