import React, { useEffect } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import slugify from "slugify";

import "./tableOfContents.scss";

const TableOfContents = () => {
  const [headings, setHeadings] = React.useState([]);

  const MIN_ENTRIES = 3;

  useEffect(() => {
    if (document) {
      const elements = Array
        .from(document.querySelectorAll("main h3"))
        .map((element) => (
          { id: element.id, title: element.textContent }
        ));

      document.querySelectorAll("main h3").forEach(
        (item) => item.setAttribute("id", slugify(item.textContent, { lower: true })),
      );

      setHeadings(elements);
    }
  }, []);

  if (headings && headings.length < MIN_ENTRIES) {
    return null;
  }

  const tmpAnchorPoints = [];

  headings.forEach((heading, index) => {
    tmpAnchorPoints.push(
      <li>
        <a className="anchor-button" href={`#${slugify(heading.title, { lower: true })}`} key={heading.id}>
          <span className="anchor-button__number">{index + 1}.</span>
          <span className="anchor-button__text">{heading.title}</span>
        </a>
      </li>,
    );
  });

  return (
    <div className="table-of-contents">
      <div className="table-of-contents__headline">
        <FormattedMessage id="table_of_contents.title" />
      </div>
      <ol className="table-of-contents__list">
        {tmpAnchorPoints}
      </ol>
    </div>
  );
};

export default TableOfContents;
