import React from "react";

import PropTypes from "prop-types";

import "./iconbox.scss";

const Iconbox = (props) => {
  const { children } = props;

  return <div className="iconbox">{children}</div>;
};

Iconbox.propTypes = {
  children: PropTypes.node,
};

Iconbox.defaultProps = {
  children: null,
};

export default Iconbox;
