import { graphql, useStaticQuery } from "gatsby";

import CampaignCard from "./campaignCard";

const LastFourCampaignCards = () => {
  const data = useStaticQuery(graphql`
    {
      campaigns: allKontentItemArticlePageCampaign(sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          ...CampaignCard
        }
      }
    }
  `);

  if (data.campaigns) {
    return CampaignCard({ data: data.campaigns.nodes.slice(0, 4) });
  }

  return null;
};

export default LastFourCampaignCards;
