import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { bool, object, string } from "yup";

import { setPaymentData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import Checkbox from "../../dynamicForm/checkbox/checkbox";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../../registrationDialog/registrationDataHelper";
import RegistrationDialogHeadline from "../../registrationDialog/registrationDialogHeadline";

const PaymentDialogStepBankAccountUk = ({ content, next }) => {
  const paymentData = useSelector((state) => state.registration.paymentData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const validationSchema = useMemo(() => object().shape({
    accountHolderName: string()
      .required(requiredMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    accountNumber: string()
      .max(8, intl.formatMessage(
        { id: "form.error.exact_digits_max" },
        {
          value: 8,
        },
      ))
      .matches(
        /^[0-9]{8}$/,
        intl.formatMessage({ id: "payment_dialog_error.account_number" }),
      )
      .required(requiredMessage),
    debitGuarantee: bool()
      .required(requiredMessage),
    sortCode: string()
      .max(6, intl.formatMessage(
        { id: "form.error.exact_digits_max" },
        {
          value: 6,
        },
      ))
      .matches(
        /^[0-9]{6}$/,
        intl.formatMessage({ id: "payment_dialog_error.sortcode" }),
      )
      .required(requiredMessage),
  }), []);

  return (
    <div className="payment-dialog__bank-account">
      <RegistrationDialogHeadline
        headline="payment_dialog.bankaccount.title"
      />
      <Formik
        initialValues={paymentData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setPaymentData({
              accountHolderName: values.accountHolderName,
              accountNumber: values.accountNumber,
              sortCode: values.sortCode,
            }),
          );

          DataLayer.pushEvent("pp_donation_step_bank_account", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="accountHolderName"
                  label={intl.formatMessage({ id: "payment_dialog.accountHolderName" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="accountNumber"
                  label={intl.formatMessage({ id: "payment_dialog.accountNumber" })}
                  type="text"
                  maxLength={8}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="sortCode"
                  label={intl.formatMessage({ id: "payment_dialog.sortCode" })}
                  type="text"
                  maxLength={6}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12}>
                <Checkbox
                  id="debitGuarantee"
                  name="debitGuarantee"
                  checked={values.debitGuarantee}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  isInvalid={(errors.debitGuarantee && touched.debitGuarantee)}
                  label={intl.formatMessage(
                    { id: "payment_dialog.debitGuarantee" },
                    {
                      link: content.debitGuarentee,
                    },
                  )}
                  errors={errors}
                  feedback={errors.debitGuarantee}
                  className="checkbox-required"
                  required
                />
              </Col>

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" variant="donation">
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>
                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

PaymentDialogStepBankAccountUk.stepName = "payment_bank_account";

PaymentDialogStepBankAccountUk.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default PaymentDialogStepBankAccountUk;
