import React from "react";

import classNames from "classnames";
import { Field } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { IconError18 } from "../../../icons";
import InputSelect from "../../inputSelect/inputSelect";

import "./dropdownFormField.scss";

const DropdownFormField = (props) => {
  const {
    id,
    clearable,
    label,
    options,
    errors,
    touched,
    meta,
    onChange,
    onBlur,
    otherSelected,
    placeholder,
    setFieldValue,
    values,
    defaultValue,
    required,
    additionalInvalidCondition,
    maxMenuHeight,
  } = props;

  const getOptionByValue = (items, value) => items.flatMap(
    (el) => (el.options ? el.options : el),
  ).find((el) => el.value === value);

  const intl = useIntl();

  return (
    <Field name={id}>
      {() => (
        <Form.Group
          className={classNames(
            {
              required: required || meta?.requiredFields?.includes(id),
            },
          )}
        >
          <InputSelect
            clearable={clearable}
            defaultValue={defaultValue}
            label={label}
            placeholder={placeholder || intl.formatMessage({ id: "form.please_select" })}
            inputId={id}
            name={id}
            items={options}
            value={getOptionByValue(options, values?.[id]) ? getOptionByValue(options, values?.[id]) : ""}
            onChange={(selectedOption) => {
              onChange({
                target: {
                  id,
                  name: id,
                  type: "",
                  value: selectedOption?.value || "",
                },
              });

              if (typeof otherSelected === "function") {
                otherSelected(selectedOption?.value || "", setFieldValue);
              }
            }}
            onBlur={onBlur}
            isInvalid={(errors[id] && touched[id])}
            maxMenuHeight={maxMenuHeight}
          />
          <Form.Label>
            {label}
          </Form.Label>
          {((errors[id] && touched[id]) || !required) && (
            <Form.Text
              className={classNames("d-block", { "invalid-feedback": (errors[id] && touched[id]) || additionalInvalidCondition })}
            >
              {errors[id] && touched[id]
                ? <div><IconError18 />{errors[id]}</div>
                : <>{!required && <FormattedMessage id="registration.optional" />}</>}
            </Form.Text>
          )}
        </Form.Group>
      )}
    </Field>
  );
};

DropdownFormField.propTypes = {
  additionalInvalidCondition: PropTypes.bool,
  clearable: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object]),
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxMenuHeight: PropTypes.number,
  meta: PropTypes.oneOfType([PropTypes.object]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  otherSelected: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  setFieldValue: PropTypes.func,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DropdownFormField.defaultProps = {
  additionalInvalidCondition: false,
  clearable: true,
  defaultValue: {},
  maxMenuHeight: 180,
  meta: null,
  otherSelected: () => {},
  placeholder: undefined,
  required: false,
  setFieldValue: () => {},
  values: null,
};

export default DropdownFormField;
