import React, { useMemo, useState } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFillForm24, IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import FormObserver from "../../../utils/formik/formObserver";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import AddressInput from "../../addressInput/addressInput";
import IconButton from "../../button/iconButton";
import CollapseBox from "../../collapseBox/collapseBox";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationResidentialAddressZa = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [addressShown, setAddressShown] = useState(registrationData.street !== "");
  const [deliveryAddressShown, setDeliveryAddressShown] = useState(registrationData.deliveryStreet !== "");

  const getValueByLabel = (options, label) => options.find((el) => el.label === label)?.value;

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const warning = useSelector((state) => state.registration.warning);

  let warningInfo;

  if (Object.keys(warning).length > 0) {
    warningInfo = RegistrationDataHelper.generateWarning(warning);
  }

  const validationSchema = useMemo(() => object().shape({
    apartment: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    city: string()
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .required(requiredMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    deliveryBuilding: string().nullable()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    deliveryCity: string()
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .nullable()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    deliveryHouseNo: string()
      .max(10, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 10,
        },
      ))
      .nullable()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    deliveryInstructions: string(),
    deliveryPostCode: string()
      .max(4, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 4,
        },
      ))
      .matches(
        /^[0-9]{4}$/,
        intl.formatMessage({ id: "form.error.zipcode" }, { value: 4 }),
      )
      .nullable(),
    deliveryProvince: string().nullable(),
    deliveryStreet: string()
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .nullable()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    deliverySuburb: string()
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .nullable()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    houseNo: string()
      .max(10, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 10,
        },
      ))
      .required(requiredMessage)
      .matches(
        /^[0-9]+[a-zA-Z0-9 \-/]*$/,
        intl.formatMessage({ id: "form.error.invalid_value" }),
      ),
    postCode: string()
      .required(requiredMessage)
      .max(4, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 4,
        },
      ))
      .matches(
        /^[0-9]{4}$/,
        intl.formatMessage({ id: "form.error.zipcode" }, { value: 4 }),
      ),
    province: string().required(requiredMessage),
    street: string()
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .required(requiredMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    suburb: string()
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .required(requiredMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.address.title"
        subheadline="registration.address.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          if (values.deliveryAddress) {
            if (!values.deliveryStreet) {
              errors.deliveryStreet = intl.formatMessage({ id: "form.required" });
            }

            if (!values.deliveryHouseNo) {
              errors.deliveryHouseNo = intl.formatMessage({ id: "form.required" });
            }

            if (!values.deliveryCity) {
              errors.deliveryCity = intl.formatMessage({ id: "form.required" });
            }

            if (!values.deliverySuburb) {
              errors.deliverySuburb = intl.formatMessage({ id: "form.required" });
            }

            if (!values.deliveryPostCode) {
              errors.deliveryPostCode = intl.formatMessage({ id: "form.required" });
            }

            if (!values.deliveryProvince) {
              errors.deliveryProvince = intl.formatMessage({ id: "form.required" });
            }
          }

          return errors;
        }}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              apartment: values.apartment,
              city: values.city,
              deliveryAddress: values.deliveryAddress,
              deliveryBuilding: values.deliveryBuilding,
              deliveryCity: values.deliveryCity,
              deliveryHouseNo: values.deliveryHouseNo,
              deliveryInstructions: values.deliveryInstructions,
              deliveryPostCode: values.deliveryPostCode,
              deliveryProvince: values.deliveryProvince,
              deliveryStreet: values.deliveryStreet,
              deliverySuburb: values.deliverySuburb,
              houseNo: values.houseNo,
              postCode: values.postCode,
              postalAddress: values.postalAddress,
              province: values.province,
              street: values.street,
              suburb: values.suburb,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_residential_address", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormObserver />
            <AddressInput
              onResult={(data) => {
                setAddressShown(true);

                setFieldValue("street", data.street || "");
                setFieldValue("houseNo", data.houseno || "");
                setFieldValue("postCode", data.zipcode || "");
                setFieldValue("city", data.city || "");
                setFieldValue("suburb", data.district || "");
                setFieldValue("province", getValueByLabel(
                  RegistrationDataHelper.provinceOptionsZa, data.state,
                ) || "");
              }}
            />

            <CollapseBox
              opened={addressShown}
              label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
              icon={<IconFillForm24 />}
              warning={Object.keys(warning).length > 0}
            >
              <Row>
                <Col xs={12}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="apartment"
                    label={intl.formatMessage({ id: "registration.address.apartment" })}
                    type="text"
                    warning={warning}
                    maxLength={100}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={8}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="street"
                    label={intl.formatMessage({ id: "registration.address.street" })}
                    type="text"
                    warning={warning}
                    maxLength={50}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={4}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="houseNo"
                    label={intl.formatMessage({ id: "registration.address.houseNo" })}
                    type="text"
                    warning={warning}
                    maxLength={10}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col xs={12}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="suburb"
                    label={intl.formatMessage({ id: "registration.address.suburb" })}
                    type="text"
                    warning={warning}
                    maxLength={50}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={8}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="city"
                    label={intl.formatMessage({ id: "registration.address.city" })}
                    type="text"
                    maxLength={50}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={4}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="postCode"
                    label={intl.formatMessage({ id: "registration.address.postCode" })}
                    type="text"
                    warning={warning}
                    maxLength={10}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={12} className="mb-0">
                  <DropdownFormField
                    label={intl.formatMessage({ id: "registration.address.province" })}
                    placeholder={intl.formatMessage({ id: "registration.please_select" })}
                    onChange={(selectedOption) => {
                      const { target } = selectedOption;

                      if (target && target.value) {
                        setFieldValue("province", target.value);
                      } else {
                        setFieldValue("province", "");
                      }
                    }}
                    onBlur={() => {
                      setFieldTouched("province", true);
                    }}
                    touched={touched}
                    id="province"
                    errors={errors}
                    values={values}
                    options={RegistrationDataHelper.provinceOptionsZa}
                    required
                  />
                </Col>
                <Col xs={12} className="warning-info">
                  {warningInfo}
                </Col>
              </Row>
            </CollapseBox>

            <Form.Group>
              <Form.Check
                type="checkbox"
                id="deliveryAddress"
                name="deliveryAddress"
                className="checkbox-body1"
                checked={values.deliveryAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={(errors.deliveryAddress && touched.deliveryAddress)}
                label={intl.formatMessage({ id: "registration.address.delivery_address" })}
              />
            </Form.Group>

            {values.deliveryAddress && (
              <>
                <AddressInput
                  onResult={(data) => {
                    setDeliveryAddressShown(true);

                    setFieldValue("deliveryStreet", data.street || "");
                    setFieldValue("deliveryHouseNo", data.houseno || "");
                    setFieldValue("deliveryPostCode", data.zipcode || "");
                    setFieldValue("deliveryCity", data.city || "");
                    setFieldValue("deliverySuburb", data.suburb || "");
                    setFieldValue("deliveryProvince", getValueByLabel(
                      RegistrationDataHelper.provinceOptionsZa, data.state,
                    ) || "");
                  }}
                />

                <CollapseBox
                  opened={deliveryAddressShown}
                  label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
                  icon={<IconFillForm24 />}
                >
                  <Row>
                    <Col xs={12}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="deliveryBuilding"
                        label={intl.formatMessage({ id: "registration.address.delivery_building" })}
                        type="text"
                        maxLength={100}
                        touched={touched}
                        errors={errors}
                        values={values}
                      />
                    </Col>
                    <Col md={6}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="deliveryStreet"
                        label={intl.formatMessage({ id: "registration.address.street" })}
                        type="text"
                        maxLength={50}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="deliveryHouseNo"
                        label={intl.formatMessage({ id: "registration.address.delivery_housno" })}
                        type="text"
                        maxLength={10}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col xs={12}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="deliverySuburb"
                        label={intl.formatMessage({ id: "registration.address.suburb" })}
                        type="text"
                        maxLength={50}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col md={8}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="deliveryCity"
                        label={intl.formatMessage({ id: "registration.address.city" })}
                        type="text"
                        maxLength={50}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="deliveryPostCode"
                        label={intl.formatMessage({ id: "registration.address.postCode" })}
                        type="text"
                        maxLength={10}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col md={12} className="mb-0">
                      <DropdownFormField
                        label={intl.formatMessage({ id: "registration.address.province" })}
                        placeholder={intl.formatMessage({ id: "registration.please_select" })}
                        onChange={(selectedOption) => {
                          const { target } = selectedOption;

                          if (target && target.value) {
                            setFieldValue("deliveryProvince", target.value);
                          } else {
                            setFieldValue("deliveryProvince", "");
                          }
                        }}
                        onBlur={() => {
                          setFieldTouched("deliveryProvince", true);
                        }}
                        touched={touched}
                        id="deliveryProvince"
                        errors={errors}
                        values={values}
                        options={RegistrationDataHelper.provinceOptionsZa}
                        required
                      />
                    </Col>
                  </Row>
                </CollapseBox>
              </>
            )}

            <div className="registration-dialog__subheader">
              <FormattedMessage id="registration.address.delivery_instructions" />
            </div>

            <DropdownFormField
              label={intl.formatMessage({ id: "form.selection" })}
              placeholder={intl.formatMessage({ id: "form.selection" })}
              onChange={(selectedOption) => {
                const { target } = selectedOption;

                if (target && target.value) {
                  setFieldValue("deliveryInstructions", target.value);
                } else {
                  setFieldValue("deliveryInstructions", "");
                }
              }}
              onBlur={() => {
                setFieldTouched("deliveryInstructions", true);
              }}
              touched={touched}
              id="deliveryInstructions"
              errors={errors}
              values={values}
              options={RegistrationDataHelper.deliveryInstructionsZa}
            />

            <ScrollToFieldError />

            <Form.Group>
              <IconButton
                type="submit"
                disabled={isSubmitting}
                onClick={() => {
                  setAddressShown(true);
                }}
              >
                <FormattedMessage id="registration.address.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>

            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationResidentialAddressZa.stepName = "registration_residential_address";

RegistrationDialogStepRegistrationResidentialAddressZa.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationResidentialAddressZa;
