import React, { useMemo, useState } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setPaymentData } from "../../../features/registrationSlice";
import { IconFillForm24, IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import AddressInput from "../../addressInput/addressInput";
import IconButton from "../../button/iconButton";
import CollapseBox from "../../collapseBox/collapseBox";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../../registrationDialog/registrationDataHelper";
import RegistrationDialogHeadline from "../../registrationDialog/registrationDialogHeadline";

const PaymentDialogStepContactInformationIn = ({ next }) => {
  const paymentData = useSelector((state) => state.registration.paymentData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [addressShown, setAddressShown] = useState(paymentData.street !== "");

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const emailMessage = intl.formatMessage({ id: "registration.additional.error.email" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const validationSchema = useMemo(() => object().shape({
    apartment: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    careOfAddress: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    city: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 50 },
      ))
      .trim()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    county: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    emailAddress: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage),
    houseName: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    houseNo: string()
      .max(10, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 10 },
      )),
    locality: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .trim()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    mobileNumber: string()
      .required(requiredMessage)
      .matches(
        /^[\d]{10}$/,
        intl.formatMessage({ id: "form.error.mobile" }),
      ),
    postCode: string()
      .required(requiredMessage)
      .matches(/^\d{6}$/, intl.formatMessage({ id: "form.error.zipcode" })),
    state: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      )),
    street: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 50 },
      ))
      .trim()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline={intl.formatMessage({ id: "payment_dialog.contact_information.title" })}
      />
      <Formik
        initialValues={paymentData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setPaymentData({
              apartment: values.apartment,
              city: values.city,
              county: values.county,
              emailAddress: values.emailAddress,
              houseName: values.houseName,
              houseNo: values.houseNo,
              locality: values.locality,
              mobileNumber: values.mobileNumber,
              postCode: values.postCode,
              state: values.state,
              street: values.street,
            }),
          );

          DataLayer.pushEvent("pp_donation_step_contact_information", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          setFieldError,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="mobileNumber"
                  label={intl.formatMessage({ id: "registration.additional.mobile_number" })}
                  type="text"
                  maxLength={10}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="emailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12}>
                <AddressInput
                  onResult={(data) => {
                    setAddressShown(true);

                    const tmpStateCode = data.state_code === "CG" ? "CT" : data.state_code;

                    setFieldValue("street", data.street || "");
                    setFieldValue("houseNo", data.houseno || "");
                    setFieldValue("postCode", data.zipcode || "");
                    setFieldValue("city", data.city || "");
                    setFieldValue("state", (tmpStateCode && (RegistrationDataHelper.stateOptionsIn.find((el) => el.value === tmpStateCode)?.value || "OTHER")) || "");
                    setFieldValue("county", data.district || "");
                    setFieldValue("locality", data.region || "");
                  }}
                />

                <CollapseBox
                  opened={addressShown}
                  label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
                  icon={<IconFillForm24 />}
                >
                  <Row>
                    <Col md={6}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="careOfAddress"
                        label={intl.formatMessage({ id: "registration.address.care_of_address" })}
                        type="text"
                        maxLength={100}
                        touched={touched}
                        errors={errors}
                        values={values}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="apartment"
                        label={intl.formatMessage({ id: "registration.address.apartment" })}
                        type="text"
                        maxLength={100}
                        touched={touched}
                        errors={errors}
                        values={values}
                      />
                    </Col>
                    <Col md={6}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="houseName"
                        label={intl.formatMessage({ id: "registration.address.housename" })}
                        type="text"
                        maxLength={100}
                        touched={touched}
                        errors={errors}
                        values={values}
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="houseNo"
                        label={intl.formatMessage({ id: "registration.address.houseno" })}
                        type="text"
                        maxLength={10}
                        touched={touched}
                        errors={errors}
                        values={values}
                      />
                    </Col>
                    <Col md={8}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="street"
                        label={intl.formatMessage({ id: "registration.address.street" })}
                        type="text"
                        maxLength={50}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="locality"
                        label={intl.formatMessage({ id: "registration.address.locality" })}
                        type="text"
                        maxLength={50}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="city"
                        label={intl.formatMessage({ id: "registration.address.city" })}
                        type="text"
                        maxLength={50}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="postCode"
                        label={intl.formatMessage({ id: "registration.address.postCode" })}
                        type="text"
                        maxLength={6}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="county"
                        label={intl.formatMessage({ id: "registration.address.county" })}
                        type="text"
                        maxLength={6}
                        touched={touched}
                        errors={errors}
                        values={values}
                      />
                    </Col>
                    <Col md={6} className="mb-0">
                      <DropdownFormField
                        label={intl.formatMessage({ id: "registration.address.state" })}
                        placeholder={intl.formatMessage({ id: "registration.please_select" })}
                        onChange={(selectedOption) => {
                          const { target } = selectedOption;

                          if (target && target.value) {
                            setFieldValue("state", target.value);
                            setFieldError("state", undefined);
                          } else {
                            setFieldValue("state", "");
                          }
                        }}
                        onBlur={() => {
                          setFieldTouched("state", true);
                        }}
                        touched={touched}
                        id="state"
                        errors={errors}
                        values={values}
                        options={RegistrationDataHelper.stateOptionsIn}
                        required
                        maxMenuHeight={300}
                      />
                    </Col>
                  </Row>
                </CollapseBox>
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton
                    type="submit"
                    variant="donation"
                    onClick={() => setAddressShown(true)}
                  >
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>
                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

PaymentDialogStepContactInformationIn.stepName = "contact_information";

PaymentDialogStepContactInformationIn.propTypes = {
  next: PropTypes.func.isRequired,
};

export default PaymentDialogStepContactInformationIn;
