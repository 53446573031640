import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import FilterDropdown from "../../components/filterDropown/filterDropdown";
import IntroSection from "../../components/introSection/introSection";
import QuickLinks from "../../components/quickLinks/quickLinks";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemHeroCarouselPress from "../kontentItemHeroCarouselPress";
import KontentItemRelatedNewsSection from "../kontentItemRelatedNewsSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import PressReleaseTeaserCard from "../press/pressReleaseTeaserCard";
import QuickLinkTiles from "../press/quickLinkTiles";

const KontentItemPressOverviewPage = ({ data }) => {
  const {
    page,
  } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <KontentItemHeroCarouselPress data={page.elements.hero_carousel} />

      <BreadcrumbGenerator id={page.id} />

      <IntroSection
        headline={page.elements.headline.value}
        text={page.elements.lead_text.value}
        headlineAs="h1"
      />

      <QuickLinks>
        {QuickLinkTiles({ data: page.elements.quicklinks })}
      </QuickLinks>

      <FilterDropdown variant="article-teaser">
        {PressReleaseTeaserCard({ data: data.relatedArticles.nodes })}
      </FilterDropdown>

      {page.elements.eyecatcher_contact.value.length > 0
        ? mapKontentItem(page.elements.eyecatcher_contact.value[0])
        : null}

      <KontentItemRelatedNewsSection data={page.elements.related_stories} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    stories: allKontentItemArticlePageNews {
      nodes {
        ...NewsCard
      }
    }
    relatedArticles: allKontentItemArticlePress(sort: {fields: elements___publishdate___value, order: DESC}) {
      nodes {
        ...PressReleaseTeaserCard
      }
    }
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemPressOverviewPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        hero_carousel {
          value {
            ...KontentItemHeroCarouselPress
          }
        }
        quicklinks {
          value {
            ...QuickLinkTiles
          }
        }
        eyecatcher_contact {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherContact
            ...KontentItemEyecatcher
          }
        }
        related_stories {
          value {
            ...KontentItemRelatedNewsSection
          }
        }
      }
    }
  }
`;

KontentItemPressOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemPressOverviewPage;
