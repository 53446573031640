import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import IconButton from "../../components/button/iconButton";
import HeroCarouselCard from "../../components/heroCarousel/heroCarouselCard";
import { IconFurther18 } from "../../icons";
import backgroundImageHelper from "../image/backgroundImageHelper";

const CampaignHeroCarouselCards = ({ data, buttonText }) => {
  const campaignData = Array.isArray(data) ? data : data.value;

  const campaigns = [];

  campaignData.forEach((campaign) => {
    campaigns.push(
      <HeroCarouselCard
        backgroundImageStyle={backgroundImageHelper(campaign.elements.hero_image)}
        button={(
          <IconButton variant="inverted-light" href={campaign.id}>
            {buttonText}<IconFurther18 />
          </IconButton>
        )}
        menuTitle={campaign.elements.hero_headline.value}
        date={new Date(campaign.elements.publishdate.value)}
        isCampaign
      >
        {campaign.elements.hero_headline.value}
      </HeroCarouselCard>,
    );
  });

  return React.Children.toArray(campaigns);
};

export const query = graphql`
  fragment CampaignHeroCarouselCards on kontent_item_article_page_campaign {
    id
    system {
      codename
    }
    elements {
        hero_headline {
            value
        }
        publishdate {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
    }
  }
`;

CampaignHeroCarouselCards.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

CampaignHeroCarouselCards.defaultProps = {
  buttonText: null,
};

export default CampaignHeroCarouselCards;
