/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react";

import PiwikPro, { PageViews } from "@piwikpro/react-piwik-pro";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import BrowserDetection from "./src/components/browserDetection";
import PreviewStatusWidget from "./src/components/previewStatusWidget/previewStatusWidget";
import Store from "./src/state/store";
import UrlParamListener from "./src/templates/utils/urlParamListener";

import "./src/styles/style.scss";

let lastTitle = "";

export const onRouteUpdate = ({ location }) => {
  if (process.env.GATSBY_PIWIK_SITE_ID) {
    // document.title workaround stolen from:
    // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js
    const sendPageView = () => {
      const { title } = document;

      if (lastTitle !== title) {
        lastTitle = title;
        PageViews.trackPageView(location);
      }
    };

    // Minimum delay for reactHelmet's requestAnimationFrame
    const delay = Math.max(500, 0);
    setTimeout(sendPageView, delay);
  }

  return null;
};

export const wrapRootElement = ({ element }) => {
  const { store, persistor } = Store();

  if (typeof window === "undefined") {
    return <Provider store={store}>{element}</Provider>;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
};

export const wrapPageElement = ({ element, props }) => (
  <>
    <UrlParamListener {...props} />

    {element}

    {typeof previewWidget !== "undefined" && previewWidget.PREVIEW && previewWidget.SITE_ID && (
      <PreviewStatusWidget />
    )}
  </>
);

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location?.hash) {
    try {
      let offset = 0;
      const mainNavigation = document.getElementById("navigation-container");
      const isTouchScreen = BrowserDetection.hasTouchScreen();

      // offset for sticky navigation
      if (mainNavigation.classList.contains("fixed-top") && !isTouchScreen) {
        offset = mainNavigation.getBoundingClientRect().height;
      }

      window.scrollTo({
        behavior: "smooth",
        top:
          document.getElementById(location.hash.substring(1)).getBoundingClientRect().top
          - document.body.getBoundingClientRect().top
          - offset,
      });
    } catch (e) {
      //
    }

    return false;
  }

  if (!location || !location.state) {
    return true;
  }

  const { disableScrollUpdate } = location.state;

  return !disableScrollUpdate;
};

export const onClientEntry = () => {
  if (process.env.GATSBY_PIWIK_SITE_ID) {
    PiwikPro.initialize(process.env.GATSBY_PIWIK_SITE_ID, "https://dkms.containers.piwik.pro");
  }
};
