import React, { memo } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

const StemCellBankLogo = () => {
  const data = useStaticQuery(graphql`
    {
      bigLogo: file(name: {regex: "/a-logo-dkms-scb-l/"}, ext: {eq: ".png"}) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 30, quality: 100)
        }
      }
      smallLogo: file(name: {regex: "/a-logo-dkms-scb-small/"}, ext: {eq: ".png"}) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, quality: 100)
        }
      }
    }
  `);

  const { bigLogo, smallLogo } = data;

  if (bigLogo && smallLogo) {
    const images = withArtDirection(getImage(bigLogo.childImageSharp.gatsbyImageData), [
      {
        image: getImage(smallLogo.childImageSharp.gatsbyImageData),
        media: "(max-width: 767px)",
      },
    ]);

    return (
      <GatsbyImage
        className="scb-logo"
        image={images}
        alt="Stem Cell Bank"
        objectFit="contain"
      />
    );
  }

  return null;
};

export default memo(StemCellBankLogo);
