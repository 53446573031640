import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./highlightTopicTileSection.scss";

const HighlightTopicTileSection = ({ children, highlights }) => (
  <div className="highlight-topic-tile-container container--fullwidth">
    <Container fluid>
      <Row className="highlight-topic-tile-container__tiles">
        {React.Children.map(React.Children.toArray(highlights), (child) => (
          <Col xs={12}>
            {child}
          </Col>
        ))}
        {React.Children.map(children, (child) => (
          <Col md={6} xl={4}>
            {child}
          </Col>
        ))}
      </Row>
    </Container>
  </div>
);

HighlightTopicTileSection.propTypes = {
  children: PropTypes.node,
  highlights: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

HighlightTopicTileSection.defaultProps = {
  children: null,
  highlights: null,
};

export default HighlightTopicTileSection;
