import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import SearchHit from "./searchHit";
import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import {
  IconClose18,
  IconDeleteInput18,
  IconSearch18,
  IconWarning24,
} from "../../icons";
import Breadcrumb from "../breadcrumb/breadcrumb";
import IconButton from "../button/iconButton";
import Logo from "../logo";
import Tag from "../tag";

import "./search.scss";

const Search = (props) => {
  const {
    desktop,
    focus,
    intl,
    onBlur,
    onCancel,
    onFocus,
  } = props;

  const [loading, setLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchHits, setSearchHits] = useState(false);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const [searchResultPage, setSearchResultPage] = useState(0);
  const [searchResultPageMax, setSearchResultPageMax] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);

  const searchFieldRef = useRef(null);
  const searchInnerRef = useRef(null);
  const searchHitsRef = useRef(null);

  const hitsPerPage = 10;

  const search = (isNewSearch = false) => {
    if (searchTerm.length > 2) {
      setLoading(true);
      axios.get(process.env.GATSBY_ADDSEARCH_URL, {
        params: {
          categories: process.env.GATSBY_ADDSEARCH_SEARCH_CATEGORY,
          fuzzy: "auto",
          page: isNewSearch ? 1 : searchResultPage,
          term: searchTerm,
        },
      })
        .then((response) => {
          if (isNewSearch) {
            setSearchResultCount(response.data.total_hits);
            setSearchResultPage(1);
            setSearchResultPageMax(Math.ceil(response.data.total_hits / hitsPerPage));
            setSearchHits(response.data.hits);
          } else {
            setSearchHits(searchHits.concat(response.data.hits));
          }
        })
        .catch(() => {
          setSearchError(true);
        })
        .then(() => {
          setLoading(false);
        });
    } else {
      setSearchError(false);
      setSearchHits(false);
      setSearchResultCount(0);
      setSearchResultPage(1);
      setSearchResultPageMax(0);
    }
  };

  const scroll = () => {
    if (
      searchInnerRef.current.scrollTop
      + searchInnerRef.current.offsetHeight
      + 100 > searchHitsRef.current.offsetHeight
    ) {
      if (searchResultPage < searchResultPageMax) {
        if (!loading) {
          setSearchResultPage(searchResultPage + 1);
        }
      }
    }
  };

  useEffect(() => {
    search(true);
  }, [searchTerm]);

  useEffect(() => {
    if (searchResultPage !== 1) {
      search();
    }
  }, [searchResultPage]);

  const resetSearch = () => {
    if (focus) {
      searchFieldRef.current.focus();
    }

    setSearchError(false);
    setSearchHits(false);
    setSearchTerm("");
  };

  const onFocusFunc = () => {
    onFocus();

    setSearching(true);
  };

  const onBlurFunc = (event) => {
    if (event.target.value.length === 0) {
      onBlur();

      setSearching(false);
    }
  };

  const onCancelFunc = () => {
    resetSearch(true);
    onCancel();
  };

  useEffect(() => {
    if (focus) {
      searchFieldRef.current?.focus();
    }
  }, []);

  const data = useStaticQuery(graphql`
    {
      primaryNavigation: kontentItemNavigationItem(system: {codename: {eq: "primary_navigation"}}) {
        elements {
          elements {
            value {
              ... on kontent_item_navigation_item {
                elements {
                  url_slug {
                    value
                  }
                }
              }
            }
          }
        }
      }
      secondaryNavigation: kontentItemNavigationItem(system: {codename: {eq: "secondary_navigation"}}) {
        elements {
          elements {
            value {
              ... on kontent_item_navigation_item {
                elements {
                  url_slug {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const colors = {};
  data.primaryNavigation.elements.elements.value.forEach((item, index) => {
    switch (index) {
      case 0:
        colors[item.elements.url_slug.value] = "dkms-red";
        break;
      case 1:
      case 2:
        colors[item.elements.url_slug.value] = "dkms-tertiary";
        break;
      default:
        break;
    }
  });

  data.secondaryNavigation.elements.elements.value.forEach((item, index) => {
    switch (index) {
      case 0:
        colors[item.elements.url_slug.value] = "dkms-tertiary";
        break;
      default:
        break;
    }
  });

  const isGreaterLg = useMediaQuery("(min-width: 992px)");

  return (
    <Container
      fluid
      className={classNames("search", { "flex-fill search--searching": searching })}
      ref={!desktop ? searchInnerRef : null}
      onScroll={!desktop ? scroll : () => {}}
    >
      <Row className="search__top">
        {isGreaterLg && (
          <Col lg={3}>
            <a href="/" title={intl.formatMessage({ id: "navigation.logo_title" })}>
              <Logo breakpoint="lg" />
            </a>
          </Col>
        )}
        <Col lg={6} className="search__input">
          <InputGroup className="prepend">
            <Form.Control
              placeholder={intl.formatMessage({ id: "search.type" })}
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
              onFocus={() => {
                onFocusFunc();
              }}
              onBlur={(event) => {
                onBlurFunc(event);
              }}
              ref={searchFieldRef}
              value={searchTerm}
            />
            <InputGroup.Prepend>
              <InputGroup.Text>
                <IconSearch18 />
              </InputGroup.Text>
            </InputGroup.Prepend>
            {searching && searchTerm !== null && (
              <button
                type="button"
                className="btn-clean"
                onClick={() => {
                  resetSearch(true);
                }}
              >
                <IconDeleteInput18 className="text-black" />
              </button>
            )}
          </InputGroup>
        </Col>
        {isGreaterLg && (
          <Col md={{ offset: 1, span: 2 }} className="text-right">
            <IconButton
              onClick={() => {
                onCancelFunc();
              }}
              className="btn-utility"
              iconOnly
            >
              <IconClose18 />
            </IconButton>
          </Col>
        )}
      </Row>

      {searchHits.length > 0 && (
        <div
          className="search__inner"
          ref={desktop ? searchInnerRef : null}
          onScroll={desktop ? scroll : () => {}}
        >
          <small className="search__result-count">
            <FormattedMessage id="search.results" /> ({searchResultCount})
          </small>
          <div
            className="search-hits"
            ref={searchHitsRef}
          >
            {searchHits && searchHits.map((hit) => {
              const urlPaths = hit.categories?.slice(1).map((item) => ({
                link: "#",
                title: item.replace(/[0-9]+x/, ""),
              }));
              let tag = null;
              let breadcrumb = null;

              if (hit.categories) {
                breadcrumb = (
                  <Breadcrumb
                    items={urlPaths}
                    withHomeIcon={false}
                  />
                );
              }

              if (urlPaths[0] && colors[urlPaths[0].title]) {
                if (urlPaths[1] && urlPaths[0].title !== "faq") {
                  tag = (
                    <Tag variant={colors[urlPaths[0].title]}>
                      {urlPaths[1].title.replaceAll("-", " ")}
                    </Tag>
                  );
                } else {
                  tag = (
                    <Tag variant={colors[urlPaths[0].title]}>
                      {urlPaths[0].title.replaceAll("-", " ")}
                    </Tag>
                  );
                }
              }

              return (
                <SearchHit
                  key={hit.id}
                  title={hit.title.replace("| DKMS", "")}
                  description={hit.highlight}
                  imageBase64={hit.images.main_b64}
                  imageSrc={hit.images.main}
                  category={tag}
                  url={hit.url}
                  documentType={hit.document_type}
                  urlPaths={hit.categories}
                  breadcrumb={breadcrumb}
                />
              );
            })}
          </div>
        </div>
      )}

      {searching && loading && (
        <Row className="search__info-text search__info-text--passive">
          <Col xs={12} lg={{ offset: 3, span: 6 }}>
            <Spinner animation="border" variant="dkms-grey-dark" />
          </Col>
        </Row>
      )}

      {searching && !searchHits && !searchError && (
        <Row className="search__info-text search__info-text--passive">
          <Col xs={12} lg={{ offset: 3, span: 6 }}>
            <FormattedMessage id="search.info" />
          </Col>
        </Row>
      )}

      {searching && searchHits.length === 0 && (
        <Row className="search__info-text">
          <Col xs={12} lg={{ offset: 3, span: 6 }}>
            <FormattedMessage
              id="search.noresult"
              values={{
                searchTerm,
              }}
            />
          </Col>
        </Row>
      )}

      {searching && searchError && (
        <Row className="search__info-text">
          <Col xs={12} lg={{ offset: 3, span: 6 }}>
            <IconWarning24 /><br />
            <FormattedMessage id="search.error" />
          </Col>
        </Row>
      )}
    </Container>
  );
};

Search.propTypes = {
  desktop: PropTypes.bool,
  focus: PropTypes.bool,
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func,
  onCancel: PropTypes.func,
  onFocus: PropTypes.func,
};

Search.defaultProps = {
  desktop: false,
  focus: false,
  onBlur: () => {},
  onCancel: () => {},
  onFocus: () => {},
};

export default injectIntl(Search);
