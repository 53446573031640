import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { bool, object } from "yup";

import VirtualDriveSetupError from "./virtualDriveSetupError";
import { useSubmitIoVirtualDriveMutation } from "../../../features/io/ioVirtualDriveApiSlice";
import useURNParams from "../../../hooks/useURNParams";
import {
  IconInfo30,
  IconSubmit18,
  IconSubmit24,
  IconVirtualDrive30,
} from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import Checkbox from "../../dynamicForm/checkbox/checkbox";
import NavigationLink from "../../navigationLink/navigationLink";
import SetupDataHelper from "../setupDataHelper";

const VirtualDriveSetupOverview = ({ content, next }) => {
  const setupData = useSelector((state) => state.registration.setupData);

  const intl = useIntl();

  const urnParams = useURNParams();

  const [submitIoVirtualDrive, submitIoVirtualDriveResult] = useSubmitIoVirtualDriveMutation({ fixedCacheKey: "submitIoVirtualDriveCacheKey" });

  if (submitIoVirtualDriveResult.isError) {
    return (<VirtualDriveSetupError error={submitIoVirtualDriveResult.error} content={content} />);
  }

  if (submitIoVirtualDriveResult.isSuccess) {
    DataLayer.pushEvent("ppe_virtual_drive_success_goal");

    next();
  }

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    dataProtection: bool()
      .required(requiredMessage)
      .oneOf([true], requiredMessage),
  }), []);

  return (
    <div>
      <Row className="mb-36">
        <Col sm={10}>
          <h3>
            <FormattedMessage id="virtual_drive_setup.overview_title" />
          </h3>
        </Col>
        <Col lg={6} className="virtual-drive-setup-dialog-step__overview-group">
          <IconVirtualDrive30 />
          <div className="virtual-drive-setup-dialog-step__section-title">
            <FormattedMessage id="virtual_drive_overview.your_drive" />:
            {` ${setupData.driveTitle}`}
          </div>
          <div className="typo-body2">
            {setupData.motivation === "patient"
              ? <FormattedMessage id="virtual_drive.patient_drive" />
              : <FormattedMessage id="virtual_drive.general_drive" />}
            <br />
            {setupData.organizationName}
          </div>
        </Col>
        <Col lg={6} className="virtual-drive-setup-dialog-step__overview-group">
          <IconInfo30 />
          <div className="virtual-drive-setup-dialog-step__section-title">
            <FormattedMessage id="virtual_drive_overview.whats_next" />
          </div>
          <div className="typo-body2">
            <FormattedMessage id="virtual_drive_overview.whats_next_content" />
          </div>
        </Col>
      </Row>

      <Formik
        initialValues={{
          dataProtection: false,
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          if (!submitIoVirtualDriveResult.isSuccess) {
            submitIoVirtualDrive({
              data: SetupDataHelper.preparePayload(setupData),
              urns: urnParams,
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <Checkbox
                  id="dataProtection"
                  name="dataProtection"
                  checked={values.dataProtection}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  isInvalid={(errors.dataProtection && touched.dataProtection)}
                  label={intl.formatMessage(
                    { id: "virtual_drive_setup.dataprotection" },
                    {
                      link: (
                        <NavigationLink href={content.privacyPolicyLink} target="_blank">
                          <FormattedMessage id="virtual_drive_setup.dataprotection_link" />
                        </NavigationLink>
                      ),
                    },
                  )}
                  errors={errors}
                  feedback={errors.dataProtection}
                  className="checkbox-required"
                  small
                  required
                />
              </Col>

              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting} loading={submitIoVirtualDriveResult.isLoading}>
                    <FormattedMessage id="virtual_drive_setup.submit_drive_request" />
                    <AdaptiveIcon
                      sm={<IconSubmit18 />}
                      lg={<IconSubmit24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

VirtualDriveSetupOverview.stepName = "virtual_drive_summary";

VirtualDriveSetupOverview.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default VirtualDriveSetupOverview;
