import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./assetsHeader.scss";

const AssetsHeader = (props) => {
  const {
    headline,
    description,
    children,
  } = props;

  return (
    <Row className="assets-header">
      <Col sm={10} md={8} lg={7}>
        <h1>{headline}</h1>
        <div className="assets-header__description">{description}</div>
      </Col>
      <Col md={8} lg={{ offset: 1, span: 4 }}>
        {children}
      </Col>
    </Row>
  );
};

AssetsHeader.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
};

export default AssetsHeader;
