import React from "react";

import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import BrowserDetection from "./browserDetection";
import useLanguage from "../hooks/useLanguage";

const SEO = (props) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `}
    render={(data) => {
      const {
        description,
        lang,
        linkingData,
        meta,
        keywords,
        title,
        twitterTitle,
        twitterSite,
        twitterImage,
        twitterDescription,
        twitterCreator,
        ogTitle,
        ogImage,
        ogDescription,
        isHiddenForRobots,
      } = props;

      const { languageCode } = useLanguage();

      const metaDescription = description || data.site.siteMetadata.description;

      let browserList = "";

      browserList += BrowserDetection.hasTouchScreen() ? "touchable " : "";
      browserList += BrowserDetection.isIE() ? "ie " : "";

      return (
        <Helmet
          htmlAttributes={{
            class: browserList.trim(),
            lang: lang || languageCode || "en",
          }}
          title={title}
          defer={false}
          titleTemplate={`%s | ${data.site.siteMetadata.title}`}
          meta={[
            {
              content: metaDescription,
              name: "description",
            },
            {
              content: ogTitle || title,
              property: "og:title",
            },
            {
              content: ogDescription || metaDescription,
              property: "og:description",
            },
            {
              content: "website",
              property: "og:type",
            },
            {
              content: ogImage.length > 0 ? `${ogImage[0].url}?h=630&w=1200` : "",
              property: "og:image",
            },
            {
              content: "summary",
              name: "twitter:card",
            },
            {
              content: data.site.siteMetadata.author,
              name: "twitter:creator",
            },
            {
              content: twitterSite,
              name: "twitter:site",
            },
            {
              content: twitterImage.length > 0 ? `${twitterImage[0].url}?h=630&w=1200` : "",
              name: "twitter:image",
            },
            {
              content: twitterTitle || title,
              name: "twitter:title",
            },
            {
              content: twitterCreator,
              name: "twitter:creator",
            },
            {
              content: twitterDescription || metaDescription,
              name: "twitter:description",
            },
            ...[isHiddenForRobots
              ? {
                content: "noindex,nofollow",
                name: "robots",
              }
              : {
                content: "index,follow",
                name: "robots",
              },
            ],
          ]
            .concat(
              keywords.length > 0
                ? {
                  content: keywords.join(", "),
                  name: "keywords",
                }
                : [],
            )
            .concat(meta)}
        >
          {linkingData && [].concat(linkingData).length > 0
            && [].concat(linkingData).map((item, index) => (
              <script key={`${item["@type"]}${index.toString()}`} type="application/ld+json">
                {JSON.stringify(item)}
              </script>
            ))}
        </Helmet>
      );
    }}
  />
);

SEO.propTypes = {
  description: PropTypes.string,
  isHiddenForRobots: PropTypes.bool,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  linkingData: PropTypes.arrayOf(PropTypes.shape({})),
  meta: PropTypes.arrayOf(PropTypes.shape({})),
  ogDescription: PropTypes.string,
  ogImage: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
  })),
  ogTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  twitterCreator: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterImage: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
  })),
  twitterSite: PropTypes.string,
  twitterTitle: PropTypes.string,
};

SEO.defaultProps = {
  description: "",
  isHiddenForRobots: false,
  keywords: [],
  lang: null,
  linkingData: null,
  meta: [],
  ogDescription: "",
  ogImage: [],
  ogTitle: "",
  twitterCreator: "",
  twitterDescription: "",
  twitterImage: [],
  twitterSite: "",
  twitterTitle: "",
};

export default SEO;
