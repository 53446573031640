import { graphql, useStaticQuery } from "gatsby";

import getInternalLinkById from "../utils/getInternalLinkById";

const useSspSettings = () => {
  const defaultSettings = {
    accessDeniedSlug: null,
    loginInfoText: "",
    maintenanceInfoText: "",
  };

  const { kontentItemSspSettings } = useStaticQuery(graphql`
    {
      kontentItemSspSettings(system: {workflow_step: {ne: "archived"}}) {
        elements {
          error_page___oauth_access_denied {
            value {
              id
            }
          }
          login_info_text {
            value
          }
          maintenance_info_text {
            value
          }
        }
      }
    }
  `);

  if (kontentItemSspSettings) {
    const {
      elements,
    } = kontentItemSspSettings;

    if (elements.error_page___oauth_access_denied.value.length > 0) {
      const accessDeniedPageId = elements.error_page___oauth_access_denied.value[0].id;
      defaultSettings.accessDeniedSlug = getInternalLinkById(accessDeniedPageId);
    }

    if (elements.login_info_text.value) {
      defaultSettings.loginInfoText = elements.login_info_text;
    }

    if (elements.maintenance_info_text.value) {
      defaultSettings.maintenanceInfoText = elements.maintenance_info_text;
    }
  }

  return defaultSettings;
};

export default useSspSettings;
