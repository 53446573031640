import React from "react";

import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";
import TabContainer from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";

import "./tabList.scss";

const TabList = ({ items, onSelect, defaultActiveKey }) => (
  <div className="tab-list">
    <TabContainer
      unmountOnExit
      defaultActiveKey={defaultActiveKey !== null ? defaultActiveKey : items[0]?.key}
      onSelect={onSelect}
    >
      <Nav className="row">
        {items.map((child) => (
          <Nav.Item key={child.key} className="col">
            {child.tab}
          </Nav.Item>
        ))}
      </Nav>
      <TabContent>
        {items.map((child) => (
          <TabPane key={child.key} eventKey={child.key}>
            {child.content}
          </TabPane>
        ))}
      </TabContent>
    </TabContainer>
  </div>
);

TabList.propTypes = {
  defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      key: PropTypes.string,
      tab: PropTypes.oneOfType([PropTypes.object]),
    }),
  ).isRequired,
  onSelect: PropTypes.func,
};

TabList.defaultProps = {
  defaultActiveKey: null,
  onSelect: () => {},
};

export default TabList;
