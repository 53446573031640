import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { IconFurther18, IconFurther24 } from "../../icons";
import { format } from "../../utils/dateHelper";
import AdaptiveIcon from "../adaptiveIcon";
import BackgroundImage from "../backgroundImage/backgroundImage";
import IconButton from "../button/iconButton";
import NavigationLink from "../navigationLink/navigationLink";

import "./campaign.scss";

const Campaign = (props) => {
  const {
    children,
    date,
    backgroundImageStyle,
    small,
    href,
    tile,
    className,
  } = props;

  return (
    <NavigationLink href={href} className={classNames(className, "no-style")}>
      <div className={classNames("campaign", { "campaign--small": small, "campaign--tile": tile })}>
        <BackgroundImage
          className="campaign__background"
          backgroundImageStyle={backgroundImageStyle}
        />
        <div className="campaign__gradient">
          <div className="campaign__inner">
            <div className="d-flex justify-content-between align-items-end mt-auto">
              <div>
                <div className="typo-label">
                  {format(date)}
                </div>
                <div className={classNames("campaign__title", small ? "typo-title3" : "typo-title1")}>
                  {children}
                </div>
              </div>
              <IconButton className="campaign__button" variant="inverted-light" iconOnly aria-label={children}>
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </NavigationLink>
  );
};

Campaign.propTypes = {
  backgroundImageStyle: PropTypes.oneOfType([PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  date: PropTypes.instanceOf(Date).isRequired,
  href: PropTypes.string.isRequired,
  small: PropTypes.bool,
  tile: PropTypes.bool,
};

Campaign.defaultProps = {
  className: null,
  small: false,
  tile: false,
};

export default Campaign;
