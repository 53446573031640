import React, { useEffect, useState } from "react";

import { Formik } from "formik";
import { navigate } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import * as Yup from "yup";

import { getValidationArray } from "./dynamicFormUtils";
import { useSubmitIoDynamicFormMutation } from "../../features/io/ioDynamicFormApiSlice";
import useURNParams from "../../hooks/useURNParams";
import { IconRetry24, IconSubmit18, IconSubmit24 } from "../../icons";
import { notificationService } from "../../services/notification.service";
import KontentItemAccordion from "../../templates/kontentItemAccordion";
import DataLayer from "../../utils/dataLayer";
import mapKontentItem from "../../utils/mapKontentItem";
import AdaptiveIcon from "../adaptiveIcon";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import { clean, createYupSchema } from "../localForms/formBuilder";
import Notification from "../notification/notification";

import "./dynamicForm.scss";

const DynamicForm = (props) => {
  const {
    fields,
    buttonText,
    thankYouPageLink,
    kontentItemID,
    formSlug,
    legalDetails,
    initValues,
    seperateHandledFields,
    contactFormDataView,
  } = props;

  const intl = useIntl();

  const [submitIoDynamicForm, submitIoDynamicFormResult] = useSubmitIoDynamicFormMutation();

  const urnParams = useURNParams();

  const validationArray = getValidationArray(fields.concat(seperateHandledFields), intl);
  const validationSchema = Yup.object(validationArray.reduce(createYupSchema, {}));

  // passing a meta object with additional meta data,
  // here adding all required fields into an array from the generated validation schema
  const meta = {
    requiredFields: [],
  };

  Object.keys(validationSchema.fields).forEach((key) => {
    // eslint-disable-next-line no-underscore-dangle
    if (validationSchema.fields[key]._exclusive.required) {
      meta.requiredFields.push(key);
    }
  });

  const [formValues, setFormValues] = useState();

  const onSubmit = (values) => {
    setFormValues(values);

    submitIoDynamicForm({
      data: clean(values),
      kontentItemId: kontentItemID,
      urns: urnParams,
    });
  };

  const onSuccess = () => {
    const { slug } = thankYouPageLink;
    DataLayer.pushEvent(`ppfs_df_success_${formSlug}`);
    navigate(slug);
  };

  const onError = () => {
    notificationService.error(intl.formatMessage({ id: "error.something_went_wrong" }), {
      action: (
        <LinkButton onClick={() => onSubmit(formValues)}>
          <FormattedMessage values={{ countdown: 0 }} id="io.error.retry" /> <IconRetry24 />
        </LinkButton>),
      autoClose: false,
    });
  };

  useEffect(() => {
    if (submitIoDynamicFormResult.isSuccess) {
      onSuccess();
    }

    if (submitIoDynamicFormResult.isError) {
      onError();
    }
  }, [submitIoDynamicFormResult]);

  return (
    <>
      <Formik
        validateOnMount
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              {
                fields.filter((e) => e.elements).map(
                  (item) => mapKontentItem(item, {
                    errors,
                    meta,
                    onBlur: handleBlur,
                    onChange: handleChange,
                    touched,
                    values,
                  }),
                )
                }

              {contactFormDataView}

              {seperateHandledFields.map(
                (item) => (
                  mapKontentItem(item, {
                    errors,
                    meta,
                    onBlur: handleBlur,
                    onChange: handleChange,
                    touched,
                    values,
                  })),
              )}

              {legalDetails.map((item) => (
                <Col xs={12} key={item.id}>
                  <KontentItemAccordion data={item} usePreIconForModal />
                </Col>
              ))}

              <Col lg={12}>
                <Form.Group>
                  <IconButton
                    type="submit"
                    disabled={submitIoDynamicFormResult.isLoading}
                    loading={submitIoDynamicFormResult.isLoading}
                  >
                    {buttonText}
                    <AdaptiveIcon
                      sm={<IconSubmit18 />}
                      lg={<IconSubmit24 />}
                    />
                  </IconButton>
                </Form.Group>

                {meta.requiredFields.length > 0 && (
                  <div className="footnote--required">
                    <span className="required-sign" />
                    <FormattedMessage id="registration.required_fields" />
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Notification />
    </>
  );
};

DynamicForm.propTypes = {
  buttonText: PropTypes.string.isRequired,
  contactFormDataView: PropTypes.node,
  fields: PropTypes.oneOfType([PropTypes.array]).isRequired,
  formSlug: PropTypes.string.isRequired,
  initValues: PropTypes.oneOfType([PropTypes.array]).isRequired,
  kontentItemID: PropTypes.string.isRequired,
  legalDetails: PropTypes.oneOfType([PropTypes.array]),
  seperateHandledFields: PropTypes.oneOfType([PropTypes.array]),
  thankYouPageLink: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

DynamicForm.defaultProps = {
  contactFormDataView: null,
  legalDetails: [],
  seperateHandledFields: [],
};

export default DynamicForm;
