import React, { useRef, useState } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import useSupercluster from "use-supercluster";

import Marker from "./marker";
import { IconMaps18 } from "../../icons";
import IconButton from "../button/iconButton";
import Consent from "../consent/consent";

import "./map.scss";
import "./mapContainer.scss";

const Map = (props) => {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [activeWindow, setActiveWindow] = useState(null);

  const {
    markers,
    infoWindow,
    openMapButton,
    defaultZoom,
    emptyZoom,
    variant,
  } = props;

  const geoJsonPoints = [];

  const filteredMarkers = markers.filter((el) => el.lng && el.lat);

  const tmpMarkers = {};
  filteredMarkers.forEach((el) => {
    if (tmpMarkers[`${el.lat}${el.lng}`]) {
      tmpMarkers[`${el.lat}${el.lng}`].push(el);
    } else {
      tmpMarkers[`${el.lat}${el.lng}`] = [el];
    }
  });

  Object.values(tmpMarkers).forEach((entries) => {
    geoJsonPoints.push({
      geometry: {
        coordinates: [
          parseFloat(entries[0].lng),
          parseFloat(entries[0].lat),
        ],
        type: "Point",
      },
      properties: {
        cluster: false,
        entries,
        id: `${entries[0].lat}${entries[0].lng}`,
      },
      type: "Feature",
    });
  });

  const {
    clusters,
    supercluster,
  } = useSupercluster({
    bounds,
    options: {
      maxZoom: 20,
      radius: 75,
    },
    points: geoJsonPoints,
    zoom,
  });

  const openMap = () => {
    const newWindow = window.open(
      process.env.GATSBY_GOOGLE_MAPS_URL.replace("{lat}", filteredMarkers[0].lat).replace("{lng}", filteredMarkers[0].lng),
      "_blank",
      "noopener,noreferrer",
    );

    newWindow.opener = null;
  };

  let mainClusterId = null;

  const onLoaded = ({ map }) => {
    mapRef.current = map;

    if (filteredMarkers.length === 1) {
      mapRef.current.setCenter({
        lat: filteredMarkers[0].lat,
        lng: filteredMarkers[0].lng,
      });

      return;
    }

    if (filteredMarkers.length > 1) {
      // eslint-disable-next-line no-undef
      const fitBounds = new google.maps.LatLngBounds();

      for (let i = 0; i < filteredMarkers.length; i += 1) {
        // eslint-disable-next-line no-undef
        fitBounds.extend(new google.maps.LatLng(filteredMarkers[i].lat, filteredMarkers[i].lng));
      }

      mapRef.current.fitBounds(fitBounds);
    }
  };

  return (
    <Consent type="map">
      <div className="map">
        {openMapButton && filteredMarkers.length > 0 && (
          <IconButton variant="light" size="sm" onClick={openMap}>
            <FormattedMessage id="event.open_in_maps" />
            <IconMaps18 />
          </IconButton>
        )}
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
          defaultCenter={{
            lat: parseFloat(process.env.GATSBY_GOOGLE_MAPS_DEFAULT_CENTER_LAT || 0),
            lng: parseFloat(process.env.GATSBY_GOOGLE_MAPS_DEFAULT_CENTER_LNG || 0),
          }}
          defaultZoom={filteredMarkers.length === 0 ? emptyZoom : defaultZoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={onLoaded}
          onChange={({ zoom: newZoom, bounds: newBounds }) => {
            setZoom(newZoom);
            setBounds([
              newBounds.nw.lng,
              newBounds.se.lat,
              newBounds.se.lng,
              newBounds.nw.lat,
            ]);
          }}
          onChildClick={(key) => {
            setActiveWindow(key);
          }}
          onClick={({ event }) => {
            if (event.target.className.startsWith("info-window")) {
              return;
            }

            setActiveWindow(null);
          }}
        >
          {clusters.map((cluster) => {
            const [
              longitude,
              latitude,
            ] = cluster.geometry.coordinates;

            const {
              cluster: isCluster,
              point_count: pointCount,
            } = cluster.properties;

            if (isCluster) {
              if (mainClusterId === null) {
                mainClusterId = cluster.id;
              }

              return (
                <Marker
                  id={`cluster-${cluster.id}`}
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                  cluster
                  count={pointCount}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20,
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                  variant={variant}
                />
              );
            }

            return (
              <Marker
                id={`marker-${cluster.properties.id}`}
                key={`marker-${cluster.properties.id}`}
                lat={latitude}
                lng={longitude}
                show={infoWindow && activeWindow === `marker-${cluster.properties.id}`}
                entries={cluster.properties.entries}
                onClick={() => {
                  setTimeout(() => {
                    const tmpMapClientRect = document.getElementsByClassName("map")[0].getBoundingClientRect();
                    const infoWindowClientRect = document.getElementById(`marker-${cluster.properties.id}`).nextSibling.getBoundingClientRect();

                    if (tmpMapClientRect.top > infoWindowClientRect.top) {
                      mapRef.current.panTo(
                        {
                          lat: bounds[3],
                          lng: longitude,
                        },
                      );
                    }
                  }, 500);
                  mapRef.current.panTo({ lat: latitude, lng: longitude });
                }}
                variant={variant}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    </Consent>
  );
};

Map.propTypes = {
  defaultZoom: PropTypes.number,
  emptyZoom: PropTypes.number,
  infoWindow: PropTypes.bool,
  markers: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    lat: PropTypes.string,
    lng: PropTypes.string,
    title: PropTypes.string,
  })),
  openMapButton: PropTypes.bool,
  variant: PropTypes.oneOf(["event", "location"]),
};

Map.defaultProps = {
  defaultZoom: 12,
  emptyZoom: 7,
  infoWindow: true,
  markers: [],
  openMapButton: false,
  variant: "event",
};

export default Map;
