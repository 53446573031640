import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { injectIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import loadScript from "../../utils/loadScript";

import "./socialMediaEmbed.scss";

const SocialMediaEmbed = ({ intl, url, showText }) => {
  const checkInterval = 500;
  const loadTimeout = 2000;

  const nodeRef = useRef(null);

  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const parsePost = (apiUrl, callback, objectName) => {
    loadScript(apiUrl)
      .then(() => {
        const interval = setInterval(() => {
          if (typeof window[objectName] !== "undefined") {
            clearInterval(interval);
            // eslint-disable-next-line no-use-before-define
            clearTimeout(timeout);

            callback();

            setLoaded(true);
          }
        }, checkInterval);

        const timeout = setTimeout(() => {
          clearInterval(interval);
          setError(true);
        }, loadTimeout);
      })
      .catch(() => {
        setError(true);
      });
  };

  useEffect(() => {
    const urlObject = new URL(url);

    switch (urlObject.hostname) {
      case "facebook.com":
      case "www.facebook.com":

        if (!loaded) {
          parsePost(
            process.env.GATSBY_SOCIAL_EMBED_FACEBOOK_API,
            () => {
              // eslint-disable-next-line no-undef
              FB.XFBML.parse(nodeRef.current);
            },
            "FB",
          );
        }

        break;

      case "twitter.com":
      case "www.twitter.com":

        if (!loaded) {
          parsePost(
            process.env.GATSBY_SOCIAL_EMBED_TWITTER_API,
            () => {
              const id = url.substring(url.lastIndexOf("/") + 1);

              // eslint-disable-next-line no-undef
              twttr.widgets.createTweet(
                id, nodeRef,
                {
                  align: "center",
                  conversation: "none",
                  dnt: true,
                  lang: intl.formatMessage({ id: "code" }),
                },
              );
            },
            "twttr",
          );
        }

        break;

      case "instagram.com":
      case "www.instagram.com":

        if (!loaded) {
          parsePost(
            process.env.GATSBY_SOCIAL_EMBED_INSTAGRAM_API,
            () => {
              // eslint-disable-next-line no-undef
              instgrm.Embeds.process();
            },
            "instgrm",
          );
        }

        break;

      default:
        break;
    }
  }, []);

  const urlObject = new URL(url);

  let post = null;

  switch (urlObject.hostname) {
    case "facebook.com":
    case "www.facebook.com":

      post = (
        <div
          className="fb-post"
          data-href={url}
          ref={nodeRef}
          data-show-text={showText ? "true" : "false"}
          data-width="auto"
        />
      );

      break;

    case "twitter.com":
    case "www.twitter.com":

      post = (
        <div
          ref={nodeRef}
          data-href={url}
        />
      );

      break;

    case "instagram.com":
    case "www.instagram.com":

      post = (
        <blockquote
          className="instagram-media"
          data-instgrm-version="2"
        >
          <a href={url}>&nbsp;</a>
        </blockquote>
      );

      break;

    default:
      break;
  }

  if (error || post === null) {
    return null;
  }

  return (
    <div
      className={classNames(
        "social-media-embed",
        {
          "social-media-embed--loading": !loaded,
        },
      )}
    >
      {post}
    </div>
  );
};

SocialMediaEmbed.propTypes = {
  intl: PropTypes.oneOfType([PropTypes.object]).isRequired,
  showText: PropTypes.bool,
  url: PropTypes.string.isRequired,
};

SocialMediaEmbed.defaultProps = {
  showText: false,
};

export default injectIntl(SocialMediaEmbed);
