import React, { useEffect } from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import { useLoginMutation } from "../../../features/io/ioSspApiSlice";
import useSspSettings from "../../../hooks/useSspSettings";
import { IconLogin18, IconRetry24 } from "../../../icons";
import { notificationService } from "../../../services/notification.service";
import LinkButton from "../../button/linkButton";

const LoginButton = ({ className }) => {
  const intl = useIntl();
  const [login, loginResult] = useLoginMutation();

  useEffect(() => {
    if (loginResult.isError) {
      notificationService.error(intl.formatMessage({ id: "ssp.login_error" }), {
        action: (
          <LinkButton onClick={() => login()}>
            <FormattedMessage id="ssp.login_error.retry" /> <IconRetry24 />
          </LinkButton>),
        autoClose: false,
        dismissButtonText: <FormattedMessage id="ssp.login_error.hide" />,
      });
    }
  }, [loginResult]);

  const { accessDeniedSlug } = useSspSettings();

  return (
    <button
      type="button"
      onClick={() => {
        login(accessDeniedSlug?.slug);
      }}
      className={className}
      disabled={loginResult.isLoading}
    >
      {loginResult.isLoading ? (
        <Spinner animation="border" variant="dkms-grey-dark" />
      ) : (
        <IconLogin18 />
      )}
      <FormattedMessage id="ssp.login" />
    </button>
  );
};

LoginButton.propTypes = {
  className: PropTypes.string,
};

LoginButton.defaultProps = {
  className: "",
};

export default LoginButton;
