import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import SubNavigation from "../../components/subNavigation/subNavigation";
import SubNavPoint from "../../components/subNavPoint/subNavPoint";
import useMediaQuery from "../../hooks/haiku/useMediaQuery";

const SubNavigationGenerator = ({ id }) => {
  const isHigherLg = useMediaQuery("(min-width: 992px)");

  const data = useStaticQuery(graphql`
    {
      links: allInternalLinks {
        nodes {
          id
          title
          slug
          navigationItemId
        }
      }
      primaryNavigation: kontentItemNavigationItem(system: {codename: {eq: "primary_navigation"}}) {
        id
        elements {
          elements {
            value {
              id
              preferred_language
              ... on kontent_item_navigation_item {
                id,
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                  elements {
                    value {
                      ... on kontent_item_navigation_item {
                        id
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      secondaryNavigation: kontentItemNavigationItem(system: {codename: {eq: "secondary_navigation"}}) {
        id
        elements {
          elements {
            value {
              id
              preferred_language
              ... on kontent_item_navigation_item {
                id,
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                  elements {
                    value {
                      ... on kontent_item_navigation_item {
                        id
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (id) {
    const node = data.links.nodes.find((el) => el.id.includes(id));

    if (node) {
      const slugParts = node.slug.split("/");
      // Remove first empty element
      slugParts.shift();

      const items = [];

      if (slugParts.length > 0 && slugParts[0]) {
        const navigationData = data.primaryNavigation.elements.elements.value.concat(
          data.secondaryNavigation.elements.elements.value,
        );

        const mainItem = data.links.nodes.find((el) => el.slug === `/${slugParts[0]}`);

        if (mainItem) {
          const mainNavigation = navigationData.find((el) => el.id === mainItem.navigationItemId);

          if (mainNavigation) {
            mainNavigation.elements.elements.value.forEach((item) => {
              if (!item.id) return null;

              items.push(
                <SubNavPoint
                  {...item}
                  href={item.id}
                  key={item.id}
                  color="black"
                  className={item.className}
                >
                  {item.elements.title.value}
                </SubNavPoint>,
              );

              return null;
            });
          }

          if (items.length > 0 && isHigherLg) {
            return <SubNavigation items={items} />;
          }
        }
      }
    }
  }

  return null;
};

SubNavigationGenerator.propTypes = {
  id: PropTypes.string.isRequired,
};

export default SubNavigationGenerator;
