import React from "react";

import PropTypes from "prop-types";

import ContentHeader from "./contentHeader/contentHeader";
import { HeaderIcon404, HeaderIcon500 } from "../images";

const StatusCode = (props) => {
  const {
    number,
    text,
    description,
  } = props;

  return (
    <ContentHeader
      headline={text}
      description={description}
      media={{
        404: <HeaderIcon404 />,
        500: <HeaderIcon500 />,
      }[number]}
    />
  );
};

StatusCode.propTypes = {
  description: PropTypes.string,
  number: PropTypes.oneOf([404, 500]).isRequired,
  text: PropTypes.string.isRequired,
};

StatusCode.defaultProps = {
  description: null,
};

export default StatusCode;
