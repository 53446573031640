import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import MegaMenuResult from "../../../components/megaMenuResult/megaMenuResult";
import KontentItemImage from "../../kontentItemImage";

const MegaMenuResultTargetGroupPage = ({ data }) => (
  <MegaMenuResult
    href={data.id}
    image={(
      <KontentItemImage
        data={data.elements.hero_image}
        width={48}
        height={48}
        aspectRatio={1}
        fit="crop"
        defaultImage
      />
    )}
  >
    {data.elements.headline.value}
  </MegaMenuResult>
);

export const query = graphql`
  fragment MegaMenuResultTargetGroupPage on kontent_item_target_group_page {
    id
    elements {
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

MegaMenuResultTargetGroupPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default MegaMenuResultTargetGroupPage;
