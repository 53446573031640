import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import FileDownloadCards from "./pressAndMediaAssets/FileDownloadCards";
import AssetsSection from "../components/assetsSection/assetsSection";

const KontentItemDownloadSection = ({ data }) => {
  const downloadSection = data.value ? data.value[0] : data;

  if (!downloadSection || !downloadSection.elements) {
    return null;
  }

  return (
    <AssetsSection
      headline={downloadSection.elements.headline.value}
    >
      {FileDownloadCards({ data: downloadSection.elements.downloads })}
    </AssetsSection>
  );
};

export const query = graphql`
  fragment KontentItemDownloadSection on kontent_item_download_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      downloads {
        value {
          ...FileDownloadCards
        }
      }
    }
  } 
`;

KontentItemDownloadSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDownloadSection;
