import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import LearnTopicEmbedComponent from "../../components/learnTopicEmbed/learnTopicEmbed";
import KontentAssetsImage from "../kontentAssetsImage";

const LearnTopicEmbed = ({ data }) => {
  const learnTopicEmbed = data.value ? data.value[0] : data;

  if (!learnTopicEmbed || !learnTopicEmbed.elements) {
    return null;
  }

  return (
    <LearnTopicEmbedComponent
      title={learnTopicEmbed.elements.headline.value}
      href={learnTopicEmbed.id}
      image={
        learnTopicEmbed.elements.topic_illustration_small_tile.value.length > 0
          ? (
            <KontentAssetsImage
              width={100}
              data={learnTopicEmbed.elements.topic_illustration_small_tile.value[0]}
            />
          )
          : null
      }
    />
  );
};

export const query = graphql`
  fragment LearnTopicEmbed on kontent_item_learn_topics_page {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      topic_illustration_small_tile {
        value {
          name
          height
          description
          url
          width
          size
          type
        }
      }
    }
  }
`;

LearnTopicEmbed.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default LearnTopicEmbed;
