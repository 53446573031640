import React from "react";

import PropTypes from "prop-types";

import "./eyecatcherSidebar.scss";

const EyecatcherSidebar = (props) => {
  const {
    title,
    button,
    text,
  } = props;

  return (
    <div className="eyecatcher-sidebar">
      <div className="typo-title3">{title}</div>
      <div className="eyecatcher-sidebar__text">{text}</div>
      {button !== null && (
        <div className="eyecatcher-sidebar__button">{button}</div>
      )}
    </div>
  );
};

EyecatcherSidebar.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]),
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

EyecatcherSidebar.defaultProps = {
  button: null,
  text: null,
};

export default EyecatcherSidebar;
