import React, { useEffect, useState } from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

import {
  IconAccordionExpand18,
  IconAccordionMinimize18,
  IconDeleteInput18,
  IconSearch24,
} from "../../icons";
import Button from "../button/button";

import "./virtualDriveList.scss";

const VirtualDriveList = ({ children }) => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [numberOfTiles, setNumberOfTiles] = useState(6);
  const intl = useIntl();

  const resize = () => {
    if (typeof window !== "undefined") {
      setNumberOfTiles(window.innerWidth >= 992 ? 6 : 4);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      resize();
      window.addEventListener("resize", resize);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", resize);
      }
    };
  }, []);

  const filteredChild = children.filter((child) => (
    child.props.headline.toLowerCase().includes(searchValue.toLowerCase())
    || child.props.description.toLowerCase().includes(searchValue.toLowerCase())
  ));

  const highlightTiles = filteredChild.filter((child) => (
    child.props.highlight
  ));

  const standardTiles = filteredChild.filter((child) => (
    !child.props.highlight
  ));

  return (
    <div className="virtual-drive-list">
      <InputGroup className="prepend large">
        <Form.Control
          size="lg"
          placeholder={intl.formatMessage({ id: "virtual_drive_current_drive.search_for_specific_drive" })}
          onChange={(event) => {
            setSearchValue(event.target.value);
          }}
          value={searchValue}
          className="typo-h4"
        />
        <InputGroup.Prepend>
          <InputGroup.Text>
            <IconSearch24 />
          </InputGroup.Text>
        </InputGroup.Prepend>
        {searchValue !== "" && (
          <button
            type="button"
            className="btn-clean"
            onClick={() => {
              setSearchValue("");
            }}
          >
            <IconDeleteInput18 />
          </button>
        )}
      </InputGroup>
      <Row>
        {React.Children.map(highlightTiles, (highlightTile) => (
          <Col xs={12}>
            {highlightTile}
          </Col>
        ))}

        {
          React.Children.map(standardTiles,
            (standardTile, index) => {
              if (index < numberOfTiles * page) {
                return (
                  <Col md={6} lg={4}>
                    {standardTile}
                  </Col>
                );
              }

              return null;
            })
        }
      </Row>
      {
        !(standardTiles.length <= numberOfTiles)
        && (numberOfTiles * page < standardTiles.length
          ? (
            <Button
              onClick={() => {
                setPage(page + 1);
              }}
              variant="inverted-registration"
              className="virtual-drive-list__pagination"
            >
              <FormattedMessage id="virtual_drive_list.show_more" /> <IconAccordionExpand18 />
            </Button>
          )
          : (
            <Button
              onClick={() => {
                setPage(1);
              }}
              variant="inverted-registration"
              className="virtual-drive-list__pagination"
            >
              <FormattedMessage id="virtual_drive_list.show_less" /> <IconAccordionMinimize18 />
            </Button>
          ))
      }
    </div>
  );
};

VirtualDriveList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VirtualDriveList;
