import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./unifyEyecatcher.scss";

/**
 * @todo rename
 */
const UnifyEyecatcher = (props) => {
  const {
    title,
    children,
    className,
    variant,
    text,
    contact,
    color,
  } = props;

  return (
    <div className={classNames(
      "text-section__fullwidth eyecatcher",
      {
        [`eyecatcher--${variant}`]: variant,
        [`eyecatcher-color--${color}`]: color,
      },
      className,
    )}
    >
      <div className="eyecatcher__content">
        <div className="eyecatcher__header">
          <div className="eyecatcher__header__title typo-h3">{title}</div>
          {text && !contact && (<div className="eyecatcher__header__text typo-body1">{text}</div>)}
          {contact}
        </div>
        {children && (
          <div className="eyecatcher__elements">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

UnifyEyecatcher.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  contact: PropTypes.oneOfType([PropTypes.object]),
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["fulltone", "dualtone"]),
};

UnifyEyecatcher.defaultProps = {
  children: null,
  className: undefined,
  color: null,
  contact: null,
  text: null,
  variant: null,
};

export default UnifyEyecatcher;
