import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./quickLinks.scss";

const QuickLinks = ({ children, ssp }) => {
  let colProps = {
    sm: 6,
  };

  if (ssp) {
    colProps = {
      md: 6,
    };
  }

  return (
    <Row className={classNames("quick-links", { "quick-links--ssp": ssp })}>
      {React.Children.toArray(children).map((child, index) => (
        <Col className="quick-links__item" {...colProps} key={`quick-links__item${index.toString()}`}>
          {child}
        </Col>
      ))}
    </Row>
  );
};

QuickLinks.propTypes = {
  children: PropTypes.node.isRequired,
  ssp: PropTypes.bool,
};

QuickLinks.defaultProps = {
  ssp: false,
};

export default QuickLinks;
