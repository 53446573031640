import React, { useState } from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import DropdownFormField from "../../components/dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../components/dynamicForm/formField/formField";
import Tooltip from "../../components/localForms/tooltip";

const KontentItemInputTypeDropdown = (props) => {
  const {
    data,
    errors,
    touched,
    meta,
    onChange,
    onBlur,
    values,
  } = props;

  const [other, setOther] = useState(false);

  const { elements } = data;

  // Setting up options array to contain an "other" option if required
  const options = elements.options.value?.split("\n").map((item) => ({ label: item, value: item }));
  const otherOption = elements.option_other.value[0].codename === "true";

  if (otherOption) {
    options.push({
      label: elements.label_other.value,
      value: elements.label_other.value,
    });
  }

  const onOtherSelected = (value) => {
    if (value === elements.label_other.value) {
      setOther(true);

      return;
    }

    setOther(false);
  };

  const dropdown = (
    <>
      <Col xs={12} md={6}>
        <Tooltip
          id={elements.id_dropdown.value}
          tooltipMessage={elements.tooltip_dropdown.value}
        >
          <DropdownFormField
            id={elements.id_dropdown.value}
            type="select"
            errors={errors}
            touched={touched}
            meta={meta}
            label={elements.label_dropdown.value}
            options={options}
            onChange={onChange}
            onBlur={onBlur}
            otherSelected={otherOption ? onOtherSelected : null}
            placeholder={elements.placeholder_dropdown.value}
            required={meta?.requiredFields?.includes(elements.id_dropdown.value)}
            values={values}
          />
        </Tooltip>
      </Col>
    </>
  );

  if (otherOption) {
    return (
      <Col xs={12}>
        <Row>
          {dropdown}
          <Col xs={12} md={6}>
            {other
              && (
                <Tooltip
                  id={elements.id_other.value}
                  tooltipMessage={elements.tooltip_other.value}
                >
                  <FormField
                    id={elements.id_other.value}
                    type="text"
                    errors={errors}
                    touched={touched}
                    meta={meta}
                    label={elements.label_other.value}
                    placeholder={elements.placeholder_other.value}
                    onChange={onChange}
                    onBlur={onBlur}
                    values={values}
                  />
                </Tooltip>
              )}
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    dropdown
  );
};

export const query = graphql`
  fragment KontentItemInputTypeDropdown on kontent_item_input_type_dropdown {
    id
    elements {
      id_other {
        value
      }
      id_dropdown {
        value
      }
      label_dropdown {
        value
      }
      label_other {
        value
      }
      option_other {
        value {
          codename
        }
      }
      options {
        value
      }
      required {
        value {
          codename
        }
      }
      tooltip_dropdown {
        value
      }
      tooltip_other {
        value
      }
      placeholder_other {
        value
      }
      placeholder_dropdown {
        value
      }
    }
  } 
`;

KontentItemInputTypeDropdown.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemInputTypeDropdown;
