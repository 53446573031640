import React, { useMemo } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { bool, object } from "yup";

import { useGetPaymentLinkMutation } from "../../../features/io/ioMoneyDonationApiSlice";
import { setPaymentData } from "../../../features/registrationSlice";
import useURNParams from "../../../hooks/useURNParams";
import { IconCreditCard24, IconDownload18, IconPrivate18 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import downloadFile from "../../../utils/downloadFile";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import IconButton from "../../button/iconButton";
import LinkButton from "../../button/linkButton";
import Checkbox from "../../dynamicForm/checkbox/checkbox";
import IconText from "../../iconText/iconText";
import NavigationLink from "../../navigationLink/navigationLink";
import RegistrationDialogHeadline from "../../registrationDialog/registrationDialogHeadline";
import PaymentDataHelper from "../paymentDataHelper";

const PaymentDialogStepOverview = ({ content, next }) => {
  const paymentData = useSelector((state) => state.registration.paymentData);

  const dispatch = useDispatch();

  const intl = useIntl();

  const urnParams = useURNParams();

  const [getPaymentLink, paymentLinkResult] = useGetPaymentLinkMutation({ fixedCacheKey: "getPaymentLinkCacheKey" });

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    dataProtection: bool()
      .required(requiredMessage)
      .oneOf([true], requiredMessage),
  }), []);

  const {
    amount,
    donorType,
    street,
    houseNo,
    postCode,
    city,
    emailAddress,
    companyName,
    companyStreet,
    companyHouseNo,
    companyPostCode,
    companyCity,
    interval,
    firstname,
    lastname,
    salutation,
    gender,
  } = paymentData;

  let text = "";
  let download = false;
  let donationReceipt = false;

  switch (true) {
    case interval === "ONCE" && amount < 50:
      text = "payment_dialog.donation_receipt_description";
      download = true;
      break;
    case interval === "ONCE" && amount < 300:
      text = "payment_dialog.donation_receipt_description_upper_limit";
      download = true;
      donationReceipt = true;
      break;
    case interval === "ONCE" && amount >= 300:
      text = "";
      donationReceipt = true;
      break;
    case interval === "YEARLY":
      text = "payment_dialog.pay_in_interval_yearly_description";
      break;
    default: // monthly etc.
      text = "payment_dialog.pay_in_interval_description";
  }

  return (
    <div className="payment-dialog-overview">
      <RegistrationDialogHeadline
        headline="payment_dialog.overview.title"
      />
      <Row>
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="payment_dialog.donation" />
          </div>
          <div className="typo-body2">
            {intl.formatMessage({ id: "currency.amount" }, { amount })} <br />
            {`${intl.formatMessage({ id: `payment_dialog.${interval.toLowerCase()}` })}`}<br />
          </div>
        </Col>
        {donorType === "company" && (
          <Col xs={6} className="registration-dialog-step__overview-group">
            <div className="registration-dialog-step__section-title">
              {parse(sanitizeHtml(intl.formatMessage({ id: "payment_dialog.company_data" })))}
            </div>
            <div className="typo-body2">
              <FormattedMessage id="payment_dialog.donorType.company" /> <br />
              {companyName}<br /><br />
              {companyStreet} {companyHouseNo}<br />
              {companyPostCode} {companyCity}<br />
              {emailAddress}
              <br /><br />
            </div>
          </Col>
        )}
        {donorType === "private" && (
          <Col xs={6} className="registration-dialog-step__overview-group">
            <div className="registration-dialog-step__section-title">
              <FormattedMessage id="payment_dialog.personal_data" />
            </div>
            <div className="typo-body2">
              <FormattedMessage id="payment_dialog.donorType.private" /> <br />
              <FormattedMessage id={gender === "M" ? "form.sex.male" : "form.sex.female"} /> {salutation} {firstname} {lastname} <br /><br />

              {street} {houseNo}<br />
              {postCode} {city}<br /><br />
              {emailAddress}<br />
            </div>
          </Col>
        )}

        <Col xs={12} className={classNames("payment-dialog-step__section", { "mb-4": interval !== "ONCE" })}>
          <div className="typo-small-label">
            <FormattedMessage id="payment_dialog.donation_receipt" />
          </div>
          {text && (
            <div className={classNames("typo-body2", { "mb-18": !download })}>
              <FormattedMessage id={text} />
            </div>
          )}

          {download && (
            <LinkButton
              onClick={() => {
                downloadFile(
                  content.simplified_donation_receipt.url,
                  content.simplified_donation_receipt.name,
                );
              }}
              className="mb-18"
            >
              <FormattedMessage id="payment_dialog.download_donation_receipt" /> <IconDownload18 />
            </LinkButton>
          )}
        </Col>
      </Row>

      <Formik
        initialValues={{
          consentDeclaration: false,
          dataProtection: false,
          donationReceipt: paymentData.donationReceipt === "YES",
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          if (!paymentLinkResult.isSuccess) {
            getPaymentLink({
              data: PaymentDataHelper.preparePayload(
                paymentData,
                `${process.env.GATSBY_SITE_URL}${content.successPage}`,
              ),
              urns: urnParams,
            });

            DataLayer.pushEvent("pp_donation_step_payment_summary", { event_value: "success" });

            next();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            {donationReceipt && (
              <Checkbox
                id="donationReceipt"
                name="donationReceipt"
                checked={values.donationReceipt}
                onChange={(event) => {
                  dispatch(
                    setPaymentData({
                      donationReceipt: event.target.checked ? "YES" : "NO",
                    }),
                  );
                  handleChange(event);
                }}
                onBlur={handleBlur}
                touched={touched}
                isInvalid={(errors.donationReceipt && touched.donationReceipt)}
                label={intl.formatMessage({ id: "payment_dialog.receipt_checkbox" })}
                errors={errors}
                feedback={errors.donationReceipt}
                small
              />
            )}
            <div className="typo-small-label required-sign">
              <FormattedMessage id="payment_dialog.dataprotection_title" />
            </div>
            <Checkbox
              id="dataProtection"
              name="dataProtection"
              checked={values.dataProtection}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched}
              isInvalid={(errors.dataProtection && touched.dataProtection)}
              label={intl.formatMessage(
                { id: "payment_dialog.dataprotection_label" },
                {
                  link: (
                    <NavigationLink href={content.privacyPolicyLink} target="_blank">
                      <FormattedMessage id="payment_dialog.dataprotection_label_linktext" />
                    </NavigationLink>),
                },
              )}
              errors={errors}
              feedback={errors.dataProtection}
              small
              required
            />
            {(content.optinHeadline || content.optinText) && (
              <>
                <div className="registration-dialog-step__overview-text">
                  <div className="text--simple">
                    {content.optinHeadline && (
                      <p>
                        <strong>{content.optinHeadline}</strong>
                      </p>
                    )}
                    {content.optinText && (
                      <p>
                        {content.optinText}
                      </p>
                    )}
                  </div>
                </div>
                <Checkbox
                  id="consentDeclaration"
                  name="consentDeclaration"
                  checked={values.consentDeclaration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  isInvalid={(errors.consentDeclaration && touched.consentDeclaration)}
                  label={intl.formatMessage({ id: "registration.consent_declaration_email" })}
                  errors={errors}
                  feedback={errors.consentDeclaration}
                  small
                  required
                />
              </>
            )}
            <div className="registration-dialog-step__text-revocation">
              {content.revocation}

              <ScrollToFieldError />

              <div className="payment-dialog__submit">
                <IconButton
                  type="submit"
                  variant="donation"
                  loading={paymentLinkResult.isLoading}
                >
                  <FormattedMessage id="payment.overview.button_next" /> <IconCreditCard24 />
                </IconButton>
                <IconText
                  icon={<IconPrivate18 />}
                  variant="grey-dark"
                  text={intl.formatMessage({ id: "payment.ssl_encrypted" })}
                />
              </div>
              <div className="footnote--required">
                <span className="required-sign" />
                <FormattedMessage id="registration.required_fields" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

PaymentDialogStepOverview.stepName = "payment_summary";

PaymentDialogStepOverview.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default PaymentDialogStepOverview;
