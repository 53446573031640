import React, { useEffect, useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { bool, object } from "yup";

import RegistrationDialogStepError from "./registrationDialogStepError";
import { useSubmitIoRegistrationMutation } from "../../../features/io/ioRegistrationApiSlice";
import { setStepError, setSuccess } from "../../../features/registrationSlice";
import usePrevious from "../../../hooks/usePrevious";
import useURNParams from "../../../hooks/useURNParams";
import { IconSubmit18, IconSubmit24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import { dateFactory, format } from "../../../utils/dateHelper";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import Checkbox from "../../dynamicForm/checkbox/checkbox";
import NavigationLink from "../../navigationLink/navigationLink";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepOverviewIn = ({ content, next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const lastSuccess = useSelector((state) => state.registration.lastSuccess);
  const stepError = useSelector((state) => state.registration.stepError);
  const dispatch = useDispatch();

  const intl = useIntl();
  const urnParams = useURNParams();

  const [submitIoRegistration, submitIoRegistrationResult] = useSubmitIoRegistrationMutation({ fixedCacheKey: "submitIoRegistrationCacheKey" });

  const getValue = (options, value) => options.find((el) => el.value === value).label;

  const prevSubmitIoRegistrationResult = usePrevious(submitIoRegistrationResult);

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    dataProtection: bool()
      .required(requiredMessage)
      .oneOf([true], requiredMessage),
  }), []);

  useEffect(() => {
    if (submitIoRegistrationResult.isSuccess && !prevSubmitIoRegistrationResult.isSuccess) {
      DataLayer.removeUnloadListener();
      DataLayer.pushEvent("pp_registration_step_final", { event_value: "success" });
      DataLayer.pushEvent("pp_registration_step_final_urn", { event_value: urnParams?.refs });
      DataLayer.pushEvent("ppe_registration_success_goal");

      dispatch(
        setSuccess(),
      );

      if (content.successPage) {
        window.skipBeforeUnload = true;
        window.location.href = content.successPage;
      } else {
        next();
      }
    }

    if (submitIoRegistrationResult.isError && !prevSubmitIoRegistrationResult?.isError) {
      DataLayer.pushEvent("pp_registration_step_final", { event_value: "fail" });
    }

    if (submitIoRegistrationResult.isError || prevSubmitIoRegistrationResult?.isError) {
      dispatch(
        setStepError(true),
      );
    }
  }, [submitIoRegistrationResult]);

  if (stepError) {
    return (
      <RegistrationDialogStepError
        content={content}
        error={submitIoRegistrationResult.error}
      />
    );
  }

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.overview.title"
      />
      <Row className="mb-36">
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.personal_details" />
          </div>
          <div className="typo-body2">
            {[
              registrationData.salutation,
              registrationData.firstname,
              registrationData.lastname,
            ].filter((x) => x).join(" ")}<br />
            {registrationData.birthdate && (
              <>
                {format(dateFactory(registrationData.birthdate))}<br /><br />
              </>
            )}
            {registrationData.heightImperialFeet}
            &apos; {registrationData.heightImperialInch}&quot;<br />
            {registrationData.weightMetric} kg
          </div>
        </Col>
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.ethnicity" />
          </div>
          <div className="typo-body2">
            {getValue(RegistrationDataHelper.stateOptionsIn, registrationData.stateFather)}{" "}
            (<FormattedMessage id="registration.ethnicity.father" />)<br />
            {registrationData.motherTongueFather !== "" && (
              <>
                {getValue(
                  RegistrationDataHelper.motherTongueOptions,
                  registrationData.motherTongueFather,
                )}
                (<FormattedMessage id="registration.ethnicity.father" />)<br />
              </>
            )}
            <br />
            {getValue(RegistrationDataHelper.stateOptionsIn, registrationData.stateMother)}{" "}
            (<FormattedMessage id="registration.ethnicity.mother" />)<br />
            {registrationData.motherTongueMother !== "" && (
              <>
                {getValue(
                  RegistrationDataHelper.motherTongueOptions,
                  registrationData.motherTongueMother,
                )}
                (<FormattedMessage id="registration.ethnicity.mother" />)
              </>
            )}
          </div>
        </Col>
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.contact_data" />
          </div>
          <div className="typo-body2">
            {(registrationData.apartment || registrationData.houseName) && (
              <>
                {`${registrationData.apartment} ${registrationData.houseName}`.trim()}<br />
              </>
            )}
            {registrationData.houseNo} {registrationData.street}<br />
            {registrationData.locality}<br />
            {registrationData.city}, {registrationData.county !== "" && `${registrationData.county}, `}{registrationData.postCode}<br />
            {getValue(RegistrationDataHelper.stateOptionsIn, registrationData.state)}<br /><br />
            {registrationData.emailAddress}<br />
            {registrationData.additionalEmailAddress && (
              <>
                {registrationData.additionalEmailAddress}<br />
              </>
            )}
            {registrationData.mobileNumber}<br />
            {registrationData.additionalPhoneNumber && (
              <>
                {registrationData.additionalPhoneNumber}<br />
              </>
            )}
          </div>
        </Col>
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.alternate_contact" />
          </div>
          <div className="typo-body2">
            {registrationData.contactFirstname} {registrationData.contactLastname}
            , {getValue(
              RegistrationDataHelper.relationshipOptionsIn,
              registrationData.contactRelationship,
            )}
            <br />
            {registrationData.contactPhoneNumber}<br />
            {registrationData.contactEmailAddress}<br /><br />
            {registrationData.secondContactFirstname} {registrationData.secondContactLastname}
            , {getValue(
              RegistrationDataHelper.relationshipOptionsIn,
              registrationData.secondContactRelationship,
            )}
            <br />
            {registrationData.secondContactPhoneNumber}<br />
            {registrationData.secondContactEmailAddress}
          </div>
        </Col>
      </Row>

      {content && content.overviewText && (
        <div className="registration-dialog-step__overview-text">
          {content.overviewText}
        </div>
      )}

      <Formik
        initialValues={{
          dataProtection: false,
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          // Prevent submitting if last successful submit was less than 60 seconds ago.
          if (((new Date()).getTime() - lastSuccess) / 1000 < 60) {
            dispatch(
              setStepError(true),
            );

            return;
          }

          if (!submitIoRegistrationResult.isSuccess) {
            submitIoRegistration({
              data: RegistrationDataHelper.preparePayload(registrationData),
              urns: urnParams,
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <Form.Group className="required">
                  <Checkbox
                    id="dataProtection"
                    name="dataProtection"
                    checked={values.dataProtection}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched}
                    isInvalid={(errors.dataProtection && touched.dataProtection)}
                    label={intl.formatMessage(
                      { id: "registration.overview.dataprotection" },
                      {
                        link: (
                          <NavigationLink href={content.privacyPolicyLink} target="_blank">
                            <FormattedMessage id="registration.overview.dataprotection_link" />
                          </NavigationLink>
                        ),
                      },
                    )}
                    errors={errors}
                    feedback={errors.dataProtection}
                    className="checkbox-required"
                    small
                    required
                  />
                </Form.Group>
              </Col>

              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group>
                  <IconButton
                    type="submit"
                    disabled={isSubmitting || submitIoRegistrationResult.isLoading}
                    loading={submitIoRegistrationResult.isLoading}
                  >
                    <FormattedMessage id="registration.overview.button_next" />
                    <AdaptiveIcon
                      sm={<IconSubmit18 />}
                      lg={<IconSubmit24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepOverviewIn.stepName = "registration_final";

RegistrationDialogStepOverviewIn.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepOverviewIn;
