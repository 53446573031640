import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";

import Checkbox from "../../components/dynamicForm/checkbox/checkbox";
import Tooltip from "../../components/localForms/tooltip";

const KontentItemInputTypeCheckbox = (props) => {
  const {
    data,
    errors,
    touched,
    meta,
    onBlur,
    onChange,
  } = props;

  const { elements } = data;

  return (
    <Col xs={12} className="mb-12">
      <Tooltip id={elements.id.value} tooltipMessage={elements.tooltip.value}>
        <Checkbox
          id={elements.id.value}
          name={elements.id.value}
          label={elements.label.value}
          data={elements.label}
          errors={errors}
          touched={touched}
          meta={meta}
          onChange={onChange}
          onBlur={onBlur}
          required={meta.requiredFields.includes(elements.id.value)}
        />
      </Tooltip>
    </Col>
  );
};

export const query = graphql`
  fragment KontentItemInputTypeCheckbox on kontent_item_input_type_checkbox {
    id
    elements {
      id {
        value
      }
      label {
        name
        value
        links {
          codename
          link_id
          type
          url_slug
        }
        images {
          description
          height
          image_id
          url
          width
        }
      }
      required {
        value {
          codename
        }
      }
      tooltip {
        value
      }
    }
  } 
`;

KontentItemInputTypeCheckbox.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemInputTypeCheckbox;
