import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import "./statementBox.scss";

const StatementBox = ({ image, description }) => {
  const { width } = useWindowDimensions();

  let tmpImage = image;

  if (width < 768) {
    tmpImage = React.cloneElement(image, { aspectRatio: 16 / 9 });
  }

  return (
    <Row noGutters className="statement-box">
      <Col md={4} xl={3} className="statement-box__image">
        {tmpImage}
      </Col>
      <Col md={8} xl={9} className="statement-box__description">
        {description}
      </Col>
    </Row>
  );
};

StatementBox.propTypes = {
  description: PropTypes.node.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default StatementBox;
