import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import KontentItemImageCard from "./kontentItemImageCard";
import CardSlider from "../components/cardSlider/cardSlider";

const KontentItemImageCardsGroup = ({ data }) => {
  const cards = data.value ? data.value[0] : data;

  if (!cards || !cards.elements) {
    return null;
  }

  const elements = cards.elements.image_cards.value.length;

  let colProps = {
    md: 6,
  };

  if (elements < 3 || cards.elements.prevent_slider.value.length > 0) {
    if (elements >= 3) {
      colProps = {
        lg: 4,
      };
    }

    return (
      <Row className="take-action-section text-section__fullwidth">
        {cards.elements.image_cards.value.map((card) => (
          <Col {...colProps} key={card.id}>
            <KontentItemImageCard data={card} height={700} width={700} />
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <CardSlider className="text-section__fullwidth">
      {cards.elements.image_cards.value.map((card) => (
        <KontentItemImageCard data={card} key={card.id} disableDescription />
      ))}
    </CardSlider>
  );
};

export const query = graphql`
  fragment KontentItemImageCardsGroup on kontent_item_image_cards_group {
    id
    system {
      codename
    }
    elements {
      image_cards {
        value {
          ...KontentItemImageCard
        }
      }
      prevent_slider {
        value {
          codename
        }
      }
    }
  }
`;

KontentItemImageCardsGroup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemImageCardsGroup;
