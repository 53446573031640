import React from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import sanitizeHtml from "sanitize-html";

import "./registrationDialogHeadline.scss";

const RegistrationDialogHeadline = (props) => {
  const {
    className,
    headline,
    subheadline,
    lessSpacing,
    values,
  } = props;

  const intl = useIntl();

  return (
    <Row className={classNames("registration-dialog-headline", className, { "registration-dialog-headline--less-spacing": lessSpacing })}>
      <Col sm={10}>
        <h3>
          <FormattedMessage id={headline} />
        </h3>
        {subheadline && (
          <p className="registration-dialog-headline__subtitle">
            {parse(sanitizeHtml(
              intl.formatMessage({ id: subheadline }, values),
              { allowedTags: ["a"] },
            ))}
          </p>
        )}
      </Col>
    </Row>
  );
};

RegistrationDialogHeadline.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string.isRequired,
  lessSpacing: PropTypes.bool,
  subheadline: PropTypes.string,
  values: PropTypes.oneOfType([PropTypes.object]),
};

RegistrationDialogHeadline.defaultProps = {
  className: null,
  lessSpacing: false,
  subheadline: null,
  values: null,
};

export default React.memo(RegistrationDialogHeadline);
