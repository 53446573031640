import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./textWithSidebar.scss";

const TextWithSidebar = (props) => {
  const {
    text,
    leadText,
    campaignHeadline,
    campaign,
    contactHeadline,
    contact,
    initiatorHeadline,
    initiator,
    linksAndAccountHeadline,
    linksAndAccount,
    eyecatcher,
    donationAccountHeadline,
    donationAccount,
    pressAreaHeadline,
    pressArea,
  } = props;

  return (
    <Row className="text-with-sidebar">
      <Col md={7} className="text-with-sidebar__text text">
        {leadText}
        {text}
      </Col>
      <Col md={5} xl={{ offset: 1, span: 4 }} className="text-with-sidebar__event-organizer">
        {initiator && (
          <div className="text-with-sidebar__section">
            <h4 className="text-with-sidebar__subheadline">
              {initiatorHeadline}
            </h4>
            {initiator}
          </div>
        )}

        {linksAndAccount && (
          <div className="text-with-sidebar__link-and-author text-with-sidebar__section">
            <h4 className="text-with-sidebar__subheadline">
              {linksAndAccountHeadline}
            </h4>
            {linksAndAccount}
          </div>
        )}

        {contact && (
          <div className="text-with-sidebar__contact-person text-with-sidebar__section">
            <h4 className="text-with-sidebar__subheadline">
              {contactHeadline}
            </h4>
            {contact}
          </div>
        )}

        {donationAccount && (
          <div className="text-with-sidebar__contact-person text-with-sidebar__section">
            <h4 className="text-with-sidebar__subheadline">
              {donationAccountHeadline}
            </h4>
            {donationAccount}
          </div>
        )}

        {campaign && (
          <div className="text-with-sidebar__section">
            <h4 className="text-with-sidebar__subheadline">
              {campaignHeadline}
            </h4>
            {campaign}
          </div>
        )}

        {eyecatcher}

        {pressArea && (
          <div className="text-with-sidebar__contact-person text-with-sidebar__section">
            <h4 className="text-with-sidebar__subheadline">
              {pressAreaHeadline}
            </h4>
            {pressArea}
          </div>
        )}
      </Col>
    </Row>
  );
};

TextWithSidebar.propTypes = {
  campaign: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  campaignHeadline: PropTypes.string,
  contact: PropTypes.oneOfType([PropTypes.object]),
  contactHeadline: PropTypes.string,
  donationAccount: PropTypes.oneOfType([PropTypes.string]),
  donationAccountHeadline: PropTypes.string,
  eyecatcher: PropTypes.oneOfType([PropTypes.object]),
  initiator: PropTypes.oneOfType([PropTypes.object]),
  initiatorHeadline: PropTypes.string,
  leadText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  linksAndAccount: PropTypes.oneOfType([PropTypes.object]),
  linksAndAccountHeadline: PropTypes.string,
  pressArea: PropTypes.oneOfType([PropTypes.string]),
  pressAreaHeadline: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

TextWithSidebar.defaultProps = {
  campaign: null,
  campaignHeadline: null,
  contact: null,
  contactHeadline: null,
  donationAccount: null,
  donationAccountHeadline: null,
  eyecatcher: null,
  initiator: null,
  initiatorHeadline: null,
  leadText: null,
  linksAndAccount: null,
  linksAndAccountHeadline: null,
  pressArea: null,
  pressAreaHeadline: null,
};

export default TextWithSidebar;
