import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { number, object, string } from "yup";

import { setError, setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepEligibilityBmi = ({ content, next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const error = useSelector((state) => state.registration.error);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    bmiunit: string(),
    heightImperialFeet: number()
      .when("bmiUnit", {
        is: (value) => value === "imperial",
        then: number()
          .required(requiredMessage)
          .min(1, intl.formatMessage(
            { id: "form.error.gt_or_eq" },
            {
              value: 1,
            },
          ))
          .max(8, intl.formatMessage(
            { id: "form.error.lt_or_eq" },
            {
              value: 8,
            },
          )),
      }),
    heightMetric: number()
      .when("bmiUnit", {
        is: (value) => value === "metric",
        then: number()
          .required(requiredMessage)
          .min(50, intl.formatMessage(
            { id: "form.error.gt_or_eq" },
            {
              value: 50,
            },
          ))
          .max(250, intl.formatMessage(
            { id: "form.error.lt_or_eq" },
            {
              value: 250,
            },
          )),
      }),
    weightImperialLbs: number()
      .when("bmiUnit", {
        is: (value) => value === "imperial",
        then: number()
          .required(requiredMessage)
          .min(0, intl.formatMessage(
            { id: "form.error.gt_or_eq" },
            {
              value: 0,
            },
          ))
          .max(660, intl.formatMessage(
            { id: "form.error.lt_or_eq" },
            {
              value: 660,
            },
          )),
      }),
    weightImperialSt: number()
      .when("bmiUnit", {
        is: (value) => value === "imperial",
        then: number()
          .required(requiredMessage)
          .min(0, intl.formatMessage(
            { id: "form.error.gt_or_eq" },
            {
              value: 0,
            },
          ))
          .max(47, intl.formatMessage(
            { id: "form.error.lt_or_eq" },
            {
              value: 47,
            },
          )),
      }),
    weightMetric: number()
      .when("bmiUnit", {
        is: (value) => value === "metric",
        then: number()
          .required(requiredMessage)
          .min(0, intl.formatMessage(
            { id: "form.error.gt_or_eq" },
            {
              value: 0,
            },
          ))
          .max(300, intl.formatMessage(
            { id: "form.error.lt_or_eq" },
            {
              value: 300,
            },
          )),
      }),
  }), []);

  if (error) {
    return (
      <>
        <RegistrationDialogHeadline
          headline="registration.bmi.error.title"
        />
        <Row>
          <Col sm={10}>

            <p>
              <FormattedMessage id="registration.bmi.error.text1" />
            </p>

            {intl.formatMessage({ defaultMessage: " ", id: "registration.bmi.error.text2" }) !== " " && (
              <p>
                <FormattedMessage id="registration.bmi.error.text2" />
              </p>
            )}

            {intl.formatMessage({ defaultMessage: " ", id: "registration.bmi.error.text3" }) !== " " && (
              <p>
                <FormattedMessage id="registration.bmi.error.text3" />
              </p>
            )}

            {content.donationBox}

            {!content.donationBox
              && content.errorButtonPrimary}

            {content.errorButtonSecondary}
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.bmi.title"
      />
      <Formik
        initialValues={{
          ...registrationData,
          heightImperialFeet: registrationData.heightImperialFeet !== "" && registrationData.heightImperialInch !== "" ? `${registrationData.heightImperialFeet}.${registrationData.heightImperialInch}` : "",
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          const [feet, inch] = values.heightImperialFeet.split(".");
          const feetNumber = feet ? parseInt(feet, 10) : "";
          const inchNumber = inch ? parseInt(inch, 10) : "";

          // Normalizing data
          const kg = (values.bmiUnit === "imperial")
            ? (14 * values.weightImperialSt + values.weightImperialLbs) / 2.205
            : values.weightMetric;
          const m = (values.bmiUnit === "imperial")
            ? ((12 * feetNumber + inchNumber) * 2.54) / 100
            : values.heightMetric / 100;

          // Calculate BMI
          const bmi = kg / (m * m);

          if (bmi > 40) {
            dispatch(
              setError(true),
            );

            dispatch(
              setRegistrationData({
                bmiUnit: values.bmiUnit,
                eligible: false,
                heightImperialFeet: feetNumber,
                heightImperialInch: inchNumber,
                heightMetric: values.heightMetric,
                weightImperialLbs: values.weightImperialLbs,
                weightImperialSt: values.weightImperialSt,
                weightMetric: values.weightMetric,
              }),
            );

            DataLayer.pushEvent("pp_registration_step_eligibility_bmi", { event_value: "fail" });
          } else {
            dispatch(
              setRegistrationData({
                bmiUnit: values.bmiUnit,
                heightImperialFeet: feetNumber,
                heightImperialInch: inchNumber,
                heightMetric: values.heightMetric,
                weightImperialLbs: values.weightImperialLbs,
                weightImperialSt: values.weightImperialSt,
                weightMetric: values.weightMetric,
              }),
            );

            DataLayer.pushEvent("pp_registration_step_eligibility_bmi", { event_value: "success" });

            next();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          setFieldError,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className="form-check-inline">
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.bmi.imperial" })}
                    name="bmiUnit"
                    value="imperial"
                    id="unit-imperial"
                    checked={values.bmiUnit === "imperial"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="form-check-inline">
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.bmi.metric" })}
                    name="bmiUnit"
                    value="metric"
                    id="unit-metric"
                    checked={values.bmiUnit === "metric"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Col>

              {values.bmiUnit === "imperial" && (
                <>
                  <Col xs={12} lg={6}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="weightImperialSt"
                      label={intl.formatMessage({ id: "registration.bmi.imperial_weight_st" })}
                      type="number"
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="weightImperialLbs"
                      label={intl.formatMessage({ id: "registration.bmi.imperial_weight_lbs" })}
                      type="number"
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <DropdownFormField
                      label={intl.formatMessage({ id: "registration.bmi.imperial_height_feet" })}
                      placeholder={intl.formatMessage({ id: "registration.please_select" })}
                      onChange={(selectedOption) => {
                        const { target } = selectedOption;

                        if (target && target.value) {
                          setFieldValue("heightImperialFeet", target.value);
                          setFieldError("heightImperialFeet", undefined);
                        } else {
                          setFieldValue("heightImperialFeet", "");
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched("heightImperialFeet", true);
                      }}
                      touched={touched}
                      id="heightImperialFeet"
                      errors={errors}
                      values={values}
                      options={RegistrationDataHelper.heightOptions}
                      required
                    />
                  </Col>
                </>
              )}

              {values.bmiUnit === "metric" && (
                <>
                  <Col xs={12} lg={6}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="weightMetric"
                      label={intl.formatMessage({ id: "registration.bmi.metric_weight" })}
                      type="number"
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="heightMetric"
                      label={intl.formatMessage({ id: "registration.bmi.metric_height" })}
                      type="number"
                      touched={touched}
                      errors={errors}
                      values={values}
                      required
                    />
                  </Col>
                </>
              )}

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.bmi.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepEligibilityBmi.stepName = "eligibility_bmi";

RegistrationDialogStepEligibilityBmi.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepEligibilityBmi;
