import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import backgroundImageHelper from "./image/backgroundImageHelper";
import KontentAssetsImage from "./kontentAssetsImage";
import KontentItemCtaButton from "./kontentItemCtaButton";
import KontentItemVideoEmbed from "./kontentItemVideoEmbed";
import KeyFactArea from "../components/keyFacts/keyFactArea";
import KeyFactCard from "../components/keyFacts/keyFactCard";

const KontentItemKeyFactArea = ({ data }) => {
  const keyfactarea = data.value ? data.value[0] : data;

  if (!keyfactarea || !keyfactarea.elements) {
    return null;
  }

  let card1;
  let card2;
  let card3;

  // todo: martin
  if (keyfactarea.elements.video_embed_card1.value.length > 0) {
    card1 = <KontentItemVideoEmbed variant="keyfact-area" data={keyfactarea.elements.video_embed_card1} />;
  } else {
    card1 = (
      <KeyFactCard
        title={keyfactarea.elements.title_card1.value}
        className="keyfactcard--background"
        backgroundImageStyle={
          backgroundImageHelper(keyfactarea.elements.image_card1)
        }
        text={keyfactarea.elements.text_card1.value}
      />
    );
  }

  if (keyfactarea.elements.video_embed_card2.value.length > 0) {
    card2 = <KontentItemVideoEmbed variant="keyfact-area" data={keyfactarea.elements.video_embed_card2} />;
  } else {
    card2 = (
      <KeyFactCard
        title={keyfactarea.elements.title_card2.value}
        text={keyfactarea.elements.text_card2.value}
        image={
          keyfactarea.elements.image_card2.value.length > 0
            ? (
              <KontentAssetsImage
                data={keyfactarea.elements.image_card2.value[0]}
                height={96}
              />
            )
            : null
        }
      />
    );
  }

  if (keyfactarea.elements.video_embed_card3.value.length > 0) {
    card3 = <KontentItemVideoEmbed variant="keyfact-area" data={keyfactarea.elements.video_embed_card3} />;
  } else {
    card3 = (
      <KeyFactCard
        title={keyfactarea.elements.title_card3.value}
        image={
          keyfactarea.elements.image_card3.value.length > 0
            ? (
              <KontentAssetsImage
                data={keyfactarea.elements.image_card3.value[0]}
                height={96}
              />
            )
            : null
        }
      />
    );
  }

  return (
    <KeyFactArea
      title={keyfactarea.elements.title.value}
    >
      {card1}
      {card2}
      {card3}
      <KeyFactCard
        title={keyfactarea.elements.title_card4.value}
        button={
            keyfactarea.elements.button.value.length > 0
              ? (
                <KontentItemCtaButton
                  data={keyfactarea.elements.button.value[0]}
                />
              )
              : null
        }
      />
    </KeyFactArea>
  );
};

export const query = graphql`
  fragment KontentItemKeyFactArea on kontent_item_key_fact_area {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      button {
        value {
          ...KontentItemCtaButton
        }
      }
      title_card1 {
        value
      }
      text_card1 {
        value
      }
      image_card1 {
        value {
          ...KontentItemImage
        }
      }
      video_embed_card1 {
        value {
          ...KontentItemVideoEmbed
        }
      }
      title_card2 {
        value
      }
      text_card2 {
        value
      }
      image_card2 {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
      video_embed_card2 {
        value {
          ...KontentItemVideoEmbed
        }
      }
      title_card3 {
        value
      }
      image_card3 {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
      video_embed_card3 {
        value {
          ...KontentItemVideoEmbed
        }
      }
      title_card4 {
        value
      }
    }
  }
`;

KontentItemKeyFactArea.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemKeyFactArea;
