import React, { memo } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

import MetaSiteSettings from "./metaSiteSettings";
import CookieBanner from "../../components/consent/cookieBanner";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import Notification from "../../components/notification/notification";
import RegistrationReminder from "../../components/registrationReminder/registrationReminder";
import SkipLink from "../../components/skipLink/skipLink";
import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import KontentItemImageBannerLightbox from "../kontentItemImageBannerLightbox";
import KontentItemTopBannerAction from "../kontentItemTopBannerAction";
import KontentItemTopBannerInfo from "../kontentItemTopBannerInfo";

const MainLayout = ({ children, hideNavigation, headerLogo }) => {
  const isGreaterLg = useMediaQuery("(min-width: 992px)", false);

  return (
    <Container fluid>

      <SkipLink target="#main">
        <FormattedMessage id="navigation.skip_to_content" />
      </SkipLink>
      <SkipLink target="#footer">
        <FormattedMessage id="navigation.skip_to_footer" />
      </SkipLink>

      <MetaSiteSettings />

      <RegistrationReminder />

      {isGreaterLg && <KontentItemTopBannerInfo />}

      <KontentItemTopBannerAction />

      <KontentItemImageBannerLightbox />

      <Header hideNavigation={hideNavigation} headerLogo={headerLogo} />

      {!isGreaterLg && <KontentItemTopBannerInfo />}

      <main id="main">{children}</main>

      <Footer />

      <CookieBanner />

      <Notification />
    </Container>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  headerLogo: PropTypes.oneOfType([PropTypes.object]),
  hideNavigation: PropTypes.bool,
};

MainLayout.defaultProps = {
  children: null,
  headerLogo: null,
  hideNavigation: false,
};

export default memo(MainLayout);
