import React from "react";

import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

import "./topicIntroductionSection.scss";

const TopicIntroductionSection = ({ children }) => (
  <div className="topic-introduction-section container--fullwidth">
    <Container fluid>
      {children}
    </Container>
  </div>
);

TopicIntroductionSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TopicIntroductionSection;
