import React from "react";

import PropTypes from "prop-types";

import { IconFax18, IconPhone18 } from "../../icons";

import "./contactOffice.scss";

const ContactOffice = (props) => {
  const {
    office,
    street,
    city,
    phone,
    fax,
  } = props;

  return (
    <div className="contact-office">
      {office}
      <div className="contact-office__address">
        {street}<br />{city}
      </div>
      {phone && <a href={`tel:${phone}`} title={`Call ${phone}`}><IconPhone18 />{phone}</a>}
      {fax && <a href={`fax:${fax}`} title={`Send a fax to ${fax}`}><IconFax18 />{fax}</a>}
    </div>
  );
};

ContactOffice.propTypes = {
  city: PropTypes.string.isRequired,
  fax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  office: PropTypes.string.isRequired,
  phone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  street: PropTypes.string.isRequired,
};

ContactOffice.defaultProps = {
  fax: null,
  phone: null,
};

export default ContactOffice;
