import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { format } from "../../utils/dateHelper";
import Divider from "../divider/divider";
import SocialMediaShare from "../socialMediaShare/socialMediaShare";
import TableOfContents from "../tableOfContents/tableOfContents";

import "./infoSection.scss";

const InfoSection = (props) => {
  const {
    date,
    showTableOfContents,
    author,
    hideLastUpdateLabel,
    disableShareButtons,
  } = props;

  let contentOfTable = null;

  if (showTableOfContents) {
    contentOfTable = TableOfContents();
  }

  return (
    <Row className="info-section mt-5">
      {contentOfTable
        ? (
          <Col sm={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
            <Row className="d-md-flex justify-content-md-between mb-0">
              <Col xs={12} md={4} className="info-section__social-media">
                <div className="info-section__last-updated-container flex-md-column">
                  {!disableShareButtons && <SocialMediaShare className="order-md-last" />}
                  {date && (
                    <div className="info-section__last-updated">
                      {!hideLastUpdateLabel && <><FormattedMessage id="info_section.last_updated" />&nbsp;</>}{format(date)}
                    </div>
                  )}
                </div>
                {author}
              </Col>
              <Col xs={12} md={8} lg={8} className="order-md-0">
                {contentOfTable}
              </Col>
            </Row>
          </Col>
        ) : (
          <Col
            sm={{ offset: 1, span: 10 }}
            lg={{ offset: 2, span: 8 }}
            className={classNames({ "d-md-flex justify-content-md-between": author })}
          >
            <div
              className={classNames(
                "info-section__last-updated-container order-md-last",
                { "flex-md-column mb-5": author },
                { "d-block": !author && disableShareButtons && date },
              )}
            >
              {}
              {!disableShareButtons
                && <><SocialMediaShare className={author ? "order-md-last" : "mt-md-0"} /></>}
              {date && (
                <div className="info-section__last-updated">
                  <div className="flex-shrink-0">{!hideLastUpdateLabel && <><FormattedMessage id="info_section.last_updated" />&nbsp;</>}{format(date)}</div>
                  {disableShareButtons && <Divider />}
                </div>
              )}
            </div>
            {author}
          </Col>
        )}
    </Row>
  );
};

InfoSection.propTypes = {
  author: PropTypes.oneOfType([PropTypes.object]),
  date: PropTypes.instanceOf(Date),
  disableShareButtons: PropTypes.bool,
  hideLastUpdateLabel: PropTypes.bool,
  showTableOfContents: PropTypes.bool,
};

InfoSection.defaultProps = {
  author: null,
  date: null,
  disableShareButtons: false,
  hideLastUpdateLabel: false,
  showTableOfContents: false,
};

export default InfoSection;
