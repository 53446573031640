import React from "react";

import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Banner from "./banner";
import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import KontentItemImage from "../../templates/kontentItemImage";
import NavigationLink from "../navigationLink/navigationLink";
import StateAwareAccordionToggle from "../stateAwareAccordionToggle/stateAwareAccordionToggle";

import "./faqBanner.scss";

const FaqBanner = (props) => {
  const {
    title,
    buttonText,
    buttonUrl,
    imageData,
    faqItems,
    maxItems,
  } = props;

  const isHigherMd = useMediaQuery("(min-width: 768px)");
  const isLowerXl = useMediaQuery("(max-width: 1199px)");

  if (faqItems.length > maxItems) {
    faqItems.length = maxItems;
  }

  let image = <KontentItemImage data={imageData} width={800} imageOnlyWidth aspectRatio={16 / 9} />;

  if (isHigherMd) {
    image = <KontentItemImage data={imageData} width={600} crop aspectRatio={3 / 4} />;
  }

  let defaultActiveKeyObject = {};

  if (typeof window !== "undefined") {
    const { hash } = window.location;

    if (hash !== "") {
      const accordionItem = faqItems.find((item) => `#${item.anchor_slug}` === hash);

      if (accordionItem) {
        defaultActiveKeyObject = { defaultActiveKey: accordionItem.id };
      }
    }
  }

  return (
    <Banner
      className="faq-banner"
      mediaObject={image}
    >
      <Row className="faq-banner__inner">
        <Col md={7}>
          <h3 className="typo-h2">{title}</h3>
          <Accordion className="faq-banner__items" {...defaultActiveKeyObject}>
            {faqItems.slice(0, isLowerXl ? 3 : faqItems.length).map((item, i) => (
              <div
                className="faq-banner__item"
                key={item.question}
                {...(item.anchorSlug ? { id: item.anchorSlug } : {})}
              >
                <StateAwareAccordionToggle
                  as="div"
                  eventKey={i.toString()}
                >
                  <h4 className="d-flex justify-content-between align-items-center">
                    {item.question}
                  </h4>
                </StateAwareAccordionToggle>

                <Accordion.Collapse className="faq-banner__item-answer" eventKey={i.toString()}>
                  <>
                    {item.answer}
                  </>
                </Accordion.Collapse>

                <hr className="faq-banner__seperator" />
              </div>
            ))}
          </Accordion>
          <NavigationLink href={buttonUrl} className="btn btn-plain-faq">
            {buttonText}
          </NavigationLink>
        </Col>
      </Row>
    </Banner>
  );
};

FaqBanner.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  faqItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  imageData: PropTypes.oneOfType([PropTypes.object]),
  maxItems: PropTypes.number,
  title: PropTypes.string.isRequired,
};

FaqBanner.defaultProps = {
  imageData: null,
  maxItems: 6,
};

export default FaqBanner;
