import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, ref, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import CleaveFormField from "../../dynamicForm/cleaveFormField/cleaveFormField";
import FormField from "../../dynamicForm/formField/formField";
import Tooltip from "../../localForms/tooltip";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationAdditionalInformationUs = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    additionalPhoneNumber: string()
      .matches(
        /^(\d{3}-\d{3}-\d{4})$/,
        "This value seems to be invalid. The phone number follows the format xxx-xxx-xxxx.",
      ),
    confirmEmailAddress: string()
      .required(requiredMessage)
      .oneOf([ref("emailAddress")], intl.formatMessage({ id: "registration.additional.error.email_confirm" })),
    emailAddress: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .email(intl.formatMessage({ id: "registration.additional.error.email" }))
      .test(
        "not_allowed_email",
        intl.formatMessage({ id: "registration.additional.error.email" }),
        (v) => !/@.*\.edu$/.test(v),
      ),
    mobileNumber: string()
      .required(requiredMessage)
      .matches(
        /^(\d{3}-\d{3}-\d{4})$/,
        "This value seems to be invalid. The phone number follows the format xxx-xxx-xxxx.",
      ),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.additional.title"
      />
      <Formik
        initialValues={{
          additionalPhoneNumber: registrationData.additionalPhoneNumber,
          confirmEmailAddress: registrationData.emailAddress,
          emailAddress: registrationData.emailAddress,
          mobileNumber: registrationData.mobileNumber,
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              additionalPhoneNumber: values.additionalPhoneNumber,
              emailAddress: values.emailAddress,
              mobileNumber: values.mobileNumber,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_additional_information", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={6}>
                <CleaveFormField
                  label={intl.formatMessage({ id: "registration.additional.mobileNumber" })}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  id="mobileNumber"
                  errors={errors}
                  options={{
                    blocks: [3, 3, 4],
                    delimiter: "-",
                    numericOnly: true,
                  }}
                  maxLength={12}
                  required
                />
              </Col>
              <Col xs={6}>
                <CleaveFormField
                  label={intl.formatMessage({ id: "registration.additional.additionalPhoneNumber" })}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  id="additionalPhoneNumber"
                  errors={errors}
                  options={{
                    blocks: [3, 3, 4],
                    delimiter: "-",
                    numericOnly: true,
                  }}
                  maxLength={12}
                  required
                />
              </Col>
              <Col xs={12}>
                <Tooltip
                  id="emailAddress-tooltip"
                  tooltipMessage={intl.formatMessage({ id: "registration.email.tooltip" })}
                >
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="emailAddress"
                    label={intl.formatMessage({ id: "registration.address.email" })}
                    type="email"
                    maxLength={100}
                    touched={touched}
                    errors={errors}
                    values={values}
                    required
                  />
                </Tooltip>
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="confirmEmailAddress"
                  label={intl.formatMessage({ id: "registration.address.email_confirm" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.additional.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationAdditionalInformationUs.stepName = "registration_additional_information";

RegistrationDialogStepRegistrationAdditionalInformationUs.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationAdditionalInformationUs;
