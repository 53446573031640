import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import { startCase } from "../../utils/stringUtilHelper";

const BreadcrumbGenerator = ({ lastItemAs, id }) => {
  const data = useStaticQuery(graphql`
    {
      links: allInternalLinks {
        nodes {
          id
          title
          slug
          navigationItemId
        }
      }
    }
  `);

  if (id) {
    const node = data.links.nodes.find(
      (child) => child.id.includes(id),
    );

    if (node) {
      const slugParts = node.slug.split("/");
      // Remove first empty element
      slugParts.shift();

      const items = [];
      let slug = "";

      if (slugParts.length > 0 && slugParts[0]) {
        slugParts.forEach((slugPart) => {
          slug += `/${slugPart}`;
          const tmpNode = data.links.nodes.find((child) => child.slug === slug);

          if (tmpNode) {
            items.push({ link: tmpNode.slug, title: tmpNode.title });
          } else {
            items.push({ title: startCase(slugPart) });
          }
        });
      }

      const itemListElement = [{
        "@type": "ListItem",
        item: process.env.GATSBY_SITE_URL,
        name: "Home",
        position: "1",
      }];

      items.forEach((item, index) => {
        itemListElement.push({
          "@type": "ListItem",
          item: `${process.env.GATSBY_SITE_URL}${item.link}`,
          name: item.title,
          position: (index + 2).toString(),
        });
      });

      return (
        <>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement,
              })}
            </script>
          </Helmet>
          <Breadcrumb items={items} lastItemAs={lastItemAs} />
        </>
      );
    }
  }

  return null;
};

BreadcrumbGenerator.propTypes = {
  id: PropTypes.string.isRequired,
  lastItemAs: PropTypes.oneOf(["h1", "li"]),
};

BreadcrumbGenerator.defaultProps = {
  lastItemAs: "li",
};

export default BreadcrumbGenerator;
