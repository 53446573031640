import React, { useState } from "react";

import PropTypes from "prop-types";

import DonationHeader from "../../components/donationHeader/donationHeader";
import backgroundImageHelper from "../image/backgroundImageHelper";
import KontentItemDonationBox from "../kontentItemDonationBox";

const DonationBoxHeader = ({ backgroundImage, donationBoxData }) => {
  const [currentImpact, setCurrentImpact] = useState(null);

  return (
    <DonationHeader
      backgroundImage={backgroundImageHelper(backgroundImage)}
      icon={currentImpact?.icon}
      text={currentImpact?.text}
    >
      <KontentItemDonationBox
        className="bg-white"
        data={donationBoxData}
        onInit={({ impact }) => {
          setCurrentImpact(impact);
        }}
        onChange={({ impact }) => {
          setCurrentImpact(impact);
        }}
        withRequiredText
      />
    </DonationHeader>
  );
};

DonationBoxHeader.propTypes = {
  backgroundImage: PropTypes.oneOfType([PropTypes.object]).isRequired,
  donationBoxData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

DonationBoxHeader.defaultProps = {
  donationBoxData: undefined,
};

export default DonationBoxHeader;
