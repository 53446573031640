import React from "react";

import Templates from "./megaMenuResultTemplates";
import { camelCase } from "../../utils/stringUtilHelper";

const mapMegaMenuResultItems = (item) => {
  let output = ("");

  if (item?.internal?.type) {
    const type = camelCase(item.internal.type);
    const TagName = Templates[`${type.charAt(0).toUpperCase()}${type.slice(1)}`];

    if (!TagName) return (<div className="alert alert-warning">## missing element ##</div>);

    try {
      output = (<TagName data={item} key={item.id} />);
    } catch (e) {
      output = ("Error");
    }
  }

  return output;
};

export default mapMegaMenuResultItems;
