import { cleanupObject } from "../../utils/formik/objectUtils";

export default class PaymentDataHelper {
  static maxAmount = {
    de: {
      HALFYEARLY: 500,
      MONTHLY: 500,
      ONCE: 5000,
      QUARTERLY: 500,
      YEARLY: 500,
    },
    gb: {
      ONCE: 10000,
    },
    in: {
      ONCE: 10000,
    },
    pl: {
      ONCE: 10000,
    },
  };

  static #getTitleGb = (salutation) => (["Mr.", "Mrs.", "Ms.", "Miss"].includes(salutation) ? salutation : "");

  static #getSalutationGb = (salutation) => (["Dr.", "Prof."].includes(salutation) ? salutation : "");

  static preparePayload(data, returnUrl) {
    let payload;

    switch (process.env.GATSBY_SITE) {
      case "de":
        payload = PaymentDataHelper.#getPostDataDe(data, returnUrl);
        break;
      case "gb":
        payload = PaymentDataHelper.#getPostDataGb(data, returnUrl);
        break;
      case "pl":
        payload = PaymentDataHelper.#getPostDataPl(data, returnUrl);
        break;
      default:
        payload = {};
    }

    return cleanupObject(payload);
  }

  static #getPostDataDe = (data, returnUrl) => ({
    company: {
      name: data.companyName,
    },
    md_data: {
      amount: parseFloat(data.amount),
      donationReceiptRequired: data.donationReceipt,
      interval: data.interval,
      return_url: returnUrl,
    },
    person: {
      firstname: data.firstname,
      lastname: data.lastname,
      sex: data.gender,
      title: data.salutation,
    },
    private_address: {
      city: data.city || data.companyCity,
      houseno: data.houseNo || data.companyHouseNo,
      street: data.street || data.companyStreet,
      zipcode: data.postCode || data.companyPostCode,
    },
    private_contact: {
      email: data.emailAddress,
    },
  });

  static #getPostDataGb = (data, returnUrl) => (data.interval === "ONCE"
    ? {
      marketing_consent: {
        email: data.keepInTouchEmail,
        post: data.keepInTouchPost,
      },
      md_data: {
        amount: parseFloat(data.amount),
        gift_aid: data.agreeGiftAid ? "YES" : "NO",
        return_url: returnUrl,
      },
      person: {
        firstname: data.firstname,
        lastname: data.lastname,
        salutation: PaymentDataHelper.#getTitleGb(data.salutation),
        sex: data.gender,
        title: PaymentDataHelper.#getSalutationGb(data.salutation),
      },
      private_address: {
        apartment: data.apartment,
        building: data.houseName,
        city: data.city,
        houseno: data.houseNo,
        state: data.county,
        street: data.street,
        zipcode: data.postCode,
      },
      private_contact: {
        email: data.emailAddress,
      },
    }
    : {
      marketing_consent: {
        email: data.keepInTouchEmail,
        post: data.keepInTouchPost,
      },
      md_data: {
        account: {
          name: data.accountHolderName,
          number: data.accountNumber,
          sort_code: data.sortCode,
        },
        amount: parseFloat(data.amount),
        gift_aid: data.agreeGiftAid ? "YES" : "NO",
        interval: data.interval,
        return_url: returnUrl,
      },
      person: {
        firstname: data.firstname,
        lastname: data.lastname,
        salutation: PaymentDataHelper.#getTitleGb(data.salutation),
        sex: data.gender,
        title: PaymentDataHelper.#getSalutationGb(data.salutation),
      },
      private_address: {
        apartment: data.apartment,
        building: data.houseName,
        city: data.city,
        houseno: data.houseNo,
        state: data.county,
        street: data.street,
        zipcode: data.postCode,
      },
      private_contact: {
        email: data.emailAddress,
      },
    });

  static #getPostDataPl = (data, returnUrl) => ({
    md_data: {
      amount: parseFloat(data.amount),
      return_url: returnUrl,
    },
    person: {
      firstname: data.firstname,
      lastname: data.lastname,
      sex: data.gender,
    },
    private_address: {
      apartment: data.apartment,
      city: data.city,
      co: data.careOfAddress,
      houseno: data.houseNo,
      street: data.street,
      zipcode: data.postCode,
    },
    private_contact: {
      email: data.emailAddress,
    },
  });
}
