import React from "react";

import { RichTextElement } from "@kentico/gatsby-kontent-components";
import sanitizeHtml from "sanitize-html";

import NavigationLink from "../../components/navigationLink/navigationLink";
import mapSimpleKontentItem from "../../utils/mapSimpleKontentItem";
import KontentAssetsImage from "../kontentAssetsImage";

const SimpleRichText = (data) => {
  const {
    data: {
      value,
      images,
      links,
      modular_content: modularContent,
    },
  } = data;

  if (!value || value === "<p><br></p>") {
    return null;
  }

  // cleanup value
  const text = sanitizeHtml(value.replace(/&nbsp;/g, " "), {
    allowVulnerableTags: true, // todo: remove
    allowedAttributes: false,
    allowedTags: false, // todo: define allowed tags
    exclusiveFilter: (frame) => ["script", "style"].includes(frame.tag),
  });

  return (
    <div className="text--simple">
      <RichTextElement
        value={text}
        images={images}
        links={links}
        linkedItems={modularContent}
        resolveImage={(image) => (
          <KontentAssetsImage
            data={image}
          />
        )}
        resolveLink={(link, domNode) => {
          if (link) {
            return (
              <NavigationLink href={link.link_id}>
                {domNode.children[0].data}
              </NavigationLink>
            );
          }

          return <>{domNode.children[0].data}</>;
        }}
        resolveLinkedItem={
          (linkedItem) => mapSimpleKontentItem(linkedItem)
        }
        resolveDomNode={(domNode, domToReact) => {
          if (["h1", "h2", "h3", "h4", "h5"].includes(domNode.name)) {
            // check https://github.com/remarkablemark/html-react-parser#readme
            return domToReact(
              [domNode],
              {
                // eslint-disable-next-line react/display-name
                replace: (dom) => (<div className={`title-${dom.name}`}>{domToReact(dom.children)}</div>),
              },
            );
          }

          return domToReact(domNode);
        }}
      />
    </div>
  );
};

export default SimpleRichText;
