import { useIntl } from "gatsby-plugin-react-intl";

const errorHelper = (errorData) => {
  const intl = useIntl();

  return {
    message: errorData?.status ? intl.formatMessage({ id: `error.status_${errorData?.status}` }) : "",
    status: errorData?.status,
  };
};

export default errorHelper;
