import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import useIntersection from "../../hooks/useIntersection";
import { placeholderTypes } from "../imagePlaceholder/imagePlaceholder";

const BackgroundImage = (props) => {
  const {
    backgroundImageStyle,
    className,
    children,
    placeholderTyp,
    ...otherProps
  } = props;

  const ref = useRef(null);

  const inViewport = useIntersection(ref, "200px");

  const [loaded, setLoaded] = useState(inViewport);

  const placeholderStyle = {
    backgroundColor: "#F2F2F7",
    backgroundImage: `url('/images/placeholder/${placeholderTypes[placeholderTyp]}.webp')`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  useEffect(() => {
    if (inViewport) {
      setLoaded(true);
    }
  }, [inViewport]);

  return (
    <div
      className={className}
      style={loaded ? backgroundImageStyle : placeholderStyle}
      ref={ref}
      {...otherProps}
    >
      {children}
    </div>
  );
};

BackgroundImage.propTypes = {
  backgroundImageStyle: PropTypes.oneOfType([PropTypes.object]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  placeholderTyp: PropTypes.oneOf(["maps", "events", "icon", "news", "virtual-drive"]),
};

BackgroundImage.defaultProps = {
  children: null,
  className: null,
  placeholderTyp: "icon",
};

export default BackgroundImage;
