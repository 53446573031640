/* eslint-disable sort-keys */
import * as Yup from "yup";

import { usStates } from "./general-form-data";
import { format } from "../../../utils/dateHelper";
import { clean } from "../formBuilder";
import Validators from "../validations/validators";

const getUpdateAddressForm = (intl) => {
  const validators = new Validators(intl);
  const REQUIRED_ERROR = intl.formatMessage({ id: "form.required" });

  /* eslint-disable sort-keys */
  return [
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "personal_info_header",
        headline: "personal_info.title",
        description: "personal_info.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "donor_id",
        name: "donor_id",
        label: intl.formatMessage({ id: "donor_id" }),
        type: "text",
        required: false,
        maxLength: 9,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("donor_id", false),
        ],
      },
      tooltipMessage: intl.formatMessage({ id: "tooltip.donor_id" }),
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "DateInput",
      props: {
        id: "birthdate",
        name: "birthdate",
        label: intl.formatMessage({ id: "registration.age.birthdate" }),
        type: "date",
        required: true,
        placeholder: "",
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInPast", true),
        ],
      },
      initialValue: "",
      tooltipMessage: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "firstname",
        name: "firstname",
        label: intl.formatMessage({ id: "registration.personal.firstname" }),
        type: "text",
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("firstname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "lastname",
        name: "lastname",
        label: intl.formatMessage({ id: "registration.personal.lastname" }),
        type: "text",
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "former_lastname",
        name: "former_lastname",
        label: intl.formatMessage({ id: "personal_info.former_last_name" }),
        type: "text",
        required: false,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "previous_address_header",
        headline: "previous_address.title",
        description: "",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "CheckboxInput",
      props: {
        id: "address_unchanged",
        name: "address_unchanged",
        label: intl.formatMessage({ id: "address_unchanged" }),
        type: "checkbox",
        lg: true,
      },
      validation: {
        type: "bool",
        validators: [],
      },
      initialValue: false,
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "FormAddressInput",
      props: {
        id: "prev_address_input",
        name: "prev_address_input",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        fieldType: "prev",
        subFields: [
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "prev_house_no",
              name: "prev_house_no",
              label: intl.formatMessage({ id: "registration.address.houseNo" }),
              type: "text",
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("houseno", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "prev_street",
              name: "prev_street",
              label: intl.formatMessage({ id: "registration.address.street" }),
              type: "text",
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("street", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "prev_apartment",
              name: "prev_apartment",
              label: intl.formatMessage({ id: "registration.address.apartment" }),
              type: "text",
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("apartment"),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "prev_city",
              name: "prev_city",
              label: intl.formatMessage({ id: "registration.address.city" }),
              type: "text",
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("city", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "SelectInput",
            props: {
              id: "prev_state",
              name: "prev_state",
              label: intl.formatMessage({ id: "state" }),
              type: "text",
              required: true,
            },
            options: usStates,
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("state", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "prev_postcode",
              name: "prev_postcode",
              label: intl.formatMessage({ id: "registration.address.postCode" }),
              type: "text",
              required: true,
              maxLength: 5,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("zipcode", false),
              ],
            },
            initialValue: "",
          },
        ],
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "current_address_header",
        headline: "current_address.title",
        description: "",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "FormAddressInput",
      props: {
        id: "current_address_input",
        name: "current_address_input",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        fieldType: "current",
        subFields: [
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "current_house_no",
              name: "current_house_no",
              label: intl.formatMessage({ id: "registration.address.houseNo" }),
              type: "text",
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("houseno", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "current_street",
              name: "current_street",
              label: intl.formatMessage({ id: "registration.address.street" }),
              type: "text",
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("street", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "current_apartment",
              name: "current_apartment",
              label: intl.formatMessage({ id: "registration.address.apartment" }),
              type: "text",
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("apartment", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "current_city",
              name: "current_city",
              label: intl.formatMessage({ id: "registration.address.city" }),
              type: "text",
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("city", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "SelectInput",
            props: {
              id: "current_state",
              name: "current_state",
              label: intl.formatMessage({ id: "state" }),
              type: "text",
              required: true,
            },
            options: usStates,
            validation: {
              type: "string",
              validators: [
                ...validators.get("state", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "current_postcode",
              name: "current_postcode",
              label: intl.formatMessage({ id: "registration.address.postCode" }),
              type: "text",
              required: true,
              maxLength: 5,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("zipcode", true),
              ],
            },
            initialValue: "",
          },
        ],
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "other_details_header",
        headline: "other_details.title",
        description: "",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "CleaveInput",
      props: {
        id: "mobile",
        name: "mobile",
        label: intl.formatMessage({ id: "registration.additional.mobileNumber" }),
        type: "text",
        required: true,
        maxLength: 12,
        options: {
          blocks: [3, 3, 4],
          delimiter: "-",
          numericOnly: true,
        },
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("phone", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "CleaveInput",
      props: {
        id: "landline",
        name: "landline",
        label: intl.formatMessage({ id: "registration.additional.additionalPhoneNumber" }),
        type: "text",
        required: false,
        maxLength: 12,
        options: {
          blocks: [3, 3, 4],
          delimiter: "-",
          numericOnly: true,
        },
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("phone", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "email",
        name: "email",
        label: intl.formatMessage({ id: "registration.additional.email" }),
        type: "text",
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("email", true),
          ...validators.get("email_no_edu", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "validity_date_header",
        headline: "validity_date.title",
        description: "validity_date.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: true,
      },
      tagName: "DateInput",
      props: {
        id: "validity_date",
        name: "validity_date",
        label: intl.formatMessage({ id: "validity_date.label" }),
        type: "date",
        required: true,
        placeholder: "",
        value: new Date(),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInFuture", true),
          ...validators.get("dateNotToFarInFuture"),
        ],
      },
      initialValue: format(new Date(), "yyyy-MM-dd"),
      tooltipMessage: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "data_protection_header",
        headline: "dataprotection.title",
        description: "dataprotection.text",
        type: "header",
      },
    },
  ];
};

const generatePayload = (values) => {
  /* eslint-disable sort-keys */
  const payload = {
    person: {
      firstname: values.firstname,
      lastname: values.lastname,
      former_lastname: values.former_lastname,
      birthdate: values.birthdate,
      donor_id: values.donor_id.toUpperCase(),
    },
    former_address: {
      street: values.prev_street,
      houseno: values.prev_house_no,
      zipcode: values.prev_postcode,
      city: values.prev_city,
      state: values.prev_state,
      apartment: values.prev_apartment,
    },
    private_address: {
      street: values.current_street,
      houseno: values.current_house_no,
      zipcode: values.current_postcode,
      city: values.current_city,
      state: values.current_state,
      apartment: values.current_apartment,
    },
    private_contact: {
      phone: values.landline,
      mobile: values.mobile,
      email: values.email,
    },
    ciu_data: {
      validity_date: values.validity_date,
    },
  };

  return clean(payload);
};

export default { getUpdateAddressForm, generatePayload };
