import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";

import ArticleClosure from "../../components/articleClosure/articleClosure";
import HeroCampaign from "../../components/heroHeadline/heroCampaign";
import SEO from "../../components/seo";
import { setTracking } from "../../features/trackingSlice";
import mapKontentItem from "../../utils/mapKontentItem";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import RichText from "../text/richText";

const KontentItemCareersPage = ({ data }) => {
  const dispatch = useDispatch();

  dispatch(
    setTracking({
      URN: data.page.elements.urn_primary.value,
      secondaryURN: data.page.elements.urn_secondary.value,
    }),
  );

  return (
    <MainLayout>

      <SEO
        title={data.page.elements.metadata__meta_title.value}
        description={data.page.elements.metadata__meta_description.value}
        twitterTitle={data.page.elements.metadata__twitter_title.value}
        twitterSite={data.page.elements.metadata__twitter_site.value}
        twitterImage={data.page.elements.metadata__twitter_image.value}
        twitterDescription={data.page.elements.metadata__twitter_description.value}
        twitterCreator={data.page.elements.metadata__twitter_creator.value}
        ogTitle={data.page.elements.metadata__og_title.value}
        ogImage={data.page.elements.metadata__og_image.value}
        ogDescription={data.page.elements.metadata__og_description.value}
      />

      <HeroCampaign
        eyecatcher={
          data.page.elements.hero_eyecatcher.value.length > 0
            ? mapKontentItem(data.page.elements.hero_eyecatcher.value[0])
            : null
        }
        imageData={data.page.elements.hero_image}
      >
        {data.page.elements.hero_headline.value}
      </HeroCampaign>

      <BreadcrumbGenerator id={data.page.id} />

      <RichText data={data.page.elements.body} isArticle />

      <Row>
        <Col md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
          <ArticleClosure />
        </Col>
      </Row>

      {data.page.elements.card_slider.value.map(
        (item) => mapKontentItem(item, { pageId: data.page.id }),
      )}

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemCareersPage(id: {eq: $id}) {
      id
      system {
        last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
      }
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        hero_eyecatcher {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
          }
        }
        card_slider {
          value {
            internal {
              type
            }
            ...KontentItemIconCardSliderSection
            ...KontentItemImageCardSliderSection
          }
        }
        urn_primary {
          value
        }
        urn_secondary {
          value
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            internal {
              type
            }
            system {
              codename
            }
            ...KontentItemEyecatcherImage
            ...KontentItemProcessStepGroup
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemCtaButton
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemStatementboxGroup
            ...KontentItemEmbedExternal
          }
        }
      }
    }
  }
`;

KontentItemCareersPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemCareersPage;
