import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconArticle18, IconClose18 } from "../../icons";
import LinkButton from "../button/linkButton";
import IconText from "../iconText/iconText";

import "./donorPathPopover.scss";

const DonorPathPopover = (props) => {
  const {
    stepNumber,
    topic,
    description,
    articles,
    className,
    onclick,
    getRef,
  } = props;

  return (
    <div className={classNames("donor-path-popover", (className !== null) && className)} ref={getRef}>
      <div className="donor-path-popover__header">
        <div className="donor-path-popover__content">
          <div className="donor-path-popover__headline">
            <div className="donor-path-popover__step-number">{stepNumber}</div>
            <div className="donor-path-popover__topic">{topic}</div>
          </div>
          <div className="donor-path-popover__description">{description}</div>
        </div>
        <div
          role="presentation"
          className="donor-path-popover__close-icon"
          onClick={onclick}
        >
          <IconClose18 />
        </div>
      </div>
      {
        articles
        && articles.length > 0
        && (
          <>
            <div className="donor-path-popover__article-headline"><FormattedMessage id="topic_header.article" /></div>
            <div className="donor-path-popover__articles">
              {articles.map((item) => (
                <LinkButton href={item.href} key={item.id} className="donor-path-popover__article">
                  <IconText icon={<IconArticle18 />} variant="donor-path" text={item.text} />
                </LinkButton>
              ))}
            </div>
          </>
        )
      }
    </div>
  );
};

DonorPathPopover.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  getRef: PropTypes.oneOfType([PropTypes.func]),
  onclick: PropTypes.oneOfType([PropTypes.func]),
  stepNumber: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
};

DonorPathPopover.defaultProps = {
  articles: [],
  className: null,
  getRef: null,
  onclick: null,
};

export default DonorPathPopover;
