import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";

const KontentItemSectionHeader = ({ data }) => {
  const { elements } = data;

  if (elements.forced_linebreak.value.length > 0) {
    return (<Col xs={12} />);
  }

  return (
    <Col xs={12} className={elements.headline.value ? "my-48" : "mt-48"}>
      {elements.headline.value && <h3>{elements.headline.value}</h3>}
      {elements.description.value !== "" && (
        <p className="text-grey-dark">{elements.description.value}</p>
      )}
    </Col>
  );
};

export const query = graphql`
  fragment KontentItemSectionHeader on kontent_item_section_header {
    id
    elements {
      headline {
        value
      }
      description {
        value
      }
      forced_linebreak {
        value {
          codename
        }
      }
    }
  }
`;

KontentItemSectionHeader.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemSectionHeader;
