import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationEthnicityCl = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const validationSchema = useMemo(() => object().shape({
    ethnicityFather: string()
      .nullable(),
    ethnicityMother: string()
      .nullable(),
    stateFather: string()
      .required("Required"),
    stateMother: string()
      .required("Required"),
  }), []);

  const {
    ethnicityOptions,
    stateOptions,
  } = RegistrationDialogStepRegistrationEthnicityCl;

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.ethnicity.title"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          if (values.stateFather === "CL" && !values.ethnicityFather) {
            errors.ethnicityFather = intl.formatMessage({ id: "form.required" });
          }

          if (values.stateMother === "CL" && !values.ethnicityMother) {
            errors.ethnicityMother = intl.formatMessage({ id: "form.required" });
          }

          return errors;
        }}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              ethnicityFather: values.stateFather === "CL" ? values.ethnicityFather : null,
              ethnicityMother: values.stateMother === "CL" ? values.ethnicityMother : null,
              stateFather: values.stateFather,
              stateMother: values.stateMother,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_ethnicity", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <DropdownFormField
                  label={(
                    <>{intl.formatMessage({ id: "registration.ethnicity.state" })}
                      ({intl.formatMessage({ id: "registration.ethnicity.father" })})
                    </>
                  )}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("stateFather", target.value);
                      setFieldError("stateFather", undefined);

                      if (target.value === "CL") {
                        setFieldTouched("ethnicityFather", true);
                      }
                    } else {
                      setFieldValue("stateFather", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("stateFather", true);
                  }}
                  touched={touched}
                  id="stateFather"
                  errors={errors}
                  values={values}
                  options={stateOptions}
                  required
                />
              </Col>
              {values.stateFather === "CL" && (
                <Col xs={12}>
                  <DropdownFormField
                    label={(
                      <>{intl.formatMessage({ id: "registration.ethnicity.ethnicity" })}
                        ({intl.formatMessage({ id: "registration.ethnicity.father" })})
                      </>
                    )}
                    placeholder={intl.formatMessage({ id: "registration.please_select" })}
                    onChange={(selectedOption) => {
                      const { target } = selectedOption;

                      if (target && target.value) {
                        setFieldValue("ethnicityFather", target.value);
                        setFieldError("ethnicityFather", undefined);
                      } else {
                        setFieldValue("ethnicityFather", "");
                      }
                    }}
                    onBlur={() => {
                      setFieldTouched("ethnicityFather", true);
                    }}
                    touched={touched}
                    id="ethnicityFather"
                    errors={errors}
                    values={values}
                    options={ethnicityOptions}
                    required
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12}>
                <DropdownFormField
                  label={(
                    <>{intl.formatMessage({ id: "registration.ethnicity.state" })}
                      ({intl.formatMessage({ id: "registration.ethnicity.mother" })})
                    </>
                  )}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("stateMother", target.value);
                      setFieldError("stateMother", undefined);

                      if (target.value === "CL") {
                        setFieldTouched("ethnicityMother", true);
                      }
                    } else {
                      setFieldValue("stateMother", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("stateMother", true);
                  }}
                  touched={touched}
                  id="stateMother"
                  errors={errors}
                  values={values}
                  options={stateOptions}
                  required
                />
              </Col>
              {values.stateMother === "CL" && (
                <Col xs={12}>
                  <DropdownFormField
                    label={(
                      <>{intl.formatMessage({ id: "registration.ethnicity.ethnicity" })}
                        ({intl.formatMessage({ id: "registration.ethnicity.mother" })})
                      </>
                    )}
                    placeholder={intl.formatMessage({ id: "registration.please_select" })}
                    onChange={(selectedOption) => {
                      const { target } = selectedOption;

                      if (target && target.value) {
                        setFieldValue("ethnicityMother", target.value);
                        setFieldError("ethnicityMother", undefined);
                      } else {
                        setFieldValue("ethnicityMother", "");
                      }
                    }}
                    onBlur={() => {
                      setFieldTouched("ethnicityMother", true);
                    }}
                    touched={touched}
                    id="ethnicityMother"
                    errors={errors}
                    values={values}
                    options={ethnicityOptions}
                    required
                  />
                </Col>
              )}
            </Row>

            <Row>
              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationEthnicityCl.ethnicityOptions = [
  { label: "No pertenece a pueblos indígenas", value: "NONINDIGENOUS" },
  { label: "No lo sabe", value: "DONTKNOW" },
  { label: "Prefiere no decir", value: "DONTTELL" },
  {
    label: "Pertenece a:",
    options: [
      { label: "Mapuche", value: "MAPUCHE" },
      { label: "Aymara", value: "AYMARA" },
      { label: "Rapa Nui", value: "RAPA NUI" },
      { label: "Likan Antai", value: "LIKAN ANTAI" },
      { label: "Quechua", value: "QUECHUA" },
      { label: "Colla", value: "COLLA" },
      { label: "Diaguita", value: "DIAGUITA" },
      { label: "Kawesqar", value: "KAWESQAR" },
      { label: "Yagan/Yámana", value: "YAGAN/YAMA" },
      { label: "Otros pueblos indígenas", value: "OTHERINDIGENOUS" },
    ],
  },
];

RegistrationDialogStepRegistrationEthnicityCl.stateOptions = [
  { label: "Chile", value: "CL" },
  { label: "Afganistán", value: "AF" },
  { label: "Albania", value: "AL" },
  { label: "Alemania", value: "DE" },
  { label: "Algeria", value: "DZ" },
  { label: "Andorra", value: "AD" },
  { label: "Angola", value: "AO" },
  { label: "Anguila", value: "AI" },
  { label: "Antártida", value: "AQ" },
  { label: "Antigua y Barbuda", value: "AG" },
  { label: "Arabia Saudita", value: "SA" },
  { label: "Argentina", value: "AR" },
  { label: "Armenia", value: "AM" },
  { label: "Aruba", value: "AW" },
  { label: "Australia", value: "AU" },
  { label: "Austria", value: "AT" },
  { label: "Azerbayán", value: "AZ" },
  { label: "Bahamas", value: "BS" },
  { label: "Bahrein", value: "BH" },
  { label: "Bangladesh", value: "BD" },
  { label: "Barbados", value: "BB" },
  { label: "Bélgica", value: "BE" },
  { label: "Belice", value: "BZ" },
  { label: "Benín", value: "BJ" },
  { label: "Bhután", value: "BT" },
  { label: "Bielorrusia", value: "BY" },
  { label: "Birmania", value: "MM" },
  { label: "Bolivia", value: "BO" },
  { label: "Bosnia y Herzegovina", value: "BA" },
  { label: "Botsuana", value: "BW" },
  { label: "Brasil", value: "BR" },
  { label: "Brunéi", value: "BN" },
  { label: "Bulgaria", value: "BG" },
  { label: "Burkina Faso", value: "BF" },
  { label: "Burundi", value: "BI" },
  { label: "Cabo Verde", value: "CV" },
  { label: "Camboya", value: "KH" },
  { label: "Camerún", value: "CM" },
  { label: "Canadá", value: "CA" },
  { label: "Chad", value: "TD" },
  { label: "China", value: "CN" },
  { label: "Chipre", value: "CY" },
  { label: "Ciudad del Vaticano", value: "VA" },
  { label: "Colombia", value: "CO" },
  { label: "Comoras", value: "KM" },
  { label: "Corea del Norte", value: "KP" },
  { label: "Corea del Sur", value: "KR" },
  { label: "Costa de Marfil", value: "CI" },
  { label: "Costa Rica", value: "CR" },
  { label: "Croacia", value: "HR" },
  { label: "Cuba", value: "CU" },
  { label: "Democratic Republic of the Congo", value: "CD" },
  { label: "Dinamarca", value: "DK" },
  { label: "Dominica", value: "DM" },
  { label: "Ecuador", value: "EC" },
  { label: "Egipto", value: "EG" },
  { label: "El Salvador", value: "SV" },
  { label: "Emiratos Árabes Unidos", value: "AE" },
  { label: "Eritrea", value: "ER" },
  { label: "Eslovaquia", value: "SK" },
  { label: "Eslovenia", value: "SI" },
  { label: "España", value: "ES" },
  { label: "Estados Unidos de América", value: "US" },
  { label: "Estonia", value: "EE" },
  { label: "Etiopía", value: "ET" },
  { label: "Filipinas", value: "PH" },
  { label: "Finlandia", value: "FI" },
  { label: "Fiyi", value: "FJ" },
  { label: "Francia", value: "FR" },
  { label: "Gabón", value: "GA" },
  { label: "Gambia", value: "GM" },
  { label: "Georgia", value: "GE" },
  { label: "Ghana", value: "GH" },
  { label: "Gibraltar", value: "GI" },
  { label: "Granada", value: "GD" },
  { label: "Grecia", value: "GR" },
  { label: "Groenlandia", value: "GL" },
  { label: "Guadalupe", value: "GP" },
  { label: "Guam", value: "GU" },
  { label: "Guatemala", value: "GT" },
  { label: "Guayana Francesa", value: "GF" },
  { label: "Guernsey", value: "GG" },
  { label: "Guinea", value: "GN" },
  { label: "Guinea Ecuatorial", value: "GQ" },
  { label: "Guinea-Bissau", value: "GW" },
  { label: "Guyana", value: "GY" },
  { label: "Haití", value: "HT" },
  { label: "Honduras", value: "HN" },
  { label: "Hong kong", value: "HK" },
  { label: "Hungría", value: "HU" },
  { label: "India", value: "IN" },
  { label: "Indonesia", value: "ID" },
  { label: "Irak", value: "IQ" },
  { label: "Irán", value: "IR" },
  { label: "Irlanda", value: "IE" },
  { label: "Isla Bouvet", value: "BV" },
  { label: "Isla de Man", value: "IM" },
  { label: "Isla de Navidad", value: "CX" },
  { label: "Isla Norfolk", value: "NF" },
  { label: "Islandia", value: "IS" },
  { label: "Islas Bermudas", value: "BM" },
  { label: "Islas Caimán", value: "KY" },
  { label: "Islas Cocos (Keeling)", value: "CC" },
  { label: "Islas Cook", value: "CK" },
  { label: "Islas de Åland", value: "AX" },
  { label: "Islas Feroe", value: "FO" },
  { label: "Islas Georgias del Sur y Sandwich del Sur", value: "GS" },
  { label: "Islas Heard y McDonald", value: "HM" },
  { label: "Islas Maldivas", value: "MV" },
  { label: "Islas Malvinas", value: "FK" },
  { label: "Islas Marianas del Norte", value: "MP" },
  { label: "Islas Marshall", value: "MH" },
  { label: "Islas Pitcairn", value: "PN" },
  { label: "Islas Salomón", value: "SB" },
  { label: "Islas Turcas y Caicos", value: "TC" },
  { label: "Islas Ultramarinas Menores de Estados Unidos", value: "UM" },
  { label: "Islas Vírgenes Británicas", value: "VG" },
  { label: "Islas Vírgenes de los Estados Unidos", value: "VI" },
  { label: "Israel", value: "IL" },
  { label: "Italia", value: "IT" },
  { label: "Jamaica", value: "JM" },
  { label: "Japón", value: "JP" },
  { label: "Jersey", value: "JE" },
  { label: "Jordania", value: "JO" },
  { label: "Kazajistán", value: "KZ" },
  { label: "Kenia", value: "KE" },
  { label: "Kirgizstán", value: "KG" },
  { label: "Kiribati", value: "KI" },
  { label: "Kosovo", value: "XK" },
  { label: "Kuwait", value: "KW" },
  { label: "Laos", value: "LA" },
  { label: "Lesoto", value: "LS" },
  { label: "Letonia", value: "LV" },
  { label: "Líbano", value: "LB" },
  { label: "Liberia", value: "LR" },
  { label: "Libia", value: "LY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Lituania", value: "LT" },
  { label: "Luxemburgo", value: "LU" },
  { label: "Macao", value: "MO" },
  { label: "Macedônia", value: "MK" },
  { label: "Madagascar", value: "MG" },
  { label: "Malasia", value: "MY" },
  { label: "Malawi", value: "MW" },
  { label: "Mali", value: "ML" },
  { label: "Malta", value: "MT" },
  { label: "Marruecos", value: "MA" },
  { label: "Martinica", value: "MQ" },
  { label: "Mauricio", value: "MU" },
  { label: "Mauritania", value: "MR" },
  { label: "Mayotte", value: "YT" },
  { label: "México", value: "MX" },
  { label: "Micronesia", value: "FM" },
  { label: "Moldavia", value: "MD" },
  { label: "Mónaco", value: "MC" },
  { label: "Mongolia", value: "MN" },
  { label: "Montenegro", value: "ME" },
  { label: "Montserrat", value: "MS" },
  { label: "Mozambique", value: "MZ" },
  { label: "Namibia", value: "NA" },
  { label: "Nauru", value: "NR" },
  { label: "Nepal", value: "NP" },
  { label: "Nicaragua", value: "NI" },
  { label: "Niger", value: "NE" },
  { label: "Nigeria", value: "NG" },
  { label: "Niue", value: "NU" },
  { label: "Noruega", value: "NO" },
  { label: "Nueva Caledonia", value: "NC" },
  { label: "Nueva Zelanda", value: "NZ" },
  { label: "Omán", value: "OM" },
  { label: "Países BajosPakistán", value: "NL" },
  { label: "Pakistán", value: "PK" },
  { label: "Palau", value: "PW" },
  { label: "Palestina", value: "PS" },
  { label: "Panamá", value: "PA" },
  { label: "Papúa Nueva Guinea", value: "PG" },
  { label: "Paraguay", value: "PY" },
  { label: "Perú", value: "PE" },
  { label: "Polinesia Francesa", value: "PF" },
  { label: "Polonia", value: "PL" },
  { label: "Portugal", value: "PT" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Qatar", value: "QA" },
  { label: "Reino Unido", value: "GB" },
  { label: "Republic of the Congo", value: "CG" },
  { label: "República Centroafricana", value: "CF" },
  { label: "República Checa", value: "CZ" },
  { label: "República Dominicana", value: "DO" },
  { label: "Reunión", value: "RE" },
  { label: "Ruanda", value: "RW" },
  { label: "Rumanía", value: "RO" },
  { label: "Rusia", value: "RU" },
  { label: "Sahara Occidental", value: "EH" },
  { label: "Samoa", value: "WS" },
  { label: "Samoa Americana", value: "AS" },
  { label: "San Bartolomé", value: "BL" },
  { label: "San Cristóbal y Nieves", value: "KN" },
  { label: "San Marino", value: "SM" },
  { label: "San Martín (Francia)", value: "MF" },
  { label: "San Pedro y Miquelón", value: "PM" },
  { label: "San Vicente y las Granadinas", value: "VC" },
  { label: "Santa Elena", value: "SH" },
  { label: "Santa Lucía", value: "LC" },
  { label: "Santo Tomé y Príncipe", value: "ST" },
  { label: "Senegal", value: "SN" },
  { label: "Serbia", value: "RS" },
  { label: "Seychelles", value: "SC" },
  { label: "Sierra Leona", value: "SL" },
  { label: "Singapur", value: "SG" },
  { label: "Siria", value: "SY" },
  { label: "Somalia", value: "SO" },
  { label: "Sri lanka", value: "LK" },
  { label: "Sudáfrica", value: "ZA" },
  { label: "Sudán", value: "SD" },
  { label: "Suecia", value: "SE" },
  { label: "Suiza", value: "CH" },
  { label: "Surinám", value: "SR" },
  { label: "Svalbard y Jan Mayen", value: "SJ" },
  { label: "Swazilandia", value: "SZ" },
  { label: "Tadjikistán", value: "TJ" },
  { label: "Tailandia", value: "TH" },
  { label: "Taiwán", value: "TW" },
  { label: "Tanzania", value: "TZ" },
  { label: "Territorio Británico del Océano Índico", value: "IO" },
  { label: "Territorios Australes y Antárticas Franceses", value: "TF" },
  { label: "Timor Oriental", value: "TL" },
  { label: "Togo", value: "TG" },
  { label: "Tokelau", value: "TK" },
  { label: "Tonga", value: "TO" },
  { label: "Trinidad y Tobago", value: "TT" },
  { label: "Tunez", value: "TN" },
  { label: "Turkmenistán", value: "TM" },
  { label: "Turquía", value: "TR" },
  { label: "Tuvalu", value: "TV" },
  { label: "Ucrania", value: "UA" },
  { label: "Uganda", value: "UG" },
  { label: "Uruguay", value: "UY" },
  { label: "Uzbekistán", value: "UZ" },
  { label: "Vanuatu", value: "VU" },
  { label: "Venezuela", value: "VE" },
  { label: "Vietnam", value: "VN" },
  { label: "Wallis y Futuna", value: "WF" },
  { label: "Yemen", value: "YE" },
  { label: "Yibuti", value: "DJ" },
  { label: "Zambia", value: "ZM" },
  { label: "Zimbabue", value: "ZW" },
];

RegistrationDialogStepRegistrationEthnicityCl.stepName = "registration_ethnicity";

RegistrationDialogStepRegistrationEthnicityCl.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationEthnicityCl;
