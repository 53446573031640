import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./quote.scss";

const Quote = (props) => {
  const {
    quote,
    color,
    isParagraphQuote,
  } = props;

  return (
    <Row className={classNames(
      "quote",
      "text-section__fullwidth",
      { "quote--paragraph": isParagraphQuote },
    )}
    >
      <Col sm={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
        <div className={classNames(
          "quote__text",
          isParagraphQuote ? "typo-title3" : "typo-h3",
          `text-${color}`,
        )}
        >
          {quote}
        </div>
      </Col>
    </Row>
  );
};

Quote.propTypes = {
  color: PropTypes.oneOf(["primary", "indication", "tertiary", "black-variant"]).isRequired,
  isParagraphQuote: PropTypes.bool,
  quote: PropTypes.string.isRequired,
};

Quote.defaultProps = {
  isParagraphQuote: false,
};

export default Quote;
