import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import CampaignHeroCarouselCards from "./campaigns/campaignHeroCarouselCards";
import HeroCarousel from "../components/heroCarousel/heroCarousel";

const KontentItemHeroCarouselCampaigns = ({ data }) => {
  const heroCarouselCampaigns = data.value ? data.value[0] : data;

  if (!heroCarouselCampaigns || !heroCarouselCampaigns.elements) {
    return null;
  }

  return (
    <HeroCarousel notOverwriteButton>
      {CampaignHeroCarouselCards({
        buttonText: heroCarouselCampaigns.elements.button_text.value,
        data: heroCarouselCampaigns.elements.carousel_campaigns,
      })}
    </HeroCarousel>
  );
};

export const query = graphql`
  fragment KontentItemHeroCarouselCampaigns on kontent_item_hero_carousel_campaigns {
    id
    system {
      codename
    }
    elements {
      carousel_campaigns {
        value {
          ...CampaignHeroCarouselCards
        }
      }
      button_text {
        value
      }
    }
  } 
`;

KontentItemHeroCarouselCampaigns.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemHeroCarouselCampaigns;
