import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemTargetGroup from "./kontentItemTargetGroup";
import Hubsection from "../components/hubsection/hubsection";
import TargetGroupSection from "../components/targetGroupSection/targetGroupSection";

const KontentItemTargetGroupSection = ({ data, asImage }) => {
  const targetGroupSection = data.value ? data.value[0] : data;

  if (!targetGroupSection || !targetGroupSection.elements) {
    return null;
  }

  if (asImage) {
    return (
      <Hubsection variant="target-group">
        {KontentItemTargetGroup({
          asImage: true,
          data: targetGroupSection.elements.target_group_items.value,
        })}
      </Hubsection>
    );
  }

  return (
    <TargetGroupSection
      headline={targetGroupSection.elements.headline.value}
    >
      {KontentItemTargetGroup({
        data: targetGroupSection.elements.target_group_items.value,
      })}
    </TargetGroupSection>
  );
};

export const query = graphql`
  fragment KontentItemTargetGroupSection on kontent_item_target_group_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      target_group_items {
        value {
          ...KontentItemTargetGroup
        }
      }
    }
  }
`;

KontentItemTargetGroupSection.propTypes = {
  asImage: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
KontentItemTargetGroupSection.defaultProps = {
  asImage: false,
};

export default KontentItemTargetGroupSection;
