import React, { useState } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";

import { IconAccordionExpand18, IconAccordionMinimize18 } from "../../icons";
import IconButton from "../button/iconButton";
import StateAwareAccordionToggle from "../stateAwareAccordionToggle/stateAwareAccordionToggle";

import "./faqAccordion.scss";

const FaqAccordion = (props) => {
  const {
    accordionItems,
    expandable,
    titleAs,
    variant,
  } = props;

  const [showAll, setShowAll] = useState(false);
  let defaultActiveKeyObject = {};

  if (typeof window !== "undefined") {
    const { hash } = window.location;

    if (hash !== "") {
      const accordionItem = accordionItems.find((item) => `#${item.anchor_slug}` === hash);

      if (accordionItem) {
        defaultActiveKeyObject = { defaultActiveKey: accordionItem.id };
      }
    }
  }

  const TitleTag = ["h2", "h3", "h4", "h5", "p"].includes(titleAs) ? titleAs : "div";

  return (
    <Accordion
      className={classNames("faq-accordion", { [`faq-accordion--${variant}`]: variant })}
      {...defaultActiveKeyObject}
    >
      {accordionItems.map((item, index) => (
        <div
          key={item.id}
          className={classNames("faq-accordion__accordion-item", expandable && !showAll && index > 5 && "d-none")}
          {...(item.anchorSlug ? { id: item.anchorSlug } : {})}
        >
          <StateAwareAccordionToggle
            eventKey={item.id}
            className="faq-accordion__item_headline"
          >
            <TitleTag>{item.question}</TitleTag>
          </StateAwareAccordionToggle>
          <Accordion.Collapse eventKey={item.id}>
            <>
              {item.answer}
            </>
          </Accordion.Collapse>
        </div>
      ))}
      {expandable && accordionItems.length > 6 && (
        <div className={classNames("faq-accordion__button", { "d-none": accordionItems.length <= 6 })}>
          <IconButton
            size="sm"
            variant={(variant !== null) ? "plain-primary" : "plain-faq"}
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {showAll
              ? <FormattedMessage id="faq.accordion.show_less_results" />
              : <FormattedMessage id="faq.accordion.show_more_results" />}
            {showAll
              ? <IconAccordionMinimize18 />
              : <IconAccordionExpand18 />}
          </IconButton>
        </div>
      )}
    </Accordion>
  );
};

FaqAccordion.propTypes = {
  accordionItems: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    question: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  })).isRequired,
  expandable: PropTypes.bool,
  titleAs: PropTypes.oneOf(["h2", "h3", "h4", "h5", "p"]),
  variant: PropTypes.oneOf(["virtual-drive-help"]),
};

FaqAccordion.defaultProps = {
  expandable: false,
  titleAs: null,
  variant: null,
};

export default FaqAccordion;
