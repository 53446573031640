import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemProcessStep from "./kontentItemProcessStep";
import IconProcess from "../components/iconProcess/iconProcess";

const KontentItemProcessStepGroup = ({ data }) => {
  const processStepGroup = data.value ? data.value[0] : data;

  return (
    <IconProcess className="d-flex justify-content-center">
      {KontentItemProcessStep({ data: processStepGroup.elements.process_steps.value })}
    </IconProcess>
  );
};

export const query = graphql`
  fragment KontentItemProcessStepGroup on kontent_item_process_step_group {
    id
    system {
      codename
    }
    elements {
      process_steps {
        value {
          ...KontentItemProcessStep
        }
      }
    }
  }
`;

KontentItemProcessStepGroup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemProcessStepGroup;
