import { graphql, useStaticQuery } from "gatsby";

const getInternalLinkById = (id) => {
  const { links } = useStaticQuery(graphql`
    {
      links: allInternalLinks {
        nodes {
          navigationItemId
          systemId
          title
          slug
        }
      }
    }
  `);

  const nodes = links ? links.nodes : [];
  const link = nodes.find((o) => o.navigationItemId === id || o.systemId === id);

  if (link) {
    return {
      slug: link.slug,
      title: link.title,
    };
  }

  return null;
};

export default getInternalLinkById;
