import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import { IconCalendar24, IconLocation24 } from "../../icons";
import { formatInTimeZone } from "../../utils/dateHelper";
import NavigationLink from "../navigationLink/navigationLink";
import Tag from "../tag";

import "./eventTile.scss";

const EventTile = (props) => {
  const {
    headline,
    image,
    href,
    location,
    category,
    date,
    dateEnd,
    timezone,
    highlight,
    archive,
    description,
  } = props;

  let colPropsImage = {
    lg: 4,
    md: 12,
    sm: 4,
  };

  let colPropsContent = {
    lg: 8,
    md: 12,
    sm: 8,
  };

  if (highlight) {
    colPropsImage = {
      md: 6,
      sm: 4,
    };

    colPropsContent = {
      md: 6,
      sm: 8,
    };
  }

  let tmpImage = image;

  const isLowerSm = useMediaQuery("(max-width: 575px)");
  const isLowerLg = useMediaQuery("(max-width: 991px)");
  const isHigherEqualMd = useMediaQuery("(min-width: 768px)");

  if ((isLowerLg && isHigherEqualMd) || isLowerSm) {
    tmpImage = React.cloneElement(image, { aspectRatio: 21 / 9 });
  }

  const formattedDate = formatInTimeZone(date, "P", timezone);
  const formattedDateEnd = dateEnd ? formatInTimeZone(dateEnd, "P", timezone) : null;

  return (
    <NavigationLink
      href={href}
      className={classNames(
        "event-tile",
        "no-style",
        {
          "event-tile--highlight": highlight,
        },
      )}
    >
      <Row noGutters>
        <Col
          {...colPropsImage}
          className="event-tile__image"
        >
          {tmpImage}
        </Col>
        <Col {...colPropsContent} className="event-tile__content">
          <div className="event-tile__category">
            <Tag variant="event">{category}</Tag>
            {archive && (
              <FormattedMessage id="archive" />
            )}
          </div>
          <div className="flex-grow-1">
            <div className="event-tile__headline">
              {headline}
            </div>
            {highlight && description && isHigherEqualMd && (
              <div className="event-tile__description">{description}</div>
            )}
          </div>
          {location !== null && (
            <div className="event-tile__info">
              <IconLocation24 />{location}
            </div>
          )}
          <div className="event-tile__info">
            <IconCalendar24 />{formattedDate}{formattedDateEnd && formattedDate !== formattedDateEnd && ` - ${formattedDateEnd}`}
          </div>
        </Col>
      </Row>
    </NavigationLink>
  );
};

EventTile.propTypes = {
  archive: PropTypes.bool,
  category: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  dateEnd: PropTypes.instanceOf(Date),
  description: PropTypes.string,
  headline: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
  href: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.string,
  timezone: PropTypes.string.isRequired,
};

EventTile.defaultProps = {
  archive: false,
  dateEnd: null,
  description: null,
  highlight: false,
  location: null,
};

export default EventTile;
