import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./iconText.scss";

const IconText = (props) => {
  const {
    icon,
    text,
    variant,
    postIcon,
  } = props;

  return (
    <div className={classNames("icon-text typo-small-label", `icon-text--${variant}`, postIcon && "icon-text--post-icon")}>
      <div className="icon-text__icon">{icon}</div>
      <div className={classNames("icon-text__text", postIcon && "order-first")}>{text}</div>
    </div>
  );
};

IconText.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  postIcon: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  variant: PropTypes.oneOf([
    "primary",
    "learn-area",
    "donation",
    "faq",
    "light",
    "grey",
    "events",
    "donor-path",
    "grey-dark"]).isRequired,
};

IconText.defaultProps = {
  postIcon: false,
};

export default IconText;
