import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import FilterDropdown from "../../components/filterDropown/filterDropdown";
import IntroSection from "../../components/introSection/introSection";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemHeroCarouselNews from "../kontentItemHeroCarouselNews";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import NewsCard from "../news/newsCard";

const KontentItemNewsOverview = ({ data }) => {
  const { page } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <KontentItemHeroCarouselNews data={page.elements.hero_carousel} />

      <BreadcrumbGenerator id={page.id} />

      <IntroSection
        headline={page.elements.headline.value}
        text={page.elements.lead_text.value}
        headlineAs="h1"
      />

      <FilterDropdown>
        {NewsCard({
          articleMigration: data.articleMigration.nodes,
          currentFirst: true,
          data: data.stories.nodes,
        })}
      </FilterDropdown>

      {page.elements.page_sections.value.map((item) => mapKontentItem(item))}

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    
    articleMigration: allKontentItemArticleMigration {
      nodes {
        ...NewsCardMigrated
      }
    }
    stories: allKontentItemArticlePageNews {
      nodes {
        ...NewsCard
      }
    }
    page: kontentItemNewsOverview(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemIconCardSliderSection
            ...KontentItemEyecatcherContact
            ...KontentItemEyecatcher
          }
        }
        hero_carousel {
          value {
            ...KontentItemHeroCarouselNews
          }
        }
      }
    }
  }
`;

KontentItemNewsOverview.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemNewsOverview;
