import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import backgroundImageHelper from "./image/backgroundImageHelper";
import Iconbox from "../components/iconbox/iconbox";
import RegistrationStep from "../components/registrationProcess/registrationStep";
import iconHelper from "../utils/iconHelper";

const KontentItemRegistrationStep = ({ data }) => {
  const registrationSteps = data.value ? data.value[0] : data;

  if (!registrationSteps || !registrationSteps.elements) {
    return null;
  }

  return (
    <RegistrationStep
      description={registrationSteps.elements.description.value}
      iconbox={(
        <Iconbox>{iconHelper(registrationSteps.elements.icon.value.length > 0
          ? registrationSteps.elements.icon.value[0].codename
          : null)}
        </Iconbox>
        )}
      backgroundImageStyle={backgroundImageHelper(registrationSteps.elements.image)}
      label={registrationSteps.elements.title.value}
    />
  );
};

export const query = graphql`
  fragment KontentItemRegistrationStep on kontent_item_registration_step {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      description {
        value
      }
      icon {
        value {
          codename
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

KontentItemRegistrationStep.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemRegistrationStep;
