import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import Button from "../../components/button/button";
import ContentHeader from "../../components/contentHeader/contentHeader";
import SEO from "../../components/seo";
import SocialMediaShare from "../../components/socialMediaShare/socialMediaShare";
import Tag from "../../components/tag";
import { HeaderIconFaq } from "../../images";
import mapKontentItem from "../../utils/mapKontentItem";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SimpleRichText from "../text/simpleRichText";

const KontentItemFaqDetailPage = ({ data }) => (
  <MainLayout>

    <SEO
      title={data.page.elements.question.value}
      twitterTitle={data.page.elements.question.value}
      ogTitle={data.page.elements.question.value}
    />

    <BreadcrumbGenerator id={data.page.id} />

    <ContentHeader
      headline={data.page.elements.question.value}
      description={SimpleRichText({ data: data.page.elements.answer })}
      media={<HeaderIconFaq />}
      tag={data.page.elements.faq_category.value.length > 0
        ? <Tag variant="faq">{data.page.elements.faq_category.value[0].name}</Tag>
        : null}
      variant="faq-detail"
      button={
        data.faqPage && data.faqPage.elements.faq_page_navigation_item.value.length > 0
          ? (
            <Button
              variant="inverted-faq"
              href={data.faqPage.elements.faq_page_navigation_item.value[0].id}
              activeClassName=""
            >
              <FormattedMessage id="faq.go_to_faq" />
            </Button>
          )
          : null
      }
      socialMediaShare={<SocialMediaShare />}
    />

    {data.faqPage
      && data.faqPage.elements.faq_page_navigation_item.value.length > 0
      && data.faqPage.elements.faq_page_navigation_item.value[0]
        .elements.elements.value.length > 0
      && data.faqPage.elements.faq_page_navigation_item.value[0]
        .elements.elements.value[0].elements.page_sections.value.map((item) => (
          mapKontentItem(item)
        ))}

  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    faqPage: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
      elements {
        faq_page_navigation_item {
          value {
            id
            ... on kontent_item_navigation_item {
              id
              elements {
                elements {
                  value {
                    ... on kontent_item_faq_page {
                      id
                      elements {
                        page_sections {
                          value {
                            internal {
                              type
                            }
                            ...KontentItemImageCardSliderSection
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    page: kontentItemFaq(id: {eq: $id}) {
      id
      elements {
        anchor_slug {
          value
        }
        question {
          value
        }
        answer {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemImage
            ...KontentItemCtaButton
          }
        }
        faq_category {
          value {
            name
          }
        }
      }
    }
  }
`;

KontentItemFaqDetailPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemFaqDetailPage;
