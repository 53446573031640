/* eslint-disable sort-keys */
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { sex } from "./general-form-data";
import { format } from "../../../utils/dateHelper";
import { clean } from "../formBuilder";
import Validators from "../validations/validators";

const getUpdateAddressForm = (intl) => {
  const validators = new Validators(intl);
  const REQUIRED_ERROR = intl.formatMessage({ id: "form.required" });
  const SEX_OPTIONS = sex(intl);

  const detailsView = useSelector((state) => state.updateAddress.detailsView);

  /* eslint-disable sort-keys */
  const samePhoneTest = {
    type: "test",
    params: [{
      name: "samePhoneTest",
      test() {
        const { mobile, landline } = this.parent;

        return !(landline && mobile === landline);
      },
      message: intl.formatMessage({ id: "registration.alternate_contact.error.same_phone" }),
    }],
  };

  return [
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "personal_info_header",
        headline: "personal_info.title",
        description: "personal_info.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "donor_id",
        name: "donor_id",
        label: intl.formatMessage({ id: "donor_id" }),
        type: "text",
        maxLength: 20,
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("donor_id", true),
        ],
      },
      tooltipMessage: intl.formatMessage({ id: "tooltip.donor_id" }),
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "DateInput",
      props: {
        id: "birthdate",
        name: "birthdate",
        label: intl.formatMessage({ id: "registration.age.birthdate" }),
        type: "date",
        required: true,
        placeholder: intl.formatMessage({ id: "registration.age.birthdate_placeholder" }),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInPast", true),
        ],
      },
      initialValue: "",
      tooltipMessage: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "4",
        singleLine: true,
      },
      tagName: "RadioInput",
      props: {
        id: "sex",
        name: "sex",
        label: intl.formatMessage({ id: "form.salutation" }),
        type: "text",
        title: intl.formatMessage({ id: "form.please_select.label" }),
        isRequired: true,
      },
      options: SEX_OPTIONS,
      validation: {
        type: "string",
        validators: [
          ...validators.get(null, true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "firstname",
        name: "firstname",
        label: intl.formatMessage({ id: "registration.personal.firstname" }),
        type: "text",
        maxLength: 40,
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("firstname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "lastname",
        name: "lastname",
        label: intl.formatMessage({ id: "registration.personal.lastname" }),
        type: "text",
        maxLength: 100,
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "former_lastname",
        name: "former_lastname",
        label: intl.formatMessage({ id: "personal_info.former_last_name" }),
        type: "text",
        maxLength: 100,
        required: false,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", false),
        ],
      },
      tooltipMessage: intl.formatMessage({ id: "tooltip.former_last_name" }),
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "previous_address_header",
        headline: "previous_address.title",
        description: "previous_address.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
        singleLine: false,
      },
      tagName: "CheckboxInput",
      props: {
        id: "address_unchanged",
        name: "address_unchanged",
        label: intl.formatMessage({ id: "address_unchanged" }),
        type: "checkbox",
        lg: true,
      },
      validation: {
        type: "bool",
        validators: [],
      },
      initialValue: false,
    },

    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "FormAddressInput",
      props: {
        id: "prev_address_input",
        name: "prev_address_input",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        fieldType: "prev",
        subFields: [
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_street",
              name: "prev_street",
              label: intl.formatMessage({ id: "registration.address.street" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_house_no",
              name: "prev_house_no",
              label: intl.formatMessage({ id: "registration.address.houseNo" }),
              type: "text",
              maxLength: 10,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_apartment",
              name: "prev_apartment",
              label: intl.formatMessage({ id: "registration.address.apartment" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
              singleLine: false,
            },
            tagName: "CleaveInput",
            props: {
              id: "prev_postcode",
              name: "prev_postcode",
              label: intl.formatMessage({ id: "registration.address.postCode" }),
              type: "text",
              maxLength: 6,
              required: true,
              options: {
                blocks: [2, 3],
                delimiter: "-",
                numericOnly: true,
              },
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("zipcode", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_city",
              name: "prev_city",
              label: intl.formatMessage({ id: "registration.address.city" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
              ],
            },
            tooltipMessage: intl.formatMessage({ id: "tooltip.city" }),
            initialValue: "",
          },
        ],
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "current_address_header",
        headline: "current_address.title",
        description: "current_address.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "FormAddressInput",
      props: {
        id: "current_address_input",
        name: "current_address_input",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        fieldType: "current",
        subFields: [
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_street",
              name: "current_street",
              label: intl.formatMessage({ id: "registration.address.street" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get(null, true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_house_no",
              name: "current_house_no",
              label: intl.formatMessage({ id: "registration.address.houseNo" }),
              type: "text",
              maxLength: 10,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get(null, true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_apartment",
              name: "current_apartment",
              label: intl.formatMessage({ id: "registration.address.apartment" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
              singleLine: false,
            },
            tagName: "CleaveInput",
            props: {
              id: "current_postcode",
              name: "current_postcode",
              label: intl.formatMessage({ id: "registration.address.postCode" }),
              type: "text",
              maxLength: 6,
              required: true,
              options: {
                blocks: [2, 3],
                delimiter: "-",
                numericOnly: true,
              },
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("zipcode", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_city",
              name: "current_city",
              label: intl.formatMessage({ id: "registration.address.city" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get(null, true),
              ],
            },
            tooltipMessage: intl.formatMessage({ id: "tooltip.city" }),
            initialValue: "",
          },
        ],
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "other_details_header",
        headline: "other_details.title",
        description: "",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "mobile",
        name: "mobile",
        label: intl.formatMessage({ id: "form.updateAddress.phone" }),
        type: "text",
        maxLength: 9,
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("phone", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "landline",
        name: "landline",
        label: intl.formatMessage({ id: "form.updateAddress.additional_phone" }),
        type: "text",
        maxLength: 9,
        required: false,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("phone", false),
          samePhoneTest,
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "email",
        name: "email",
        label: intl.formatMessage({ id: "registration.additional.email" }),
        type: "text",
        maxLength: 100,
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("email", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "validity_date_header",
        headline: "validity_date.title",
        description: "validity_date.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: true,
      },
      tagName: "DateInput",
      props: {
        id: "validity_date",
        name: "validity_date",
        label: intl.formatMessage({ id: "validity_date.label" }),
        type: "date",
        required: true,
        placeholder: "",
        value: new Date(),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInFuture", true),
          ...validators.get("dateNotToFarInFuture"),
        ],
      },
      initialValue: format(new Date(), "yyyy-MM-dd"),
      tooltipMessage: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "data_protection_header",
        headline: "dataprotection.title",
        description: "dataprotection.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
        singleLine: false,
      },
      tagName: "CheckboxInput",
      props: {
        id: "privacy",
        name: "privacy",
        label: intl.formatMessage({ id: "registration.dataprotection.dataprotection" }),
        type: "checkbox",
        lg: true,
        required: true,
      },
      validation: {
        type: "bool",
        validators: detailsView
          ? [...validators.get("requiredCheckbox", false)]
          : [],
      },
      initialValue: false,
    },
  ];
};

const generatePayload = (values) => {
  /* eslint-disable sort-keys */
  const payload = {
    person: {
      birthdate: values.birthdate,
      sex: values.sex,
      firstname: values.firstname,
      lastname: values.lastname,
      former_lastname: values.former_lastname,
      donor_id: values.donor_id.toUpperCase(),
    },
    former_address: {
      houseno: values.prev_house_no,
      street: values.prev_street,
      city: values.prev_city,
      zipcode: values.prev_postcode,
      apartment: values.prev_apartment,
    },
    private_address: {
      houseno: values.current_house_no,
      street: values.current_street,
      city: values.current_city,
      zipcode: values.current_postcode,
      apartment: values.current_apartment,
    },
    private_contact: {
      mobile: values.mobile,
      phone: values.landline,
      email: values.email,
    },
    ciu_data: {
      validity_date: values.validity_date,
    },
  };

  return clean(payload);
};

export default { getUpdateAddressForm, generatePayload };
