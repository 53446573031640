import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import ComponentDonationBoxEyecatcher from "../../components/donationBox/donationBoxEyecatcher";
import getInternalLinkById from "../../utils/getInternalLinkById";

const DonationBoxEyecatcher = (props) => {
  const {
    buttonURL,
    buttonText,
  } = props;

  const intl = useIntl();

  const data = useStaticQuery(graphql`
    {
      settings: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          payment_dialog_url {
            value {
              id
            }
          }
          payment_provider_onetime {
            value {
              url
              description
              height
              name
              size
              type
              width
            }
          }
          payment_provider_recurrent {
            value {
              url
              description
              height
              name
              size
              type
              width
            }
          }
        }
      }
    }
  `);

  const defaultButtonURL = getInternalLinkById(
    data.settings.elements.payment_dialog_url?.value?.[0]?.id,
  );

  let uniquePaymentProvider = null;

  if (["de", "gb", "pl"].includes(process.env.GATSBY_SITE)) {
    const paymentProvider = [
      ...data.settings.elements.payment_provider_onetime.value,
      ...data.settings.elements.payment_provider_recurrent.value,
    ];

    const mapPaymentProvider = new Map(
      paymentProvider.map((provider) => [provider.name, provider]),
    );

    uniquePaymentProvider = [...mapPaymentProvider.values()];
  }

  return (
    <ComponentDonationBoxEyecatcher
      {...props}
      buttonURL={buttonURL || defaultButtonURL?.slug}
      buttonText={buttonText || intl.formatMessage({ id: "payment_dialog.donate_now" })}
      paymentProvider={uniquePaymentProvider}
    />
  );
};

DonationBoxEyecatcher.propTypes = {
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
};

DonationBoxEyecatcher.defaultProps = {
  buttonText: undefined,
  buttonURL: undefined,
};

export default DonationBoxEyecatcher;
