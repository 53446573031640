import React from "react";

import PropTypes from "prop-types";

import { IconDownload18 } from "../../icons";

import "./fileDownload.scss";

const FileDownload = (props) => {
  const {
    title,
    size,
    href,
    fileIcon,
    image,
  } = props;

  return (
    <a href={href} download className="file-download">
      {image && (
        <div className="file-download__image">
          {image}
        </div>
      )}
      <div className="file-download__content">
        {fileIcon}
        <div className="flex-fill mx-18">
          <div className="typo-label">
            {title}
          </div>
          <div className="typo-small-label">
            ({size})
          </div>
        </div>
        <IconDownload18 />
      </div>
    </a>
  );
};

FileDownload.propTypes = {
  fileIcon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  size: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

FileDownload.defaultProps = {
  image: null,
};

export default FileDownload;
