/* eslint-disable no-underscore-dangle */
import { useIntl } from "gatsby-plugin-react-intl";

import Validators from "../localForms/validations/validators";

/**
 * Representing Yup Schema types
 */
const STRING_TYPE = "string";
const BOOL_TYPE = "boolean";

/**
 *
 * @param {array} fields
 *
 * A field describes various KontentItemTypes which can be found
 * in the templates/form folder. The method generates an initial
 * values object from the field descriptions in order to use it
 * with FORMIK and YUP. It contains
 * KontentItemTypes that are not composed of many form fields
 * like for e.g. the templates/form/KontentItemInputTypeSingleInput
 */
export const generateInitialFormValues = (fields) => {
  const filteredIds = fields.filter(
    (e) => e.elements && e.internal.type !== "kontent_item_section_header",
  ).map((e) => Object.keys(e.elements).filter(
    (key) => key.includes("id"),
  ).map(
    (filteredKey) => ({ [e.elements[filteredKey].value]: "" }),
  )).filter((e) => e.length > 0);

  return filteredIds.flatMap((el) => el).reduce((obj, item) => ({ ...obj, ...item }), {});
};

const exceptions = ["kontent_item_section_header"];

const createValidation = (id, validationType, validations = []) => ({
  id,
  validationType,
  validations,
});

export const isRequired = (key, requiredFields) => requiredFields.some((el) => el.codename === key);

const getValidations = (typename, elements, intl) => {
  const validators = new Validators(intl);

  const requiredFields = elements?.required?.value;

  switch (typename) {
    case "kontent_item_input_type_name":
      return [
        createValidation(
          elements.id_firstname.value,
          STRING_TYPE,
          validators.get("firstname", isRequired("id_firstname", requiredFields)),
        ),
        createValidation(
          elements.id_lastname.value,
          STRING_TYPE,
          validators.get("lastname", isRequired("id_lastname", requiredFields)),
        ),
      ];
    case "kontent_item_input_type_address":
      return [
        createValidation(
          elements.id_street.value,
          STRING_TYPE,
          validators.get("street", isRequired("id_streeet", requiredFields)),
        ),
        createValidation(
          elements.id_street2.value,
          STRING_TYPE,
          validators.get("street", isRequired("id_street2", requiredFields)),
        ),
        createValidation(
          elements.id_city.value,
          STRING_TYPE,
          validators.get("city", isRequired("id_city", requiredFields)),
        ),
        createValidation(
          elements.id_postcode.value,
          STRING_TYPE,
          validators.get("zipcode", isRequired("id_postcode", requiredFields)),
        ),
      ];
    case "kontent_item_input_type_dropdown":
      return [
        createValidation(
          elements.id_dropdown.value,
          STRING_TYPE,
          validators.get(null, isRequired("id_dropdown", requiredFields)),
        ),
        createValidation(
          elements.id_other.value,
          STRING_TYPE,
          validators.get(null, isRequired("id_other", requiredFields)),
        ),
      ];
    case "kontent_item_input_type_country":
      return [
        createValidation(
          elements.id_country.value,
          STRING_TYPE,
          validators.get("country", isRequired("id_country", requiredFields)),
        ),
        createValidation(
          elements.id_state.value,
          STRING_TYPE,
          validators.get("state", isRequired("id_state", requiredFields)),
        ),
      ];
    case "kontent_item_input_type_state":
      return [
        createValidation(
          elements.id.value,
          STRING_TYPE,
          validators.get("state", isRequired("id", requiredFields)),
        ),
      ];
    case "kontent_item_input_type_email":
      return [
        createValidation(
          elements.id.value,
          STRING_TYPE,
          validators.get("email", isRequired("id", requiredFields)),
        ),
      ];
    case "kontent_item_input_type_date":
      return [
        createValidation(
          elements.id.value,
          STRING_TYPE,
          validators.get(null, isRequired("id", requiredFields)),
        ),
      ];
    case "kontent_item_input_type_checkbox":
      return [
        createValidation(
          elements.id.value,
          BOOL_TYPE,
          validators.get(isRequired("id", requiredFields) ? "requiredCheckbox" : null, isRequired("id", requiredFields)),
        ),
      ];
    case "kontent_item_input_type_text":
      return [
        createValidation(
          elements.id.value,
          STRING_TYPE,
          validators.get(null, isRequired("id", requiredFields)),
        ),
        elements.max_length.value ? {
          params: [
            elements.max_length.value,
            intl.formatMessage(
              { id: "form.error.max_length_exact" },
              { value: elements.max_length.value },
            ),
          ],
          type: "max",
        } : {},
      ];
    case "kontent_item_input_type_salutation":
      return [
        createValidation(
          elements.id.value,
          STRING_TYPE,
          validators.get(null, isRequired("id", requiredFields)),
        ),
      ];
    case "kontent_item_input_type_single_input":
      return [
        createValidation(
          elements.id.value,
          STRING_TYPE,
          [
            ...validators.get(null, isRequired("id", requiredFields)),
            elements.max_length.value ? {
              params: [
                elements.max_length.value,
                intl.formatMessage(
                  { id: "form.error.max_length_exact" },
                  { value: elements.max_length.value },
                ),
              ],
              type: "max",
            } : {},
          ],
        ),
      ];
    default:
      return [];
  }
};

export const getValidationArray = (fields, intl) => {
  const filtered = fields.filter(
    (e) => !exceptions.includes(e.internal.type),
  );

  const validations = filtered.map((e) => {
    if (e && e.internal.type && e.elements) {
      return getValidations(e.internal.type, e.elements, intl);
    }

    return [];
  });

  return validations.flatMap((el) => el);
};

export const isOptionalText = (required) => {
  const intl = useIntl();

  return (!required ? `${intl.formatMessage({ id: "form.optional" })}` : "");
};
