const ConfigTemplates = {};

// Update Address Templates
ConfigTemplates.UpdateAddress_gb = require("./updateAddress-gb.config").default;
ConfigTemplates.UpdateAddress_de = require("./updateAddress-de.config").default;
ConfigTemplates.UpdateAddress_in = require("./updateAddress-in.config").default;
ConfigTemplates.UpdateAddress_us = require("./updateAddress-us.config").default;
ConfigTemplates.UpdateAddress_pl = require("./updateAddress-pl.config").default;
ConfigTemplates.UpdateAddress_cl = require("./updateAddress-cl.config").default;

// Update Mail Address Templates
ConfigTemplates.UpdateMailAddress_de = require("./updateMailAddress-de.config").default;
ConfigTemplates.UpdateMailAddress_gb = require("./updateMailAddress-gb.config").default;

// Donate Templates
ConfigTemplates.DonateForm_gb = require("./donateForm-gb.config").default;
ConfigTemplates.DonateForm_de = require("./donateForm-de.config").default;
// ConfigTemplates.DonateForm_in = require("./donateForm-in.config").default;
// ConfigTemplates.DonateForm_us = require("./donateForm-us.config").default;
// ConfigTemplates.DonateForm_pl = require("./donateForm-pl.config").default;
// ConfigTemplates.DonateForm_cl = require("./donateForm-cl.config").default;

export default ConfigTemplates;
