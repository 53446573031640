import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationAlternateContactIn = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });

  const validationSchema = useMemo(() => object().shape({
    contactEmailAddress: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .test(
        "not_allowed_email",
        intl.formatMessage({ id: "registration.additional.error.email" }),
        (v) => !/@.*\.edu$/.test(v),
      )
      .email(intl.formatMessage({ id: "registration.additional.error.email" })),
    contactFirstname: string()
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 40 },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    contactLastname: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    contactPhoneNumber: string()
      .required(requiredMessage)
      .matches(
        /^[\d]{10}$/,
        "Please ensure you have entered a valid (ten digit) mobile number",
      ),
    contactRelationship: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      )),
    secondContactEmailAddress: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .test(
        "not_allowed_email",
        intl.formatMessage({ id: "registration.additional.error.email" }),
        (v) => !/@.*\.edu$/.test(v),
      )
      .email(intl.formatMessage({ id: "registration.additional.error.email" })),
    secondContactFirstname: string()
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 40 },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    secondContactLastname: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    secondContactPhoneNumber: string()
      .required(requiredMessage)
      .matches(
        /^[\d]{10}$/,
        "Please ensure you have entered a valid (ten digit) mobile number",
      ),
    secondContactRelationship: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      )),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.alternate_contact.title"
        subheadline="registration.alternate_contact.text"
      />
      <Formik
        initialValues={{
          contactEmailAddress: registrationData.contactEmailAddress,
          contactFirstname: registrationData.contactFirstname,
          contactLastname: registrationData.contactLastname,
          contactPhoneNumber: registrationData.contactPhoneNumber,
          contactRelationship: registrationData.contactRelationship,
          secondContactEmailAddress: registrationData.secondContactEmailAddress,
          secondContactFirstname: registrationData.secondContactFirstname,
          secondContactLastname: registrationData.secondContactLastname,
          secondContactPhoneNumber: registrationData.secondContactPhoneNumber,
          secondContactRelationship: registrationData.secondContactRelationship,
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          const {
            contactFirstname,
            contactLastname,
            secondContactFirstname,
            secondContactLastname,
            contactPhoneNumber,
            secondContactPhoneNumber,
          } = values;

          if (registrationData.firstname.toLowerCase() === contactFirstname.toLowerCase()
            && registrationData.lastname.toLowerCase() === contactLastname.toLowerCase()) {
            errors.contactLastname = intl.formatMessage({ id: "registration.alternate_contact.error.same_name" });
          }

          if (registrationData.firstname.toLowerCase() === secondContactFirstname.toLowerCase()
            && registrationData.lastname.toLowerCase() === secondContactLastname.toLowerCase()) {
            errors.secondContactLastname = intl.formatMessage({ id: "registration.alternate_contact.error.same_name" });
          }

          if (contactFirstname.toLowerCase() === secondContactFirstname.toLowerCase()
            && contactLastname.toLowerCase() === secondContactLastname.toLowerCase()) {
            errors.secondContactLastname = intl.formatMessage({ id: "registration.alternate_contact.error.same_name" });
          }

          if (registrationData.mobileNumber === contactPhoneNumber) {
            errors.contactPhoneNumber = intl.formatMessage({ id: "registration.alternate_contact.error.same_phone" });
          }

          if (registrationData.additionalPhoneNumber === contactPhoneNumber) {
            errors.contactPhoneNumber = intl.formatMessage({ id: "registration.alternate_contact.error.same_phone" });
          }

          if (registrationData.mobileNumber === secondContactPhoneNumber) {
            errors.secondContactPhoneNumber = intl.formatMessage({ id: "registration.alternate_contact.error.same_phone" });
          }

          if (registrationData.additionalPhoneNumber === secondContactPhoneNumber) {
            errors.secondContactPhoneNumber = intl.formatMessage({ id: "registration.alternate_contact.error.same_phone" });
          }

          if (values.contactPhoneNumber === values.secondContactPhoneNumber) {
            errors.secondContactPhoneNumber = intl.formatMessage({ id: "registration.alternate_contact.error.same_phone" });
          }

          return errors;
        }}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              contactEmailAddress: values.contactEmailAddress,
              contactFirstname: values.contactFirstname,
              contactLastname: values.contactLastname,
              contactPhoneNumber: values.contactPhoneNumber,
              contactRelationship: values.contactRelationship,
              secondContactEmailAddress: values.secondContactEmailAddress,
              secondContactFirstname: values.secondContactFirstname,
              secondContactLastname: values.secondContactLastname,
              secondContactPhoneNumber: values.secondContactPhoneNumber,
              secondContactRelationship: values.secondContactRelationship,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_alternate_contact", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="registration-dialog__subheader">
              <FormattedMessage id="registration.alternate_contact.first_contact" />
            </div>
            <Row>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="contactFirstname"
                  label={intl.formatMessage({ id: "registration.alternate_contact.firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="contactLastname"
                  label={intl.formatMessage({ id: "registration.alternate_contact.lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12} lg={6}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.please_select" })}
                  placeholder={intl.formatMessage({ id: "registration.alternate_contact.relationship" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("contactRelationship", target.value);
                      setFieldError("contactRelationship", undefined);
                    } else {
                      setFieldValue("contactRelationship", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("contactRelationship", true);
                  }}
                  touched={touched}
                  id="contactRelationship"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.relationshipOptionsIn}
                  required
                  maxMenuHeight={300}
                />
              </Col>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="contactPhoneNumber"
                  label={intl.formatMessage({ id: "registration.alternate_contact.phone_number" })}
                  type="text"
                  maxLength={10}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12} className="mb-0">
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="contactEmailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>
            </Row>

            <div className="registration-dialog__subheader">
              <FormattedMessage id="registration.alternate_contact.second_contact" />
            </div>
            <Row>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="secondContactFirstname"
                  label={intl.formatMessage({ id: "registration.alternate_contact.second_firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="secondContactLastname"
                  label={intl.formatMessage({ id: "registration.alternate_contact.second_lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12} lg={6}>
                <DropdownFormField
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  label={intl.formatMessage({ id: "registration.alternate_contact.second_relationship" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("secondContactRelationship", target.value);
                      setFieldError("secondContactRelationship", undefined);
                    } else {
                      setFieldValue("secondContactRelationship", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("secondContactRelationship", true);
                  }}
                  touched={touched}
                  id="secondContactRelationship"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.relationshipOptionsIn}
                  required
                  maxMenuHeight={300}
                />
              </Col>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="secondContactPhoneNumber"
                  label={intl.formatMessage({ id: "registration.alternate_contact.second_phone_number" })}
                  type="text"
                  maxLength={10}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="secondContactEmailAddress"
                  label={intl.formatMessage({ id: "registration.alternate_contact.second_email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  tooltip
                />
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.alternate_contact.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationAlternateContactIn.stepName = "registration_alternate_contact";

RegistrationDialogStepRegistrationAlternateContactIn.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationAlternateContactIn;
