import IoBaseApiSlice from "./ioBaseApiSlice";

export const IoVirtualDriveApiSlice = IoBaseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    submitIoVirtualDrive: build.mutation({
      query: ({ data, urns = null }) => ({
        apiRequirements: ["session", "csrfToken"],
        data: {
          classification: "Restricted",
          payload: data,
          ...(urns !== null && { meta: urns }),
        },
        method: "post",
        url: `${process.env.GATSBY_IO_HOST}/inq/${process.env.GATSBY_IO_URN_VIRTUAL_DRIVE}`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
  }),
});

export const {
  useSubmitIoVirtualDriveMutation,
} = IoVirtualDriveApiSlice;
