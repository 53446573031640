import React from "react";

import isFuture from "date-fns/isFuture";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import filterByCategory from "./utils";
import MegaMenuResult from "../../components/megaMenuResult/megaMenuResult";
import KontentItemImage from "../kontentItemImage";

const MegaMenuResultsEvent = (props) => {
  const {
    item,
  } = props;

  const data = useStaticQuery(graphql`{
    allKontentItemEvent (sort: {fields: elements___date_start___value, order: ASC}) {
      nodes {
        id
        system {
          codename     
        }
        elements {
          title {
            value
          }
          image {
            value {
              ...KontentItemImage
            }
          }
          date_end {
            value
          }
          event_category {
            value {
             codename
            } 
          }
        }
      }
    }
  }
`);

  if (!data) return null;

  const filteredByEndDate = data.allKontentItemEvent.nodes.filter(
    (event) => isFuture(new Date(event.elements.date_end.value)),
  );

  const filteredData = filterByCategory(filteredByEndDate, "event_category", item.categories);

  const slicedData = filteredData.slice(0, 4);

  return slicedData.map((event) => (
    <MegaMenuResult
      key={event.id}
      href={event.id}
      image={(
        <KontentItemImage
          data={event.elements.image}
          width={48}
          height={48}
          aspectRatio={1}
          fit="crop"
          defaultImage
        />
      )}
    >
      {event.elements.title.value}
    </MegaMenuResult>
  ));
};

MegaMenuResultsEvent.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]),
};

MegaMenuResultsEvent.defaultProps = {
  item: null,
};

export default MegaMenuResultsEvent;
