import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Consent from "../components/consent/consent";

const KontentItemEmbedExternal = ({ data }) => {
  const embedData = data.value ? data.value[0] : data;

  if (!embedData || !embedData.elements) {
    return null;
  }

  let urlParameter = [];
  let url;
  let consentType = "";

  switch (true) {
    case embedData.elements.document_url.value.startsWith("https://issuu.com"):
      if (embedData.elements.document_url.value) {
        urlParameter = embedData.elements.document_url.value.substring(18).split("/");
      }

      url = `https://e.issuu.com/embed.html?d=${urlParameter[2]}&u=${urlParameter[0]}`;
      break;
    case embedData.elements.document_url.value.startsWith("https://open.spotify.com"):
      consentType = "spotify";

      if (embedData.elements.document_url.value) {
        urlParameter = embedData.elements.document_url.value.substring(25).split("/");
      }

      url = `https://open.spotify.com/embed/${urlParameter[0]}/${urlParameter[1]}`;
      break;
    default:
      url = embedData.elements.document_url.value;
  }

  if (consentType === "spotify") {
    return (
      <Consent type={consentType}>
        <div>
          <iframe
            title={embedData.elements.title.value}
            className="border-0"
            src={url}
            sandbox
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            width="100%"
            height="232px"
            style={{ maxWidth: "90.3rem" }}
          />
        </div>
      </Consent>
    );
  }

  return (
    <Consent type={consentType}>
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          title={embedData.elements.title.value}
          className="border-0"
          src={url}
          sandbox
          allow="fullscreen;"
        />
      </div>
    </Consent>
  );
};

export const query = graphql`
  fragment KontentItemEmbedExternal on kontent_item_embed_external {
    id
    system {
      codename
    }
    elements {
      document_url {
        value
      }
      title {
        value
      }
    }
  }
`;

KontentItemEmbedExternal.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEmbedExternal;
