import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useDispatch, useSelector } from "react-redux";

import Button from "../components/button/button";
import TopBanner from "../components/topBanner/topBanner";
import { excludeTopNotification } from "../features/notificationSlice";
import iconHelper from "../utils/iconHelper";

const KontentItemTopBannerAction = () => {
  const excludedTopNotifications = useSelector((state) => (
    state.notification.excludedTopNotifications
  ));
  const dispatch = useDispatch();

  const data = useStaticQuery(graphql`
    {
      topBannerActions: allKontentItemTopBannerAction(sort: {fields: system___last_modified, order: ASC}, filter: {system: {workflow_step: {ne: "archived"}}}) {
        nodes {
          id
          system {
            codename
            last_modified
          }
          elements {
            headline {
              value
            }
            icon {
              value {
                codename
                name
              }
            }
            text {
              value
            }
            primary_button_text {
              value
            }
            primary_button_url {
              value {
                id
              }
            }
            primary_button_url_extern {
              value
            }
            secondary_button_text {
              value
            }
            secondary_button_url {
              value {
                id
              }
            }
            secondary_button_url_extern {
              value
            }
          }
        }
      }
    }
  `);

  const topBannerActions = data && data.topBannerActions && data.topBannerActions.nodes;

  if (!topBannerActions || topBannerActions.length === 0) {
    return null;
  }

  let topBannerAction = null;

  topBannerActions.forEach((item) => {
    if (topBannerAction === null && !excludedTopNotifications.find(
      (x) => x.id === item.id
        && x.last_modified === item.system.last_modified,
    )) {
      topBannerAction = item;
    }
  });

  if (topBannerAction === null) {
    return null;
  }

  return (
    <TopBanner
      onClose={() => {
        dispatch(
          excludeTopNotification(
            { id: topBannerAction.id, last_modified: topBannerAction.system.last_modified },
          ),
        );
      }}
      headline={topBannerAction.elements.headline.value}
      text={topBannerAction.elements.text.value}
      headlinePreIcon={iconHelper(
        topBannerAction.elements.icon.value.length > 0
          ? topBannerAction.elements.icon.value[0].codename
          : null,
      )}
      action1={(
        <Button
          variant="plain-faq"
          href={
            topBannerAction.elements.primary_button_url.value.length > 0
              ? topBannerAction.elements.primary_button_url.value[0].id
              : topBannerAction.elements.primary_button_url_extern.value
          }
        >
          {topBannerAction.elements.primary_button_text.value}
        </Button>
      )}
      action2={(
        <Button
          variant="text-faq"
          href={
            topBannerAction.elements.secondary_button_url.value.length > 0
              ? topBannerAction.elements.secondary_button_url.value[0].id
              : topBannerAction.elements.secondary_button_url_extern.value
          }
        >
          {topBannerAction.elements.secondary_button_text.value}
        </Button>
      )}
    />
  );
};

export default KontentItemTopBannerAction;
