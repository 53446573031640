import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./anchorPoints.scss";

const AnchorPoints = ({ children, isArticle }) => {
  const content = useRef(null);
  const [anchorPoints, setAnchorPoints] = useState(null);

  useEffect(() => {
    const headlines = content.current.getElementsByTagName("h3");

    if (headlines.length > 2) {
      const tmpAnchorPoints = [];

      for (let i = 0; i < headlines.length; i += 1) {
        headlines[i].id = `anchor-point-${i}`;

        tmpAnchorPoints.push(
          <a className="anchor-button" href={`#anchor-point-${i}`} key={`ap${i}`}>
            {headlines[i].textContent}
          </a>,
        );
      }

      setAnchorPoints(tmpAnchorPoints);
    }
  }, [content]);

  if (!children.props.data) {
    return null;
  }
  const linkListColProps = isArticle
    ? {
      lg: { offset: 2, span: 8 },
      sm: { offset: 1, span: 10 },
      xs: 12,
    }
    : {
      lg: 7,
      md: 10,
    };

  const segmentListColProps = isArticle
    ? {
      xs: 12,
    }
    : {
      lg: 7,
      md: 10,
    };

  return (
    <Row>
      {anchorPoints && (!isArticle || (isArticle && anchorPoints.length >= 3)) && (
        <Col className="anchor-points__link-list" {...linkListColProps}>
          {anchorPoints}
        </Col>
      )}
      <Col className="anchor-points__segment-list" ref={content} {...segmentListColProps}>
        {children}
      </Col>
    </Row>
  );
};

AnchorPoints.propTypes = {
  children: PropTypes.node.isRequired,
  isArticle: PropTypes.bool,
};

AnchorPoints.defaultProps = {
  isArticle: false,
};

export default AnchorPoints;
