import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import CardSlider from "../../components/cardSlider/cardSlider";
import Hubsection from "../../components/hubsection/hubsection";
import IntroSection from "../../components/introSection/introSection";
import SEO from "../../components/seo";
import CampaignRelatedStory from "../campaigns/campaignRelatedStory";
import LatestCampaignCards from "../campaigns/latestCampaignCards";
import KontentItemHeroCarouselCampaigns from "../kontentItemHeroCarouselCampaigns";
import KontentItemIconCardSliderSection from "../kontentItemIconCardSliderSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";

const KontentItemCampaignsOverviewPage = ({ data }) => {
  const { page, stories } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <KontentItemHeroCarouselCampaigns data={page.elements.hero_carousel} />

      <BreadcrumbGenerator id={page.id} />

      <IntroSection
        headline={page.elements.headline.value}
        text={page.elements.description.value}
        headlineAs="h1"
      />

      <Hubsection variant="campaign">
        {LatestCampaignCards()}
      </Hubsection>

      <CardSlider
        header={page.elements.headline_related_stories.value}
      >
        {CampaignRelatedStory({ data: stories.nodes })}
      </CardSlider>

      <KontentItemIconCardSliderSection data={page.elements.icon_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    stories: allKontentItemArticlePageCampaign {
      nodes {
        elements {
          related_story {
            value {
              ...NewsCard
              ...NewsCardMigrated
            }
          }
        }
      }
    }
    page: kontentItemCampaignsOverviewPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        description {
          value
        }
        hero_carousel {
          value {
            ...KontentItemHeroCarouselCampaigns
          }
        }
        icon_card_slider {
          value {
            ...KontentItemIconCardSliderSection
          }
        }
        headline_related_stories {
          value
        }
      }
    }
  }
`;

KontentItemCampaignsOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemCampaignsOverviewPage;
