import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import StateAwareAccordionToggle from "../stateAwareAccordionToggle/stateAwareAccordionToggle";

import "./moneyPartition.scss";

const MoneyPartition = ({ accordionItems, title }) => {
  const [activeItemImage, setActiveItemImage] = useState(0);
  useEffect(() => {
    accordionItems.forEach((item) => {
      const img = new Image();
      img.src = item.image.props.src;
    });
  }, []);

  return (
    <Row className="money-partition">
      <Col md={8} lg={7} className="money-partition__title">
        <h2>{title}</h2>
      </Col>
      <Col md={6} lg={{ offset: 1, span: 5 }} className="money-partition__image-container">
        {accordionItems[activeItemImage].image}
        <div className="money-partition__image-description">
          <div className="typo-title1">{accordionItems[activeItemImage].title}</div>
          <div className="typo-title3">{accordionItems[activeItemImage].amount}</div>
        </div>
      </Col>
      <Col md={6} className="order-md-1">
        <Accordion defaultActiveKey={accordionItems[0].title}>
          {accordionItems.map((item, i) => (
            <div key={item.title} className="money-partition__accordion-item">
              <StateAwareAccordionToggle
                eventKey={item.title}
                callback={() => setActiveItemImage(i)}
              >
                <h4>
                  {item.title} ({item.amount})
                </h4>
              </StateAwareAccordionToggle>

              <Accordion.Collapse eventKey={item.title}>
                <>
                  {
                    item.subheadline && item.subheadline !== ""
                      ? (
                        <>
                          <div className="typo-h4">
                            {item.subheadline}
                          </div>
                          {item.text}
                        </>
                      )
                      : item.text
                  }
                </>
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      </Col>
    </Row>
  );
};

MoneyPartition.propTypes = {
  accordionItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  title: PropTypes.string.isRequired,
};

export default MoneyPartition;
