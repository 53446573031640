import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ContactIconButton from "../../components/button/contactIconButton";
import ContactAccordion from "../../components/contactAccordion/contactAccordion";
import ContentHeader from "../../components/contentHeader/contentHeader";
import OfficeList from "../../components/officeList/officeList";
import SEO from "../../components/seo";
import KontentItemModalView from "../kontenItemModalView";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemContact from "../kontentItemContact";
import KontentItemContactFaqBox from "../kontentItemContactFaqBox";
import KontentItemContactOffice from "../kontentItemContactOffice";
import KontentItemImageCardSliderSection from "../kontentItemImageCardSliderSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";

const KontentItemContactPage = ({ data }) => {
  const { page } = data;

  const contactBoxes = [];
  let generalInquiries = null;

  if (page.elements.contact_boxes.value.length > 0) {
    const contacts = [...page.elements.contact_boxes.value];

    const general = contacts.pop();

    generalInquiries = {
      button: (
        <ContactIconButton
          variant="inverted-registration"
          href={
            general.elements.button_url.value.length > 0
              ? general.elements.button_url.value[0].id
              : general.elements.button_url_webform.value
          }
          mailto={general.elements.button_mailto.value}
          phone={general.elements.button_phone.value}
          contactName={general.elements.title.value}
        >
          {general.elements.button_text.value}
        </ContactIconButton>
      ),
      contact: (
        <KontentItemContact data={general.elements.contact} stack hideDepartment />
      ),
      legalModal: <KontentItemModalView data={general.elements.legal_modal} usePreIcon buttonVariant="typo-button" />,
      text: general.elements.text.value,
      title: general.elements.title.value,
    };

    contacts.forEach((item) => {
      contactBoxes.push(
        {
          button: (
            <ContactIconButton
              variant="inverted-registration"
              href={
                item.elements.button_url.value.length > 0
                  ? item.elements.button_url.value[0].id
                  : item.elements.button_url_webform.value
              }
              mailto={item.elements.button_mailto.value}
              phone={item.elements.button_phone.value}
              contactName={item.elements.title.value}
            >
              {item.elements.button_text.value}
            </ContactIconButton>
          ),
          contact: (
            <KontentItemContact data={item.elements.contact} stack hideDepartment />
          ),
          id: item.id,
          legalModal: <KontentItemModalView data={item.elements.legal_modal} usePreIcon buttonVariant="typo-button" />,
          text: item.elements.text.value,
          title: item.elements.title.value,
        },
      );
    });
  }

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <BreadcrumbGenerator id={page.id} />

      <ContentHeader
        headline={page.elements.headline.value}
        description={page.elements.lead_text.value}
        media={page.elements.image.value.length > 0
          ? (
            <KontentAssetsImage
              data={page.elements.image.value[0]}
              width={271}
            />
          )
          : null}
      />

      <ContactAccordion
        headline={page.elements.title.value}
        contactFaq={(
          <KontentItemContactFaqBox data={page.elements.faq_box} />
        )}
        accordionItems={contactBoxes}
        generalInquiries={generalInquiries}
      />

      <OfficeList headline={page.elements.headline_offices.value}>
        {page.elements.offices.value.map((office) => (
          <KontentItemContactOffice data={office} key={office.id} />
        ))}
      </OfficeList>

      <KontentItemImageCardSliderSection data={page.elements.image_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemContactPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        image {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        title {
          value
        }
        faq_box {
          value {
            ...KontentItemContactFaqBox
          }
        }
        contact_boxes {
          value {
            ...KontentItemContactBox
          }
        }
        headline_offices {
          value
        }
        offices {
          value {
            ...KontentItemContactOffice
          }
        }
        image_card_slider {
          value {
            ...KontentItemImageCardSliderSection
          }
        }
      }
    }
  }
`;

KontentItemContactPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemContactPage;
