import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import { IconFurther24 } from "../../icons";
import LinkButton from "../button/linkButton";
import TitleRow from "../titleRow";

import "./campaigns.scss";

const Campaigns = (props) => {
  const {
    children,
    headline,
    description,
    moreUrl,
  } = props;

  const campaignChildren = React.Children.toArray(children);

  if (campaignChildren.length < 2) {
    return null;
  }

  if (campaignChildren.length === 3) {
    campaignChildren.pop();
  }

  const isLargerEqualXl = useMediaQuery("(min-width: 1200px)");

  return (
    <div className="campaigns">
      <TitleRow description={description}>
        {headline}
      </TitleRow>

      <Row className="row--fullwidth" noGutters>
        <Col xs={12} md={6}>{campaignChildren[0]}</Col>
        <Col xs={12} md={6}>
          <Row noGutters>
            <Col xs={12}>
              {
                campaignChildren[1]
                && React.cloneElement(campaignChildren[1], {
                  small: (campaignChildren.length !== 2),
                })
              }
            </Col>
            <Col xs={12} xl={6}>
              {
                campaignChildren[2]
                && React.cloneElement(campaignChildren[2], {
                  small: true,
                })
              }
            </Col>
            {isLargerEqualXl && (
              <Col xl={6}>
                {
                  campaignChildren[3]
                  && React.cloneElement(campaignChildren[3], {
                    small: true,
                  })
                }
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <LinkButton href={moreUrl}>
        <FormattedMessage id="campaigns.view_all" />
        <IconFurther24 />
      </LinkButton>
    </div>
  );
};

Campaigns.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  moreUrl: PropTypes.string.isRequired,
};

export default Campaigns;
