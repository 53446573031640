import React, { useMemo, useState } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setError, setRegistrationData } from "../../../features/registrationSlice";
import {
  IconAfrica,
  IconChile,
  IconEarth18,
  IconError18,
  IconExternalLink18,
  IconFillForm24,
  IconFurther18,
  IconFurther24,
  IconGermany,
  IconIndia18,
  IconPoland,
  IconUk,
  IconUsa,
} from "../../../icons";
import {
  COUNTRY_AFRICA_URL,
  COUNTRY_CHILE_URL,
  COUNTRY_GERMANY_URL,
  COUNTRY_POLAND_URL,
  COUNTRY_UK_URL,
  COUNTRY_USA_URL,
} from "../../../utils/constants";
import DataLayer from "../../../utils/dataLayer";
import FormObserver from "../../../utils/formik/formObserver";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import AddressInput from "../../addressInput/addressInput";
import IconButton from "../../button/iconButton";
import LinkButton from "../../button/linkButton";
import CollapseBox from "../../collapseBox/collapseBox";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import InputSelect from "../../inputSelect/inputSelect";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const countryOptions = [
  { label: <div><IconIndia18 /> India</div>, value: "india" },
  { label: <div><IconEarth18 /> Other</div>, value: "other" },
];

const RegistrationDialogStepRegistrationResidentialAddressIn = ({ next, content }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [addressShown, setAddressShown] = useState(registrationData.street !== "");

  const getOptionByValue = (options, value) => options.find((el) => el.value === value);

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const warning = useSelector((state) => state.registration.warning);

  const error = useSelector((state) => state.registration.error);

  let warningInfo;

  if (Object.keys(warning).length > 0) {
    warningInfo = RegistrationDataHelper.generateWarning(warning);
  }

  const validationSchema = useMemo(() => object().shape({
    apartment: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    city: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 50 },
      )),
    country: string()
      .required(),
    county: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    houseName: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    houseNo: string()
      .max(10, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 10 },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    locality: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .trim()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    postCode: string()
      .required(requiredMessage)
      .matches(/^\d{6}$/, intl.formatMessage({ id: "form.error.zipcode" })),
    state: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      )),
    street: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 50 },
      ))
      .trim()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
  }), []);

  if (error) {
    const countries = [
      {
        icon: <IconGermany />,
        id: "de",
        name: intl.formatMessage({ id: "country.germany" }),
        url: COUNTRY_GERMANY_URL,
      },
      {
        icon: <IconChile />,
        id: "cl",
        name: intl.formatMessage({ id: "country.chile" }),
        url: COUNTRY_CHILE_URL,
      },
      {
        icon: <IconPoland />,
        id: "pl",
        name: intl.formatMessage({ id: "country.poland" }),
        url: COUNTRY_POLAND_URL,
      },
      {
        icon: <IconUsa />,
        id: "us",
        name: intl.formatMessage({ id: "country.usa" }),
        url: COUNTRY_USA_URL,
      },
      {
        icon: <IconUk />,
        id: "gb",
        name: intl.formatMessage({ id: "country.uk" }),
        url: COUNTRY_UK_URL,
      },
      {
        icon: <IconAfrica />,
        id: "za",
        name: intl.formatMessage({ id: "country.africa" }),
        url: COUNTRY_AFRICA_URL,
      },
      {
        icon: <IconExternalLink18 />,
        id: "wmda",
        name: intl.formatMessage({ id: "wmda" }),
        url: "https://wmda.info/",
      },
    ];

    return (
      <>
        <Row>
          <Col xs={12}>
            <RegistrationDialogHeadline
              headline="registration.address.error.title"
              subheadline="registration.address.error.subtitle"
            />

            <p>
              {countries.map((countryItem) => (
                <LinkButton
                  variant="normal"
                  href={countryItem.url}
                  title={countryItem.name}
                  className="mr-30 mb-18"
                  key={countryItem.id}
                >
                  {countryItem.icon} {countryItem.name}
                </LinkButton>
              ))}
            </p>

            <p><FormattedMessage id="registration.address.error.text" /></p>

            {content.donationBox}

            {!content.donationBox
              && content.errorButtonPrimary}

            {content.errorButtonSecondary}
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.address.title"
        subheadline="registration.address.subtitle"
      />
      <Formik
        initialValues={{
          country: "india",
          ...registrationData,
        }}
        validationSchema={validationSchema}
        validateOnMount
        validateOnChange
        validate={(values) => {
          const errors = {};

          if (values.country !== "india") {
            errors.country = intl.formatMessage({ id: "registration.address.error.other_country" });
          }

          return errors;
        }}
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              apartment: values.apartment,
              city: values.city,
              county: values.county,
              houseName: values.houseName,
              houseNo: values.houseNo,
              locality: values.locality,
              postCode: values.postCode,
              state: values.state,
              street: values.street,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_residential_address", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormObserver />
            <DropdownFormField
              label={intl.formatMessage({ id: "registration.please_select" })}
              touched={touched}
              id="country"
              errors={errors}
              options={countryOptions}
              clearable={false}
              onChange={(selectedOption) => {
                const { target } = selectedOption;

                if (target?.value !== "india") {
                  setAddressShown(false);
                  setFieldError("country");
                }

                setFieldTouched("country", true);

                setFieldValue("country", target?.value);

                // handleChange({
                //   target: {
                //     id: "country",
                //     name: "country",
                //     type: "",
                //     value: selectedOption?.value || "",
                //   },
                // })

                // setTimeout(() => {
                //
                //   validateForm();
                // }, 200);
              }}
              onBlur={handleBlur}
              values={values}
              required
            />
            <AddressInput
              onResult={(data) => {
                setAddressShown(true);

                const tmpStateCode = data.state_code === "CG" ? "CT" : data.state_code;

                setFieldValue("street", data.street || "");
                setFieldValue("houseNo", data.houseno || "");
                setFieldValue("postCode", data.zipcode || "");
                setFieldValue("city", data.city || "");
                setFieldValue("state", (tmpStateCode && (RegistrationDataHelper.stateOptionsIn.find((el) => el.value === tmpStateCode)?.value || "OTHER")) || "");
                setFieldValue("county", data.district || "");
                setFieldValue("locality", data.region || "");
              }}
              disabled={values.country !== "india"}
            />

            <CollapseBox
              disabled={values.country !== "india"}
              opened={addressShown && values.country === "india"}
              label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
              icon={<IconFillForm24 />}
              warning={Object.keys(warning).length > 0}
            >
              <Row>
                <Col xs={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="apartment"
                    label={intl.formatMessage({ id: "registration.address.apartment" })}
                    type="text"
                    warning={warning}
                    maxLength={100}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col xs={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="houseName"
                    label={intl.formatMessage({ id: "registration.address.houseName" })}
                    type="text"
                    warning={warning}
                    maxLength={100}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={4}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="houseNo"
                    label={intl.formatMessage({ id: "registration.address.houseNo" })}
                    type="text"
                    warning={warning}
                    maxLength={10}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col xs={12}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="street"
                    label={intl.formatMessage({ id: "registration.address.street" })}
                    type="text"
                    warning={warning}
                    maxLength={50}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="locality"
                    label={intl.formatMessage({ id: "registration.address.locality" })}
                    type="text"
                    warning={warning}
                    maxLength={50}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="city"
                    label={intl.formatMessage({ id: "registration.address.city" })}
                    type="text"
                    warning={warning}
                    maxLength={50}
                    required
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="postCode"
                    label={intl.formatMessage({ id: "registration.address.postCode" })}
                    type="text"
                    warning={warning}
                    maxLength={6}
                    touched={touched}
                    errors={errors}
                    values={values}
                    required
                  />
                </Col>
                <Col md={6}>
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="county"
                    label={intl.formatMessage({ id: "registration.address.county" })}
                    type="text"
                    warning={warning}
                    touched={touched}
                    errors={errors}
                    values={values}
                  />
                </Col>
                <Col xs={12}>
                  <Form.Group className="mb-0 required">
                    <InputSelect
                      placeholder={intl.formatMessage({ id: "registration.please_select" })}
                      items={RegistrationDataHelper.stateOptionsIn}
                      name="state"
                      value={values.state && getOptionByValue(
                        RegistrationDataHelper.stateOptionsIn,
                        values.state,
                      )}
                      onChange={(selectedOption) => {
                        if (selectedOption && selectedOption.value) {
                          setFieldValue("state", selectedOption.value);
                          setFieldError("state", undefined);
                        } else {
                          setFieldValue("state", "");
                        }
                      }}
                      onBlur={() => {
                        setFieldTouched("state", true);
                      }}
                      isInvalid={(errors.state && touched.state)}
                      maxMenuHeight={300}
                    />
                    <Form.Label>
                      <FormattedMessage id="registration.address.state" />
                    </Form.Label>
                    {errors.state && touched.state && (
                      <Form.Text className="invalid-feedback">
                        <IconError18 />{errors.state}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} className="warning-info">
                  {warningInfo}
                </Col>
              </Row>
            </CollapseBox>

            <ScrollToFieldError />

            <Form.Group>
              <IconButton
                type="submit"
                disabled={isSubmitting}
                onClick={() => {
                  if (values.country === "india") {
                    setAddressShown(true);
                  } else {
                    setAddressShown(false);
                    dispatch(
                      setRegistrationData({
                        apartment: "",
                        city: "",
                        county: "",
                        houseName: "",
                        houseNo: "",
                        locality: "",
                        postCode: "",
                        state: "",
                        street: "",
                      }),
                    );
                    dispatch(
                      setError(true),
                    );
                  }
                }}
              >
                <FormattedMessage id="registration.address.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>

            <div className="footnote--required">
              <span className="required-sign" />
              <FormattedMessage id="registration.required_fields" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationResidentialAddressIn.stepName = "registration_residential_address";

RegistrationDialogStepRegistrationResidentialAddressIn.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationResidentialAddressIn;
