import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import {
  IconFacebookOriginalColored24,
  IconInstagramOriginalColored24,
  IconLinkedIn24,
  IconSpotifyOriginalColored24,
  IconThreads24,
  IconTikTokOriginalColored24,
  IconTwitter24,
  IconXingOriginalColored24,
  IconYoutubeOriginalColored24,
} from "../../icons";

const SocialMedia = () => {
  const data = useStaticQuery(graphql`
    {
      siteSettings: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          url_facebook {
            value
          }
          url_twitter {
            value
          }
          url_instagram {
            value
          }
          url_youtube {
            value
          }
          url_tiktok {
            value
          }
          url_linkedin {
            value
          }
          url_xing {
            value
          }
          url_threads {
            value
          }
          url_spotify {
            value
          }
        }
      }
    }
  `);

  const { siteSettings } = data;

  if (!siteSettings) {
    return null;
  }

  return (
    <>
      {siteSettings.elements.url_instagram?.value !== "" && (
        <a href={siteSettings.elements.url_instagram.value} title="Instagram" target="_blank" rel="noopener noreferrer">
          <IconInstagramOriginalColored24 />
        </a>
      )}
      {siteSettings.elements.url_facebook?.value !== "" && (
        <a href={siteSettings.elements.url_facebook.value} title="Facebook" target="_blank" rel="noopener noreferrer">
          <IconFacebookOriginalColored24 />
        </a>
      )}
      {siteSettings.elements.url_youtube?.value !== "" && (
        <a href={siteSettings.elements.url_youtube.value} title="YouTube" target="_blank" rel="noopener noreferrer">
          <IconYoutubeOriginalColored24 />
        </a>
      )}
      {siteSettings.elements.url_twitter?.value !== "" && (
        <a href={siteSettings.elements.url_twitter.value} title="Twitter" target="_blank" rel="noopener noreferrer">
          <IconTwitter24 />
        </a>
      )}
      {siteSettings.elements.url_tiktok?.value !== "" && (
        <a href={siteSettings.elements.url_tiktok.value} title="TikTok" target="_blank" rel="noopener noreferrer">
          <IconTikTokOriginalColored24 />
        </a>
      )}
      {siteSettings.elements.url_spotify?.value !== "" && (
        <a href={siteSettings.elements.url_spotify.value} title="Spotify" target="_blank" rel="noopener noreferrer">
          <IconSpotifyOriginalColored24 />
        </a>
      )}
      {siteSettings.elements.url_linkedin?.value !== "" && (
        <a href={siteSettings.elements.url_linkedin.value} title="LinkedIn" target="_blank" rel="noopener noreferrer">
          <IconLinkedIn24 />
        </a>
      )}
      {process.env.GATSBY_SITE === "de" && siteSettings.elements.url_xing?.value !== "" && (
        <a href={siteSettings.elements.url_xing.value} title="Xing" target="_blank" rel="noopener noreferrer">
          <IconXingOriginalColored24 />
        </a>
      )}
      {siteSettings.elements.url_threads?.value !== "" && (
        <a href={siteSettings.elements.url_threads.value} title="Threads" target="_blank" rel="noopener noreferrer">
          <IconThreads24 />
        </a>
      )}
    </>
  );
};

export default SocialMedia;
