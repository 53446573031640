import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { SectionIconFaq } from "../../images";
import FaqAccordion from "../faqAccordion/faqAccordion";

import "./faqSection.scss";

const FaqSection = (props) => {
  const {
    title,
    accordionItems,
    icon,
  } = props;

  return (
    <Row className="faq-section">
      <Col lg={8}>
        <Row>
          <Col sm={10} md={8} lg={10}>
            <h2 className="faq-section__title">{title}</h2>
          </Col>
        </Row>
        <FaqAccordion accordionItems={accordionItems} titleAs="h4" />
      </Col>
      <Col lg={4} className="faq-section__icon">
        {icon || <SectionIconFaq />}
      </Col>
    </Row>
  );
};

FaqSection.propTypes = {
  accordionItems: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    id: PropTypes.string.isRequired,
    question: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  })).isRequired,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
};

FaqSection.defaultProps = {
  icon: null,
};

export default FaqSection;
