import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import StyledTab from "./styledTab";
import TabList from "./tabList";
import { IconList24, IconMap24 } from "../../icons";

const EventTabList = ({ mapContent, listContent }) => {
  const intl = useIntl();

  return (
    <TabList
      items={
        [
          {
            content: mapContent,
            key: "EventTabListMapContent",
            tab: <StyledTab title={intl.formatMessage({ id: "event_tab_list.map_view" })} icon={<IconMap24 />} eventKey="EventTabListMapContent" />,
          },
          {
            content: listContent,
            key: "EventTabListListContent",
            tab: <StyledTab title={intl.formatMessage({ id: "event_tab_list.list_view" })} icon={<IconList24 />} eventKey="EventTabListListContent" />,
          },
        ]
      }
    />
  );
};

EventTabList.propTypes = {
  listContent: PropTypes.node.isRequired,
  mapContent: PropTypes.node.isRequired,
};

export default EventTabList;
