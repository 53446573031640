import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { IconArticle18 } from "../../icons";
import IconText from "../iconText/iconText";
import NavigationLink from "../navigationLink/navigationLink";

import "./highlightArticle.scss";

const HighlightArticle = (props) => {
  const {
    image,
    headline,
    text,
    url,
    imageRight,
  } = props;

  return (
    <Row className="highlight-article">
      <Col
        xs={{ offset: 2, span: 8 }}
        sm={{ offset: 1, span: 4 }}
        md={{ offset: imageRight ? 2 : 1, span: 4 }}
        className={classNames({ "highlight-article__image--right": imageRight })}
      >
        <div className="highlight-article__image">
          {image}
        </div>
      </Col>
      <Col xs={12} sm={7} md={imageRight ? 6 : { offset: 1, span: 6 }} className="highlight-article__content">
        <IconText icon={<IconArticle18 />} variant="learn-area" text={<FormattedMessage id="article_card.article" />} />
        <div className="highlight-article__headline">{headline}</div>
        <div className="highlight-article__text">{text}</div>
        <div className="highlight-article__button">
          <NavigationLink href={url} className="btn btn-inverted-learn-area"><FormattedMessage id="article_card.read_more" /></NavigationLink>
        </div>
      </Col>
    </Row>
  );
};

HighlightArticle.propTypes = {
  headline: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  imageRight: PropTypes.bool,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

HighlightArticle.defaultProps = {
  imageRight: false,
};

export default HighlightArticle;
