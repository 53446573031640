import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import useEventListener from "../../../hooks/haiku/useEventListener";
import useMediaQuery from "../../../hooks/haiku/useMediaQuery";
import { IconClose18, IconGlobe18, IconMenu18 } from "../../../icons";
import KontentItemUniversalNavigation from "../../../templates/kontentItemUniversalNavigation";
import scrollActiveNavItem from "../../../utils/scrollActiveNavItem";
import BrowserDetection from "../../browserDetection";
import IconButton from "../../button/iconButton";
import TextLightbox from "../../lightbox/textLightbox";
import Logo from "../../logo";
import Search from "../../search/search";
import UserNavigation from "../../ssp/userNavigation/userNavigation";
import HeaderButtons from "../headerButtons";
import HeaderButtonsGroup from "../headerButtonsGroup";
import MobileNavigation from "../mobileNavigation";
import PrimaryNavigation from "../primaryNavigation";
import SecondaryNavigation from "../secondaryNavigation";

import "./megamenu.scss";
import "./navigation.scss";

const prevScroll = {
  headerButtonsHeight: 0,
  prevScrollPos: 0,
  translate: 0,
  translateButton: 0,
};

const Navigation = ({ isAsia, isGroup }) => {
  const intl = useIntl();
  const isGreaterLg = useMediaQuery("(min-width: 992px)");

  const allWebsitesOpen = useRef(null);
  const burgerNavigation = useRef(null);
  const scrollMenu = useRef(null);
  const navMenu = useRef(null);

  const [burgerMenuSearching, setBurgerMenuSearching] = useState(false);
  const [burgerNavigationOpen, setBurgerNavigationOpen] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [resizeTimeout, setResizeTimeout] = useState(null);
  const [scrollMenuClasses, setScrollMenuClasses] = useState("");

  const data = useStaticQuery(graphql`
    {
      primaryNavigationBurger: kontentItemNavigationItem(system: {codename: {eq: "primary_navigation"}}) {
        id
        elements {
          elements {
            value {
              id
              preferred_language
              ... on kontent_item_navigation_item {
                id,
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                  elements {
                    value {
                      ... on kontent_item_navigation_item {
                        id
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  let headerButtons = {
    desktop: (<HeaderButtons />),
    mobile: (<HeaderButtons
      donationButtonProps={{
        className: "btn btn-inverted-donation",
      }}
      registrationButtonProps={{
        className: "btn btn-registration",
      }}
    />),
  };

  if (isGroup) {
    headerButtons = {
      desktop: (<HeaderButtonsGroup />),
      mobile: (<HeaderButtonsGroup
        donationButtonProps={{
          className: "btn btn-inverted-donation",
        }}
        registrationButtonProps={{
          className: "btn btn-registration",
        }}
      />),
    };
  } else if (isAsia) {
    headerButtons = null;
  }

  const toggleSearch = (show = null) => {
    const state = show !== null ? show : !searchOpen;

    setSearchOpen(state);

    if (state) {
      document.body.parentNode.classList.add("html-search--open");
    } else {
      document.body.parentNode.classList.remove("html-search--open");
    }
  };

  const toggleBurgerNavigation = (show = null) => {
    const state = show !== null ? show : !burgerNavigationOpen;
    setBurgerNavigationOpen(state);
    setHasShadow(false);

    if (state) {
      document.body.parentNode.classList.add("html-burger-navigation--open");
    } else {
      document.body.parentNode.classList.remove("html-burger-navigation--open");
    }
  };

  const scrollTrigger = () => {
    if (!scrollMenu?.current) return null;

    const target = scrollMenu.current.firstElementChild;

    const classes = [];

    if (target.scrollLeft > 20) {
      classes.push("menu-scrolling--left");
    }

    if (
      target.scrollWidth > target.clientWidth
      && target.scrollLeft + target.clientWidth !== target.scrollWidth
    ) {
      classes.push("menu-scrolling--right");
    }

    const classesString = classes.join(" ");

    if (scrollMenuClasses !== classesString) {
      setScrollMenuClasses(classesString);
    }

    return null;
  };

  const resize = () => {
    clearTimeout(resizeTimeout);
    const tmpResizeTimeout = setTimeout(() => {
      if (windowWidth !== window.innerWidth) {
        setWindowWidth(window.innerWidth);
        toggleSearch(false);
        toggleBurgerNavigation(false);
        allWebsitesOpen.current?.close();

        scrollTrigger();
      }
    }, 200);
    setResizeTimeout(tmpResizeTimeout);
  };

  useEventListener("resize", resize);

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    if (navMenu?.current) {
      navMenu.current.scrollLeft = scrollActiveNavItem(navMenu.current);
    }
    scrollTrigger();

    return () => {
      clearTimeout(resizeTimeout);
    };
  }, []);

  const [showHeaders, setShowHeaders] = useState(false);
  const navigationRef = useRef(null);
  const headerButtonsRef = useRef(null);

  const getActiveKey = (primaryNavigationBurger) => {
    let activeKey = "";

    primaryNavigationBurger.elements.elements.value.forEach((item, index) => {
      if (window.location.pathname.includes(item.elements.url_slug.value)) {
        activeKey = `menu${index}`;
      }
    });

    return activeKey;
  };

  useEffect(() => {
    prevScroll.headerButtonsHeight = headerButtonsRef?.current?.getBoundingClientRect()?.height;
  }, [navigationRef, headerButtonsRef]);

  const isMobile = BrowserDetection.hasTouchScreen();

  useEffect(() => {
    if (isMobile) {
      prevScroll.prevScrollPos = window.scrollY;
      const currentScrollPos = window.scrollY;
      let navigationHeight = navigationRef.current.getBoundingClientRect().height;

      if (currentScrollPos >= navigationHeight) {
        headerButtonsRef?.current?.classList.add("d-none");
        navigationHeight = navigationRef.current.getBoundingClientRect().height;
        prevScroll.translate = navigationHeight * -1;
        navigationRef.current.style.transform = `translate(0px, ${prevScroll.translate}px)`;
      }
    }
  }, []);

  if (isMobile) {
    useEventListener(
      "scroll",
      () => {
        const currentScrollPos = window.scrollY;
        const navigationHeight = navigationRef.current.getBoundingClientRect().height;

        if (prevScroll.prevScrollPos > currentScrollPos) {
          // scrollup
          const diff = prevScroll.prevScrollPos - window.scrollY;

          if (navigationRef?.current) {
            if (prevScroll.translate + diff < 0) {
              prevScroll.translate += diff;
            } else {
              prevScroll.translate = 0;
            }

            navigationRef.current.style.transform = `translate(0px, ${prevScroll.translate}px)`;
          }
        } else if (prevScroll.prevScrollPos <= currentScrollPos) {
          // scrolldown
          const diff = currentScrollPos - prevScroll.prevScrollPos;

          if (navigationRef?.current) {
            if (prevScroll.translate - diff < navigationHeight * -1) {
              prevScroll.translate = navigationHeight * -1;
            } else {
              prevScroll.translate -= diff;
            }
            navigationRef.current.style.transform = `translate(0px, ${prevScroll.translate}px)`;
          }

          if (currentScrollPos >= navigationHeight) {
            headerButtonsRef?.current?.classList.add("d-none");
            setShowHeaders(true);
          }
        }

        if (currentScrollPos <= 0) {
          prevScroll.translate = 0;
          headerButtonsRef?.current?.classList.remove("d-none");
          setShowHeaders(false);
        }
        prevScroll.prevScrollPos = currentScrollPos;
      },
      window,
      { passive: true },
    );
  }

  return (
    <>
      <div id="navigation-container" className={classNames("navigation container--fullwidth", { "fixed-top": !isGreaterLg })} ref={navigationRef}>
        <Container fluid>

          {isGreaterLg && (
            <SecondaryNavigation
              searchOnClick={() => {
                toggleSearch();
              }}
              allWebsiteOnClick={() => {
                allWebsitesOpen.current.show();
              }}
              isAsia={isAsia}
              isGroup={isGroup}
            />
          )}
          <div className={classNames(
            "main-navigation__container container--fullwidth",
            { "scroll-shadow": hasShadow },
          )}
          >
            <Container fluid className="d-lg-flex">
              <Navbar expand="lg" className="main-navigation">
                <Navbar.Brand
                  className="d-lg-none"
                  href="/"
                  title={intl.formatMessage({ id: "navigation.logo_title" })}
                >
                  <Logo breakpoint="lg" />
                </Navbar.Brand>
                {isAsia && (
                  <button
                    className={classNames("all-websites-toggle pr-0 d-lg-none", { "top-navigation__delimiter": !isAsia })}
                    onClick={() => {
                      allWebsitesOpen.current.show();
                    }}
                    type="button"
                    aria-label={intl.formatMessage({ id: "navigation.dkms_websites" })}
                  >
                    <span>
                      <FormattedMessage id="navigation.dkms_websites" />
                    </span>
                    <IconGlobe18 />
                  </button>
                )}
                {!isAsia && (
                  <>
                    <div className="d-lg-none">
                      {!isGroup && (<UserNavigation />)}

                      <IconButton
                        iconOnly
                        size="sm"
                        variant="utility"
                        className="ml-4"
                        onClick={() => {
                          toggleBurgerNavigation();
                        }}
                        type="button"
                        title={intl.formatMessage({ id: burgerNavigationOpen ? "navigation.logo_title" : "navigation.menu.menu" })}
                      >
                        {burgerNavigationOpen ? <IconClose18 /> : <IconMenu18 />}
                      </IconButton>
                    </div>

                    {headerButtons && React.cloneElement(headerButtons.mobile, {
                      className: classNames("d-lg-none", { "d-none": burgerNavigationOpen || showHeaders }),
                    })}

                    {isGreaterLg && (
                      <Navbar.Collapse
                        className={scrollMenuClasses}
                        ref={scrollMenu}
                        onScroll={() => scrollTrigger()}
                      >
                        <Nav ref={navMenu}>
                          <PrimaryNavigation />
                        </Nav>
                      </Navbar.Collapse>
                    )}
                  </>
                )}
              </Navbar>
              {!isAsia && (
                <div className="navigation-cta ml-auto d-none d-lg-inline-flex">
                  {headerButtons && headerButtons.desktop}
                  {!isGroup && (<UserNavigation />)}
                </div>
              )}
            </Container>
          </div>

          {!isAsia && searchOpen && (
            <div className="search-overlay">
              <Search
                onCancel={() => {
                  toggleSearch();
                }}
                desktop
                focus
              />
            </div>
          )}

          {burgerNavigationOpen && (
            <div
              className="burger-navigation"
              ref={burgerNavigation}
              onScroll={() => {
                setHasShadow(burgerNavigation?.current?.scrollTop !== 0);
              }}
            >
              <div className="navigation-cta">
                {headerButtons && headerButtons.mobile}
              </div>

              {!isAsia && (
                <Search
                  onFocus={() => {
                    setBurgerMenuSearching(true);
                  }}
                  onBlur={() => {
                    setBurgerMenuSearching(false);
                  }}
                />
              )}
              {!burgerMenuSearching && (
                <Nav>
                  <Accordion defaultActiveKey={getActiveKey(data.primaryNavigationBurger)}>
                    <MobileNavigation />
                    <div className="burger-navigation__footer">
                      <button
                        className={classNames("all-websites-toggle top-navigation__delimiter px-0", { "html-all-websites--open": allWebsitesOpen.current })}
                        onClick={() => {
                          allWebsitesOpen.current.show();
                        }}
                        type="button"
                      >
                        <IconGlobe18 />
                        <span>
                          <FormattedMessage id="navigation.dkms_websites" />
                        </span>
                      </button>
                    </div>
                  </Accordion>
                </Nav>
              )}

            </div>
          )}
        </Container>

        <TextLightbox
          ref={allWebsitesOpen}
          title={intl.formatMessage({ id: "navigation.dkms_websites" })}
        >
          <KontentItemUniversalNavigation />
        </TextLightbox>
      </div>
      <HeaderButtons
        className={classNames("d-lg-none", { "d-none": burgerNavigationOpen || !showHeaders })}
        donationButtonProps={{
          className: "btn btn-inverted-donation",
        }}
        registrationButtonProps={{
          className: "btn btn-registration",
        }}
      />
    </>
  );
};

Navigation.propTypes = {
  isAsia: PropTypes.bool.isRequired,
  isGroup: PropTypes.bool.isRequired,
};
export default Navigation;
