import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import KontentItemImage from "../../templates/kontentItemImage";
import { format } from "../../utils/dateHelper";
import SocialMediaShare from "../socialMediaShare/socialMediaShare";

const HeroCampaign = (props) => {
  const {
    children,
    imageData,
    eyecatcher,
    date,
    disableShareButtons,
  } = props;

  return (
    <div className="container--fullwidth">
      <div className={classNames("hero-headline hero-headline--campaign", { "hero-headline--eyecatcher": eyecatcher })}>
        <div className="hero-headline__gradient">
          <Container fluid>
            <Row>
              <Col md={10} lg={8}>
                {date !== null && (
                  <div className="hero-headline__category-container">
                    <div className="hero-headline__category">
                      <FormattedMessage id="hero_carousel_card.campaign" />
                    </div>
                    <div className="hero-headline__date">{format(date)}</div>
                  </div>
                )}
                <h1 className={classNames({ "disabled-share-buttons": disableShareButtons })}>{children}</h1>
                {!disableShareButtons && (<SocialMediaShare white />)}
              </Col>
            </Row>
          </Container>
        </div>
        <KontentItemImage data={imageData} width={1920} height={660} fixed className="img-fluid" />
      </div>
      {eyecatcher && (
        <Container fluid className="hero-headline__eyecatcher-container">
          {eyecatcher}
        </Container>
      )}
    </div>
  );
};

HeroCampaign.propTypes = {
  children: PropTypes.node.isRequired,
  date: PropTypes.instanceOf(Date),
  disableShareButtons: PropTypes.bool,
  eyecatcher: PropTypes.oneOfType([PropTypes.object]),
  imageData: PropTypes.oneOfType([PropTypes.object]),
};

HeroCampaign.defaultProps = {
  date: null,
  disableShareButtons: false,
  eyecatcher: null,
  imageData: null,
};

export default HeroCampaign;
