import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import DonorArticleEmbed from "./donorArea/donorArticleEmbed";
import DonorTopicEmbed from "./donorArea/donorTopicEmbed";
import KontentItemVideoEmbed from "./kontentItemVideoEmbed";
import LearnArticleEmbed from "./learn/learnArticleEmbed";
import LearnTopicEmbed from "./learn/learnTopicEmbed";
import IconButton from "../components/button/iconButton";
import LinkButton from "../components/button/linkButton";
import LearnModule from "../components/learnEmbedModule/learnEmbedModule";
import { IconFurther18 } from "../icons";

const KontentItemRelatedLearnContentEmbed = ({ data }) => {
  const relatedContent = data.value ? data.value[0] : data;

  if (!relatedContent || !relatedContent.elements) {
    return null;
  }

  return (
    <LearnModule
      headline={relatedContent.elements.headline.value}
      buttonLearnArea={(
        <IconButton
          href={
            relatedContent.elements.button_learn_area.value.length > 0
              ? relatedContent.elements.button_learn_area.value[0].id
              : "#"
          }
          variant="plain-learn-area"
        >
          {relatedContent.elements.button_text.value}
        </IconButton>
      )}
      buttonDonorArea={
        relatedContent.elements.button_text_donor_area.value !== ""
          ? (
            <LinkButton
              href={
                relatedContent.elements.button_donor_area.value.length > 0
                  ? relatedContent.elements.button_donor_area.value[0].id
                  : "#"
              }
            >
              {relatedContent.elements.button_text_donor_area.value}
              <IconFurther18 />
            </LinkButton>
          )
          : null
      }
      subHeadlineDonorArea={relatedContent.elements.sub_headline_donor_area.value}
    >
      {relatedContent.elements.article___video_cards.value.map((item) => {
        if (item.id) {
          switch (item.internal.type) {
            case "kontent_item_video_embed":
              return <KontentItemVideoEmbed key={item.id} data={item} variant="learn-module" ltr noLength />;
            case "kontent_item_article_page_learn":
              return <LearnArticleEmbed data={item} key={item.id} />;
            case "kontent_item_learn_topics_page":
              return <LearnTopicEmbed data={item} key={item.id} />;
            case "kontent_item_donor_area_topic":
              return <DonorTopicEmbed data={item} key={item.id} />;
            case "kontent_item_article_donor_area":
              return <DonorArticleEmbed data={item} key={item.id} />;
            default:
          }
        }

        return null;
      })}
    </LearnModule>
  );
};

export const query = graphql`
  fragment KontentItemRelatedLearnContentEmbed on kontent_item_related_learn_content_embed {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      article___video_cards {
        value {
          internal {
            type
          }
          ...KontentItemVideoEmbed
          ...LearnArticleEmbed
          ...LearnTopicEmbed
          ...DonorTopicEmbed
          ...DonorArticleEmbed
        }
      }
      button_text {
        value
      }
      sub_headline_donor_area {
        value
      }
      button_text_donor_area {
        value
      }
      button_learn_area {
        value {
          id
        }
      }
      button_donor_area {
        value {
          id
        }
      }
    }
  }
`;

KontentItemRelatedLearnContentEmbed.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemRelatedLearnContentEmbed;
