import React from "react";

import classNames from "classnames";
import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";

import ArticleClosure from "../../components/articleClosure/articleClosure";
import LinkButton from "../../components/button/linkButton";
import CardSlider from "../../components/cardSlider/cardSlider";
import HeroCampaign from "../../components/heroHeadline/heroCampaign";
import InfoSection from "../../components/infoSection/infoSection";
import SEO from "../../components/seo";
import { useGetUrnCounterQuery } from "../../features/io/ioEventApiSlice";
import { setTracking } from "../../features/trackingSlice";
import { IconAnchorRight18 } from "../../icons";
import mapKontentItem from "../../utils/mapKontentItem";
import getAuthorByCodename from "../author/getAuthorByCodename";
import EventTileCard from "../events/eventTileCard";
import KontentItemCampaignsSection from "../kontentItemCampaignsSection";
import KontentItemContact from "../kontentItemContact";
import KontentItemIconCardSliderSection from "../kontentItemIconCardSliderSection";
import KontentItemLocationsSection from "../kontentItemLocationsSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import ArticleTeaserCard from "../news/articleTeaserCard";
import RichText from "../text/richText";

const KontentItemArticlePageCampaign = ({ data: { page }, pageContext }) => {
  const dispatch = useDispatch();

  dispatch(
    setTracking({
      URN: page.elements.urn_primary.value,
      secondaryURN: page.elements.urn_secondary.value,
    }),
  );

  const urnCounter = page.elements.urn_primary.value
    ? useGetUrnCounterQuery(page.elements.urn_primary.value)
    : { data: null };

  const counter = urnCounter.data?.value;

  const headerWithBackground = page.elements.related_story.value.length > 0
    || page.elements.related_event.value.length > 0;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  const author = getAuthorByCodename(
    page.elements.author_name.value.length > 0 && page.elements.author_name.value[0].codename,
  );

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_description.value}
        isHiddenForRobots={pageContext.seoNoIndex}
      />

      <SubNavigationGenerator id={page.id} />

      <div className={classNames("container--fullwidth", { "bg-grey-background": headerWithBackground })}>
        <Container fluid>
          <HeroCampaign
            disableShareButtons
            eyecatcher={
              page.elements.hero_eyecatcher.value.length > 0
                ? mapKontentItem(page.elements.hero_eyecatcher.value[0], { counter })
                : null
            }
            date={new Date(page.elements.publishdate.value)}
            imageData={page.elements.hero_image}
          >
            {page.elements.hero_headline.value}
          </HeroCampaign>

          <BreadcrumbGenerator id={page.id} />

          <KontentItemLocationsSection data={page.elements.location_list} />

          {headerWithBackground && (
            <CardSlider
              subHeader={page.elements.title_related.value}
              twoCols
            >
              {page.elements.related_story.value.length > 0
                && ArticleTeaserCard({ data: page.elements.related_story })}
              {page.elements.related_event.value.length > 0
                && EventTileCard({ data: page.elements.related_event })}
            </CardSlider>
          )}
        </Container>
      </div>

      <InfoSection
        hideLastUpdateLabel
        showTableOfContents={page.elements.table_of_content?.value[0]?.codename !== "disable"}
        author={author?.elements?.contact_item
          ? (
            <KontentItemContact
              data={author.elements.contact_item}
              contactHref={(
                <LinkButton
                  variant="normal"
                  className="btn-light-registration"
                  href={author.id}
                >
                  <FormattedMessage id="stories.further_articles" /> <IconAnchorRight18 />
                </LinkButton>
              )}
              isAuthor
            />
          )
          : null}
        date={new Date(page.elements.publishdate.value)}
      />

      <RichText data={page.elements.body} isArticle />

      <Row className="pb-15">
        <Col md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
          <ArticleClosure className="pt-0" />
        </Col>
      </Row>

      <KontentItemCampaignsSection data={page.elements.further_campaigns} />

      <KontentItemIconCardSliderSection data={page.elements.icon_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemArticlePageCampaign(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        related_story {
          value {
            ...ArticleTeaserCard
            ...ArticleTeaserCardMigrated
          }
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        related_event {
          value {
           ...EventTileCard
          }
        }
        hero_headline {
          value
        }
        hero_eyecatcher {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherContact
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherCounter
          }
        }
        further_campaigns {
          value {
            ...KontentItemCampaignsSection
          }
        }
        icon_card_slider {
          value {
            ...KontentItemIconCardSliderSection
          }
        }
        publishdate {
          value(formatString: "YYYY-MM-DD[T]HH:mm:ss")
        }
        title_related {
          value
        }
        urn_primary {
          value
        }
        urn_secondary {
          value
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemCtaButton
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemStatementboxGroup
            ...KontentItemEmbedExternal
          }
        }
        author_name {
          value {
            codename
          }
        }
        location_list {
          value {
            ...KontentItemLocationsSection
          }
        }
        table_of_content{
          value {
            codename
          }
        }
      }
    }
  }
`;

KontentItemArticlePageCampaign.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemArticlePageCampaign;
