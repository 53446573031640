import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import ArticleModule from "../components/articleModule/articleModule";

const KontentItemImageGrid = ({ data, ...extraProps }) => {
  const imageGrid = data.value ? data.value[0] : data;

  if (!imageGrid || !imageGrid.elements) {
    return null;
  }

  return (
    <ArticleModule caption="">
      {imageGrid.elements.images.value.map((image) => (
        <KontentItemImage
          data={image}
          key={image.id}
          width={600}
          fit="crop"
          aspectRatio={1}
          {...extraProps}
        />
      ))}
    </ArticleModule>
  );
};

export const query = graphql`
  fragment KontentItemImageGrid on kontent_item_image_grid {
    id
    system {
      codename
    }
    elements {
      images {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

KontentItemImageGrid.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemImageGrid;
