import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./eventSeriesModule.scss";

const EventSeriesModule = ({ children, headline }) => (
  <div className="event-series-module">
    <Container fluid>
      <Row>
        <Col lg={8}>
          <h3>{headline}</h3>
        </Col>
        {React.Children.map(children, (child) => (
          <Col md={6} className="event-series-module__event-tile">
            {child}
          </Col>
        ))}
      </Row>
    </Container>
  </div>
);

EventSeriesModule.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
};

export default EventSeriesModule;
