import React from "react";

import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

import RegistrationDialog from "./registrationDialog";
import useEventListener from "../../hooks/haiku/useEventListener";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import "./registrationDialogWrapper.scss";

const RegistrationDialogWrapper = (props) => {
  const {
    image,
    content,
    rootsId,
    dialogType,
    steps,
  } = props;

  // Fixes iOS viewport height bug
  // https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
  const setAppHeight = () => {
    if (typeof document === "undefined" || typeof window === "undefined") {
      return;
    }

    document.documentElement.style.setProperty(
      "--app-height",
      `${window.innerHeight}px`,
    );
  };
  useEventListener("resize", setAppHeight);
  setAppHeight();

  const { width } = useWindowDimensions();

  return (
    <div
      className="registration-dialog"
      style={image && width >= 992 ? { backgroundImage: `url(${image})` } : null}
    >
      <div className="registration-dialog__layout">
        <Container fluid className="h-100">
          {steps.length > 0
            ? (
              <RegistrationDialog
                steps={steps}
                content={content}
                rootsId={rootsId}
                dialogType={dialogType}
                image={image}
              />
            )
            : null}
        </Container>
      </div>
    </div>
  );
};

RegistrationDialogWrapper.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]),
  dialogType: PropTypes.oneOf(["virtual-drive-setup", "payment", "registration-dialog"]),
  image: PropTypes.string.isRequired,
  rootsId: PropTypes.string,
  steps: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

RegistrationDialogWrapper.defaultProps = {
  content: {},
  dialogType: "registration-dialog",
  rootsId: null,
};

export default RegistrationDialogWrapper;
