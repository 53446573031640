import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";

import RichText from "../text/richText";

const KontentItemFormRichText = ({ data }) => {
  const { elements } = data;

  return (<Col xs={12}><RichText data={elements.rich_text} /></Col>);
};

export const query = graphql`
  fragment KontentItemFormRichText on kontent_item_form__rich_text {
    id
    elements {
      rich_text {
        value
      }
    }    
  } 
`;

KontentItemFormRichText.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemFormRichText;
