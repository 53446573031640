/* eslint-disable camelcase, sort-keys */
import { useSelector } from "react-redux";

import { REGIONS_CL, sex, whatsappOptions } from "./general-form-data";
import { format } from "../../../utils/dateHelper";
import { getOnPreventKeypress, RegexPatterns } from "../../dynamicForm/validators";
import { clean } from "../formBuilder";
import Validators from "../validations/validators";

const getUpdateAddressForm = (intl) => {
  const validators = new Validators(intl);
  const SEX = sex(intl);
  const WHATSAPP_OPTIONS = whatsappOptions(intl);

  const detailsView = useSelector((state) => state.updateAddress.detailsView);

  const prevCityOptions = [];

  /* eslint-disable sort-keys */
  const poTest = {
    type: "test",
    params: [{
      test(value) {
        const { po_box, po_name } = this.parent;

        if (!(po_box && po_name)) {
          return !!value;
        }

        return true;
      },
      message: intl.formatMessage({ id: "form.required" }),
    }],
  };

  const prevPoTest = {
    type: "test",
    params: [{
      test(value) {
        const {
          prev_po_box,
          prev_po_name,
          address_unchanged,
        } = this.parent;

        if (address_unchanged) {
          return true;
        }

        if (prev_po_box && prev_po_name) {
          return true;
        }

        return !!value;
      },
      message: intl.formatMessage({ id: "form.required" }),
    }],
  };

  const prevAddressPartiallyFilled = {
    type: "test",
    params: [{
      test(value) {
        const {
          prev_house_no,
          prev_region,
          prev_city,
          prev_street,
        } = this.parent;

        return !(!value && (prev_house_no || prev_street || prev_region || prev_city));
      },
      message: intl.formatMessage({ id: "form.required" }),
    }],
  };

  const currentAddressPartiallyFilled = {
    type: "test",
    params: [{
      test(value) {
        const {
          current_house_no,
          current_region,
          current_city,
          current_street,
        } = this.parent;

        return !(!value && (current_house_no || current_street || current_region || current_city));
      },
      message: intl.formatMessage({ id: "form.required" }),
    }],
  };

  const sameEmailtest = {
    type: "test",
    params: [{
      test() {
        const { email, additional_email } = this.parent;

        if (!additional_email) {
          return true;
        }

        return additional_email && email !== additional_email;
      },
      message: intl.formatMessage({ id: "form.error.same_email" }),
    }],
  };

  const samePhoneTest = {
    type: "test",
    params: [{
      name: "samePhoneTest",
      test() {
        const { mobile, landline } = this.parent;

        return !((landline && landline !== "+") && mobile === landline);
      },
      message: intl.formatMessage({ id: "registration.alternate_contact.error.same_phone" }),
    }],
  };

  const transformLandlinePhone = {
    type: "transform",
    params: [(value) => (value === "+56" ? undefined : value)],
  };

  return [
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "personal_info_header",
        headline: "personal_info.title",
        description: "personal_info.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "4",
        singleLine: true,
      },
      tagName: "RadioInput",
      props: {
        id: "sex",
        name: "sex",
        label: intl.formatMessage({ id: "form.salutation" }),
        type: "text",
        isRequired: true,
        title: intl.formatMessage({ id: "form.please_select.label" }),
      },
      options: SEX,
      validation: {
        type: "string",
        validators: [
          ...validators.get(null, true, "form.error.no_gender_selected"),
        ],
      },
      initialValue: "",
    },

    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "firstname",
        name: "firstname",
        label: intl.formatMessage({ id: "registration.personal.firstname" }),
        type: "text",
        maxLength: 40,
        required: true,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("firstname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "lastname",
        name: "lastname",
        label: intl.formatMessage({ id: "registration.personal.lastname" }),
        type: "text",
        maxLength: 100,
        required: true,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "CleaveInput",
      props: {
        id: "rut_run",
        name: "rut_run",
        label: intl.formatMessage({ id: "rut_run" }),
        type: "text",
        required: true,
        maxLength: 12,
        options: {
          blocks: [8, 1],
          delimiter: "-",
          numericOnly: false,
        },
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("rut_run", true),
        ],
      },
      tooltipMessage: "",
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "DateInput",
      props: {
        id: "birthdate",
        name: "birthdate",
        label: intl.formatMessage({ id: "registration.age.birthdate" }),
        type: "date",
        required: true,
        placeholder: "",
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInPast", true),
        ],
      },
      initialValue: "",
      tooltipMessage: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "previous_address_header",
        headline: "previous_address.title",
        description: "previous_address.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "CheckboxInput",
      props: {
        id: "address_unchanged",
        name: "address_unchanged",
        label: intl.formatMessage({ id: "address_unchanged" }),
        type: "checkbox",
        lg: true,
      },
      validation: {
        type: "bool",
        validators: [],
      },
      initialValue: false,
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "FormAddressInput",
      props: {
        id: "prev_address_input",
        name: "prev_address_input",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        fieldType: "prev",
        subFields: [
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "prev_street",
              name: "prev_street",
              label: intl.formatMessage({ id: "registration.address.street" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [prevAddressPartiallyFilled, prevPoTest],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "prev_house_no",
              name: "prev_house_no",
              label: intl.formatMessage({ id: "registration.address.houseNo" }),
              type: "text",
              maxLength: 10,
              required: true,
            },
            validation: {
              type: "string",
              validators: [prevAddressPartiallyFilled, prevPoTest],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "prev_info",
              name: "prev_info",
              label: intl.formatMessage({ id: "registration.address.info" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "prev_postcode",
              name: "prev_postcode",
              label: intl.formatMessage({ id: "registration.address.postCode" }),
              type: "text",
              required: false,
              maxLength: 7,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("zipcode"),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
            },
            tagName: "SelectInput",
            options: REGIONS_CL,
            props: {
              id: "prev_region",
              name: "prev_region",
              label: intl.formatMessage({ id: "registration.address.region" }),
              type: "text",
              maxLength: 100,
              required: true,
              inFormAddressInput: true,
            },
            validation: {
              type: "string",
              validators: [prevAddressPartiallyFilled, prevPoTest],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              style: {
                display: "none",
              },
            },
            tagName: "SelectInput",
            options: prevCityOptions,
            props: {
              id: "prev_city",
              name: "prev_city",
              label: intl.formatMessage({ id: "registration.address.city" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [prevAddressPartiallyFilled, prevPoTest],
            },
            initialValue: "",
          },
        ],
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "current_address_header",
        headline: "current_address.title",
        description: "current_address.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "FormAddressInput",
      props: {
        id: "current_address_input",
        name: "current_address_input",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        fieldType: "current",
        subFields: [
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "current_street",
              name: "current_street",
              label: intl.formatMessage({ id: "registration.address.street" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                poTest,
                currentAddressPartiallyFilled,
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "current_house_no",
              name: "current_house_no",
              label: intl.formatMessage({ id: "registration.address.houseNo" }),
              type: "text",
              maxLength: 10,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                poTest,
                currentAddressPartiallyFilled,
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "current_info",
              name: "current_info",
              label: intl.formatMessage({ id: "registration.address.info" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "current_postcode",
              name: "current_postcode",
              label: intl.formatMessage({ id: "registration.address.postCode" }),
              type: "text",
              required: false,
              maxLength: 7,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("zipcode", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
            },
            tagName: "SelectInput",
            options: REGIONS_CL,
            props: {
              id: "current_region",
              name: "current_region",
              label: intl.formatMessage({ id: "registration.address.region" }),
              type: "text",
              maxLength: 100,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                poTest,
                currentAddressPartiallyFilled,
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              style: {
                display: "none",
              },
            },
            tagName: "SelectInput",
            options: [],
            props: {
              id: "current_city",
              name: "current_city",
              label: intl.formatMessage({ id: "registration.address.city" }),
              type: "text",
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                poTest,
                currentAddressPartiallyFilled,
              ],
            },
            initialValue: "",
          },
        ],
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "other_details_header",
        headline: "other_details.title",
        description: "",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: true,
      },
      tagName: "RadioInput",
      options: WHATSAPP_OPTIONS,
      props: {
        id: "whatsapp",
        name: "whatsapp",
        label: intl.formatMessage({ id: "registration.additional.whatsapp" }),
        type: "radio",
        title: intl.formatMessage({ id: "registration.additional.whatsapp" }),
        isRequired: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get(null, true, "form.error.whatsapp"),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "CleaveInput",
      props: {
        id: "mobile",
        name: "mobile",
        label: intl.formatMessage({ id: "registration.additional.mobileNumber" }),
        type: "text",
        maxLength: 15,
        required: true,
        options: {
          prefix: "+569",
          blocks: [12],
          delimiter: "",
          numericOnly: false,
        },
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("mobile", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "CleaveInput",
      props: {
        id: "landline",
        name: "landline",
        label: intl.formatMessage({ id: "registration.additional.additionalPhoneNumber" }),
        type: "text",
        maxLength: 12,
        required: false,
        options: {
          prefix: "+56",
          blocks: [12],
          delimiter: "",
          numericOnly: false,
        },
      },
      validation: {
        type: "string",
        validators: [
          samePhoneTest,
          transformLandlinePhone,
          ...validators.get("phone", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "email",
        name: "email",
        label: intl.formatMessage({ id: "registration.additional.email" }),
        type: "text",
        maxLength: 100,
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("email", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "additional_email",
        name: "additional_email",
        label: intl.formatMessage({ id: "registration.additional.additional_email" }),
        type: "text",
        maxLength: 100,
        required: false,
      },
      validation: {
        type: "string",
        validators: [
          sameEmailtest,
          ...validators.get("email", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "validity_date_header",
        headline: "validity_date.title",
        description: "validity_date.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: true,
      },
      tagName: "DateInput",
      props: {
        id: "validity_date",
        name: "validity_date",
        label: intl.formatMessage({ id: "validity_date.label" }),
        type: "date",
        required: true,
        placeholder: "",
        value: new Date(),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInFuture", true),
          ...validators.get("dateNotToFarInFuture"),
        ],
      },
      initialValue: format(new Date(), "yyyy-MM-dd"),
      tooltipMessage: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "data_protection_header",
        headline: "dataprotection.title",
        description: "dataprotection.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "CheckboxInput",
      props: {
        id: "privacy",
        name: "privacy",
        label: intl.formatMessage({ id: "registration.dataprotection.dataprotection" }),
        type: "checkbox",
        required: true,
        lg: true,
      },
      validation: {
        type: "bool",
        validators: detailsView
          ? [...validators.get("requiredCheckbox", false)]
          : [],
      },
      initialValue: false,
    },
  ];
};

const generatePayload = (values) => {
  /* eslint-disable sort-keys */
  const payload = {
    person: {
      // donor_id: values.donor_id.toUpperCase(),
      birthdate: values.birthdate,
      rut_run: values.rut_run,
      sex: values.sex,
      firstname: values.firstname,
      lastname: values.lastname,
    },
    former_address: {
      street: values.prev_street,
      houseno: values.prev_house_no,
      info: values.prev_info,
      zipcode: values.prev_postcode,
      region: values.prev_region,
      city: values.prev_city,
    },
    former_po_box: {
      po_box: values.prev_po_box,
      po_name: values.prev_po_name,
    },
    private_address: {
      street: values.current_street,
      houseno: values.current_house_no,
      info: values.current_info,
      zipcode: values.current_postcode,
      region: values.current_region,
      city: values.current_city,
    },
    private_contact: {
      mobile: values.mobile,
      phone: values.landline !== "+56" ? values.landline : "",
      email: values.email,
      email2: values.additional_email,
    },
    po_box: {
      po_box: values.po_box,
      po_name: values.po_name,
    },
    ciu_data: {
      validity_date: values.validity_date,
      whatsapp: values.whatsapp === "yes",
    },
  };

  return clean(payload);
};

export default { getUpdateAddressForm, generatePayload };
