import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemVideoEmbed from "../kontentItemVideoEmbed";

const PressVideoEmbeds = ({ data }) => {
  let videoData = Array.isArray(data) ? data : data.value;

  videoData = videoData.filter((video) => (
    video.elements.press_category.value.length > 0
  ));

  const videoList = [];

  videoData.forEach((video) => {
    videoList.push(
      <KontentItemVideoEmbed data={video} variant="16by9" previewImageSizeByParent />,
    );
  });

  return React.Children.toArray(videoList);
};

export const query = graphql`
  fragment PressVideoEmbed on kontent_item_video_embed {
    id
    system {
      codename
      last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
    }
    internal {
        type
      }
    elements {
      title {
        value
      }
      url {
        value
      }
      duration {
        value
      }
      image {
        value {
          description
          name
          height
          size
          url
          type
          width
        }
      }
      video_component_style {
        value {
          name
          codename
        }
      }
      press_category {
        value {
          name
        }
      }
      description {
        value
      }
    }
  } 
`;

PressVideoEmbeds.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default PressVideoEmbeds;
