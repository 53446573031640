import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import CampaignCounterEyecatcher from "../../components/campaignCounterEyecatcher/campaignCounterEyecatcher";

const KontentItemEyecatcherCounter = ({ data, counter }) => {
  const eyecatcher = data.value ? data.value[0] : data;

  if (!eyecatcher || !eyecatcher.elements) {
    return null;
  }

  return (
    <CampaignCounterEyecatcher
      headline={eyecatcher.elements.title.value}
      description={eyecatcher.elements.text.value}
      registrations={counter || 0}
      maxRegistration={eyecatcher.elements.goal.value}
    />
  );
};

export const query = graphql`
  fragment KontentItemEyecatcherCounter on kontent_item_eyecatcher_counter {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      text {
        value
      }
      goal {
        value
      }
    }
  }
`;

KontentItemEyecatcherCounter.propTypes = {
  counter: PropTypes.number.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEyecatcherCounter;
