import React from "react";

import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";

import StateAwareAccordionToggle from "../stateAwareAccordionToggle/stateAwareAccordionToggle";

import "./collapseRow.scss";

const CollapseRow = (props) => {
  const {
    children,
    titleAs,
    headline,
    id,
  } = props;

  const TitleTag = ["h2", "h3", "h4", "h5", "p"].includes(titleAs) ? titleAs : "div";

  return (
    <Accordion className="collapse-row">
      <div className="collapse-row__item">
        <StateAwareAccordionToggle eventKey={id} className="collapse-row__header">
          <TitleTag>{headline}</TitleTag>
        </StateAwareAccordionToggle>
        <Accordion.Collapse eventKey={id}>
          <>{children}</>
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};

CollapseRow.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  titleAs: PropTypes.oneOf(["h2", "h3", "h4", "h5", "p"]),
};

CollapseRow.defaultProps = {
  titleAs: null,
};

export default CollapseRow;
