import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Consent from "../../components/consent/consent";
import SocialMediaEmbed from "../../components/socialMediaEmbed/socialMediaEmbed";

const KontentItemEmbedFacebookPost = ({ data }) => {
  const facebookPost = data.value ? data.value[0] : data;

  if (
    !facebookPost
    || !facebookPost.elements
    || !facebookPost.elements.display_options.value.length > 0
  ) {
    return null;
  }

  return (
    <Consent type="social">
      <SocialMediaEmbed
        url={facebookPost.elements.post_url.value}
        showText={facebookPost.elements.display_options.value[0].codename}
      />
    </Consent>
  );
};

export const query = graphql`
  fragment KontentItemEmbedFacebookPost on kontent_item_embed_facebook_post {
    id
    elements {
      post_url {
        value
      }
      display_options {
        value {
          name
          codename
        }
      }
    }
  }
`;

KontentItemEmbedFacebookPost.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEmbedFacebookPost;
