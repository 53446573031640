import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { object } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconError18, IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import StateAwareAccordionToggle from "../../stateAwareAccordionToggle/stateAwareAccordionToggle";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationAncestryUs = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const getCount = (group) => {
    if (typeof group !== "object") {
      return null;
    }

    const count = Object.values(group).filter((el) => ((el[0] === "on") ? el : null)).length;

    return count > 0 ? ` (${count})` : null;
  };

  const isOtherOn = (group) => {
    let isSelected = false;

    if (group) {
      Object.entries(group).forEach((s) => {
        if (s[1].length > 0 && s[1][0] === "on" && s[0].includes("_OTHER")) {
          isSelected = true;
        }
      });
    }

    return isSelected;
  };

  const validationSchema = useMemo(() => object().shape({
    ancestry: object()
      .test("test-ancestry", intl.formatMessage({ id: "form.required" }),
        (value) => {
          let valid = false;
          const selected = Object.entries(value);
          selected.forEach((select) => {
            if (!valid && select.length > 1) {
              Object.entries(select[1]).forEach((s) => {
                if (s[1].length > 0 && s[1][0] === "on") {
                  valid = true;
                }
              });
            }
          });

          return valid;
        }),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.ancestry.title"
        subheadline="registration.ancestry.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          const { ancestryOther } = values;

          if (values.ancestryOther) {
            const selected = Object.entries(values.ancestry);
            selected.forEach((select) => {
              if (select.length > 1) {
                let selectionString = "";
                Object.entries(select[1]).forEach((s) => {
                  if (s[1].length > 0 && s[1][0] === "on" && s[0]) {
                    selectionString += s[0];
                  }
                });

                if (!selectionString.includes("_OTHER")) {
                  delete ancestryOther[select[0]];
                }
              }
            });
          }

          DataLayer.pushEvent("pp_registration_step_ancestry", { event_value: "success" });

          dispatch(
            setRegistrationData({
              ancestry: values.ancestry,
              ancestryOther,
            }),
          );

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            {RegistrationDialogStepRegistrationAncestryUs.ancestryList
              .map((group, groupId) => (
                <Accordion key={group.value} className="accordion-ancestry">
                  <StateAwareAccordionToggle
                    eventKey={group.value}
                  >
                    <div className="typo-h4">
                      {group.label}
                      {getCount(values.ancestry[group.value])}
                    </div>
                  </StateAwareAccordionToggle>
                  <Accordion.Collapse eventKey={group.value}>
                    <>
                      <div className="accordion-ancestry__group">
                        {group.items.map((item, itemId) => (
                          <Form.Check
                            key={item.value}
                            type="checkbox"
                            id={`opt_${groupId}_${itemId}`}
                            label={item.label}
                            name={`ancestry[${group.value}][${item.value}]`}
                            checked={
                              values.ancestry[group.value]
                              && values.ancestry[group.value][item.value]
                                ? values.ancestry[group.value][item.value][0] === "on"
                                : false
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        ))}
                      </div>
                      {isOtherOn(values.ancestry[group.value]) && (
                        <Form.Group>
                          <Form.Control
                            type="text"
                            placeholder={intl.formatMessage({ id: "registration.ancestry.please_specify" })}
                            name={`ancestryOther[${group.value}]`}
                            value={values.ancestryOther[group.value]}
                            onChange={handleChange}
                            onBlur={(event) => {
                              handleBlur(event);
                              const { name } = event.target;
                              setFieldValue(name, values?.ancestryOther?.[group.value]?.trim() || "");
                            }}
                            isInvalid={(errors.ancestryOther && touched.ancestryOther)}
                            maxLength={30}
                            className="mt-18"
                          />
                          {errors.ancestryOther && touched.ancestryOther && (
                            <Form.Text className="invalid-feedback">
                              <IconError18 />{errors.ancestryOther}
                            </Form.Text>
                          )}
                        </Form.Group>
                      )}
                    </>
                  </Accordion.Collapse>
                </Accordion>
              ))}

            <ScrollToFieldError />

            <Form.Group>
              <IconButton type="submit" disabled={isSubmitting}>
                <FormattedMessage id="registration.ancestry.button_next" />
                <AdaptiveIcon
                  sm={<IconFurther18 />}
                  lg={<IconFurther24 />}
                />
              </IconButton>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationAncestryUs.ancestryList = [
  {
    items: [
      {
        label: "Northern European",
        value: "NORTHERN_EUROPEAN",
      },
      {
        label: "Eastern European",
        value: "EASTERN_EUROPEAN",
      },
      {
        label: "Western European",
        value: "WESTERN_EUROPEAN",
      },
      {
        label: "North Coast of Africa",
        value: "NORTH_COAST_AFRICA",
      },
      {
        label: "Mediterranean",
        value: "MEDITERRANEAN",
      },
      {
        label: "Middle Eastern",
        value: "MIDDLE_EASTERN",
      },
      {
        label: "North American",
        value: "NORTH_AMERICAN",
      },
      {
        label: "White South or Central American",
        value: "WHITE_SOUTH_AMERICAN",
      },
      {
        label: "White Caribbean",
        value: "WHITE_CARIBBEAN",
      },
      {
        label: "Other",
        value: "WHITE_OTHER",
      },
    ],
    label: "White or Caucasian",
    value: "white",
  },
  {
    items: [
      {
        label: "Chinese",
        value: "CHINESE",
      },
      {
        label: "Filipino",
        value: "FILIPINO",
      },
      {
        label: "Japanese",
        value: "JAPANESE",
      },
      {
        label: "Korean",
        value: "KOREAN",
      },
      {
        label: "Vietnamese",
        value: "VIETNAMESE",
      },
      {
        label: "South Asian",
        value: "SOUTH_ASIAN",
      },
      {
        label: "Other",
        value: "ASIAN_OTHER",
      },
    ],
    label: "Asian",
    value: "asian",
  },
  {
    items: [
      {
        label: "African",
        value: "AFRICAN",
      },
      {
        label: "African American",
        value: "AFRICAN_AMERICAN",
      },
      {
        label: "Black Caribbean",
        value: "BLACK_CARIBBEAN",
      },
      {
        label: "Black South or Central American",
        value: "BLACK_SOUTH_AMERICAN",
      },
      {
        label: "Other",
        value: "BLACK_OTHER",
      },
    ],
    label: "Black or African Descent",
    value: "black",
  },
  {
    items: [
      {
        label: "Alaska Native or Aleut",
        value: "ALASKA",
      },
      {
        label: "Native North American",
        value: "NORTH_AMERICAN_INDIAN",
      },
      {
        label: "Native South or Central American",
        value: "AMERICAN_INDIAN_SOUTH",
      },
      {
        label: "Caribbean Indian",
        value: "CARIBBEAN_INDIAN",
      },
      {
        label: "Other",
        value: "NATIVE_OTHER",
      },
    ],
    label: "Native American or Alaska Native",
    value: "native",
  },
  {
    items: [
      {
        label: "Guamanian",
        value: "GUAMANIAN",
      },
      {
        label: "Hawaiian",
        value: "HAWAIIAN",
      },
      {
        label: "Samoan",
        value: "SAMOAN",
      },
      {
        label: "Other",
        value: "PACIFIC_OTHER",
      },
    ],
    label: "Native Hawaiian or other Pacific Islander",
    value: "pacific",
  },
  {
    items: [
      {
        label: "Mexican",
        value: "LATINO_MEXICAN",
      },
      {
        label: "Puerto Rican",
        value: "LATINO_PUERTORICAN",
      },
      {
        label: "Cuban",
        value: "LATINO_CUBAN",
      },
      {
        label: "Other",
        value: "LATINO_OTHER",
      },
    ],
    label: "Hispanic, Latino or Spanish",
    value: "latino",
  },
];

RegistrationDialogStepRegistrationAncestryUs.getFlatObjectOfAncestries = () => {
  const list = {};
  RegistrationDialogStepRegistrationAncestryUs.ancestryList.forEach((group) => {
    group.items.forEach((s) => {
      list[s.value] = s.label;
    });
  });

  return list;
};

RegistrationDialogStepRegistrationAncestryUs.stepName = "registration_ancestry";

RegistrationDialogStepRegistrationAncestryUs.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationAncestryUs;
