import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import LocationCard from "../components/locationCard/locationCard";
import getInternalLinkById from "../utils/getInternalLinkById";

const KontentItemLocationCard = ({ data }) => {
  const locationCard = data.value ? data.value[0] : data;

  if (!locationCard || !locationCard.elements) {
    return null;
  }

  return (
    <LocationCard
      title={locationCard.elements.title.value}
      text={locationCard.elements.description.value}
      streetAddress={locationCard.elements.address.value}
      city={`${locationCard.elements.postal_code.value} ${locationCard.elements.city.value}`}
      fax={locationCard.elements.button_fax.value}
      phone={locationCard.elements.button_phone.value}
      email={locationCard.elements.button_mailto.value}
      externalLink={locationCard.elements.button_url_ext.value}
      internalLink={getInternalLinkById(
        locationCard.elements.button_url_internal?.value[0]?.id,
      )?.slug}
      internalLinkText={locationCard.elements.button_url_internal_text?.value}
    />
  );
};

export const query = graphql`
  fragment KontentItemLocationCard on kontent_item_location_card {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      postal_code {
        value
      }
      country {
        value
      }
      description {
        value
      }
      coordinates {
        value
      }
      city {
        value
      }
      button_url_ext {
        value
      }
      button_phone {
        value
      }
      button_mailto {
        value
      }
      button_fax {
        value
      }
      address {
        value
      }
      button_url_internal {
        value {
          id
        }
      }
      button_url_internal_text {
        value
      }
    }
  }
`;

KontentItemLocationCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLocationCard;
