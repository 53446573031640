import React from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconFurther18, IconFurther24, IconTopic18 } from "../../icons";
import AdaptiveIcon from "../adaptiveIcon";
import IconButton from "../button/iconButton";
import IconText from "../iconText/iconText";
import NavigationLink from "../navigationLink/navigationLink";

import "./topicTile.scss";

const TopicTile = (props) => {
  const {
    articleCount,
    headline,
    description,
    image,
    variant,
    href,
  } = props;

  const intl = useIntl();

  return (
    <NavigationLink href={href} className={classNames("topic-tile no-style", variant && `topic-tile--${variant}`)}>
      <div>
        <div className="d-flex typo-body2">
          <IconText
            icon={<IconTopic18 />}
            variant="learn-area"
            text={intl.formatMessage({ id: "topic_tile.topic" })}
          />
          <FormattedMessage id="article_header.article_count" values={{ articleCount: parseInt(articleCount, 10) }} />
        </div>
        <div className="typo-title2">{headline}</div>
        <div className="topic-tile___description typo-body1">{description}</div>
      </div>
      <div className="d-flex align-content-end justify-content-between">
        <IconButton iconOnly className="btn-inverted-learn-area align-self-end" aria-label={headline}>
          <AdaptiveIcon
            sm={<IconFurther18 />}
            lg={<IconFurther24 />}
          />
        </IconButton>
        {image}
      </div>
    </NavigationLink>
  );
};

TopicTile.propTypes = {
  articleCount: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  variant: PropTypes.oneOf(["border"]),
};

TopicTile.defaultProps = {
  variant: null,
};

export default TopicTile;
