import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import LinkButton from "../../components/button/linkButton";
import CardSlider from "../../components/cardSlider/cardSlider";
import ElementHeader from "../../components/elementHeader/elementHeader";
import Initiator from "../../components/initiator/initiator";
import SEO from "../../components/seo";
import SocialMediaShare from "../../components/socialMediaShare/socialMediaShare";
import TextWithSidebar from "../../components/textWithSidebar/textWithSidebar";
import VirtualDriveCounterHero from "../../components/virtualDriveCounterHero/virtualDriveCounterHero";
import { useGetUrnCounterQuery } from "../../features/io/ioEventApiSlice";
import { setTracking } from "../../features/trackingSlice";
import {
  IconExternalLink24,
  IconFacebookOriginalColored18,
  IconFurther24,
  IconInstagramOriginalColored18,
  IconTiktokOriginalColored18,
  IconTwitterOriginalColored18,
  IconWebsite18,
  IconYoutubeOriginalColored18,
} from "../../icons";
import mapKontentItem from "../../utils/mapKontentItem";
import EventTileCard from "../events/eventTileCard";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemContact from "../kontentItemContact";
import KontentItemImage from "../kontentItemImage";
import KontentItemImageCardSliderSection from "../kontentItemImageCardSliderSection";
import KontentItemRelatedLearnContentEmbed from "../kontentItemRelatedLearnContentEmbed";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";
import SimpleRichText from "../text/simpleRichText";

const KontentItemVirtualDrive = ({ data: { page, setup }, pageContext }) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const urnCounter = page.elements.urn_primary.value
    ? useGetUrnCounterQuery(page.elements.urn_primary.value)
    : { data: null };

  const counter = urnCounter.data?.value;
  const links = [];
  let registrationUrl = null;

  const virtualDriveSetup = setup && setup.nodes.length > 0
    ? setup.nodes[0]
    : null;

  if (virtualDriveSetup && virtualDriveSetup.elements.button_register_url.value.length > 0) {
    registrationUrl = virtualDriveSetup.elements.button_register_url.value[0].id;
  }

  const privateDrive = page.elements.vd_type.value.length > 0
    && page.elements.vd_type.value[0].codename === "private";

  if (page.elements.website.value.length > 0) {
    links.push(
      <LinkButton className="vd-social-media" key="1" href={page.elements.website.value} variant="normal">
        <IconWebsite18 className="text-primary" />{page.elements.label_website.value || <FormattedMessage id="virtual_drive.visit_website_profile" />}
      </LinkButton>,
    );
  }

  if (page.elements.facebook.value.length > 0) {
    links.push(
      <LinkButton className="vd-social-media" key="2" href={page.elements.facebook.value} variant="normal">
        <IconFacebookOriginalColored18 />{page.elements.label_facebook.value || <FormattedMessage id="virtual_drive.visit_facebook_profile" />}
      </LinkButton>,
    );
  }

  if (page.elements.instagram.value.length > 0) {
    links.push(
      <LinkButton className="vd-social-media" key="3" href={page.elements.instagram.value} variant="normal">
        <IconInstagramOriginalColored18 />{page.elements.label_instagram.value || <FormattedMessage id="virtual_drive.visit_instagram_profile" />}
      </LinkButton>,
    );
  }

  if (page.elements.twitter.value.length > 0) {
    links.push(
      <LinkButton className="vd-social-media" key="4" href={page.elements.twitter.value} variant="normal">
        <IconTwitterOriginalColored18 />{page.elements.label_twitter.value || <FormattedMessage id="virtual_drive.visit_twitter_profile" />}
      </LinkButton>,
    );
  }

  if (page.elements.tiktok.value.length > 0) {
    links.push(
      <LinkButton className="vd-social-media" key="5" href={page.elements.tiktok.value} variant="normal">
        <IconTiktokOriginalColored18 />{page.elements.label_tiktok.value || <FormattedMessage id="virtual_drive.visit_tiktok_profile" />}
      </LinkButton>,
    );
  }

  if (page.elements.youtube.value.length > 0) {
    links.push(
      <LinkButton className="vd-social-media" key="6" href={page.elements.youtube.value} variant="normal">
        <IconYoutubeOriginalColored18 />{page.elements.label_youtube.value || <FormattedMessage id="virtual_drive.visit_youtube_profile" />}
      </LinkButton>,
    );
  }

  dispatch(
    setTracking({
      URN: page.elements.urn_primary.value,
      secondaryURN: page.elements.urn_secondary.value,
    }),
  );

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  const blurImage = page.elements.image_object_fit_contain.value.length > 0 && page.elements.image_object_fit_contain.value[0].codename === "true";

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_description.value}
        isHiddenForRobots={pageContext.seoNoIndex}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <VirtualDriveCounterHero
        registrations={counter}
        maxRegistration={page.elements.goal.value}
        headline={page.elements.hero_headline.value}
        image={(
          <KontentItemImage
            data={page.elements.hero_image}
            imageOnlyWidth
            width={1320}
            fit={blurImage ? "clip" : "crop"}
            aspectRatio={4 / 3}
            placeholderTyp="virtual-drive"
          />
        )}
        blurImage={blurImage}
        privateDrive={privateDrive}
        registerUrl={registrationUrl}
      />

      <TextWithSidebar
        text={<RichText data={page.elements.body} isSidebarLayout />}
        leadText={(
          <ElementHeader
            headline={page.elements.story_headline.value}
            description={page.elements.lead_text.value}
            isLeadText
          />
        )}
        initiatorHeadline={intl.formatMessage({ id: "virtual_drive.about_initiator" })}
        initiator={
          (page.elements.name_first.value || page.elements.name_last.value
            || page.elements.name_alias.value
            || page.elements.name_company.value
            || page.elements.name_university.value) ? (
              <Initiator
                firstName={page.elements.name_first.value}
                lastName={page.elements.name_last.value}
                alias={
                  page.elements.name_alias.value
                  || page.elements.name_company.value
                  || page.elements.name_university.value
                }
                image={page.elements.image_initiator.value.length > 0
                  ? (
                    <KontentAssetsImage
                      data={page.elements.image_initiator.value[0]}
                      height={300}
                      aspectRatio={1}
                      fit="crop"
                      className="rounded-circle"
                    />
                  )
                  : null}
              />
            )
            : null
        }
        contactHeadline={page.elements.contact_person.value.length > 0
          ? intl.formatMessage({ id: "virtual_drive.dkms_contact_person" })
          : null}
        contact={page.elements.contact_person.value.length > 0
          ? <KontentItemContact data={page.elements.contact_person} />
          : null}
        donationAccountHeadline={page.elements.donation_account.value !== "<p><br></p>"
          ? intl.formatMessage({ id: "virtual_drive.donation_account" })
          : null}
        donationAccount={page.elements.donation_account.value.length > 0
          ? <SimpleRichText data={page.elements.donation_account} />
          : null}
        linksAndAccountHeadline={links.length > 0
          ? intl.formatMessage({ id: "virtual_drive.links_and_accounts" })
          : null}
        linksAndAccount={links.length > 0
          ? links
          : null}
        pressAreaHeadline={page.elements.press_link_text.value
          ? intl.formatMessage({ id: "virtual_drive.press_area" })
          : null}
        pressArea={page.elements.press_link_text.value
        && (page.elements.press_url.value.length > 0 || page.elements.press_url_extern.value)
          ? (
            <LinkButton
              className="link-button__press-area"
              variant="normal"
              href={page.elements.press_url.value.length > 0
                ? page.elements.press_url.value[0].id
                : page.elements.press_url_extern.value}
            >
              {page.elements.press_link_text.value}
              {page.elements.press_url.value.length > 0
                ? <IconFurther24 />
                : <IconExternalLink24 />}
            </LinkButton>
          )
          : null}
      />

      {!privateDrive && (
        <div>
          <SocialMediaShare copyUrl />
        </div>
      )}

      {page.elements.eyecatcher_registration.value.length > 0
        ? mapKontentItem(page.elements.eyecatcher_registration.value[0])
        : null}

      <CardSlider
        subHeader={page.elements.title_related.value}
        twoCols
      >
        {EventTileCard({ data: page.elements.related_event })}
      </CardSlider>

      <KontentItemRelatedLearnContentEmbed data={page.elements.related_learn_material} />

      <KontentItemImageCardSliderSection data={page.elements.image_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    setup: allKontentItemVdSetup(filter: {system: {workflow_step: {ne: "archived"}}}) {
      nodes {
        id
        elements {
          button_register_url {
            value {
              id
            }
          }
        }
      }
    }
    page: kontentItemVirtualDrive(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        image_initiator {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        name_alias {
          value
        }
        name_company {
          value
        }
        name_first {
          value
        }
        name_last {
          value
        }
        name_university {
          value
        }
        vd_type {
          value {
            codename
          }
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemCtaButton
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemEmbedExternal
          }
        }
        eyecatcher_registration {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcher
          }
        }
        image_card_slider {
          value  {
            ...KontentItemImageCardSliderSection
          }
        }
        lead_text {
          value
        }
        urn_primary {
          value
        }
        urn_secondary {
          value
        }
        goal {
          value
        }
        contact_person {
          value {
            ...KontentItemContact
          }
        }
        youtube {
          value
        }
        website {
          value
        }
        twitter {
          value
        }
        instagram {
          value
        }
        facebook {
          value
        }
        tiktok {
          value
        }
        urn_primary {
          value
        }
        story_headline {
          value
        }
        related_learn_material {
          value {
            ...KontentItemRelatedLearnContentEmbed
          }
        }
        donation_account {
          value
          links {
            link_id
            codename
            type
            url_slug
          }
        }
        press_link_text {
          value
        }
        press_url {
          value {
            id
          }
        }
        press_url_extern {
          value
        }
        label_tiktok {
          value
        }
        label_twitter {
          value
        }
        label_website {
          value
        }
        label_youtube {
          value
        }
        label_instagram {
          value
        }
        label_facebook {
          value
        }
        image_object_fit_contain {
          value {
            codename
          }
        }
        related_event {
          value {
           ...EventTileCard
          }
        }
        title_related {
          value
        }
      }
    }
  }
`;

KontentItemVirtualDrive.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemVirtualDrive;
