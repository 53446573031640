import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { IconCooperation30, IconDualtoneNewbadge48 } from "../../icons";
import BlurredOverlay from "../blurredOverlay/blurredOverlay";
import NavigationLink from "../navigationLink/navigationLink";

import "./virtualDriveTile.scss";

const VirtualDriveTile = (props) => {
  const {
    headline,
    description,
    image,
    highlight,
    href,
    registrations,
    maxRegistration,
    initiator,
    showCounter,
    blurImage,
  } = props;

  const { width } = useWindowDimensions();
  const percantageRegistration = (100 / maxRegistration) * registrations;

  let highlightImage = image;

  let colPropsContent = {
    xs: 12,
  };

  let colPropsImage = {
    xs: 12,
  };

  if (width >= 576 && width < 768) {
    highlightImage = React.cloneElement(image, { aspectRatio: 16 / 9 });
  }

  if (highlight && width >= 768) {
    highlightImage = React.cloneElement(
      image,
      {
        aspectRatio: 4 / 3,
        height: 338,
        width: 630,
      },
    );

    colPropsContent = {
      md: 7,
      xl: 6,
    };

    colPropsImage = {
      md: 5,
      xl: 6,
    };
  }

  return (
    <NavigationLink
      href={href}
      className={classNames(
        "virtual-drive-tile no-style",
        { "virtual-drive-tile--highlight": highlight && width >= 768 },
      )}
    >
      <Row noGutters>
        <Col {...colPropsImage} className="virtual-drive-tile__image-container">
          <div className="virtual-drive-tile__image">
            {blurImage && <BlurredOverlay image={highlightImage} />}
            {highlightImage}
          </div>
        </Col>
        <Col
          {...colPropsContent}
          className={classNames(
            "virtual-drive-tile__container",
            { "without-limit": !maxRegistration },
          )}
        >
          <div
            className={classNames(
              "virtual-drive-tile__content",
              { "virtual-drive-tile__content--without-counter": (!showCounter && !registrations) },
            )}
          >
            <div className="virtual-drive-tile__headline">
              {headline}
            </div>
            {highlight && (
              <div className="virtual-drive-tile__description">
                {description}
              </div>
            )}
            <div className="d-flex">
              {showCounter && registrations !== null && (
                <div>
                  <div className="virtual-drive-tile__registrations">
                    {highlight ? (
                      <>
                        {registrations > 0
                          ? <IconCooperation30 className="primary" />
                          : <IconDualtoneNewbadge48 />}
                      </>
                    ) : (
                      <>
                        {(width < 992 || width >= 1200) && (
                          <>
                            {registrations > 0
                              ? <IconCooperation30 className="primary" />
                              : <IconDualtoneNewbadge48 />}
                          </>
                        )}
                      </>
                    )}
                    <div className="typo-title3">
                      {registrations > 0
                        ? <>{registrations} {maxRegistration !== null && [`/ ${maxRegistration}`]}</>
                        : <FormattedMessage id="virtual_drive.counter.new" />}
                    </div>
                  </div>
                  {registrations > 0 && (
                    <div className="virtual-drive-tile__registrations-subtext">
                      <FormattedMessage id="virtual_drive.registrations" />
                    </div>
                  )}
                </div>
              )}
              {initiator}
            </div>
          </div>
          {maxRegistration !== null && (
            <div className="virtual-drive-tile__progress-bar">
              <div
                className="virtual-drive-tile__registered"
                style={{ width: `${percantageRegistration}%` }}
              />
              <div
                className="virtual-drive-tile__remaining-capacity"
                style={{ width: `${100 - percantageRegistration}%` }}
              />
            </div>
          )}
        </Col>
      </Row>
    </NavigationLink>
  );
};

VirtualDriveTile.propTypes = {
  blurImage: PropTypes.bool,
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
  href: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  initiator: PropTypes.oneOfType([PropTypes.object]).isRequired,
  maxRegistration: PropTypes.number,
  registrations: PropTypes.number.isRequired,
  showCounter: PropTypes.bool,
};

VirtualDriveTile.defaultProps = {
  blurImage: false,
  highlight: false,
  maxRegistration: null,
  showCounter: true,
};

export default VirtualDriveTile;
