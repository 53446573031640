import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Select, { components } from "react-select";

import { IconClearInput18, IconDropdownExpand18, IconError18 } from "../../icons";

import "./inputField.scss";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <IconDropdownExpand18 />
  </components.DropdownIndicator>
);

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <IconClearInput18 />
  </components.ClearIndicator>
);

/**
 * Only used in donationBox
 * @deprecated
 */
const InputField = (props) => {
  const {
    append,
    clearable,
    disabled,
    dropdownId,
    dropdownPlaceholder,
    errorMessage,
    id,
    isInvalid,
    label,
    options,
    placeholder,
    prepend,
    required,
    type,
    value,
    variant,
    onChangeSelect,
    formikInput,
    formikSelect,
    onReset,
  } = props;

  const dropdown = useRef(null);
  const inputField = useRef(null);
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(false);
  const [focus, setFocus] = useState(false);
  const [menuStyle, setMenuStyle] = useState(false);

  if (options) {
    useEffect(() => {
      if (dropdown) {
        dropdown.current.select.controlRef.addEventListener("mouseenter", () => {
          setHover(true);
        });

        dropdown.current.select.controlRef.addEventListener("mouseleave", () => {
          setHover(false);
        });
      }
    }, [dropdown]);
  }

  useEffect(() => {
    if (!dropdown.current || !inputField.current) {
      return;
    }

    const tmpMenuStyle = {};
    const clientRectInputField = inputField.current.getBoundingClientRect();
    const clientRectDropdown = dropdown?.current?.select.controlRef.getBoundingClientRect();

    tmpMenuStyle.width = `${clientRectInputField.width}px`;
    tmpMenuStyle.marginLeft = `${clientRectInputField.left - clientRectDropdown.left}px`;
    setMenuStyle(tmpMenuStyle);
  }, [inputField, dropdown, prepend, append]);

  const getOptionByValue = (items, optionValue) => items.find((item) => item.value === optionValue);

  return (
    <Form.Group
      className={classNames(
        "input-field",
        {
          "input-field--append": append,
          "input-field--disabled": disabled,
          "input-field--invalid": isInvalid,
          "input-field--prepend": prepend,
          required,
          [`input-field-${variant}`]: variant,
        },
      )}
      ref={inputField}
    >
      <InputGroup
        className={classNames(
          {
            append,
            prepend,
            "select-container--is-active": active,
            "select-container--is-focus": focus,
            "select-container--is-hover": hover,
          },
        )}
      >
        <Form.Control
          id={id}
          type={type}
          value={value}
          placeholder={placeholder}
          isInvalid={isInvalid}
          readOnly={disabled}
          className={options ? "border-right-0" : ""}
          {...formikInput}
        />

        {options && (
          <Select
            ref={dropdown}
            placeholder={dropdownPlaceholder}
            options={options}
            className={classNames(
              "select-container",
              "flex-grow-1",
              {
                "is-invalid": isInvalid,
                "select-container--active": active,
                "select-container--focus": focus,
                "select-container--hover": hover,
                "select-container--invalid": isInvalid,
                "select-container--placeholder-shown": typeof tmpValue === "undefined",
              },
            )}
            inputId={dropdownId}
            classNamePrefix="select"
            maxMenuHeight={300}
            isDisabled={disabled}
            isClearable={clearable}
            components={{ ClearIndicator, DropdownIndicator }}
            menuPosition="absolute"
            menuPlacement="auto"
            isSearchable={false}
            captureMenuScroll={false}
            defaultValue={getOptionByValue(options, formikSelect?.value)}
            onChange={(event) => {
              onChangeSelect(event ? event.value : null);
              setFocus(false);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            onMenuOpen={() => {
              setActive(true);
            }}
            onMenuClose={() => {
              setActive(false);
            }}
            onFocus={() => {
              setFocus(true);
            }}
            styles={{
              menu: (style) => ({
                ...style,
                ...menuStyle,
              }),
            }}
          />
        )}
        {prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text>
              {prepend}
            </InputGroup.Text>
          </InputGroup.Prepend>
        )}
        {append && (
          <InputGroup.Append>
            <InputGroup.Text>
              {clearable && !options && (
                <IconClearInput18
                  onClick={() => {
                    if (!disabled) {
                      onReset();
                    }
                  }}
                />
              )}
              {append}
            </InputGroup.Text>
          </InputGroup.Append>
        )}
        <Form.Label>
          {label}
        </Form.Label>
      </InputGroup>

      {isInvalid && (
        <Form.Text className="invalid-feedback">
          <IconError18 />{errorMessage}
        </Form.Text>
      )}
    </Form.Group>
  );
};

InputField.propTypes = {
  append: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  dropdownId: PropTypes.string,
  dropdownPlaceholder: PropTypes.string,
  errorMessage: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  fieldNameSelect: PropTypes.string,
  formikInput: PropTypes.shape({
    checked: PropTypes.bool,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  formikSelect: PropTypes.shape({
    checked: PropTypes.bool,
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  id: PropTypes.string,
  isInvalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onChangeSelect: PropTypes.func,
  onReset: PropTypes.func,
  onlyDropdown: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  placeholder: PropTypes.string,
  prepend: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
};

InputField.defaultProps = {
  append: null,
  clearable: false,
  disabled: false,
  dropdownId: "",
  dropdownPlaceholder: "",
  errorMessage: null,
  fieldNameSelect: null,
  id: "",
  isInvalid: false,
  onChange: () => {},
  onChangeSelect: () => {},
  onReset: () => {},
  onlyDropdown: false,
  options: null,
  placeholder: "",
  prepend: null,
  required: false,
  type: "text",
  value: "",
  variant: null,
};

export default InputField;
