import React from "react";

import classNames from "classnames";
import { Field } from "formik";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { IconError18 } from "../../../icons";
import RichText from "../../../templates/text/richText";

import "./checkbox.scss";

const Checkbox = (props) => {
  const {
    id,
    className,
    checked,
    label,
    data,
    errors,
    small,
    touched,
    onChange,
    onBlur,
    value,
    required,
  } = props;

  return (
    <Field
      name={id}
    >
      {() => (
        <Form.Group
          className={classNames(
            "checkbox-form",
            className,
            {
              "checkbox-small": small,
              required,
            },
          )}
        >
          <Form.Check
            id={id}
            name={id}
            type="checkbox"
            onChange={onChange}
            onBlur={onBlur}
            className={classNames("mr-9", { "is-invalid": errors[id] && touched[id] })}
            isInvalid={errors[id] && touched[id]}
            label={data ? <RichText data={data} openLinksInNewTab /> : label}
            feedback={(<><IconError18 />{errors[id]}</>)}
            value={value}
            checked={checked ? "checked" : undefined}
          />
        </Form.Group>
      )}
    </Field>
  );
};

Checkbox.defaultProps = {
  checked: false,
  className: "",
  data: null,
  label: "No label supplied",
  required: false,
  small: false,
  value: false,
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]),
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  small: PropTypes.bool,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  value: PropTypes.bool,
};

export default Checkbox;
