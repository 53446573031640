import React, { useMemo } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import FormObserver from "../../../utils/formik/formObserver";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationAdditionalInformationDe = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const emailMessage = intl.formatMessage({ id: "registration.additional.error.email" });

  const warning = useSelector((state) => state.registration.warning);

  let warningInfo;

  if (Object.keys(warning).length > 0) {
    warningInfo = RegistrationDataHelper.generateWarning(warning);
  }

  const validationSchema = useMemo(() => object().shape({
    additionalPhoneNumber: string()
      .matches(
        /^[\d\s\-+/]{5,20}$/,
        intl.formatMessage({ id: "form.error.phone_pattern" }),
      ),
    emailAddress: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage),
    mobileNumber: string()
      .matches(
        /^[\d\s\-+/]{5,20}$/,
        intl.formatMessage({ id: "form.error.phone_pattern" }),
      ),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.additional.title"
        subheadline="registration.additional.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          const {
            mobileNumber,
            additionalPhoneNumber,
          } = values;

          if (mobileNumber === "" && additionalPhoneNumber === "") {
            errors.mobileNumber = intl.formatMessage({ id: "form.error.one_phone_number" });
            errors.additionalPhoneNumber = intl.formatMessage({ id: "form.error.one_phone_number" });
          } else if (mobileNumber && additionalPhoneNumber
            && mobileNumber === additionalPhoneNumber) {
            errors.mobileNumber = intl.formatMessage({ id: "form.error.same_phone.mobile" });
            errors.additionalPhoneNumber = intl.formatMessage({ id: "form.error.same_phone.landline" });
          }

          return errors;
        }}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              additionalPhoneNumber: values.additionalPhoneNumber,
              emailAddress: values.emailAddress,
              mobileNumber: values.mobileNumber,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_additional_information", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormObserver />
            <Row>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="mobileNumber"
                  label={intl.formatMessage({ id: "registration.additional.mobileNumber" })}
                  type="text"
                  warning={warning}
                  maxLength={20}
                  required
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="additionalPhoneNumber"
                  label={intl.formatMessage({ id: "registration.additional.additionalPhoneNumber" })}
                  type="text"
                  warning={warning}
                  maxLength={20}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={(event) => {
                    setFieldTouched("additionalPhoneNumber", true);
                    setFieldTouched("mobileNumber", true);
                    handleChange(event);
                  }}
                  onBlur={(event) => {
                    setFieldTouched("additionalPhoneNumber", true);
                    setFieldTouched("mobileNumber", true);
                    handleBlur(event);
                  }}
                  id="emailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email" })}
                  type="email"
                  maxLength={100}
                  required
                  touched={setFieldTouched}
                  errors={errors}
                  values={values}
                />
              </Col>

              <Form.Check
                checked={values.whatsApp === "yes"}
                inline
                className={classNames("mb-0", errors.whatsApp && touched.whatsApp ? "is-invalid" : "")}
              />

              {Object.keys(warning).length > 0 && (
                <Col xs={12} className="warning-info">
                  {warningInfo}
                </Col>
              )}

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.additional.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationAdditionalInformationDe.stepName = "registration_additional_information";

RegistrationDialogStepRegistrationAdditionalInformationDe.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationAdditionalInformationDe;
