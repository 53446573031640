import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./initiator.scss";

const Initiator = (props) => {
  const {
    firstName,
    lastName,
    image,
    alias,
  } = props;

  return (
    <div className="initiator">
      {(image !== null || firstName.length > 0 || lastName.length > 0) && (
        <div className={classNames("initiator__image", { "without-image": image === null })}>
          {image !== null
            ? image
            : (
              <div className="initiator__initials">
                {firstName.charAt(0)}
                {lastName.charAt(0)}
              </div>
            )}
        </div>
      )}
      <div className="initiator__name">{alias}</div>
    </div>
  );
};

Initiator.propTypes = {
  alias: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  lastName: PropTypes.string.isRequired,
};

Initiator.defaultProps = {
  image: null,
};

export default Initiator;
