import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KeyFactArea from "../../components/keyFacts/keyFactArea";
import KeyFactCard from "../../components/keyFacts/keyFactCard";
import backgroundImageHelper from "../image/backgroundImageHelper";
import KontentAssetsImage from "../kontentAssetsImage";

const KontentItemKeyFactAreaMoneyDonation = ({ data }) => {
  const keyFactAreaMoneyDonation = data.value ? data.value[0] : data;

  if (!keyFactAreaMoneyDonation || !keyFactAreaMoneyDonation.elements) {
    return null;
  }

  return (
    <KeyFactArea
      title={keyFactAreaMoneyDonation.elements.title.value}
      className="keyfactarea--donate-money"
    >
      <KeyFactCard
        title={keyFactAreaMoneyDonation.elements.title_card1.value}
        className="keyfactcard--background"
        backgroundImageStyle={backgroundImageHelper(keyFactAreaMoneyDonation.elements.image_card1)}
        text={keyFactAreaMoneyDonation.elements.text_card1.value}
      />
      <KeyFactCard
        title={keyFactAreaMoneyDonation.elements.title_card2.value}
        className="keyfactcard--donate-money"
        image={
          keyFactAreaMoneyDonation.elements.image_card2.value.length > 0
            ? (
              <KontentAssetsImage
                data={keyFactAreaMoneyDonation.elements.image_card2.value[0]}
                height={96}
              />
            )
            : null
        }
        text={keyFactAreaMoneyDonation.elements.text_card2.value}
      />
      <KeyFactCard
        title={keyFactAreaMoneyDonation.elements.title_card3.value}
        className="keyfactcard--donate-money"
        image={
          keyFactAreaMoneyDonation.elements.image_card3.value.length > 0
            ? (
              <KontentAssetsImage
                data={keyFactAreaMoneyDonation.elements.image_card3.value[0]}
                height={96}
              />
            )
            : null
        }
        text={keyFactAreaMoneyDonation.elements.text.value}
      />
    </KeyFactArea>
  );
};

export const query = graphql`
  fragment KontentItemKeyFactAreaMoneyDonation on kontent_item_key_fact_area_money_donation {
    id
    elements {
      title {
        value
      }
      image_card1 {
        value {
          ...KontentItemImage
        }
      }
      image_card2 {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
      image_card3 {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
      text {
        value
      }
      text_card1 {
        value
      }
      text_card2 {
        value
      }
      title_card1 {
        value
      }
      title_card2 {
        value
      }
      title_card3 {
        value
      }
    }
  } 
`;

KontentItemKeyFactAreaMoneyDonation.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemKeyFactAreaMoneyDonation;
