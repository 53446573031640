import React from "react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import Button from "../../components/button/button";
import { setShowComplianceSettings } from "../../features/complianceConsentSlice";

const CustomTag = ({ method, options }) => {
  const dispatch = useDispatch();

  const MISSING_OPTION_MESSAGE = "### MISSING OPTION ###";
  const MISSING_ELEMENT_MESSAGE = "### MISSING ELEMENT ###";

  switch (method) {
    case "consentLink":
      if (!options.alt || !options.text) {
        return MISSING_OPTION_MESSAGE;
      }

      return (
        <Button
          variant="direct-link"
          type="button"
          onClick={() => {
            dispatch(
              setShowComplianceSettings(true),
            );
          }}
          title={options.alt}
        >
          {options.text}
        </Button>
      );
    case "anchorID":
      if (!options.id) {
        return MISSING_OPTION_MESSAGE;
      }

      return (
        <div id={options.id} />
      );
    default:
      return MISSING_ELEMENT_MESSAGE;
  }
};

CustomTag.propTypes = {
  method: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.object]),
};

CustomTag.defaultProps = {
  method: null,
  options: {},
};

export default CustomTag;
