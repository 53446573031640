import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import { IconCalendar24, IconLocation24 } from "../../icons";
import { formatInTimeZone } from "../../utils/dateHelper";
import downloadICS from "../../utils/iCalHelper";
import BlurredOverlay from "../blurredOverlay/blurredOverlay";
import SocialMediaShare from "../socialMediaShare/socialMediaShare";
import Tag from "../tag";

import "./eventHeader.scss";

const EventHeader = (props) => {
  const {
    headline,
    category,
    location,
    date,
    endDate,
    timezone,
    image,
    joinButton,
    eventUid,
    blurImage,
  } = props;

  const isLowerMd = useMediaQuery("(max-width: 767px)");
  const isHigherEqualSm = useMediaQuery("(min-width: 576px)");

  const tmpImage19to9 = image ? React.cloneElement(image, { aspectRatio: 19 / 9, ...(blurImage ? { fit: "contain" } : {}) }) : null;
  const tmpImage4to3 = image ? React.cloneElement(image, { aspectRatio: 4 / 3, ...(blurImage ? { fit: "contain" } : {}) }) : null;

  return (
    <Row className="event-header">
      {image && (
        <Col md={{ offset: 1, span: 4 }} className="event-header__image-container">
          <div className="overflow-hidden position-relative">
            {isHigherEqualSm && isLowerMd
              ? (
                <>
                  {blurImage && (
                    <BlurredOverlay image={tmpImage19to9} />
                  )}
                  {tmpImage19to9}
                </>
              )
              : (
                <>
                  {blurImage && (
                    <BlurredOverlay image={tmpImage4to3} />
                  )}
                  {tmpImage4to3}
                </>
              )}
          </div>
        </Col>
      )}
      <Col md={7} className="order-md-first">
        <Tag variant="event">{category}</Tag>
        <h1>{headline}</h1>
        {location !== null && (
          <div className="event-header__location">
            <IconLocation24 />
            <span className="typo-h4">{location}</span>
          </div>
        )}
        <div className="event-header__date">
          <IconCalendar24 />

          <div className="d-flex flex-wrap">
            <div className="event-header__start-date">
              <div className="typo-h4">{formatInTimeZone(date, "P", timezone)}</div>
              <div className="event-header__time">
                {formatInTimeZone(date, "p (z)", timezone)}
              </div>
            </div>
            {endDate && (
              <>
                <div className="event-header__date-divider">–</div>
                <div className="event-header__end-date">
                  <div className="typo-h4">{formatInTimeZone(endDate, "P", timezone)}</div>
                  <div className="event-header__time">
                    {formatInTimeZone(endDate, "p (z)", timezone)}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="event-header__button-container">
          {joinButton}
          <Button
            variant="inverted-events"
            onClick={() => {
              downloadICS(date, endDate, headline, eventUid);
            }}
          >
            <FormattedMessage id="event.add_calendar" />
          </Button>
        </div>
      </Col>
      <Col xs={12}>
        <SocialMediaShare />
      </Col>
    </Row>
  );
};

EventHeader.propTypes = {
  blurImage: PropTypes.bool,
  category: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date),
  eventUid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  headline: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  joinButton: PropTypes.oneOfType([PropTypes.object]),
  location: PropTypes.string,
  timezone: PropTypes.string.isRequired,
};

EventHeader.defaultProps = {
  blurImage: false,
  endDate: null,
  image: null,
  joinButton: null,
  location: null,
};

export default EventHeader;
