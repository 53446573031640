import React, { forwardRef } from "react";

import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import NavigationLink from "../navigationLink/navigationLink";

import "./headerButtons.scss";

const HeaderButtons = forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    {
      kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          button_registration_text {
            value
          }
          button_registration_url {
            value {
              id
            }
          }
          button_donation_text {
            value
          }
          button_donation_url {
            value {
              id
            }
          }
          button_donation_external_url {
            value
          }
        }
      }
    }
  `);

  const {
    registrationButtonProps,
    donationButtonProps,
    className,
  } = props;

  if (!data && !data.kontentItemSiteSettings) {
    return null;
  }

  const settings = data.kontentItemSiteSettings;

  return (
    <div className={classNames("header-buttons", className)} ref={ref}>
      {
        settings.elements.button_registration_url.value.length > 0
        && settings.elements.button_registration_text.value !== ""
          ? (
            <NavigationLink
              {...registrationButtonProps}
              href={settings.elements.button_registration_url.value[0].id}
              activeClassName={null}
            >
              {settings.elements.button_registration_text.value}
            </NavigationLink>
          )
          : null
      }
      {
        (settings.elements.button_donation_url.value.length > 0
          || settings.elements.button_donation_external_url.value)
        && settings.elements.button_donation_text.value !== ""
          ? (
            <NavigationLink
              {...donationButtonProps}
              href={settings.elements.button_donation_url.value.length > 0
                ? settings.elements.button_donation_url.value[0].id
                : settings.elements.button_donation_external_url.value}
              activeClassName={null}
            >
              {settings.elements.button_donation_text.value}
            </NavigationLink>
          )
          : null
      }
    </div>
  );
});

HeaderButtons.displayName = "HeaderButtons";

HeaderButtons.propTypes = {
  className: PropTypes.string,
  donationButtonProps: PropTypes.oneOfType([PropTypes.object]),
  registrationButtonProps: PropTypes.oneOfType([PropTypes.object]),
};

HeaderButtons.defaultProps = {
  className: "",
  donationButtonProps: {
    className: "btn btn-sm btn-inverted-donation",
  },
  registrationButtonProps: {
    className: "btn btn-sm btn-registration",
  },
};

export default HeaderButtons;
