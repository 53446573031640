import React, { memo } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";
import PropTypes from "prop-types";

import useLanguage from "../hooks/useLanguage";

const Logo = (props) => {
  const {
    country,
    breakpoint,
    white,
  } = props;

  const { countryCode } = useLanguage();
  let logoLanguage = country || countryCode;
  const color = white ? "-white" : "";

  switch (logoLanguage) {
    case "us":
    case "gb":
    case "za":
      logoLanguage = "en";
      break;
    case "cl":
      logoLanguage = "es";
      break;
    default:
  }

  const data = useStaticQuery(graphql`
    {
      bigLogo: allFile(filter: {ext: {eq: ".png"}, name: {regex: "/a-logo-dkms.*-l/"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 180, height: 40, quality: 100)
            }
            name
          }
        }
      }
      smallLogo: allFile(filter: {ext: {eq: ".png"}, name: {regex: "/a-logo-dkms-notagline.*-s/"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 180, height: 24, quality: 100)
            }
            name
          }
        }
      }
    }
  `);

  let image = null;
  let smallLogo = null;

  /* eslint-disable sort-keys */
  const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    ul: 1500,
    hd: 1920,
  };

  data.bigLogo.edges.map((value) => {
    if (value.node.name === `a-logo-dkms-${logoLanguage}${color}-l`) {
      image = value.node;
    }

    return undefined;
  });

  data.smallLogo.edges.map((value) => {
    if (
      (logoLanguage !== "in" && value.node.name === `a-logo-dkms-notagline${color}-s`)
      || value.node.name === `a-logo-dkms-notagline-${logoLanguage}${color}-s`
    ) {
      smallLogo = value.node;
    }

    return undefined;
  });

  if (image && smallLogo) {
    // todo: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#withartdirection

    const images = withArtDirection(getImage(image.childImageSharp.gatsbyImageData), [
      {
        media: `(max-width: ${breakpoints[breakpoint] - 1}px)`,
        image: getImage(smallLogo.childImageSharp.gatsbyImageData),
      },
    ]);

    return (
      <GatsbyImage
        image={images}
        className="logo"
        alt="DKMS"
        objectFit="contain"
      />
    );
  }

  return null;
};

Logo.propTypes = {
  country: PropTypes.string,
  breakpoint: PropTypes.oneOf(["xs", "sm", "md", "lg"]).isRequired,
  white: PropTypes.bool,
};

Logo.defaultProps = {
  country: null,
  white: false,
};

export default memo(Logo);
