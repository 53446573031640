import React, { useEffect } from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { setIsSepa } from "../../../features/registrationSlice";
import DataLayer from "../../../utils/dataLayer";
import NavigationLink from "../../navigationLink/navigationLink";

const PaymentDialogStepSuccessSepa = ({ contactLink }) => {
  const paymentData = useSelector((state) => state.registration.paymentData);
  const secondaryUrn = useSelector((state) => state.tracking.secondaryURN);
  const urn = useSelector((state) => state.tracking.URN || state.tracking.urlURN);
  const dispatch = useDispatch();
  const intervalMapping = {
    de: {
      HALFYEARLY: 6,
      MONTHLY: 1,
      ONCE: "onetime",
      QUARTERLY: 3,
      YEARLY: 12,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      DataLayer.pushEvent("pp_donation_step_payment_final", { event_value: "sepa", payment_amount: parseInt(paymentData.amount, 10) });

      dispatch(setIsSepa(false));
      window.location.href = process.env.GATSBY_WEBFORMS_DONATION_URL
        .replace("{amount}", paymentData.amount)
        .replace("{frequency}", intervalMapping[process.env.GATSBY_SITE][paymentData.interval] || "onetime")
        + (urn ? `&urn=${encodeURI(urn)}` : "")
        + (secondaryUrn ? `&urnSecondary=${encodeURI(secondaryUrn)}` : "");
    }, 1000);
  }, []);

  return (
    <Row className="align-self-center align-content-center h-100">
      <Col xs={12} lg={{ offset: 1, span: 10 }} className="text-center">
        <Spinner animation="border" variant="dark" />
        <h3 className="mt-24">
          <FormattedMessage id="payment_dialog.submit.title" />
        </h3>
        <p className="text-black">
          <FormattedMessage
            id="payment_dialog.submit.text"
            values={{
              contact: (
                <NavigationLink href={contactLink} target="_blank">
                  <FormattedMessage id="payment_dialog.submit.text_contacttext" />
                </NavigationLink>
              ),
              link: (
                <NavigationLink
                  href={process.env.GATSBY_WEBFORMS_DONATION_URL
                    .replace("{amount}", paymentData.amount)
                    .replace("{frequency}", intervalMapping[process.env.GATSBY_SITE][paymentData.interval] || "onetime")}
                  target="_blank"
                >
                  <FormattedMessage id="payment_dialog.submit.text_linktext" />
                </NavigationLink>
              ),
            }}
          />
        </p>
      </Col>
    </Row>
  );
};

PaymentDialogStepSuccessSepa.propTypes = {
  contactLink: PropTypes.string.isRequired,
};

export default PaymentDialogStepSuccessSepa;
