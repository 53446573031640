/* eslint-disable sort-keys */
import { useSelector } from "react-redux";

import { getOnPreventKeypress, RegexPatterns } from "../../dynamicForm/validators";
import { clean } from "../formBuilder";
import Validators from "../validations/validators";

const getUpdateMailAddressForm = (intl) => {
  const validators = new Validators(intl);

  const detailsView = useSelector((state) => state.updateAddress.detailsView);

  /* eslint-disable sort-keys */
  return [
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "personal_info_header",
        headline: "update_mail.personal_info.title",
        description: "update_mail.personal_info.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "donor_id",
        name: "donor_id",
        label: intl.formatMessage({ id: "donor_id" }),
        type: "text",
        required: true,
        maxLength: 20,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("donor_id", true),
        ],
      },
      tooltipMessage: intl.formatMessage({ id: "update_mail_tooltip.donor_id" }),
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "DateInput",
      props: {
        id: "birthdate",
        name: "birthdate",
        label: intl.formatMessage({ id: "registration.age.birthdate" }),
        type: "date",
        required: true,
        placeholder: "",
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInPast", true),
        ],
      },
      initialValue: "",
      tooltipMessage: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "firstname",
        name: "firstname",
        label: intl.formatMessage({ id: "registration.personal.firstname" }),
        type: "text",
        required: true,
        maxLength: 40,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
        warningUnderSection: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("firstname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "lastname",
        name: "lastname",
        label: intl.formatMessage({ id: "registration.personal.lastname" }),
        type: "text",
        required: true,
        maxLength: 100,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
        warningUnderSection: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "mail_address_header",
        headline: "update_mail.mail_address.title",
        description: "update_mail.mail_address.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "TextInput",
      props: {
        id: "email",
        name: "email",
        label: intl.formatMessage({ id: "registration.additional.email" }),
        type: "text",
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("email", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "data_protection_header",
        headline: "update_mail.dataprotection.title",
        description: "update_mail.dataprotection.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "CheckboxInput",
      props: {
        id: "privacy",
        name: "privacy",
        label: intl.formatMessage({ id: "registration.dataprotection.dataprotection" }),
        type: "checkbox",
        lg: true,
        required: detailsView,
      },
      validation: {
        type: "bool",
        validators: detailsView
          ? [...validators.get("requiredCheckbox", false)]
          : [],
      },
      initialValue: false,
    },
  ];
};

const generatePayload = (values) => {
  /* eslint-disable sort-keys */
  const payload = {
    firstname: values.firstname,
    latname: values.lastname,
    birthdate: values.birthdate,
    email: values.email,
  };

  return clean(payload);
};

export default { getUpdateMailAddressForm, generatePayload };
