import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import getPaymentDialogSteps from "../../components/paymentDialog/paymentDialogSteps";
import RegistrationDialogWrapper from "../../components/registrationDialog/registrationDialogWrapper";
import SEO from "../../components/seo";
import getInternalLinkById from "../../utils/getInternalLinkById";
import KontentItemModalView from "../kontenItemModalView";
import KontentItemContact from "../kontentItemContact";
import SimpleRichText from "../text/simpleRichText";

const KontentItemPaymentDialog = ({ data }) => {
  const { page } = data;

  const image = data.page.elements.background_image.value;

  const truncateSteps = useSelector(((state) => state.registration.truncateSteps));

  return (
    <>
      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <RegistrationDialogWrapper
        steps={getPaymentDialogSteps(truncateSteps)}
        dialogType="payment"
        image={
          image.length > 0
            ? `${image[0].url}?w=1920&h=1080&fm=pjpeg&auto=format`
            : null
        }
        content={
          {
            amounts: [
              page.elements.amount_1.value,
              page.elements.amount_2.value,
              page.elements.amount_3.value,
            ],
            buttonExitText: page.elements.button_text_exit.value,
            buttonExitUrl: page.elements.button_url_exit.value.length > 0
              ? page.elements.button_url_exit.value[0].id
              : null,
            buttonTextContinue: page.elements.button_text_continue.value,
            contactLink:
              page.elements.contact_page.value.length > 0
                ? page.elements.contact_page.value[0].id
                : null,
            contactPerson: <KontentItemContact data={page.elements.contact_person} variant="money-donation" />,
            contactPersonRecurringDonations: (
              <KontentItemContact
                data={page.elements.contact_person_recurring_donations}
                variant="money-donation"
              />
            ),
            debitGuarentee: (
              <KontentItemModalView
                data={page.elements.direct_debit_guarantee_modal}
                removePostIcon
              />
            ),
            declaration: (
              <KontentItemModalView
                data={page.elements.legal_declaration_in}
                removePostIcon
              />
            ),
            defaultAmount: page.elements.amount_default.value,
            paymentProviderOnetime: page.elements.payment_provider_onetime.value,
            paymentProviderRecurrant: page.elements.payment_provider_recurrant.value,
            privacyPolicyLink: page.elements.url_privacy_policy.value.length > 0
              ? page.elements.url_privacy_policy.value[0].id
              : null,
            revocation: page.elements.text_revocation.value.length > 0
              ? <SimpleRichText data={page.elements.text_revocation} />
              : null,
            simplified_donation_receipt: page.elements.simplified_donation_receipt.value.length > 0
              ? {
                name: page.elements.simplified_donation_receipt.value[0].name,
                url: page.elements.simplified_donation_receipt.value[0].url,
              }
              : null,
            successPage: page.elements.success_page.value.length > 0
              ? getInternalLinkById(page.elements.success_page.value[0].id)?.slug
              : null,
            termsAndConditionsLink: page.elements.url_terms_and_conditions.value.length > 0
              ? page.elements.url_terms_and_conditions.value[0].id
              : null,
            textLeaveIntent: page.elements.text_leave_intent.value,
          }
        }
      />
    </>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemPaymentDialogue(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        background_image {
          value {
            url
            type
          }
        }
        button_text_continue {
          value
        }
        button_text_exit {
          value
        }
        button_url_exit {
          value {
            id
          }
        }
        contact_page {
          value {
            id
          }
        }
        text_leave_intent {
          value
        }
        simplified_donation_receipt {
          value {
            url
            name
          }
        }
        contact_person {
          value {
            ...KontentItemContact
          }
        }
        contact_person_recurring_donations {
          value {
            ...KontentItemContact
          }
        }
        amount_1 {
          value
        }
        amount_2 {
          value
        }
        amount_3 {
          value
        }
        amount_default {
          value
        }
        success_page {
          value {
            id
          }
        }
        text_revocation {
          value
          links {
            link_id
            codename
            type
            url_slug
          }
        }
        url_privacy_policy {
          value {
            id
          }
        }
        url_terms_and_conditions {
          value {
            id
          }
        }
        payment_provider_onetime {
          value {
            url
            description
            height
            name
            size
            type
            width
          }
        }
        payment_provider_recurrant {
          value {
            url
            description
            height
            name
            size
            type
            width
          }
        }
        direct_debit_guarantee_modal {
          value {
            ...KontentItemModalView
          }
        }
        legal_declaration_in {
          value {
            ...KontentItemModalView
          }
        }   
      }
    }
  }
`;

KontentItemPaymentDialog.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemPaymentDialog;
