import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setSetupData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../../registrationDialog/registrationDataHelper";

const VirtualDriveSetupModalPersonalInformation = ({ next }) => {
  const setupData = useSelector((state) => state.registration.setupData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });
  const emailMessage = intl.formatMessage({ id: "registration.additional.error.email" });

  const phoneRegexCountries = {
    cl: [15, /^[\d\s\-+/]{9,15}$/],
    de: [20, /^[\d\s\-+/]{5,20}$/],
    gb: [20, /^[\d\s\-+/]{7,20}$/],
    in: [25, /^[\d\s\-+/]{5,25}$/],
    pl: [12, /^[\d+\s]{9,12}$/],
    us: [12, /^[\d\-/]{10,12}$/],
    za: [20, /^[\d\s\-+/]{5,20}$/],
  };

  const validationSchema = useMemo(() => object().shape({
    email: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage)
      .required(requiredMessage),
    firstname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 40,
        },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    lastname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    phone: string()
      .required(requiredMessage)
      .matches(phoneRegexCountries[process.env.GATSBY_SITE][1], intl.formatMessage({ id: "form.error.phone_pattern" })),
  }), []);

  return (
    <>
      <Row>
        <Col sm={10}>
          <h3>
            <FormattedMessage id="virtual_drive_setup.personal_information_title" />
          </h3>
        </Col>
      </Row>
      <Formik
        initialValues={setupData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setSetupData({
              email: values.email,
              firstname: values.firstname,
              lastname: values.lastname,
              phone: values.phone,
            }),
          );

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="firstname"
                  label={intl.formatMessage({ id: "virtual_drive_setup.personal_information_firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="lastname"
                  label={intl.formatMessage({ id: "virtual_drive_setup.personal_information_lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="email"
                  label={intl.formatMessage({ id: "virtual_drive_setup.personal_information_email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="phone"
                  label={intl.formatMessage({ id: "virtual_drive_setup.personal_information_phone" })}
                  type="tel"
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="virtual_drive_setup.next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

VirtualDriveSetupModalPersonalInformation.stepName = "virtual_drive_personal_information";

VirtualDriveSetupModalPersonalInformation.propTypes = {
  next: PropTypes.func.isRequired,
};

export default VirtualDriveSetupModalPersonalInformation;
