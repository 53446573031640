import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import RelatedNewsCards from "./news/relatedNewsCards";
import CardSlider from "../components/cardSlider/cardSlider";

const KontentItemRelatedNewsSection = ({ data, pageId }) => {
  const relatedNewsSection = data.value ? data.value[0] : data;

  if (!relatedNewsSection || !relatedNewsSection.elements) {
    return null;
  }

  const categories = [];

  relatedNewsSection.elements.news_category.value.forEach((category) => {
    categories.push(category.codename);
  });

  return (
    <CardSlider
      header={relatedNewsSection.elements.title.value}
      related
    >
      {RelatedNewsCards({ categories, excludeId: pageId })}
    </CardSlider>
  );
};

export const query = graphql`
  fragment KontentItemRelatedNewsSection on kontent_item_related_news_section {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      news_category {
        value {
          codename
        }
      }
    }
  } 
`;

KontentItemRelatedNewsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageId: PropTypes.string,
};

KontentItemRelatedNewsSection.defaultProps = {
  pageId: null,
};

export default KontentItemRelatedNewsSection;
