import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import filterByCategory from "./utils";
import MegaMenuResult from "../../components/megaMenuResult/megaMenuResult";
import KontentItemImage from "../kontentItemImage";

const MegaMenuResultsNews = (props) => {
  const {
    item,
  } = props;

  const data = useStaticQuery(graphql`
    {
      allKontentItemArticlePageNews (sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          id
          elements {
            hero_image {
              value {
                ...KontentItemImage
              }
            }
            headline {
              value
            }
            news_category {
              value {
                codename
              }
            }
            publishdate {
              value
            }
          }
        }
      }
    }
  `);

  if (!data) return null;

  const filteredData = filterByCategory(
    data.allKontentItemArticlePageNews.nodes,
    "news_category",
    item.categories,
  );

  const slicedData = filteredData.slice(0, 4);

  return slicedData.map((e) => (
    <MegaMenuResult
      key={e.id}
      href={e.id}
      image={(
        <KontentItemImage
          data={e.elements.hero_image}
          width={48}
          height={48}
          aspectRatio={1}
          fit="crop"
          defaultImage
        />
      )}
    >
      {e.elements.headline.value}
    </MegaMenuResult>
  ));
};

MegaMenuResultsNews.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]),
};

MegaMenuResultsNews.defaultProps = {
  item: null,
};

export default MegaMenuResultsNews;
