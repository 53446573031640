import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import SocialMediaShare from "../socialMediaShare/socialMediaShare";

import "./articleClosure.scss";

const ArticleClosure = ({ disableShareButtons, className }) => (
  <div className={classNames("article-closure", className)}>
    {!disableShareButtons && (<SocialMediaShare />)}
    <div className="article-closure__divider" />
  </div>
);

ArticleClosure.propTypes = {
  className: PropTypes.string,
  disableShareButtons: PropTypes.bool,
};

ArticleClosure.defaultProps = {
  className: undefined,
  disableShareButtons: false,
};

export default ArticleClosure;
