import { useIntl } from "gatsby-plugin-react-intl";

import useLanguage from "../hooks/useLanguage";

const convertBytes = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (!bytes || bytes === 0) {
    return "n/a";
  }

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }

  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

const currency = (amount) => {
  const {
    countryCode,
  } = useLanguage();

  const intl = useIntl();

  const formatted = intl.formatNumber(parseFloat(amount), {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  switch (countryCode) {
    case "us":
    case "cl":
      return `${formatted}$`;
    case "de":
      return `${formatted}€`;
    case "gb":
      return `£${formatted}`;
    case "pl":
      return `${formatted}zł`;
    case "in":
      return `${formatted}₹`;
    case "za":
      return `${formatted}R`;
    default:
      return formatted;
  }
};

export { convertBytes, currency };
