import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import IconCards from "./iconCard/iconCards";
import CardSlider from "../components/cardSlider/cardSlider";

const KontentItemIconCardSliderSection = ({ data }) => {
  const section = data.value ? data.value[0] : data;

  if (!section || !section.elements) {
    return null;
  }

  return (
    <CardSlider
      header={section.elements.title.value}
      description={section.elements.description.value}
      equalCardHeights
      moreUrl={section.elements.button_url.value.length > 0
        ? section.elements.button_url.value[0].id
        : null}
    >
      {IconCards({ data: section.elements.icon_cards.value })}
    </CardSlider>
  );
};

export const query = graphql`
  fragment KontentItemIconCardSliderSection on kontent_item_icon_card_slider_section {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      description {
        value
      }
      button_url {
        value {
          id
        }
      }
      icon_cards {
        value {
          ...IconCard
        }
      }
    }
  }
`;

KontentItemIconCardSliderSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemIconCardSliderSection;
