import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { IconMatch30 } from "../../icons";

import "./leaveFormPopup.scss";

const LeaveFormPopup = (props) => {
  const {
    children,
    isVirtualDriveSetup,
    headline,
    icon,
  } = props;

  return (
    <Row className="justify-content-center">
      <Col md={8} xl={6}>
        <div className="leave-form-popup">
          {icon || <IconMatch30 className="text-primary" />}
          <h3>
            {headline || (
              <FormattedMessage
                id={isVirtualDriveSetup ? "virtual_drive_setup.popup.message" : "registration.popup.message"}
              />
            )}
          </h3>
          {children}
        </div>
      </Col>
    </Row>
  );
};

LeaveFormPopup.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object]),
  isVirtualDriveSetup: PropTypes.bool,
};

LeaveFormPopup.defaultProps = {
  headline: null,
  icon: null,
  isVirtualDriveSetup: false,
};

export default LeaveFormPopup;
