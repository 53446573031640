import React from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { IconDirectory18, IconFurther18 } from "../../icons";
import IconButton from "../button/iconButton";
import IconText from "../iconText/iconText";

import "./highlightTopicTile.scss";

const HighlightTopicTile = (props) => {
  const {
    articleCount,
    headline,
    description,
    children,
    image,
    url,
  } = props;

  const intl = useIntl();

  return (
    <Row className="highlight-topic-tile">
      <Col xs={12} sm={8} md={7} xl={5} className="highlight-topic-tile__content-container">
        <div className="highlight-topic-tile__topic-container">
          <IconText
            icon={<IconDirectory18 />}
            variant="learn-area"
            text={intl.formatMessage({ id: "highlight_topic_tile.topic" })}
          />
          <div className="highlight-topic-tile__article">
            <FormattedMessage id="article_header.article_count" values={{ articleCount: parseInt(articleCount, 10) }} />
          </div>
        </div>
        <div className="highlight-topic-tile__headline">
          {headline}
        </div>
        <div className="highlight-topic-tile__description">
          {description}
        </div>
        <div className="highlight-topic-tile__button">
          <IconButton variant="inverted-learn-area" href={url}>
            <FormattedMessage id="highlight_topic_tile.view_topic" /><IconFurther18 />
          </IconButton>
        </div>
      </Col>
      <Col
        xs={{ offset: 8, span: 4 }}
        sm={{ offset: 0, span: 4 }}
        md={5}
        xl={4}
        className="highlight-topic-tile__image order-first order-sm-0"
      >
        {image}
      </Col>
      <Col xs={12} className="highlight-topic-tile__popular-articles">
        <div className="highlight-topic-tile__popular-articles-text">
          <FormattedMessage id="highlight_topic_tile.popular_articles_text" />
        </div>
        <Row className="highlight-topic-tile__articles">
          {React.Children.toArray(children).map(
            (child, index) => (
              <Col
                xs={12}
                md={6}
                xl={4}
                key={`highlight-topic-tile__article--${index.toString()}`}
                className={classNames("highlight-topic-tile__article-item")}
              >
                {child}
              </Col>
            ),
          )}
        </Row>
      </Col>
    </Row>
  );
};

HighlightTopicTile.propTypes = {
  articleCount: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  children: PropTypes.node,
  description: PropTypes.string,
  headline: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  url: PropTypes.string.isRequired,
};

HighlightTopicTile.defaultProps = {
  children: null,
  description: null,
  image: null,
};

export default HighlightTopicTile;
