import {
  PaymentDialogStepAmountAndInterval,
  PaymentDialogStepBankAccountUk,
  PaymentDialogStepContactInformationIn,
  PaymentDialogStepGiftAid,
  PaymentDialogStepKeepInTouch,
  PaymentDialogStepOverview,
  PaymentDialogStepOverviewIn,
  PaymentDialogStepOverviewPl,
  PaymentDialogStepOverviewUk,
  PaymentDialogStepPersonalInformation,
  PaymentDialogStepPersonalInformationIn,
  PaymentDialogStepPersonalInformationPl,
  PaymentDialogStepPersonalInformationUk,
  PaymentDialogStepSuccess,
} from "./steps";

const getPaymentDialogSteps = (truncateSteps = null) => {
  let steps = [];

  switch (process.env.GATSBY_SITE) {
    case "gb":
      steps = [
        PaymentDialogStepAmountAndInterval,
        PaymentDialogStepGiftAid,
        PaymentDialogStepPersonalInformationUk,
        PaymentDialogStepBankAccountUk,
        PaymentDialogStepKeepInTouch,
        PaymentDialogStepOverviewUk,
        PaymentDialogStepSuccess,
      ];

      if (truncateSteps) {
        steps.splice(3, 1);
      }
      break;
    case "de":
      steps = [
        PaymentDialogStepAmountAndInterval,
        PaymentDialogStepPersonalInformation,
        PaymentDialogStepOverview,
        PaymentDialogStepSuccess,
      ];
      break;
    case "us":
      steps = [];
      break;
    case "pl":
      steps = [
        PaymentDialogStepAmountAndInterval,
        PaymentDialogStepPersonalInformationPl,
        PaymentDialogStepOverviewPl,
        PaymentDialogStepSuccess,
      ];
      break;
    case "cl":
      steps = [];
      break;
    case "in":
      steps = [
        PaymentDialogStepAmountAndInterval,
        PaymentDialogStepPersonalInformationIn,
        PaymentDialogStepContactInformationIn,
        PaymentDialogStepOverviewIn,
        PaymentDialogStepSuccess,
      ];
      break;
    case "za":
      steps = [];
      break;
    default:
  }

  return steps;
};

export default getPaymentDialogSteps;
