import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemTestimonial from "./kontentItemTestimonial";
import CardSlider from "../components/cardSlider/cardSlider";

const KontentItemTestimonialSection = ({ data }) => {
  const testimonials = data.value ? data.value[0] : data;

  if (!testimonials || !testimonials.elements) {
    return null;
  }

  return (
    <CardSlider
      header={testimonials.elements.title.value}
      description={testimonials.elements.description.value}
      showViewAllButton={false}
      showSlideButtons
      className="text-section__fullwidth"
    >
      {testimonials.elements.testimonials.value.map((testimonial) => (
        <KontentItemTestimonial data={testimonial} key={testimonial.id} />
      ))}
    </CardSlider>
  );
};

export const query = graphql`
  fragment KontentItemTestimonialSection on kontent_item_testimonial_section {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      description {
        value
      }
      testimonials {
        value {
          ...KontentItemTestimonial
        }
      }
    }
  }
`;

KontentItemTestimonialSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemTestimonialSection;
