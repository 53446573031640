import React from "react";

import PropTypes from "prop-types";

import BackgroundImage from "../backgroundImage/backgroundImage";

import "./registrationStep.scss";

const RegistrationStep = (props) => {
  const {
    iconbox,
    label,
    description,
    backgroundImageStyle,
  } = props;

  return (
    <div className="registration-step">
      <BackgroundImage
        className="registration-step__image"
        backgroundImageStyle={backgroundImageStyle}
      >
        {iconbox}
      </BackgroundImage>
      <div className="registration-step__label">{label}</div>
      <div className="registration-step__description">{description}</div>
    </div>
  );
};

RegistrationStep.propTypes = {
  backgroundImageStyle: PropTypes.oneOfType([PropTypes.object]),
  description: PropTypes.string,
  iconbox: PropTypes.oneOfType([PropTypes.object]),
  label: PropTypes.string,
};

RegistrationStep.defaultProps = {
  backgroundImageStyle: null,
  description: null,
  iconbox: null,
  label: null,
};

export default RegistrationStep;
