import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import NavigationLink from "../navigationLink/navigationLink";

import "./subNavPoint.scss";

const SubNavPoint = (props) => {
  const {
    className,
    color,
    children,
    ...otherProps
  } = props;

  return (
    <NavigationLink
      {...otherProps}
      className={classNames("sub-nav-point", `sub-nav-point--${color}`, className)}
    >
      {children}
    </NavigationLink>
  );
};

SubNavPoint.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(["black", "white"]),
};

SubNavPoint.defaultProps = {
  className: "",
  color: "black",
};

export default SubNavPoint;
