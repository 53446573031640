import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import DonorPathStep from "./donorPathStep";
import { IconLearnMore18 } from "../../icons";

import "./donorPathTopic.scss";

const DonorPathTopic = (props) => {
  const {
    number,
    stepNumber,
    topic,
    className,
    getRef,
  } = props;

  return (
    <div className={classNames("donor-path-topic", className)} ref={getRef}>
      <div className="donor-path-topic__icon">
        <DonorPathStep stepNumber={number} />
      </div>
      <div>
        <div className="donor-path-topic__step-number">{stepNumber}</div>
        <div className="donor-path-topic__topic">{topic}<IconLearnMore18 /></div>
      </div>
    </div>
  );
};

DonorPathTopic.propTypes = {
  className: PropTypes.string,
  getRef: PropTypes.oneOfType([PropTypes.func]),
  number: PropTypes.string.isRequired,
  stepNumber: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
};

DonorPathTopic.defaultProps = {
  className: null,
  getRef: null,
};

export default DonorPathTopic;
