import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Masonry from "react-masonry-css";

import "./keyFactArea.scss";

const KeyFactArea = (props) => {
  const {
    children,
    title,
    className,
  } = props;

  const keyFactCards = React.Children.toArray(children);

  return (
    <div className={classNames("keyfactarea__container container--fullwidth", className)}>
      <Container fluid>
        <Row>
          <Col md={8} lg={7}>
            <h2 className="keyfactarea__title">{title}</h2>
          </Col>
        </Row>
        <Row className="keyfactarea">
          <Col lg={6}>{keyFactCards[0]}</Col>
          <Col lg={6}>
            <Masonry
              breakpointCols={{
                767: 1,
                991: 2,
                default: 1,
              }}
              className="keyfactarea__children"
              columnClassName="keyfactarea__children-column"
            >
              {keyFactCards.slice(1).map((keyFactCard) => (
                <React.Fragment key={keyFactCard.key}>
                  {keyFactCard}
                </React.Fragment>
              ))}
            </Masonry>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

KeyFactArea.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
};

KeyFactArea.defaultProps = {
  className: null,
};

export default KeyFactArea;
