import React, { useRef, useState } from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import {
  useGetVerifiedAddressDetailsMutation,
  useGetVerifiedAddressPredictionsMutation,
} from "../../features/io/ioLocationApiSlice";
import useClickOutside from "../../hooks/haiku/useClickOutside";
import useLanguage from "../../hooks/useLanguage";
import { IconDeleteInput18, IconSearch18 } from "../../icons";

import "./addressInput.scss";

const AddressInput = ({ onResult, disabled }) => {
  const intl = useIntl();

  const inputRef = useRef();
  const componentRef = useRef();

  const {
    languageCode,
    countryCode,
  } = useLanguage();

  const [
    getVerifiedAddressPredictions,
    getVerifiedAddressPredictionsResult,
  ] = useGetVerifiedAddressPredictionsMutation();
  const [
    getVerifiedAddressDetails,
    getVerifiedAddressDetailsResult,
  ] = useGetVerifiedAddressDetailsMutation();

  const [focus, setFocus] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useClickOutside(componentRef, () => {
    setFocus(false);
  });

  const search = (searchTerm) => {
    clearTimeout(debounceTimeout);

    setHasValue(searchTerm.length > 0);
    setSearching(searchTerm.length >= 5);

    if (searchTerm.length >= 5) {
      setDebounceTimeout(setTimeout(() => {
        getVerifiedAddressPredictions({
          address: encodeURIComponent(searchTerm),
          country: countryCode,
          language: languageCode,
        });

        setSearching(false);
      }, 700));
    } else {
      getVerifiedAddressPredictionsResult.reset();
    }
  };

  const resetSearch = () => {
    inputRef.current.value = "";
    inputRef.current.focus();

    setFocus(true);
    setHasValue(false);
  };

  const selectResult = (addressId, description) => {
    if (getVerifiedAddressDetailsResult.isLoading) {
      return;
    }

    inputRef.current.value = description;

    setSelectedAddressId(addressId);

    getVerifiedAddressDetails({
      addressId,
      country: countryCode,
      language: languageCode,
    }).then(({ data }) => {
      setFocus(false);

      onResult(data.address);
    });
  };

  const showResults = hasValue
    && focus
    && getVerifiedAddressPredictionsResult.data
    && Array.isArray(getVerifiedAddressPredictionsResult?.data?.predictions);

  return (
    <div
      ref={componentRef}
      className="address-input"
    >
      <InputGroup className="prepend">
        <Form.Control
          ref={inputRef}
          autoComplete="none"
          placeholder={intl.formatMessage({ id: "address_lookup.search_label" })}
          onKeyUp={(event) => {
            search(event.target.value);
          }}
          onFocus={() => {
            setFocus(true);
          }}
          disabled={disabled}
        />
        <InputGroup.Prepend>
          <InputGroup.Text>
            <IconSearch18 />
          </InputGroup.Text>
        </InputGroup.Prepend>
        {(getVerifiedAddressPredictionsResult.isLoading || searching) && (
          <div className="address-input__spinner">
            <Spinner
              animation="border"
              variant="dark"
            />
          </div>
        )}
        {hasValue && (
          <button
            type="button"
            className="btn-clean"
            tabIndex="-1"
            onClick={() => {
              resetSearch();
            }}
          >
            <IconDeleteInput18 />
          </button>
        )}
      </InputGroup>
      {showResults && (
        <div className="address-input__results">
          <div className="address-input__results-inner">
            {getVerifiedAddressPredictionsResult.data.predictions.length === 0 && (
              <div className="address-input__no-results">
                <FormattedMessage id="address_lookup.no_results" />
              </div>
            )}
            {getVerifiedAddressPredictionsResult.data.predictions.map(({ id, description }) => (
              <div
                className={classNames(
                  "address-input__result",
                  {
                    "address-input__result--disabled": selectedAddressId !== id && getVerifiedAddressDetailsResult.isLoading,
                  },
                )}
                key={id}
                onClick={() => {
                  selectResult(id, description);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    selectResult(id, description);
                  }
                }}
                role="button"
                tabIndex="0"
              >
                <div className="address-input__result-label-inner">
                  {description}
                </div>
                {selectedAddressId === id && getVerifiedAddressDetailsResult.isLoading && (
                  <Spinner
                    animation="border"
                    variant="dark"
                  />
                )}
              </div>
            ))}
            {process.env.GATSBY_SITE !== "gb" && (
              <Row className="address-input__results-footer">
                <Col xs={3}>
                  <img
                    className="address-input__google-logo"
                    src="/images/powered_by_google_on_white_hdpi.png"
                    alt="Powered by Google"
                  />
                </Col>
                <Col xs={9} className="address-input__google-info">
                  <FormattedMessage id="address_lookup.google" />
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

AddressInput.propTypes = {
  disabled: PropTypes.bool,
  onResult: PropTypes.func.isRequired,
};

AddressInput.defaultProps = {
  disabled: false,
};

export default AddressInput;
