import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import GetLearnTopicCount from "./learn/getLearnTopicCount";
import TopicArticleCards from "./learn/topicArticleCards";
import HighlightTopicTile from "../components/highlightTopicTile/highlightTopicTile";

const KontentItemLearnTopicPreviewSection = ({ data }) => {
  const learnTopicArea = data.value ? data.value : data;

  if (!learnTopicArea || !learnTopicArea.elements) {
    return null;
  }

  const learnTopic = learnTopicArea.elements.learn_topic.value[0];

  return (
    <HighlightTopicTile
      headline={learnTopic.elements.headline.value}
      articleCount={(
        GetLearnTopicCount({
          codename:
            learnTopic.elements.learn_topic.value.length > 0
            && learnTopic.elements.learn_topic.value[0].codename,
        })
      )}
      description={learnTopic.elements.lead_text.value}
      image={
        learnTopic.elements.topic_illustration_preview_section.value.length > 0
          ? (
            <KontentAssetsImage
              width={400}
              data={learnTopic.elements.topic_illustration_preview_section.value[0]}
              className="img-fluid"
            />
          )
          : null
      }
      url={learnTopic.id}
    >
      {TopicArticleCards({ data: learnTopicArea.elements.topic_articles })}
    </HighlightTopicTile>
  );
};

export const query = graphql`
  fragment KontentItemLearnTopicPreviewSection on kontent_item_learn_topic_preview_section {
    id
    system {
      codename
    }
    elements {
      topic_articles {
        value {
          ...TopicArticleCards
        }
      }
      learn_topic {
        value {
          ... on kontent_item_learn_topics_page {
            id
            elements {
              headline {
                value
              }
              lead_text {
                value
              }
              topic_illustration_preview_section {
                value {
                  description
                  height
                  size
                  name
                  type
                  url
                  width
                }
              }
              learn_topic {
                value {
                  codename
                }
              }
            }
          }
        }
      }
    }
  }
`;

KontentItemLearnTopicPreviewSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLearnTopicPreviewSection;
