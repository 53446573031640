import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, ref, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import CleaveFormField from "../../dynamicForm/cleaveFormField/cleaveFormField";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import Tooltip from "../../localForms/tooltip";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationAlternateContactUs = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });

  const validationSchema = useMemo(() => object().shape({
    contactConfirmEmailAddress: string()
      .required(requiredMessage)
      .oneOf([ref("contactEmailAddress")], intl.formatMessage({ id: "registration.additional.error.email_confirm" })),
    contactEmailAddress: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .test(
        "not_allowed_email",
        intl.formatMessage({ id: "registration.additional.error.email" }),
        (v) => !/@.*\.edu$/.test(v),
      )
      .email(intl.formatMessage({ id: "registration.additional.error.email" })),
    contactFirstname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 40 },
      ))
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    contactLastname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    contactPhoneNumber: string()
      .required(requiredMessage)
      .matches(
        /^(\d{3}-\d{3}-\d{4})$/,
        "This value seems to be invalid. The phone number follows the format xxx-xxx-xxxx.",
      ),
    contactRelationship: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      )),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.alternate_contact.title"
        subheadline="registration.alternate_contact.text"
      />
      <Formik
        initialValues={{
          contactEmailAddress: registrationData.contactEmailAddress,
          contactFirstname: registrationData.contactFirstname,
          contactLastname: registrationData.contactLastname,
          contactPhoneNumber: registrationData.contactPhoneNumber,
          contactRelationship: registrationData.contactRelationship,
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          if (registrationData.firstname.toLowerCase() === values.contactFirstname.toLowerCase()
            && registrationData.lastname.toLowerCase() === values.contactLastname.toLowerCase()) {
            errors.contactLastname = intl.formatMessage({ id: "registration.alternate_contact.error.same_name" });
          }

          return errors;
        }}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              contactEmailAddress: values.contactEmailAddress,
              contactFirstname: values.contactFirstname,
              contactLastname: values.contactLastname,
              contactPhoneNumber: values.contactPhoneNumber,
              contactRelationship: values.contactRelationship,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_alternate_contact", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="contactFirstname"
                  label={intl.formatMessage({ id: "registration.alternate_contact.firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="contactLastname"
                  label={intl.formatMessage({ id: "registration.alternate_contact.lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12}>
                <CleaveFormField
                  label={intl.formatMessage({ id: "registration.alternate_contact.phone_number" })}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  id="contactPhoneNumber"
                  errors={errors}
                  options={{
                    blocks: [3, 3, 4],
                    delimiter: "-",
                    numericOnly: true,
                  }}
                  maxLength={12}
                  required
                />
              </Col>
              <Col xs={12}>
                <Tooltip
                  id="emailAddress-tooltip"
                  tooltipMessage={intl.formatMessage({ id: "registration.email.tooltip" })}
                >
                  <FormField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="contactEmailAddress"
                    label={intl.formatMessage({ id: "registration.additional.email" })}
                    type="email"
                    maxLength={100}
                    touched={touched}
                    errors={errors}
                    values={values}
                    required
                  />
                </Tooltip>
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="contactConfirmEmailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email_confirm" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col md={12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.alternate_contact.relationship" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("contactRelationship", target.value);
                      setFieldError("contactRelationship", undefined);
                    } else {
                      setFieldValue("contactRelationship", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("contactRelationship", true);
                  }}
                  touched={touched}
                  id="contactRelationship"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.relationshipOptionsUs}
                  required
                  maxMenuHeight={300}
                />
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.alternate_contact.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationAlternateContactUs.stepName = "registration_alternate_contact";

RegistrationDialogStepRegistrationAlternateContactUs.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationAlternateContactUs;
