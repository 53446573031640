import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import LearnTopicEmbedComponent from "../../components/learnTopicEmbed/learnTopicEmbed";
import KontentAssetsImage from "../kontentAssetsImage";

const DonorTopicEmbed = ({ data }) => {
  const donorTopicEmbed = data.value ? data.value[0] : data;

  if (!donorTopicEmbed || !donorTopicEmbed.elements) {
    return null;
  }

  return (
    <LearnTopicEmbedComponent
      title={donorTopicEmbed.elements.headline.value}
      href={donorTopicEmbed.id}
      image={donorTopicEmbed.elements.topic_illustration_small_tile.value > 0
        ? (
          <KontentAssetsImage
            width={100}
            data={donorTopicEmbed.elements.topic_illustration_small_tile.value[0]}
          />
        )
        : null}
    />
  );
};

export const query = graphql`
  fragment DonorTopicEmbed on kontent_item_donor_area_topic {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      topic_illustration_small_tile {
        value {
          name
          height
          description
          url
          width
          size
          type
        }
      }
    }
  }
`;

DonorTopicEmbed.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default DonorTopicEmbed;
