import React, { useRef } from "react";

import PropTypes from "prop-types";

import { IconClose18, IconClose24 } from "../../icons";
import AdaptiveIcon from "../adaptiveIcon";
import BackgroundImage from "../backgroundImage/backgroundImage";
import IconButton from "../button/iconButton";

import "./imageLightbox.scss";

const ImageLightbox = (props) => {
  const {
    backgroundImageStyle,
    headline,
    text,
    buttons,
    id,
  } = props;

  const lightbox = useRef(null);
  const lightboxWrapper = useRef(null);

  return (
    <div className="image-lightbox d-none" ref={lightbox} id={id}>
      <div className="image-lightbox__wrapper" ref={lightboxWrapper}>
        <div className="image-lightbox__close-button">
          <IconButton
            iconOnly
            onClick={() => {
              if (lightbox) {
                lightbox.current.classList.add("d-none");
              }
            }}
          >
            <AdaptiveIcon
              sm={<IconClose18 />}
              lg={<IconClose24 />}
            />
          </IconButton>
        </div>
        <BackgroundImage
          className="image-lightbox__image"
          backgroundImageStyle={backgroundImageStyle}
        />
        <div className="image-lightbox__content">
          <div className="image-lightbox__headline">{headline}</div>
          <div className="image-lightbox__text">{text}</div>
          <div className="image-lightbox__buttons">{buttons}</div>
        </div>
      </div>
    </div>
  );
};

ImageLightbox.propTypes = {
  backgroundImageStyle: PropTypes.oneOfType([PropTypes.object]).isRequired,
  buttons: PropTypes.node,
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

ImageLightbox.defaultProps = {
  buttons: null,
};

export default ImageLightbox;
