const Templates = {};

Templates.KontentItemAccordion = require("./kontentItemAccordion").default;
Templates.KontentItemArticlesEmbed = require("./kontentItemArticlesEmbed").default;
Templates.KontentItemCtaButton = require("./kontentItemCtaButton").default;
Templates.KontentItemDonationBox = require("./kontentItemDonationBox").default;
Templates.KontentItemDonationHeaderSimple = require("./moneyDonation/kontentItemDonationHeaderSimple").default;
Templates.KontentItemDownloadGroup = require("./kontentItemDownloadGroup").default;
Templates.KontentItemEmbedExternal = require("./kontentItemEmbedExternal").default;
Templates.KontentItemEmbedFacebookPost = require("./socialEmbed/kontentItemEmbedFacebookPost").default;
Templates.KontentItemEmbedInstagramPost = require("./socialEmbed/kontentItemEmbedInstagramPost").default;
Templates.KontentItemEyecatcher = require("./kontentItemEyecatcher").default;
Templates.KontentItemEyecatcherContact = require("./kontentItemEyecatcherContact").default;
Templates.KontentItemEyecatcherCounter = require("./campaigns/kontentItemEyecatcherCounter").default;
Templates.KontentItemEyecatcherDonate = require("./kontentItemEyecatcherDonate").default;
Templates.KontentItemEyecatcherDonation = require("./kontentItemEyecatcherDonation").default;
Templates.KontentItemEyecatcherImage = require("./kontentItemEyecatcherImage").default;
Templates.KontentItemEyecatcherRegistration = require("./kontentItemEyecatcherRegistration").default;
Templates.KontentItemFaqSection = require("./kontentItemFaqSection").default;
Templates.KontentItemFormRichText = require("./form/kontentItemFormRichText").default;
Templates.KontentItemIconCardSliderSection = require("./kontentItemIconCardSliderSection").default;
Templates.KontentItemImage = require("./kontentItemImage").default;
Templates.KontentItemImageButton = require("./kontentItemImageButton").default;
Templates.KontentItemImageCardSliderSection = require("./kontentItemImageCardSliderSection").default;
Templates.KontentItemImageCardsGroup = require("./kontentItemImageCardsGroup").default;
Templates.KontentItemImageGrid = require("./kontentItemImageGrid").default;
Templates.KontentItemInputTypeAddress = require("./form/kontentItemInputTypeAddress").default;
Templates.KontentItemInputTypeCheckbox = require("./form/kontentItemInputTypeCheckbox").default;
Templates.KontentItemInputTypeCountry = require("./form/kontentItemInputTypeCountry").default;
Templates.KontentItemInputTypeDate = require("./form/kontentItemInputTypeDate").default;
Templates.KontentItemInputTypeDropdown = require("./form/kontentItemInputTypeDropdown").default;
Templates.KontentItemInputTypeEmail = require("./form/kontentItemInputTypeEmail").default;
Templates.KontentItemInputTypeName = require("./form/kontentItemInputTypeName").default;
Templates.KontentItemInputTypeSalutation = require("./form/kontentItemInputTypeSalutation").default;
Templates.KontentItemInputTypeSingleInput = require("./form/kontentItemInputTypeSingleInput").default;
Templates.KontentItemInputTypeState = require("./form/kontentItemInputTypeState").default;
Templates.KontentItemInputTypeText = require("./form/kontentItemInputTypeText").default;
Templates.KontentItemKeyFactAreaMoneyDonation = require("./moneyDonation/kontentItemKeyFactAreaMoneyDonation").default;
Templates.KontentItemLinkCard = require("./kontentItemLinkCard").default;
Templates.KontentItemLinkCardGroup = require("./kontentItemLinkCardGroup").default;
Templates.KontentItemMediaGallery = require("./kontentItemMediaGallery").default;
Templates.KontentItemMegaMenuData = require("./kontentItemMegaMenuData").default;
Templates.KontentItemMissionStatement = require("./kontentItemMissionStatement").default;
Templates.KontentItemMoneyPartition = require("./moneyDonation/kontentItemMoneyPartition").default;
Templates.KontentItemProcessStepGroup = require("./kontentItemProcessStepGroup").default;
Templates.KontentItemProgramSliderSection = require("./kontentItemProgramSliderSection").default;
Templates.KontentItemQuote = require("./kontentItemQuote").default;
Templates.KontentItemRegistrationProcessSection = require("./kontentItemRegistrationProcessSection").default;
Templates.KontentItemRegistryBanner = require("./kontentItemRegistryBanner").default;
Templates.KontentItemRelatedDonorArticlesSection = require("./kontentItemRelatedDonorArticlesSection").default;
Templates.KontentItemRelatedLearnArticles = require("./kontentItemRelatedLearnArticles").default;
Templates.KontentItemRelatedLearnContentEmbed = require("./kontentItemRelatedLearnContentEmbed").default;
Templates.KontentItemRelatedNewsSection = require("./kontentItemRelatedNewsSection").default;
Templates.KontentItemRelatedPressArticles = require("./kontentItemRelatedPressArticles").default;
Templates.KontentItemSectionHeader = require("./form/kontentItemSectionHeader").default;
Templates.KontentItemStatementboxGroup = require("./statements/kontentItemStatementboxGroup").default;
Templates.KontentItemTargetGroupSection = require("./kontentItemTargetGroupSection").default;
Templates.KontentItemTestimonialSection = require("./kontentItemTestimonialSection").default;
Templates.KontentItemTweet = require("./socialEmbed/kontentItemTweet").default;
Templates.KontentItemVideoEmbed = require("./kontentItemVideoEmbed").default;

export default Templates;
