import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

import ContentHeader from "../../components/contentHeader/contentHeader";
import SearchFilter from "../../components/searchFilter/searchFilter";
import SEO from "../../components/seo";
import { HeaderIconFaq } from "../../images";
import mapKontentItem from "../../utils/mapKontentItem";
import FaqOverviewComponent from "../faq/faqOverviewComponent";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";

const KontentItemFaqPage = ({ data }) => {
  const { page } = data;

  const mainEntity = [];

  page.elements.faq_groups.value.forEach((item) => (
    item.elements.group_items.value.forEach((faqObject) => {
      let tmpString = faqObject.elements.answer.value;
      tmpString = tmpString.replaceAll(/<\/a>/g, "");
      tmpString = tmpString.replaceAll(/<a[a-zA-Z0-9 "=/\\:-]*>/g, "");
      mainEntity.push({
        "@type": "Question",
        acceptedAnswer: {
          "@type": "Answer",
          text: tmpString,
        },
        name: faqObject.elements.question.value,
      });
    })
  ));

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
        linkingData={{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity,
        }}
      />

      <div className="container--fullwidth bg-faq-dualtone">
        <Container fluid>
          <BreadcrumbGenerator id={data.page.id} />
        </Container>
      </div>

      <ContentHeader
        headline={page.elements.headline.value}
        description={page.elements.page_description.value}
        media={<HeaderIconFaq />}
        variant="faq"
      />

      <SearchFilter variant="faq">
        <FaqOverviewComponent items={page.elements.faq_groups} />
      </SearchFilter>

      {data.page.elements.page_sections.value.map((item) => mapKontentItem(item))}

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemFaqPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        page_description {
          value
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemImageCardSliderSection
          }
        }
        faq_groups {
          value {
            ...FaqOverviewComponent
          }
        }
      }
    }
  }
`;

KontentItemFaqPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemFaqPage;
