import useEventListener from "./useEventListener";

const useClickOutside = (ref, handler, trigger = ["mousedown"]) => {
  useEventListener(trigger, (event) => {
    const el = ref?.current;

    if (!el || el.contains(event.target)) {
      return;
    }

    handler(event);
  });
};

export default useClickOutside;
