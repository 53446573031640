import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Collapse from "react-bootstrap/Collapse";

import LinkButton from "../button/linkButton";

import "./collapseBox.scss";

const CollapseBox = (props) => {
  const {
    opened,
    label,
    icon,
    children,
    warning,
    disabled,
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <div className={classNames("collapse-box", { warning }, { open: open || opened })}>
      {!open && !opened && (
        <LinkButton
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              setOpen(true);
            }
          }}
        >
          {label} {icon}
        </LinkButton>
      )}

      <Collapse className="collapse-box__inner" in={open || opened}>
        <div>
          {children}
        </div>
      </Collapse>
    </div>
  );
};

CollapseBox.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  opened: PropTypes.bool,
  warning: PropTypes.bool,
};

CollapseBox.defaultProps = {
  disabled: false,
  opened: false,
  warning: false,
};

export default CollapseBox;
