import React from "react";

import classNames from "classnames";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import ArticleModule from "../components/articleModule/articleModule";
import EmbedMediaPlayer from "../components/embedMediaPlayer/embedMediaPlayer";

const KontentItemVideoEmbed = (props) => {
  const {
    data,
    variant,
    ltr,
    noLength,
    previewImageSizeByParent,
  } = props;

  const video = data.value ? data.value[0] : data;

  if (!video || !video.elements || video.elements.image.value.length === 0) {
    return null;
  }

  let extraClasses = "";
  let child = (
    <EmbedMediaPlayer
      videoUrl={video.elements.url.value}
      previewImageUrl={`${video.elements.image.value[0].url}?w=400&h=400&fm=pjpg&auto=format`}
      title={video.elements.title.value}
      length={noLength ? null : video.elements.duration.value}
      variant={variant}
      ltr={ltr}
    />
  );

  if (video.elements.video_component_style.value.length > 0 && !previewImageSizeByParent) {
    const { codename } = video.elements.video_component_style.value[0];

    if (codename === "panorama_21_9") {
      extraClasses = "text-section__fullwidth";
      child = (
        <div className="mx-sm-15">
          <ArticleModule caption={null}>
            {React.cloneElement(child,
              {
                previewImageUrl: `${video.elements.image.value[0].url}?w=1920&h=${(1920 / 21) * 9}&fm=pjpg&auto=format`,
              })}
          </ArticleModule>
        </div>
      );
    } else if (codename === "block_image_right" || codename === "block_image_left") {
      child = (
        <div className="embed-responsive-item">
          {React.cloneElement(child,
            {
              previewImageUrl: `${video.elements.image.value[0].url}?w=500&h=500&fm=pjpg&auto=format`,
            })}
        </div>
      );
      extraClasses = classNames("p-0 col-12 col-md-4 embed-responsive embed-responsive-1by1",
        { "float-right mt-0 ml-30": codename === "block_image_right" },
        { "float-left mt-0 mr-30": codename === "block_image_left" });
    }
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@id": video.elements.url.value,
            "@type": "VideoObject",
            description: video?.elements?.description?.value || video.elements?.title?.value,
            duration: video?.elements?.duration?.value,
            name: video.elements?.title?.value,
            thumbnailUrl: video.elements?.image?.value.length > 0 ? video.elements.image.value[0].url : "",
            uploadDate: video.elements?.upload_date?.value,
          })}
        </script>
      </Helmet>
      <div className={classNames(extraClasses, "video-container")}>{child}</div>
    </>
  );
};

export const query = graphql`
  fragment KontentItemVideoEmbed on kontent_item_video_embed {
    id
    system {
      codename
    }
    internal {
        type
      }
    elements {
      title {
        value
      }
      url {
        value
      }
      duration {
        value
      }
      image {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
      video_component_style {
        value {
          name
          codename
        }
      }
      description {
        value
      }
      upload_date {
        value(formatString: "YYYY-MM-DD")
      }
    }
  } 
`;

KontentItemVideoEmbed.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  ltr: PropTypes.bool,
  noLength: PropTypes.bool,
  previewImageSizeByParent: PropTypes.bool,
  variant: PropTypes.string,
};

KontentItemVideoEmbed.defaultProps = {
  ltr: false,
  noLength: false,
  previewImageSizeByParent: false,
  variant: "null",
};

export default KontentItemVideoEmbed;
