import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";

export const placeholderTypes = Object.freeze({
  download: "placeholder-download",
  events: "placeholder-events",
  icon: "placeholder-icon",
  maps: "placeholder-events-map",
  news: "placeholder-news",
  "virtual-drive": "placeholder-virtual-drive",
});

const ImagePlaceholder = (props) => {
  const {
    aspectRatio,
    className,
    height,
    typ,
    width,
  } = props;

  const imageName = placeholderTypes[typ] || "placeholder";

  const data = useStaticQuery(graphql`{
    placeholderImages: allFile(filter: {name: {regex: "/placeholder/"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        name
      }
    }
  }`);

  const imageNode = data?.placeholderImages?.nodes.find((item) => item.name === imageName);

  if (!imageNode?.childImageSharp?.gatsbyImageData) {
    return null;
  }

  const image = {
    ...imageNode.childImageSharp.gatsbyImageData,
    height: height > 0 ? height : width / aspectRatio,
    width: width > 0 ? width : height / aspectRatio,
  };

  return (
    <div className="p-absolute w-100 h-100">
      <GatsbyImage image={image} alt="Placeholder" className={`p-absolute w-100 h-100 ${className}`} objectPosition="50%" objectFit="contain" />
    </div>
  );
};

ImagePlaceholder.propTypes = {
  aspectRatio: PropTypes.number,
  className: PropTypes.string,
  height: PropTypes.number,
  typ: PropTypes.oneOf(["maps", "events", "icon", "news", "virtual-drive", "download"]),
  width: PropTypes.number,
};

ImagePlaceholder.defaultProps = {
  aspectRatio: 1,
  className: "",
  height: null,
  typ: null,
  width: 1920,
};

export default ImagePlaceholder;
