import React, { createRef, useEffect } from "react";

import { navigate } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import Form from "./form";
import { createYupSchema, generateInitialValues, getTemplate } from "./formBuilder";
import { reset } from "../../features/donateFormSlice";
import { useSubmitIoUpdateMailAddressMutation } from "../../features/io/ioUpdateMailAddressApiSlice";
import { setMailUpdateFields, setMailUpdateFormValues } from "../../features/updateAddressSlice";
import { IconSubmit24 } from "../../icons";
import { notificationService } from "../../services/notification.service";
import LinkButton from "../button/linkButton";
import Notification from "../notification/notification";

const AddressForm = getTemplate("UpdateMailAddress");

const UpdateMailAddress = ({ privacyConsent, thankYouPageLink }) => {
  const fields = useSelector((state) => state.updateAddress.mailUpdateFields);
  const formValues = useSelector((state) => state.updateAddress.mailUpdateFormValues);

  const [
    submitIoUpdateMailAddress,
    submitIoUpdateMailAddressResult,
  ] = useSubmitIoUpdateMailAddressMutation();

  const dispatch = useDispatch();

  const intl = useIntl();

  const updateAddressForm = createRef();

  const initFields = AddressForm.getUpdateMailAddressForm(intl);
  const filteredInputs = initFields.filter((field) => field.type === "input");
  const initialValues = generateInitialValues(filteredInputs);

  const mappedValidation = filteredInputs.map((field) => ({
    id: field.props.id,
    validationType: field.validation.type,
    validations: field.validation.validators,
  }));

  const yupSchema = mappedValidation.reduce(createYupSchema, {});

  const validationSchema = Yup.object(yupSchema);

  const setInitialFields = (_fields) => _fields.map((field) => {
    const f = { ...field };
    Object.freeze(f);

    if (f.props.id === "privacy") {
      f.props.data = privacyConsent;
    }

    return f;
  });

  const onSubmit = (values) => {
    dispatch(
      setMailUpdateFormValues(values),
    );

    const payload = AddressForm.generatePayload(values);

    submitIoUpdateMailAddress({
      data: payload,
    });
  };

  const onSuccess = () => {
    dispatch(
      reset(),
    );

    navigate(thankYouPageLink);
  };

  const onError = () => {
    notificationService.error(intl.formatMessage({ id: "error.something_went_wrong" }), {
      action: (
        <LinkButton onClick={() => onSubmit(formValues)}>
          <FormattedMessage values={{ countdown: 0 }} id="io.error.retry" /> <IconSubmit24 />
        </LinkButton>),
      autoClose: false,
    });
  };

  useEffect(() => {
    dispatch(
      setMailUpdateFields(setInitialFields(initFields)),
    );
  }, []);

  useEffect(() => {
    if (submitIoUpdateMailAddressResult.isSuccess) {
      onSuccess();
    }

    if (submitIoUpdateMailAddressResult.isError) {
      onError();
    }
  }, [submitIoUpdateMailAddressResult]);

  return (
    <>
      <div className="dynamic-form">
        <div id="update-address-form">
          <Form
            ref={updateAddressForm}
            fields={fields}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            footNote={intl.formatMessage({ defaultMessage: " ", id: "address_update.footnote" })}
            submitButtonLabel={intl.formatMessage({ id: "form.updateAddress.submit" })}
            summaryInfo={intl.formatMessage({ id: "update_mail.summary.info" })}
          />
        </div>
      </div>
      <Notification />
    </>
  );
};

UpdateMailAddress.propTypes = {
  privacyConsent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  thankYouPageLink: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default UpdateMailAddress;
