import React from "react";

import Col from "react-bootstrap/Col";

import { dateFactory, format } from "../../utils/dateHelper";

const detailViewBuild = (field, intl, values) => {
  switch (field.tagName) {
    case "FormSectionHeader":
      return (
        <Col xs={12} className="typo-h4 detail-view__headline">
          {intl.formatMessage({ id: field.props.headline }).replace(/[0-9]./g, "")}
        </Col>
      );
    case "FormAddressInput":
      return field.props.subFields.map((subField) => (
        <>
          <Col xs={6} className="typo-label detail-view__label">{subField.props.label}</Col>
          <Col xs={6} className="detail-view__value">{values[subField.props.name]}</Col>
        </>
      ));
    case "RadioButtonSection":
      return field.props.radioButtonLists.map((item) => (
        <>
          <Col xs={6} className="typo-label detail-view__label">{item.label}</Col>
          <Col xs={6} className="detail-view__value">{values[item.name]}</Col>
        </>
      ));
    case "DateInput":
      return (
        <>
          <Col xs={6} className="typo-label detail-view__label">{field.props.label}</Col>
          <Col xs={6} className="detail-view__value">{format(dateFactory(values[field.props.name]))}</Col>
        </>
      );
    case "RadioInput":
      return (
        <>
          <Col xs={6} className="typo-label detail-view__label">{field.props.label}</Col>
          <Col xs={6} className="detail-view__value">{field.options.find((item) => item.value === values[field.props.name]).label}</Col>
        </>
      );
    default:
      return (
        <>
          <Col xs={6} className="typo-label detail-view__label">{field.props.label}</Col>
          <Col xs={6} className="detail-view__value">{values[field.props.name]}</Col>
        </>
      );
  }
};

export default detailViewBuild;
