import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";

import { setNavigationHeaderEnabled } from "../../../features/registrationSlice";
import { IllustrationCheck } from "../../../images";
import KontentItemImage from "../../../templates/kontentItemImage";
import Logo from "../../logo";

const VirtualDriveSetupModalSuccess = ({ content }) => {
  const dispatch = useDispatch();

  dispatch(
    setNavigationHeaderEnabled(false),
  );

  return (
    <Row className="virtual-drive-setup-dialog__success-page">
      <Col xs={12} lg={{ offset: 2, span: 8 }} className="text-center">
        <div className="virtual-drive-setup-dialog__success-page-image container--fullwidth">
          <Logo breakpoint="lg" white />
          <KontentItemImage data={content.imageSuccessPage} aspectRatio={16 / 9} />
          <IllustrationCheck />
        </div>
        <div className="virtual-drive-setup-dialog__success-page-content">
          <IllustrationCheck />
          <h3 className="mt-24">
            <FormattedMessage id="virtual_drive_setup.success_title" />
          </h3>
          <p className="text-black">
            <FormattedMessage id="virtual_drive_setup.success_text" />
          </p>
          <div className="pt-36">
            {content.successButton}
          </div>
        </div>
      </Col>
    </Row>
  );
};

VirtualDriveSetupModalSuccess.stepName = "virtual_drive_success";

VirtualDriveSetupModalSuccess.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default VirtualDriveSetupModalSuccess;
