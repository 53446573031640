import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { format } from "../../utils/dateHelper";

import "./dkmsNumbersList.scss";

const DkmsNumbersList = (props) => {
  const {
    headline,
    children,
    lastModifiedDate,
  } = props;

  return (
    <div className="dkms-numbers-list">
      <div className="dkms-numbers-list__headline">{headline}</div>
      <Row className="dkms-numbers-list__items">
        {React.Children.toArray(children).map(
          (child, index) => (
            <Col
              className="dkms-numbers-list__item"
              key={`dkms-numbers-list__item${index.toString()}`}
              xs={12}
              sm={6}
              md={4}
            >
              {child}
            </Col>
          ),
        )}
      </Row>
      <div className="dkms-numbers-list__subtext">
        <FormattedMessage id="dkms_number_list_subtext" /> {format(lastModifiedDate)}
      </div>
    </div>
  );
};

DkmsNumbersList.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
  lastModifiedDate: PropTypes.instanceOf(Date).isRequired,
};

export default DkmsNumbersList;
