const Templates = {};

Templates.TextInput = require("../dynamicForm/formField/formField").default;
Templates.DateInput = require("../dynamicForm/dateField/dateField").default;
Templates.CheckboxInput = require("../dynamicForm/checkbox/checkbox").default;
Templates.RadioInput = require("../dynamicForm/radioButtons/radioButtons").default;
Templates.SelectInput = require("../dynamicForm/dropdownFormField/dropdownFormField").default;
Templates.CleaveInput = require("../dynamicForm/cleaveFormField/cleaveFormField").default;
Templates.FormSectionHeader = require("./sectionHeader/sectionHeader").default;
Templates.RadioButtonSection = require("../radioButtonSection/radioButtonSection").default;
Templates.FormAddressInput = require("./formAddressInput/formAddressInput").default;

export default Templates;
