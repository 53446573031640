import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ArticleCard from "../../components/articleCard/articleCard";
import KontentItemImage from "../kontentItemImage";

const LearnCard = ({ data, variant, author }) => {
  let newsData = Array.isArray(data) ? data : [data];

  const newsList = [];

  if (author) {
    newsData = newsData.filter((item) => (
      item.elements.author_name.value.length > 0
      && item.elements.author_name.value[0].codename === author));
  }

  newsData.forEach((news) => {
    newsList.push(
      <ArticleCard
        image={(
          <KontentItemImage
            data={news.elements.hero_image}
            width={400}
            aspectRatio={400 / 225}
            fit="crop"
            placeholderTyp="news"
          />
        )}
        category={
          news.elements.learn_topic.value.length > 0
            ? news.elements.learn_topic.value[0].name
            : null
        }
        headline={news.elements.headline.value}
        href={news.id}
        excerpt={news.elements.lead_text.value}
        variant={variant}
      />,
    );
  });

  return React.Children.toArray(newsList);
};

export const query = graphql`
  fragment LearnCard on kontent_item_article_page_learn {
    id
    system {
      codename
      last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
    }
    elements {
      author_name {
        value {
          codename
        }
      }
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      lead_text {
        value
      }
      learn_topic {
        value {
          codename
          name
        }
      }
    }
  }
`;

LearnCard.propTypes = {
  author: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  variant: PropTypes.oneOf(["learn-topic", "learn-article"]).isRequired,
};

LearnCard.defaultProps = {
  author: null,
};

export default LearnCard;
