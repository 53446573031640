import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import InfoNavigation from "./infoNavigation";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { IconTargetGroups18 } from "../../icons";
import iconHelper from "../../utils/iconHelper";
import LinkButton from "../button/linkButton";
import FooterSection from "../footer/footerSection";
import NavigationLink from "../navigationLink/navigationLink";

const isGroup = process.env.GATSBY_SITE === "group";

const FooterNavigation = () => {
  const data = useStaticQuery(graphql`
    {
      footerNavigation: kontentItemNavigationItem(system: {codename: {eq: "footer_navigation"}}) {
        id
        elements {
          elements {
            value {
              id
              typename: __typename
              preferred_language
              ... on kontent_item_navigation_section {
                id,
                elements {
                  title {
                    value
                  }
                  link {
                    value {
                      id
                    }
                  }
                  sub_items {
                    value {
                      ... on kontent_item_navigation_item {
                        id
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      infoNavigation: kontentItemNavigationItem(system: {codename: {eq: "info_navigation"}}) {
        id
        elements {
          title {
            value
          }
          icon {
            value {
              codename
            }
          }
          elements {
            value {
              id
              typename: __typename
              preferred_language
              ... on kontent_item_navigation_item {
                id
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                }
              }
            }
          }
        }
      }
      infoNavigation2: kontentItemNavigationItem(system: {codename: {eq: "info_navigation_2"}}) {
        id
        elements {
          title {
            value
          }
          icon {
            value {
              codename
            }
          }
          elements {
            value {
              id
              typename: __typename
              preferred_language
              ... on kontent_item_navigation_item {
                id
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                }
              }
            }
          }
        }
      }
      secondaryNavigation: kontentItemNavigationItem(system: {codename: {eq: "secondary_navigation"}}) {
        id
        elements {
          elements {
            value {
              id
              preferred_language
              ... on kontent_item_navigation_item {
                id,
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                  icon {
                    value {
                      codename
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const footerNavigation = [];

  const { width } = useWindowDimensions();

  if (data.footerNavigation) {
    data.footerNavigation.elements.elements.value.forEach((item) => {
      footerNavigation.push((
        <React.Fragment key={item.elements.title.value}>
          <Col lg={3}>
            <FooterSection
              sectionName={
                item.elements.link
                && item.elements.link.value.length > 0
                  ? (
                    <>
                      {width >= 992
                        ? (
                          <NavigationLink
                            className="no-style"
                            href={item.elements.link.value[0].id}
                          >
                            {item.elements.title.value}
                          </NavigationLink>
                        )
                        : item.elements.title.value}
                    </>
                  )
                  : item.elements.title.value
              }
            >
              <div className="nav">
                {
                  item.elements.link
                  && item.elements.link.value.length > 0
                  && width < 992 && (
                    <NavigationLink
                      className="nav-link"
                      href={item.elements.link.value[0].id}
                    >
                      <FormattedMessage id="navigation.menu.overview" />
                    </NavigationLink>
                  )
                }
                {item.elements.sub_items.value.map((link) => (
                  <NavigationLink {...link} key={link.id} className="nav-link" />
                ))}
              </div>
            </FooterSection>
          </Col>
        </React.Fragment>
      ));
    });

    if (data.footerNavigation.elements.elements.value.length < 3) {
      for (let i = data.footerNavigation.elements.elements.value.length; i <= 3; i += 1) {
        footerNavigation.push((
          <Col lg={3} />
        ));
      }
    }

    if (data.infoNavigation) {
      footerNavigation.push((
        <React.Fragment key="menuinfonavigation1">
          <Col xs={12} lg={3} className="footer-menu__quicklinks">
            {width < 992 && (<div className="divider" />)}
            <Row>
              {data.secondaryNavigation
                && data.secondaryNavigation.elements.elements.value.filter(
                  (item) => item.id || item.elements.icon,
                ).map((link) => (
                  <Col key={link.elements.title.value} xs={12} className="footer-section burger-navigation">
                    {width < 992
                      ? (
                        <NavigationLink
                          {...link}
                          href={link.elements.external_url.value || `${link.elements.url_slug.value}`}
                          key={link.id}
                          className="footer-section__header burger-navigation__nav-direct-link footer-menu__info--padding"
                        />
                      )
                      : (
                        <LinkButton
                          href={link.elements.external_url.value || `${link.elements.url_slug.value}`}
                          className="d-flex"
                          variant="normal"
                        >
                          {link.elements.icon && link.elements.icon.value.length > 0
                            && iconHelper(link.elements.icon.value[0].codename)}
                          {link.elements.title.value}
                        </LinkButton>
                      )}
                  </Col>
                ))}
              {!isGroup && (
                <>
                  {width < 992
                    ? (
                      <Col xs={12} className="footer__info-navigation mt-0">
                        <FooterSection
                          sectionName={data.infoNavigation.elements.title.value}
                          icon={
                            iconHelper(
                              data.infoNavigation.elements.icon.value[0]?.codename,
                            ) || <IconTargetGroups18 />
                          }
                        >
                          <div className="nav">
                            {data.infoNavigation.elements.elements.value.filter(
                              (item) => item.id,
                            ).map((link) => (
                              <NavigationLink
                                {...link}
                                href={link.elements.external_url.value || `${link.elements.url_slug.value}`}
                                key={link.id}
                                className="nav-link"
                              />

                            ))}
                          </div>
                        </FooterSection>
                      </Col>
                    )
                    : (
                      <Col xs={12} className="d-flex top-navigation align-items-center">
                        <InfoNavigation renderDropdown footer data={data.infoNavigation} />
                      </Col>
                    )}
                  {data.infoNavigation2 && (
                    <>
                      {width < 992
                        ? (
                          <Col xs={12} className="footer__info-navigation">
                            <FooterSection
                              sectionName={data.infoNavigation2.elements.title.value}
                              icon={
                                iconHelper(
                                  data.infoNavigation2.elements.icon.value[0]?.codename,
                                )
                                || <IconTargetGroups18 />
                              }
                            >
                              <div className="nav">
                                {data.infoNavigation2.elements.elements.value.filter(
                                  (item) => item.id,
                                ).map((link) => (
                                  <NavigationLink
                                    {...link}
                                    href={link.elements.external_url.value || `${link.elements.url_slug.value}`}
                                    key={link.id}
                                    className="nav-link"
                                  />

                                ))}
                              </div>
                            </FooterSection>
                          </Col>
                        )
                        : (
                          <Col xs={12} className="d-flex top-navigation align-items-center">
                            <InfoNavigation renderDropdown footer data={data.infoNavigation2} />
                          </Col>
                        )}
                    </>
                  )}
                </>
              )}
            </Row>
            {!isGroup && width < 992 && (<div className="divider" />)}
          </Col>
        </React.Fragment>
      ));
    }
  }

  return footerNavigation;
};

export default FooterNavigation;
