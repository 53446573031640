import React, { useEffect } from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import KontentItemProvider from "./kontentItemProvider";
import Button from "../../components/button/button";
import ComponentDonationBox from "../../components/donationBox/donationBox";
import { cleanupObject } from "../../utils/formik/objectUtils";
import getInternalLinkById from "../../utils/getInternalLinkById";
import iconHelper from "../../utils/iconHelper";

const getAmounts = (data) => {
  if (!data) {
    return {};
  }

  return data.map((donationAmount) => ({
    amount: parseInt(donationAmount.elements.amount.value, 10),
    impact: {
      icon: iconHelper(
        donationAmount.elements.amount_text_icon.value.length > 0
          ? donationAmount.elements.amount_text_icon.value[0].codename
          : null,
      ),
      text: donationAmount.elements.amount_text.value,
    },
  }));
};

const DonationBox = (props) => {
  const {
    initialValues,
    overwriteDonationBoxData,
    buttonURL,
    buttonText,
    title,
    provider,
  } = props;

  const siteSettingsData = useStaticQuery(graphql`
    {
      settings: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          payment_dialog_url {
            value {
              id
            }
          }
          payment_provider_onetime {
            value {
              url
              description
              height
              name
              size
              type
              width
            }
          }
          payment_provider_recurrent {
            value {
              url
              description
              height
              name
              size
              type
              width
            }
          }
          contact_page {
            value {
              id
            }
          }
          donation_box_default {
            value {
              ...KontentItemDonationBox
            }
          }
        }
      }
    }
  `);

  let donationBoxData;

  if (overwriteDonationBoxData) {
    donationBoxData = {
      ...siteSettingsData?.settings?.elements?.donation_box_default?.value[0],
      ...cleanupObject(overwriteDonationBoxData),
    };
  } else {
    donationBoxData = siteSettingsData?.settings?.elements?.donation_box_default?.value[0];
  }

  const defaultAmount = initialValues?.amount || parseInt(
    donationBoxData?.elements?.amounts_once?.value?.[0]?.elements?.amount?.value,
    10,
  );

  let urlSearch = "";

  if (typeof window !== "undefined") {
    urlSearch = window.location.search;
  }

  const queryParameters = new URLSearchParams(urlSearch);
  const urlAmount = queryParameters.get("amount");
  const urlInterval = queryParameters.get("interval");

  // Todo: Check if interval is a valid option for the current site.

  const defaultButtonURL = getInternalLinkById(
    donationBoxData?.elements?.button_url?.value?.[0]?.id
    || siteSettingsData?.settings?.elements?.payment_dialog_url?.value?.[0]?.id,
  );

  useEffect(() => {
    if (urlAmount !== null || urlInterval !== null) {
      queryParameters.delete("amount");
      queryParameters.delete("interval");

      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + (queryParameters.size > 0 ? `?${queryParameters.toString()}` : ""),
      );
    }
  }, []);

  return (
    <ComponentDonationBox
      {...props}
      buttonURL={buttonURL || defaultButtonURL?.slug}
      initialValues={{
        amount: urlAmount || defaultAmount,
        interval: urlInterval || "ONCE",
      }}
      paymentOptions={{
        HALFYEARLY: getAmounts(donationBoxData?.elements?.amounts_half_yearly?.value),
        MONTHLY: getAmounts(donationBoxData?.elements?.amounts_monthly?.value),
        ONCE: getAmounts(donationBoxData?.elements?.amounts_once?.value),
        QUARTERLY: getAmounts(donationBoxData?.elements?.amounts_quarterly?.value),
        YEARLY: getAmounts(donationBoxData?.elements?.amounts_yearly?.value),
        ZERO: getAmounts(donationBoxData?.elements?.amount_zero?.value),
        ZERORECURRENT: getAmounts(donationBoxData?.elements?.amount_zero_recurrant?.value),
        oneTimePaymentProvider:
          siteSettingsData?.settings?.elements?.payment_provider_onetime?.value,
        recurrentPaymentProvider:
          siteSettingsData?.settings?.elements?.payment_provider_recurrent?.value,
      }}
      contactLink={siteSettingsData?.settings?.elements?.contact_page?.value.length > 0
        ? siteSettingsData.settings.elements.contact_page.value[0].id
        : null}
      title={(title || title === null) ? title : donationBoxData?.elements?.title?.value}
      text={donationBoxData?.elements?.info?.value}
      buttonText={buttonText || donationBoxData?.elements?.button_text?.value}
      buttons={[
        donationBoxData?.elements?.button_text?.value
          ? (
            <Button
              href={
            donationBoxData?.elements.button_url?.value.length > 0
              ? donationBoxData?.elements?.button_url?.value[0].id
              : donationBoxData?.elements?.button_url_extern?.value
          }
              variant="secondary"
            >
              {donationBoxData.elements.button_text.value}
            </Button>
          ) : null,
        donationBoxData?.elements?.secondary_button_text?.value
          ? (
            <Button
              href={
                donationBoxData?.elements?.secondary_button_url?.value.length > 0
                  ? donationBoxData?.elements?.secondary_button_url?.value[0].id
                  : donationBoxData?.elements?.secondary_button_url_extern?.value
              }
              variant="secondary"
            >
              {donationBoxData.elements.secondary_button_text.value}
            </Button>
          ) : null,
      ]}
      provider={
        provider ?? (donationBoxData?.elements?.payment_providers?.value?.length > 0
          ? KontentItemProvider({ data: donationBoxData.elements.payment_providers })
          : null)
      }
    />
  );
};

DonationBox.propTypes = {
  ...ComponentDonationBox.propTypes,
  buttonText: PropTypes.node,
  buttonURL: PropTypes.string,
  initialValues: PropTypes.shape({
    amount: PropTypes.number,
    interval: PropTypes.string,
  }),
  provider: PropTypes.oneOfType([PropTypes.array]),
  title: PropTypes.string,
};

DonationBox.defaultProps = {
  ...ComponentDonationBox.defaultProps,
  buttonText: undefined,
  buttonURL: undefined,
  initialValues: undefined,
  provider: undefined,
  title: undefined,
};

export default DonationBox;
