import React from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import SimpleRichText from "../../../templates/text/simpleRichText";

import "./sectionHeader.scss";

const FormSectionHeader = (props) => {
  const {
    headline,
    description,
    subheadline,
  } = props;

  const intl = useIntl();

  return (
    <div className={classNames({
      "my-30": !subheadline,
      "section-description": !headline,
    })}
    >
      {headline && (
        <>
          {subheadline
            ? <p><strong><FormattedMessage id={headline} /></strong></p>
            : <h3 className="section-header">{intl.formatMessage({ id: headline })}</h3>}
        </>
      )}
      {description && (
        <SimpleRichText data={{ value: intl.formatMessage({ id: description }) }} />
      )}
    </div>
  );
};

FormSectionHeader.propTypes = {
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.bool,
};

FormSectionHeader.defaultProps = {
  subheadline: false,
};

export default FormSectionHeader;
