import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import IconButton from "./iconButton";
import { IconMail24, IconPhone24, IconWebForm24 } from "../../icons";

const ContactIconButton = (props) => {
  const {
    children,
    href,
    mailto,
    phone,
    contactName,
    ...buttonProps
  } = props;

  const intl = useIntl();

  if (!mailto && !phone && !href) {
    return null;
  }

  let iconButtonProps;
  let icon = null;

  if (href) {
    iconButtonProps = {
      href,
      title: intl.formatMessage({ id: "contact.button.title.link" }),
    };

    icon = <IconWebForm24 />;
  } else if (mailto) {
    iconButtonProps = {
      href: `mailto:${mailto}`,
      title: intl.formatMessage({ id: "contact.button.title.mailto" }, { contactName }),
    };

    icon = <IconMail24 />;
  } else if (phone) {
    iconButtonProps = {
      href: `tel:${phone.replace(/[^0-9]/g, "")}`,
      title: intl.formatMessage({ id: "contact.button.title.phone" }, { contactName }),
    };

    icon = <IconPhone24 />;
  }

  return (
    <IconButton
      {...iconButtonProps}
      {...buttonProps}
    >
      {children}{icon}
    </IconButton>
  );
};

ContactIconButton.propTypes = {
  children: PropTypes.node.isRequired,
  contactName: PropTypes.string,
  href: PropTypes.string,
  mailto: PropTypes.string,
  phone: PropTypes.string,
};

ContactIconButton.defaultProps = {
  contactName: null,
  href: null,
  mailto: null,
  phone: null,
};

export default ContactIconButton;
