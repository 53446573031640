import {
  VirtualDriveSetupModalDriveHeadInfo,
  VirtualDriveSetupModalDriveMotivation,
  VirtualDriveSetupModalDriveType,
  VirtualDriveSetupModalDriveTypeCl,
  VirtualDriveSetupModalDriveTypeDe,
  VirtualDriveSetupModalDriveTypeIn,
  VirtualDriveSetupModalDriveTypeInformation,
  VirtualDriveSetupModalDriveTypeInformationIn,
  VirtualDriveSetupModalDriveTypePl,
  VirtualDriveSetupModalDriveTypeUs,
  VirtualDriveSetupModalPersonalInformation,
  VirtualDriveSetupModalRelationShipToPatient,
  VirtualDriveSetupModalSuccess,
  VirtualDriveSetupOverview,
} from "./steps";

const getVirtualDriveSetupSteps = () => {
  let steps = [];

  switch (process.env.GATSBY_SITE) {
    case "gb":
      steps = [
        VirtualDriveSetupModalDriveMotivation,
        VirtualDriveSetupModalRelationShipToPatient,
        VirtualDriveSetupModalDriveType,
        VirtualDriveSetupModalDriveHeadInfo,
        VirtualDriveSetupModalDriveTypeInformation,
        VirtualDriveSetupModalPersonalInformation,
        VirtualDriveSetupOverview,
        VirtualDriveSetupModalSuccess,
      ];
      break;
    case "de":
      steps = [
        VirtualDriveSetupModalDriveMotivation,
        VirtualDriveSetupModalRelationShipToPatient,
        VirtualDriveSetupModalDriveTypeDe,
        VirtualDriveSetupModalDriveHeadInfo,
        VirtualDriveSetupModalDriveTypeInformation,
        VirtualDriveSetupModalPersonalInformation,
        VirtualDriveSetupOverview,
        VirtualDriveSetupModalSuccess,
      ];
      break;
    case "us":
      steps = [
        VirtualDriveSetupModalDriveMotivation,
        VirtualDriveSetupModalRelationShipToPatient,
        VirtualDriveSetupModalDriveTypeUs,
        VirtualDriveSetupModalDriveHeadInfo,
        VirtualDriveSetupModalDriveTypeInformation,
        VirtualDriveSetupModalPersonalInformation,
        VirtualDriveSetupOverview,
        VirtualDriveSetupModalSuccess,
      ];
      break;
    case "pl":
      steps = [
        VirtualDriveSetupModalDriveMotivation,
        VirtualDriveSetupModalRelationShipToPatient,
        VirtualDriveSetupModalDriveTypePl,
        VirtualDriveSetupModalDriveHeadInfo,
        VirtualDriveSetupModalDriveTypeInformation,
        VirtualDriveSetupModalPersonalInformation,
        VirtualDriveSetupOverview,
        VirtualDriveSetupModalSuccess,
      ];
      break;
    case "cl":
      steps = [
        VirtualDriveSetupModalDriveMotivation,
        VirtualDriveSetupModalRelationShipToPatient,
        VirtualDriveSetupModalDriveTypeCl,
        VirtualDriveSetupModalDriveHeadInfo,
        VirtualDriveSetupModalDriveTypeInformation,
        VirtualDriveSetupModalPersonalInformation,
        VirtualDriveSetupOverview,
        VirtualDriveSetupModalSuccess,
      ];
      break;
    case "in":
      steps = [
        VirtualDriveSetupModalDriveMotivation,
        VirtualDriveSetupModalRelationShipToPatient,
        VirtualDriveSetupModalDriveTypeIn,
        VirtualDriveSetupModalDriveHeadInfo,
        VirtualDriveSetupModalDriveTypeInformationIn,
        VirtualDriveSetupModalPersonalInformation,
        VirtualDriveSetupOverview,
        VirtualDriveSetupModalSuccess,
      ];
      break;
    case "za":
      steps = [
        VirtualDriveSetupModalDriveMotivation,
        VirtualDriveSetupModalRelationShipToPatient,
        VirtualDriveSetupModalDriveType,
        VirtualDriveSetupModalDriveHeadInfo,
        VirtualDriveSetupModalDriveTypeInformation,
        VirtualDriveSetupModalPersonalInformation,
        VirtualDriveSetupOverview,
        VirtualDriveSetupModalSuccess,
      ];
      break;
    default:
  }

  return steps;
};

export default getVirtualDriveSetupSteps;
