import React, { memo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import BrowserDetection from "../browserDetection";
import NavigationLink from "../navigationLink/navigationLink";

import "./takeActionCard.scss";

const TakeActionCard = (props) => {
  const {
    button,
    href,
    icon,
    image,
    label,
    description,
  } = props;

  const isApple = BrowserDetection.isApple();

  return (
    <NavigationLink href={href} className={classNames("no-style takeactioncard", image ? "takeactioncard--background" : "takeactioncard--border")}>
      {image && (
        <div className={classNames("takeactioncard__image", { "takeactioncard__image--zindex": isApple })}>
          {image}
        </div>
      )}
      {!image && (
        <div className="takeactioncard__headline">
          {icon}
        </div>
      )}
      <div className="takeactioncard__subline">
        <div className="w-75">
          <div className="takeactioncard__subline-label">{label}</div>
          {description && (
            <div className="takeactioncard__subline-description">{description}</div>
          )}
        </div>
        <div className="w-auto takeactioncard__button">
          {button}
        </div>
      </div>
    </NavigationLink>
  );
};

TakeActionCard.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]),
  description: PropTypes.string,
  href: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]),
  image: PropTypes.oneOfType([PropTypes.object]),
  label: PropTypes.string.isRequired,
};

TakeActionCard.defaultProps = {
  button: null,
  description: null,
  icon: null,
  image: null,
};

export default memo(TakeActionCard);
