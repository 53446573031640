import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useEventListener from "../../hooks/haiku/useEventListener";
import { IconAccordionExpand18, IconAccordionMinimize18 } from "../../icons";
import IconButton from "../button/iconButton";

import "./hubsection.scss";

const Hubsection = ({ children, variant }) => {
  const referencedChild = useRef(null);

  const [page, setPage] = useState(0);
  const [childHeight, setChildHeight] = useState(undefined);

  const numberOfTiles = 6;
  const initialNumberOfTiles = ["campaign", "take-action"].includes(variant) ? 5 : 6;
  const visibleTiles = page * numberOfTiles + initialNumberOfTiles;

  const childrenArray = React.Children.toArray(children).slice(0, visibleTiles);
  const childrenLength = childrenArray.length;
  const childrenTotalLength = React.Children.toArray(children).length;

  const resize = () => {
    if (typeof window === "undefined" || referencedChild?.current === null) {
      return;
    }

    setChildHeight(window.getComputedStyle(referencedChild.current).height);
  };

  useEffect(() => {
    resize();
  }, [referencedChild]);

  useEventListener("resize", resize);

  const setReferences = (reference, index) => {
    if (index === 1) {
      referencedChild.current = reference;
    }
  };

  const getColProps = (index) => {
    const props = {
      lg: 12,
      sm: 12,
      style: {},
      xs: 12,
    };

    if (childrenLength === 1) {
      return props;
    }

    switch (variant) {
      case "take-action":
      case "campaign":
        if (index === 0) {
          props.lg = 8;
          props.style = { height: childHeight };
          break;
        }

        props.sm = 6;
        props.lg = 4;
        break;

      case "program":
        props.sm = 6;
        props.lg = 4;
        break;

      case "target-group":
        props.lg = 6;
        break;

      default:
    }

    return props;
  };

  return (
    <div className={classNames("hubsection", `hubsection--${variant}`)}>
      <Row className="hubsection__items align-items-start">
        {childrenArray.map((child, index) => (
          <Col
            key={child.props.key}
            ref={(reference) => setReferences(reference, index)}
            className="hubsection__item"
            {...getColProps(index)}
          >
            {child}
          </Col>
        ))}
      </Row>
      {numberOfTiles < childrenTotalLength && (
        <div className="hubsection__button-container">
          {visibleTiles < childrenTotalLength ? (
            <IconButton
              onClick={(event) => {
                setPage((prev) => prev + 1);

                event.currentTarget.blur();
              }}
              variant="inverted-registration"
            >
              <FormattedMessage id="hubsection.show_more" />
              <IconAccordionExpand18 />
            </IconButton>
          ) : (
            <IconButton
              onClick={(event) => {
                setPage(0);

                event.currentTarget.blur();
              }}
              variant="inverted-registration"
            >
              <FormattedMessage id="hubsection.show_less" />
              <IconAccordionMinimize18 />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
};

Hubsection.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["take-action", "campaign", "target-group", "program"]),
};

Hubsection.defaultProps = {
  variant: "take-action",
};

export default Hubsection;
