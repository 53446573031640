const loadScript = (src) => {
  if (typeof window !== "undefined") {
    if (typeof window.scriptsLoaded === "undefined") {
      window.scriptsLoaded = [];
    } else if (window.scriptsLoaded.indexOf(src) >= 0) {
      return Promise.resolve(src);
    }
  }

  window.scriptsLoaded.push(src);

  return new Promise((resolve, reject) => {
    const body = document.getElementsByTagName("body")[0];
    const tag = document.createElement("script");

    const handleLoad = () => {
      resolve(src);
    };

    const handleReject = () => {
      reject(src);
    };

    tag.type = "text/javascript";
    tag.async = false;

    tag.onreadystatechange = () => {
      const state = tag.readyState;

      if (state === "complete") {
        handleLoad();
      } else if (state === "error") {
        handleReject();
      }

      return true;
    };

    tag.addEventListener("load", handleLoad);
    tag.addEventListener("error", handleReject);

    tag.src = src;
    body.appendChild(tag);

    return tag;
  });
};

export default loadScript;
