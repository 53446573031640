import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import DkmsNumbers from "../../components/dkmsNumbers/dkmsNumbers";
import useLanguage from "../../hooks/useLanguage";
import iconHelper from "../../utils/iconHelper";

const KontentItemGlobalDkmsFigures = ({ data }) => {
  const collection = useStaticQuery(graphql`
    {
      allKontentItemGlobalDkmsFigures {
        nodes {
          id
          system {
            codename
            id
            last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
          }
          elements {
            country {
              value {
                name
                codename
              }
            }
            year_founded {
              value
            }
            bone_marrow_donations {
              value
              name
            }
            registered_donors {
              value
            }
            headline_en {
              value
            }
            headline_de {
              value
            }
            headline_es {
              value
            }
            headline_pl {
              value
            }
          }
        }
      }
    }
  `);

  if (collection.allKontentItemGlobalDkmsFigures) {
    const figures = collection.allKontentItemGlobalDkmsFigures.nodes;

    const item = figures.find((el) => el.system.codename === data.codename);

    if (item) {
      const { languageCode } = useLanguage();

      let headline;

      switch (languageCode) {
        case "de":
          headline = item.elements.headline_de.value;
          break;
        case "es":
          headline = item.elements.headline_es.value;
          break;
        case "pl":
          headline = item.elements.headline_pl.value;
          break;
        default:
          headline = item.elements.headline_en.value;
      }

      return (
        <DkmsNumbers
          yearFounded={item.elements.year_founded.value}
          registeredDonors={item.elements.registered_donors.value}
          steamCellDonations={item.elements.bone_marrow_donations.value}
          icon={iconHelper(item.elements.country.value[0].codename)}
          headline={headline}
          key={item.id}
        />
      );
    }
  }

  return null;
};

KontentItemGlobalDkmsFigures.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemGlobalDkmsFigures;
