import React from "react";

import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import iconHelper from "../../utils/iconHelper";
import KontentAssetsImage from "../kontentAssetsImage";
import SimpleRichText from "../text/simpleRichText";

const KontentItemProvider = ({ data }) => {
  const providerList = data.value;

  if (!providerList.length > 0) {
    return null;
  }

  const intl = useIntl();

  const providers = [];

  providerList.forEach((provider) => {
    const impacts = [];

    for (let i = 1; i <= 3; i += 1) {
      if (provider.elements?.[`amount_${i}`]?.value) {
        impacts.push(provider.elements[`amount_${i}`].value);
      }
    }

    providers.push({
      details: provider.elements.details.value.trim() !== "<p><br></p>"
        ? <SimpleRichText data={provider.elements.details} />
        : null,
      externButtonText: provider.elements.name.value.toLowerCase() === "zapper"
        ? intl.formatMessage({ id: "donation_box.get_app" }, { name: provider.elements.name.value })
        : `${intl.formatMessage({ id: "donation_box.donate_via" })} ${provider.elements.name.value}`,
      icon: iconHelper(provider.elements.name.value),
      impacts,
      logo: provider.elements.logo.value.length > 0
        ? (
          <KontentAssetsImage
            data={provider.elements.logo.value[0]}
            height={36}
          />
        )
        : null,
      name: provider.elements.name.value,
      urlExtern: provider.elements.url_extern?.value,
      urlQr: provider.elements.url_qr?.value,
    });
  });

  return providers;
};

export const query = graphql`
  fragment KontentItemProvider on kontent_item_payment_provider {
    id
    system {
      codename
    }
    elements {
      amount_1 {
        value
      }
      amount_2 {
        value
      }
      amount_3 {
        value
      }
      name {
        value
      }
      url_extern {
        value
      }
      url_qr {
        value
      }
      variant {
        value {
          codename
          name
        }
      }
      logo {
        value {
          description
          height
          size
          name
          type
          url
          width
        }
      }
      details {
        value
        images {
          width
          url
          image_id
          height
          description
        }
        links {
          link_id
          codename
          type
          url_slug
        }
        type
        name
        modular_content {
          id
          system {
            codename
          }
          internal {
            type
          }
          ...KontentItemImage
          ...KontentItemCtaButton
        }
      }
    }
  }
`;

KontentItemProvider.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemProvider;
