import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

import { IconClose18 } from "../../icons";

import "./topBanner.scss";

const TopBanner = (props) => {
  const {
    headline,
    headlinePreIcon,
    text,
    action1,
    action2,
    onClose,
    registration,
  } = props;

  const [open, setOpen] = useState(true);

  const missingProps = !text && !action1 && !action2 && !headlinePreIcon && !registration;

  return (
    <CSSTransition
      in={open}
      classNames="slide"
      timeout={500}
    >
      <div className={classNames("container--fullwidth top-banner", { "top-banner--registration": registration })}>
        <div className={registration ? "container-fluid" : "container"}>
          {missingProps ? (
            <div className="text-center typo-label">{headline}</div>
          ) : (
            <div className="d-md-flex justify-content-md-between">
              <div className="d-flex justify-content-between mr-md-48">
                <div>
                  <div className={classNames(
                    "d-flex typo-label align-items-center",
                    registration ? "text-primary" : "text-faq",
                  )}
                  >
                    {!registration && headlinePreIcon && (
                      <div className="mr-12 d-flex"> {headlinePreIcon}</div>
                    )}
                    {headline}
                  </div>
                  <div className="mt-9 typo-body1 text-black-variant">
                    {text}
                  </div>
                </div>
                <div className="text-grey-dark d-md-none">
                  <IconClose18 onClick={(event) => {
                    onClose(event);
                    setOpen(false);
                  }}
                  />
                </div>
              </div>
              <div className="mt-24 mt-md-0 d-flex align-items-center">
                {action1}
                {action2 && <div className="ml-12"> {action2}</div>}
                <IconClose18
                  className="text-grey-dark d-none d-md-block ml-36"
                  onClick={(event) => {
                    onClose(event);
                    setOpen(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </CSSTransition>
  );
};

TopBanner.propTypes = {
  action1: PropTypes.oneOfType([PropTypes.object]),
  action2: PropTypes.oneOfType([PropTypes.object]),
  headline: PropTypes.string.isRequired,
  headlinePreIcon: PropTypes.oneOfType([PropTypes.object]),
  onClose: PropTypes.func,
  registration: PropTypes.bool,
  text: PropTypes.string,
};

TopBanner.defaultProps = {
  action1: null,
  action2: null,
  headlinePreIcon: null,
  onClose: () => {},
  registration: false,
  text: null,
};

export default TopBanner;
