import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import NewsHeroCarouselCards from "./news/newsHeroCarouselCards";
import HeroCarousel from "../components/heroCarousel/heroCarousel";

const KontentItemHeroCarouselNews = ({ data }) => {
  const heroCarouselNews = data.value ? data.value[0] : data;

  if (!heroCarouselNews || !heroCarouselNews.elements) {
    return null;
  }

  return (
    <HeroCarousel notOverwriteButton>
      {NewsHeroCarouselCards({
        buttonText: heroCarouselNews.elements.button_text.value,
        data: heroCarouselNews.elements.carousel_news,
      })}
    </HeroCarousel>
  );
};

export const query = graphql`
  fragment KontentItemHeroCarouselNews on kontent_item_hero_carousel_news {
    id
    system {
      codename
    }
    elements {
      carousel_news {
        value {
          ...NewsHeroCarouselCards
          ...NewsHeroCarouselCardsMigrated
        }
      }
      button_text {
        value
      }
    }
  } 
`;

KontentItemHeroCarouselNews.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemHeroCarouselNews;
