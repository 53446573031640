import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import VideoEmbeds from "./VideoEmbeds";
import AssetsSection from "../components/assetsSection/assetsSection";

const KontentItemVideoLibrarySection = ({ data }) => {
  const videoLibrarySection = data.value ? data.value[0] : data;

  if (!videoLibrarySection || !videoLibrarySection.elements) {
    return null;
  }

  return (
    <AssetsSection
      headline={videoLibrarySection.elements.headline.value}
      description={videoLibrarySection.elements.description.value}
      variant="virtual-drive-video-embed"
    >
      {VideoEmbeds({ data: videoLibrarySection.elements.videos })}
    </AssetsSection>
  );
};

export const query = graphql`
  fragment KontentItemVideoLibrarySection on kontent_item_video_library_section {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      description {
        value
      }
      videos {
        value {
          ...VideoEmbed
        }
      }
    }
  } 
`;

KontentItemVideoLibrarySection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemVideoLibrarySection;
