import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";

import ArticleClosure from "../../components/articleClosure/articleClosure";
import HeroCampaign from "../../components/heroHeadline/heroCampaign";
import InfoSection from "../../components/infoSection/infoSection";
import SEO from "../../components/seo";
import StemCellBankLogo from "../../components/stemCellBankLogo";
import { setTracking } from "../../features/trackingSlice";
import useContentABTest from "../../hooks/useContentABTest";
import mapKontentItem from "../../utils/mapKontentItem";
import MainLayout from "../layout/mainLayout";
import DonationBoxHeader from "../moneyDonation/donationBoxHeader";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import RichText from "../text/richText";

const KontentItemLandingPage = ({ data, pageContext }) => {
  if (data.variant?.elements?.variant?.value?.length > 0 && typeof window === "undefined") {
    return null;
  }

  const page = useContentABTest(data.page, data.variant);

  const dispatch = useDispatch();

  dispatch(
    setTracking({
      URN: data.page.elements.urn_primary.value,
      secondaryURN: data.page.elements.urn_secondary.value,
    }),
  );

  const disableShareButtons = page.elements.disable_sharing.value.length > 0;

  return (
    <MainLayout
      hideNavigation={data.page.elements.hide_navigation.value.length > 0}
      headerLogo={
        data.page.elements.hide_navigation.value.length > 0
        && data.page.elements.use_scb_logo.value.length > 0
          ? <StemCellBankLogo />
          : null
      }
    >

      <SEO
        title={data.page.elements.metadata__meta_title.value}
        description={data.page.elements.metadata__meta_description.value}
        twitterTitle={data.page.elements.metadata__twitter_title.value}
        twitterSite={data.page.elements.metadata__twitter_site.value}
        twitterImage={data.page.elements.metadata__twitter_image.value}
        twitterDescription={data.page.elements.metadata__twitter_description.value}
        twitterCreator={data.page.elements.metadata__twitter_creator.value}
        ogTitle={data.page.elements.metadata__og_title.value}
        ogImage={data.page.elements.metadata__og_image.value}
        ogDescription={data.page.elements.metadata__og_description.value}
        isHiddenForRobots={pageContext.seoNoIndex}
      />
      {
        page.elements.fundraising_box.value.length > 0
          ? (
            <DonationBoxHeader
              backgroundImage={page.elements.hero_image}
              donationBoxData={page.elements.fundraising_box.value}
            />
          )
          : (
            <HeroCampaign
              eyecatcher={
                page.elements.hero_eyecatcher.value.length > 0
                  ? mapKontentItem(page.elements.hero_eyecatcher.value[0])
                  : null
              }
              imageData={page.elements.hero_image}
              disableShareButtons
            >
              {page.elements.hero_headline.value}
            </HeroCampaign>
          )
      }
      <BreadcrumbGenerator id={page.id} />

      <InfoSection
        hideLastUpdateLabel
        showTableOfContents={page.elements.table_of_content?.value[0]?.codename !== "disable"}
        date={page.elements.publishdate.value && new Date(page.elements.publishdate.value)}
        disableShareButtons={disableShareButtons}
      />

      <RichText data={page.elements.body} isArticle />

      <Row className="pb-15">
        <Col md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
          <ArticleClosure disableShareButtons={disableShareButtons} className="pt-0" />
        </Col>
      </Row>

      {page.elements.page_sections.value.map(
        (item) => mapKontentItem(item),
      )}

      {page.elements.card_slider.value.map(
        (item) => mapKontentItem(item, { pageId: page.id }),
      )}

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemLandingPage(id: {eq: $id}) {
      id
      system {
        last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
      }
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        hero_eyecatcher {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
          }
        }
        fundraising_box {
          value {
            ...KontentItemDonationBox
          }
        }
        card_slider {
          value {
            internal {
              type
            }
            ...KontentItemIconCardSliderSection
            ...KontentItemImageCardSliderSection
          }
        }
        urn_primary {
          value
        }
        urn_secondary {
          value
        }
        disable_sharing {
          value {
            name
          }
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemCtaButton
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemStatementboxGroup
            ...KontentItemEmbedExternal
          }
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemRegistryBanner
            ...KontentItemStatementboxGroup
          }
        }
        hide_navigation {
          value {
            codename
          }
        }
        meta_robots__noindex {
          value {
            codename
          }
        }
        use_scb_logo {
          value {
            codename
          }
        }
        table_of_content{
          value {
            codename
          }
        }
        publishdate {
          value(formatString: "YYYY-MM-DD[T]HH:mm:ss")
        }
      }
    }
    variant: kontentItemABTest(elements: {main: {value: {elemMatch: {id: {eq: $id}}}}}) {
      elements {
        start_date {
          value
        }
        end_date {
          value
        }
        experiment_name {
          value
        }
        variant {
          value {
            ... on kontent_item_landing_page {
              id
              elements {
                hero_headline {
                  value
                }
                hero_image {
                  value {
                    ...KontentItemImage
                  }
                }
                hero_eyecatcher {
                  value {
                    internal {
                      type
                    }
                    ...KontentItemEyecatcher
                    ...KontentItemEyecatcherRegistration
                    ...KontentItemEyecatcherDonation
                    ...KontentItemEyecatcherDonate
                    ...KontentItemEyecatcherContact
                  }
                }
                fundraising_box {
                  value {
                    ...KontentItemDonationBox
                  }
                }
                card_slider {
                  value {
                    internal {
                      type
                    }
                    ...KontentItemIconCardSliderSection
                    ...KontentItemImageCardSliderSection
                  }
                }
                disable_sharing {
                  value {
                    name
                  }
                }
                body {
                  value
                  images {
                    width
                    url
                    image_id
                    height
                    description
                  }
                  links {
                    link_id
                    codename
                    type
                    url_slug
                  }
                  type
                  name
                  modular_content {
                    id
                    system {
                      codename
                    }
                    internal {
                      type
                    }
                    ...KontentItemLinkCard
                    ...KontentItemImage
                    ...KontentItemVideoEmbed
                    ...KontentItemLinkCardGroup
                    ...KontentItemQuote
                    ...KontentItemAccordion
                    ...KontentItemImageGrid
                    ...KontentItemMediaGallery
                    ...KontentItemArticlesEmbed
                    ...KontentItemRelatedLearnContentEmbed
                    ...KontentItemEyecatcher
                    ...KontentItemEyecatcherRegistration
                    ...KontentItemEyecatcherDonation
                    ...KontentItemEyecatcherDonate
                    ...KontentItemEyecatcherContact
                    ...KontentItemDownloadGroup
                    ...KontentItemFaqSection
                    ...KontentItemImageCardsGroup
                    ...KontentItemCtaButton
                    ...KontentItemEmbedFacebookPost
                    ...KontentItemEmbedInstagramPost
                    ...KontentItemTweet
                    ...KontentItemStatementboxGroup
                    ...KontentItemEmbedExternal
                  }
                }
                page_sections {
                  value {
                    internal {
                      type
                    }
                    ...KontentItemRelatedLearnContentEmbed
                    ...KontentItemRegistryBanner
                    ...KontentItemStatementboxGroup
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

KontentItemLandingPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLandingPage;
