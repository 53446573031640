import React from "react";

import classNames from "classnames";
import { graphql, StaticQuery } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Nav from "react-bootstrap/Nav";

import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import { IconTargetGroups18 } from "../../icons";
import iconHelper from "../../utils/iconHelper";
import NavigationLink from "../navigationLink/navigationLink";
import StateAwareAccordionToggle from "../stateAwareAccordionToggle/stateAwareAccordionToggle";

const StaticQueryMobileNavigation = ({ data }) => {
  const isLowerLg = useMediaQuery("(max-width: 991px)");

  return (
    <>
      <div className="burger-navigation-primary-accordion-items">
        {data.primaryNavigation.elements.elements.value.map((item, index) => (
          <React.Fragment key={item.elements.title.value}>
            <div className="burger-navigation-primary-accordion-item">
              <StateAwareAccordionToggle eventKey={`menu${index}`}>
                <span
                  className={classNames(
                    "typo-title3",
                    { "text-primary": window && window.location.href.indexOf(item.elements.url_slug.value) !== -1 },
                  )}
                >
                  {item.elements.title.value}
                </span>
              </StateAwareAccordionToggle>
              <Accordion.Collapse eventKey={`menu${index}`} className="burger-navigation__accordion-collapse">
                <>
                  <NavigationLink
                    href={item.elements.url_slug.value}
                    key={item.id}
                    className="nav-link d-flex justify-content-between align-items-center"
                    partiallyActive={false}
                  >
                    <FormattedMessage id="navigation.menu.overview" />
                  </NavigationLink>
                  {item.elements.elements.value.filter(
                    (item2) => item2.id,
                  ).map((link) => (
                    <NavigationLink
                      {...link}
                      href={link.elements.external_url.value || `${item.elements.url_slug.value}/${link.elements.url_slug.value}`}
                      key={link.id}
                      className="nav-link"
                    />
                  ))}
                </>
              </Accordion.Collapse>
            </div>
          </React.Fragment>
        ))}
      </div>

      {isLowerLg && <div className="divider first" />}

      {data.secondaryNavigation.elements.elements.value.filter(
        (item) => item.id,
      ).map((link) => (
        <NavigationLink
          {...link}
          href={link.elements.external_url.value || `${link.elements.url_slug.value}`}
          key={link.id}
          className="nav-link burger-navigation__nav-direct-link"
        />
      ))}
      {data.infoNavigation.elements?.title?.value && (
        <div className="burger-navigation-secondary-accordion-item">
          <StateAwareAccordionToggle eventKey="menuinfonavigation1">
            <Nav.Item className="burger-navigation__item-secondary">
              {iconHelper(data.infoNavigation.elements.icon.value[0]?.codename)
                || <IconTargetGroups18 />}
              {data.infoNavigation.elements.title.value}
            </Nav.Item>
          </StateAwareAccordionToggle>
          <Accordion.Collapse eventKey="menuinfonavigation1" className="burger-navigation__accordion-collapse">
            <>
              {data.infoNavigation.elements.elements.value.filter(
                (item) => item.id,
              ).map((link) => (
                <NavigationLink
                  {...link}
                  href={link.elements.external_url.value || `${link.elements.url_slug.value}`}
                  key={link.id}
                  className="nav-link"
                />
              ))}
            </>
          </Accordion.Collapse>
        </div>
      )}
      {data?.infoNavigation2?.elements?.title?.value && (
        <div className="burger-navigation-secondary-accordion-item">
          <StateAwareAccordionToggle eventKey="menuinfonavigation2">
            <Nav.Item className="burger-navigation__item-secondary">
              {iconHelper(data.infoNavigation2.elements.icon.value[0]?.codename)
                || <IconTargetGroups18 />}
              {data.infoNavigation2.elements.title.value}
            </Nav.Item>
          </StateAwareAccordionToggle>
          <Accordion.Collapse eventKey="menuinfonavigation2" className="burger-navigation__accordion-collapse">
            <>
              {data.infoNavigation2.elements.elements.value.filter(
                (item) => item.id,
              ).map((link) => (
                <NavigationLink
                  {...link}
                  href={link.elements.external_url.value || `${link.elements.url_slug.value}`}
                  key={link.id}
                  className="nav-link"
                />
              ))}
            </>
          </Accordion.Collapse>
        </div>
      )}
      {isLowerLg && <div className="divider last" />}
    </>
  );
};

StaticQueryMobileNavigation.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const MobileNavigation = (props) => (
  <StaticQuery
    query={graphql`
        {
          primaryNavigation: kontentItemNavigationItem(system: {codename: {eq: "primary_navigation"}}) {
            id
            elements {
              elements {
                value {
                  id
                  preferred_language
                  ... on kontent_item_navigation_item {
                    id,
                    elements {
                      title {
                        value
                      }
                      url_slug {
                        value
                      }
                      external_url {
                        value
                      }
                      elements {
                        value {
                          ... on kontent_item_navigation_item {
                            id
                            elements {
                              title {
                                value
                              }
                              url_slug {
                                value
                              }
                              external_url {
                                value
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          secondaryNavigation: kontentItemNavigationItem(system: {codename: {eq: "secondary_navigation"}}) {
            id
            elements {
              elements {
                value {
                  id
                  preferred_language
                  ... on kontent_item_navigation_item {
                    id,
                    elements {
                      title {
                        value
                      }
                      url_slug {
                        value
                      }
                      external_url {
                        value
                      }
                      icon {
                        value {
                          codename
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          infoNavigation: kontentItemNavigationItem(system: {codename: {eq: "info_navigation"}}) {
            id
            elements {
              title {
                value
              }
              icon {
                value {
                  codename
                }
              }
              elements {
                value {
                  id
                  typename: __typename
                  preferred_language
                  ... on kontent_item_navigation_item {
                    id
                    elements {
                      title {
                        value
                      }
                      url_slug {
                        value
                      }
                      external_url {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
          infoNavigation2: kontentItemNavigationItem(system: {codename: {eq: "info_navigation_2"}}) {
            id
            elements {
              title {
                value
              }
              icon {
                value {
                  codename
                }
              }
              elements {
                value {
                  id
                  typename: __typename
                  preferred_language
                  ... on kontent_item_navigation_item {
                    id
                    elements {
                      title {
                        value
                      }
                      url_slug {
                        value
                      }
                      external_url {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
    render={(data) => <StaticQueryMobileNavigation data={data} {...props} />}
  />
);

export default MobileNavigation;
