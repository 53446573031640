import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import SEO from "../../components/seo";
import VirtualDriveList from "../../components/virtualDriveList/virtualDriveList";
import { useGetAllUrnCounterQuery } from "../../features/io/ioEventApiSlice";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import TabNavigationGenerator from "../virtualDrive/tabNavigationGenerator";
import VirtualDriveCards from "../virtualDrive/virtualDriveCards";

const KontentItemVdCurrentDrives = ({ data: { page, drives } }) => {
  const urnCounter = page.elements.disable_counter.value.length === 0
    ? useGetAllUrnCounterQuery()
    : { data: null };

  const highlightDrives = VirtualDriveCards({
    data: page.elements.highlight_drives.value,
    isHighlight: true,
    showCounter: page.elements.disable_counter.value.length === 0,
    virtualDriveCounter: urnCounter.data?.counters
    && page.elements.disable_counter.value.length === 0
      ? urnCounter.data.counters
      : null,
  });

  const highlightIds = page.elements.highlight_drives.value.map((item) => item.id);

  const normalDrives = VirtualDriveCards({
    data: drives.nodes.filter((item) => !highlightIds.includes(item.id)),
    showCounter: page.elements.disable_counter.value.length === 0,
    virtualDriveCounter: urnCounter.data?.counters
    && page.elements.disable_counter.value.length === 0
      ? urnCounter.data.counters
      : null,
  });

  const allDrives = highlightDrives.concat(normalDrives);

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
        tabNavigation={<TabNavigationGenerator id={page.id} />}
      >
        {page.elements.hero_headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={page.id} />

      <VirtualDriveList>
        {allDrives}
      </VirtualDriveList>

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    drives: allKontentItemVirtualDrive(filter: {elements: {vd_type: {value: {elemMatch: {codename: {eq: "normal"}}}}}}, sort: {fields: elements___publishdate___value, order: DESC}) {
      nodes {
        ...VirtualDriveCards
      }
    }
    page: kontentItemVdCurrentDrives(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        hero_headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        highlight_drives {
          value {
            ...VirtualDriveCards
          }
        }
        disable_counter {
          value {
            codename
          }
        }
      }
    }
  }
`;

KontentItemVdCurrentDrives.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemVdCurrentDrives;
