import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useDispatch, useSelector } from "react-redux";

import { setShowRegistrationReminder } from "../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../icons";
import AdaptiveIcon from "../adaptiveIcon";
import IconButton from "../button/iconButton";
import TopBanner from "../topBanner/topBanner";

const RegistrationReminder = () => {
  const { registrationReminder, settings } = useStaticQuery(graphql`
    {
      settings: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          button_registration_url {
            value {
              id
            }
          }
        }
      }
      registrationReminder: kontentItemTopBannerRegistrationReminder {
        elements {
          text {
            value
          }
          headline {
            value
          }
          button_text {
            value
          }
        }
      }
    }
  `);

  if (!registrationReminder || !settings) {
    return null;
  }

  const showRegistrationReminder = useSelector(
    (state) => state.registration.showRegistrationReminder,
  );
  const dispatch = useDispatch();

  if (showRegistrationReminder) {
    return (
      <TopBanner
        registration
        headline={registrationReminder.elements.headline.value}
        headlinePreIcon={null}
        text={registrationReminder.elements.text.value}
        onClose={() => dispatch(setShowRegistrationReminder(false))}
        action1={(
          <IconButton
            variant="registration"
            href={
              settings.elements.button_registration_url.value.length > 0
                ? settings.elements.button_registration_url.value[0].id
                : null
            }
          >
            {registrationReminder.elements.button_text.value}
            <AdaptiveIcon
              sm={<IconFurther18 />}
              lg={<IconFurther24 />}
            />
          </IconButton>
        )}
      />
    );
  }

  return null;
};

export default RegistrationReminder;
