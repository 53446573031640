import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import AssetsHeader from "../../components/assetsHeader/assetsHeader";
import AssetsSection from "../../components/assetsSection/assetsSection";
import FilterDropdown from "../../components/filterDropown/filterDropdown";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemAsset from "../kontentItemAsset";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import FileDownloadCards from "../pressAndMediaAssets/FileDownloadCards";
import ImageDownloads from "../pressAndMediaAssets/ImageDownloads";
import PressVideoEmbeds from "../pressAndMediaAssets/PressVideoEmbeds";

const KontentItemPressMediaAndAssetsPage = ({ data }) => {
  const { page } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <AssetsHeader
        headline={page.elements.headline.value}
        description={page.elements.lead_text.value}
      >
        <KontentItemAsset data={page.elements.download.value.length > 0
          ? page.elements.download.value[0]
          : null}
        />
      </AssetsHeader>

      {data.images.nodes.length > 0 && (
        <FilterDropdown variant="assets" headline={page.elements.headline_images.value}>
          {ImageDownloads({ data: data.images.nodes, usePressFilter: true })}
        </FilterDropdown>
      )}

      {data.videos.nodes.length > 0 && (
        <AssetsSection headline={page.elements.headline_videos.value}>
          {PressVideoEmbeds({ data: data.videos.nodes })}
        </AssetsSection>
      )}

      <AssetsSection headline={page.elements.headline_downloads.value}>
        {FileDownloadCards({ data: page.elements.downloads.value })}
      </AssetsSection>

      {page.elements.contact.value.length > 0
        ? mapKontentItem(page.elements.contact.value[0])
        : null}

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    images: allKontentItemImage {
      nodes {
        ...ImageDownloads
      }
    }
    videos: allKontentItemVideoEmbed {
      nodes {
        ...PressVideoEmbed
      }
    }
    page: kontentItemPressMediaAndAssetsPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
         headline_downloads {
          value
        }
        headline_images {
          value
        }
        headline_videos {
          value
        }
        lead_text {
          value
        }
        download {
          value {
            ...KontentItemAsset
          }
        }
        downloads {
          value {
            ...FileDownloadCards
          }
        }
        contact {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherContact
            ...KontentItemEyecatcher
          }
        }
      }
    }
  }
`;

KontentItemPressMediaAndAssetsPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemPressMediaAndAssetsPage;
