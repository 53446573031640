import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { setMaxDonate, setPaymentData } from "../../../features/registrationSlice";
import { IconFurther18 } from "../../../icons";
import DonationBox from "../../../templates/moneyDonation/donationBox";
import DataLayer from "../../../utils/dataLayer";
import RegistrationDialogHeadline from "../../registrationDialog/registrationDialogHeadline";
import PaymentDataHelper from "../paymentDataHelper";

const PaymentDialogStepAmountAndInterval = ({ next }) => {
  const paymentData = useSelector((state) => state.registration.paymentData);
  const dispatch = useDispatch();
  const isSepa = useSelector((state) => state.registration.isSepa);

  return (
    <Row className="payment-dialog__interval">
      {!isSepa && (
        <Col sm={10}>
          <RegistrationDialogHeadline headline="payment_dialog.interval_title" />
        </Col>
      )}

      <Col sm={12}>
        <DonationBox
          className="bg-white p-0"
          title={null}
          variant="default"
          buttonText={(<><FormattedMessage id="payment_dialog.next" /> {process.env.GATSBY_SITE !== "de" && <IconFurther18 />}</>)}
          initialValues={{
            amount: paymentData?.amount,
            interval: paymentData?.interval,
          }}
          onSubmit={(values) => {
            dispatch(
              setPaymentData({
                amount: values.amount,
                interval: values.interval,
                isSepa: values.isSepa,
              }),
            );

            if (parseFloat(values.amount) >= PaymentDataHelper
              .maxAmount[process.env.GATSBY_SITE][values.interval]
            ) {
              dispatch(
                setMaxDonate(true),
              );
            } else {
              DataLayer.pushEvent("pp_donation_step_amount_and_interval", { event_value: "success" });
            }
            next();
          }}
        />
        {!isSepa && (
          <div className="footnote--required">
            <span className="required-sign" />
            <FormattedMessage id="registration.required_fields" />
          </div>
        )}
      </Col>
    </Row>

  );
};

PaymentDialogStepAmountAndInterval.stepName = "amount_and_interval";

PaymentDialogStepAmountAndInterval.propTypes = {
  next: PropTypes.func.isRequired,
};

export default PaymentDialogStepAmountAndInterval;
