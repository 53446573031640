import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemTargetGroupSection from "./kontentItemTargetGroupSection";
import RelatedProgramTiles from "./program/relatedProgramTiles";
import CardSlider from "../components/cardSlider/cardSlider";

const KontentItemProgramSliderSection = ({ data, pageId }) => {
  const programSliderSection = data.value ? data.value[0] : data;

  if (!programSliderSection || !programSliderSection.elements) {
    return null;
  }

  const categories = [];

  programSliderSection.elements.program_category.value.forEach((category) => {
    categories.push(category.codename);
  });

  return (
    <CardSlider
      header={programSliderSection.elements.title.value}
      description={programSliderSection.elements.description.value}
      related
      targetGroup={
        programSliderSection.elements.target_groups
          ? (
            <KontentItemTargetGroupSection
              data={programSliderSection.elements.target_groups}
            />
          )
          : null
      }
    >
      {RelatedProgramTiles({ categories, excludeId: pageId })}
    </CardSlider>
  );
};

export const query = graphql`
  fragment KontentItemProgramSliderSection on kontent_item_program_slider_section {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      description {
        value
      }
      target_groups {
        value {
          ...KontentItemTargetGroupSection
        }
      }
      program_category {
        value {
          codename
        }
      }
    }
  } 
`;

KontentItemProgramSliderSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageId: PropTypes.string,
};

KontentItemProgramSliderSection.defaultProps = {
  pageId: null,
};

export default KontentItemProgramSliderSection;
