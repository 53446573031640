import React from "react";

import classNames from "classnames";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconArticle18 } from "../../icons";
import IconText from "../iconText/iconText";
import NavigationLink from "../navigationLink/navigationLink";

import "./learnArticleEmbed.scss";

const LearnArticleEmbed = (props) => {
  const {
    headline,
    image,
    href,
    border,
  } = props;

  const intl = useIntl();

  return (
    <NavigationLink
      href={href}
      className={classNames("learn-article-embed", "no-style", { "learn-article-embed--border": border })}
    >
      <div className="learn-article-embed__image-container">
        <div className="learn-article-embed__image">{image}</div>
      </div>
      <div className="learn-article-embed__content">
        <IconText
          icon={<IconArticle18 />}
          variant="learn-area"
          text={intl.formatMessage({ id: "learn_article_embed.article" })}
        />
        <div className="learn-article-embed__headline">{headline}</div>
      </div>
    </NavigationLink>
  );
};

LearnArticleEmbed.propTypes = {
  border: PropTypes.bool,
  headline: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

LearnArticleEmbed.defaultProps = {
  border: false,
};

export default LearnArticleEmbed;
