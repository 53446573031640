/* eslint-disable sort-keys */
import { salutationGermany, titleGenderMapping } from "./general-form-data";
import { getOnPreventKeypress, RegexPatterns } from "../../dynamicForm/validators";
import { clean } from "../formBuilder";
import Validators from "../validations/validators";

const getDonateForm = (intl) => {
  const validators = new Validators(intl);

  return [
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "donate_header",
        headline: "donate_form.amount.title",
        description: "donate_form.amount.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "6",
        singleLine: true,
      },
      tagName: "TextInput",
      props: {
        id: "amount",
        name: "amount",
        label: intl.formatMessage({ id: "amount" }),
        type: "number",
        required: true,
        maxLength: 20,
      },
      validation: {
        type: "string",
        validators: [
        ],
      },
      tooltipMessage: null,
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "donate_person_header",
        headline: "donate_form.person.title",
        description: "donate_form.person.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: true,
      },
      tagName: "RadioInput",
      props: {
        id: "salutation",
        name: "salutation",
        label: intl.formatMessage({ id: "salutation" }),
        type: "text",
        isRequired: true,
      },
      options: salutationGermany(intl),
      validation: {
        type: "string",
        validators: [
          ...validators.get("", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "firstname",
        name: "firstname",
        label: intl.formatMessage({ id: "registration.personal.firstname" }),
        type: "text",
        required: true,
        maxLength: 40,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("firstname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "lastname",
        name: "lastname",
        label: intl.formatMessage({ id: "registration.personal.lastname" }),
        type: "text",
        required: true,
        maxLength: 100,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "8",
      },
      tagName: "TextInput",
      props: {
        id: "street",
        name: "street",
        label: intl.formatMessage({ id: "registration.address.street" }),
        type: "text",
        required: true,
        maxLength: 50,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("street", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "4",
      },
      tagName: "TextInput",
      props: {
        id: "house_no",
        name: "house_no",
        label: intl.formatMessage({ id: "registration.address.houseNo" }),
        type: "text",
        required: true,
        maxLength: 10,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("houseno", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "8",
      },
      tagName: "TextInput",
      props: {
        id: "city",
        name: "city",
        label: intl.formatMessage({ id: "registration.address.city" }),
        type: "text",
        required: true,
        maxLength: 50,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("city", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "4",
      },
      tagName: "TextInput",
      props: {
        id: "postcode",
        name: "postcode",
        label: intl.formatMessage({ id: "registration.address.postCode" }),
        type: "text",
        required: true,
        maxLength: 5,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("zipcode", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "TextInput",
      props: {
        id: "email",
        name: "email",
        label: intl.formatMessage({ id: "registration.additional.email" }),
        type: "text",
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("email", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "data_protection_header",
        headline: "dataprotection.title",
        description: "registration.dataprotection.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "CheckboxInput",
      props: {
        id: "privacy",
        name: "privacy",
        label: intl.formatMessage({ id: "registration.dataprotection.dataprotection" }),
        type: "checkbox",
        lg: true,
        required: true,
      },
      validation: {
        type: "bool",
        validators: [
          ...validators.get("requiredCheckbox", true),
        ],
      },
      initialValue: false,
    },
  ];
};

const generatePayload = (values) => {
  const payload = {
    person: {
      sex: titleGenderMapping[values.salutation],
      firstname: values.firstname,
      lastname: values.lastname,
    },
    private_address: {
      street: values.street,
      houseno: values.house_no,
      zipcode: values.postcode,
      city: values.city,
    },
    private_contact: {
      email: values.email,
    },
    md_data: {
      amount: values.amount,
    },
  };

  return clean(payload);
};

export default { getDonateForm, generatePayload };
