import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ContentHeader from "../../components/contentHeader/contentHeader";
import DynamicForm from "../../components/dynamicForm/dynamicForm";
import { generateInitialFormValues } from "../../components/dynamicForm/dynamicFormUtils";
import SEO from "../../components/seo";
import KontentAssetsImage from "../kontentAssetsImage";
import MainLayout from "../layout/mainLayout";

const KontentItemForm = ({ data, pageContext }) => {
  const { page, internalLinks } = data;
  let thankYouPageLink = null;

  if (
    page.elements.thank_you_page.value.length > 0
    && page.elements.thank_you_page.value[0].id
  ) {
    const { id } = page.elements.thank_you_page.value[0];
    const links = internalLinks ? internalLinks.nodes : [];
    thankYouPageLink = links.find((el) => el.navigationItemId === id);
  }

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
        isHiddenForRobots={pageContext.seoNoIndex}
      />

      <ContentHeader
        headline={page.elements.headline.value}
        description={page.elements.lead_text.value}
        media={page.elements.image.value.length > 0 ? (
          <KontentAssetsImage
            data={page.elements.image.value[0]}
            height={147}
          />
        ) : null}
      />

      <Row className="dynamic-form">
        <Col sm={12} xl={8}>
          <DynamicForm
            thankYouPageLink={thankYouPageLink}
            buttonText={page.elements.button_text.value}
            fields={page.elements.fields.value}
            kontentItemID={page.system.id}
            formSlug={page.elements.url_pattern.value}
            initValues={generateInitialFormValues(page.elements.fields.value)}
            legalDetails={page.elements.legal_details.value}
          />
        </Col>
      </Row>

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    internalLinks: allInternalLinks {
      nodes {
        navigationItemId
        systemId
        title
        slug
      }
    }
    page: kontentItemForm(id: {eq: $id}) {
      id
      system {
        id
        codename
      }
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        image {
          value {
            url
            height
            width
            description
          }
        }
        button_text {
          value
        }
        url_pattern {
          value
        }
        thank_you_page {
          value {
            id
          }
        }
        fields {
          value {
            internal {
              type
            }
            ...KontentItemInputTypeText
            ...KontentItemInputTypeSingleInput
            ...KontentItemInputTypeCheckbox
            ...KontentItemInputTypeAddress
            ...KontentItemInputTypeEmail
            ...KontentItemInputTypeDropdown
            ...KontentItemInputTypeDate
            ...KontentItemInputTypeState
            ...KontentItemInputTypeCountry
            ...KontentItemInputTypeName
            ...KontentItemInputTypeSalutation
            ...KontentItemSectionHeader
            ...KontentItemFormRichText
          }
        }
        legal_details {
          value {
            ...KontentItemAccordion
          }
        }
      }
    }
  }
`;

KontentItemForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemForm;
