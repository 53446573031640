import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

// eslint-disable-next-line import/no-cycle
import Button from "./button";

import "./linkButton.scss";

const LinkButton = (props) => {
  const {
    children,
    className,
    variant,
    ...buttonProps
  } = props;

  return (
    <Button
      variant="link"
      type="button"
      className={classNames([
        "link-button",
        (Array.isArray(children) && (typeof children[0] !== "undefined")
        && (typeof children[0] === "string" || typeof children[0] === "boolean"
          || (typeof children[0] === "object" && children[0].type !== undefined && children[0].type.displayName === "MemoizedFormattedMessage"))
          ? "link-button--pre-text" : "link-button--post-text"),
        className,
        {
          "link-button--normal": variant === "normal" || variant === "text-button-small" || variant === "text-button-large",
          "typo-button": variant === "typo-button",
          "typo-label": variant === "normal",
          "typo-label lg": variant === "text-button-large",
          "typo-small-label sm": variant === "text-button-small",
        },
      ])}
      {...buttonProps}
    >
      <span tabIndex="-1">
        {children}
      </span>
    </Button>
  );
};

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["button", "normal", "text-button-small", "text-button-large", "typo-button"]),
};

LinkButton.defaultProps = {
  className: "",
  variant: "button",
};

export default LinkButton;
