const downloadICS = (start, end, title, uid) => {
  const icsContent = `BEGIN:VCALENDAR\r
      VERSION:2.0\r
      PRODID:DKMS\r
      BEGIN:VEVENT\r
      UID:${uid}\r
      DTSTAMP:${(new Date()).toISOString().slice(0, 19).replaceAll(/[-:]/g, "")}Z\r
      DTSTART:${start.toISOString().slice(0, 19).replaceAll(/[-:]/g, "")}Z\r
      DTEND:${(end ?? (new Date(start.getTime())).add(1, "hour")).toISOString().slice(0, 19).replaceAll(/[-:]/g, "")}Z\r
      SUMMARY:${title}\r
      END:VEVENT\r
      END:VCALENDAR`;

  const downloadLink = document.createElement("a");
  downloadLink.href = `data:text/calendar;charset=utf8,${encodeURIComponent(icsContent.replace(/^ +/gm, ""))}`;
  downloadLink.download = `${title.replace(/ /g, "_")}.ics`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export default downloadICS;
