import React, { useEffect } from "react";

import { graphql } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import KontentItemSspContactForm from "./kontentItemSspContactForm";
import VariantHelper from "./utils/variantHelper";
import Button from "../components/button/button";
import IconButton from "../components/button/iconButton";
import LinkButton from "../components/button/linkButton";
import { useLoginMutation } from "../features/io/ioSspApiSlice";
import useSspSettings from "../hooks/useSspSettings";
import { IconRetry24 } from "../icons";
import { notificationService } from "../services/notification.service";
import { isAuthenticated } from "../services/sspAuthentication.service";
import iconHelper from "../utils/iconHelper";
import { kebabCase } from "../utils/stringUtilHelper";

const KontentItemCtaButton = ({ data: button }) => {
  if (!button || !button.elements) {
    return null;
  }

  // const isSspContactForm = buttonUrlIsNotEmpty && button.elements.button_url.value[0]
  //  ?.elements?.ssp_contact?.value.length > 0;

  const pseudoLoggedOut = useSelector((state) => state.ssp.pseudoLoggedOut);

  const [login, loginResult] = useLoginMutation();

  // Fallback
  const theme = button.elements.cta_theme?.value.length > 0
    ? kebabCase(button.elements.cta_theme.value[0].codename)
    : null;

  const variant = VariantHelper(
    button.elements.button_style_color?.value?.[0]?.codename || theme,
    button.elements.button_style_variant?.value?.[0]?.codename,
  );

  const tmpChildren = [button.elements.button_text.value];

  if (button.elements.icon?.value.length > 0) {
    tmpChildren.unshift(iconHelper(button.elements.icon.value[0].codename));
  }

  let buttonProps = {
    children: tmpChildren,
    href: button.elements.button_url?.value.length > 0
      ? button.elements.button_url.value[0].id
      : button.elements.button_url_ext.value,
  };

  if (button.elements.button_mailto?.value) {
    buttonProps.href = `mailto:${button.elements.button_mailto.value}`;
  }

  if (button.elements.button_phone?.value) {
    buttonProps.href = `tel:${button.elements.button_phone.value.replace(/[^0-9]/g, "")}`;
  }

  // todo: Alternative für das ssp-login theme. cta_theme wird es nicht mehr geben.
  if ((button.elements.ssp_login?.value?.length > 0 || theme === "ssp-login") && (!isAuthenticated() || pseudoLoggedOut)) {
    const intl = useIntl();
    const { accessDeniedSlug } = useSspSettings();

    useEffect(() => {
      if (loginResult.isError) {
        notificationService.error(intl.formatMessage({ id: "ssp.login_error" }), {
          action: (
            <LinkButton onClick={() => login(accessDeniedSlug?.slug)}>
              <FormattedMessage id="ssp.login_error.retry" /> <IconRetry24 />
            </LinkButton>),
          autoClose: false,
          dismissButtonText: <FormattedMessage id="ssp.login_error.hide" />,
        });
      }
    }, [loginResult]);

    buttonProps = {
      children: button.elements.button_text.value,
      disabled: loginResult.isLoading,
      loading: loginResult.isLoading,
      onClick: () => {
        login(accessDeniedSlug?.slug);
      },
    };
  }

  if (button.elements.ssp_contact_form?.value.length > 0) {
    return (
      <KontentItemSspContactForm
        variant={variant}
        buttonText={button.elements.button_text.value}
        overwriteButton={button.elements.icon.value.length > 0}
        buttonProps={buttonProps}
      />
    );
  }

  if (button.elements.icon?.value.length > 0 || loginResult.isLoading) {
    return (
      <IconButton
        variant={variant}
        {...buttonProps}
      />
    );
  }

  return (
    <Button
      variant={variant}
      {...buttonProps}
    />
  );
};

export const query = graphql`
  fragment KontentItemCtaButton on kontent_item_cta_button {
    id
    system {
      codename
    }
    elements {
      button_text {
        value
      }
      cta_theme {
        value {
          codename
        }
      }
      button_url {
        value {
          id
          ... on kontent_item_form {
            elements {
              ssp_contact {
                value {
                  codename
                }
              } 
            }
          }
        }
      }
      button_url_ext {
        value
      }
      icon {
        value {
          codename
        }
      }
      button_style_color {
        value {
          codename
        }
      }
      button_style_variant {
        value {
          codename
        }
      }
      ssp_login {
        value {
          codename
        }
      }
      button_mailto {
        value
      }
      button_phone {
        value
      }
      ssp_contact_form {
        value {
          codename
        }
      }
    }
  }
`;

KontentItemCtaButton.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemCtaButton;
