import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import ArticleCard from "../components/articleCard/articleCard";
import LinkButton from "../components/button/linkButton";
import { IconExternalLink24, IconFurther24 } from "../icons";

const KontentItemLinkCard = ({ data }) => {
  let button = null;
  let href = data.elements.external_url.value;

  if (data.elements.internal_url.value.length > 0) {
    href = data.elements.internal_url.value[0].id;
  }

  if (href && href !== "") {
    button = (
      <LinkButton href={href}>
        {data.elements.button_text.value}
        {data.elements.external_url.value ? <IconExternalLink24 /> : <IconFurther24 />}
      </LinkButton>
    );
  }

  return (
    <ArticleCard
      image={
        data.elements.image.value.length > 0
          ? (
            <KontentItemImage
              data={data.elements.image.value[0]}
              width={800}
              aspectRatio={16 / 9}
            />
          )
          : null
      }
      headline={data.elements.headline.value}
      excerpt={data.elements.text.value}
      variant="link-card"
      button={button}
      href={href}
    />
  );
};

export const query = graphql`
  fragment KontentItemLinkCard on kontent_item_link_card {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      text {
        value
      }
      button_text {
        value
      }
      external_url {
        value
      }
      internal_url {
        value {
          id
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

KontentItemLinkCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLinkCard;
