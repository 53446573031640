import React from "react";

import { RichTextElement } from "@kentico/gatsby-kontent-components";
import sanitizeHtml from "sanitize-html";

import CustomTag from "./customTag";
import RichTextContext from "./richTextContext";
import NavigationLink from "../../components/navigationLink/navigationLink";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentAssetsImage from "../kontentAssetsImage";

const RichText = (data, openLinksInNewTab) => {
  const {
    data: {
      value,
      images,
      links,
      modular_content: modularContent,
    },
    isArticle,
    isSidebarLayout,
  } = data;

  // cleanup value
  const text = sanitizeHtml(value.replace(/&nbsp;/g, " "), {
    allowVulnerableTags: true, // todo: remove
    allowedAttributes: false,
    allowedTags: false, // todo: define allowed tags
    exclusiveFilter: (frame) => ["script", "style"].includes(frame.tag),
  });

  const richText = (
    <RichTextElement
      value={text}
      images={images}
      links={links}
      linkedItems={modularContent}
      resolveImage={(image) => (
        <KontentAssetsImage
          data={image}
          className="mx-auto"
        />
      )}
      resolveLink={(link, domNode) => {
        if (link) {
          return (
            <NavigationLink href={link.link_id} target={openLinksInNewTab ? "_blank" : "_self"}>
              {domNode.children[0].data}
            </NavigationLink>
          );
        }

        return <>{domNode.children[0].data}</>;
      }}
      resolveLinkedItem={
        (linkedItem) => mapKontentItem(linkedItem, { isSidebarLayout, keyPrefix: "rt" })
      }
      resolveDomNode={(domNode, domToReact) => {
        const customTagRegex = /#<(.*?)>#/g;

        if (domNode.data && domNode.data.indexOf("#<") !== -1) {
          const customTagManipulation = domNode.data.split(customTagRegex);

          if (customTagManipulation.length > 1) {
            for (let i = 0; i < customTagManipulation.length; i += 1) {
              if (customTagManipulation[i].charAt(0) === "{") {
                try {
                  customTagManipulation[i] = (
                    <CustomTag {...JSON.parse(customTagManipulation[i])} />
                  );
                } catch (e) {
                  customTagManipulation[i] = "### MISSING ELEMENT ###";
                }
              }
            }

            return domToReact(
              [domNode],
              { replace: () => (<>{customTagManipulation}</>) },
            );
          }
        }

        return domToReact(domNode);
      }}
    />
  );

  if (isArticle) {
    return (
      <RichTextContext.Provider value>
        <div className="text-section text row">
          <div className="col-lg-8 col-sm-10 offset-lg-2 offset-sm-1">
            {richText}
          </div>
        </div>
      </RichTextContext.Provider>
    );
  }

  return (
    <RichTextContext.Provider value>
      {richText}
    </RichTextContext.Provider>
  );
};

export default RichText;
