import React, { memo } from "react";

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import "./skipLink.scss";

const SkipLink = (props) => {
  const {
    children,
    target,
  } = props;

  return (
    <Button
      className="skip-link"
      href={target}
    >
      {children}
    </Button>
  );
};

SkipLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  target: PropTypes.string.isRequired,
};

export default memo(SkipLink);
