import React from "react";

import PropTypes from "prop-types";

import { IconSubmit18, IconSubmit24 } from "../../icons";
import AdaptiveIcon from "../adaptiveIcon";
import IconButton from "../button/iconButton";

const SubmitButton = (props) => {
  const {
    label,
    disabled,
    loading,
    onClick,
  } = props;

  return (
    <IconButton type="submit" disabled={disabled} loading={loading} onClick={onClick}>
      {label}
      <AdaptiveIcon
        sm={<IconSubmit18 />}
        lg={<IconSubmit24 />}
      />
    </IconButton>
  );
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};

SubmitButton.defaultProps = {
  disabled: false,
  loading: false,
  onClick: () => {},
};

export default SubmitButton;
