import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ArticleTeaser from "../../components/articleTeaser/articleTeaser";
import KontentItemImage from "../kontentItemImage";

const ArticleTeaserCard = ({ data }) => {
  const articleData = Array.isArray(data) ? data : data.value;

  const articleList = [];

  articleData.forEach((news) => {
    articleList.push(
      <ArticleTeaser
        variant="story"
        headline={news.elements.headline.value}
        url={news.id}
        category={news.elements.news_category.value.length > 0
          ? news.elements.news_category.value[0].name
          : null}
        image={<KontentItemImage data={news.elements.hero_image} height={120} fit="crop" aspectRatio={1} placeholderTyp="news" />}
        showImage
      />,
    );
  });

  return React.Children.toArray(articleList);
};

export const query = graphql`
  fragment ArticleTeaserCard on kontent_item_article_page_news {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      lead_text {
        value
      }
      news_category {
        value {
          codename
          name
        }
      }
    }
  }
  fragment ArticleTeaserCardMigrated on kontent_item_article_migration {
    id
    system {
      codename
    }
    elements {
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      lead_text {
        value
      }
      news_category {
        value {
          codename
          name
        }
      }
    }
  }
`;

ArticleTeaserCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ArticleTeaserCard;
