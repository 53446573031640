import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const IntroSection = ({ headline, text, headlineAs: HeadlineComponent }) => (
  <Row className="py-36 py-md-48 py-lg-60 justify-content-lg-center">
    <Col lg={8}>
      <HeadlineComponent className="typo-h3 mb-18">{headline}</HeadlineComponent>
      {text}
    </Col>
  </Row>
);

IntroSection.propTypes = {
  headline: PropTypes.string.isRequired,
  headlineAs: PropTypes.oneOf(["h1", "h3"]),
  text: PropTypes.string.isRequired,
};

IntroSection.defaultProps = {
  headlineAs: "h3",
};

export default IntroSection;
