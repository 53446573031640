import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import AdaptiveIcon from "../../components/adaptiveIcon";
import IconButton from "../../components/button/iconButton";
import TakeActionCard from "../../components/takeActionCard/takeActionCard";
import { IconFurther18, IconFurther24 } from "../../icons";
import iconHelper from "../../utils/iconHelper";
import VariantHelper from "../utils/variantHelper";

const IconCards = ({ data }) => {
  const dataCards = Array.isArray(data) ? data : data.value;

  const cards = [];

  dataCards.forEach((card) => {
    const variant = VariantHelper(card.elements.button_style_color.value, "inverted");

    cards.push(
      <TakeActionCard
        key={card.id}
        label={card.elements.label.value}
        icon={
          card.elements.icon.value.length > 0
            ? iconHelper(card.elements.icon.value[0].codename)
            : null
        }
        button={(
          <IconButton
            iconOnly
            variant={variant}
            aria-label={card.elements.label.value}
          >
            <AdaptiveIcon
              sm={<IconFurther18 />}
              lg={<IconFurther24 />}
            />
          </IconButton>
        )}
        href={
          card.elements.button_url.value.length > 0
            ? card.elements.button_url.value[0].id
            : "/#"
        }
      />,
    );
  });

  return cards;
};

export const query = graphql`
  fragment IconCard on kontent_item_icon_card {
    id
    system {
      codename
    }
    elements {
      label {
        value
      }
      icon {
        value {
          codename
        }
      }
      iconbutton {
        value {
          codename
        }
      }
      button_url {
        value {
          id
        }
      }
      button_style_color {
        value {
          codename
        }
      }
    }
  }
`;

IconCards.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default IconCards;
