import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ArticleCard from "../../components/articleCard/articleCard";
import KontentItemImage from "../kontentItemImage";

const DonorCard = ({ data, variant, author }) => {
  let newsData = Array.isArray(data) ? data : [data];

  const newsList = [];

  if (author) {
    newsData = newsData.filter((item) => (
      item.elements.author_name.value.length > 0
      && item.elements.author_name.value[0].codename === author));
  }

  newsData.forEach((news) => {
    newsList.push(
      <ArticleCard
        image={(
          <KontentItemImage
            data={news.elements?.hero_image}
            aspectRatio={400 / 225}
            width={800}
            fit="contain"
            placeholderTyp="news"
          />
        )}
        category={
          news.elements.donor_topic.value.length > 0
            ? news.elements.donor_topic.value[0].name
            : null
        }
        headline={news.elements.headline.value}
        href={news.id}
        excerpt={news.elements.lead_text.value}
        variant={variant}
        patientsJourney={news.elements.article_patient_side.value.length > 0}
      />,
    );
  });

  return React.Children.toArray(newsList);
};

export const query = graphql`
  fragment DonorCard on kontent_item_article_donor_area {
    id
    system {
      codename
      last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
    }
    elements {
      author_name {
        value {
          codename
        }
      }
      headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      article_patient_side {
        value {
          name
          codename
        }
      }
      lead_text {
        value
      }
      donor_topic {
        value {
          name
        }
      }
    }
  }
`;

DonorCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  variant: PropTypes.oneOf(["learn-topic", "learn-article"]).isRequired,
};

export default DonorCard;
