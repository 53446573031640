import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemAsset from "../kontentItemAsset";

const FileDownloadCards = ({ data }) => {
  const fileData = Array.isArray(data) ? data : data.value;

  const fileList = [];

  const minOneItemWithImage = fileData.some((item) => (item.elements.image.value.length > 0));

  fileData.forEach((file) => {
    fileList.push(
      <KontentItemAsset data={file} usePlaceholder={minOneItemWithImage} />,
    );
  });

  return React.Children.toArray(fileList);
};

export const query = graphql`
  fragment FileDownloadCards on kontent_item_asset {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      file_size {
        value
      }
      asset_item {
        value {
          url
        }
      }
      media_type {
        value {
          codename
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  } 
`;

FileDownloadCards.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default FileDownloadCards;
