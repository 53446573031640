import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

import KontentItemImage from "./kontentItemImage";
import KontentItemVideoEmbed from "./kontentItemVideoEmbed";
import CardSlider from "../components/cardSlider/cardSlider";
import GalleryImage from "../components/galleryImage/galleryImage";

const KontentItemMediaGallery = ({ data }) => {
  const gallery = data.value ? data.value[0] : data;

  if (!gallery || !gallery.elements) {
    return null;
  }

  return (
    <div className="container--fullwidth">
      <Container fluid>
        <CardSlider
          showViewAllButton={false}
          inline
        >
          {gallery.elements.media.value.map((item) => {
            if (item.id) {
              // eslint-disable-next-line no-underscore-dangle
              switch (item.internal.type) {
                case "kontent_item_image":
                  return (
                    <GalleryImage
                      key={item.id}
                      image={(
                        <KontentItemImage
                          data={item}
                          aspectRatio={4 / 3}
                          fit="crop"
                          width={800}
                        />
                      )}
                    />
                  );
                case "kontent_item_video_embed":
                  return (
                    <KontentItemVideoEmbed
                      key={item.id}
                      data={item}
                      variant="media-gallery"
                    />
                  );
                default:
              }
            }

            return null;
          })}
        </CardSlider>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment KontentItemMediaGallery on kontent_item_media_gallery {
    id
    system {
      codename
    }
    elements {
      media {
        value {
          internal {
            type
          }
          ...KontentItemImage
          ...KontentItemVideoEmbed
        }
      }
    }
  }
`;

KontentItemMediaGallery.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemMediaGallery;
