import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./avatar.scss";

const Avatar = ({ name, small, className }) => (
  <span className={classNames("avatar", { "avatar--small": small }, className)}>
    {name ? name.charAt(0) : ""}
  </span>
);

Avatar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

Avatar.defaultProps = {
  className: null,
  small: false,
};

export default Avatar;
