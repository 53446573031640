import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import ArticleTeaser from "../components/articleTeaser/articleTeaser";
import ArticleTeaserEmbed from "../components/articleTeaserEmbed/articleTeaserEmbed";

const KontentItemArticlesEmbed = ({ data }) => {
  const articleTeaser = data.value ? data.value[0] : data;

  if (!articleTeaser || !articleTeaser.elements) {
    return null;
  }

  return (
    <ArticleTeaserEmbed>
      {articleTeaser.elements.article_teasers.value.map((item) => {
        if (item.id) {
          let options = null;

          switch (item.internal.type) {
            case "kontent_item_article_page_learn":
            case "kontent_item_article_donor_area":
              options = {
                variant: "learn",
              };
              break;
            case "kontent_item_article_migration":
            case "kontent_item_article_page_news":
              options = {
                variant: "story",
              };
              break;
            default:
          }

          return (
            <ArticleTeaser
              key={item.id}
              headline={item.elements.headline.value}
              url={item.id}
              image={
                item.elements.hero_image.value.length > 0
                  ? (
                    <KontentItemImage
                      data={item.elements.hero_image}
                      height={120}
                      crop
                      aspectRatio={1}
                    />
                  )
                  : null
              }
              {...options}
            />
          );
        }

        return null;
      })}

    </ArticleTeaserEmbed>
  );
};

export const query = graphql`
  fragment KontentItemArticlesEmbed on kontent_item_articles_embed {
    id
    system {
      codename
    }
    elements {
      article_teasers {
        value {
          internal {
            type
          }
          ... on kontent_item_article_page_learn {
            id
            elements {
              headline {
                value
              }
              hero_image {
                value {
                  ...KontentItemImage
                }
              }
            }
          }
          ... on kontent_item_article_page_news {
            id
            elements {
              headline {
                value
              }
              hero_image {
                value {
                  ...KontentItemImage
                }
              }
            }
          }
          ... on kontent_item_article_migration {
            id
            elements {
              headline {
                value
              }
              hero_image {
                value {
                  ...KontentItemImage
                }
              }
            }
          }
          ... on kontent_item_article_donor_area {
            id
            elements {
              headline {
                value
              }
              hero_image {
                value {
                  ...KontentItemImage
                }
              }
            }
          }
        }
      }
    }
  }
`;

KontentItemArticlesEmbed.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemArticlesEmbed;
