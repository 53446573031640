const downloadFile = (url, name, options = {}) => {
  fetch(url, options)
    .then((response) => {
      response.blob().then((blob) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = name;
        a.click();
      });
    });
};

export default downloadFile;
