import React from "react";

import PropTypes from "prop-types";

import { IconAnchorRight18 } from "../../icons";
import LinkButton from "../button/linkButton";

import "./megaMenuLink.scss";

const MegaMenuLink = (props) => {
  const {
    href,
    children,
  } = props;

  return (
    <LinkButton className="megamenu-link" variant="normal" href={href} {...props}>
      {children}
      <IconAnchorRight18 />
    </LinkButton>
  );
};

MegaMenuLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

export default MegaMenuLink;
