import React from "react";

import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";

import useMediaQuery from "../../hooks/haiku/useMediaQuery";

import "./searchHit.scss";

const searchHit = (props) => {
  const {
    breadcrumb,
    title,
    category,
    description,
    imageBase64,
    imageSrc,
    documentType,
    url,
  } = props;

  const openLink = () => {
    const newWindow = window.open(
      url,
      documentType === "html" ? "_self" : "_blank",
      "noopener,noreferrer",
    );

    newWindow.opener = null;
  };

  const isGreaterSm = useMediaQuery("(min-width: 576px)");

  return (
    <div
      role="button"
      tabIndex="0"
      className="search-hit"
      onClick={() => {
        openLink();
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          openLink();
          event.stopPropagation();
        }
      }}
    >
      <div className="search-hit__header">
        <div>
          {category && (
            <div className="search-hit__category">{category}</div>
          )}
          <div className="typo-title3">{title}</div>
          {isGreaterSm && (
            <>
              <div className="search-hit__description typo-body2">
                {/* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(description, { allowedTags: ["em"] }) }} />
              </div>
              <div>
                {breadcrumb}
              </div>
            </>
          )}
        </div>
        <div
          className="search-hit__image d-flex align-self-center"
          style={{ backgroundImage: `url(${imageSrc}), url(data:image/gif;base64,${imageBase64})` }}
        />
      </div>
      {!isGreaterSm && (
        <>
          <div className="search-hit__description">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(description, { allowedTags: ["em"] }) }} />
          </div>
          <div>
            {breadcrumb}
          </div>
        </>
      )}

    </div>
  );
};

searchHit.propTypes = {
  breadcrumb: PropTypes.oneOfType([PropTypes.object]),
  category: PropTypes.oneOfType([PropTypes.object]),
  description: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  imageBase64: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

searchHit.defaultProps = {
  breadcrumb: null,
  category: null,
};

export default searchHit;
