import React from "react";

import { graphql } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import ArticleHeader from "../../components/articleHeader/articleHeader";
import HighlightArticles from "../../components/highlightArticles/highlightArticles";
import IconText from "../../components/iconText/iconText";
import LearnArticleList from "../../components/learnArticleList/learnArticleList";
import SEO from "../../components/seo";
import TopicIntroductionImage from "../../components/topicIntroductionSection/topicIntroductionImage";
import TopicIntroductionSection from "../../components/topicIntroductionSection/topicIntroductionSection";
import { IconDirectory18 } from "../../icons";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemEyecatcher from "../kontentItemEyecatcher";
import KontentItemImageCardSliderSection from "../kontentItemImageCardSliderSection";
import KontentItemLearnTopicsSection from "../kontentItemLearnTopicsSection";
import MainLayout from "../layout/mainLayout";
import GetLearnTopicCount from "../learn/getLearnTopicCount";
import HighlightArticle from "../learn/highlightArticle";
import LearnCard from "../learn/learnCard";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemLearnTopicsPage = ({ data }) => {
  const { page } = data;

  let headerImage = null;

  if (page.elements.topic_illustration_main.value.length > 0) {
    [headerImage] = page.elements.topic_illustration_main.value;
  }

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <ArticleHeader
        title={page.elements.headline.value}
        tag={(
          <IconText
            icon={<IconDirectory18 />}
            text={<FormattedMessage id="learn.learn_topic" />}
            variant="learn-area"
          />
        )}
        description={page.elements.lead_text.value}
        articleCount={(
          GetLearnTopicCount({
            codename: page.elements.learn_topic.value.length > 0
              && page.elements.learn_topic.value[0].codename,
          })
        )}
      />

      {headerImage && (
        <TopicIntroductionImage>
          <KontentAssetsImage
            data={headerImage}
            height={240}
          />
        </TopicIntroductionImage>
      )}

      <TopicIntroductionSection>
        <RichText data={page.elements.body} isArticle />
      </TopicIntroductionSection>

      {page.elements.highlight_articles.value.length > 0 && (
        <HighlightArticles>
          {HighlightArticle({ data: page.elements.highlight_articles.value })}
        </HighlightArticles>
      )}

      <LearnArticleList headline={page.elements.headline_topic_articles.value}>
        {page.elements.learn_articles_section.value.map((article) => (
          <LearnCard data={article} key={article.id} variant="learn-article" />
        ))}
      </LearnArticleList>

      <KontentItemEyecatcher data={page.elements.eyecatcher} />

      <KontentItemLearnTopicsSection data={page.elements.more_topics} />

      {page.elements.eyecatcher_contact.value.length > 0
        ? mapKontentItem(page.elements.eyecatcher_contact.value[0])
        : null}

      <KontentItemImageCardSliderSection data={page.elements.image_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemLearnTopicsPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        learn_topic {
          value {
            codename
          }
        }
        headline {
          value
        }
        lead_text {
          value
        }
        highlight_articles {
          value {
            ...HighlightArticle
          }
        }
        eyecatcher {
          value {
            ...KontentItemEyecatcher
          }
        }
        eyecatcher_contact {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherContact
            ...KontentItemEyecatcher
          }
        }
        image_card_slider {
          value {
            ...KontentItemImageCardSliderSection
          }
        }
        more_topics {
          value {
            ...KontentItemLearnTopicsSection
          }
        }
        topic_illustration_main {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        learn_articles_section {
          value {
            ...LearnCard
          }
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemEmbedExternal
          }
        }
        headline_topic_articles {
          value
        }
      }
    }
  }
`;

KontentItemLearnTopicsPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLearnTopicsPage;
