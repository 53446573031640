import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import backgroundImageHelper from "./image/backgroundImageHelper";
import RegistryBanner from "../components/banner/registryBanner";

const KontentItemRegistryBanner = ({ data }) => {
  const registryBanner = data.value ? data.value[0] : data;

  if (!registryBanner || !registryBanner.elements) return null;

  const url = registryBanner.elements.button_url.value.length > 0
    ? registryBanner.elements.button_url.value[0].id
    : "";

  return (
    <RegistryBanner
      title={registryBanner.elements.title.value}
      description={registryBanner.elements.description.value}
      buttonText={registryBanner.elements.button_text.value}
      buttonUrl={url}
      backgroundImageStyle={backgroundImageHelper(registryBanner.elements.image, { w: 1000 })}
    />
  );
};

export const query = graphql`
  fragment KontentItemRegistryBanner on kontent_item_registry_banner {
    id
    system {
      codename
    }
    elements {
      description {
        value
      }
      button_text {
        value
      }
      title {
        value
      }
      button_url {
        value {
          id
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

KontentItemRegistryBanner.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemRegistryBanner;
