import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import IconButton from "../components/button/iconButton";
import ContactFaq from "../components/contactFaq/contactFaq";
import { IconHelp24 } from "../icons";

const KontentItemContactFaqBox = ({ data }) => {
  const contactFaq = data.value ? data.value[0] : data;

  if (!contactFaq || !contactFaq.elements) {
    return null;
  }

  return (
    <ContactFaq
      question={contactFaq.elements.title.value}
      description={contactFaq.elements.text.value}
      button={(
        <IconButton
          variant="plain-faq"
          href={
            contactFaq.elements.button_url.value.length > 0
              ? contactFaq.elements.button_url.value[0].id
              : null
          }
        >
          {contactFaq.elements.button_text.value}<IconHelp24 />
        </IconButton>
      )}
    />
  );
};

export const query = graphql`
  fragment KontentItemContactFaqBox on kontent_item_contact_faq_box {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      text {
        value
      }
      button_text {
        value
      }
      button_url {
        value {
          id
        }
      }
    }
  }
`;

KontentItemContactFaqBox.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemContactFaqBox;
