import React, { useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import {
  getComplianceSettings,
  setComplianceSettings,
  setShowComplianceSettings,
} from "../../features/complianceConsentSlice";
import {
  IconDropdownExpand18,
  IconDropdownMinimize18,
  IconSelectBlue24,
  IconSelectDisabled24,
  IconSelectEmpty24,
} from "../../icons";
import SimpleRichText from "../../templates/text/simpleRichText";
import DataLayer from "../../utils/dataLayer";
import Button from "../button/button";
import IconButton from "../button/iconButton";
import LinkButton from "../button/linkButton";
import NavigationLink from "../navigationLink/navigationLink";

import "./cookieBanner.scss";

const CookieBannerContent = (props) => {
  const {
    openLinksInNewTab,
    settings,
    complianceTypes,
    setSettings,
  } = props;

  const dispatch = useDispatch();

  const data = useStaticQuery(graphql`
    {
      cookie: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          url_legal {
            value {
              id
            }
          }
          url_privacy {
            value {
              id
            }
          }
          text_consent_banner {
            value
          }
          headline_consent_banner {
            value
          }
          headline_consent_edit {
            value
          }
          button_accept_text {
            value
          }
          button_edit_text {
            value
          }
          button_save_text {
            value
          }
          url_legal_text {
            value
          }
          url_privacy_text {
            value
          }
          detailed_settings {
            value {
              ... on kontent_item_consent_types {
                id
                elements {
                  consent_type {
                    value {
                      codename
                    }
                  }
                  title {
                    value
                  }
                  description {
                    value
                  }
                }
              }
            }
          }
          button_hide_text {
            value
          }
          button_reject_text {
            value
          }
          lightbox_variant {
            value {
              codename
            }
          }
        }
      }
    }
  `);

  if (!data.cookie) {
    return null;
  }

  const content = data.cookie.elements;

  const [labels, setLabels] = useState([]);
  const [showSettings, setShowSettings] = useState(false);

  return (
    <div className="cookie-banner">
      <div className="typo-title3">{content.headline_consent_banner.value}</div>

      <div className="cookie-banner__subtext">
        <SimpleRichText data={content.text_consent_banner} />
      </div>

      <div className="cookie_banner-copyright">
        {content.url_privacy.value.length > 0 && (
          <NavigationLink
            href={content.url_privacy.value[0].id}
            target={openLinksInNewTab ? "_blank" : null}
            onClick={() => {
              dispatch(
                setShowComplianceSettings(false),
              );
            }}
          >
            {content.url_privacy_text.value}
          </NavigationLink>
        )}
        {content.url_legal.value.length > 0 && (
          <NavigationLink
            href={content.url_legal.value[0].id}
            target={openLinksInNewTab ? "_blank" : null}
            onClick={() => {
              dispatch(
                setShowComplianceSettings(false),
              );
            }}
          >
            {content.url_legal_text.value}
          </NavigationLink>
        )}
      </div>

      <LinkButton
        className="typo-button"
        onClick={() => {
          setShowSettings(!showSettings);
        }}
      >

        {showSettings
          ? <>{content.button_hide_text.value}<IconDropdownMinimize18 /></>
          : <>{content.button_edit_text.value}<IconDropdownExpand18 /></>}
      </LinkButton>
      <div>
        {showSettings && (
          content.detailed_settings.value.map(
            (item) => (complianceTypes.includes(
              item.elements.consent_type.value.length > 0
                        && item.elements.consent_type.value[0].codename,
            ) || item.elements.consent_type.value[0].codename === "essential") && (
              <div className="cookie-banner__filter-listitem" key={item.elements.consent_type.value[0].codename}>
                <div
                  className="label"
                  onClick={() => {
                    if (item.elements.consent_type.value[0].codename !== "essential") {
                      if (settings[item.elements.consent_type.value[0].codename]
                                  && settings[item.elements.consent_type.value[0].codename]
                                    .status <= 0) {
                        setLabels([...labels, item.elements.title.value]);
                        setSettings({
                          ...settings,
                          [item.elements.consent_type.value[0].codename]: { status: 1 },
                        });
                      } else if (settings[item.elements.consent_type.value[0].codename]
                                  && settings[
                                    item.elements.consent_type.value[0].codename
                                  ].status === 1) {
                        setLabels([...labels, item.elements.title.value]);
                        setSettings({
                          ...settings,
                          [item.elements.consent_type.value[0].codename]: { status: 0 },
                        });
                      }
                    }
                  }}
                  role="presentation"
                >
                  {item.elements.consent_type.value[0].codename !== "essential"
                    ? (
                      <>{settings[item.elements.consent_type
                        .value[0].codename].status === 0
                                || settings[item.elements.consent_type
                                  .value[0].codename].status === -1
                        ? <IconSelectEmpty24 className="text-grey-light" />
                        : <IconSelectBlue24 />}
                      </>
                    )
                    : <IconSelectDisabled24 />}
                  {item.elements.title.value}

                </div>
                <div className="filter-listitem-description">{item.elements.description.value}</div>
              </div>
            ),
          )
        )}
      </div>

      <div className="cookie_banner__button-container">
        {showSettings && (
          <IconButton
            onClick={() => {
              const seenConsents = Object.keys(settings).reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: { status: settings[key].status === 1 ? 1 : 0 },
                }),
                {},
              );

              dispatch(
                setComplianceSettings({ consents: seenConsents }),
              ).then(() => {
                dispatch(
                  getComplianceSettings(),
                );
              });

              dispatch(
                setShowComplianceSettings(false),
              );

              setShowSettings(false);

              DataLayer.pushEvent("stg.saveChoicesClicked");
            }}
            variant="inverted-tertiary"
            size="sm"
            className="justify-content-center"
          >
            {content.button_save_text.value}
          </IconButton>
        )}

        <div className="cookie_banner__buttons">
          <Button
            variant="faq"
            size="sm"
            onClick={() => {
              const tmpSettings = {};
              complianceTypes.forEach((item) => {
                tmpSettings[item] = { status: 0 };
              });
              dispatch(
                setComplianceSettings({ consents: tmpSettings }),
              );

              dispatch(
                getComplianceSettings(),
              );

              dispatch(
                setShowComplianceSettings(false),
              );

              setShowSettings(false);

              DataLayer.pushEvent("stg.rejectAllClicked");
            }}
          >
            {content.button_reject_text.value}
          </Button>

          <Button
            variant="faq"
            size="sm"
            onClick={() => {
              const tmpSettings = {};
              complianceTypes.forEach((item) => {
                tmpSettings[item] = { status: 1 };
              });
              dispatch(
                setComplianceSettings({ consents: tmpSettings }),
              );

              dispatch(
                getComplianceSettings(),
              );

              dispatch(
                setShowComplianceSettings(false),
              );

              setShowSettings(false);

              DataLayer.pushEvent("stg.agreeToAllClicked");
            }}
          >
            {content.button_accept_text.value}
          </Button>
        </div>
      </div>
    </div>
  );
};

CookieBannerContent.propTypes = {
  complianceTypes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  openLinksInNewTab: PropTypes.bool,
  setSettings: PropTypes.func.isRequired,
  settings: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

CookieBannerContent.defaultProps = {
  openLinksInNewTab: false,
};

export default CookieBannerContent;
