import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./donorPathStep.scss";

const DonorPathStep = (props) => {
  const {
    stepNumber,
    clickable,
  } = props;

  return (
    <div className={classNames("donor-path-step", { "donor-path-step--clickable": clickable })}>
      <div className="donor-path-step__inner">
        {stepNumber}
      </div>
    </div>
  );
};

DonorPathStep.propTypes = {
  clickable: PropTypes.bool,
  stepNumber: PropTypes.string.isRequired,
};

DonorPathStep.defaultProps = {
  clickable: false,
};

export default DonorPathStep;
