import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import IconButton from "../../components/button/iconButton";
import HighlightTopicTileSection from "../../components/highlightTopicSection/highlightTopicTileSection";
import LearnHeader from "../../components/learnHeader/learnHeader";
import SEO from "../../components/seo";
import { IconFurther18 } from "../../icons";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemIconCardSliderSection from "../kontentItemIconCardSliderSection";
import KontentItemImage from "../kontentItemImage";
import KontentItemLearnTopicPreviewSection from "../kontentItemLearnTopicPreviewSection";
import KontentItemRegistryBanner from "../kontentItemRegistryBanner";
import KontentItemRelatedLearnArticles from "../kontentItemRelatedLearnArticles";
import MainLayout from "../layout/mainLayout";
import LearnTopicTiles from "../learn/learnTopicTiles";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";

const KontentItemLearnOverviewPage = ({ data }) => {
  const { page } = data;

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <LearnHeader
        image={(
          <KontentItemImage
            data={page.elements.hero_image}
            className="img-fluid"
            width={700}
            aspectRatio={3 / 4}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        )}
        headline={page.elements.headline.value}
        text={page.elements.text.value}
        button={(
          <IconButton variant="inverted-learn-area" href={page.elements.button_url.value.length > 0 ? page.elements.button_url.value[0].id : null}>
            {page.elements.button_text.value}<IconFurther18 />
          </IconButton>
        )}
      />

      <HighlightTopicTileSection
        highlights={
          page.elements.highlight_topic_previews.value.length > 0
          && page.elements.highlight_topic_previews.value.map((highlight) => (
            <KontentItemLearnTopicPreviewSection data={highlight} key={highlight.id} />
          ))
        }
      >
        {LearnTopicTiles({ data: page.elements.learn_topics })}
      </HighlightTopicTileSection>

      {page.elements.eyecatcher_contact.value.length > 0
        ? mapKontentItem(page.elements.eyecatcher_contact.value[0])
        : null}

      <KontentItemRelatedLearnArticles data={page.elements.related_articles} />

      <KontentItemRegistryBanner data={page.elements.join_registry_banner} />

      <KontentItemIconCardSliderSection data={page.elements.icon_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemLearnOverviewPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        button_text {
          value
        }
        button_url {
          value {
            id
          }
        }
        headline {
          value
        }
        text {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        highlight_topic_previews {
          value {
            ...KontentItemLearnTopicPreviewSection
          }
        }
        learn_topics {
          value {
            ...LearnTopicTiles
          }
        }
        eyecatcher_contact {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherContact
            ...KontentItemEyecatcher
          }
        }
        related_articles {
          value {
            ...KontentItemRelatedLearnArticles
          }
        }
        join_registry_banner {
          value {
            ...KontentItemRegistryBanner
          }
        }
        icon_card_slider {
          value {
            ...KontentItemIconCardSliderSection
          }
        }
      }
    }
  }
`;

KontentItemLearnOverviewPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLearnOverviewPage;
