import React, { memo } from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { IconArticle18, IconBentcorner36 } from "../../icons";
import { format } from "../../utils/dateHelper";
import IconText from "../iconText/iconText";
import NavigationLink from "../navigationLink/navigationLink";
import Tag from "../tag";

import "./articleCard.scss";

const ArticleCard = (props) => {
  const {
    category,
    date,
    headline,
    href,
    image,
    excerpt,
    variant,
    button,
    className,
    patientsJourney,
  } = props;

  const intl = useIntl();

  const { width } = useWindowDimensions();

  let maxChars = 96;

  if ((width >= 767 && width < 991) || (width >= 1199 && width < 1499)) {
    maxChars = 120;
  }

  if (variant === "link-card") {
    maxChars = 9999;
  }

  const inner = (
    <>
      <div className="article-card__image-container">
        <div className="article-card__image">
          {image}
        </div>
        {["learn-topic", "learn-article"].includes(variant) && (
          <div className="article-card__fold-icon-container">
            <IconBentcorner36 className="article-card__fold-icon" />
            {patientsJourney && (
              <div className="article-card__patient typo-small-label">
                <FormattedMessage id="learn_area.patients_journey" />
              </div>
            )}
          </div>
        )}
      </div>
      {category && (
        <div className="d-flex article-card__subline">
          {variant === "learn-article"
            ? (
              <IconText
                icon={<IconArticle18 />}
                variant="learn-area"
                text={intl.formatMessage({ id: "article_card.article" })}
              />
            )
            : <Tag variant={variant}>{category}</Tag>}
          {date && (
            <div className="article-card__date typo-body1">
              {format(date)}
            </div>
          )}
        </div>
      )}
      <div className="article-card__headline">
        {headline}
      </div>
      {excerpt && (
        <div className="article-card__excerpt">
          {excerpt.length > maxChars
            ? `${excerpt.substring(0, maxChars - 3)}...`
            : excerpt}
        </div>
      )}
      {button}
    </>
  );

  const classNameString = classNames("no-style", "article-card", variant && `article-card--${variant}`, className);

  if (!href) {
    return (
      <div className={classNameString}>
        {inner}
      </div>
    );
  }

  return (
    <NavigationLink href={href} className={classNameString}>
      {inner}
    </NavigationLink>
  );
};

ArticleCard.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]),
  category: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  excerpt: PropTypes.string,
  headline: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  patientsJourney: PropTypes.bool,
  variant: PropTypes.oneOf(["learn-topic", "learn-article", "link-card"]),
};

ArticleCard.defaultProps = {
  button: null,
  category: null,
  className: "",
  date: null,
  excerpt: null,
  image: null,
  patientsJourney: false,
  variant: null,
};

export default memo(ArticleCard);
