import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import Hubsection from "../../components/hubsection/hubsection";
import IntroSection from "../../components/introSection/introSection";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemTargetGroupSection from "../kontentItemTargetGroupSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import ProgramTile from "../program/programTile";

const KontentItemProgramsOverview = ({ data }) => {
  const { page } = data;

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
      >
        {page.elements.hero_headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={data.page.id} />

      <KontentItemTargetGroupSection data={page.elements.target_groups} asImage />

      <IntroSection
        headline={page.elements.headline.value}
        text={page.elements.lead_text.value}
      />

      <Hubsection variant="program">
        {ProgramTile({ data: page.elements.program_tiles.value })}
      </Hubsection>

      {data.page.elements.page_sections.value.map((item) => mapKontentItem(item))}

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemProgramsOverview(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        lead_text {
          value
        }
        hero_headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        target_groups {
          value {
            ...KontentItemTargetGroupSection
          }
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemEyecatcherContact
            ...KontentItemIconCardSliderSection
            ...KontentItemEyecatcher
          }
        }
        program_tiles {
          value {
            ...ProgramTile
          }
        }
      }
    }
  }
`;

KontentItemProgramsOverview.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemProgramsOverview;
