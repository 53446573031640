const filterByCategory = (data, key, categories) => {
  let filteredData = [];
  data.forEach((node) => filteredData.push(node));

  filteredData = filteredData.filter((el) => {
    let filterObj = false;
    el.elements[key].value.forEach((cat) => {
      if (categories.includes(cat.codename)) {
        filterObj = true;
      }
    });

    return filterObj;
  });

  return filteredData;
};

export default filterByCategory;
