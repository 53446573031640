import React, { memo } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

const MetaSiteSettings = () => {
  const data = useStaticQuery(graphql`
    {
      siteSettings: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          translate {
            value {
              codename
            }
          }
        }
      }
    }
  `);

  try {
    if (data.siteSettings.elements.translate.value[0].codename !== "no") {
      return null;
    }
  } catch (e) {
    return null;
  }

  return (
    <Helmet
      htmlAttributes={{
        translate: "no",
      }}
      defer={false}
      meta={[
        {
          content: "notranslate",
          name: "robots",
        },
        {
          content: "notranslate",
          name: "googlebot",
        },
      ]}
    />
  );
};

export default memo(MetaSiteSettings);
