import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import TabNavigation from "../../components/tabNavigation/tabNavigation";

const TabNavigationGenerator = ({ id }) => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    {
      links: allInternalLinks {
        nodes {
          id
          title
          slug
          navigationItemId
        }
      }
      primaryNavigation: allKontentItemNavigationItem(filter: {system: {codename: {in: ["primary_navigation", "hidden_navigation"]}}}) {
        nodes {
          id
          system {
            codename
          }
          elements {
            elements {
              value {
                id
                preferred_language
                ... on kontent_item_navigation_item {
                  id,
                  elements {
                    title {
                      value
                    }
                    url_slug {
                      value
                    }
                    external_url {
                      value
                    }
                    elements {
                      value {
                        ... on kontent_item_navigation_item {
                          id
                          elements {
                            title {
                              value
                            }
                            url_slug {
                              value
                            }
                            external_url {
                              value
                            }
                            elements {
                              value {
                                ... on kontent_item_navigation_item {
                                  id
                                  elements {
                                    title {
                                      value
                                    }
                                    url_slug {
                                      value
                                    }
                                    external_url {
                                      value
                                    }
                                    elements {
                                      value {
                                        ... on kontent_item_navigation_item {
                                          id
                                          elements {
                                            title {
                                              value
                                            }
                                            url_slug {
                                              value
                                            }
                                            external_url {
                                              value
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (id) {
    const node = data.links.nodes.find(
      (child) => child.id.includes(id),
    );

    let currentItem = null;
    const simpleMenuArray = { root: [] };
    const rootCodeNames = {};

    const getSimpleMenuIdObject = (sourceNode, nodeId, parentId = null) => {
      if (
        !sourceNode
        || !Array.isArray(sourceNode)
        || (sourceNode.length === 1 && Object.keys(sourceNode[0]).length === 0)
      ) {
        return null;
      }

      return sourceNode
        .filter((item) => !(item && Object.keys(item).length === 0))
        .forEach((item) => {
          getSimpleMenuIdObject(item.elements.elements.value, nodeId, item.id);

          const nodeObject = {
            externalUrl: item.elements?.external_url?.value,
            id: item.id,
            parentId: parentId || "root",
            title: item.elements?.title?.value,
            urlSlug: item.elements?.url_slug?.value,
          };

          if (item.id === nodeId) {
            currentItem = nodeObject;
          }

          if (!parentId) {
            simpleMenuArray.root.push(nodeObject);
            rootCodeNames[item.id] = item.system?.codename;
          } else {
            if (!simpleMenuArray[parentId]) {
              simpleMenuArray[parentId] = [];
            }

            simpleMenuArray[parentId].push(nodeObject);
          }
        });
    };

    getSimpleMenuIdObject(data.primaryNavigation.nodes, node.navigationItemId);

    if (node) {
      const items = [];

      const rootId = simpleMenuArray[currentItem.id] ? currentItem.id : currentItem.parentId;

      const mainItem = simpleMenuArray[rootId];

      if (!mainItem) return null;

      Object.entries(simpleMenuArray)
        .every((simpleMenuArrayRoot) => simpleMenuArrayRoot[1]
          .every((simpleMenuArrayItem) => {
            if (simpleMenuArrayItem.id === rootId) {
              // Skip if root Element
              if (simpleMenuArrayItem.parentId) {
                const navigationItem = data.links.nodes.find(
                  (element) => element.navigationItemId === rootId,
                );

                items.push({
                  activeClassName: navigationItem.navigationItemId !== node.navigationItemId ? null : "active",
                  id: simpleMenuArrayItem.id,
                  link: simpleMenuArrayItem.id,
                  partiallyActive: false,
                  state: {
                    disableScrollUpdate: true,
                  },
                  title: rootCodeNames[simpleMenuArrayItem.parentId] !== "undefined" && rootCodeNames[simpleMenuArrayItem.parentId] === "hidden_navigation" && navigationItem.title
                    ? navigationItem.title
                    : intl.formatMessage({ id: "tab_navigation.overview" }),
                });
              }

              return false;
            }

            return true;
          }));

      mainItem.forEach((item) => {
        if (!item.id) return null;

        items.push({
          id: item.id,
          link: item.id,
          state: {
            disableScrollUpdate: true,
          },
          title: item.title,
        });

        return null;
      });

      if (items.length > 0) {
        return <TabNavigation items={items} />;
      }
    }
  }

  return null;
};

TabNavigationGenerator.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TabNavigationGenerator;
