import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";

import ComplianceConsentSlice from "../features/complianceConsentSlice";
import DonateFormSlice from "../features/donateFormSlice";
import IoBaseApiSlice from "../features/io/ioBaseApiSlice";
import { NetlifyApiSlice } from "../features/netlifyApiSlice";
import NotificationSlice from "../features/notificationSlice";
import RegistrationSlice from "../features/registrationSlice";
import SspSlice from "../features/sspSlice";
import TrackingSlice from "../features/trackingSlice";
import UpdateAddressSlice from "../features/updateAddressSlice";

export default () => {
  const store = configureStore({
    middleware: (getDefaultMiddleware) => (
      // see: https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
      // see: https://github.com/reduxjs/redux-toolkit/issues/415
      getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat([
        IoBaseApiSlice.middleware,
        NetlifyApiSlice.middleware,
      ])
    ),
    reducer: {
      [IoBaseApiSlice.reducerPath]: IoBaseApiSlice.reducer,
      [NetlifyApiSlice.reducerPath]: NetlifyApiSlice.reducer,
      complianceConsent: ComplianceConsentSlice,
      donateForm: DonateFormSlice,
      notification: persistReducer({
        key: "dkms-n",
        storage,
      }, NotificationSlice),
      registration: persistReducer({
        key: "dkms-reg",
        storage,
      }, RegistrationSlice),
      ssp: persistReducer({
        key: "dkms-ssp",
        storage: storageSession,
      }, SspSlice),
      tracking: persistReducer({
        key: "dkms-t",
        storage: storageSession,
      }, TrackingSlice),
      updateAddress: UpdateAddressSlice,
    },
  });

  const persistor = persistStore(store);

  return { persistor, store };
};
