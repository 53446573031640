import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ContentHeader from "../../components/contentHeader/contentHeader";
import NavigationLink from "../../components/navigationLink/navigationLink";
import SEO from "../../components/seo";
import { IconFurther18 } from "../../icons";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemImageCardSliderSection from "../kontentItemImageCardSliderSection";
import MainLayout from "../layout/mainLayout";

const KontentItemThankYouPage = ({ data: { page }, pageContext }) => (
  <MainLayout>

    <SEO
      title={page.elements.metadata__meta_title.value}
      description={page.elements.metadata__meta_description.value}
      twitterTitle={page.elements.metadata__twitter_title.value}
      twitterSite={page.elements.metadata__twitter_site.value}
      twitterImage={page.elements.metadata__twitter_image.value}
      twitterDescription={page.elements.metadata__twitter_description.value}
      twitterCreator={page.elements.metadata__twitter_creator.value}
      ogTitle={page.elements.metadata__og_title.value}
      ogImage={page.elements.metadata__og_image.value}
      ogDescription={page.elements.metadata__og_description.value}
      isHiddenForRobots={pageContext.seoNoIndex}
    />

    <ContentHeader
      headline={page.elements.headline.value}
      description={page.elements.text.value}
      media={page.elements.image.value.length > 0
        ? (
          <KontentAssetsImage
            data={page.elements.image.value[0]}
            height={150}
            className="img-fluid"
          />
        )
        : null}
      variant="thank-you"
      button={page.elements.button_url.value.length > 0
        ? (
          <NavigationLink
            className="icon-btn btn btn-inverted-registration"
            activeClassName={null}
            href={page.elements.button_url.value[0].id}
          >
            {page.elements.button_text.value} <IconFurther18 />
          </NavigationLink>
        )
        : null}
    />

    <KontentItemImageCardSliderSection data={page.elements.image_card_slider} />

  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    page: kontentItemThankYouPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        image {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        text {
          value
        }
        button_url {
          value {
            id
          }
        }
        button_text {
          value
        }
        image_card_slider {
          value {
            ...KontentItemImageCardSliderSection
          }
        }
      }
    }
  }
`;

KontentItemThankYouPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemThankYouPage;
