import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ArticleClosure from "../../components/articleClosure/articleClosure";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import KontentItemEyecatcher from "../kontentItemEyecatcher";
import KontentItemLocationsSection from "../kontentItemLocationsSection";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemProgram = ({ data, pageContext }) => {
  const headerImage = (data.page.elements.hero_image.value.length > 0
    && data.page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? data.page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={data.page.elements.metadata__meta_title.value}
        description={data.page.elements.metadata__meta_description.value}
        twitterTitle={data.page.elements.metadata__twitter_title.value}
        twitterSite={data.page.elements.metadata__twitter_site.value}
        twitterImage={data.page.elements.metadata__twitter_image.value.length > 0
          ? data.page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={data.page.elements.metadata__twitter_description.value}
        twitterCreator={data.page.elements.metadata__twitter_creator.value}
        ogTitle={data.page.elements.metadata__og_title.value}
        ogImage={data.page.elements.metadata__og_image.value.length > 0
          ? data.page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={data.page.elements.metadata__og_description.value}
        isHiddenForRobots={pageContext.seoNoIndex}
      />

      <SubNavigationGenerator id={data.page.id} />

      <HeroHeadline
        imageData={data.page.elements.hero_image}
        eyecatcher={
          data.page.elements.header_eyecatcher.value.length > 0
            ? <KontentItemEyecatcher data={data.page.elements.header_eyecatcher} />
            : null
        }
      >
        {data.page.elements.headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={data.page.id} />

      <KontentItemLocationsSection data={data.page.elements.location_list} />

      <RichText data={data.page.elements.body} isArticle />

      <Row>
        <Col sm={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
          <ArticleClosure />
        </Col>
      </Row>

      {data.page.elements.page_sections.value.map(
        (item) => mapKontentItem(item, { pageId: data.page.id }),
      )}

    </MainLayout>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemProgram(id: {eq: $id}) {
      id
      system {
        last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
      }
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        location_list {
          value {
            ...KontentItemLocationsSection
          }
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemRegistryBanner
            ...KontentItemImageCardSliderSection
            ...KontentItemProgramSliderSection
            ...KontentItemTestimonialSection
            ...KontentItemIconCardSliderSection
            ...KontentItemStatementboxGroup
          }
        }
        program_category {
          value {
            name
          }
        }
        header_eyecatcher {
          value {
            ...KontentItemEyecatcher
          }
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemStatementboxGroup
            ...KontentItemEmbedExternal
          }
        }
      }
    }
  }
`;

KontentItemProgram.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemProgram;
