import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import StatementBox from "../../components/statementBox/statementBox";
import KontentItemImage from "../kontentItemImage";
import SimpleRichText from "../text/simpleRichText";

const StatementBoxes = ({ data }) => {
  const statementData = Array.isArray(data) ? data : data.value;

  const statementList = [];

  statementData.forEach((statement) => {
    statementList.push(
      <StatementBox
        image={(
          <KontentItemImage
            data={statement.elements.image}
            width={800}
          />
        )}
        description={<SimpleRichText data={statement.elements.text} />}
        key={`statementBox${statement.id}`}
      />,
    );
  });

  return statementList;
};

export const query = graphql`
  fragment StatementBoxes on kontent_item_statementbox {
    id
    system {
      codename
    }
    elements {
      text {
        value
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

StatementBoxes.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default StatementBoxes;
