import IoBaseApiSlice from "./ioBaseApiSlice";

export const IoRegistrationApiSlice = IoBaseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    submitIoRegistration: build.mutation({
      query: ({ data, urns = null }) => {
        const urn = typeof data.registration_data?.roots_id !== "undefined"
          ? process.env.GATSBY_IO_URN_REGISTRATION_ROOTSID
          : process.env.GATSBY_IO_URN_REGISTRATION;

        return {
          apiRequirements: ["session", "csrfToken"],
          data: {
            classification: "Restricted",
            payload: data,
            ...(urns !== null && { meta: urns }),
          },
          method: "post",
          url: `${process.env.GATSBY_IO_HOST}/reg/${urn}`,
        };
      },
      transformResponse: ({ payload }) => payload,
    }),
  }),
});

export const {
  useSubmitIoRegistrationMutation,
} = IoRegistrationApiSlice;
