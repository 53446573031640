import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import { useLogoutMutation } from "../../../features/io/ioSspApiSlice";
import { IconLogout18 } from "../../../icons";

const LogoutButton = ({ className }) => {
  const [logout, logoutResult] = useLogoutMutation();

  return (
    <button
      type="button"
      onClick={() => {
        logout();
      }}
      className={className}
      disabled={logoutResult.isLoading}
    >
      {logoutResult.isLoading ? (
        <Spinner animation="border" variant="dkms-grey-dark" />
      ) : (
        <IconLogout18 />
      )}
      <FormattedMessage id="ssp.logout" />
    </button>
  );
};

LogoutButton.propTypes = {
  className: PropTypes.string,
};

LogoutButton.defaultProps = {
  className: "",
};

export default LogoutButton;
