import React from "react";

import classNames from "classnames";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import {
  IconExternalLink18,
  IconFax18,
  IconFurther18,
  IconMail18,
  IconMaps18,
  IconPhone18,
} from "../../icons";
import NavigationLink from "../navigationLink/navigationLink";

import "./locationCard.scss";

const LocationCard = (props) => {
  const {
    title,
    text,
    streetAddress,
    city,
    internalLink,
    internalLinkText,
    externalLink,
    email,
    phone,
    fax,
    compact,
  } = props;

  const intl = useIntl();

  const test = (
    <>
      {internalLink && internalLinkText && (
        <NavigationLink href={internalLink} title={internalLinkText}>
          <IconFurther18 />{internalLinkText}
        </NavigationLink>
      )}
      {externalLink && (
        <NavigationLink href={externalLink}>
          <IconExternalLink18 />{externalLink}
        </NavigationLink>
      )}
      {email && (
        <a href={`mailto:${email}`} title={intl.formatMessage({ id: "location_card.email" }, { email })}>
          <IconMail18 />{email}
        </a>
      )}
      {phone && (
        <a href={`tel:${phone}`} title={intl.formatMessage({ id: "location_card.phone" }, { phone })}>
          <IconPhone18 />{phone}
        </a>
      )}
      {fax && (
        <a href={`fax:${fax}`} title={intl.formatMessage({ id: "location_card.fax" }, { fax })}>
          <IconFax18 />{fax}
        </a>
      )}
    </>
  );

  return (
    <div className={classNames("location-card", { "location-card--compact": compact })}>
      <div className="location-card__title">{title}</div>
      <div className="location-card__text">{text}</div>
      <div className="location-card__address">
        <IconMaps18 />
        <div>
          {streetAddress}<br />
          {city}
        </div>
      </div>
      {test?.props?.children && (
        <div className="location-card__details">
          {test}
        </div>
      )}
    </div>
  );
};

LocationCard.propTypes = {
  city: PropTypes.string.isRequired,
  compact: PropTypes.bool,
  email: PropTypes.string,
  externalLink: PropTypes.string,
  fax: PropTypes.string,
  internalLink: PropTypes.string,
  internalLinkText: PropTypes.string,
  phone: PropTypes.string,
  streetAddress: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
};

LocationCard.defaultProps = {
  compact: false,
  email: "",
  externalLink: "",
  fax: "",
  internalLink: "",
  internalLinkText: "",
  phone: "",
  text: "",
};

export default LocationCard;
