import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemContact from "./kontentItemContact";
import KontentItemCtaButton from "./kontentItemCtaButton";
import UnifyEyecatcher from "../components/unifyEyecatcher/unifyEyecatcher";

const KontentItemEyecatcher = ({ data }) => {
  const eyecatcher = data.value ? data.value[0] : data;

  if (!eyecatcher || !eyecatcher.elements) {
    return null;
  }

  return (
    <UnifyEyecatcher
      title={eyecatcher.elements.title.value}
      text={eyecatcher.elements.text.value}
      contact={eyecatcher.elements.contact.value.length > 0
        ? <KontentItemContact data={eyecatcher.elements.contact} />
        : null}
      variant={eyecatcher.elements.eyecatcher_style_variant.value.length > 0
        && eyecatcher.elements.eyecatcher_style_variant.value[0].codename !== "default"
        ? eyecatcher.elements.eyecatcher_style_variant.value[0].codename
        : null}
      color={eyecatcher.elements.eyecatcher_color_variant.value.length > 0
        ? eyecatcher.elements.eyecatcher_color_variant.value[0].codename
        : null}
    >
      {eyecatcher.elements.buttons.value.map(
        (button) => (<KontentItemCtaButton data={button} key={`ctabutton${button.id}`} />),
      )}
    </UnifyEyecatcher>
  );
};

export const query = graphql`
  fragment KontentItemEyecatcher on kontent_item_eyecatcher {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      text {
        value
      }
      buttons {
        value {
          ...KontentItemCtaButton
        }
      }
      eyecatcher_color_variant {
        value {
          codename
        }
      }
      eyecatcher_style_variant {
        value {
          codename
        }
      }
      contact {
        value {
          ...KontentItemContact
        }
      }
    }
  }
`;

KontentItemEyecatcher.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEyecatcher;
