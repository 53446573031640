import React from "react";

import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import DropdownItem from "./dropdownItem";
import DropdownLanguage from "./dropdownLanguage";
import useLanguage from "../../hooks/useLanguage";
import {
  IconAfrica24,
  IconChile,
  IconExternalLink18,
  IconGermany,
  IconIndia,
  IconPoland,
  IconUk,
  IconUsa,
} from "../../icons";
import {
  COUNTRY_AFRICA_URL,
  COUNTRY_CHILE_URL,
  COUNTRY_GERMANY_URL,
  COUNTRY_INDIA_URL,
  COUNTRY_POLAND_URL,
  COUNTRY_UK_URL,
  COUNTRY_USA_URL,
} from "../../utils/constants";

const DropdownCountry = (props) => {
  const {
    country,
  } = props;

  const intl = useIntl();
  const { countryCode } = useLanguage();
  const currentDefaultCountry = country || countryCode;

  return (
    <DropdownLanguage
      id="dropdown-country"
      dropdownItems={
        [
          {
            item: <DropdownItem
              key="Germany"
              text={intl.formatMessage({ id: "country.germany" })}
              url={COUNTRY_GERMANY_URL}
              preIcon={<IconGermany />}
              postIcon={<IconExternalLink18 />}
              active={currentDefaultCountry === "de"}
            />,
            label: "menu",
            value: intl.formatMessage({ id: "country.germany" }),
          },
          {
            context: "menu",
            item: <DropdownItem
              key="Chile"
              text={intl.formatMessage({ id: "country.chile" })}
              url={COUNTRY_CHILE_URL}
              preIcon={<IconChile />}
              postIcon={<IconExternalLink18 />}
              active={currentDefaultCountry === "cl"}
            />,
            value: intl.formatMessage({ id: "country.chile" }),
          },
          {
            context: "menu",
            item: <DropdownItem
              key="India"
              text={intl.formatMessage({ id: "country.india" })}
              url={COUNTRY_INDIA_URL}
              preIcon={<IconIndia />}
              postIcon={<IconExternalLink18 />}
              active={currentDefaultCountry === "in"}
            />,
            value: intl.formatMessage({ id: "country.india" }),
          },
          {
            context: "menu",
            item: <DropdownItem
              key="Poland"
              text={intl.formatMessage({ id: "country.poland" })}
              url={COUNTRY_POLAND_URL}
              preIcon={<IconPoland />}
              postIcon={<IconExternalLink18 />}
              active={currentDefaultCountry === "pl"}
            />,
            value: intl.formatMessage({ id: "country.poland" }),
          },
          {
            context: "menu",
            item: <DropdownItem
              key="United States"
              text={intl.formatMessage({ id: "country.usa" })}
              url={COUNTRY_USA_URL}
              preIcon={<IconUsa />}
              postIcon={<IconExternalLink18 />}
              active={currentDefaultCountry === "us"}
            />,
            value: intl.formatMessage({ id: "country.usa" }),
          },
          {
            context: "menu",
            item: <DropdownItem
              key="United Kingdom"
              text={intl.formatMessage({ id: "country.uk" })}
              url={COUNTRY_UK_URL}
              preIcon={<IconUk />}
              postIcon={<IconExternalLink18 />}
              active={currentDefaultCountry === "gb"}
            />,
            value: intl.formatMessage({ id: "country.uk" }),
          },
          {
            context: "menu",
            item: <DropdownItem
              key="Africa"
              text={intl.formatMessage({ id: "country.africa" })}
              url={COUNTRY_AFRICA_URL}
              preIcon={<IconAfrica24 />}
              postIcon={<IconExternalLink18 />}
              active={currentDefaultCountry === "za"}
            />,
            value: intl.formatMessage({ id: "country.africa" }),
          },
        ]
      }
    />
  );
};

DropdownCountry.propTypes = {
  country: PropTypes.oneOf(["de", "us", "gb", "in", "cl", "pl", "za"]),
};

DropdownCountry.defaultProps = {
  country: null,
};

export default DropdownCountry;
