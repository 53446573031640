import React from "react";

import * as yup from "yup";

import ConfigTemplates from "./descriptions/config-templates";
import Templates from "./templates";
import Tooltip from "./tooltip";
import { dateFactory } from "../../utils/dateHelper";
import { camelCase } from "../../utils/stringUtilHelper";
import RadioButtonList from "../radioButtonList/radioButtonList";

export const build = (field, options) => {
  let output = "";
  const type = camelCase(field.tagName);
  const TagName = Templates[`${type.charAt(0).toUpperCase()}${type.slice(1)}`];

  if (TagName) {
    // Todo: Never reached
    if (!TagName) {
      return <div className="alert alert-warning">## missing element ##</div>;
    }

    try {
      switch (field.tagName) {
        case "RadioInput":
        case "SelectInput":
          output = (
            <TagName
              key={field.id}
              options={field.options}
              {...field.props}
              {...options}
              {...field.style}
            />
          );
          break;
        case "RadioButtonSection":
          output = (
            <TagName
              key={field.id}
              notice={field.props.notice ? field.props.notice : null}
              {...field.style}
            >
              {field.props.radioButtonLists.map(
                (item) => (
                  <RadioButtonList key={item.key} {...item} {...options} />
                ),
              )}
            </TagName>
          );
          break;
        case "DateInput":
          output = (
            <TagName
              key={field.id}
              {...field.props}
              {...field.style}
              {...options}
              value={dateFactory(options.values[field.props.name])}
            />
          );
          break;
        default:
          output = <TagName key={field.id} {...field.props} {...field.style} {...options} />;
      }
    } catch (e) {
      output = "Error";
    }
  }

  return (
    <Tooltip
      id={field.props.id}
      tooltipMessage={field.tooltipMessage}
    >
      {output}
    </Tooltip>
  );
};

export const createYupSchema = (schema, config) => {
  const { id, validationType, validations = [] } = config;

  if (!yup[validationType]) {
    return schema;
  }

  let validator = yup[validationType]();

  validations.forEach((validation) => {
    const { params, type } = validation;

    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });

  // eslint-disable-next-line no-param-reassign
  schema[id] = validator;

  return schema;
};

export const generateInitialValues = (inputs) => inputs.reduce((obj, field) => {
  if (field.tagName === "RadioButtonSection") {
    field.props.radioButtonLists.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      obj[item.id] = item.initialValue;
    });
  }

  // eslint-disable-next-line no-param-reassign
  obj[field.props.id] = field.initialValue;

  return obj;
}, {});

export const getTemplate = (key) => ConfigTemplates[`${key}_${process.env.GATSBY_SITE}`];

export const clean = (object) => {
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === "object") {
        clean(v);
      }

      if (
        (v && typeof v === "object" && !Object.keys(v).length)
        || v === null || v === undefined || v === ""
      ) {
        if (Array.isArray(object)) {
          object.splice(k, 1);
        } else {
          // eslint-disable-next-line no-param-reassign
          delete object[k];
        }
      }
    });

  return object;
};
