import React from "react";

import PropTypes from "prop-types";

import "./paymentMethods.scss";

const PaymentMethods = ({ children }) => (
  <div className="payment-methods">
    {children}
  </div>
);

PaymentMethods.propTypes = {
  children: PropTypes.node,
};

PaymentMethods.defaultProps = {
  children: null,
};

export default PaymentMethods;
