import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ContentHeader from "../../components/contentHeader/contentHeader";
import UpdateAddress from "../../components/localForms/updateAddress";
import UpdateMailAddress from "../../components/localForms/updateMailAddress";
import SEO from "../../components/seo";
import getInternalLinkById from "../../utils/getInternalLinkById";
import KontentAssetsImage from "../kontentAssetsImage";
import KontentItemEyecatcher from "../kontentItemEyecatcher";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";

const KontentItemUpdateAddress = ({ data }) => {
  const { page } = data;
  let thankYouPageLink = null;

  if (
    Array.isArray(page.elements.thank_you_page.value)
    && page.elements.thank_you_page.value.length > 0
    && page.elements.thank_you_page.value[0].id
  ) {
    thankYouPageLink = getInternalLinkById(page.elements.thank_you_page.value[0].id)?.slug;
  }

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
        isHiddenForRobots={page.elements.url_pattern.value === "jad-19052"}
      />

      <SubNavigationGenerator id={page.id} />

      <BreadcrumbGenerator id={page.id} />

      <ContentHeader
        headline={page.elements.headline.value}
        description={page.elements.lead_text.value}
        media={page.elements.image.value.length > 0
          ? (
            <KontentAssetsImage
              height={147}
              data={page.elements.image.value[0]}
            />
          )
          : null}
      />

      <KontentItemEyecatcher data={page.elements.eyecatcher_profile} />

      {page.elements.url_pattern.value === "jad-19052"
        ? (
          <UpdateMailAddress
            thankYouPageLink={thankYouPageLink}
            privacyConsent={page.elements.privacy_consent}
          />
        )

        : (
          <UpdateAddress
            thankYouPageLink={thankYouPageLink}
            privacyConsent={page.elements.privacy_consent}
          />
        )}

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    page: kontentItemUpdateAddress(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        url_privacy_policy {
          value {
            id
          }
        }
        privacy_consent {
          name
          value
          links {
            codename
            link_id
            type
            url_slug
          }
          images {
            description
            height
            image_id
            url
            width
          }
        }
        thank_you_page {
          name
          value {
            id
          }
        }
        headline {
          value
        }
        image {
          value {
            description
            height
            size
            name
            type
            url
            width
          }
        }
        lead_text {
          value
        }
        text_url_generation {
          value
        }
        url_pattern {
          value
        }
        eyecatcher_profile {
          value {
            ...KontentItemEyecatcher
          }
        }
      }
    }
  }
`;

KontentItemUpdateAddress.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemUpdateAddress;
