import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Quote from "../components/quote/quote";

const KontentItemQuote = ({ data }) => {
  const quote = data.value ? data.value[0] : data;

  if (!quote || !quote.elements) {
    return null;
  }

  return (
    <Quote
      quote={quote.elements.quote_text.value}
      color={quote.elements.color.value.length > 0 && quote.elements.color.value[0].codename !== "purple"
        ? quote.elements.color.value[0].codename.replace("_", "-")
        : "primary"}
      isParagraphQuote={quote.elements.paragraph_quote.value.length > 0}
    />
  );
};

export const query = graphql`
  fragment KontentItemQuote on kontent_item_quote {
    id
    system {
      codename
    }
    elements {
      quote_text {
        value
      }
      color {
        value {
          codename
        }
      }
      paragraph_quote {
        value {
          codename
        }
      }
    }
  } 
`;

KontentItemQuote.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemQuote;
