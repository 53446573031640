import React, { useRef } from "react";

import PropTypes from "prop-types";

import { IconLearnMore18 } from "../../icons";
import LinkButton from "../button/linkButton";
import TextLightbox from "../lightbox/textLightbox";

import "./modalView.scss";

const ModalView = (props) => {
  const {
    buttonText,
    title,
    text,
    variant,
    removePostIcon,
    overWriteButton,
    usePreIcon,
    buttonVariant,
  } = props;

  const textLightbox = useRef(null);

  return (
    <>
      {overWriteButton
        ? React.cloneElement(overWriteButton, {
          className: "modal-view",
          onClick: () => {
            textLightbox.current.show();
          },
        })
        : (
          <LinkButton
            onClick={() => {
              textLightbox.current.show();
            }}
            className="modal-view"
            variant={buttonVariant}
            title={title}
          >
            {usePreIcon && <IconLearnMore18 />}
            {buttonText}
            {(!removePostIcon && !usePreIcon) && <IconLearnMore18 />}
          </LinkButton>
        )}
      <TextLightbox
        variant={variant}
        title={title}
        ref={textLightbox}
      >
        {text}
      </TextLightbox>
    </>
  );
};

ModalView.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonVariant: PropTypes.oneOf(["button", "normal", "text-button-small", "text-button-large", "typo-button"]),
  overWriteButton: PropTypes.oneOfType([PropTypes.object]),
  removePostIcon: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  usePreIcon: PropTypes.bool,
  variant: PropTypes.string,
};

ModalView.defaultProps = {
  buttonVariant: "normal",
  overWriteButton: null,
  removePostIcon: false,
  usePreIcon: false,
  variant: null,
};

export default ModalView;
