import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import * as Sentry from "@sentry/gatsby";
import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";

import RegistrationDialogFaq from "./registrationDialogFaq";
import { useGetPaymentLinkMutation } from "../../features/io/ioMoneyDonationApiSlice";
import { useSubmitIoRegistrationMutation } from "../../features/io/ioRegistrationApiSlice";
import { useSubmitIoVirtualDriveMutation } from "../../features/io/ioVirtualDriveApiSlice";
import {
  replaceRegistrationData,
  resetRegistration,
  resetVirtualDriveSetup,
  setError,
  setMaxDonate,
  setNavigationHeaderEnabled,
  setPaymentData,
  setShowRegistrationReminder,
  setStepError,
} from "../../features/registrationSlice";
import {
  IconAnchorLeft18,
  IconClose18,
  IconDonateDe30,
  IconDonateIn30,
  IconDonatePl30,
  IconDonateUk30,
  IconDonateUsCl30,
} from "../../icons";
import DataLayer from "../../utils/dataLayer";
import getInternalLinkById from "../../utils/getInternalLinkById";
import Button from "../button/button";
import LinkButton from "../button/linkButton";
import LeaveFormPopup from "../leaveFormPopup/leaveFormPopup";
import TextLightbox from "../lightbox/textLightbox";
import BottomFooterNavigation from "../navigation/bottomFooterNavigation";
import PaymentDataHelper from "../paymentDialog/paymentDataHelper";

import "./registrationDialog.scss";

const RegistrationDialog = (props) => {
  const {
    dialogType,
    steps,
    content,
    rootsId,
    image,
  } = props;

  const setupData = useSelector((state) => state.registration.setupData);
  const maxDonate = useSelector((state) => state.registration.maxDonate);
  const success = useSelector((state) => state.registration.success);
  const error = useSelector((state) => state.registration.error);
  const isSepa = useSelector((state) => state.registration.isSepa);
  const isNavigationHeaderEnabled = useSelector((state) => (
    state.registration.isNavigationHeaderEnabled
  ));
  const registrationData = useSelector((state) => state.registration.registrationData);
  const stepError = useSelector((state) => state.registration.stepError);
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);

  const intl = useIntl();

  const [, submitIoRegistrationResult] = useSubmitIoRegistrationMutation({ fixedCacheKey: "submitIoRegistrationCacheKey" });
  const [, submitIoVirtualDriveResult] = useSubmitIoVirtualDriveMutation({ fixedCacheKey: "submitIoVirtualDriveCacheKey" });
  const [, paymentLinkResult] = useGetPaymentLinkMutation({ fixedCacheKey: "getPaymentLinkCacheKey" });

  const dialogBody = useRef(null);
  const leaveFormDialog = useRef(null);
  const prefillFormDialog = useRef(null);

  const isDialogTypeRegistration = dialogType === "registration-dialog";

  const exitSlug = `${process.env.GATSBY_SITE_URL}${getInternalLinkById(content.buttonExitUrl)?.slug}`;

  const progressPercentage = (100 / (steps.length)) * (currentStep + 1);

  const showLeaveFormDialog = () => {
    leaveFormDialog.current?.show();
  };

  const confirmClose = (e) => {
    const confirmationMessage = "o/";

    if (window.skipBeforeUnload === true) {
      return null;
    }

    e.preventDefault();
    window.event.preventDefault();
    (e || window.event).returnValue = confirmationMessage;

    return confirmationMessage;
  };

  const closeActions = () => {
    if (currentStep > 0 && isDialogTypeRegistration) {
      dispatch(
        setShowRegistrationReminder(true),
      );
    }
  };

  const parsePrefillData = (pushPiwikEvent = false) => {
    if (typeof window === "undefined") {
      return null;
    }

    const queryParameters = new URLSearchParams(window.location.search);

    if (!queryParameters.has("data")) {
      return null;
    }

    const base64Data = queryParameters.get("data");

    if (base64Data === null) {
      return null;
    }

    try {
      // needed to properly decode special chars and umlauts.
      const byteData = Uint8Array.from(atob(base64Data), (m) => m.codePointAt(0));
      const data = JSON.parse(new TextDecoder().decode(byteData));

      if (pushPiwikEvent && typeof data.campaign_id !== "undefined") {
        DataLayer.pushEvent("ppe_campaign_id", {
          event_value: data.campaign_id,
        });
      }

      // remove all unknown fields from data object and sanitize everything else.
      Object
        .keys(data)
        .forEach((key) => {
          if (typeof registrationData[key] === "undefined") {
            delete data[key];

            return;
          }

          data[key] = sanitizeHtml(data[key]);
        });

      if (Object.keys(data).length > 0) {
        return data;
      }
    } catch (exception) {
      Sentry.captureException(exception);
    }

    return null;
  };

  useLayoutEffect(() => {
    if (typeof window !== "undefined" && dialogType === "payment") {
      const queryParameters = new URLSearchParams(window.location.search);
      const paymentDialogAmount = queryParameters.get("amount");
      const paymentDialogInterval = queryParameters.get("interval");

      // Todo: Check if interval is a valid option for the current site.

      if (paymentDialogAmount && paymentDialogInterval) {
        dispatch(
          setPaymentData({
            amount: paymentDialogAmount,
            interval: paymentDialogInterval,
          }),
        );

        if (
          (PaymentDataHelper.maxAmount[process.env.GATSBY_SITE][paymentDialogInterval]
          && parseFloat(paymentDialogAmount) >= PaymentDataHelper
            .maxAmount[process.env.GATSBY_SITE][paymentDialogInterval])
          || parseFloat(paymentDialogAmount) >= PaymentDataHelper
            .maxAmount[process.env.GATSBY_SITE].ONCE
        ) {
          dispatch(
            setMaxDonate(true),
          );
        } else {
          dispatch(
            setMaxDonate(false),
          );
          DataLayer.pushEvent("pp_donation_step_amount_and_interval", { event_value: "success" });
        }

        setCurrentStep(1);
      }
    }
  }, []);

  useEffect(() => {
    window.skipBeforeUnload = currentStep === 0 || currentStep === steps.length - 1;
    DataLayer.currentStep = steps[currentStep]?.stepName;
  }, [currentStep]);

  // componentDidMount
  useEffect(() => {
    dispatch(
      setNavigationHeaderEnabled(true),
    );
    const queryParameters = new URLSearchParams(window.location.search);
    const urlId = queryParameters.get("key");
    switch (dialogType) {
      case "virtual-drive-setup":
        if (submitIoVirtualDriveResult.isSuccess || submitIoVirtualDriveResult.isError) {
          dispatch(
            resetVirtualDriveSetup(),
          );
          submitIoVirtualDriveResult.reset();
        }
        break;

      case "payment":
        if (paymentLinkResult.isSuccess || paymentLinkResult.isError) {
          paymentLinkResult.reset();
        }

        if (urlId !== null) {
          queryParameters.delete("key");

          window.history.replaceState(
            {},
            document.title,
            window.location.pathname + (queryParameters.size > 0 ? `?${queryParameters.toString()}` : ""),
          );
        }
        dispatch(
          setPaymentData({ donationBoxId: urlId }),
        );

        dispatch(
          setError(false),
        );

        dispatch(
          setStepError(false),
        );
        break;

      default:
        if (parsePrefillData(true) !== null) {
          prefillFormDialog.current.show();
        }

        if (success) {
          dispatch(
            resetRegistration(),
          );
          submitIoRegistrationResult.reset();
        }

        dispatch(
          setError(false),
        );

        dispatch(
          setStepError(false),
        );
    }

    if (currentStep > 0 && isDialogTypeRegistration) {
      dispatch(
        setShowRegistrationReminder(true),
      );
    }

    if (typeof window !== "undefined") {
      window.skipBeforeUnload = false;

      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", showLeaveFormDialog);
      window.addEventListener("beforeunload", confirmClose);
      window.addEventListener("unload", closeActions);
      switch (dialogType) {
        case "payment":
          DataLayer.dialogType = "payment";
          DataLayer.addUnloadListener();
          DataLayer.pushEvent("pp_donation_step_start", { event_value: "success" });
          break;
        default:
          DataLayer.dialogType = "registration";
          DataLayer.addUnloadListener();
      }
    }

    // componentDidUnmount
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("popstate", showLeaveFormDialog);
        window.removeEventListener("beforeunload", confirmClose);
        window.removeEventListener("unload", closeActions);
        switch (dialogType) {
          case "payment":
            DataLayer.removeUnloadListener();
            break;
          default:
            DataLayer.removeUnloadListener();
        }
      }
    };
  }, []);

  const back = () => {
    switch (dialogType) {
      case "virtual-drive-setup":
        if (error) {
          dispatch(
            setError(false),
          );
        } else if (currentStep === 2 && setupData.motivation === "mission") {
          setCurrentStep(currentStep > 1 ? currentStep - 2 : 0);
        } else if (currentStep > 0) {
          setCurrentStep(currentStep - 1);
        }
        break;

      case "payment":
        if (error) {
          dispatch(
            setError(false),
          );
        } else if (currentStep > 0) {
          dispatch(
            setMaxDonate(false),
          );
          setCurrentStep(currentStep - 1);
        }
        break;

      default:
        if (error || stepError) {
          dispatch(
            setError(false),
          );

          dispatch(
            setStepError(false),
          );
        } else if (currentStep > 0) {
          window.history.pushState(null, document.title, window.location.href);

          setCurrentStep(currentStep - 1);
        }
    }
  };

  const next = (skip = false) => {
    if (steps.length - 1 > currentStep) {
      // IE
      if (dialogBody.current?.scrollTo !== undefined) {
        dialogBody.current.scrollTo(0, 0);
      }

      if (typeof window !== "undefined" && window.scrollTo !== undefined) {
        window.scrollTo(0, 0);
      }

      setCurrentStep(currentStep + (skip ? 2 : 1));
    }
  };

  const renderSteps = () => {
    let step;

    switch (dialogType) {
      case "virtual-drive-setup":
        step = <FormattedMessage id="virtual_drive_setup.submit_your_drive" />;
        break;
      case "payment":
        step = <FormattedMessage id="payment.dialog.payment" />;
        break;
      default:
        step = <FormattedMessage id="registration.dialog.registration" />;
    }

    return step;
  };

  // Reset navigation header
  if (
    currentStep === 0
    && !error
    && isDialogTypeRegistration
  ) {
    dispatch(
      setNavigationHeaderEnabled(true),
    );
  }

  let backButton;
  let cancelButton;
  let leavePopupExit;
  let leavePopupContinue;
  let buttonVariant;
  let stepsLabel;
  let backButtonTerm = (currentStep === 0 && !error && !maxDonate);
  const countryDonateIcons = {
    cl: <IconDonateUsCl30 className="text-secondary" />,
    de: <IconDonateDe30 className="text-secondary" />,
    gb: <IconDonateUk30 className="text-secondary" />,
    in: <IconDonateIn30 className="text-secondary" />,
    pl: <IconDonatePl30 className="text-secondary" />,
    us: <IconDonateUsCl30 className="text-secondary" />,
    za: <IconDonateUsCl30 className="text-secondary" />,
  };
  switch (dialogType) {
    case "virtual-drive-setup":
      cancelButton = <FormattedMessage id="virtual_drive_setup.cancel" />;
      backButton = "virtual_drive_setup.dialog.back";
      leavePopupExit = <FormattedMessage id="virtual_drive_setup.button_go_to_homepage" />;
      buttonVariant = "registration";
      leavePopupContinue = <FormattedMessage id="virtual_drive_setup.button_continue_setup" />;
      stepsLabel = "virtual_drive_setup.dialog.step";
      break;
    case "payment":
      cancelButton = <FormattedMessage id="payment_dialog.cancel" />;
      backButton = "payment_dialog.back";
      leavePopupContinue = content?.buttonTextContinue;
      leavePopupExit = content?.buttonExitText;
      buttonVariant = "donation";
      backButtonTerm = (currentStep === 0 && !error && !maxDonate);
      stepsLabel = "payment.dialog.step";
      break;
    default:
      buttonVariant = "registration";
      leavePopupContinue = content?.buttonTextContinue;
      leavePopupExit = content?.buttonExitText;
      backButton = "registration.dialog.back";
      stepsLabel = "registration.dialog.step";
  }

  if (typeof window === "undefined") {
    return null;
  }

  return (
    <>
      <Row className="h-100 row-md-down--fullwidth">
        <Col lg={4} className="registration-dialog__left-column">
          <RegistrationDialogFaq
            content={content}
            stepNames={steps.map((step) => step.stepName)}
            dialogType={dialogType}
            currentStep={currentStep}
            image={image}
          />
        </Col>
        <Col lg={{ offset: 1, span: 7 }} className="registration-dialog__right-column">
          <div
            className={classNames("registration-dialog", { [`${dialogType}-dialog`]: dialogType !== "registration-dialog" })}
          >
            {isNavigationHeaderEnabled && (
              <div className="registration-dialog__header">
                {!stepError ? (
                  <LinkButton
                    variant="normal"
                    className={classNames({ "d-none": backButtonTerm })}
                    onClick={() => {
                      back();
                    }}
                  >
                    <IconAnchorLeft18 />
                    <FormattedMessage id={backButton} />
                  </LinkButton>
                ) : (
                  <div />
                )}
                {!stepError && !error && !maxDonate && !isSepa && currentStep === 0 && (
                  <div className="registration-dialog__step">
                    <>
                      {renderSteps()}
                    </>
                  </div>
                )}
                {isSepa && <div />}
                <div>
                  <LinkButton
                    variant="normal"
                    className="d-inline"
                    onClick={showLeaveFormDialog}
                  >
                    {cancelButton}
                    <IconClose18 />
                  </LinkButton>
                </div>
              </div>
            )}
            {currentStep < steps.length - 1 && !isSepa && (
              <div className="registration-dialog__progress-bar">
                <div
                  className="registration-dialog__step-success"
                  style={{ width: `${progressPercentage}%` }}
                />
                <div
                  className="registration-dialog__step-remaining"
                  style={{ width: `${100.00 - progressPercentage}%` }}
                />
              </div>
            )}
            <div
              className="registration-dialog__body"
              ref={dialogBody}
            >
              <div className={classNames(
                "registration-dialog-step",
                {
                  "step-success": (currentStep === steps.length - 1 || isSepa),
                  [`${dialogType}-dialog-step`]: dialogType !== "registration-dialog",
                },
              )}
              >
                {!error && !stepError && steps.length !== 0 && !isSepa && (
                  <div className="registration-dialog__steps"><FormattedMessage
                    id={stepsLabel}
                    values={{ number: currentStep + 1 }}
                  />
                  </div>
                )}

                {React.createElement(steps[currentStep], {
                  content,
                  next,
                  rootsId,
                })}
              </div>
              <div className="registration-dialog-faq__footer d-lg-none">
                <BottomFooterNavigation isOpenInNewTab />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <TextLightbox ref={leaveFormDialog}>
        <LeaveFormPopup
          isVirtualDriveSetup={dialogType === "virtual-drive-setup"}
          headline={content?.textLeaveIntent}
          icon={dialogType === "payment" ? countryDonateIcons[process.env.GATSBY_SITE] : null}
        >
          <Button
            onClick={() => {
              leaveFormDialog.current?.close();
            }}
            variant={buttonVariant}
          >
            {leavePopupContinue}
          </Button>
          <Button
            onClick={() => {
              if (currentStep > 0 && isDialogTypeRegistration) {
                dispatch(
                  setShowRegistrationReminder(true),
                );
              }

              if (dialogType === "registration-dialog") {
                window.skipBeforeUnload = true;
              }

              window.location = exitSlug;
            }}
            variant={`inverted-${buttonVariant}`}
          >
            {leavePopupExit}
          </Button>
        </LeaveFormPopup>
      </TextLightbox>

      <TextLightbox ref={prefillFormDialog}>
        <LeaveFormPopup
          headline={intl.formatMessage({ id: "registration.prefill_dialog.message" })}
        >
          <Button
            onClick={() => {
              dispatch(replaceRegistrationData(parsePrefillData()));

              // remove all parameters from URL without reloading.
              window.history.replaceState({}, document.title, window.location.pathname);

              prefillFormDialog.current?.close();
            }}
            variant="registration"
          >
            <FormattedMessage id="registration.prefill_dialog.button_prefill" />
          </Button>
          <Button
            onClick={() => {
              // remove all parameters from URL without reloading.
              window.history.replaceState({}, document.title, window.location.pathname);

              prefillFormDialog.current?.close();
            }}
            variant="inverted-registration"
          >
            <FormattedMessage id="registration.prefill_dialog.button_discard" />
          </Button>
        </LeaveFormPopup>
      </TextLightbox>
    </>
  );
};

RegistrationDialog.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dialogType: PropTypes.oneOf(["virtual-drive-setup", "payment", "registration-dialog"]),
  image: PropTypes.string.isRequired,
  rootsId: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ])).isRequired,
};

RegistrationDialog.defaultProps = {
  dialogType: "registration-dialog",
  rootsId: null,
};

export default RegistrationDialog;
