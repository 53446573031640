import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { setShowComplianceSettings } from "../../features/complianceConsentSlice";
import Button from "../button/button";
import NavigationLink from "../navigationLink/navigationLink";

const BottomFooterNavigation = ({ isOpenInNewTab }) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const data = useStaticQuery(graphql`
    {
      bottomFooterNavigation: kontentItemNavigationItem(system: { codename: { eq: "bottom_footer_navigation" } } ) {
        id
        elements {
          elements {
            value {
              id
              typename: __typename
              preferred_language
              ... on kontent_item_navigation_item {
                id
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const openCookieConsentBanner = () => {
    dispatch(
      setShowComplianceSettings(true),
    );
  };

  const footerBottomNavigation = [];
  let extras = null;

  if (data.bottomFooterNavigation) {
    if (isOpenInNewTab) {
      extras = { target: "_blank" };
    }

    data.bottomFooterNavigation.elements.elements.value.forEach((item) => {
      footerBottomNavigation.push(
        <React.Fragment key={item.elements.title.value}>
          <NavigationLink {...item} {...extras} />
        </React.Fragment>,
      );
    });

    /**
     * Inserting a button to open up the cookie consent banner
     * from Piwik Pro
     */
    footerBottomNavigation.push(
      <React.Fragment key="consent-management">
        <Button
          type="button"
          className="consent-management-button"
          onClick={openCookieConsentBanner}
          variant="link"
          title={intl.formatMessage({ id: "consent.management" })}
        >
          {intl.formatMessage({ id: "consent.management" })}
        </Button>
      </React.Fragment>,
    );
  }

  return footerBottomNavigation;
};

BottomFooterNavigation.propTypes = {
  isOpenInNewTab: PropTypes.bool,
};

BottomFooterNavigation.defaultProps = {
  isOpenInNewTab: false,
};

export default BottomFooterNavigation;
