import React, { useEffect } from "react";

import { Formik } from "formik";
import { navigate } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { bool, object, string } from "yup";

import { useSubmitIoWebToCaseMutation } from "../../features/io/ioWebToCaseApiSlice";
import { IconError18, IconRetry24 } from "../../icons";
import { notificationService } from "../../services/notification.service";
import DataLayer from "../../utils/dataLayer";
import { cleanupObject } from "../../utils/formik/objectUtils";
import ScrollToFieldError from "../../utils/formik/scrollToFieldError";
import LinkButton from "../button/linkButton";
import Checkbox from "../dynamicForm/checkbox/checkbox";
import SubmitButton from "../dynamicForm/submitButton";

import "./webToCase.scss";

const WebToCase = ({ thankYouPageLink, formSlug }) => {
  const intl = useIntl();

  const [submitIoWebToCase, submitIoWebToCaseResult] = useSubmitIoWebToCaseMutation();

  const initialValues = {
    description: "",
    donor: false,
    email: "",
    firstname: "",
    phone: "",
    surname: "",
  };

  const validationSchema = object().shape({
    description: string()
      .required(intl.formatMessage({ id: "web_to_case.error.description" })),
    donor: bool(),
    email: string()
      .required(intl.formatMessage({ id: "web_to_case.error.email" }))
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(intl.formatMessage({ id: "web_to_case.error.email" })),
    firstname: string()
      .required(intl.formatMessage({ id: "web_to_case.error.name" }))
      .matches(/^[^\d]+$/, intl.formatMessage({ id: "form.error.only_alphabetic_chars" })),
    phone: string()
      .matches(
        /^[\d\s\-+/]{7,20}$/,
        intl.formatMessage({ id: "form.error.phone_pattern" }),
      ),
    surname: string()
      .required(intl.formatMessage({ id: "web_to_case.error.name" }))
      .matches(/^[^\d]+$/, intl.formatMessage({ id: "form.error.only_alphabetic_chars" })),
  });

  useEffect(() => {
    if (submitIoWebToCaseResult.isSuccess) {
      const { slug } = thankYouPageLink;
      DataLayer.pushEvent(`ppfs_df_success_${formSlug}`);
      navigate(slug);
    }

    if (submitIoWebToCaseResult.isError) {
      notificationService.error(intl.formatMessage({ id: "web_to_case.submit_error" }), {
        action: (
          <LinkButton onClick={() => {}}>
            <FormattedMessage id="web_to_case.retry" /> <IconRetry24 />
          </LinkButton>),
        autoClose: false,
        dismissButtonText: <FormattedMessage id="web_to_case.hide" />,
      });
    }
  }, [submitIoWebToCaseResult]);

  return (
    <Row className="web-to-case">
      <Col sm={12} xl={8}>
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formikBag) => {
            formikBag.setSubmitting(true);
            submitIoWebToCase({
              data: cleanupObject({
                description: {
                  description: values.description,
                },
                donorcheckbox: {
                  donorcheckbox: values.donor,
                },
                person: {
                  firstname: values.firstname,
                  lastname: values.surname,
                },
                private_contact: {
                  email: values.email,
                  phone: values.phone,
                },
              }),
            });
          }}
        >{({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group className="required">
                  <Form.Control
                    type="text"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={(errors.firstname && touched.firstname)}
                    maxLength={40}
                  />
                  <Form.Label>
                    <FormattedMessage id="web_to_case.firstname" />
                  </Form.Label>
                  {errors.firstname && touched.firstname && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.firstname}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="required">
                  <Form.Control
                    type="text"
                    name="surname"
                    value={values.surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={(errors.surname && touched.surname)}
                    maxLength={40}
                  />
                  <Form.Label>
                    <FormattedMessage id="web_to_case.surname" />
                  </Form.Label>
                  {errors.surname && touched.surname && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.surname}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group className="required">
                  <Form.Control
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={(errors.email && touched.email)}
                    maxLength={40}
                  />
                  <Form.Label>
                    <FormattedMessage id="web_to_case.email" />
                  </Form.Label>
                  {errors.email && touched.email && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.email}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={(errors.phone && touched.phone)}
                    maxLength={20}
                  />
                  <Form.Label>
                    <FormattedMessage id="web_to_case.phone" />
                  </Form.Label>
                  {errors.phone && touched.phone && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.phone}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12}>
                <Checkbox
                  id="donor"
                  name="donor"
                  checked={values.donor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  isInvalid={(errors.donor && touched.donor)}
                  label={intl.formatMessage({ id: "web_to_case.donor" })}
                  errors={errors}
                  feedback={errors.donor}
                  className="checkbox"
                />
              </Col>

              <Col xs={12}>
                <Form.Group className="required">
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={(errors.description && touched.description)}
                  />
                  <Form.Label>
                    <FormattedMessage id="web_to_case.description" />
                  </Form.Label>
                  {errors.description && touched.description && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.description}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <SubmitButton
                  label={intl.formatMessage({ id: "web_to_case.submit" })}
                  disabled={submitIoWebToCaseResult.isLoading}
                  loading={submitIoWebToCaseResult.isLoading}
                />
              </Col>

              <Col className="footnote--required">
                <span className="required-sign" />
                <FormattedMessage id="registration.required_fields" />
              </Col>

            </Row>
          </Form>
        )}
        </Formik>
      </Col>
    </Row>
  );
};

WebToCase.propTypes = {
  formSlug: PropTypes.string.isRequired,
  thankYouPageLink: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default WebToCase;
