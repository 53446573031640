import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./fileDownloadList.scss";

const FileDownloadList = ({ children, headline }) => (
  <div className="file-download-list">
    <div className="file-download-list__headline">{headline}</div>
    <Row className="file-download-list__files">
      {
        React.Children.toArray(children).map((child, index) => (
          <Col
            className="file-download-list__file"
            key={`file-download-list__file${index.toString()}`}
            xs={12}
            sm={6}
            lg={4}
          >
            {child}
          </Col>
        ))
      }
    </Row>
  </div>
);

FileDownloadList.propTypes = {
  children: PropTypes.node.isRequired,
  headline: PropTypes.string.isRequired,
};

export default FileDownloadList;
