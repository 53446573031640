import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import AnchorPoints from "../../components/anchorPoints/anchorPoints";
import SEO from "../../components/seo";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemTextPage = ({ data }) => (
  <MainLayout pageInfo={{ pageName: data.navigationItem.elements.title.value }}>

    <SEO
      title={data.page.elements.metadata__meta_title.value}
      description={data.page.elements.metadata__meta_description.value}
      twitterTitle={data.page.elements.metadata__twitter_title.value}
      twitterSite={data.page.elements.metadata__twitter_site.value}
      twitterImage={data.page.elements.metadata__twitter_image.value}
      twitterDescription={data.page.elements.metadata__twitter_description.value}
      twitterCreator={data.page.elements.metadata__twitter_creator.value}
      ogTitle={data.page.elements.metadata__og_title.value}
      ogImage={data.page.elements.metadata__og_image.value}
      ogDescription={data.page.elements.metadata__og_description.value}
    />

    <SubNavigationGenerator id={data.page.id} />

    <BreadcrumbGenerator id={data.page.id} />

    <div className="text-page">
      <Row>
        <Col md={10} lg={7}>
          <h1 className="typo-h2">{data.page.elements.title.value}</h1>
        </Col>
      </Row>
      <AnchorPoints>
        <RichText data={data.page.elements.text} />
      </AnchorPoints>
    </div>

  </MainLayout>
);

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
          url_slug {
              value
          }
          title {
              value
          }
      }
    }
    page: kontentItemTextPage(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        title {
          value
        }
        text {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemEmbedExternal
          }
        }
      }
    }
  }
`;

KontentItemTextPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemTextPage;
