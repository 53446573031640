import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import TopBanner from "../components/topBanner/topBanner";

const KontentItemTopBannerInfo = () => {
  const data = useStaticQuery(graphql`
    {
      topBannerInfos: allKontentItemTopBannerInfo(filter: {system: {workflow_step: {ne: "archived"}}}) {
        nodes {
          id
          system {
            codename
          }
          elements {
            headline {
              value
            }
          }
        }
      }
    }
  `);

  const topBannerInfos = data && data.topBannerInfos && data.topBannerInfos.nodes;

  if (!topBannerInfos || topBannerInfos.length === 0) {
    return null;
  }

  let headline = "";

  topBannerInfos.forEach((topBannerInfo, index) => {
    headline += topBannerInfo.elements.headline.value;

    if (index < topBannerInfos.length - 1) {
      headline += "\n\n";
    }
  });

  return (
    <TopBanner headline={headline} />
  );
};

export default KontentItemTopBannerInfo;
