import React from "react";

import PropTypes from "prop-types";

import "./donationBox.scss";

const DonationBoxLinks = ({ buttons }) => <div className="donation-box__links">{buttons}</div>;

DonationBoxLinks.propTypes = {
  buttons: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default DonationBoxLinks;
