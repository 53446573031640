import React from "react";

import classNames from "classnames";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import SocialMedia from "../../templates/layout/socialMedia";
import DropdownCountry from "../dropdown/dropdownCountry";
import Logo from "../logo";
import BottomFooterNavigation from "../navigation/bottomFooterNavigation";
import FooterNavigation from "../navigation/footerNavigation";
import InfoBottomFooterNavigation from "../navigation/infoBottomFooterNavigation";

import "./footer.scss";

const isGroup = process.env.GATSBY_SITE === "group";
const isAsia = process.env.GATSBY_SITE === "asia";

const Footer = () => (
  <footer className="footer" id="footer">
    <div className="footer-menu container--fullwidth">
      <Container fluid>
        <Row className={classNames("footer-menu__header", { "mb-0": isAsia })}>
          <Col xs={4} sm={7} md="auto" lg={3} className="footer-menu__header-logo">
            <Logo breakpoint="sm" />
          </Col>
          {!isGroup && !isAsia && (
            <>
              <Col xs={12} sm={5} md={4} lg={3} className="footer-menu__header-language">
                <DropdownCountry />
              </Col>
              <Col
                xs={12}
                md="auto"
                lg={6}
                className="footer-menu__header-social"
              >
                <SocialMedia />
              </Col>
            </>
          )}
        </Row>
        {!isAsia && (
          <Row className="footer-menu__body footer-navigation">
            <FooterNavigation />
            {!isGroup && <InfoBottomFooterNavigation />}
          </Row>
        )}
      </Container>
    </div>
    <div className="footer-copyright">
      <div className="footer-copyright__menu">
        <BottomFooterNavigation />
      </div>
      <div className="footer-copyright__text typo-body2">
        &copy; {new Date().getFullYear()} DKMS Group gGmbH
      </div>
    </div>
  </footer>
);

export default Footer;
