import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import KontentItemModalView from "../kontenItemModalView";

const HealthCheckModalView = () => {
  const data = useStaticQuery(graphql`
    {
      kontentItemRegistrationDialogue {
        id
        elements {
          health_check_modal {
            value {
              ...KontentItemModalView
            }
          }
        }
      }
    }
  `);

  const registrationDialogue = data.kontentItemRegistrationDialogue || null;

  if (!registrationDialogue || !registrationDialogue.elements) {
    return null;
  }

  return <KontentItemModalView data={registrationDialogue.elements.health_check_modal} />;
};

export default HealthCheckModalView;
