import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import ReactIdSwiperCustom from "react-id-swiper";
import { Navigation, Swiper } from "swiper/js/swiper";

import SubNavPoint from "../subNavPoint/subNavPoint";

import "./tabNavigation.scss";

const TabNavigation = ({ items }) => {
  const swiperRef = useRef(null);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [prevDisabled, setPrevDisabled] = useState(true);

  const resize = () => {
    if (swiperRef.current?.swiper) {
      const i = Object.keys(swiperRef.current.swiper.slides).find((el) => {
        const index = parseInt(el, 10);

        if (index) {
          return swiperRef.current.swiper.slides[el].classList.contains("active");
        }

        return false;
      });

      if (i > 0 && i < swiperRef.current.swiper.slidesGrid.length) {
        swiperRef.current.swiper.clickedIndex = i;
        swiperRef.current.swiper.slideTo(i, 300);
        swiperRef.current.swiper.translateTo(
          (swiperRef.current.swiper.slidesGrid[i]
            - (window.innerWidth / 2)
            + (swiperRef.current.swiper.slidesSizesGrid[i] / 2)) * -1,
          300,
        );
      }
    }
  };

  const init = () => {
    if (swiperRef.current?.swiper) {
      setTimeout(() => {
        swiperRef.current.swiper.update();
        resize();
      }, 500);
    }
  };

  const setTranslate = () => {
    setNextDisabled(swiperRef.current?.swiper?.isEnd);
    setPrevDisabled(swiperRef.current?.swiper?.isBeginning);
  };

  const next = () => {
    if (window.innerWidth >= 992) {
      swiperRef.current?.swiper?.slideNext();
    }
  };

  const prev = () => {
    if (window.innerWidth >= 992) {
      swiperRef.current?.swiper?.slidePrev();
    }
  };

  const swiperProps = {
    Swiper,
    breakpoints: {
      0: {
        allowTouchMove: true,
        spaceBetween: 24,
      },
      992: {
        allowTouchMove: false,
        spaceBetween: 24,
      },
      1200: {
        allowTouchMove: false,
        spaceBetween: 30,
      },
    },
    centeredSlidesBounds: true,
    freeMode: true,
    modules: [Navigation],
    on: {
      resize,
      setTranslate,
    },
    ref: swiperRef,
    shouldSwiperUpdate: true,
    slidesPerView: "auto",
  };

  useEffect(() => {
    init();
    setNextDisabled(swiperRef.current?.swiper?.isEnd);
    setPrevDisabled(swiperRef.current?.swiper?.isBeginning);
  }, []);

  return (
    <div className="tab-navigation">
      <ReactIdSwiperCustom
        {...swiperProps}
      >
        {items.map((item) => (
          <SubNavPoint
            {...item}
            href={item.link}
            key={item.id}
            className={item.className}
            color="white"
          >
            {item.children || item.title}
          </SubNavPoint>
        ))}
      </ReactIdSwiperCustom>
      <div
        role="presentation"
        className={classNames(
          "swiper-button-prev",
          { "swiper-button-disabled": prevDisabled },
        )}
        onClick={prev}
      />
      <div
        role="presentation"
        className={classNames(
          "swiper-button-next",
          { "swiper-button-disabled": nextDisabled },
        )}
        onClick={next}
      />
    </div>
  );
};

TabNavigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    state: PropTypes.oneOfType([PropTypes.object]),
    title: PropTypes.string.isRequired,
  })).isRequired,
};

export default TabNavigation;
