import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationAdditionalInformation = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const emailMessage = intl.formatMessage({ id: "registration.additional.error.email" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const validationSchema = useMemo(() => object().shape({
    additionalEmailAddress: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    additionalPhoneNumber: string()
      .matches(
        /^[\d\s\-+/]{7,20}$/,
        intl.formatMessage({ id: "form.error.phone_pattern" }),
      ),
    emailAddress: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage)
      .email(emailMessage),
    mobileNumber: string()
      .required(requiredMessage)
      .matches(
        /^[\d\s\-+/]{7,20}$/,
        intl.formatMessage({ id: "form.error.phone_pattern" }),
      ),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.additional.title"
      />
      <Formik
        initialValues={{
          additionalEmailAddress: registrationData.additionalEmailAddress,
          additionalPhoneNumber: registrationData.additionalPhoneNumber,
          emailAddress: registrationData.emailAddress,
          mobileNumber: registrationData.mobileNumber,
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              additionalEmailAddress: values.additionalEmailAddress,
              additionalPhoneNumber: values.additionalPhoneNumber,
              emailAddress: values.emailAddress,
              mobileNumber: values.mobileNumber,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_additional_information", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="mobileNumber"
                  label={intl.formatMessage({ id: "registration.additional.mobileNumber" })}
                  type="text"
                  maxLength={25}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="additionalPhoneNumber"
                  label={intl.formatMessage({ id: "registration.additional.additionalPhoneNumber" })}
                  type="text"
                  maxLength={20}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="emailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="additionalEmailAddress"
                  label={intl.formatMessage({ id: "registration.additional.additional_email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.additional.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationAdditionalInformation.stepName = "registration_additional_information";

RegistrationDialogStepRegistrationAdditionalInformation.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationAdditionalInformation;
