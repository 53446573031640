import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./emptyContentWall.scss";

const EmptyContentWall = (props) => {
  const {
    headline,
    icon,
    message,
  } = props;

  return (
    <div className="empty-content-wall">
      <Row>
        <Col
          xs={12}
          md={{ offset: 1, span: 10 }}
          lg={{ offset: 2, span: 8 }}
        >
          <div className="empty-content-wall__content">
            <div className="empty-content-wall__icon">{icon}</div>
            <div className="empty-content-wall__headline">{headline}</div>
            <div className="empty-content-wall__message">{message}</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

EmptyContentWall.propTypes = {
  headline: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  message: PropTypes.string.isRequired,
};

export default EmptyContentWall;
