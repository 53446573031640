import React, { useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { IconFurther24 } from "../../icons";
import KontentItemMegaMenuData from "../../templates/kontentItemMegaMenuData";
import BrowserDetection from "../browserDetection";
import LinkButton from "../button/linkButton";
import MegaMenuLink from "../megaMenuLink/megaMenuLink";
import NavigationLink from "../navigationLink/navigationLink";

const isGroup = process.env.GATSBY_SITE === "group";

const PrimaryNavigation = () => {
  const [hovered, setHovered] = useState({});
  const [megaMenuData, setMegaMenuData] = useState(null);
  const [megaMenuDescription, setMegaMenuDescription] = useState("");
  const [megaMenuTitle, setMegaMenuTitle] = useState("");
  const [megaMenuToLink, setMegaMenuToLink] = useState("");

  const { width } = useWindowDimensions();

  const data = useStaticQuery(graphql`
    {
      primaryNavigation: kontentItemNavigationItem(system: {codename: {eq: "primary_navigation"}}) {
        id
        elements {
          elements {
            value {
              id
              preferred_language
              ... on kontent_item_navigation_item {
                id
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                  megamenu_data {
                    value {
                      ...KontentItemMegaMenuData
                    }
                  }
                  elements {
                    value {
                      ...on kontent_item_navigation_item {
                        id
                        elements {
                          title {
                            value
                          }
                          url_slug {
                            value
                          }
                          external_url {
                            value
                          }
                          megamenu_data {
                            value {
                              ...KontentItemMegaMenuData
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const onMouseOver = (key, entered) => {
    const tmpHovered = {};

    Object.keys(hovered).forEach((key2) => {
      tmpHovered[key2] = false;
    });
    setHovered({ ...tmpHovered, [key]: entered });
  };

  const changeMegaMenuInfo = (title, description, link, megaMenuData2) => {
    const breakpoint = window.matchMedia("(min-width:768px)");

    if (breakpoint.matches === true) {
      setMegaMenuData(megaMenuData2);
      setMegaMenuDescription(description);
      setMegaMenuTitle(title);
      setMegaMenuToLink(link);
    }
  };

  const primaryNavigation = [];
  const isTouchScreen = BrowserDetection.hasTouchScreen();

  data.primaryNavigation?.elements?.elements?.value.forEach((item) => {
    primaryNavigation.push((
      <React.Fragment key={item.elements.title.value}>
        <Nav.Item
          onMouseEnter={() => {
            if (!isTouchScreen) {
              onMouseOver(item.elements.title.value, true);
            }
          }}
          onMouseLeave={() => onMouseOver(item.elements.title.value, false)}
        >
          <NavigationLink
            {...item}
            key={item.id}
            className="nav-link"
            onClick={(event) => {
              if (isTouchScreen && window.matchMedia("(min-width:768px)").matches === true) {
                event.preventDefault();
                onMouseOver(item.elements.title.value, !hovered[item.elements.title.value]);
              } else {
                onMouseOver(item.elements.title.value, false);
              }
            }}
            onMouseEnter={() => {
              changeMegaMenuInfo(
                item.elements.title.value,
                item.elements.megamenu_data.value.length > 0
                  ? item.elements.megamenu_data.value[0].elements.page_description.value
                  : "",
                item.elements.external_url.value || item.id,
                <KontentItemMegaMenuData data={item.elements.megamenu_data} />,
              );
            }}
          />
          <div className={`megamenu ${hovered[item.elements.title.value] ? "d-block" : "d-none"}`}>
            <Container fluid>
              <Row>
                <Col md={4} lg={3}>
                  {item.elements.elements.value.map((link) => {
                    if (!link.id) return null;

                    return (
                      <MegaMenuLink
                        href={link.elements.external_url.value || link.id}
                        onClick={(event) => {
                          if (isTouchScreen) {
                            event.preventDefault();

                            changeMegaMenuInfo(
                              link.elements.title.value,
                              link.elements.megamenu_data.value.length > 0
                                // eslint-disable-next-line max-len
                                ? link.elements.megamenu_data.value[0].elements.page_description.value
                                : "",
                              link.elements.external_url.value || link.id,
                              <KontentItemMegaMenuData data={link.elements.megamenu_data} />,
                            );
                          }
                        }}
                        onMouseEnter={() => {
                          changeMegaMenuInfo(
                            link.elements.title.value,
                            link.elements.megamenu_data.value.length > 0
                              // eslint-disable-next-line max-len
                              ? link.elements.megamenu_data.value[0].elements.page_description.value
                              : "",
                            link.elements.external_url.value || link.id,
                            <KontentItemMegaMenuData data={link.elements.megamenu_data} />,
                          );
                        }}
                        title={link.elements.title.value}
                        key={link.elements.title.value}
                      >
                        {link.elements.title.value}
                      </MegaMenuLink>
                    );
                  })}
                </Col>
                <Col md={6} lg={{ offset: 1, span: 4 }} className="megamenu__description">
                  <h4>{megaMenuTitle}</h4>
                  {megaMenuDescription && (<p>{megaMenuDescription}</p>)}
                  <LinkButton href={megaMenuToLink}>
                    <FormattedMessage id="navigation.menu.go_to_page" /><IconFurther24 />
                  </LinkButton>
                </Col>
                {!isGroup && width >= 992 && (
                  <Col lg={4}>
                    {megaMenuData}
                  </Col>
                )}
              </Row>
            </Container>
          </div>
        </Nav.Item>
      </React.Fragment>
    ));
  });

  return React.Children.toArray(primaryNavigation);
};

export default PrimaryNavigation;
