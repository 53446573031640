/* eslint-disable sort-keys */
import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";

import { IconError18 } from "../../icons";
import { cleanupObject } from "../../utils/formik/objectUtils";

export default class RegistrationDataHelper {
  static preparePayload(data) {
    let payload;

    switch (process.env.GATSBY_SITE) {
      case "gb":
        payload = RegistrationDataHelper.#getPostDataGb(data);
        break;
      case "us":
        payload = RegistrationDataHelper.#getPostDataUs(data);
        break;
      case "in":
        payload = RegistrationDataHelper.#getPostDataIn(data);
        break;
      case "pl":
        payload = RegistrationDataHelper.#getPostDataPl(data);
        break;
      case "cl":
        payload = RegistrationDataHelper.#getPostDataCl(data);
        break;
      case "de":
        payload = RegistrationDataHelper.#getPostDataDe(data);
        break;
      case "za":
        payload = RegistrationDataHelper.#getPostDataZa(data);
        break;
      default:
        payload = {};
    }

    return cleanupObject(payload);
  }

  static #getTitleGb = (salutation) => (["Mr.", "Mrs.", "Ms.", "Miss"].includes(salutation) ? salutation : "");

  static #getSalutationGb = (salutation) => (["Dr.", "Prof."].includes(salutation) ? salutation : "");

 static howDidYouHearOptions = [
   { label: "Online advert", value: "Online_Advert" },
   { label: "Media coverage (radio)", value: "Media_Coverage_-_Radio" },
   { label: "Media coverage (TV)", value: "Media_Coverage_-_TV" },
   { label: "Media coverage (news article)", value: "Media_Coverage_-_Newspaper_Magazine_Article" },
   { label: "Outdoor advert (poster, bus, train, etc.)", value: "Outdoor_advert_(poster,_bus,_train,_etc.)" },
   { label: "Print advert (newspaper, magazine, etc.)", value: "Print_advert_(newspaper,_magazine,_etc.)" },
   { label: "Letter from DKMS", value: "Letter" },
   { label: "Facebook", value: "Facebook" },
   { label: "Twitter", value: "Twitter_social_media" },
   { label: "Other social media", value: "Other_Social_Media" },
   { label: "Word of mouth", value: "Family_friends" },
   { label: "Personally affected", value: "Personal_Connection" },
   { label: "Event registering blood stem cell donor", value: "Donor_Drive" },
   { label: "Other", value: "Other" },
 ];

  static howDidYouHearOptionsCl = [
    { label: "TV", value: "Media_Coverage_-_TV" },
    { label: "Universidad/Colegio", value: "University" },
    { label: "Lugar de trabajo", value: "Employer" },
    { label: "Diario/Revista", value: "Print_advert_(newspaper,_magazine,_etc.)" },
    { label: "Vía pública", value: "Outdoor_advert_(poster,_bus,_train,_etc.)" },
    { label: "Radio", value: "Media_Coverage_-_Radio" },
    { label: "Facebook", value: "Facebook" },
    { label: "Instagram", value: "Instagram" },
    { label: "Twitter", value: "Twitter" },
    { label: "LinkedIn", value: "LinkedIn" },
    { label: "Youtube", value: "Youtube" },
    { label: "TikTok", value: "TikTok" },
    { label: "Conocido o familiar", value: "Conocido_o_familiar" },
    { label: "Buscador o navegador", value: "Buscador_o_navegador" },
    { label: "Otro", value: "Other" },
  ];

  static howDidYouHearOptionsDe = [
    { label: "Mündlicher Hinweis", value: "Muendlicher_Hinweis_aus_Bekanntenkreis" },
    { label: "TV", value: "TV" },
    { label: "Erkrankung im persönlichen Umfeld", value: "Erkrankung_im_persoenlichen_Umfeld" },
    { label: "Social Media (Facebook, X, usw.)", value: "Social_Media" },
    { label: "WhatsApp, Signal, usw.", value: "Instant_Messenger" },
    { label: "Arbeitgeber/Firma", value: "Arbeitgeber_Firma" },
    { label: "Hochschule/Universität", value: "Hochschule" },
    { label: "Plakat", value: "Plakat" },
    { label: "Radio", value: "Radio" },
    { label: "Zeitung/Magazin", value: "Zeitung" },
    { label: "Keine Angabe", value: "Keine_Angabe" },
  ];

  static howDidYouHearOptionsIn = [
    { label: "Outdoor advert", value: "Outdoor_advert" },
    { label: "Email", value: "Email" },
    { label: "Online advert", value: "Online_advert" },
    { label: "Personally affected", value: "Personally_affected" },
    { label: "Word of mouth", value: "Word_of_mouth" },
    { label: "Donor drive", value: "Donor_drive" },
    { label: "Social media", value: "Social_Media" },
    { label: "Press", value: "Press" },
    { label: "Print advert", value: "Print_advert" },
    { label: "Text message", value: "Text_message" },
    { label: "Radio", value: "Radio" },
    { label: "University", value: "University" },
    { label: "Company Drive", value: "Company_Drive" },
    { label: "TV", value: "TV" },
    { label: "Employer", value: "Employer" },
    { label: "Other", value: "Other" },
  ];

  static howDidYouHearOptionsPl = [
    { label: "Portale społecznościowe (np. Facebook, Instagram)", value: "Socialmedia" },
    { label: "Osoba Chora", value: "Osoba_chora" },
    { label: "Zachęcił mnie znajomy / członek rodziny", value: "Znajomy_czlonek_rodziny" },
    { label: "Billboardy", value: "Billboardy" },
    { label: "Telewizja", value: "Telewizja" },
    { label: "Radio", value: "Radio" },
    { label: "Pracodawca", value: "Pracodawca" },
    { label: "Reklama w internecie", value: "Reklama_internetowa" },
    { label: "Dni Dawcy", value: "Dni_Dawcy" },
    { label: "Prasa", value: "Prasa" },
  ];

  static howDidYouHearOptionsUs = [
    { label: "From a friend (family member, coworker, etc.)", value: "Friend" },
    { label: "Social Media (Facebook, Instagram, etc.)", value: "SocialMedia" },
    { label: "Online Search (Google, Bing, etc.)", value: "OnlineSearch" },
    { label: "Outdoor Ad (flyers, poster, billboard, etc.)", value: "OutdoorAd" },
    { label: "TV (on air or streaming - Hulu, etc.)", value: "TV" },
    { label: "Radio/Podcast (on air or online)", value: "RadioPodcast" },
    { label: "Newspaper/Magazine (print or digital)", value: "NewspaperMagazine" },
  ];

  static howDidYouHearOptionsZa = [
    { label: "Online advert", value: "Online_Advert" },
    { label: "Media coverage (radio)", value: "Media_Coverage_-_Radio" },
    { label: "Media coverage (TV)", value: "Media_Coverage_-_TV" },
    { label: "Media coverage (news article)", value: "Media_Coverage_-_Newspaper_Magazine_Article" },
    { label: "Outdoor advert (poster, bus, train, etc.)", value: "Outdoor_advert_(poster,_bus,_train,_etc.)" },
    { label: "Print advert (newspaper, magazine, etc.)", value: "Print_advert_(newspaper,_magazine,_etc.)" },
    { label: "Letter from DKMS", value: "Letter" },
    { label: "Facebook", value: "Facebook" },
    { label: "Twitter", value: "Twitter_social_media" },
    { label: "Other social media", value: "Other_Social_Media" },
    { label: "Word of mouth", value: "Family_friends" },
    { label: "Personally affected", value: "Personal_Connection" },
    { label: "Event registering blood stem cell donor", value: "Donor_Drive" },
    { label: "Other", value: "Other" },
  ];

  static ethnicityOptions = [
    {
      label: "UK & Ireland (WHITE)",
      options: [
        { label: "English", value: "ENG" },
        { label: "Scottish", value: "SCO" },
        { label: "Welsh", value: "WAL" },
        { label: "Northern Irish", value: "NIR" },
        { label: "Irish", value: "ROI" },
        {
          label: "Any other UK and/or Irish descent, or combination of those above (eg Welsh Mother, English Father)",
          value: "OUK",
        },
      ],
    },
    {
      label: "Northern & Central European (WHITE)",
      options: [
        { label: "Austria, Belgium, France, Germany, Netherlands, Switzerland", value: "CEU" },
        { label: "Scandinavia (eg Denmark, Norway, Sweden, Finland, Iceland)", value: "SCA" },
        {
          label: "Any other Northern or Central European descent, or combination of those above (eg Danish Mother, Dutch Father)",
          value: "NCE",
        },
      ],
    },
    {
      label: "Southern & Eastern European (WHITE)",
      options: [
        { label: "Italy, Portugal, Spain", value: "IPS" },
        { label: "Turkey", value: "TUR" },
        { label: "Greece", value: "GRE" },
        { label: "Other Balkan States (eg Serbia, Croatia, Bosnia-Herzegovina, Albania, Bulgaria)", value: "OBS" },
        {
          label: "Eastern Europe (eg Czech Republic, Poland, Romania, Baltic States, Ukraine, Western Russia)",
          value: "EEU",
        },
        {
          label: "Any other Southern or Eastern European descent, or combination of those above (eg Spanish Mother, Polish Father)",
          value: "OSE",
        },
      ],
    },
    {
      label: "Other (WHITE)",
      options: [
        { label: "Any other white or combination of these (eg French Mother, Scottish Father)", value: "OTW" },
      ],
    },
    {
      label: "African-Caribbean & African (BLACK)",
      options: [
        { label: "Caribbean Islands", value: "CAI" },
        { label: "Africa (excluding North Africa)", value: "AFR" },
        { label: "Any other African or African-Caribbean family origins (excluding North Africa)", value: "OAC" },
      ],
    },
    {
      label: "South Asian (ASIAN)",
      options: [
        { label: "India", value: "IND" },
        { label: "Pakistan", value: "PST" },
        { label: "Bangladesh", value: "BAN" },
        {
          label: "Any other South Asian family origins or combination of those above (eg Nepal, Bhutan)",
          value: "OSA",
        },
      ],
    },
    {
      label: "East Asian (ASIAN)",
      options: [
        { label: "Japan, North Korea, South Korea", value: "JKO" },
        { label: "China, Taiwan", value: "CHT" },
        { label: "Thailand, Indonesia, Burma", value: "TIB" },
        { label: "Malaysia, Vietnam, Philippines, Cambodia, Laos", value: "MPL" },
        {
          label: "Any other East Asian family origins or combinations of those above (eg Chinese Mother, Malaysian Father)",
          value: "OEA",
        },
      ],
    },
    {
      label: "Central Asian (ASIAN)",
      options: [
        { label: "eg Eastern Russia, Kazakhstan, Uzbekistan, Mongolia", value: "CEA" },
      ],
    },
    {
      label: "Other (ASIAN)",
      options: [
        {
          label: "Any individual of Asian origins or combination of South, East or Central Asian origins listed above (eg Chinese Mother, Mongolian Father)",
          value: "AAO",
        },
      ],
    },
    {
      label: "Other (OTHER)",
      options: [
        { label: "North Africa & Middle East", value: "NME" },
        { label: "Jewish", value: "JWH" },
        { label: "South America", value: "SAM" },
        { label: "Central America, Mexico", value: "CAM" },
        { label: "Any other Non-European family origins", value: "ONE" },
      ],
    },
    {
      label: "Mixed race",
      options: [
        { label: "Mixed White & Black", value: "MWB" },
        { label: "Mixed White & Asian", value: "MWA" },
        { label: "Mixed White & Other", value: "MWO" },
        { label: "Mixed Black & Asian", value: "MBA" },
        { label: "Mixed Black & Other", value: "MBO" },
        { label: "Mixed Asian & Other", value: "MAO" },
        { label: "Mixed Other", value: "MIO" },
      ],
    },
    {
      label: "Not known",
      options: [
        { label: "Not known", value: "UKN" },
      ],
    },
  ];

  static stateOptionsIn = [
    { label: "Andaman and Nicobar Islands", value: "AN" },
    { label: "Andhra Pradesh", value: "AP" },
    { label: "Arunachal Pradesh", value: "AR" },
    { label: "Assam", value: "AS" },
    { label: "Bihar", value: "BR" },
    { label: "Chandigarh", value: "CH" },
    { label: "Chhattisgarh", value: "CT" },
    { label: "Dadra and Nagar Haveli", value: "DN" },
    { label: "Daman and Diu", value: "DD" },
    { label: "Delhi", value: "DL" },
    { label: "Goa", value: "GA" },
    { label: "Gujarat", value: "GJ" },
    { label: "Haryana", value: "HR" },
    { label: "Himachal Pradesh", value: "HP" },
    { label: "Jammu and Kashmir", value: "JK" },
    { label: "Jharkhand", value: "JH" },
    { label: "Karnataka", value: "KA" },
    { label: "Kerala", value: "KL" },
    { label: "Lakshadweep", value: "LD" },
    { label: "Madhya Pradesh", value: "MP" },
    { label: "Maharashtra", value: "MH" },
    { label: "Manipur", value: "MN" },
    { label: "Meghalaya", value: "ML" },
    { label: "Mizoram", value: "MZ" },
    { label: "Nagaland", value: "NL" },
    { label: "Odisha", value: "OR" },
    { label: "Puducherry", value: "PY" },
    { label: "Punjab", value: "PB" },
    { label: "Rajasthan", value: "RJ" },
    { label: "Sikkim", value: "SK" },
    { label: "Tamil Nadu", value: "TN" },
    { label: "Telangana", value: "TG" },
    { label: "Tripura", value: "TR" },
    { label: "Uttar Pradesh", value: "UP" },
    { label: "Uttarakhand", value: "UT" },
    { label: "West Bengal", value: "WB" },
    { label: "Other", value: "OTHER" },
  ];

  static motherTongueOptions = [
    { label: "Assamese", value: "ASM" },
    { label: "Bengali", value: "BEN" },
    { label: "Dogri", value: "DOI" },
    { label: "English", value: "GB" },
    { label: "Gujarati", value: "GUJ" },
    { label: "Hindi", value: "HIN" },
    { label: "Kannada", value: "KAN" },
    { label: "Kashmiri", value: "KAS" },
    { label: "Malayalam", value: "MAL" },
    { label: "Manipuri", value: "MNI" },
    { label: "Marathi", value: "MAR" },
    { label: "Odiya", value: "ORI" },
    { label: "Punjabi", value: "PAN" },
    { label: "Rajasthani", value: "RAJ" },
    { label: "Tamil", value: "TAM" },
    { label: "Telugu", value: "TEL" },
    { label: "Urdu", value: "URD" },
    { label: "Other", value: "OTH" },
  ];

  static titleOptionsDe = [
    { label: "Dr.", value: "Dr." },
    { label: "Prof.", value: "Prof." },
  ];

  static titleOptionsIn = [
    { label: "Rtn. (Rotarian)", value: "Rtn." },
    { label: "Dr", value: "Dr." },
    { label: "Prof.", value: "Prof." },
  ];

  static titleOptionsGb = [
    { label: "Dr", value: "Dr." },
    { label: "Prof.", value: "Prof." },
  ];

  static noneEmojiRegex = new RegExp(/^[\u0020-\u007e\u00a0-\u024F]+$/);

  static noneEmojiRegexTextArea = new RegExp(/^[\u0020-\u007e\u00a0-\u024F\s]+$/);

  static titleOptions = [
    { label: "Mr", value: "Mr." },
    { label: "Mrs", value: "Mrs." },
    { label: "Ms", value: "Ms." },
    { label: "Miss", value: "Miss" },
    { label: "Dr", value: "Dr." },
    { label: "Prof.", value: "Prof." },
  ];

  static titleGenderMapping = {
    Miss: "F",
    "Mr.": "M",
    "Mrs.": "F",
    "Ms.": "F",
  };

  static heightOptions = [
    { label: "4' 10\"", value: "4.10" },
    { label: "4' 11\"", value: "4.11" },
    { label: "5' 0\"", value: "5.0" },
    { label: "5' 1\"", value: "5.1" },
    { label: "5' 2\"", value: "5.2" },
    { label: "5' 3\"", value: "5.3" },
    { label: "5' 4\"", value: "5.4" },
    { label: "5' 5\"", value: "5.5" },
    { label: "5' 6\"", value: "5.6" },
    { label: "5' 7\"", value: "5.7" },
    { label: "5' 8\"", value: "5.8" },
    { label: "5' 9\"", value: "5.9" },
    { label: "5' 10\"", value: "5.10" },
    { label: "5' 11\"", value: "5.11" },
    { label: "6' 0\"", value: "6.0" },
    { label: "6' 1\"", value: "6.1" },
    { label: "6' 2\"", value: "6.2" },
    { label: "6' 3\"", value: "6.3" },
    { label: "6' 4\"", value: "6.4" },
    { label: "6' 5\"", value: "6.5" },
    { label: "6' 6\"", value: "6.6" },
    { label: "6' 7\"", value: "6.7" },
    { label: "6' 8\"", value: "6.8" },
    { label: "6' 9\"", value: "6.9" },
    { label: "6' 10\"", value: "6.10" },
    { label: "6' 11\"", value: "6.11" },
    { label: "7' 0\"", value: "7.0" },
  ];

  static relationshipOptions = [
    { label: "Wife", value: "WIFE" },
    { label: "Husband", value: "HUSBAND" },
    { label: "Mother", value: "MOTHER" },
    { label: "Father", value: "FATHER" },
    { label: "Sister", value: "SISTER" },
    { label: "Brother", value: "BROTHER" },
    { label: "In-law", value: "IN-LAW" },
    { label: "Aunt", value: "AUNT" },
    { label: "Uncle", value: "UNCLE" },
    { label: "Cousin", value: "COUSIN" },
    { label: "Friend", value: "FRIEND" },
    { label: "Other", value: "OTHER" },
  ];

  static relationshipOptionsIn = [
    { label: "Aunt", value: "AUNT" },
    { label: "Brother", value: "BROTHER" },
    { label: "Cousin", value: "COUSIN" },
    { label: "Friend", value: "FRIEND" },
    { label: "Husband", value: "HUSBAND" },
    { label: "Other", value: "OTHER" },
    { label: "Sister", value: "SISTER" },
    { label: "Uncle", value: "UNCLE" },
    { label: "Wife", value: "WIFE" },
  ];

  static relationshipOptionsUs = [
    { label: "Father", value: "FATHER" },
    { label: "Mother", value: "MOTHER" },
    { label: "Son", value: "SON" },
    { label: "Daughter", value: "DAUGHTER" },
    { label: "Brother", value: "BROTHER" },
    { label: "Sister", value: "SISTER" },
    { label: "Spouse", value: "SPOUSE" },
    { label: "Friend", value: "FRIEND" },
  ];

  static relationshipTypeOptionsDe = [
    { label: "Vater/Mutter", value: "E" },
    { label: "Sohn/Tochter", value: "K" },
    { label: "Bruder/Schwester", value: "G" },
    { label: "Halbbruder/Halbschwester", value: "H" },
    { label: "Cousin/Cousine", value: "C" },
    { label: "Neffe/Nichte", value: "N" },
    { label: "Onkel/Tante", value: "T" },
    { label: "Sonstige Blutverwandtschaft", value: "S" },
    { label: "Keine Blutsverwandtschaft", value: "U" },
  ];

  static regionOptionsCl = [
    { label: "Región de Arica y Parinacota", value: "REGIÓN DE ARICA Y PARINACOTA" },
    { label: "Región de Tarapacá", value: "REGIÓN DE TARAPACA" },
    { label: "Región de Antofagasta", value: "REGIÓN DE ANTOFAGASTA" },
    { label: "Región de Atacama", value: "REGIÓN DE ATACAMA" },
    { label: "Región de Coquimbo", value: "REGIÓN DE COQUIMBO" },
    { label: "Región de Valparaíso", value: "REGIÓN DE VALPARAÍSO" },
    { label: "Región Metropolitana", value: "REGIÓN METROPOLITANA DE SANTIAGO" },
    { label: "Región del Libertador B. O'Higgins", value: "REGIÓN DEL LIBERTADOR B. O'HIGGINS" },
    { label: "Región del Maule", value: "REGIÓN DEL MAULE" },
    { label: "Región de Ñuble", value: "REGIÓN DE ÑUBLE" },
    { label: "Región del Bíobío", value: "REGIÓN DEL BÍOBÍO" },
    { label: "Región de La Araucanía", value: "REGIÓN DE LA ARAUCANÍA" },
    { label: "Región de Los Ríos", value: "REGIÓN DE LOS RÍOS" },
    { label: "Región de Los Lagos", value: "REGIÓN DE LOS LAGOS" },
    { label: "Región de Aysén del Gral. Carlos Ibáñez del Campo", value: "REGIÓN DE AISÉN DEL GRAL. C. IBÁÑEZ DEL CAMPO" },
    { label: "Región de Magallanes y de la Antártica Chilena", value: "REGIÓN DE MAGALLANES Y DE LA ANTÁRTICA CHILENA" },
  ];

  static cityOptionsCl = [
    {
      label: "Región de Arica y Parinacota",
      options: [
        {
          label: "Arica",
          value: "ARICA",
        },
        {
          label: "Camarones",
          value: "CAMARONES",
        },
        {
          label: "Putre",
          value: "PUTRE",
        },
        {
          label: "General Lagos",
          value: "GENERAL LAGOS",
        },
      ],
    },
    {
      label: "Región de Tarapacá",
      options: [
        {
          label: "Iquique",
          value: "IQUIQUE",
        },
        {
          label: "Camiña",
          value: "CAMIÑA",
        },
        {
          label: "Colchane",
          value: "COLCHANE",
        },
        {
          label: "Huara",
          value: "HUARA",
        },
        {
          label: "Pica",
          value: "PICA",
        },
        {
          label: "Pozo Almonte",
          value: "POZO ALMONTE",
        },
        {
          label: "Alto Hospicio",
          value: "ALTO HOSPICIO",
        },
      ],
    },
    {
      label: "Región de Antofagasta",
      options: [
        {
          label: "Antofagasta",
          value: "ANTOFAGASTA",
        },
        {
          label: "Mejillones",
          value: "MEJILLONES",
        },
        {
          label: "Sierra Gorda",
          value: "SIERRA GORDA",
        },
        {
          label: "Taltal",
          value: "TALTAL",
        },
        {
          label: "Calama",
          value: "CALAMA",
        },
        {
          label: "Ollagüe",
          value: "OLLAGÜE",
        },
        {
          label: "San Pedro de Atacama",
          value: "SAN PEDRO DE ATACAMA",
        },
        {
          label: "Tocopilla",
          value: "TOCOPILLA",
        },
        {
          label: "María Elena",
          value: "MARÍA ELENA",
        },
      ],
    },
    {
      label: "Región de Atacama",
      options: [
        {
          label: "Copiapó",
          value: "COPIAPÓ",
        },
        {
          label: "Caldera",
          value: "CALDERA",
        },
        {
          label: "Tierra Amarilla",
          value: "TIERRA AMARILLA",
        },
        {
          label: "Chañaral",
          value: "CHAÑARAL",
        },
        {
          label: "Diego de Almagro",
          value: "DIEGO DE ALMAGRO",
        },
        {
          label: "Vallenar",
          value: "VALLENAR",
        },
        {
          label: "Alto del Carmen",
          value: "ALTO DEL CARMEN",
        },
        {
          label: "Freirina",
          value: "FREIRINA",
        },
        {
          label: "Huasco",
          value: "HUASCO",
        },
      ],
    },
    {
      label: "Región de Coquimbo",
      options: [
        {
          label: "La Serena",
          value: "LA SERENA",
        },
        {
          label: "Coquimbo",
          value: "COQUIMBO",
        },
        {
          label: "Andacollo",
          value: "ANDACOLLO",
        },
        {
          label: "La Higuera",
          value: "LA HIGUERA",
        },
        {
          label: "Paiguano",
          value: "PAIGUANO",
        },
        {
          label: "Vicuña",
          value: "VICUÑA",
        },
        {
          label: "Illapel",
          value: "ILLAPEL",
        },
        {
          label: "Canela",
          value: "CANELA",
        },
        {
          label: "Los Vilos",
          value: "LOS VILOS",
        },
        {
          label: "Salamanca",
          value: "SALAMANCA",
        },
        {
          label: "Ovalle",
          value: "OVALLE",
        },
        {
          label: "Combarbalá",
          value: "COMBARBALÁ",
        },
        {
          label: "Monte Patria",
          value: "MONTE PATRIA",
        },
        {
          label: "Punitaqui",
          value: "PUNITAQUI",
        },
        {
          label: "Río Hurtado",
          value: "RÍO HURTADO",
        },
      ],
    },
    {
      label: "Región de Valparaíso",
      options: [
        {
          label: "Valparaíso",
          value: "VALPARAÍSO",
        },
        {
          label: "Casablanca",
          value: "CASABLANCA",
        },
        {
          label: "Concón",
          value: "CONCÓN",
        },
        {
          label: "Juan Fernández",
          value: "JUAN FERNÁNDEZ",
        },
        {
          label: "Puchuncaví",
          value: "PUCHUNCAVÍ",
        },
        {
          label: "Quilpué",
          value: "QUILPUÉ",
        },
        {
          label: "Quintero",
          value: "QUINTERO",
        },
        {
          label: "Villa Alemana",
          value: "VILLA ALEMANA",
        },
        {
          label: "Viña del Mar",
          value: "VIÑA DEL MAR",
        },
        {
          label: "Isla de Pascua",
          value: "ISLA DE PASCUA",
        },
        {
          label: "Los Andes",
          value: "LOS ANDES",
        },
        {
          label: "Calle Larga",
          value: "CALLE LARGA",
        },
        {
          label: "Rinconada",
          value: "RINCONADA",
        },
        {
          label: "San Esteban",
          value: "SAN ESTEBAN",
        },
        {
          label: "La Ligua",
          value: "LA LIGUA",
        },
        {
          label: "Cabildo",
          value: "CABILDO",
        },
        {
          label: "Papudo",
          value: "PAPUDO",
        },
        {
          label: "Petorca",
          value: "PETORCA",
        },
        {
          label: "Zapallar",
          value: "ZAPALLAR",
        },
        {
          label: "Quillota",
          value: "QUILLOTA",
        },
        {
          label: "Calera",
          value: "CALERA",
        },
        {
          label: "Hijuelas",
          value: "HIJUELAS",
        },
        {
          label: "La Cruz",
          value: "LA CRUZ",
        },
        {
          label: "Limache",
          value: "LIMACHE",
        },
        {
          label: "Nogales",
          value: "NOGALES",
        },
        {
          label: "Olmué",
          value: "OLMUÉ",
        },
        {
          label: "San Antonio",
          value: "SAN ANTONIO",
        },
        {
          label: "Algarrobo",
          value: "ALGARROBO",
        },
        {
          label: "Cartagena",
          value: "CARTAGENA",
        },
        {
          label: "El Quisco",
          value: "EL QUISCO",
        },
        {
          label: "El Tabo",
          value: "EL TABO",
        },
        {
          label: "Santo Domingo",
          value: "SANTO DOMINGO",
        },
        {
          label: "San Felipe",
          value: "SAN FELIPE",
        },
        {
          label: "Catemu",
          value: "CATEMU",
        },
        {
          label: "Llaillay",
          value: "LLAILLAY",
        },
        {
          label: "Panquehue",
          value: "PANQUEHUE",
        },
        {
          label: "Putaendo",
          value: "PUTAENDO",
        },
        {
          label: "Santa María",
          value: "SANTA MARÍA",
        },
      ],
    },
    {
      label: "Región Metropolitana",
      options: [
        {
          label: "Santiago",
          value: "SANTIAGO",
        },
        {
          label: "Cerrillos",
          value: "CERRILLOS",
        },
        {
          label: "Cerro Navia",
          value: "CERRO NAVIA",
        },
        {
          label: "Conchalí",
          value: "CONCHALÍ",
        },
        {
          label: "El Bosque",
          value: "EL BOSQUE",
        },
        {
          label: "Estación Central",
          value: "ESTACIÓN CENTRAL",
        },
        {
          label: "Huechuraba",
          value: "HUECHURABA",
        },
        {
          label: "Independencia",
          value: "INDEPENDENCIA",
        },
        {
          label: "La Cisterna",
          value: "LA CISTERNA",
        },
        {
          label: "La Florida",
          value: "LA FLORIDA",
        },
        {
          label: "La Granja",
          value: "LA GRANJA",
        },
        {
          label: "La Pintana",
          value: "LA PINTANA",
        },
        {
          label: "La Reina",
          value: "LA REINA",
        },
        {
          label: "Las Condes",
          value: "LAS CONDES",
        },
        {
          label: "Lo Barnechea",
          value: "LO BARNECHEA",
        },
        {
          label: "Lo Espejo",
          value: "LO ESPEJO",
        },
        {
          label: "Lo Prado",
          value: "LO PRADO",
        },
        {
          label: "Macul",
          value: "MACUL",
        },
        {
          label: "Maipú",
          value: "MAIPÚ",
        },
        {
          label: "Ñuñoa",
          value: "ÑUÑOA",
        },
        {
          label: "Pedro Aguirre Cerda",
          value: "PEDRO AGUIRRE CERDA",
        },
        {
          label: "Peñalolén",
          value: "PEÑALOLÉN",
        },
        {
          label: "Providencia",
          value: "PROVIDENCIA",
        },
        {
          label: "Pudahuel",
          value: "PUDAHUEL",
        },
        {
          label: "Quilicura",
          value: "QUILICURA",
        },
        {
          label: "Quinta Normal",
          value: "QUINTA NORMAL",
        },
        {
          label: "Recoleta",
          value: "RECOLETA",
        },
        {
          label: "Renca",
          value: "RENCA",
        },
        {
          label: "San Joaquín",
          value: "SAN JOAQUÍN",
        },
        {
          label: "San Miguel",
          value: "SAN MIGUEL",
        },
        {
          label: "San Ramón",
          value: "SAN RAMÓN",
        },
        {
          label: "Vitacura",
          value: "VITACURA",
        },
        {
          label: "Puente Alto",
          value: "PUENTE ALTO",
        },
        {
          label: "Pirque",
          value: "PIRQUE",
        },
        {
          label: "San José de Maipo",
          value: "SAN JOSÉ DE MAIPO",
        },
        {
          label: "Colina",
          value: "COLINA",
        },
        {
          label: "Lampa",
          value: "LAMPA",
        },
        {
          label: "Tiltil",
          value: "TILTIL",
        },
        {
          label: "San Bernardo",
          value: "SAN BERNARDO",
        },
        {
          label: "Buin",
          value: "BUIN",
        },
        {
          label: "Calera de Tango",
          value: "CALERA DE TANGO",
        },
        {
          label: "Paine",
          value: "PAINE",
        },
        {
          label: "Melipilla",
          value: "MELIPILLA",
        },
        {
          label: "Alhué",
          value: "ALHUÉ",
        },
        {
          label: "Curacaví",
          value: "CURACAVÍ",
        },
        {
          label: "María Pinto",
          value: "MARÍA PINTO",
        },
        {
          label: "San Pedro",
          value: "SAN PEDRO",
        },
        {
          label: "Talagante",
          value: "TALAGANTE",
        },
        {
          label: "El Monte",
          value: "EL MONTE",
        },
        {
          label: "Isla de Maipo",
          value: "ISLA DE MAIPO",
        },
        {
          label: "Padre Hurtado",
          value: "PADRE HURTADO",
        },
        {
          label: "Peñaflor",
          value: "PEÑAFLOR",
        },
      ],
    },
    {
      label: "Región del Libertador B. O'Higgins",
      options: [
        {
          label: "Rancagua",
          value: "RANCAGUA",
        },
        {
          label: "Codegua",
          value: "CODEGUA",
        },
        {
          label: "Coinco",
          value: "COINCO",
        },
        {
          label: "Coltauco",
          value: "COLTAUCO",
        },
        {
          label: "Doñihue",
          value: "DOÑIHUE",
        },
        {
          label: "Graneros",
          value: "GRANEROS",
        },
        {
          label: "Las Cabras",
          value: "LAS CABRAS",
        },
        {
          label: "Machalí",
          value: "MACHALÍ",
        },
        {
          label: "Malloa",
          value: "MALLOA",
        },
        {
          label: "Mostazal",
          value: "MOSTAZAL",
        },
        {
          label: "Olivar",
          value: "OLIVAR",
        },
        {
          label: "Peumo",
          value: "PEUMO",
        },
        {
          label: "Pichidegua",
          value: "PICHIDEGUA",
        },
        {
          label: "Quinta de Tilcoco",
          value: "QUINTA DE TILCOCO",
        },
        {
          label: "Rengo",
          value: "RENGO",
        },
        {
          label: "Requínoa",
          value: "REQUÍNOA",
        },
        {
          label: "San Vicente",
          value: "SAN VICENTE",
        },
        {
          label: "Pichilemu",
          value: "PICHILEMU",
        },
        {
          label: "La Estrella",
          value: "LA ESTRELLA",
        },
        {
          label: "Litueche",
          value: "LITUECHE",
        },
        {
          label: "Marchihue",
          value: "MARCHIHUE",
        },
        {
          label: "Navidad",
          value: "NAVIDAD",
        },
        {
          label: "Paredones",
          value: "PAREDONES",
        },
        {
          label: "San Fernando",
          value: "SAN FERNANDO",
        },
        {
          label: "Chépica",
          value: "CHÉPICA",
        },
        {
          label: "Chimbarongo",
          value: "CHIMBARONGO",
        },
        {
          label: "Lolol",
          value: "LOLOL",
        },
        {
          label: "Nancagua",
          value: "NANCAGUA",
        },
        {
          label: "Palmilla",
          value: "PALMILLA",
        },
        {
          label: "Peralillo",
          value: "PERALILLO",
        },
        {
          label: "Placilla",
          value: "PLACILLA",
        },
        {
          label: "Pumanque",
          value: "PUMANQUE",
        },
        {
          label: "Santa Cruz",
          value: "SANTA CRUZ",
        },
      ],
    },
    {
      label: "Región del Maule",
      options: [
        {
          label: "Talca",
          value: "TALCA",
        },
        {
          label: "Constitución",
          value: "CONSTITUCIÓN",
        },
        {
          label: "Curepto",
          value: "CUREPTO",
        },
        {
          label: "Empedrado",
          value: "EMPEDRADO",
        },
        {
          label: "Maule",
          value: "MAULE",
        },
        {
          label: "Pelarco",
          value: "PELARCO",
        },
        {
          label: "Pencahue",
          value: "PENCAHUE",
        },
        {
          label: "Río Claro",
          value: "RÍO CLARO",
        },
        {
          label: "San Clemente",
          value: "SAN CLEMENTE",
        },
        {
          label: "San Rafael",
          value: "SAN RAFAEL",
        },
        {
          label: "Cauquenes",
          value: "CAUQUENES",
        },
        {
          label: "Chanco",
          value: "CHANCO",
        },
        {
          label: "Pelluhue",
          value: "PELLUHUE",
        },
        {
          label: "Curicó",
          value: "CURICÓ",
        },
        {
          label: "Hualañé",
          value: "HUALAÑÉ",
        },
        {
          label: "Licantén",
          value: "LICANTÉN",
        },
        {
          label: "Molina",
          value: "MOLINA",
        },
        {
          label: "Rauco",
          value: "RAUCO",
        },
        {
          label: "Romeral",
          value: "ROMERAL",
        },
        {
          label: "Sagrada Familia",
          value: "SAGRADA FAMILIA",
        },
        {
          label: "Teno",
          value: "TENO",
        },
        {
          label: "Vichuquén",
          value: "VICHUQUÉN",
        },
        {
          label: "Linares",
          value: "LINARES",
        },
        {
          label: "Colbún",
          value: "COLBÚN",
        },
        {
          label: "Longaví",
          value: "LONGAVÍ",
        },
        {
          label: "Parral",
          value: "PARRAL",
        },
        {
          label: "Retiro",
          value: "RETIRO",
        },
        {
          label: "San Javier",
          value: "SAN JAVIER",
        },
        {
          label: "Villa Alegre",
          value: "VILLA ALEGRE",
        },
        {
          label: "Yerbas Buenas",
          value: "YERBAS BUENAS",
        },
      ],
    },
    {
      label: "Región de Ñuble",
      options: [
        {
          label: "Bulnes",
          value: "BULNES",
        },
        {
          label: "Chillán",
          value: "CHILLÁN",
        },
        {
          label: "Chillán Viejo",
          value: "CHILLÁN VIEJO",
        },
        {
          label: "El Carmen",
          value: "EL CARMEN",
        },
        {
          label: "Pemuco",
          value: "PEMUCO",
        },
        {
          label: "Pinto",
          value: "PINTO",
        },
        {
          label: "Quillón",
          value: "QUILLÓN",
        },
        {
          label: "San Ignacio",
          value: "SAN IGNACIO",
        },
        {
          label: "Yungay",
          value: "YUNGAY",
        },
        {
          label: "Quirihue",
          value: "QUIRIHUE",
        },
        {
          label: "Cobquecura",
          value: "COBQUECURA",
        },
        {
          label: "Coelemu",
          value: "COELEMU",
        },
        {
          label: "Ninhue",
          value: "NINHUE",
        },
        {
          label: "Portezuelo",
          value: "PORTEZUELO",
        },
        {
          label: "Ránquil",
          value: "RÁNQUIL",
        },
        {
          label: "Trehuaco",
          value: "TREHUACO",
        },
        {
          label: "San Carlos",
          value: "SAN CARLOS",
        },
        {
          label: "Coihueco",
          value: "COIHUECO",
        },
        {
          label: "Ñiquén",
          value: "ÑIQUÉN",
        },
        {
          label: "San Fabián",
          value: "SAN FABIÁN",
        },
        {
          label: "San Nicolás",
          value: "SAN NICOLÁS",
        },
      ],
    },
    {
      label: "Región del Bíobío",
      options: [
        {
          label: "Concepción",
          value: "CONCEPCIÓN",
        },
        {
          label: "Coronel",
          value: "CORONEL",
        },
        {
          label: "Chiguayante",
          value: "CHIGUAYANTE",
        },
        {
          label: "Florida",
          value: "FLORIDA",
        },
        {
          label: "Hualqui",
          value: "HUALQUI",
        },
        {
          label: "Lota",
          value: "LOTA",
        },
        {
          label: "Penco",
          value: "PENCO",
        },
        {
          label: "San Pedro de La Paz",
          value: "SAN PEDRO DE LA PAZ",
        },
        {
          label: "Santa Juana",
          value: "SANTA JUANA",
        },
        {
          label: "Talcahuano",
          value: "TALCAHUANO",
        },
        {
          label: "Tomé",
          value: "TOMÉ",
        },
        {
          label: "Hualpén",
          value: "HUALPÉN",
        },
        {
          label: "Lebu",
          value: "LEBU",
        },
        {
          label: "Arauco",
          value: "ARAUCO",
        },
        {
          label: "Cañete",
          value: "CAÑETE",
        },
        {
          label: "Contulmo",
          value: "CONTULMO",
        },
        {
          label: "Curanilahue",
          value: "CURANILAHUE",
        },
        {
          label: "Los Álamos",
          value: "LOS ÁLAMOS",
        },
        {
          label: "Tirúa",
          value: "TIRÚA",
        },
        {
          label: "Los Ángeles",
          value: "LOS ÁNGELES",
        },
        {
          label: "Antuco",
          value: "ANTUCO",
        },
        {
          label: "Cabrero",
          value: "CABRERO",
        },
        {
          label: "Laja",
          value: "LAJA",
        },
        {
          label: "Mulchén",
          value: "MULCHÉN",
        },
        {
          label: "Nacimiento",
          value: "NACIMIENTO",
        },
        {
          label: "Negrete",
          value: "NEGRETE",
        },
        {
          label: "Quilaco",
          value: "QUILACO",
        },
        {
          label: "Quilleco",
          value: "QUILLECO",
        },
        {
          label: "San Rosendo",
          value: "SAN ROSENDO",
        },
        {
          label: "Santa Bárbara",
          value: "SANTA BÁRBARA",
        },
        {
          label: "Tucapel",
          value: "TUCAPEL",
        },
        {
          label: "Yumbel",
          value: "YUMBEL",
        },
        {
          label: "Alto Biobío",
          value: "ALTO BIOBÍO",
        },
      ],
    },
    {
      label: "Región de La Araucanía",
      options: [
        {
          label: "Temuco",
          value: "TEMUCO",
        },
        {
          label: "Carahue",
          value: "CARAHUE",
        },
        {
          label: "Cunco",
          value: "CUNCO",
        },
        {
          label: "Curarrehue",
          value: "CURARREHUE",
        },
        {
          label: "Freire",
          value: "FREIRE",
        },
        {
          label: "Galvarino",
          value: "GALVARINO",
        },
        {
          label: "Gorbea",
          value: "GORBEA",
        },
        {
          label: "Lautaro",
          value: "LAUTARO",
        },
        {
          label: "Loncoche",
          value: "LONCOCHE",
        },
        {
          label: "Melipeuco",
          value: "MELIPEUCO",
        },
        {
          label: "Nueva Imperial",
          value: "NUEVA IMPERIAL",
        },
        {
          label: "Padre Las Casas",
          value: "PADRE LAS CASAS",
        },
        {
          label: "Perquenco",
          value: "PERQUENCO",
        },
        {
          label: "Pitrufquén",
          value: "PITRUFQUÉN",
        },
        {
          label: "Pucón",
          value: "PUCÓN",
        },
        {
          label: "Saavedra",
          value: "SAAVEDRA",
        },
        {
          label: "Teodoro Schmidt",
          value: "TEODORO SCHMIDT",
        },
        {
          label: "Toltén",
          value: "TOLTÉN",
        },
        {
          label: "Vilcún",
          value: "VILCÚN",
        },
        {
          label: "Villarrica",
          value: "VILLARRICA",
        },
        {
          label: "Cholchol",
          value: "CHOLCHOL",
        },
        {
          label: "Angol",
          value: "ANGOL",
        },
        {
          label: "Collipulli",
          value: "COLLIPULLI",
        },
        {
          label: "Curacautín",
          value: "CURACAUTÍN",
        },
        {
          label: "Ercilla",
          value: "ERCILLA",
        },
        {
          label: "Lonquimay",
          value: "LONQUIMAY",
        },
        {
          label: "Los Sauces",
          value: "LOS SAUCES",
        },
        {
          label: "Lumaco",
          value: "LUMACO",
        },
        {
          label: "Purén",
          value: "PURÉN",
        },
        {
          label: "Renaico",
          value: "RENAICO",
        },
        {
          label: "Traiguén",
          value: "TRAIGUÉN",
        },
        {
          label: "Victoria",
          value: "VICTORIA",
        },
      ],
    },
    {
      label: "Región de Los Ríos",
      options: [
        {
          label: "Valdivia",
          value: "VALDIVIA",
        },
        {
          label: "Corral",
          value: "CORRAL",
        },
        {
          label: "Futrono",
          value: "FUTRONO",
        },
        {
          label: "La Unión",
          value: "LA UNIÓN",
        },
        {
          label: "Lago Ranco",
          value: "LAGO RANCO",
        },
        {
          label: "Lanco",
          value: "LANCO",
        },
        {
          label: "Los Lagos",
          value: "LOS LAGOS",
        },
        {
          label: "Máfil",
          value: "MÁFIL",
        },
        {
          label: "Mariquina",
          value: "MARIQUINA",
        },
        {
          label: "Paillaco",
          value: "PAILLACO",
        },
        {
          label: "Panguipulli",
          value: "PANGUIPULLI",
        },
        {
          label: "Río Bueno",
          value: "RÍO BUENO",
        },
      ],
    },
    {
      label: "Región de Los Lagos",
      options: [
        {
          label: "Puerto Montt",
          value: "PUERTO MONTT",
        },
        {
          label: "Calbuco",
          value: "CALBUCO",
        },
        {
          label: "Cochamó",
          value: "COCHAMÓ",
        },
        {
          label: "Fresia",
          value: "FRESIA",
        },
        {
          label: "Frutillar",
          value: "FRUTILLAR",
        },
        {
          label: "Los Muermos",
          value: "LOS MUERMOS",
        },
        {
          label: "Llanquihue",
          value: "LLANQUIHUE",
        },
        {
          label: "Maullín",
          value: "MAULLÍN",
        },
        {
          label: "Puerto Varas",
          value: "PUERTO VARAS",
        },
        {
          label: "Castro",
          value: "CASTRO",
        },
        {
          label: "Ancud",
          value: "ANCUD",
        },
        {
          label: "Chonchi",
          value: "CHONCHI",
        },
        {
          label: "Curaco de Vélez",
          value: "CURACO DE VÉLEZ",
        },
        {
          label: "Dalcahue",
          value: "DALCAHUE",
        },
        {
          label: "Puqueldón",
          value: "PUQUELDÓN",
        },
        {
          label: "Queilén",
          value: "QUEILÉN",
        },
        {
          label: "Quellón",
          value: "QUELLÓN",
        },
        {
          label: "Quemchi",
          value: "QUEMCHI",
        },
        {
          label: "Quinchao",
          value: "QUINCHAO",
        },
        {
          label: "Osorno",
          value: "OSORNO",
        },
        {
          label: "Puerto Octay",
          value: "PUERTO OCTAY",
        },
        {
          label: "Purranque",
          value: "PURRANQUE",
        },
        {
          label: "Puyehue",
          value: "PUYEHUE",
        },
        {
          label: "Río Negro",
          value: "RÍO NEGRO",
        },
        {
          label: "San Juan de La Costa",
          value: "SAN JUAN DE LA COSTA",
        },
        {
          label: "San Pablo",
          value: "SAN PABLO",
        },
        {
          label: "Chaitén",
          value: "CHAITÉN",
        },
        {
          label: "Futaleufú",
          value: "FUTALEUFÚ",
        },
        {
          label: "Hualaihué",
          value: "HUALAIHUÉ",
        },
        {
          label: "Palena",
          value: "PALENA",
        },
      ],
    },
    {
      label: "Región de Aysén del Gral. Carlos Ibáñez del Campo",
      options: [
        {
          label: "Coihaique",
          value: "COIHAIQUE",
        },
        {
          label: "Lago Verde",
          value: "LAGO VERDE",
        },
        {
          label: "Aisén",
          value: "AISÉN",
        },
        {
          label: "Cisnes",
          value: "CISNES",
        },
        {
          label: "Guaitecas",
          value: "GUAITECAS",
        },
        {
          label: "Cochrane",
          value: "COCHRANE",
        },
        {
          label: "O'higgins",
          value: "O'HIGGINS",
        },
        {
          label: "Tortel",
          value: "TORTEL",
        },
        {
          label: "Chile Chico",
          value: "CHILE CHICO",
        },
        {
          label: "Río Ibáñez",
          value: "RÍO IBÁÑEZ",
        },
      ],
    },
    {
      label: "Región de Magallanes y de la Antártica Chilena",
      options: [
        {
          label: "Punta Arenas",
          value: "PUNTA ARENAS",
        },
        {
          label: "Laguna Blanca",
          value: "LAGUNA BLANCA",
        },
        {
          label: "Río Verde",
          value: "RÍO VERDE",
        },
        {
          label: "San Gregorio",
          value: "SAN GREGORIO",
        },
        {
          label: "Cabo de Hornos",
          value: "CABO DE HORNOS",
        },
        {
          label: "Antártica",
          value: "ANTÁRTICA",
        },
        {
          label: "Porvenir",
          value: "PORVENIR",
        },
        {
          label: "Primavera",
          value: "PRIMAVERA",
        },
        {
          label: "Timaukel",
          value: "TIMAUKEL",
        },
        {
          label: "Natales",
          value: "NATALES",
        },
        {
          label: "Torres del Paine",
          value: "TORRES DEL PAINE",
        },
      ],
    },
  ];

  static stateOptionsUs = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachussetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "Washington D.C.", value: "DC" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];

  static deliveryInstructionsZa = [
    { label: "None", value: "" },
    { label: "Call before arrival", value: "CALL_BEFORE" },
    { label: "Email/SMS before delivery", value: "EMAIL_SMS_BEFORE" },
    { label: "Leave at Security", value: "LEAVE_AT_SECURITY" },
  ];

  static provinceOptionsZa = [
    { label: "Eastern Cape", value: "EASTERN CAPE" },
    { label: "Free State", value: "FREE STATE" },
    { label: "Gauteng", value: "GAUTENG" },
    { label: "KwaZulu-Natal", value: "KWAZULU-NATAL" },
    { label: "Limpopo", value: "LIMPOPO" },
    { label: "Mpumalanga", value: "MPUMALANGA" },
    { label: "Northern Cape", value: "NORTHERN CAPE" },
    { label: "North West", value: "NORTH WEST" },
    { label: "Western Cape", value: "WESTERN CAPE" },
  ];

  static titleGenderMappingIn = {
    "GENDER-MR": "M",
    "GENDER-MRS": "F",
    "GENDER-MS": "F",
  };

  static #getHeight = (data) => {
    if (data.bmiUnit === "metric") {
      return {
        cm: data.heightMetric,
      };
    }

    return {
      ft: data.heightImperialFeet,
      in: data.heightImperialInch,
    };
  };

  static #getWeight = (data) => {
    if (data.bmiUnit === "metric") {
      return {
        kg: data.weightMetric,
      };
    }

    return {
      st: data.weightImperialSt,
      lb: data.weightImperialLbs,
    };
  };

  static getAncestryFormattedArray = (data) => {
    const ancestryList = [];
    const selected = Object.entries(data.ancestry);
    selected.forEach((select) => {
      if (select.length > 1) {
        Object.entries(select[1]).forEach((s) => {
          if (s[1].length > 0 && s[1][0] === "on") {
            ancestryList.push(s[0]);
          }
        });
      }
    });

    return ancestryList;
  };

  static generateWarning = (warning) => (
    <>
      <IconError18 />
      <div>
        <FormattedMessage id="update_address.same_value_warning" />&nbsp;
        {Object.keys(warning).map((item, index) => (
          <><div className="warning-field">{warning[item]}</div>{index < (Object.keys(warning).length - 1) && ", "}</>
        ))}
      </div>
    </>
  )

  static #getPostDataGb = (data) => ({
    person: {
      sex: data.gender, // required
      salutation: RegistrationDataHelper.#getTitleGb(data.salutation),
      title: RegistrationDataHelper.#getSalutationGb(data.salutation),
      birthdate: data.birthdate, // required
      firstname: data.firstname, // required
      middlename: data.middlename,
      lastname: data.lastname, // required
      former_lastname: "",
    }, // required
    private_address: {
      street: data.street, // required
      houseno: data.houseNo,
      zipcode: data.postCode, // required
      city: data.city, // required
      apartment: data.apartment,
      info: "",
      building: data.houseName,
      co: "", // My courtesy of
      region: "",
      state: data.county,
      country: "",
      country_code: "", // ^[A-Z]{2}$
    }, // required
    general_practitioner: {
      name: data.gpPracticeName,
      city: data.gpCity,
      phone: data.gpPhoneNumber,
    },
    private_contact: {
      email: data.emailAddress, // required
      phone: data.additionalPhoneNumber,
      mobile: data.mobileNumber, // required
      email2: data.additionalEmailAddress,
    }, // required
    registration_data: {
      height: RegistrationDataHelper.#getHeight(data), // required
      weight: RegistrationDataHelper.#getWeight(data), // required
      ethnicity: data.ethnicity, // required
      source: data.howDidYouHear, // required
    }, // required
    marketing_consent: {
      email: data.keepInTouchEmail,
      phone: data.keepInTouchTelephone,
      message: data.keepInTouchTextMessage,
      post: data.keepInTouchPost,
    },
  });

  static #getPostDataUs = (data) => ({
    person: {
      sex: data.gender, // required
      birthdate: data.birthdate, // required
      firstname: data.firstname, // required
      lastname: data.lastname, // required
      social_security_no: data.socialSecurityNumber,
    }, // required
    private_address: {
      street: data.street, // required
      houseno: data.houseNo,
      zipcode: data.postCode, // required
      city: data.city, // required
      apartment: data.apartment,
      state: data.state,
    }, // required
    po_box: {
      po_box: data.po ? data.poBox : "",
      city: data.po ? data.poCity : "",
      state: data.po ? data.poState : "",
      zipcode: data.po ? data.poPostCode : "",
    },
    private_contact: {
      email: data.emailAddress, // required
      phone: data.additionalPhoneNumber,
      mobile: data.mobileNumber, // required
    }, // required
    alternate_contact: {
      firstname: data.contactFirstname,
      lastname: data.contactLastname,
      phone: data.contactPhoneNumber,
      email: data.contactEmailAddress,
      relationship_type: data.contactRelationship,
    }, // required
    registration_data: {
      height: {
        ft: data.heightImperialFeet,
        in: data.heightImperialInch,
      }, // required
      weight: {
        st: 0,
        lb: data.weightImperialLbs,
      }, // required
      ancestry: RegistrationDataHelper.getAncestryFormattedArray(data), // required
      // the clone is needed for the cleanup method. "non-configurable properties can't be deleted"
      ancestry_other: { ...data.ancestryOther },
      source: data.howDidYouHear, // required
    }, // required
  });

  static #getPostDataIn = (data) => ({
    person: {
      sex: RegistrationDataHelper.titleGenderMappingIn[data.gender], // required
      title: data.salutation,
      birthdate: data.birthdate, // required
      firstname: data.firstname, // required
      lastname: data.lastname, // required
    }, // required
    private_address: {
      apartment: data.apartment,
      building: data.houseName,
      houseno: data.houseNo,
      street: data.street, // required
      locality: data.locality, // required
      city: data.city, // required
      zipcode: data.postCode, // required
      district: data.county,
      state: data.state, // required
    }, // required
    private_contact: {
      phone: data.additionalPhoneNumber,
      mobile: data.mobileNumber, // required
      email: data.emailAddress, // required
      email2: data.additionalEmailAddress,
    }, // required
    alternate_contact: {
      person: {
        firstname: data.contactFirstname, // required
        lastname: data.contactLastname, // required
        relationship_type: data.contactRelationship, // required
        phone: data.contactPhoneNumber, // required
        email: data.contactEmailAddress,
      }, // required
      person2: {
        firstname: data.secondContactFirstname, // required
        lastname: data.secondContactLastname, // required
        relationship_type: data.secondContactRelationship, // required
        phone: data.secondContactPhoneNumber, // required
        email: data.secondContactEmailAddress,
      }, // required
    }, // required
    registration_data: {
      native_language: data.motherTongue,
      source: data.howDidYouHear, // required
      whatsapp: data.whatsApp === "yes", // required
      height: {
        ft: data.heightImperialFeet,
        in: data.heightImperialInch,
      }, // required
      weight: {
        kg: data.weightMetric,
      }, // required
      ethnicity: {
        father: {
          origin: data.stateFather, // required
          native_language: data.motherTongueFather, // required
        }, // required
        mother: {
          origin: data.stateMother, // required
          native_language: data.motherTongueMother, // required
        }, // required
      }, // required
    }, // required
  });

  static #getPostDataPl = (data) => ({
    person: {
      sex: data.gender, // required
      birthdate: data.birthdate, // required
      firstname: data.firstname, // required
      lastname: data.lastname, // required
    }, // required
    private_address: {
      apartment: data.apartment,
      houseno: data.houseNo, // required
      street: data.street, // required
      city: data.city, // required
      zipcode: data.postCode, // required
    }, // required
    private_contact: {
      mobile: data.mobileNumber, // required
      email: data.emailAddress, // required
    }, // required
    registration_data: {
      source: data.howDidYouHear, // required
      height: {
        cm: data.heightMetric,
      }, // required
      weight: {
        kg: data.weightMetric,
      }, // required
      placeofbirth: data.placeOfBirth, // required
    }, // required
    marketing_consent: {
      email: data.keepInTouchEmail,
    },
  });

  static #getPostDataCl = (data) => ({
    person: {
      sex: data.gender, // required
      birthdate: data.birthdate, // required
      firstname: data.firstname, // required
      lastname: data.lastname, // required
      rut_run: data.rutRun, // required
    }, // required
    private_address: {
      houseno: data.houseNo, // required
      street: data.street, // required
      region: data.region, // required
      city: data.city, // required
      zipcode: data.postCode,
      info: data.info,
    }, // required
    private_contact: {
      mobile: data.mobileNumber, // required
      phone: data.additionalPhoneNumber,
      email: data.emailAddress, // required
      email2: data.additionalEmailAddress,
    }, // required
    registration_data: {
      source: data.howDidYouHear, // required
      whatsapp: data.whatsApp === "yes", // required
      height: {
        cm: data.heightMetric,
      }, // required
      weight: {
        kg: data.weightMetric,
      }, // required
      ethnicity: {
        father: {
          origin: data.stateFather, // required
          group: data.ethnicityFather, // required
        }, // required
        mother: {
          origin: data.stateMother, // required
          group: data.ethnicityMother, // required
        }, // required
      }, // required
    }, // required
  });

  static #getPostDataDe = (data) => ({
    person: {
      sex: data.gender, // required
      birthdate: data.birthdate, // required
      firstname: data.firstname, // required
      lastname: data.lastname, // required
      title: data.salutation,
    }, // required
    private_address: {
      apartment: data.apartment,
      co: data.careOfAddress,
      houseno: data.houseNo, // required
      street: data.street, // required
      city: data.city, // required
      zipcode: data.postCode, // required
    }, // required
    private_contact: {
      phone: data.additionalPhoneNumber,
      mobile: data.mobileNumber, // required
      email: data.emailAddress, // required
    }, // required
    relationship: { // required/optional
      firstname: data.relationshipFirstname, // required/optional
      lastname: data.relationshipLastname, // required/optional
      type: data.relationshipType, // required/optional
    },
    registration_data: {
      source: data.howDidYouHear, // required
      height: {
        cm: data.heightMetric,
      }, // required
      weight: {
        kg: data.weightMetric,
      }, // required
      ancestry: data.ethnicity, // required
      roots_id: data.rootsId, // required/optional
    }, // required
    marketing_consent: {
      email: data.keepInTouchEmail,
      post: data.keepInTouchPost,
      cb_de_adv_01: data.activityInformation ? "YES" : "NO",
      cb_de_dcp_01: data.personalizedNewsletter ? "YES" : "NO",
    },
  });

  static #getPostDataZa = (data) => ({
    person: {
      sex: data.gender, // required
      salutation: RegistrationDataHelper.#getTitleGb(data.salutation),
      title: RegistrationDataHelper.#getSalutationGb(data.salutation),
      birthdate: data.birthdate, // required
      firstname: data.firstname, // required
      lastname: data.lastname, // required
      passport_no: data.passportNo,
      delivery_date: data.estimatedTime,
    }, // required
    private_address: {
      street: data.street, // required
      houseno: data.houseNo,
      zipcode: data.postCode, // required
      city: data.city, // required
      district: data.suburb,
      apartment: data.apartment,
      region: data.province,
    }, // required
    delivery_address: {
      co: data.deliveryBuilding,
      houseno: data.deliveryHouseNo,
      street: data.deliveryStreet,
      city: data.deliveryCity,
      district: data.deliverySuburb,
      zipcode: data.deliveryPostCode,
      region: data.deliveryProvince,
    },
    general_practitioner: {
      name: data.gpPracticeName,
      city: data.gpCity,
      phone: data.gpPhoneNumber,
    },
    private_contact: {
      email: data.emailAddress, // required
      phone: data.landlineNumber,
      mobile: data.mobileNumber, // required
    }, // required
    alternate_contact: {
      person: {
        firstname: data.personOneFirstname,
        lastname: data.personOneLastname,
        relationship_type: data.personOneRelationShip,
        mobile: data.personOneMobileNumber,
        email: data.personOneEmailAddress,
      },
      person2: {
        firstname: data.personTwoFirstname,
        lastname: data.personTwoLastname,
        relationship_type: data.personTwoRelationShip,
        mobile: data.personTwoMobileNumber,
        email: data.personTwoEmailAddress,
      },
    },
    registration_data: {
      delivery_instructions: data.deliveryInstructions,
      family_donation_agreement: data.familyAgreement,
      height: RegistrationDataHelper.#getHeight(data), // required
      weight: RegistrationDataHelper.#getWeight(data), // required
      ethnicity: {
        person: {
          group: data.yourEthnicity,
          native_language: data.yourNativeLanguage,
        },
        father: {
          group: data.ethnicityFather,
          origin: data.countryFather,
        },
        mother: {
          group: data.ethnicityMother,
          origin: data.countryMother,
        },
      }, // required
      source: data.howDidYouHear, // required
      newsletter: data.newsletter,
    }, // required
  });
}
