import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import NewsCard from "./newsCard";

const LatestNewsCards = ({ pressCategories, newsCategories }) => {
  const data = useStaticQuery(graphql`
    {
      allKontentItemArticlePageNews(sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          ...NewsCard
        }
      }
      allKontentItemArticleMigration(sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          ...NewsCardMigrated
        }
      }
      allKontentItemArticlePress(sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          ...NewsCardPress
        }
      }
    }
  `);

  let pressArticles = [];
  let newsArticles = [];

  if (pressCategories.length > 0) {
    pressArticles = data.allKontentItemArticlePress.nodes.filter((article) => (
      article.elements.news_category.value.find((item) => (
        pressCategories.find((cat) => (cat.codename === item.codename))
      ))
    ));
  }

  if (newsCategories.length > 0 && !newsCategories.find((item) => (item.codename === "all_news"))) {
    newsArticles = data.allKontentItemArticlePageNews.nodes.filter((newsArticle) => (
      newsArticle.elements.news_category.value.find((item) => (
        newsCategories.find((cat) => (cat.codename === item.codename))
      ))
    ));
  } else {
    newsArticles = data.allKontentItemArticlePageNews.nodes;
  }

  const articles = newsArticles.slice(0, 10).concat(
    data.allKontentItemArticleMigration.nodes.slice(0, 10).concat(pressArticles.slice(0, 10)),
  );

  if (data.allKontentItemArticlePageNews) {
    return NewsCard({ currentFirst: true, data: articles, limit: 10 });
  }

  return null;
};

LatestNewsCards.propTypes = {
  newsCategories: PropTypes.oneOfType([PropTypes.array]),
  pressCategories: PropTypes.oneOfType([PropTypes.array]),
};

LatestNewsCards.defaultProps = {
  newsCategories: [],
  pressCategories: [],
};

export default LatestNewsCards;
