import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import CardSlider from "../../components/cardSlider/cardSlider";
import ContentHeader from "../../components/contentHeader/contentHeader";
import ElementHeader from "../../components/elementHeader/elementHeader";
import HeroHeadline from "../../components/heroHeadline/heroHeadline";
import SEO from "../../components/seo";
import TextWithSidebar from "../../components/textWithSidebar/textWithSidebar";
import { useGetAllUrnCounterQuery } from "../../features/io/ioEventApiSlice";
import KontentItemCtaButton from "../kontentItemCtaButton";
import KontentItemEyecatcherImage from "../kontentItemEyecatcherImage";
import KontentItemEyecatcherSidebar from "../kontentItemEyecatcherSidebar";
import KontentItemImageCardSliderSection from "../kontentItemImageCardSliderSection";
import KontentItemRelatedLearnContentEmbed from "../kontentItemRelatedLearnContentEmbed";
import KontentItemVideoEmbed from "../kontentItemVideoEmbed";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";
import TabNavigationGenerator from "../virtualDrive/tabNavigationGenerator";
import VirtualDriveCards from "../virtualDrive/virtualDriveCards";

const KontentItemVdOverview = ({ data: { page, drives } }) => {
  const urnCounter = useGetAllUrnCounterQuery();

  const headerImage = (page.elements.hero_image.value.length > 0
    && page.elements.hero_image.value[0].elements.image.value.length > 0)
    ? page.elements.hero_image.value[0].elements.image.value
    : [];

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value.length > 0
          ? page.elements.metadata__twitter_image.value
          : headerImage}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value.length > 0
          ? page.elements.metadata__og_image.value
          : headerImage}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <HeroHeadline
        imageData={page.elements.hero_image}
        tabNavigation={<TabNavigationGenerator id={page.id} />}
      >
        {page.elements.hero_headline.value}
      </HeroHeadline>

      <BreadcrumbGenerator id={page.id} />

      <ContentHeader
        headline={page.elements.headline.value}
        description={page.elements.text.value}
        media={<KontentItemVideoEmbed data={page.elements.media} variant="4by3" previewImageSizeByParent />}
        button={(
          <>
            {page.elements.cta_buttons.value.map((button) => (
              <KontentItemCtaButton key={button.id} data={button} />
            ))}
          </>
        )}
        variant="video-embed"
      />

      {page.elements.headline_current_drives.value.length > 0 && (
        <>
          <ElementHeader
            headline={page.elements.headline_current_drives.value}
          />

          <CardSlider
            virtualDrive
            moreUrl={page.elements.button_url_vd_current_drives.value.length > 0
              ? page.elements.button_url_vd_current_drives.value[0].id
              : null}
          >
            {VirtualDriveCards({
              data: drives.nodes,
              showCounter: page.elements.disable_counter.value.length === 0,
              virtualDriveCounter: urnCounter.data?.counters
              && page.elements.disable_counter.value.length === 0
                ? urnCounter.data?.counters
                : null,
            })}
          </CardSlider>
        </>
      )}

      <KontentItemEyecatcherImage data={page.elements.eyecatcher_image.value[0]} />

      <TextWithSidebar
        text={<RichText data={page.elements.rich_text} />}
        eyecatcher={page.elements.eyecatcher_sidebar.value.length > 0
          ? <KontentItemEyecatcherSidebar data={page.elements.eyecatcher_sidebar.value[0]} />
          : null}
      />

      <KontentItemRelatedLearnContentEmbed data={page.elements.learn_content_section} />

      <KontentItemImageCardSliderSection data={page.elements.image_card_slider} />

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    drives: allKontentItemVirtualDrive(filter: {elements: {vd_type: {value: {elemMatch: {codename: {eq: "normal"}}}}}}, sort: {fields: elements___publishdate___value, order: DESC}, limit: 12) {
      nodes {
        ...VirtualDriveCards
      }
    }
    page: kontentItemVdOverview(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        cta_buttons {
          value {
            ...KontentItemCtaButton
          }
        }
        headline {
          value
        }
        hero_headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        headline_current_drives {
          value
        }
        eyecatcher_image {
          value {
            ...KontentItemEyecatcherImage
          }
        }
        eyecatcher_sidebar {
          value {
            ...KontentItemEyecatcherSidebar
          }
        }
        rich_text {
          value
        }
        text {
          value
        }
        learn_content_section {
            value {
              ...KontentItemRelatedLearnContentEmbed
            }
        }
        image_card_slider {
          value {
          ...KontentItemImageCardSliderSection
          }
        }
        media {
          value {
            ...KontentItemVideoEmbed
          }
        }
        disable_counter {
          value {
            codename
          }
        }
        button_url_vd_current_drives {
          value {
            id
          }
        }
      }
    }
  }
`;

KontentItemVdOverview.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemVdOverview;
