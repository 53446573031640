import { graphql, useStaticQuery } from "gatsby";

const getAuthorByCodename = (codename) => {
  if (!codename) {
    return null;
  }

  const data = useStaticQuery(graphql`
    {
      allKontentItemAuthorDetails {
        nodes {
          id
          elements {
            author_name {
              value {
                codename
              }
            }
            contact_item {
              value {
                ...KontentItemContact
              }
            }
            long_description {
              value
            }
          }
        }
      }
    }
  `);

  const author = data.allKontentItemAuthorDetails.nodes.find(
    (item) => item.elements.author_name.value.length > 0
      && item.elements.author_name.value[0].codename === codename,
  );

  if (!author?.id) {
    return null;
  }

  return author;
};

export default getAuthorByCodename;
