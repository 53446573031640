import React from "react";

import PropTypes from "prop-types";
import Select, { components } from "react-select";

import { IconDropdownExpand18, IconDropdownMinimize18 } from "../../icons";

import "./dropdownLanguage.scss";

const DropdownLanguage = (props) => {
  const {
    id,
    dropdownItems,
  } = props;

  let activeIndex = 0;
  let maxLength = 0;

  dropdownItems.forEach((item, key) => {
    if (item.item.props.active) {
      activeIndex = key;
    }

    if (item.item.props.text.length > maxLength) {
      maxLength = item.item.props.text.length;
    }
  });

  const maxWidthElements = { maxWidth: `${(8 * maxLength) + 100}px` };
  const cursorPointer = { cursor: "pointer" };

  const indicator = (options) => {
    const { selectProps } = options;

    return (
      <components.DropdownIndicator {...options}>
        {selectProps.menuIsOpen && <IconDropdownMinimize18 />}
        {!selectProps.menuIsOpen && <IconDropdownExpand18 />}
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      components={{
        DropdownIndicator: (options) => indicator(options),
        IndicatorSeparator: () => null,
      }}
      id={id}
      isSearchable={false}
      defaultValue={dropdownItems[activeIndex]}
      options={dropdownItems}
      formatOptionLabel={(data) => data.item}
      hideSelectedOptions
      onChange={(data) => {
        if (window) {
          const newWindow = window.open(
            data.item.props.url,
            "_blank",
            "noopener,noreferrer",
          );

          newWindow.opener = null;
        }
      }}
      className="dropdown-language"
      classNamePrefix="dropdown-language"
      styles={{
        control: (styles) => ({ ...styles, ...maxWidthElements, ...cursorPointer }),
        menu: (styles) => ({ ...styles, ...maxWidthElements, ...cursorPointer }),
      }}
    />
  );
};

DropdownLanguage.propTypes = {
  dropdownItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  id: PropTypes.string.isRequired,
};

export default DropdownLanguage;
