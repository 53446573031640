import React from "react";

import useLanguage from "../../hooks/useLanguage";
import {
  IconDollar18,
  IconEuro18,
  IconPounds18,
  IconRand18,
  IconRupie18,
  IconZloty18,
} from "../../icons";

const CurrencyIcon = () => {
  const {
    countryCode,
  } = useLanguage();

  switch (countryCode) {
    case "us":
    case "cl":
      return <IconDollar18 />;
    case "de":
      return <IconEuro18 />;
    case "gb":
      return <IconPounds18 />;
    case "pl":
      return <IconZloty18 />;
    case "in":
      return <IconRupie18 />;
    case "za":
      return <IconRand18 />;
    default:
      return null;
  }
};

export default CurrencyIcon;
