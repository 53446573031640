import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import DonorGraphic from "../components/donorPath/donorGraphic";

const KontentItemPathIllustrationStep = ({ data, headline, description }) => {
  const donorPathTopics = Array.isArray(data) ? data : data.value;

  if (donorPathTopics.length === 0) {
    return null;
  }

  const topics = [];

  donorPathTopics.forEach((item) => {
    const articles = [];

    if (item.elements.donor_topic.value.length > 0) {
      item.elements.donor_topic.value[0].elements.donor_articles_section.value.forEach(
        (article) => {
          articles.push(
            { href: article.id, id: article.id, text: article.elements.headline.value },
          );
        },
      );
    }

    topics.push({
      articles,
      description: item.elements.description.value,
      href: item.elements.donor_topic.value[0].id,
      id: item.id,
      number: item.elements.step.value.length > 0 ? item.elements.step.value[0].name.replace(/[a-zA-Z]/gi, "") : null,
      stepNumber: item.elements.step.value.length > 0 ? item.elements.step.value[0].name : null,
      topic: item.elements.title.value,
      topicText: item.elements.description.value,
    });
  });

  return (
    <DonorGraphic
      headline={headline}
      description={description}
      topics={topics}
    />
  );
};

export const query = graphql`
  fragment KontentItemPathIllustrationStep on kontent_item_path_illustration_step {
    id
    system {
      codename
    }
    elements {
      description {
        value
      }
      title {
        value
      }
      step {
        value {
          name
        }
      }
      donor_topic {
        value {
          ... on kontent_item_donor_area_topic {
            id
            elements {
              donor_articles_section {
                value {
                  ... on kontent_item_article_donor_area {
                    id
                    elements {
                      headline {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

KontentItemPathIllustrationStep.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  description: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
};

export default KontentItemPathIllustrationStep;
