import React, { useContext } from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import useSspSettings from "../../../hooks/useSspSettings";
import { IconDropdownExpand18, IconProfile18 } from "../../../icons";
import { isSelfServicePortalEnabled, showUserNavigation } from "../../../services/selfServicePortal.service";
import { getUserInfo, isAuthenticated } from "../../../services/sspAuthentication.service";
import SimpleRichText from "../../../templates/text/simpleRichText";
import SspNavigation from "../../navigation/sspNavigation";
import LoginButton from "../authentication/loginButton";
import LogoutButton from "../authentication/logoutButton";
import Avatar from "../avatar/avatar";
import { SspContext } from "../sspContext/sspContext";

import "./userNavigation.scss";

const UserNavigation = () => {
  const data = getUserInfo();

  if (!showUserNavigation(data)) {
    return <></>;
  }

  const isLoggedIn = isAuthenticated();

  const {
    navigationActive,
  } = useContext(SspContext);

  const { loginInfoText, maintenanceInfoText } = useSspSettings();
  const loginInfoRichText = SimpleRichText({ data: loginInfoText });
  const maintenanceModeInfoRichText = SimpleRichText({ data: maintenanceInfoText });

  return (
    <div className={classNames("ml-lg-4 user-navigation", { "user-navigation--logged-in": isLoggedIn })}>
      <Dropdown alignRight>
        <Dropdown.Toggle
          variant="utility"
          as={Button}
          size="sm"
          className={classNames("icon-btn", { active: navigationActive, "icon-only-btn": !isLoggedIn })}
          aria-label="User Navigation"
        >
          {!isLoggedIn ? (
            <IconProfile18 />
          ) : (
            <>
              <Avatar
                small
                name={data.fn}
              />
              <IconDropdownExpand18 />
            </>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          popperConfig={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 6],
                },
              },
            ],
          }}
        >
          {!isSelfServicePortalEnabled() && (
            <div className="dropdown-item user-navigation__description border-bottom-0 mb-0">
              {maintenanceModeInfoRichText}
            </div>
          )}
          {isSelfServicePortalEnabled() && (
            <>{!isLoggedIn
              ? (
                <>
                  {loginInfoRichText && (
                    <div className="dropdown-item user-navigation__description">
                      {loginInfoRichText}
                    </div>
                  )}
                  <LoginButton
                    className="dropdown-item"
                  />
                </>
              )
              : (
                <>

                  <SspNavigation className="dropdown-item" />

                  <div className="dropdown-divider" />

                  <div className="dropdown-item-text text-nowrap">
                    <FormattedMessage id="ssp.logged_in_as" /> {data.fn} {data.ln}
                  </div>

                  <LogoutButton
                    className="dropdown-item"
                  />
                </>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default UserNavigation;
