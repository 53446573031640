import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import IconButton from "../../components/button/iconButton";
import RegistrationDialogWrapper from "../../components/registrationDialog/registrationDialogWrapper";
import SEO from "../../components/seo";
import getVirtualDriveSetupSteps from "../../components/virtualDriveSetUpModal/virtualDriveSetupSteps";

const KontentItemVdSetup = ({ data }) => {
  const { page } = data;

  const image = data.page.elements.registration_dialog_background.value;

  return (
    <>
      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <RegistrationDialogWrapper
        steps={getVirtualDriveSetupSteps()}
        dialogType="virtual-drive-setup"
        image={
          image.length > 0
            ? `${image[0].url}?w=1920&h=1080&fm=pjpeg&auto=format`
            : null
        }
        content={
          {
            buttonExitUrl: page.elements.button_leave_url.value.length > 0
              ? page.elements.button_leave_url.value[0].id
              : null,
            contactLink:
              page.elements.contact.value.length > 0
                ? page.elements.contact.value[0].id
                : null,
            imageRadioButtonClubDrive: page.elements.image_club,
            imageRadioButtonCompanyDrive: page.elements.image_company,
            imageRadioButtonMotivationGeneral: page.elements.image_general,
            imageRadioButtonMotivationPatient: page.elements.image_patient,
            imageRadioButtonOwnIdeaDrive: page.elements.image_own_idea,
            imageRadioButtonPatientDrive: page.elements.image_patient_drive,
            imageRadioButtonPublicDrive: page.elements.image_public,
            imageRadioButtonSchoolDrive: page.elements.image_school,
            imageRadioButtonUniversityDrive: page.elements.image_university,
            imageSuccessPage: page.elements.image_mobile,
            privacyPolicyLink:
              page.elements.url_privacy_policy.value.length > 0
                ? page.elements.url_privacy_policy.value[0].id
                : null,
            successButton: (
              <IconButton
                variant="primary"
                target="_self"
                href={
                  page.elements.button_successful_url.value.length > 0
                    ? page.elements.button_successful_url.value[0].id
                    : null
                }
              >
                {page.elements.button_successful_text.value}
              </IconButton>
            ),
          }
        }
      />
    </>
  );
};

export const query = graphql`
  query ($id: String!) {
    page: kontentItemVdSetup(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        registration_dialog_background {
          value {
            url
            type
          }
        }
        button_successful_text {
          value
        }
        button_successful_url {
          value {
            id
          }
        }
        contact {
          value {
            id
          }
        }
        url_privacy_policy {
          value {
            id
          }
        }
        image_company {
          value {
            ...KontentItemImage
          }
        }
        image_general {
          value {
            ...KontentItemImage
          }
        }
        image_own_idea {
          value {
            ...KontentItemImage
          }
        }
        image_patient {
          value {
            ...KontentItemImage
          }
        }
        image_public {
          value {
            ...KontentItemImage
          }
        }
        image_university {
          value {
            ...KontentItemImage
          }
        }
        image_club {
          value {
            ...KontentItemImage
          }
        }
        image_mobile {
          value {
            ...KontentItemImage
          }
        }
        image_school {
          value {
            ...KontentItemImage
          }
        }
        image_patient_drive {
          value {
            ...KontentItemImage
          }
        }
        button_leave_url {
          value {
            id
          }
        }
      }
    }
  }
`;

KontentItemVdSetup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemVdSetup;
