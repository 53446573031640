import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemModalView from "./kontenItemModalView";
import SimpleRichText from "./text/simpleRichText";
import CollapseRow from "../components/collapseRow/collapseRow";

const KontentItemAccordion = ({ data, usePreIconForModal }) => {
  const accordion = data.value ? data.value[0] : data;

  if (!accordion || !accordion.elements) {
    return null;
  }

  return (
    <CollapseRow
      titleAs={accordion.elements.label_style.value.length > 0
        ? accordion.elements.label_style.value[0].codename.toLowerCase()
        : "p"}
      headline={accordion.elements.tab_label.value}
      id={accordion.id}
    >
      <SimpleRichText data={accordion.elements.tab_content} />
      <KontentItemModalView data={accordion.elements.legal_modal} usePreIcon={usePreIconForModal} buttonVariant="typo-button" />
    </CollapseRow>
  );
};

export const query = graphql`
  fragment KontentItemAccordion on kontent_item_accordion {
    id
    internal {
      type
    }
    system {
      codename
    }
    elements {
      tab_label {
        value
      }
      label_style {
        value {
          codename
          name
        }
      }
      tab_content {
        value
        images {
          width
          url
          image_id
          height
          description
        }
        links {
          link_id
          codename
          type
          url_slug
        }
        type
        name
        modular_content {
          id
          internal {
            type
          }
          system {
            codename
          }
          ...KontentItemImage
          ...KontentItemCtaButton
        }
      }
      legal_modal {
        value {
          ...KontentItemModalView
        }
      }
    }
  }
`;

KontentItemAccordion.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  usePreIconForModal: PropTypes.bool,
};

KontentItemAccordion.defaultProps = {
  usePreIconForModal: false,
};

export default KontentItemAccordion;
