import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import VariantHelper from "./utils/variantHelper";
import AdaptiveIcon from "../components/adaptiveIcon";
import IconButton from "../components/button/iconButton";
import TakeActionCard from "../components/takeActionCard/takeActionCard";
import { IconFurther18, IconFurther24 } from "../icons";

const KontentItemImageCard = (props) => {
  const {
    data,
    height,
    width,
    disableDescription,
  } = props;

  const imageCard = data;

  if (!imageCard || !imageCard.elements) {
    return null;
  }

  const variant = VariantHelper(imageCard.elements.button_style_color.value, "inverted");

  return (
    <TakeActionCard
      label={imageCard.elements.label.value}
      image={(
        <KontentItemImage
          data={imageCard.elements.image}
          imageOnlyWidth
          height={height}
          width={width}
          style={{
            height: "100%",
            width: "100%",
          }}
          fixed
        />
      )}
      description={disableDescription ? null : imageCard.elements.description.value}
      button={(
        <IconButton
          iconOnly
          variant={variant}
          aria-label={imageCard.elements.label.value}
        >
          <AdaptiveIcon
            sm={<IconFurther18 />}
            lg={<IconFurther24 />}
          />
        </IconButton>
      )}
      href={imageCard.elements.url.value.length > 0
        ? imageCard.elements.url.value[0].id
        : imageCard.elements.button_url_ext.value}
    />
  );
};

export const query = graphql`
  fragment KontentItemImageCard on kontent_item_image_card {
    id
    system {
      codename
    }
    elements {
      iconbutton {
        value {
          codename
        }
      }
      label {
        value
      }
      description {
        value
      }
      image {
        value {
          ...KontentItemImage
        }
      }
      url {
        value {
          id
        }
      }
      button_style_color {
        value {
          codename
        }
      }
      button_url_ext {
        value
      }
    }
  } 
`;

KontentItemImageCard.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  disableDescription: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};

KontentItemImageCard.defaultProps = {
  disableDescription: false,
  height: 500,
  width: 500,
};

export default KontentItemImageCard;
