import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import LatestNewsCards from "./news/latestNewsCards";
import CardSlider from "../components/cardSlider/cardSlider";

const KontentItemLatestNewsSliderSection = ({ data }) => {
  const latestNewsSection = data.value ? data.value[0] : data;

  if (!latestNewsSection || !latestNewsSection.elements) {
    return null;
  }

  return (
    <CardSlider
      header={latestNewsSection.elements.title.value}
      description={latestNewsSection.elements.description.value}
      moreUrl={latestNewsSection.elements.button_url.value.length > 0
        ? latestNewsSection.elements.button_url.value[0].id
        : null}
    >
      {LatestNewsCards({
        newsCategories: latestNewsSection.elements.news_category.value,
        pressCategories: latestNewsSection.elements.press_category.value,
      })}
    </CardSlider>
  );
};

export const query = graphql`
  fragment KontentItemLatestNewsSliderSection on kontent_item_latest_news_slider_section {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      description {
        value
      }
      button_url {
        value {
          id
        }
      }
      press_category {
        value {
          codename
        }
      }
      news_category {
        value {
          codename
        }
      }
    }
  } 
`;

KontentItemLatestNewsSliderSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemLatestNewsSliderSection;
