import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationEthnicityIn = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const validationSchema = useMemo(() => object().shape({
    motherTongueFather: string(),
    motherTongueMother: string(),
    stateFather: string()
      .required("Required"),
    stateMother: string()
      .required("Required"),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.ethnicity.title"
        subheadline="registration.ethnicity.subtitle"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              motherTongueFather: values.motherTongueFather,
              motherTongueMother: values.motherTongueMother,
              stateFather: values.stateFather,
              stateMother: values.stateMother,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_ethnicity", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className="registration-dialog__subheader mt-0">
                  <FormattedMessage id="registration.ethnicity.father_title" />
                </div>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.state" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("stateFather", target.value);
                      setFieldError("stateFather", undefined);
                    } else {
                      setFieldValue("stateFather", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("stateFather", true);
                  }}
                  touched={touched}
                  id="stateFather"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.stateOptionsIn}
                  required
                />
              </Col>
              <Col xs={12} className="mb-0">
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.mother_tongue" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("motherTongueFather", target.value);
                      setFieldError("motherTongueFather", undefined);
                    } else {
                      setFieldValue("motherTongueFather", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("motherTongueFather", true);
                  }}
                  touched={touched}
                  id="motherTongueFather"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.motherTongueOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="registration-dialog__subheader">
                  <FormattedMessage id="registration.ethnicity.mother_title" />
                </div>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.state" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("stateMother", target.value);
                      setFieldError("stateMother", undefined);
                    } else {
                      setFieldValue("stateMother", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("stateMother", true);
                  }}
                  touched={touched}
                  id="stateMother"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.stateOptionsIn}
                  required
                />
              </Col>
              <Col xs={12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.mother_tongue" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("motherTongueMother", target.value);
                      setFieldError("motherTongueMother", undefined);
                    } else {
                      setFieldValue("motherTongueMother", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("motherTongueMother", true);
                  }}
                  touched={touched}
                  id="motherTongueMother"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.motherTongueOptions}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationEthnicityIn.stepName = "registration_ethnicity";

RegistrationDialogStepRegistrationEthnicityIn.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationEthnicityIn;
