import React, { createRef, useEffect } from "react";

import { navigate } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { REGION_CITY_LIST, REGIONS_CL, title } from "./descriptions/general-form-data";
import Form from "./form";
import { createYupSchema, generateInitialValues, getTemplate } from "./formBuilder";
import { useSubmitIoUpdateAddressMutation } from "../../features/io/ioUpdateAddressApiSlice";
import {
  reset,
  setCurrentRegion,
  setFields,
  setFormValues,
  setPrevAddressCheckboxChecked,
  setPrevRegion,
  setTitleUK,
} from "../../features/updateAddressSlice";
import usePrevious from "../../hooks/usePrevious";
import useURNParams from "../../hooks/useURNParams";
import { IconSubmit24 } from "../../icons";
import { notificationService } from "../../services/notification.service";
import DataLayer from "../../utils/dataLayer";
import LinkButton from "../button/linkButton";
import Notification from "../notification/notification";

const AddressForm = getTemplate("UpdateAddress");

const UpdateAddress = ({ privacyConsent, thankYouPageLink }) => {
  const fields = useSelector((state) => state.updateAddress.fields);
  const formValues = useSelector((state) => state.updateAddress.formValues);
  const titleUK = useSelector((state) => state.updateAddress.titleUK);
  const prevAddressCheckboxChecked = useSelector((state) => (
    state.updateAddress.prevAddressCheckboxChecked
  ));
  const prevRegion = useSelector((state) => state.updateAddress.prevRegion);
  const currentRegion = useSelector((state) => state.updateAddress.currentRegion);
  const dispatch = useDispatch();

  const intl = useIntl();

  const urnParams = useURNParams();

  const updateAddressForm = createRef();

  const [submitIoUpdateAddress, submitIoUpdateAddressResult] = useSubmitIoUpdateAddressMutation();

  const prevsubmitIoUpdateAddressResult = usePrevious(submitIoUpdateAddressResult);

  const initFields = AddressForm.getUpdateAddressForm(intl);
  const filteredInputs = initFields.filter((field) => field.type === "input");
  const initialValues = generateInitialValues(filteredInputs);
  let additionalSubFieldsList = [];

  useEffect(() => {
    if (submitIoUpdateAddressResult.isSuccess && !prevsubmitIoUpdateAddressResult.isSuccess) {
      DataLayer.pushEvent("ppfs_ciu_success_goal");
      DataLayer.pushEvent("pp_ciu_submit", { event_value: "success" });
    }

    if (submitIoUpdateAddressResult.isError && !prevsubmitIoUpdateAddressResult.isError) {
      DataLayer.pushEvent("pp_ciu_submit", { event_value: "fail" });
    }
  }, [submitIoUpdateAddressResult]);

  const mappedValidation = filteredInputs.map((field) => {
    if (field.tagName === "RadioButtonSection") {
      additionalSubFieldsList = additionalSubFieldsList.concat(
        field.props.radioButtonLists.map((item) => ({
          id: item.id,
          validationType: item.validation.type,
          validations: item.validation.validators,
        })),
      );
    }

    if (field.tagName === "FormAddressInput") {
      additionalSubFieldsList = additionalSubFieldsList.concat(
        field.props.subFields.map((item) => ({
          id: item.props.id,
          validationType: item.validation.type,
          validations: item.validation.validators,
        })),
      );
    }

    return {
      id: field.props.id,
      validationType: field.validation.type,
      validations: field.validation.validators,
    };
  });

  const yupSchema = mappedValidation.concat(additionalSubFieldsList).reduce(createYupSchema, {});

  const validationSchema = Yup.object(yupSchema);

  const onCheckboxChanged = (event) => {
    if (event.target.checked) {
      dispatch(
        setPrevAddressCheckboxChecked(true),
      );

      return;
    }

    dispatch(
      setPrevAddressCheckboxChecked(false),
    );
  };

  const prevRegionSelected = (value, setFieldValue) => {
    setFieldValue("prev_city", undefined);

    dispatch(
      setPrevRegion(value),
    );
  };

  const currentRegionSelected = (value, setFieldValue) => {
    setFieldValue("current_city", undefined);

    dispatch(
      setCurrentRegion(value),
    );
  };

  const salutationSelected = (value) => {
    dispatch(
      setTitleUK(value),
    );
  };

  const setInitialFields = (_fields) => _fields.map((field) => {
    const f = { ...field };
    Object.freeze(f);

    if (f.props.id === "privacy") {
      f.props.data = privacyConsent;
    }

    if (process.env.GATSBY_SITE === "cl") {
      if (f.props.id === "prev_address_input") {
        f.props.subFields.forEach((item, index) => {
          if (item.props.id === "prev_region") {
            f.props.subFields[index].props.otherSelected = prevRegionSelected;
          }
        });
      }

      if (f.props.id === "current_address_input") {
        f.props.subFields.forEach((item, index) => {
          if (item.props.id === "current_region") {
            f.props.subFields[index].props.otherSelected = currentRegionSelected;
          }
        });
      }
    }

    if (process.env.GATSBY_SITE === "gb") {
      if (f.props.id === "salutation") {
        f.props.otherSelected = salutationSelected;
      }
    }

    return f;
  });

  const onSubmit = (values) => {
    dispatch(
      setFormValues(values),
    );

    const payload = AddressForm.generatePayload(values);

    if (
      values.address_unchanged
      || Object.keys(payload.former_address).every(
        (key) => payload.former_address[key] === payload.private_address[key],
      )
    ) {
      delete payload.former_address;
    }

    submitIoUpdateAddress({
      data: payload,
      urns: urnParams,
    });
  };

  const onSuccess = () => {
    dispatch(
      reset(),
    );

    navigate(thankYouPageLink);
  };

  const onError = () => {
    notificationService.error(intl.formatMessage({ id: "error.something_went_wrong" }), {
      action: (
        <LinkButton onClick={() => onSubmit(formValues)}>
          <FormattedMessage values={{ countdown: 0 }} id="io.error.retry" /> <IconSubmit24 />
        </LinkButton>),
      autoClose: false,
    });
  };

  useEffect(() => {
    dispatch(
      setFields(setInitialFields(initFields)),
    );

    const target = document.getElementById("update-address-form");

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const checkbox = document.getElementById("address_unchanged");

        if (checkbox) {
          checkbox.addEventListener("change", onCheckboxChanged, false);
          observer.disconnect();
        }
      });
    });
    const config = {
      attributes: true, characterData: false, childList: true, subtree: true,
    };

    observer.observe(target, config);
  }, []);

  useEffect(() => {
    if (submitIoUpdateAddressResult.isSuccess) {
      onSuccess();
    }

    if (submitIoUpdateAddressResult.isError) {
      onError();
    }
  }, [submitIoUpdateAddressResult]);

  useEffect(() => {
    if (prevAddressCheckboxChecked) {
      const subFieldParent = fields.filter(
        (field) => field.type === "input"
          && field.props.id.includes("prev_")
          && field.props.subFields?.length > 0,
      );

      if (subFieldParent.length > 0) {
        subFieldParent.shift().props.subFields
          .filter((field) => field.type === "input" && field.props.id.includes("prev_"))
          .forEach((e) => {
            updateAddressForm.current.setFieldValue(e.props.id, "");
          });
      }

      dispatch(
        setFields(fields.filter((field) => !field.props.id.includes("prev_"))),
      );

      updateAddressForm.current.validateForm().then(() => {});
    } else {
      const tmpFields = initFields.map((field) => {
        const f = { ...field };
        Object.freeze(f);

        if (title.includes(titleUK) && process.env.GATSBY_SITE === "gb" && f.props.id === "sex") {
          f.responsive.style.display = "block";
        }

        return f;
      });

      dispatch(
        setFields(setInitialFields(tmpFields)),
      );

      updateAddressForm.current.validateForm().then(() => {});
    }
  }, [prevAddressCheckboxChecked]);

  if (process.env.GATSBY_SITE === "gb") {
    useEffect(() => {
      if (titleUK) {
        dispatch(
          setFields(fields.map((field) => {
            const f = { ...field };
            Object.freeze(f);

            if (f.props.id === "sex") {
              f.responsive.style = {
                display: title.includes(titleUK) ? "block" : "none",
              };
            }

            return f;
          })),
        );
      }
    }, [titleUK]);
  }

  if (process.env.GATSBY_SITE === "cl") {
    useEffect(() => {
      if (prevRegion) {
        dispatch(
          setFields(fields.map((field) => {
            const f = { ...field };
            Object.freeze(f);

            if (f.props.id === "prev_address_input") {
              f.props.subFields.forEach((element, index) => {
                if (element.props.id === "prev_city") {
                  const region = REGIONS_CL.find((el) => el.value === prevRegion);

                  const regionWithCities = REGION_CITY_LIST.find((e) => e.label === region.label);
                  const cities = regionWithCities.options;

                  f.props.subFields[index].options = cities || [];
                  f.props.subFields[index].responsive.style = {
                    display: "block",
                  };
                }
              });
            }

            return f;
          })),
        );
      }
    }, [prevRegion]);

    useEffect(() => {
      if (currentRegion) {
        dispatch(
          setFields(fields.map((field) => {
            const f = { ...field };
            Object.freeze(f);

            if (f.props.id === "current_address_input") {
              f.props.subFields.forEach((element, index) => {
                if (element.props.id === "current_city") {
                  const region = REGIONS_CL.find((el) => el.value === currentRegion);
                  const regionWithCities = REGION_CITY_LIST.find((e) => e.label === region.label);
                  const cities = regionWithCities.options;

                  f.props.subFields[index].options = cities || [];
                  f.props.subFields[index].responsive.style = {
                    display: "block",
                  };
                }
              });
            }

            return f;
          })),
        );
      }
    }, [currentRegion]);
  }

  return (
    <>
      <div className="dynamic-form">
        <div id="update-address-form">
          <Form
            ref={updateAddressForm}
            loading={submitIoUpdateAddressResult.isLoading}
            fields={fields}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            footNote={intl.formatMessage({ defaultMessage: " ", id: "address_update.footnote" })}
            submitButtonLabel={intl.formatMessage({ id: "form.updateAddress.submit" })}
            summaryInfo={intl.formatMessage({ id: "address_update.summary.info" })}
          />
        </div>
      </div>
      <Notification />
    </>
  );
};

UpdateAddress.propTypes = {
  privacyConsent: PropTypes.oneOfType([PropTypes.object]).isRequired,
  thankYouPageLink: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default UpdateAddress;
