/* eslint-disable sort-keys */
import { cleanupObject } from "../../utils/formik/objectUtils";

export default class SetupDataHelper {
  static preparePayload(data) {
    const payload = SetupDataHelper.#getPostData(data);

    return cleanupObject(payload);
  }

  static #getPostData = (data) => ({
    inq_data: {
      motivation: data.motivationValues[data.motivation],
      relationship: data.relationship,
      drive_type: data.driveTypeValue,
      drive_title: data.driveTitle,
      drive_story: data.motivationForDrive,
      facebook: data.facebook,
      instagram: data.instagram,
      twitter: data.twitter,
    },
    organization: {
      name: data.organizationName,
      location: data.location,
      size: data.numberPersons,
      website: data.website,
    },
    person: {
      firstname: data.firstname, // required
      lastname: data.lastname, // required
    }, // required
    contact: {
      email: data.email, // required
      phone: data.phone,
    }, // required
  });
}
