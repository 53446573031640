import React from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import MainBreadcrumb from "react-bootstrap/Breadcrumb";

import useWindowDimensions from "../../hooks/useWindowDimensions";
import { IconAnchorLeft18, IconBigHome18, IconHome18 } from "../../icons";
import AdaptiveIcon from "../adaptiveIcon";

import "./breadcrumb.scss";

const Breadcrumb = ({ items, lastItemAs, withHomeIcon }) => {
  const { width } = useWindowDimensions();
  const intl = useIntl();

  return (
    <MainBreadcrumb className="breadcrumb-container">
      {
        // width === 0 for initial build
        width === 0 || width > 576 || items.length < 3
          ? (
            <>
              {withHomeIcon && (
                <MainBreadcrumb.Item
                  className="typo-small-label breadcrumb__home"
                  href="/"
                  active={items.length === 0}
                  linkProps={{ "aria-label": intl.formatMessage({ id: "breadcrumb.home.aria_label" }) }}
                >
                  <AdaptiveIcon
                    sm={<IconBigHome18 />}
                    lg={<IconHome18 />}
                  />
                </MainBreadcrumb.Item>
              )}
              {items.map((item, i) => (
                <MainBreadcrumb.Item
                  as={i + 1 === items.length ? lastItemAs : undefined}
                  active={i + 1 === items.length}
                  className="typo-small-label"
                  href={item.link}
                  key={item.title}
                >
                  {item.title}
                </MainBreadcrumb.Item>
              ))}
            </>
          )
          : (
            <MainBreadcrumb.Item
              className="typo-small-label"
              href={items[items.length - 2].link}
            >
              <span><IconAnchorLeft18 /></span><FormattedMessage id="breadcrumb.view" /> {items[items.length - 2].title}
            </MainBreadcrumb.Item>
          )
      }
    </MainBreadcrumb>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array]),
  lastItemAs: PropTypes.oneOf(["h1", "li"]),
  withHomeIcon: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  items: [],
  lastItemAs: "li",
  withHomeIcon: true,
};

export default Breadcrumb;
