import React, { useMemo } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, ref, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconError18, IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import CleaveFormField from "../../dynamicForm/cleaveFormField/cleaveFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationAdditionalInformationCl = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const emailMessage = intl.formatMessage({ id: "registration.additional.error.email" });

  const validationSchema = useMemo(() => object().shape({
    additionalEmailAddress: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage),
    additionalPhoneNumber: string()
      .matches(
        /^([\d+\s]{12}|[\d+\s]{3})$/,
        intl.formatMessage({ id: "form.error.phone_pattern" }),
      ),
    confirmEmailAddress: string()
      .required(requiredMessage)
      .oneOf([ref("emailAddress")], intl.formatMessage({ id: "registration.additional.error.email_confirm" })),
    emailAddress: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage),
    mobileNumber: string()
      .required(requiredMessage)
      .matches(
        /^[\d+\s]{12}$/,
        intl.formatMessage({ id: "form.error.phone_pattern" }),
      ),
    whatsApp: string()
      .required(requiredMessage),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.additional.title"
      />
      <Formik
        initialValues={{
          additionalEmailAddress: registrationData.additionalEmailAddress,
          additionalPhoneNumber: registrationData.additionalPhoneNumber,
          confirmEmailAddress: registrationData.emailAddress,
          emailAddress: registrationData.emailAddress,
          mobileNumber: registrationData.mobileNumber,
          whatsApp: registrationData.whatsApp,
        }}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};

          const {
            emailAddress,
            additionalEmailAddress,
            mobileNumber,
            additionalPhoneNumber,
          } = values;

          if (emailAddress.toLowerCase() === additionalEmailAddress.toLowerCase()) {
            errors.additionalEmailAddress = intl.formatMessage({ id: "registration.additional.error.same_email" });
          }

          if (mobileNumber === additionalPhoneNumber) {
            errors.additionalPhoneNumber = intl.formatMessage({ id: "registration.alternate_contact.error.same_phone" });
          }

          return errors;
        }}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              additionalEmailAddress: values.additionalEmailAddress,
              additionalPhoneNumber: values.additionalPhoneNumber !== "+56" ? values.additionalPhoneNumber : "",
              emailAddress: values.emailAddress,
              mobileNumber: values.mobileNumber,
              whatsApp: values.whatsApp,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_additional_information", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <CleaveFormField
                  label={intl.formatMessage({ id: "registration.additional.mobileNumber" })}
                  values={values}
                  onChange={(event) => {
                    setFieldTouched("whatsApp", true);
                    handleChange(event);
                  }}
                  onBlur={(event) => {
                    setFieldTouched("whatsApp", true);
                    handleBlur(event);
                  }}
                  touched={touched}
                  id="mobileNumber"
                  errors={errors}
                  options={{
                    blocks: [12],
                    delimiter: "",
                    numericOnly: false,
                    prefix: "+569",
                  }}
                  maxLength={12}
                  required
                />
              </Col>
              <Col xs={12}>
                <p className="required-sign">
                  <FormattedMessage id="registration.additional.whatsapp" />
                </p>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.additional.whatsapp_yes" })}
                    name="whatsApp"
                    value="yes"
                    id="whatsapp-yes"
                    checked={values.whatsApp === "yes"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0", errors.whatsApp && touched.whatsApp ? "is-invalid" : "")}
                  />
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.additional.whatsapp_no" })}
                    name="whatsApp"
                    value="no"
                    id="whatsapp-no"
                    checked={values.whatsApp === "no"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0", errors.whatsApp && touched.whatsApp ? "is-invalid" : "")}
                  />
                  {errors.whatsApp && touched.whatsApp && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.whatsApp}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <CleaveFormField
                  label={intl.formatMessage({ id: "registration.additional.additionalPhoneNumber" })}
                  values={values}
                  onChange={(event) => {
                    setFieldTouched("whatsApp", true);
                    handleChange(event);
                  }}
                  onBlur={(event) => {
                    setFieldTouched("whatsApp", true);
                    handleBlur(event);
                  }}
                  touched={touched}
                  id="additionalPhoneNumber"
                  errors={errors}
                  options={{
                    blocks: [12],
                    delimiter: "",
                    numericOnly: false,
                    prefix: "+56",
                  }}
                  maxLength={12}
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="emailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email" })}
                  type="email"
                  maxLength={100}
                  required
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="confirmEmailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email_confirm" })}
                  type="email"
                  maxLength={100}
                  required
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="additionalEmailAddress"
                  label={intl.formatMessage({ id: "registration.additional.additional_email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.additional.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationAdditionalInformationCl.stepName = "registration_additional_information";

RegistrationDialogStepRegistrationAdditionalInformationCl.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationAdditionalInformationCl;
