export const usStates = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachussetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "Washington D.C.", value: "DC" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const salutationUk = (intl) => [
  { label: intl.formatMessage({ id: "form.title.dr" }), value: "Dr." },
  { label: intl.formatMessage({ id: "form.title.prof" }), value: "Prof." },
];

export const salutationGermany = (intl) => [
  { label: intl.formatMessage({ id: "form.salutation.mr" }), value: "Mr" },
  { label: intl.formatMessage({ id: "form.salutation.mrs" }), value: "Mrs" },
];

export const titleGenderMapping = {
  Miss: "F",
  Mr: "M",
  "Mr.": "M",
  Mrs: "F",
  "Mrs.": "F",
  "Ms.": "F",
};

export const title = [
  { label: "Dr.", value: "Dr." },
  { label: "Prof.", value: "Prof." },
];

export const STATES_IN = [
  { label: "Andaman and Nicobar Islands", value: "AN" },
  { label: "Andhra Pradesh", value: "AP" },
  { label: "Arunachal Pradesh", value: "AR" },
  { label: "Assam", value: "AS" },
  { label: "Bihar", value: "BR" },
  { label: "Chandigarh", value: "CH" },
  { label: "Chhattisgarh", value: "CT" },
  { label: "Dadra and Nagar Haveli", value: "DN" },
  { label: "Daman and Diu", value: "DD" },
  { label: "Delhi", value: "DL" },
  { label: "Goa", value: "GA" },
  { label: "Gujarat", value: "GJ" },
  { label: "Haryana", value: "HR" },
  { label: "Himachal Pradesh", value: "HP" },
  { label: "Jammu and Kashmir", value: "JK" },
  { label: "Jharkhand", value: "JH" },
  { label: "Karnataka", value: "KA" },
  { label: "Kerala", value: "KL" },
  { label: "Lakshadweep", value: "LD" },
  { label: "Madhya Pradesh", value: "MP" },
  { label: "Maharashtra", value: "MH" },
  { label: "Manipur", value: "MN" },
  { label: "Meghalaya", value: "ML" },
  { label: "Mizoram", value: "MZ" },
  { label: "Nagaland", value: "NL" },
  { label: "Odisha", value: "OR" },
  { label: "Puducherry", value: "PY" },
  { label: "Punjab", value: "PB" },
  { label: "Rajasthan", value: "RJ" },
  { label: "Sikkim", value: "SK" },
  { label: "Tamil Nadu", value: "TN" },
  { label: "Telangana", value: "TG" },
  { label: "Tripura", value: "TR" },
  { label: "Uttar Pradesh", value: "UP" },
  { label: "Uttarakhand", value: "UT" },
  { label: "West Bengal", value: "WB" },
  { label: "Other", value: "OTHER" },
];

export const sex = (intl) => [
  { label: intl.formatMessage({ id: "form.sex.male" }), value: "M" },
  { label: intl.formatMessage({ id: "form.sex.female" }), value: "F" },
];

export const keepInTouchOptions = (intl, key) => {
  switch (key) {
    case "telephone":
      return [
        {
          className: "",
          id: "touch_telephone_yes",
          label: intl.formatMessage({ id: "keep_in_touch.option.yes" }),
          value: "YES",
        },
        {
          className: "",
          id: "touch_telephone_no",
          label: intl.formatMessage({ id: "keep_in_touch.option.no" }),
          value: "NO",
        },
      ];
    case "post":
      return [
        {
          className: "",
          id: "touch_post_yes",
          label: intl.formatMessage({ id: "keep_in_touch.option.yes" }),
          value: "YES",
        },
        {
          className: "",
          id: "touch_post_no",
          label: intl.formatMessage({ id: "keep_in_touch.option.no" }),
          value: "NO",
        },
      ];
    case "text_message":
      return [
        {
          className: "",
          id: "touch_text_message_yes",
          label: intl.formatMessage({ id: "keep_in_touch.option.yes" }),
          value: "YES",
        },
        {
          className: "",
          id: "touch_text_message_no",
          label: intl.formatMessage({ id: "keep_in_touch.option.no" }),
          value: "NO",
        },
      ];
    case "email":
      return [
        {
          className: "",
          id: "touch_email_yes",
          label: intl.formatMessage({ id: "keep_in_touch.option.yes" }),
          value: "YES",
        },
        {
          className: "",
          id: "touch_email_no",
          label: intl.formatMessage({ id: "keep_in_touch.option.no" }),
          value: "NO",
        },
      ];
    default:
      return [];
  }
};

export const whatsappOptions = (intl) => [
  { label: intl.formatMessage({ id: "registration.additional.whatsapp_yes" }), value: "yes" },
  { label: intl.formatMessage({ id: "registration.additional.whatsapp_no" }), value: "no" },
];

export const REGIONS_CL = [
  { label: "Región de Arica y Parinacota", value: "REGIÓN DE ARICA Y PARINACOTA" },
  { label: "Región de Tarapacá", value: "REGIÓN DE TARAPACA" },
  { label: "Región de Antofagasta", value: "REGIÓN DE ANTOFAGASTA" },
  { label: "Región de Atacama", value: "REGIÓN DE ATACAMA" },
  { label: "Región de Coquimbo", value: "REGIÓN DE COQUIMBO" },
  { label: "Región de Valparaíso", value: "REGIÓN DE VALPARAÍSO" },
  { label: "Región Metropolitana", value: "REGIÓN METROPOLITANA DE SANTIAGO" },
  { label: "Región del Libertador B. O'Higgins", value: "REGIÓN DEL LIBERTADOR B. O'HIGGINS" },
  { label: "Región del Maule", value: "REGIÓN DEL MAULE" },
  { label: "Región de Ñuble", value: "REGIÓN DE ÑUBLE" },
  { label: "Región del Bíobío", value: "REGIÓN DEL BÍOBÍO" },
  { label: "Región de La Araucanía", value: "REGIÓN DE LA ARAUCANÍA" },
  { label: "Región de Los Ríos", value: "REGIÓN DE LOS RÍOS" },
  { label: "Región de Los Lagos", value: "REGIÓN DE LOS LAGOS" },
  { label: "Región de Aysén del Gral. Carlos Ibáñez del Campo", value: "REGIÓN DE AISÉN DEL GRAL. C. IBÁÑEZ DEL CAMPO" },
  { label: "Región de Magallanes y de la Antártica Chilena", value: "REGIÓN DE MAGALLANES Y DE LA ANTÁRTICA CHILENA" },
];

export const REGION_CITY_LIST = [
  {
    label: "Región de Arica y Parinacota",
    options: [
      {
        label: "Arica",
        value: "ARICA",
      },
      {
        label: "Camarones",
        value: "CAMARONES",
      },
      {
        label: "Putre",
        value: "PUTRE",
      },
      {
        label: "General Lagos",
        value: "GENERAL LAGOS",
      },
    ],
  },
  {
    label: "Región de Tarapacá",
    options: [
      {
        label: "Iquique",
        value: "IQUIQUE",
      },
      {
        label: "Camiña",
        value: "CAMIÑA",
      },
      {
        label: "Colchane",
        value: "COLCHANE",
      },
      {
        label: "Huara",
        value: "HUARA",
      },
      {
        label: "Pica",
        value: "PICA",
      },
      {
        label: "Pozo Almonte",
        value: "POZO ALMONTE",
      },
      {
        label: "Alto Hospicio",
        value: "ALTO HOSPICIO",
      },
    ],
  },
  {
    label: "Región de Antofagasta",
    options: [
      {
        label: "Antofagasta",
        value: "ANTOFAGASTA",
      },
      {
        label: "Mejillones",
        value: "MEJILLONES",
      },
      {
        label: "Sierra Gorda",
        value: "SIERRA GORDA",
      },
      {
        label: "Taltal",
        value: "TALTAL",
      },
      {
        label: "Calama",
        value: "CALAMA",
      },
      {
        label: "Ollagüe",
        value: "OLLAGÜE",
      },
      {
        label: "San Pedro de Atacama",
        value: "SAN PEDRO DE ATACAMA",
      },
      {
        label: "Tocopilla",
        value: "TOCOPILLA",
      },
      {
        label: "María Elena",
        value: "MARÍA ELENA",
      },
    ],
  },
  {
    label: "Región de Atacama",
    options: [
      {
        label: "Copiapó",
        value: "COPIAPÓ",
      },
      {
        label: "Caldera",
        value: "CALDERA",
      },
      {
        label: "Tierra Amarilla",
        value: "TIERRA AMARILLA",
      },
      {
        label: "Chañaral",
        value: "CHAÑARAL",
      },
      {
        label: "Diego de Almagro",
        value: "DIEGO DE ALMAGRO",
      },
      {
        label: "Vallenar",
        value: "VALLENAR",
      },
      {
        label: "Alto del Carmen",
        value: "ALTO DEL CARMEN",
      },
      {
        label: "Freirina",
        value: "FREIRINA",
      },
      {
        label: "Huasco",
        value: "HUASCO",
      },
    ],
  },
  {
    label: "Región de Coquimbo",
    options: [
      {
        label: "La Serena",
        value: "LA SERENA",
      },
      {
        label: "Coquimbo",
        value: "COQUIMBO",
      },
      {
        label: "Andacollo",
        value: "ANDACOLLO",
      },
      {
        label: "La Higuera",
        value: "LA HIGUERA",
      },
      {
        label: "Paiguano",
        value: "PAIGUANO",
      },
      {
        label: "Vicuña",
        value: "VICUÑA",
      },
      {
        label: "Illapel",
        value: "ILLAPEL",
      },
      {
        label: "Canela",
        value: "CANELA",
      },
      {
        label: "Los Vilos",
        value: "LOS VILOS",
      },
      {
        label: "Salamanca",
        value: "SALAMANCA",
      },
      {
        label: "Ovalle",
        value: "OVALLE",
      },
      {
        label: "Combarbalá",
        value: "COMBARBALÁ",
      },
      {
        label: "Monte Patria",
        value: "MONTE PATRIA",
      },
      {
        label: "Punitaqui",
        value: "PUNITAQUI",
      },
      {
        label: "Río Hurtado",
        value: "RÍO HURTADO",
      },
    ],
  },
  {
    label: "Región de Valparaíso",
    options: [
      {
        label: "Valparaíso",
        value: "VALPARAÍSO",
      },
      {
        label: "Casablanca",
        value: "CASABLANCA",
      },
      {
        label: "Concón",
        value: "CONCÓN",
      },
      {
        label: "Juan Fernández",
        value: "JUAN FERNÁNDEZ",
      },
      {
        label: "Puchuncaví",
        value: "PUCHUNCAVÍ",
      },
      {
        label: "Quilpué",
        value: "QUILPUÉ",
      },
      {
        label: "Quintero",
        value: "QUINTERO",
      },
      {
        label: "Villa Alemana",
        value: "VILLA ALEMANA",
      },
      {
        label: "Viña del Mar",
        value: "VIÑA DEL MAR",
      },
      {
        label: "Isla de Pascua",
        value: "ISLA DE PASCUA",
      },
      {
        label: "Los Andes",
        value: "LOS ANDES",
      },
      {
        label: "Calle Larga",
        value: "CALLE LARGA",
      },
      {
        label: "Rinconada",
        value: "RINCONADA",
      },
      {
        label: "San Esteban",
        value: "SAN ESTEBAN",
      },
      {
        label: "La Ligua",
        value: "LA LIGUA",
      },
      {
        label: "Cabildo",
        value: "CABILDO",
      },
      {
        label: "Papudo",
        value: "PAPUDO",
      },
      {
        label: "Petorca",
        value: "PETORCA",
      },
      {
        label: "Zapallar",
        value: "ZAPALLAR",
      },
      {
        label: "Quillota",
        value: "QUILLOTA",
      },
      {
        label: "Calera",
        value: "CALERA",
      },
      {
        label: "Hijuelas",
        value: "HIJUELAS",
      },
      {
        label: "La Cruz",
        value: "LA CRUZ",
      },
      {
        label: "Limache",
        value: "LIMACHE",
      },
      {
        label: "Nogales",
        value: "NOGALES",
      },
      {
        label: "Olmué",
        value: "OLMUÉ",
      },
      {
        label: "San Antonio",
        value: "SAN ANTONIO",
      },
      {
        label: "Algarrobo",
        value: "ALGARROBO",
      },
      {
        label: "Cartagena",
        value: "CARTAGENA",
      },
      {
        label: "El Quisco",
        value: "EL QUISCO",
      },
      {
        label: "El Tabo",
        value: "EL TABO",
      },
      {
        label: "Santo Domingo",
        value: "SANTO DOMINGO",
      },
      {
        label: "San Felipe",
        value: "SAN FELIPE",
      },
      {
        label: "Catemu",
        value: "CATEMU",
      },
      {
        label: "Llaillay",
        value: "LLAILLAY",
      },
      {
        label: "Panquehue",
        value: "PANQUEHUE",
      },
      {
        label: "Putaendo",
        value: "PUTAENDO",
      },
      {
        label: "Santa María",
        value: "SANTA MARÍA",
      },
    ],
  },
  {
    label: "Región Metropolitana",
    options: [
      {
        label: "Santiago",
        value: "SANTIAGO",
      },
      {
        label: "Cerrillos",
        value: "CERRILLOS",
      },
      {
        label: "Cerro Navia",
        value: "CERRO NAVIA",
      },
      {
        label: "Conchalí",
        value: "CONCHALÍ",
      },
      {
        label: "El Bosque",
        value: "EL BOSQUE",
      },
      {
        label: "Estación Central",
        value: "ESTACIÓN CENTRAL",
      },
      {
        label: "Huechuraba",
        value: "HUECHURABA",
      },
      {
        label: "Independencia",
        value: "INDEPENDENCIA",
      },
      {
        label: "La Cisterna",
        value: "LA CISTERNA",
      },
      {
        label: "La Florida",
        value: "LA FLORIDA",
      },
      {
        label: "La Granja",
        value: "LA GRANJA",
      },
      {
        label: "La Pintana",
        value: "LA PINTANA",
      },
      {
        label: "La Reina",
        value: "LA REINA",
      },
      {
        label: "Las Condes",
        value: "LAS CONDES",
      },
      {
        label: "Lo Barnechea",
        value: "LO BARNECHEA",
      },
      {
        label: "Lo Espejo",
        value: "LO ESPEJO",
      },
      {
        label: "Lo Prado",
        value: "LO PRADO",
      },
      {
        label: "Macul",
        value: "MACUL",
      },
      {
        label: "Maipú",
        value: "MAIPÚ",
      },
      {
        label: "Ñuñoa",
        value: "ÑUÑOA",
      },
      {
        label: "Pedro Aguirre Cerda",
        value: "PEDRO AGUIRRE CERDA",
      },
      {
        label: "Peñalolén",
        value: "PEÑALOLÉN",
      },
      {
        label: "Providencia",
        value: "PROVIDENCIA",
      },
      {
        label: "Pudahuel",
        value: "PUDAHUEL",
      },
      {
        label: "Quilicura",
        value: "QUILICURA",
      },
      {
        label: "Quinta Normal",
        value: "QUINTA NORMAL",
      },
      {
        label: "Recoleta",
        value: "RECOLETA",
      },
      {
        label: "Renca",
        value: "RENCA",
      },
      {
        label: "San Joaquín",
        value: "SAN JOAQUÍN",
      },
      {
        label: "San Miguel",
        value: "SAN MIGUEL",
      },
      {
        label: "San Ramón",
        value: "SAN RAMÓN",
      },
      {
        label: "Vitacura",
        value: "VITACURA",
      },
      {
        label: "Puente Alto",
        value: "PUENTE ALTO",
      },
      {
        label: "Pirque",
        value: "PIRQUE",
      },
      {
        label: "San José de Maipo",
        value: "SAN JOSÉ DE MAIPO",
      },
      {
        label: "Colina",
        value: "COLINA",
      },
      {
        label: "Lampa",
        value: "LAMPA",
      },
      {
        label: "Tiltil",
        value: "TILTIL",
      },
      {
        label: "San Bernardo",
        value: "SAN BERNARDO",
      },
      {
        label: "Buin",
        value: "BUIN",
      },
      {
        label: "Calera de Tango",
        value: "CALERA DE TANGO",
      },
      {
        label: "Paine",
        value: "PAINE",
      },
      {
        label: "Melipilla",
        value: "MELIPILLA",
      },
      {
        label: "Alhué",
        value: "ALHUÉ",
      },
      {
        label: "Curacaví",
        value: "CURACAVÍ",
      },
      {
        label: "María Pinto",
        value: "MARÍA PINTO",
      },
      {
        label: "San Pedro",
        value: "SAN PEDRO",
      },
      {
        label: "Talagante",
        value: "TALAGANTE",
      },
      {
        label: "El Monte",
        value: "EL MONTE",
      },
      {
        label: "Isla de Maipo",
        value: "ISLA DE MAIPO",
      },
      {
        label: "Padre Hurtado",
        value: "PADRE HURTADO",
      },
      {
        label: "Peñaflor",
        value: "PEÑAFLOR",
      },
    ],
  },
  {
    label: "Región del Libertador B. O'Higgins",
    options: [
      {
        label: "Rancagua",
        value: "RANCAGUA",
      },
      {
        label: "Codegua",
        value: "CODEGUA",
      },
      {
        label: "Coinco",
        value: "COINCO",
      },
      {
        label: "Coltauco",
        value: "COLTAUCO",
      },
      {
        label: "Doñihue",
        value: "DOÑIHUE",
      },
      {
        label: "Graneros",
        value: "GRANEROS",
      },
      {
        label: "Las Cabras",
        value: "LAS CABRAS",
      },
      {
        label: "Machalí",
        value: "MACHALÍ",
      },
      {
        label: "Malloa",
        value: "MALLOA",
      },
      {
        label: "Mostazal",
        value: "MOSTAZAL",
      },
      {
        label: "Olivar",
        value: "OLIVAR",
      },
      {
        label: "Peumo",
        value: "PEUMO",
      },
      {
        label: "Pichidegua",
        value: "PICHIDEGUA",
      },
      {
        label: "Quinta de Tilcoco",
        value: "QUINTA DE TILCOCO",
      },
      {
        label: "Rengo",
        value: "RENGO",
      },
      {
        label: "Requínoa",
        value: "REQUÍNOA",
      },
      {
        label: "San Vicente",
        value: "SAN VICENTE",
      },
      {
        label: "Pichilemu",
        value: "PICHILEMU",
      },
      {
        label: "La Estrella",
        value: "LA ESTRELLA",
      },
      {
        label: "Litueche",
        value: "LITUECHE",
      },
      {
        label: "Marchihue",
        value: "MARCHIHUE",
      },
      {
        label: "Navidad",
        value: "NAVIDAD",
      },
      {
        label: "Paredones",
        value: "PAREDONES",
      },
      {
        label: "San Fernando",
        value: "SAN FERNANDO",
      },
      {
        label: "Chépica",
        value: "CHÉPICA",
      },
      {
        label: "Chimbarongo",
        value: "CHIMBARONGO",
      },
      {
        label: "Lolol",
        value: "LOLOL",
      },
      {
        label: "Nancagua",
        value: "NANCAGUA",
      },
      {
        label: "Palmilla",
        value: "PALMILLA",
      },
      {
        label: "Peralillo",
        value: "PERALILLO",
      },
      {
        label: "Placilla",
        value: "PLACILLA",
      },
      {
        label: "Pumanque",
        value: "PUMANQUE",
      },
      {
        label: "Santa Cruz",
        value: "SANTA CRUZ",
      },
    ],
  },
  {
    label: "Región del Maule",
    options: [
      {
        label: "Talca",
        value: "TALCA",
      },
      {
        label: "Constitución",
        value: "CONSTITUCIÓN",
      },
      {
        label: "Curepto",
        value: "CUREPTO",
      },
      {
        label: "Empedrado",
        value: "EMPEDRADO",
      },
      {
        label: "Maule",
        value: "MAULE",
      },
      {
        label: "Pelarco",
        value: "PELARCO",
      },
      {
        label: "Pencahue",
        value: "PENCAHUE",
      },
      {
        label: "Río Claro",
        value: "RÍO CLARO",
      },
      {
        label: "San Clemente",
        value: "SAN CLEMENTE",
      },
      {
        label: "San Rafael",
        value: "SAN RAFAEL",
      },
      {
        label: "Cauquenes",
        value: "CAUQUENES",
      },
      {
        label: "Chanco",
        value: "CHANCO",
      },
      {
        label: "Pelluhue",
        value: "PELLUHUE",
      },
      {
        label: "Curicó",
        value: "CURICÓ",
      },
      {
        label: "Hualañé",
        value: "HUALAÑÉ",
      },
      {
        label: "Licantén",
        value: "LICANTÉN",
      },
      {
        label: "Molina",
        value: "MOLINA",
      },
      {
        label: "Rauco",
        value: "RAUCO",
      },
      {
        label: "Romeral",
        value: "ROMERAL",
      },
      {
        label: "Sagrada Familia",
        value: "SAGRADA FAMILIA",
      },
      {
        label: "Teno",
        value: "TENO",
      },
      {
        label: "Vichuquén",
        value: "VICHUQUÉN",
      },
      {
        label: "Linares",
        value: "LINARES",
      },
      {
        label: "Colbún",
        value: "COLBÚN",
      },
      {
        label: "Longaví",
        value: "LONGAVÍ",
      },
      {
        label: "Parral",
        value: "PARRAL",
      },
      {
        label: "Retiro",
        value: "RETIRO",
      },
      {
        label: "San Javier",
        value: "SAN JAVIER",
      },
      {
        label: "Villa Alegre",
        value: "VILLA ALEGRE",
      },
      {
        label: "Yerbas Buenas",
        value: "YERBAS BUENAS",
      },
    ],
  },
  {
    label: "Región de Ñuble",
    options: [
      {
        label: "Bulnes",
        value: "BULNES",
      },
      {
        label: "Chillán",
        value: "CHILLÁN",
      },
      {
        label: "Chillán Viejo",
        value: "CHILLÁN VIEJO",
      },
      {
        label: "El Carmen",
        value: "EL CARMEN",
      },
      {
        label: "Pemuco",
        value: "PEMUCO",
      },
      {
        label: "Pinto",
        value: "PINTO",
      },
      {
        label: "Quillón",
        value: "QUILLÓN",
      },
      {
        label: "San Ignacio",
        value: "SAN IGNACIO",
      },
      {
        label: "Yungay",
        value: "YUNGAY",
      },
      {
        label: "Quirihue",
        value: "QUIRIHUE",
      },
      {
        label: "Cobquecura",
        value: "COBQUECURA",
      },
      {
        label: "Coelemu",
        value: "COELEMU",
      },
      {
        label: "Ninhue",
        value: "NINHUE",
      },
      {
        label: "Portezuelo",
        value: "PORTEZUELO",
      },
      {
        label: "Ránquil",
        value: "RÁNQUIL",
      },
      {
        label: "Trehuaco",
        value: "TREHUACO",
      },
      {
        label: "San Carlos",
        value: "SAN CARLOS",
      },
      {
        label: "Coihueco",
        value: "COIHUECO",
      },
      {
        label: "Ñiquén",
        value: "ÑIQUÉN",
      },
      {
        label: "San Fabián",
        value: "SAN FABIÁN",
      },
      {
        label: "San Nicolás",
        value: "SAN NICOLÁS",
      },
    ],
  },
  {
    label: "Región del Bíobío",
    options: [
      {
        label: "Concepción",
        value: "CONCEPCIÓN",
      },
      {
        label: "Coronel",
        value: "CORONEL",
      },
      {
        label: "Chiguayante",
        value: "CHIGUAYANTE",
      },
      {
        label: "Florida",
        value: "FLORIDA",
      },
      {
        label: "Hualqui",
        value: "HUALQUI",
      },
      {
        label: "Lota",
        value: "LOTA",
      },
      {
        label: "Penco",
        value: "PENCO",
      },
      {
        label: "San Pedro de La Paz",
        value: "SAN PEDRO DE LA PAZ",
      },
      {
        label: "Santa Juana",
        value: "SANTA JUANA",
      },
      {
        label: "Talcahuano",
        value: "TALCAHUANO",
      },
      {
        label: "Tomé",
        value: "TOMÉ",
      },
      {
        label: "Hualpén",
        value: "HUALPÉN",
      },
      {
        label: "Lebu",
        value: "LEBU",
      },
      {
        label: "Arauco",
        value: "ARAUCO",
      },
      {
        label: "Cañete",
        value: "CAÑETE",
      },
      {
        label: "Contulmo",
        value: "CONTULMO",
      },
      {
        label: "Curanilahue",
        value: "CURANILAHUE",
      },
      {
        label: "Los Álamos",
        value: "LOS ÁLAMOS",
      },
      {
        label: "Tirúa",
        value: "TIRÚA",
      },
      {
        label: "Los Ángeles",
        value: "LOS ÁNGELES",
      },
      {
        label: "Antuco",
        value: "ANTUCO",
      },
      {
        label: "Cabrero",
        value: "CABRERO",
      },
      {
        label: "Laja",
        value: "LAJA",
      },
      {
        label: "Mulchén",
        value: "MULCHÉN",
      },
      {
        label: "Nacimiento",
        value: "NACIMIENTO",
      },
      {
        label: "Negrete",
        value: "NEGRETE",
      },
      {
        label: "Quilaco",
        value: "QUILACO",
      },
      {
        label: "Quilleco",
        value: "QUILLECO",
      },
      {
        label: "San Rosendo",
        value: "SAN ROSENDO",
      },
      {
        label: "Santa Bárbara",
        value: "SANTA BÁRBARA",
      },
      {
        label: "Tucapel",
        value: "TUCAPEL",
      },
      {
        label: "Yumbel",
        value: "YUMBEL",
      },
      {
        label: "Alto Biobío",
        value: "ALTO BIOBÍO",
      },
    ],
  },
  {
    label: "Región de La Araucanía",
    options: [
      {
        label: "Temuco",
        value: "TEMUCO",
      },
      {
        label: "Carahue",
        value: "CARAHUE",
      },
      {
        label: "Cunco",
        value: "CUNCO",
      },
      {
        label: "Curarrehue",
        value: "CURARREHUE",
      },
      {
        label: "Freire",
        value: "FREIRE",
      },
      {
        label: "Galvarino",
        value: "GALVARINO",
      },
      {
        label: "Gorbea",
        value: "GORBEA",
      },
      {
        label: "Lautaro",
        value: "LAUTARO",
      },
      {
        label: "Loncoche",
        value: "LONCOCHE",
      },
      {
        label: "Melipeuco",
        value: "MELIPEUCO",
      },
      {
        label: "Nueva Imperial",
        value: "NUEVA IMPERIAL",
      },
      {
        label: "Padre Las Casas",
        value: "PADRE LAS CASAS",
      },
      {
        label: "Perquenco",
        value: "PERQUENCO",
      },
      {
        label: "Pitrufquén",
        value: "PITRUFQUÉN",
      },
      {
        label: "Pucón",
        value: "PUCÓN",
      },
      {
        label: "Saavedra",
        value: "SAAVEDRA",
      },
      {
        label: "Teodoro Schmidt",
        value: "TEODORO SCHMIDT",
      },
      {
        label: "Toltén",
        value: "TOLTÉN",
      },
      {
        label: "Vilcún",
        value: "VILCÚN",
      },
      {
        label: "Villarrica",
        value: "VILLARRICA",
      },
      {
        label: "Cholchol",
        value: "CHOLCHOL",
      },
      {
        label: "Angol",
        value: "ANGOL",
      },
      {
        label: "Collipulli",
        value: "COLLIPULLI",
      },
      {
        label: "Curacautín",
        value: "CURACAUTÍN",
      },
      {
        label: "Ercilla",
        value: "ERCILLA",
      },
      {
        label: "Lonquimay",
        value: "LONQUIMAY",
      },
      {
        label: "Los Sauces",
        value: "LOS SAUCES",
      },
      {
        label: "Lumaco",
        value: "LUMACO",
      },
      {
        label: "Purén",
        value: "PURÉN",
      },
      {
        label: "Renaico",
        value: "RENAICO",
      },
      {
        label: "Traiguén",
        value: "TRAIGUÉN",
      },
      {
        label: "Victoria",
        value: "VICTORIA",
      },
    ],
  },
  {
    label: "Región de Los Ríos",
    options: [
      {
        label: "Valdivia",
        value: "VALDIVIA",
      },
      {
        label: "Corral",
        value: "CORRAL",
      },
      {
        label: "Futrono",
        value: "FUTRONO",
      },
      {
        label: "La Unión",
        value: "LA UNIÓN",
      },
      {
        label: "Lago Ranco",
        value: "LAGO RANCO",
      },
      {
        label: "Lanco",
        value: "LANCO",
      },
      {
        label: "Los Lagos",
        value: "LOS LAGOS",
      },
      {
        label: "Máfil",
        value: "MÁFIL",
      },
      {
        label: "Mariquina",
        value: "MARIQUINA",
      },
      {
        label: "Paillaco",
        value: "PAILLACO",
      },
      {
        label: "Panguipulli",
        value: "PANGUIPULLI",
      },
      {
        label: "Río Bueno",
        value: "RÍO BUENO",
      },
    ],
  },
  {
    label: "Región de Los Lagos",
    options: [
      {
        label: "Puerto Montt",
        value: "PUERTO MONTT",
      },
      {
        label: "Calbuco",
        value: "CALBUCO",
      },
      {
        label: "Cochamó",
        value: "COCHAMÓ",
      },
      {
        label: "Fresia",
        value: "FRESIA",
      },
      {
        label: "Frutillar",
        value: "FRUTILLAR",
      },
      {
        label: "Los Muermos",
        value: "LOS MUERMOS",
      },
      {
        label: "Llanquihue",
        value: "LLANQUIHUE",
      },
      {
        label: "Maullín",
        value: "MAULLÍN",
      },
      {
        label: "Puerto Varas",
        value: "PUERTO VARAS",
      },
      {
        label: "Castro",
        value: "CASTRO",
      },
      {
        label: "Ancud",
        value: "ANCUD",
      },
      {
        label: "Chonchi",
        value: "CHONCHI",
      },
      {
        label: "Curaco de Vélez",
        value: "CURACO DE VÉLEZ",
      },
      {
        label: "Dalcahue",
        value: "DALCAHUE",
      },
      {
        label: "Puqueldón",
        value: "PUQUELDÓN",
      },
      {
        label: "Queilén",
        value: "QUEILÉN",
      },
      {
        label: "Quellón",
        value: "QUELLÓN",
      },
      {
        label: "Quemchi",
        value: "QUEMCHI",
      },
      {
        label: "Quinchao",
        value: "QUINCHAO",
      },
      {
        label: "Osorno",
        value: "OSORNO",
      },
      {
        label: "Puerto Octay",
        value: "PUERTO OCTAY",
      },
      {
        label: "Purranque",
        value: "PURRANQUE",
      },
      {
        label: "Puyehue",
        value: "PUYEHUE",
      },
      {
        label: "Río Negro",
        value: "RÍO NEGRO",
      },
      {
        label: "San Juan de La Costa",
        value: "SAN JUAN DE LA COSTA",
      },
      {
        label: "San Pablo",
        value: "SAN PABLO",
      },
      {
        label: "Chaitén",
        value: "CHAITÉN",
      },
      {
        label: "Futaleufú",
        value: "FUTALEUFÚ",
      },
      {
        label: "Hualaihué",
        value: "HUALAIHUÉ",
      },
      {
        label: "Palena",
        value: "PALENA",
      },
    ],
  },
  {
    label: "Región de Aysén del Gral. Carlos Ibáñez del Campo",
    options: [
      {
        label: "Coihaique",
        value: "COIHAIQUE",
      },
      {
        label: "Lago Verde",
        value: "LAGO VERDE",
      },
      {
        label: "Aisén",
        value: "AISÉN",
      },
      {
        label: "Cisnes",
        value: "CISNES",
      },
      {
        label: "Guaitecas",
        value: "GUAITECAS",
      },
      {
        label: "Cochrane",
        value: "COCHRANE",
      },
      {
        label: "O'higgins",
        value: "O'HIGGINS",
      },
      {
        label: "Tortel",
        value: "TORTEL",
      },
      {
        label: "Chile Chico",
        value: "CHILE CHICO",
      },
      {
        label: "Río Ibáñez",
        value: "RÍO IBÁÑEZ",
      },
    ],
  },
  {
    label: "Región de Magallanes y de la Antártica Chilena",
    options: [
      {
        label: "Punta Arenas",
        value: "PUNTA ARENAS",
      },
      {
        label: "Laguna Blanca",
        value: "LAGUNA BLANCA",
      },
      {
        label: "Río Verde",
        value: "RÍO VERDE",
      },
      {
        label: "San Gregorio",
        value: "SAN GREGORIO",
      },
      {
        label: "Cabo de Hornos",
        value: "CABO DE HORNOS",
      },
      {
        label: "Antártica",
        value: "ANTÁRTICA",
      },
      {
        label: "Porvenir",
        value: "PORVENIR",
      },
      {
        label: "Primavera",
        value: "PRIMAVERA",
      },
      {
        label: "Timaukel",
        value: "TIMAUKEL",
      },
      {
        label: "Natales",
        value: "NATALES",
      },
      {
        label: "Torres del Paine",
        value: "TORRES DEL PAINE",
      },
    ],
  },
];
