import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import "./imageRadioButton.scss";

const ImageRadioButton = (props) => {
  const {
    image,
    id,
    value,
    name,
    label,
    onClick,
    checked,
    onChange,
    isInvalid,
  } = props;

  return (
    <div
      className={classNames(
        "image-radio-button",
        {
          "is-invalid": isInvalid,
        },
      )}
      onClick={onClick}
      role="presentation"
    >
      <input
        id={id}
        name={name}
        value={value}
        type="radio"
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>
        <div className="image-radio-button__text">
          <div>{label}</div>
        </div>
        {image}
      </label>
    </div>
  );
};

ImageRadioButton.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isInvalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string.isRequired,
};

ImageRadioButton.defaultProps = {
  checked: false,
  isInvalid: false,
  onChange: () => {},
  onClick: null,
};

export default ImageRadioButton;
