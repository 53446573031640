import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setSetupData } from "../../../features/registrationSlice";
import { IconFurther18 } from "../../../icons";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import IconButton from "../../button/iconButton";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../../registrationDialog/registrationDataHelper";

const VirtualDriveSetupModalDriveHeadInfo = ({ next }) => {
  const setupData = useSelector((state) => state.registration.setupData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const site = process.env.GATSBY_SITE;

  let validationSchema;

  validationSchema = useMemo(() => object().shape({
    driveTitle: string()
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    motivationForDrive: string()
      .required(requiredMessage).min(50, intl.formatMessage(
        { id: "form.error.min_length_exact" },
        { value: 50 },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegexTextArea, invalidValueMessage),
  }), []);

  if (site === "in") {
    validationSchema = object().shape({
      driveTitle: string(),
      motivationForDrive: string(),
    });
  }

  return (
    <>
      <Row>
        <Col sm={10}>
          <h3>
            <FormattedMessage id="virtual_drive_setup.drive_head_info_title" />
          </h3>
        </Col>
      </Row>
      <Formik
        initialValues={setupData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setSetupData({
              driveTitle: values.driveTitle,
              motivationForDrive: values.motivationForDrive,
            }),
          );

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormField
              onChange={handleChange}
              onBlur={handleBlur}
              id="driveTitle"
              label={intl.formatMessage({ id: "virtual_drive_setup.title_of_your_drive" })}
              type="text"
              maxLength={40}
              touched={touched}
              errors={errors}
              values={values}
            />

            <FormField
              onChange={handleChange}
              onBlur={handleBlur}
              id="motivationForDrive"
              label={intl.formatMessage({ id: "virtual_drive_setup.motivation_for_drive" })}
              type="text"
              as="textarea"
              rows={10}
              touched={touched}
              errors={errors}
              values={values}
              required={site !== "in"}
            />

            <ScrollToFieldError />

            <Form.Group className="form-group-button">
              <IconButton type="submit" disabled={isSubmitting}>
                {site === "in" && values.motivationForDrive === ""
                  ? (
                    <FormattedMessage id="virtual_drive.skip" />
                  )
                  : (
                    <FormattedMessage id="virtual_drive_setup.next" />
                  )}
                <IconFurther18 />
              </IconButton>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

VirtualDriveSetupModalDriveHeadInfo.stepName = "virtual_drive_head_info";

VirtualDriveSetupModalDriveHeadInfo.propTypes = {
  next: PropTypes.func.isRequired,
};

export default VirtualDriveSetupModalDriveHeadInfo;
