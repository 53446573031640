import React from "react";

import PropTypes from "prop-types";

import "./blurredOverlay.scss";

const BlurredOverlay = (props) => {
  const { image } = props;

  return (
    <div className="blurred-overlay">
      {image}
    </div>
  );
};

BlurredOverlay.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default BlurredOverlay;
