import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { IconCalendar18 } from "../../icons";
import LocationCard from "../locationCard/locationCard";
import NavigationLink from "../navigationLink/navigationLink";

import "./infoWindow.scss";

const InfoWindow = ({ entries, variant }) => (
  <div className={classNames(
    "info-window",
    {
      [`marker--${variant}`]: variant,
    },
  )}
  >
    {variant === "location"
      ? entries.map((entry) => (
        <LocationCard
          key={entry.id}
          city={`${entry.postalCode} ${entry.city}`}
          streetAddress={entry.address}
          title={entry.title}
          text={entry.description}
          externalLink={entry.external}
          internalLink={entry.internal}
          internalLinkText={entry.internalText}
          phone={entry.phone}
          fax={entry.fax}
          compact
        />
      ))
      : entries.map((entry) => (
        <NavigationLink key={entry.id} href={entry.id} className="no-style">
          <div className="info-window__entry">
            <div className="info-window__entry-image">
              {entry.image}
            </div>
            <div className="info-window__entry-body">
              <div className="typo-label">
                {entry.title}
              </div>
              <div className="info-window__entry-date">
                <IconCalendar18 />
                {entry.date}
              </div>
            </div>
          </div>
        </NavigationLink>
      ))}
  </div>
);

InfoWindow.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  variant: PropTypes.oneOf(["event", "location"]),
};

InfoWindow.defaultProps = {
  variant: "event",
};

export default InfoWindow;
