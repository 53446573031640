import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Banner from "./banner";
import MissionStatementFact from "./missionStatementFact";
import { IconFurther24 } from "../../icons";
import LinkButton from "../button/linkButton";

import "./missionStatementBanner.scss";

const MissionStatementBanner = (props) => {
  const {
    title,
    cardDescription,
    linkUrl,
    iconLeft,
    iconRight,
    numberLeft,
    numberRight,
    descriptionLeft,
    descriptionRight,
    media,
  } = props;

  return (
    <Banner
      className="mission-statement-banner"
      mediaObject={media}
      mediaObjectTop={false}
    >
      <div className="mission-statement-banner__inner">
        <Row>
          <Col md={7} lg={6}>
            <div className="typo-h2">{title}</div>
            <LinkButton href={linkUrl}>
              {cardDescription}
              <IconFurther24 />
            </LinkButton>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={8} xl={4}>
            <MissionStatementFact
              icon={iconLeft}
              number={numberLeft}
              description={descriptionLeft}
            />
          </Col>
          <Col sm={6} md={8} xl={4}>
            <MissionStatementFact
              icon={iconRight}
              number={numberRight}
              description={descriptionRight}
            />
          </Col>
        </Row>
      </div>
    </Banner>
  );
};

MissionStatementBanner.propTypes = {
  cardDescription: PropTypes.string.isRequired,
  descriptionLeft: PropTypes.string.isRequired,
  descriptionRight: PropTypes.string.isRequired,
  iconLeft: PropTypes.oneOfType([PropTypes.object]).isRequired,
  iconRight: PropTypes.oneOfType([PropTypes.object]).isRequired,
  linkUrl: PropTypes.string.isRequired,
  media: PropTypes.oneOfType([PropTypes.object]).isRequired,
  numberLeft: PropTypes.number.isRequired,
  numberRight: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default MissionStatementBanner;
