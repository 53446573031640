import {
  RegistrationDialogStepEligibilityAge,
  RegistrationDialogStepEligibilityBmi,
  RegistrationDialogStepEligibilityBmiDe,
  RegistrationDialogStepEligibilityBmiIn,
  RegistrationDialogStepEligibilityBmiMetric,
  RegistrationDialogStepEligibilityBmiUs,
  RegistrationDialogStepEligibilityBmiZa,
  RegistrationDialogStepEligibilityCountry,
  RegistrationDialogStepEligibilityHealth,
  RegistrationDialogStepEligibilityRegistered,
  RegistrationDialogStepEligibilityRegisteredUs,
  RegistrationDialogStepOverview,
  RegistrationDialogStepOverviewCl,
  RegistrationDialogStepOverviewDe,
  RegistrationDialogStepOverviewIn,
  RegistrationDialogStepOverviewPl,
  RegistrationDialogStepOverviewUs,
  RegistrationDialogStepOverviewZa,
  RegistrationDialogStepRegistrationAdditionalContactZa,
  RegistrationDialogStepRegistrationAdditionalInformation,
  RegistrationDialogStepRegistrationAdditionalInformationCl,
  RegistrationDialogStepRegistrationAdditionalInformationDe,
  RegistrationDialogStepRegistrationAdditionalInformationPl,
  RegistrationDialogStepRegistrationAdditionalInformationUs,
  RegistrationDialogStepRegistrationAdditionalInformationZa,
  RegistrationDialogStepRegistrationAlternateContactIn,
  RegistrationDialogStepRegistrationAlternateContactUs,
  RegistrationDialogStepRegistrationAncestryUs,
  RegistrationDialogStepRegistrationEthnicity,
  RegistrationDialogStepRegistrationEthnicityCl,
  RegistrationDialogStepRegistrationEthnicityDe,
  RegistrationDialogStepRegistrationEthnicityIn,
  RegistrationDialogStepRegistrationEthnicityZa,
  RegistrationDialogStepRegistrationGeneralPractitioner,
  RegistrationDialogStepRegistrationHowDidYouHear,
  RegistrationDialogStepRegistrationHowDidYouHearCl,
  RegistrationDialogStepRegistrationHowDidYouHearDe,
  RegistrationDialogStepRegistrationHowDidYouHearIn,
  RegistrationDialogStepRegistrationHowDidYouHearPl,
  RegistrationDialogStepRegistrationHowDidYouHearUs,
  RegistrationDialogStepRegistrationHowDidYouHearZa,
  RegistrationDialogStepRegistrationKeepInTouch,
  RegistrationDialogStepRegistrationPersonalInformation,
  RegistrationDialogStepRegistrationPersonalInformationCl,
  RegistrationDialogStepRegistrationPersonalInformationDe,
  RegistrationDialogStepRegistrationPersonalInformationIn,
  RegistrationDialogStepRegistrationPersonalInformationPl,
  RegistrationDialogStepRegistrationPersonalInformationUs,
  RegistrationDialogStepRegistrationPersonalInformationZa,
  RegistrationDialogStepRegistrationRelationshipDe,
  RegistrationDialogStepRegistrationResidentialAddress,
  RegistrationDialogStepRegistrationResidentialAddressCl,
  RegistrationDialogStepRegistrationResidentialAddressDe,
  RegistrationDialogStepRegistrationResidentialAddressIn,
  RegistrationDialogStepRegistrationResidentialAddressPl,
  RegistrationDialogStepRegistrationResidentialAddressUs,
  RegistrationDialogStepRegistrationResidentialAddressZa,
  RegistrationDialogStepSuccess,
  RegistrationDialogStepSuccessCl,
  RegistrationDialogStepSuccessDe,
  RegistrationDialogStepSuccessPl,
} from "./steps";
import DataLayer from "../../utils/dataLayer";

const getRegistrationSteps = (rootsId = null) => {
  let site = process.env.GATSBY_SITE;
  let steps = [];

  const ukOptInEnable = process.env.GATSBY_ENABLE_UK_OPT_IN && process.env.GATSBY_ENABLE_UK_OPT_IN.toLowerCase() === "true";

  if (rootsId !== null && ["de"].includes(site)) {
    DataLayer.pushEvent("ppe_registration_roots");
    site += "_roots";
  }

  switch (site) {
    case "gb":
      steps = [
        RegistrationDialogStepEligibilityCountry,
        RegistrationDialogStepEligibilityRegistered,
        RegistrationDialogStepEligibilityAge,
        RegistrationDialogStepEligibilityHealth,
        RegistrationDialogStepEligibilityBmi,
        RegistrationDialogStepRegistrationPersonalInformation,
        RegistrationDialogStepRegistrationResidentialAddress,
        RegistrationDialogStepRegistrationAdditionalInformation,
        RegistrationDialogStepRegistrationEthnicity,
        RegistrationDialogStepRegistrationGeneralPractitioner,
        RegistrationDialogStepRegistrationHowDidYouHear,
        RegistrationDialogStepOverview,
        RegistrationDialogStepSuccess,
      ];

      // todo: Change if live
      // Between RegistrationDialogStepRegistrationHowDidYouHear and RegistrationDialogStepOverview
      if (ukOptInEnable) {
        steps.splice(11, 0, RegistrationDialogStepRegistrationKeepInTouch);
      }
      break;
    case "us":
      steps = [
        RegistrationDialogStepEligibilityCountry,
        RegistrationDialogStepEligibilityAge,
        RegistrationDialogStepEligibilityRegisteredUs,
        RegistrationDialogStepEligibilityHealth,
        RegistrationDialogStepEligibilityBmiUs,
        RegistrationDialogStepRegistrationPersonalInformationUs,
        RegistrationDialogStepRegistrationResidentialAddressUs,
        RegistrationDialogStepRegistrationAdditionalInformationUs,
        RegistrationDialogStepRegistrationAncestryUs,
        RegistrationDialogStepRegistrationAlternateContactUs,
        RegistrationDialogStepRegistrationHowDidYouHearUs,
        RegistrationDialogStepOverviewUs,
        RegistrationDialogStepSuccess,
      ];
      break;
    case "in":
      steps = [
        RegistrationDialogStepEligibilityRegistered,
        RegistrationDialogStepEligibilityHealth,
        RegistrationDialogStepEligibilityBmiIn,
        RegistrationDialogStepEligibilityAge,
        RegistrationDialogStepRegistrationPersonalInformationIn,
        RegistrationDialogStepRegistrationResidentialAddressIn,
        RegistrationDialogStepRegistrationEthnicityIn,
        RegistrationDialogStepRegistrationAlternateContactIn,
        RegistrationDialogStepRegistrationHowDidYouHearIn,
        RegistrationDialogStepOverviewIn,
        RegistrationDialogStepSuccess,
      ];
      break;
    case "pl":
      steps = [
        RegistrationDialogStepEligibilityCountry,
        RegistrationDialogStepEligibilityRegistered,
        RegistrationDialogStepEligibilityAge,
        RegistrationDialogStepEligibilityHealth,
        RegistrationDialogStepEligibilityBmiMetric,
        RegistrationDialogStepRegistrationPersonalInformationPl,
        RegistrationDialogStepRegistrationResidentialAddressPl,
        RegistrationDialogStepRegistrationAdditionalInformationPl,
        RegistrationDialogStepRegistrationHowDidYouHearPl,
        RegistrationDialogStepOverviewPl,
        RegistrationDialogStepSuccessPl,
      ];
      break;
    case "cl":
      steps = [
        RegistrationDialogStepEligibilityCountry,
        RegistrationDialogStepEligibilityRegistered,
        RegistrationDialogStepEligibilityAge,
        RegistrationDialogStepEligibilityHealth,
        RegistrationDialogStepEligibilityBmiMetric,
        RegistrationDialogStepRegistrationPersonalInformationCl,
        RegistrationDialogStepRegistrationEthnicityCl,
        RegistrationDialogStepRegistrationResidentialAddressCl,
        RegistrationDialogStepRegistrationAdditionalInformationCl,
        RegistrationDialogStepRegistrationHowDidYouHearCl,
        RegistrationDialogStepOverviewCl,
        RegistrationDialogStepSuccessCl,
      ];
      break;
    case "de":
      steps = [
        RegistrationDialogStepEligibilityCountry,
        RegistrationDialogStepEligibilityRegistered,
        RegistrationDialogStepEligibilityAge,
        RegistrationDialogStepEligibilityHealth,
        RegistrationDialogStepEligibilityBmiDe,
        RegistrationDialogStepRegistrationPersonalInformationDe,
        RegistrationDialogStepRegistrationResidentialAddressDe,
        RegistrationDialogStepRegistrationAdditionalInformationDe,
        RegistrationDialogStepRegistrationEthnicityDe,
        RegistrationDialogStepRegistrationHowDidYouHearDe,
        RegistrationDialogStepOverviewDe,
        RegistrationDialogStepSuccessDe,
      ];
      break;
    case "de_roots":
      steps = [
        RegistrationDialogStepEligibilityCountry,
        RegistrationDialogStepEligibilityRegistered,
        RegistrationDialogStepEligibilityAge,
        RegistrationDialogStepEligibilityHealth,
        RegistrationDialogStepEligibilityBmiDe,
        RegistrationDialogStepRegistrationPersonalInformationDe,
        RegistrationDialogStepRegistrationResidentialAddressDe,
        RegistrationDialogStepRegistrationAdditionalInformationDe,
        RegistrationDialogStepRegistrationRelationshipDe,
        RegistrationDialogStepRegistrationEthnicityDe,
        RegistrationDialogStepRegistrationHowDidYouHearDe,
        RegistrationDialogStepOverviewDe,
        RegistrationDialogStepSuccessDe,
      ];
      break;
    case "za":
      steps = [
        RegistrationDialogStepEligibilityCountry,
        RegistrationDialogStepEligibilityRegistered,
        RegistrationDialogStepEligibilityAge,
        RegistrationDialogStepEligibilityHealth,
        RegistrationDialogStepEligibilityBmiZa,
        RegistrationDialogStepRegistrationPersonalInformationZa,
        RegistrationDialogStepRegistrationResidentialAddressZa,
        RegistrationDialogStepRegistrationAdditionalInformationZa,
        RegistrationDialogStepRegistrationEthnicityZa,
        RegistrationDialogStepRegistrationAdditionalContactZa,
        RegistrationDialogStepRegistrationHowDidYouHearZa,
        RegistrationDialogStepOverviewZa,
        RegistrationDialogStepSuccess,
      ];
      break;
    default:
  }

  return steps;
};

export default getRegistrationSteps;
