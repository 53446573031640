import React, { useState } from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { setError, setRegistrationData } from "../../../features/registrationSlice";
import HealthCheckModalView from "../../../templates/register/healthCheckModalView";
import DataLayer from "../../../utils/dataLayer";
import Button from "../../button/button";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepEligibilityHealth = ({ content, next }) => {
  const error = useSelector((state) => state.registration.error);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = (eligible) => {
    setIsSubmitting(true);

    dispatch(
      setRegistrationData({ eligible }),
    );

    if (!eligible) {
      setIsSubmitting(false);
      dispatch(
        setError(true),
      );

      DataLayer.pushEvent("pp_registration_step_eligibility_health", { event_value: "fail" });
    } else {
      DataLayer.pushEvent("pp_registration_step_eligibility_health", { event_value: "success" });

      next();
    }
  };

  if (error) {
    return (
      <>
        <RegistrationDialogHeadline
          headline="registration.health.error.title"
        />

        <Row>
          <Col sm={10}>
            {intl.formatMessage({ defaultMessage: " ", id: "registration.health.error.text1" }) !== " " && (
              <p>
                <FormattedMessage id="registration.health.error.text1" />
              </p>
            )}

            <p>
              <FormattedMessage id="registration.health.error.text2" />
            </p>

            {content.donationBox}

            {!content.donationBox
              && content.errorButtonPrimary}

            {content.errorButtonSecondary}
          </Col>
        </Row>
      </>
    );
  }

  const contact = {
    gb: <a href="mailto:info@dkms.org.uk" title="Contact us" target="_blank" rel="noreferrer">info@dkms.org.uk</a>,
  };

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.health.title"
        subheadline="registration.health.text"
        lessSpacing
      />
      <Row>
        <Col sm={10}>
          <div className="registration-dialog__conditions">
            {intl.formatMessage(
              { id: "registration.health.conditions" },
              {
                contact: contact[process.env.GATSBY_SITE],
                link: <HealthCheckModalView />,
              },
            )}
          </div>
          {intl.formatMessage({ defaultMessage: " ", id: "registration.health.subtitle" }) !== " " && (
            <h4 className="mb-5">
              <FormattedMessage id="registration.health.subtitle" />
            </h4>
          )}
        </Col>
        <Col xs={12}>
          <Form.Group className="form-group-button">
            <Button
              onClick={() => {
                submit(true);
              }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="registration.health.button_yes" />
            </Button>
          </Form.Group>
          <Form.Group className="form-group-button">
            <Button
              onClick={() => {
                submit(false);
              }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="registration.health.button_no" />
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

RegistrationDialogStepEligibilityHealth.stepName = "eligibility_health";

RegistrationDialogStepEligibilityHealth.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepEligibilityHealth;
