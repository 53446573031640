import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FaqAccordion from "../faqAccordion/faqAccordion";

import "./commonQuestions.scss";

const CommonQuestions = ({ headline, accordionItems }) => (
  <div className="common-questions text-section__fullwidth">
    <Row>
      <Col sm={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
        <h3>{headline}</h3>
        <FaqAccordion accordionItems={accordionItems} titleAs="h4" />
      </Col>
    </Row>
  </div>
);

CommonQuestions.propTypes = {
  accordionItems: PropTypes.oneOfType([PropTypes.array]).isRequired,
  headline: PropTypes.string.isRequired,
};

export default CommonQuestions;
