import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import DonationBoxEyecatcher from "./moneyDonation/donationBoxEyecatcher";
import UnifyEyecatcher from "../components/unifyEyecatcher/unifyEyecatcher";

const KontentItemEyecatcherDonate = ({ data }) => {
  const eyecatcher = data.value ? data.value[0] : data;

  if (!eyecatcher || !eyecatcher.elements) {
    return null;
  }

  return (
    <UnifyEyecatcher
      title={eyecatcher.elements.title.value}
      color="secondary"
      variant="dualtone"
      className="eyecatcher--donate"
    >
      <DonationBoxEyecatcher
        className="p-0"
        buttonText={eyecatcher.elements.button_text.value}
        buttonURL={eyecatcher.elements.button_url_ext.value}
      />
    </UnifyEyecatcher>
  );
};

export const query = graphql`
  fragment KontentItemEyecatcherDonate on kontent_item_eyecatcher_donate {
    id
    system {
      codename
    }
    elements {
      title {
        value
      }
      form_label {
        value
      }
      form_default_value {
        value
      }
      button_text {
        value
      }
      button_url_ext {
        value
      }
    }
  }
`;

KontentItemEyecatcherDonate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEyecatcherDonate;
