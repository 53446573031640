import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import ArticleClosure from "../../components/articleClosure/articleClosure";
import ArticleHeader from "../../components/articleHeader/articleHeader";
import SEO from "../../components/seo";
import mapKontentItem from "../../utils/mapKontentItem";
import backgroundImageHelper from "../image/backgroundImageHelper";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemArticleMigration = ({ data }) => (
  <MainLayout pageInfo={{ pageName: data.page.elements.headline.value }}>

    <SEO
      title={data.page.elements.metadata__meta_title.value}
      description={data.page.elements.metadata__meta_description.value}
      twitterTitle={data.page.elements.metadata__twitter_title.value}
      twitterSite={data.page.elements.metadata__twitter_site.value}
      twitterImage={data.page.elements.metadata__twitter_image.value}
      twitterDescription={data.page.elements.metadata__twitter_description.value}
      twitterCreator={data.page.elements.metadata__twitter_creator.value}
      ogTitle={data.page.elements.metadata__og_title.value}
      ogImage={data.page.elements.metadata__og_image.value}
      ogDescription={data.page.elements.metadata__og_description.value}
    />

    <SubNavigationGenerator id={data.page.id} />

    <BreadcrumbGenerator id={data.page.id} />

    <ArticleHeader
      title={data.page.elements.headline.value}
      description={data.page.elements.lead_text.value}
      date={new Date(data.page.system.last_modified)}
      backgroundImageStyle={backgroundImageHelper(data.page.elements.hero_image)}
    />

    <RichText data={data.page.elements.body} isArticle />

    <Row>
      <Col md={{ offset: 1, span: 10 }} lg={{ offset: 2, span: 8 }}>
        <ArticleClosure />
      </Col>
    </Row>

    {data.page.elements.page_sections.value.map((item) => mapKontentItem(item))}

  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    page: kontentItemArticleMigration(id: {eq: $id}) {
      id
      system {
        last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
      }
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        hero_image {
          value {
            ...KontentItemImage
          }
        }
        lead_text {
          value
        }
        page_sections {
          value {
            internal {
              type
            }
            ...KontentItemRegistryBanner
            ...KontentItemIconCardSliderSection
            ...KontentItemRelatedNewsSection
          }
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            internal {
              type
            }
            system {
              codename
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemEmbedExternal
          }
        }
      }
    }
  }
`;

KontentItemArticleMigration.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemArticleMigration;
