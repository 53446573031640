import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentItemImage from "./kontentItemImage";
import ContactIconButton from "../components/button/contactIconButton";
import ImageEyecatcher from "../components/imageEyecatcher/imageEyecatcher";

const KontentItemEyecatcherImage = ({ data }) => {
  const eyecatcherImage = data && data.value ? data.value[0] : data;

  if (!eyecatcherImage || !eyecatcherImage.elements) {
    return null;
  }

  return (
    <ImageEyecatcher
      headline={eyecatcherImage.elements.headline.value}
      image={eyecatcherImage.elements.image.value.length > 0
        ? <KontentItemImage data={eyecatcherImage.elements.image} aspectRatio={4 / 3} />
        : null}
      text={eyecatcherImage.elements.text.value}
      button={(
        <ContactIconButton
          href={
            eyecatcherImage.elements.button_url.value.length > 0
              ? eyecatcherImage.elements.button_url.value[0].id
              : eyecatcherImage.elements.button_url_extern.value
          }
          mailto={eyecatcherImage.elements.button_mailto.value}
          contactName={eyecatcherImage.elements.button_text.value}
          variant="inverted-registration"
        >
          {eyecatcherImage.elements.button_text.value}
        </ContactIconButton>
      )}
    />
  );
};

export const query = graphql`
  fragment KontentItemEyecatcherImage on kontent_item_eyecatcher_image {
    id
    system {
      codename
    }
    elements {
      button_text {
        value
      }
      button_url {
        value {
          id
        }
      }
      button_url_extern {
        value
      }
      button_mailto {
        value
      }
      headline {
        value
      }
      text {
        value
      }
      image {
        value {
          ...KontentItemImage
        }
      }
    }
  }
`;

KontentItemEyecatcherImage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemEyecatcherImage;
