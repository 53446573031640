import React, { useMemo } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconError18, IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationPersonalInformationIn = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });
  const emailMessage = intl.formatMessage({ id: "registration.additional.error.email" });

  const validationSchema = useMemo(() => object().shape({
    emailAddress: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage),
    firstname: string()
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 40 },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    gender: string()
      .required(requiredMessage)
      .test("test-gender", requiredMessage,
        (value) => RegistrationDataHelper.titleGenderMappingIn[value] !== undefined),
    lastname: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        { value: 100 },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    mobileNumber: string()
      .required(requiredMessage)
      .matches(
        /^[\d]{10}$/,
        "Please ensure you have entered a valid (ten digit) mobile number",
      ),
    whatsApp: string()
      .required(requiredMessage),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.personal.title"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              emailAddress: values.emailAddress,
              firstname: values.firstname,
              gender: values.gender,
              lastname: values.lastname,
              mobileNumber: values.mobileNumber,
              motherTongue: values.motherTongue,
              salutation: values.salutation,
              whatsApp: values.whatsApp,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_personal_information", { event_value: "success" });
          DataLayer.pushEvent("pp_registration_step_personal_information_value",
            {
              dimension_reg_sex: RegistrationDataHelper.titleGenderMappingIn[values.gender],
              event_value: RegistrationDataHelper.titleGenderMappingIn[values.gender],
            });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className="required-sign typo-label">
                  <FormattedMessage id="registration.personal.gender.title" />
                </div>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="Mr."
                    name="gender"
                    value="GENDER-MR"
                    id="gender-mr"
                    checked={values.gender === "GENDER-MR"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0", errors.gender && touched.gender ? "is-invalid" : "")}
                  />
                  <Form.Check
                    type="radio"
                    label="Mrs."
                    name="gender"
                    value="GENDER-MRS"
                    id="gender-mrs"
                    checked={values.gender === "GENDER-MRS"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0", errors.gender && touched.gender ? "is-invalid" : "")}
                  />
                  <Form.Check
                    type="radio"
                    label="Ms."
                    name="gender"
                    value="GENDER-MS"
                    id="gender-ms"
                    checked={values.gender === "GENDER-MS"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0", errors.gender && touched.gender ? "is-invalid" : "")}
                  />
                  {errors.gender && touched.gender && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.gender}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.personal.salutation" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("salutation", target.value);
                      setFieldError("salutation", undefined);
                    } else {
                      setFieldValue("salutation", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("salutation", true);
                  }}
                  touched={touched}
                  id="salutation"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.titleOptionsIn}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={(event) => {
                    setFieldTouched("gender", true);
                    handleChange(event);
                  }}
                  onBlur={(event) => {
                    setFieldTouched("gender", true);
                    handleBlur(event);
                  }}
                  id="firstname"
                  label={intl.formatMessage({ id: "registration.personal.firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="lastname"
                  label={intl.formatMessage({ id: "registration.personal.lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.ethnicity.mother_tongue" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("motherTongue", target.value);
                      setFieldError("motherTongue", undefined);
                    } else {
                      setFieldValue("motherTongue", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("motherTongue", true);
                  }}
                  touched={touched}
                  id="motherTongue"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.motherTongueOptions}
                />
              </Col>

              <Col xs={12}>
                <p className="text-black required-sign">
                  <FormattedMessage id="registration.additional.whatsapp" />
                </p>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.additional.whatsapp_yes" })}
                    name="whatsApp"
                    value="yes"
                    id="whatsapp-yes"
                    checked={values.whatsApp === "yes"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0", errors.whatsApp && touched.whatsApp ? "is-invalid" : "")}
                  />
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "registration.additional.whatsapp_no" })}
                    name="whatsApp"
                    value="no"
                    id="whatsapp-no"
                    checked={values.whatsApp === "no"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0", errors.whatsApp && touched.whatsApp ? "is-invalid" : "")}
                  />
                  {errors.whatsApp && touched.whatsApp && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.whatsApp}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="required">
                  <Form.Control
                    type="text"
                    name="mobileNumber"
                    value={values.mobileNumber}
                    onChange={(event) => {
                      setFieldTouched("whatsApp", true);
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      setFieldTouched("whatsApp", true);
                      handleBlur(event);
                      const { name } = event.target;
                      setFieldValue(name, values?.[name]?.trim() || "");
                    }}
                    isInvalid={(errors.mobileNumber && touched.mobileNumber)}
                    maxLength={10}
                  />
                  <Form.Label>
                    <FormattedMessage id="registration.additional.mobile_number" />
                  </Form.Label>
                  {errors.mobileNumber && touched.mobileNumber && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.mobileNumber}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="required">
                  <Form.Control
                    type="email"
                    name="emailAddress"
                    value={values.emailAddress}
                    onChange={handleChange}
                    onBlur={(event) => {
                      handleBlur(event);
                      const { name } = event.target;
                      setFieldValue(name, values?.[name]?.trim() || "");
                    }}
                    isInvalid={(errors.emailAddress && touched.emailAddress)}
                    maxLength={100}
                  />
                  <Form.Label>
                    <FormattedMessage id="registration.additional.email" />
                  </Form.Label>
                  {errors.emailAddress && touched.emailAddress && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.emailAddress}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationPersonalInformationIn.stepName = "registration_personal_information";

RegistrationDialogStepRegistrationPersonalInformationIn.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationPersonalInformationIn;
