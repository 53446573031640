import React from "react";

import { camelCase } from "./stringUtilHelper";
import Templates from "../templates/templates";

const mapKontentItem = (item, options) => {
  let output = ("");
  let keyPrefix = null;

  if (item?.internal?.type) {
    const type = camelCase(item.internal.type);
    const TagName = Templates[`${type.charAt(0).toUpperCase() + type.slice(1)}`];
    let extra = null;

    if (!TagName || !item.elements) return (<div className="alert alert-warning">## missing element ##</div>);

    switch (item.internal.type) {
      case "kontent_item_image_grid":
        extra = {
          enableCaption: true,
        };
        break;
      case "kontent_item_image":
        extra = {
          enableCaption: true,
          enableComponentStyle: true,
        };
        break;
      default:
        extra = null;
    }

    if (options && options.keyPrefix) {
      keyPrefix = options.keyPrefix;
    }

    try {
      output = (<TagName data={item} {...extra} key={keyPrefix + item.id} {...options} />);
    } catch (e) {
      output = ("Error");
    }
  }

  return output;
};

export default mapKontentItem;
