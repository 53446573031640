/* eslint-disable sort-keys */
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { sex, STATES_IN, whatsappOptions } from "./general-form-data";
import { format } from "../../../utils/dateHelper";
import { getOnPreventKeypress, RegexPatterns } from "../../dynamicForm/validators";
import { clean } from "../formBuilder";
import Validators from "../validations/validators";

const getUpdateAddressForm = (intl) => {
  const validators = new Validators(intl);
  const REQUIRED_ERROR = intl.formatMessage({ id: "form.required" });
  const SEX_OPTIONS = sex(intl);
  const WHATSAPP_OPTIONS = whatsappOptions(intl);

  const detailsView = useSelector((state) => state.updateAddress.detailsView);

  const sameEmailtest = {
    type: "test",
    params: [{
      test() {
        const { email, email2 } = this.parent;

        if (!email2) {
          return true;
        }

        return email && email !== email2;
      },
      message: intl.formatMessage({ id: "registration.additional.error.same_email" }),
    }],
  };

  const samePhoneTest = {
    type: "test",
    params: [{
      name: "samePhoneTest",
      test() {
        const { mobile, landline } = this.parent;

        return !((landline && landline !== "+") && mobile === landline);
      },
      message: intl.formatMessage({ id: "registration.alternate_contact.error.same_phone" }),
    }],
  };

  return [
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "personal_info_header",
        headline: "personal_info.title",
        description: "personal_info.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "donor_id",
        name: "donor_id",
        label: intl.formatMessage({ id: "donor_id" }),
        type: "text",
        maxLength: 20,
        required: false,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("donor_id", false),
        ],
      },
      tooltipMessage: intl.formatMessage({ id: "tooltip.donor_id" }),
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "DateInput",
      props: {
        id: "birthdate",
        name: "birthdate",
        label: intl.formatMessage({ id: "registration.age.birthdate" }),
        type: "date",
        required: true,
        placeholder: "",
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInPast", true),
        ],
      },
      initialValue: "",
      tooltipMessage: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "4",
        singleLine: true,
      },
      tagName: "RadioInput",
      props: {
        id: "sex",
        name: "sex",
        label: intl.formatMessage({ id: "gender" }),
        type: "text",
        title: intl.formatMessage({ id: "form.please_select.label" }),
        isRequired: true,
      },
      options: SEX_OPTIONS,
      validation: {
        type: "string",
        validators: [
          ...validators.get("gender", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "firstname",
        name: "firstname",
        label: intl.formatMessage({ id: "registration.personal.firstname" }),
        type: "text",
        maxLength: 40,
        required: true,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("firstname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "lastname",
        name: "lastname",
        label: intl.formatMessage({ id: "registration.personal.lastname" }),
        type: "text",
        maxLength: 100,
        required: true,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "former_lastname",
        name: "former_lastname",
        label: intl.formatMessage({ id: "personal_info.former_last_name" }),
        type: "text",
        maxLength: 100,
        required: false,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "previous_address_header",
        headline: "previous_address.title",
        description: "",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
        singleLine: false,
      },
      tagName: "CheckboxInput",
      props: {
        id: "address_unchanged",
        name: "address_unchanged",
        label: intl.formatMessage({ id: "address_unchanged" }),
        type: "checkbox",
        lg: true,
      },
      validation: {
        type: "bool",
        validators: [],
      },
      initialValue: false,
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "FormAddressInput",
      props: {
        id: "prev_address_input",
        name: "prev_address_input",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        fieldType: "prev",
        subFields: [
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: true,
            },
            tagName: "TextInput",
            props: {
              id: "prev_co",
              name: "prev_co",
              label: intl.formatMessage({ id: "co" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("apartment", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_apartment",
              name: "prev_apartment",
              label: intl.formatMessage({ id: "registration.address.apartment" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("apartment", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_house_name",
              name: "prev_house_name",
              label: intl.formatMessage({ id: "registration.address.houseName" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("building", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_house_no",
              name: "prev_house_no",
              label: intl.formatMessage({ id: "registration.address.houseNo" }),
              type: "text",
              maxLength: 10,
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("houseno", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_street",
              name: "prev_street",
              label: intl.formatMessage({ id: "registration.address.street" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("street", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_locality",
              name: "prev_locality",
              label: intl.formatMessage({ id: "locality" }),
              type: "text",
              maxLength: 100,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_city",
              name: "prev_city",
              label: intl.formatMessage({ id: "registration.address.city" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("city", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_postcode",
              name: "prev_postcode",
              label: intl.formatMessage({ id: "registration.address.postCode" }),
              type: "text",
              maxLength: 6,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("zipcode", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "prev_district",
              name: "prev_district",
              label: intl.formatMessage({ id: "district" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("apartment", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: true,
            },
            tagName: "SelectInput",
            props: {
              id: "prev_state",
              name: "prev_state",
              label: intl.formatMessage({ id: "state" }),
              maxLength: 100,
              required: true,
            },
            options: STATES_IN,
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
              ],
            },
            initialValue: "",
          },
        ],
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "current_address_header",
        headline: "current_address.title",
        description: "current_address.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "FormAddressInput",
      props: {
        id: "current_address_input",
        name: "current_address_input",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        fieldType: "current",
        subFields: [
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: true,
            },
            tagName: "TextInput",
            props: {
              id: "current_co",
              name: "current_co",
              label: intl.formatMessage({ id: "co" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("apartment", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_apartment",
              name: "current_apartment",
              label: intl.formatMessage({ id: "registration.address.apartment" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("apartment", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_house_name",
              name: "current_house_name",
              label: intl.formatMessage({ id: "registration.address.houseName" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("building", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_house_no",
              name: "current_house_no",
              label: intl.formatMessage({ id: "registration.address.houseNo" }),
              type: "text",
              maxLength: 10,
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("houseno", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_street",
              name: "current_street",
              label: intl.formatMessage({ id: "registration.address.street" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("street", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_locality",
              name: "current_locality",
              label: intl.formatMessage({ id: "locality" }),
              type: "text",
              maxLength: 100,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("region", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_city",
              name: "current_city",
              label: intl.formatMessage({ id: "registration.address.city" }),
              type: "text",
              maxLength: 50,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("city", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_postcode",
              name: "current_postcode",
              label: intl.formatMessage({ id: "registration.address.postCode" }),
              type: "text",
              maxLength: 6,
              required: true,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("zipcode", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: false,
            },
            tagName: "TextInput",
            props: {
              id: "current_district",
              name: "current_district",
              label: intl.formatMessage({ id: "district" }),
              type: "text",
              maxLength: 100,
              required: false,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("apartment", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
              singleLine: true,
            },
            tagName: "SelectInput",
            props: {
              id: "current_state",
              name: "current_state",
              label: intl.formatMessage({ id: "state" }),
              maxLength: 100,
              required: true,
            },
            options: STATES_IN,
            validation: {
              type: "string",
              validators: [
                ...validators.get("state", true),
              ],
            },
            initialValue: "",
          },
        ],
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "other_details_header",
        headline: "other_details.title",
        description: "",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: true,
      },
      tagName: "RadioInput",
      options: WHATSAPP_OPTIONS,
      props: {
        id: "whatsapp",
        name: "whatsapp",
        label: intl.formatMessage({ id: "registration.additional.whatsapp" }),
        type: "radio",
        title: intl.formatMessage({ id: "registration.additional.whatsapp" }),
        isRequired: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("whatsapp"),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "mobile",
        name: "mobile",
        label: intl.formatMessage({ id: "registration.additional.mobile_number" }),
        type: "text",
        required: true,
        maxLength: 10,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("mobile", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "landline",
        name: "landline",
        label: intl.formatMessage({ id: "registration.additional.additional_phone_number" }),
        type: "text",
        required: false,
        maxLength: 10,
      },
      validation: {
        type: "string",
        validators: [
          samePhoneTest,
          ...validators.get("mobile", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "email",
        name: "email",
        label: intl.formatMessage({ id: "registration.additional.email" }),
        type: "text",
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("email", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "TextInput",
      props: {
        id: "email2",
        name: "email2",
        label: intl.formatMessage({ id: "registration.additional.additional_email" }),
        type: "text",
        required: false,
      },
      validation: {
        type: "string",
        validators: [
          sameEmailtest,
          ...validators.get("email", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "validity_date_header",
        headline: "validity_date.title",
        description: "validity_date.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: true,
      },
      tagName: "DateInput",
      props: {
        id: "validity_date",
        name: "validity_date",
        label: intl.formatMessage({ id: "validity_date.label" }),
        type: "date",
        required: true,
        placeholder: "",
        value: new Date(),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInFuture", true),
          ...validators.get("dateNotToFarInFuture"),
        ],
      },
      initialValue: format(new Date(), "yyyy-MM-dd"),
      tooltipMessage: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "data_protection_header",
        headline: "dataprotection.title",
        description: "dataprotection.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "CheckboxInput",
      props: {
        id: "privacy",
        name: "privacy",
        label: intl.formatMessage({ id: "registration.dataprotection.dataprotection" }),
        type: "checkbox",
        lg: true,
        required: true,
      },
      validation: {
        type: "bool",
        validators: detailsView
          ? [...validators.get("requiredCheckbox", false)]
          : [],
      },
      initialValue: false,
    },
  ];
};

const generatePayload = (values) => {
  const payload = {
    person: {
      birthdate: values.birthdate,
      sex: values.sex,
      firstname: values.firstname,
      lastname: values.lastname,
      former_lastname: values.former_lastname,
      donor_id: values.donor_id.toUpperCase(),
    },
    former_address: {
      apartment: values.prev_apartment,
      building: values.prev_house_name,
      houseno: values.prev_house_no,
      street: values.prev_street,
      city: values.prev_city,
      region: values.prev_county,
      zipcode: values.prev_postcode,
      district: values.prev_district,
      locality: values.prev_locality,
      co: values.prev_co,
      state: values.prev_state,
    },
    private_address: {
      apartment: values.current_apartment,
      building: values.current_house_name,
      houseno: values.current_house_no,
      street: values.current_street,
      city: values.current_city,
      region: values.current_county,
      zipcode: values.current_postcode,
      district: values.current_district,
      locality: values.current_locality,
      co: values.current_co,
      state: values.current_state,
    },
    private_contact: {
      mobile: values.mobile,
      phone: values.landline,
      email: values.email,
      email2: values.email2,
    },
    ciu_data: {
      validity_date: values.validity_date,
      whatsapp: values.whatsapp === "yes",
    },
  };

  return clean(payload);
};

export default { getUpdateAddressForm, generatePayload };
