import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import Campaign from "../../components/campaigns/campaign";
import backgroundImageHelper from "../image/backgroundImageHelper";

const CampaignCard = (props) => {
  const {
    data,
    small,
    tile,
    author,
  } = props;

  let campaignData = Array.isArray(data) ? data : data.value;

  const campaigns = [];

  if (author) {
    campaignData = campaignData.filter((item) => (
      item.elements.author_name.value.length > 0
      && item.elements.author_name.value[0].codename === author));
  }

  campaignData.forEach((campaign) => {
    campaigns.push(
      <Campaign
        backgroundImageStyle={backgroundImageHelper(campaign.elements.hero_image, { w: 1000 })}
        date={new Date(campaign.elements.publishdate.value)}
        href={campaign.id}
        key={campaign.id}
        small={small}
        tile={tile}
      >
        {campaign.elements.hero_headline.value}
      </Campaign>,
    );
  });

  return React.Children.toArray(campaigns);
};

export const query = graphql`
  fragment CampaignCard on kontent_item_article_page_campaign {
    id
    system {
      codename
    }
    elements {
      author_name {
        value {
          codename
        }
      }
      hero_headline {
        value
      }
      hero_image {
        value {
          ...KontentItemImage
        }
      }
      publishdate {
        value
      }
    }
  }
`;

CampaignCard.propTypes = {
  author: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  small: PropTypes.bool,
  tile: PropTypes.bool,
};

CampaignCard.defaultProps = {
  author: null,
  small: false,
  tile: false,
};

export default CampaignCard;
