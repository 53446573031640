import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useMediaQuery from "../../hooks/haiku/useMediaQuery";
import { IconAccordionExpand18, IconAccordionMinimize18 } from "../../icons";

import "./footerSection.scss";

const FooterSection = ({ children, sectionName, icon }) => {
  const [isSectionExpanded, setIsSectionExpanded] = useState(false);
  const isLowerLg = useMediaQuery("(max-width: 991px)");

  return (
    <Accordion className="footer-section">
      <Card>
        <Accordion.Toggle
          as="div"
          eventKey="0"
          onClick={() => {
            setIsSectionExpanded(!isSectionExpanded);
          }}
        >
          <Row
            className={classNames(
              "footer-section__header",
              {
                "footer-section__header--active": isSectionExpanded,
                "mb-0": !isSectionExpanded && icon,
              },
            )}
          >
            <Col
              xs={10}
              sm={11}
              className={classNames(
                "footer-section__section-name",
                { "d-inline-flex align-items-center": icon },
              )}
            >
              {icon}{sectionName}
            </Col>
            {isLowerLg && (
              <Col xs={2} sm={1} className="justify-content-end d-flex">
                {
                  isSectionExpanded
                    ? <IconAccordionMinimize18 className="footer-section__icon" />
                    : <IconAccordionExpand18 className="footer-section__icon" />
                }
              </Col>
            )}
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {children}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

FooterSection.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.object]),
  sectionName: PropTypes.node.isRequired,
};

FooterSection.defaultProps = {
  children: null,
  icon: null,
};

export default FooterSection;
