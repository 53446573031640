import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";

import { IconDropdownExpand18, IconTargetGroups18 } from "../../icons";
import iconHelper from "../../utils/iconHelper";
import NavigationLink from "../navigationLink/navigationLink";

const InfoNavigation = (props) => {
  const {
    data,
    renderDropdown,
    footer,
    isGroup,
  } = props;

  const dropdownMenu = useRef(null);
  const [targetGroupIsActive, setTargetGroupIsActive] = useState(false);

  if (!data || !data.elements?.title.value || isGroup) {
    return null;
  }

  const infoNavigation = data;
  const infoNavigations = [];

  if (infoNavigation) {
    infoNavigation.elements.elements.value.forEach((item) => {
      if (renderDropdown) {
        infoNavigations.push((
          <NavigationLink {...item} className="dropdown-item typo-small-label" key={item.elements.title.value} />
        ));
      } else {
        infoNavigations.push((
          <div key={item.elements.title.value} className="nav-item">
            <NavigationLink {...item} className="nav-link" />
          </div>
        ));
      }
    });
  }

  useEffect(() => {
    setTargetGroupIsActive(dropdownMenu?.current?.getElementsByClassName("active").length > 0);
  }, []);

  const dropdown = (
    <Dropdown as={Nav.Item} alignRight className="top-navigation__delimiter">
      <Dropdown.Toggle
        as={Nav.Link}
        className={classNames({ active: targetGroupIsActive }, { "typo-label": footer })}
      >
        {infoNavigation.elements.title.value}
        <IconDropdownExpand18 />
      </Dropdown.Toggle>
      <Dropdown.Menu
        renderOnMount
        ref={dropdownMenu}
      >
        {infoNavigations}
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <>
      {footer && (
        iconHelper(infoNavigation.elements.icon.value[0]?.codename) || <IconTargetGroups18 />
      )}
      {dropdown}
    </>
  );
};

InfoNavigation.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  footer: PropTypes.bool,
  isGroup: PropTypes.bool,
  renderDropdown: PropTypes.bool,
};

InfoNavigation.defaultProps = {
  footer: false,
  isGroup: false,
  renderDropdown: false,
};

export default InfoNavigation;
