import React, { useMemo } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { mixed, object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import {
  IconError18,
  IconFurther18,
  IconFurther24,
  IconLearnMore18,
} from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import DropdownFormField from "../../dynamicForm/dropdownFormField/dropdownFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationPersonalInformation = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });

  const validationSchema = useMemo(() => object().shape({
    firstname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 40,
        },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    gender: mixed()
      .required(requiredMessage)
      .oneOf(["M", "F"], requiredMessage),
    lastname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .trim()
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    middlename: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(/^[^\d]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    salutation: string()
      .max(25, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 25,
        },
      )),
  }), []);

  const getUppercasedString = (value) => {
    const valueArray = value.split(/([,\- ])+/);
    let tmpValue = "";

    for (let i = 0; i < valueArray.length; i += 1) {
      tmpValue += valueArray[i].substring(0, 1).toUpperCase()
        + valueArray[i].substring(1).toLowerCase();
    }

    return tmpValue;
  };

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.personal.title"
      />
      <Formik
        initialValues={registrationData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);
          dispatch(
            setRegistrationData({
              firstname: getUppercasedString(values.firstname),
              gender: values.gender,
              lastname: getUppercasedString(values.lastname),
              middlename: getUppercasedString(values.middlename),
              salutation: values.salutation,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_personal_information", { event_value: "success" });
          DataLayer.pushEvent("pp_registration_step_personal_information_value",
            {
              dimension_reg_sex: values.gender,
              event_value: values.gender,
            });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} lg={6}>
                <DropdownFormField
                  label={intl.formatMessage({ id: "registration.personal.salutation" })}
                  placeholder={intl.formatMessage({ id: "registration.please_select" })}
                  onChange={(selectedOption) => {
                    const { target } = selectedOption;

                    if (target && target.value) {
                      setFieldValue("salutation", target.value);
                      setFieldError("salutation", undefined);
                    } else {
                      setFieldValue("salutation", "");
                    }
                  }}
                  onBlur={() => {
                    setFieldTouched("salutation", true);
                  }}
                  touched={touched}
                  id="salutation"
                  errors={errors}
                  values={values}
                  options={RegistrationDataHelper.titleOptionsGb}
                />
              </Col>
              <Col xs={12}>
                <div className="required-sign typo-label">
                  <FormattedMessage id="registration.personal.gender.title" />
                </div>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    value="M"
                    id="gender-male"
                    checked={values.gender === "M"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0 typo-body2", errors.gender && touched.gender ? "is-invalid" : "")}
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    value="F"
                    id="gender-female"
                    checked={values.gender === "F"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0 typo-body2", errors.gender && touched.gender ? "is-invalid" : "")}
                  />
                  <OverlayTrigger
                    placement="top"
                    delay={{ hide: 400, show: 100 }}
                    overlay={(param) => (
                      <Tooltip {...param} id="gender-info">
                        <FormattedMessage id="registration.personal.gender.info" />
                      </Tooltip>
                    )}
                  >
                    <IconLearnMore18 className="text-grey-dark" />
                  </OverlayTrigger>
                  {errors.gender && touched.gender && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.gender}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="firstname"
                  label={intl.formatMessage({ id: "registration.personal.firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="middlename"
                  label={intl.formatMessage({ id: "registration.personal.middlename" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="lastname"
                  label={intl.formatMessage({ id: "registration.personal.lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationPersonalInformation.stepName = "registration_personal_information";

RegistrationDialogStepRegistrationPersonalInformation.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationPersonalInformation;
