/* eslint-disable sort-keys */
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";
import * as Yup from "yup";

import { salutationGermany, title } from "./general-form-data";
import { format } from "../../../utils/dateHelper";
import { getOnPreventKeypress, RegexPatterns } from "../../dynamicForm/validators";
import { clean } from "../formBuilder";
import Validators from "../validations/validators";

const getUpdateAddressForm = (intl) => {
  const validators = new Validators(intl);
  const REQUIRED_ERROR = intl.formatMessage({ id: "form.required" });

  const detailsView = useSelector((state) => state.updateAddress.detailsView);

  /* eslint-disable sort-keys */
  return [
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "personal_info_header",
        headline: "personal_info.title",
        description: "personal_info.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "donor_id",
        name: "donor_id",
        label: intl.formatMessage({ id: "donor_id" }),
        type: "text",
        required: true,
        maxLength: 20,
        info: intl.formatMessage({ id: "address_update.donor_id.info" }),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("donor_id", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "DateInput",
      props: {
        id: "birthdate",
        name: "birthdate",
        label: intl.formatMessage({ id: "registration.age.birthdate" }),
        type: "date",
        required: true,
        placeholder: "",
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInPast", true),
        ],
      },
      initialValue: "",
      tooltipMessage: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: true,
      },
      tagName: "RadioInput",
      props: {
        id: "salutation",
        name: "salutation",
        label: intl.formatMessage({ id: "form.salutation" }),
        type: "text",
        isRequired: true,
        title: intl.formatMessage({ id: "form.please_select.label" }),
      },
      options: salutationGermany(intl),
      validation: {
        type: "string",
        validators: [
          ...validators.get("", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: false,
      },
      tagName: "SelectInput",
      props: {
        id: "title",
        name: "title",
        label: intl.formatMessage({ id: "registration.personal.salutation" }),
        type: "text",
        required: false,
      },
      options: title,
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "firstname",
        name: "firstname",
        label: intl.formatMessage({ id: "registration.personal.firstname" }),
        type: "text",
        required: true,
        maxLength: 40,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
        warningUnderSection: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("firstname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "lastname",
        name: "lastname",
        label: intl.formatMessage({ id: "registration.personal.lastname" }),
        type: "text",
        required: true,
        maxLength: 100,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
        warningUnderSection: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "former_lastname",
        name: "former_lastname",
        label: intl.formatMessage({ id: "personal_info.former_last_name" }),
        type: "text",
        required: false,
        maxLength: 100,
        onKeyPress: getOnPreventKeypress(RegexPatterns.noNumbers),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("lastname", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "previous_address_header",
        headline: "previous_address.title",
        description: "previous_address.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "CheckboxInput",
      props: {
        id: "address_unchanged",
        name: "address_unchanged",
        label: intl.formatMessage({ id: "address_unchanged" }),
        type: "checkbox",
        lg: true,
      },
      validation: {
        type: "bool",
        validators: [],
      },
      initialValue: false,
    },
    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "FormAddressInput",
      props: {
        id: "prev_address_input",
        name: "prev_address_input",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        fieldType: "prev",
        subFields: [
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "prev_street",
              name: "prev_street",
              label: intl.formatMessage({ id: "registration.address.street" }),
              type: "text",
              required: true,
              maxLength: 50,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("street", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "prev_house_no",
              name: "prev_house_no",
              label: intl.formatMessage({ id: "registration.address.houseNo" }),
              type: "text",
              required: true,
              maxLength: 10,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("houseno", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "prev_postcode",
              name: "prev_postcode",
              label: intl.formatMessage({ id: "registration.address.postCode" }),
              type: "text",
              required: true,
              maxLength: 5,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("zipcode", false),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "prev_city",
              name: "prev_city",
              label: intl.formatMessage({ id: "registration.address.city" }),
              type: "text",
              required: true,
              maxLength: 50,
            },
            validation: {
              type: "string",
              validators: [
                {
                  type: "when",
                  params: ["address_unchanged", {
                    is: false,
                    then: Yup.string().required(REQUIRED_ERROR),
                  }],
                },
                ...validators.get("city", false),
              ],
            },
            initialValue: "",
          },
        ],
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "current_address_header",
        headline: "current_address.title",
        description: "current_address.text",
        type: "header",
      },
    },

    {
      type: "input",
      responsive: {
        xs: "12",
      },
      tagName: "FormAddressInput",
      props: {
        id: "current_address_input",
        name: "current_address_input",
        label: intl.formatMessage({ id: "registration.address.apartment" }),
        fieldType: "current",
        subFields: [
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "current_street",
              name: "current_street",
              label: intl.formatMessage({ id: "registration.address.street" }),
              type: "text",
              required: true,
              maxLength: 50,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("street", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "current_house_no",
              name: "current_house_no",
              label: intl.formatMessage({ id: "registration.address.houseNo" }),
              type: "text",
              required: true,
              maxLength: 10,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("houseno", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
            },
            tagName: "TextInput",
            props: {
              id: "current_apartment",
              name: "current_apartment",
              label: intl.formatMessage({ id: "registration.address.apartment" }),
              type: "text",
              required: false,
              maxLength: 100,
            },
            validation: {
              type: "string",
              validators: [],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "6",
            },
            tagName: "TextInput",
            props: {
              id: "current_co",
              name: "current_co",
              label: intl.formatMessage({ id: "c/o" }),
              type: "text",
              required: false,
              maxLength: 100,
            },
            validation: {
              type: "string",
              validators: [],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "4",
            },
            tagName: "TextInput",
            props: {
              id: "current_postcode",
              name: "current_postcode",
              label: intl.formatMessage({ id: "registration.address.postCode" }),
              type: "text",
              required: true,
              maxLength: 5,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("zipcode", true),
              ],
            },
            initialValue: "",
          },
          {
            type: "input",
            responsive: {
              xs: "12",
              md: "8",
            },
            tagName: "TextInput",
            props: {
              id: "current_city",
              name: "current_city",
              label: intl.formatMessage({ id: "registration.address.city" }),
              type: "text",
              required: true,
              maxLength: 50,
            },
            validation: {
              type: "string",
              validators: [
                ...validators.get("city", true),
              ],
            },
            initialValue: "",
          },
        ],
      },
      validation: {
        type: "string",
        validators: [],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "other_details_header",
        headline: "other_details.title",
        description: "other_details.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "email",
        name: "email",
        label: intl.formatMessage({ id: "registration.additional.email" }),
        type: "text",
        required: true,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("email", true),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
      },
      tagName: "TextInput",
      props: {
        id: "mobile",
        name: "mobile",
        label: intl.formatMessage({ id: "registration.additional.mobileNumber" }),
        type: "text",
        required: false,
        maxLength: 20,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("phone", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "TextInput",
      props: {
        id: "landline",
        name: "landline",
        label: intl.formatMessage({ id: "registration.additional.additionalPhoneNumber" }),
        type: "text",
        required: false,
        maxLength: 20,
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("phone", false),
        ],
      },
      initialValue: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "validity_date_header",
        headline: "validity_date.title",
        description: "validity_date.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "6",
        singleLine: true,
      },
      tagName: "DateInput",
      props: {
        id: "validity_date",
        name: "validity_date",
        label: intl.formatMessage({ id: "date" }),
        type: "date",
        required: true,
        placeholder: "",
        value: new Date(),
      },
      validation: {
        type: "string",
        validators: [
          ...validators.get("dateInFuture", true),
          ...validators.get("dateNotToFarInFuture"),
        ],
      },
      initialValue: format(new Date(), "yyyy-MM-dd"),
      tooltipMessage: "",
    },
    {
      type: "header",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "FormSectionHeader",
      props: {
        id: "data_protection_header",
        headline: "dataprotection.title",
        description: "registration.dataprotection.text",
        type: "header",
      },
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "CheckboxInput",
      props: {
        id: "privacy",
        name: "privacy",
        label: intl.formatMessage({ id: "registration.dataprotection.dataprotection" }),
        type: "checkbox",
        lg: true,
        required: detailsView,
      },
      validation: {
        type: "bool",
        validators: detailsView
          ? [...validators.get("requiredCheckbox", false)]
          : [],
      },
      initialValue: false,
    },
    {
      type: "input",
      responsive: {
        xs: "12",
        md: "12",
      },
      tagName: "CheckboxInput",
      props: {
        id: "consentTransfer",
        name: "consentTransfer",
        label: parse(sanitizeHtml(intl.formatMessage({ id: "form.updateAddress.consent_transfer" }), { allowedTags: ["a", "br"] })),
        type: "checkbox",
        lg: true,
        required: false,
      },
      validation: {
        type: "bool",
        validators: [],
      },
      initialValue: false,
    },
  ];
};

const generatePayload = (values) => {
  /* eslint-disable sort-keys */
  const payload = {
    person: {
      birthdate: values.birthdate,
      title: values.title,
      salutation: values.salutation,
      firstname: values.firstname,
      lastname: values.lastname,
      former_lastname: values.former_lastname,
      donor_id: values.donor_id.toUpperCase(),
    },
    former_address: {
      houseno: values.prev_house_no,
      street: values.prev_street,
      city: values.prev_city,
      zipcode: values.prev_postcode,
    },
    private_address: {
      street: values.current_street,
      houseno: values.current_house_no,
      apartment: values.current_apartment,
      co: values.current_co,
      city: values.current_city,
      zipcode: values.current_postcode,
    },
    private_contact: {
      mobile: values.mobile,
      phone: values.landline,
      email: values.email,
    },
    ciu_data: {
      validity_date: values.validity_date,
    },
    marketing_consent: {
      consent_transfer_cb: values.consentTransfer ? "YES" : "NO",
    },
  };

  return clean(payload);
};

export default { getUpdateAddressForm, generatePayload };
