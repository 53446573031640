import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import { IconCooperation48, IconDualtoneNewbadge48 } from "../../icons";
import HeaderButtons from "../navigation/headerButtons";

import "./campaignCounterEyecatcher.scss";

const CampaignCounterEyecatcher = (props) => {
  const {
    headline,
    description,
    registrations,
    maxRegistration,
  } = props;

  const percantageRegistration = (100 / maxRegistration) * registrations;

  return (
    <div className="counter-eyecatcher">
      <div className="counter-eyecatcher__content">
        <h3>{headline}</h3>
        <div className="counter-eyecatcher__description">
          {description}
        </div>
      </div>
      <div className="counter-eyecatcher__registrations-container">
        <div className="counter-eyecatcher__registrations">
          {registrations > 0
            ? <IconCooperation48 className="primary" />
            : <IconDualtoneNewbadge48 />}
          <div className="typo-title1">
            {registrations > 0
              ? (
                <>
                  <div>{registrations} {maxRegistration !== null && [`/ ${maxRegistration}`]}</div>
                  <div className="typo-body1 text-black">
                    <FormattedMessage id="virtual_drive.registrations" />
                  </div>
                </>
              )
              : (
                <>
                  {maxRegistration !== null
                    ? <FormattedMessage id="virtual_drive.counter_help_to_reach" values={{ goal: maxRegistration }} />
                    : <FormattedMessage id="virtual_drive.counter_be_first" />}
                </>
              )}
          </div>
        </div>
        {maxRegistration !== null && registrations > 0 && (
          <div className="counter-eyecatcher__progress-bar">
            <div className="counter-eyecatcher__registered" style={{ width: `${percantageRegistration}%` }} />
            <div
              className="counter-eyecatcher__remaining-capacity"
              style={{ width: `${100 - percantageRegistration}%` }}
            />
          </div>
        )}
        <div className="counter-eyecatcher__button">
          <HeaderButtons />
        </div>
      </div>
    </div>
  );
};

CampaignCounterEyecatcher.propTypes = {
  description: PropTypes.string,
  headline: PropTypes.string.isRequired,
  maxRegistration: PropTypes.number,
  registrations: PropTypes.number,
};

CampaignCounterEyecatcher.defaultProps = {
  description: null,
  maxRegistration: null,
  registrations: null,
};

export default CampaignCounterEyecatcher;
