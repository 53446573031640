import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import PaymentMethods from "./paymentMethods";
import KontentAssetsImage from "../../templates/kontentAssetsImage";
import NavigationLink from "../navigationLink/navigationLink";

import "./donationBox.scss";

const DonationBoxEyecatcher = (props) => {
  const {
    buttonText,
    buttonUrl,
    className,
    paymentProvider,
  } = props;

  return (
    <div className={classNames("donation-box", className)}>

      {paymentProvider && (
        <div className="mb-24">
          <div className="donation-box__payment-methods">
            <FormattedMessage id="payment_dialog.possible_payment_methods" />
          </div>
          <PaymentMethods>
            {paymentProvider.map((item) => (
              <KontentAssetsImage height={36} width={36} fit="contain" key={item.name} data={item} />
            ))}
          </PaymentMethods>
        </div>
      )}

      <NavigationLink
        href={buttonUrl}
        className="btn btn-secondary"
        onClick={() => {
          window.skipBeforeUnload = true;
        }}
      >
        {buttonText}
      </NavigationLink>

    </div>
  );
};

DonationBoxEyecatcher.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  paymentProvider: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.string,
    url: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
};

DonationBoxEyecatcher.defaultProps = {
  className: "",
  paymentProvider: undefined,
};

export default DonationBoxEyecatcher;
