import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./iconProcess.scss";

const IconProcess = ({ children, className }) => (
  <div className="text-section__fullwidth">
    <Row className={classNames("icon-process", className)}>
      {React.Children.map(children, (child) => (
        <Col md={4}>
          {child}
        </Col>
      ))}
    </Row>
  </div>
);

IconProcess.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

IconProcess.defaultProps = {
  className: "",
};

export default IconProcess;
