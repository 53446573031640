import React, { useState } from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";

import { useSubmitIoRegistrationMutation } from "../../../features/io/ioRegistrationApiSlice";
import errorHelper from "../../../hooks/errorHelper";
import useURNParams from "../../../hooks/useURNParams";
import { IllustrationError } from "../../../images";
import IconButton from "../../button/iconButton";
import TimeoutButton from "../../button/timeoutButton";
import NavigationLink from "../../navigationLink/navigationLink";
import StatusWindow from "../../statusWindow/statusWindow";
import RegistrationDataHelper from "../registrationDataHelper";

const RegistrationDialogStepError = ({ content, error }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const lastSuccess = useSelector((state) => state.registration.lastSuccess);

  const urnParams = useURNParams();

  const intl = useIntl();

  const [tryBeforeTimeOut] = useState(((new Date()).getTime() - lastSuccess) / 1000 < 60);
  const [submitIoRegistration, submitIoRegistrationResult] = useSubmitIoRegistrationMutation({ fixedCacheKey: "submitIoRegistrationCacheKey" });

  const errorData = errorHelper(error);

  const contactUsLink = (
    <NavigationLink href={content.contactLink}>
      {errorData.status > 400
        ? (
          <FormattedMessage
            id="io.error.alternative_contact_us"
            defaultMessage={intl.formatMessage({ id: "io.error.contact_us" })}
          />
        )
        : <FormattedMessage id="io.error.contact_us" />}
    </NavigationLink>
  );

  return (
    <>
      <Row className="mt-30">
        <Col xs={12} lg={{ offset: 2, span: 8 }} className="text-center">
          <IllustrationError />
          <h3 className="mt-24">
            {tryBeforeTimeOut
              ? <FormattedMessage id="registration.error.next_try_before_timelimit_title" />
              : (
                <>{errorData.status >= 400
                  ? <FormattedMessage id="io.error.title" />
                  : <FormattedMessage id="io.error.default_title" />}
                </>
              )}
          </h3>
          <p className="text-black">
            {tryBeforeTimeOut
              ? <FormattedMessage id="registration.error.next_try_before_timelimit_text" />
              : (
                <>
                  {errorData.status >= 400
                    ? (
                      <FormattedMessage
                        id="io.error.text"
                        values={{ link: contactUsLink }}
                      />
                    )
                    : (
                      <FormattedMessage
                        id="io.error.default_text"
                        values={{ link: contactUsLink }}
                      />
                    )}
                </>
              )}
          </p>
          <div>
            {
              registrationData
              && (
                !(errorData.status < 500 && errorData.status >= 400)
                || errorData.status === undefined
              )
              && (
                <TimeoutButton
                  as={IconButton}
                  intlId="io.error.retry"
                  loading={submitIoRegistrationResult.isLoading}
                  disabled={submitIoRegistrationResult.isLoading}
                  className="mt-24"
                  onClick={() => {
                    submitIoRegistration({
                      data: RegistrationDataHelper.preparePayload(registrationData),
                      urns: urnParams,
                    });
                  }}
                  timeoutSeconds={60}
                />
              )
            }
          </div>
          {error && error.status >= 400 && error.status < 600 && (
            <div className="pt-15">
              <StatusWindow errorData={errorData} />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

RegistrationDialogStepError.getAnswer = () => false;

RegistrationDialogStepError.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default RegistrationDialogStepError;
