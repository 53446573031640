import React from "react";

import classNames from "classnames";
import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import {
  IconConsentManagement18,
  IconGoogleMaps24,
  IconIssuuOriginalColored24,
  IconSpotifyOriginalColored24,
  IconVimeoOriginalColored24,
  IconYoutubeOriginalColored24,
} from "../../icons";
import Button from "../button/button";

import "./consent.scss";

const DefaultConsent = ({ openCookieConsent, enableExternalContent, type }) => (
  <div className={classNames("consent-container", type)}>
    <div className="consent">
      <div className="detail">
        <div className="mb-12 icons">
          <IconYoutubeOriginalColored24 />
          <IconGoogleMaps24 />
          <IconSpotifyOriginalColored24 />
          <IconVimeoOriginalColored24 />
          <IconIssuuOriginalColored24 />
        </div>
        <div className="typo-label">
          <FormattedMessage id="consent.details" />
        </div>
      </div>
      <div className="action">
        <Button
          onClick={(event) => {
            event.stopPropagation();
            enableExternalContent();
          }}
          className="enable-external-content-button shadow-none"
          variant="dark"
        >
          <FormattedMessage id="consent.enable_external_content" />
        </Button>
        <button
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            openCookieConsent();
          }}
          className="icon-button"
        >
          <IconConsentManagement18 className="mr-6" />
          <FormattedMessage id="consent.management" />
        </button>
      </div>
    </div>
  </div>
);

DefaultConsent.propTypes = {
  enableExternalContent: PropTypes.func.isRequired,
  openCookieConsent: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default DefaultConsent;
