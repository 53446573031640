import IoBaseApiSlice from "./ioBaseApiSlice";

export const IoUpdateAddressApiSlice = IoBaseApiSlice.injectEndpoints({
  endpoints: (build) => ({
    submitIoUpdateAddress: build.mutation({
      query: ({ data, urn = process.env.GATSBY_IO_URN_CIU, urns = null }) => ({
        apiRequirements: ["session", "csrfToken"],
        data: {
          classification: "Restricted",
          payload: data,
          ...(urns !== null && { meta: urns }),
        },
        method: "post",
        url: `${process.env.GATSBY_IO_HOST}/ciu/${urn}`,
      }),
      transformResponse: ({ payload }) => payload,
    }),
  }),
});

export const {
  useSubmitIoUpdateAddressMutation,
} = IoUpdateAddressApiSlice;
