import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { IconCheck18 } from "../../icons";
import FilterTag from "../filterTag/filterTag";
import NavigationLink from "../navigationLink/navigationLink";

import "./universalNavigation.scss";

const UniversalNavigation = (props) => {
  const {
    icon,
    headline,
    description,
    donorCenter,
    dkmsFamily,
  } = props;

  return (
    <Row className="universal-navigation">
      <Col xl={7}>
        <div className="universal-navigation__headline typo-title1">
          {headline}
        </div>
        <div className="typo-body1">
          {description}
        </div>
      </Col>
      <Col xl={5} className="order-first order-xl-0 text-xl-right">
        {icon}
      </Col>
      <Col className="universal-navigation__sites donor-center" md={6}>
        <h4><FormattedMessage id="navigation.dkms_donor_centers" /></h4>
        <div className="d-flex flex-column">
          {donorCenter.map((item) => (
            <React.Fragment key={item.id}>
              <NavigationLink href={item.href} className="typo-label">
                {item.icon}<span>{item.text}</span>
              </NavigationLink>
              {item.selected && (
                <div>
                  <FilterTag icon={<IconCheck18 />}>
                    <FormattedMessage id="navigation.you_are_here" />
                  </FilterTag>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </Col>
      <Col className="universal-navigation__sites" md={6}>
        <h4><FormattedMessage id="navigation.dkms_family" /></h4>
        <div className="d-flex flex-column">
          {dkmsFamily.map((item) => (
            <NavigationLink href={item.href} key={item.id} className="typo-label">
              {item.icon}{item.text}
            </NavigationLink>
          ))}
        </div>
      </Col>
    </Row>
  );
};

UniversalNavigation.propTypes = {
  description: PropTypes.string.isRequired,
  dkmsFamily: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  donorCenter: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  headline: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.object]),
};

UniversalNavigation.defaultProps = {
  icon: null,
};

export default UniversalNavigation;
