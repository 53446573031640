import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import KontentItemAsset from "./kontentItemAsset";

const KontentItemDownloadGroup = ({ data }) => {
  const downloads = data.value ? data.value[0] : data;

  if (!downloads || !downloads.elements) {
    return null;
  }

  const elements = downloads.elements.downloads.value.length;
  const colProps = {
    lg: 4,
    md: { offset: 0, span: 5 },
    sm: { offset: 1, span: 10 },
  };

  let colPropsFirst = {
    lg: { offset: 0, span: 4 },
    md: 5,
    sm: { offset: 1, span: 10 },
  };

  if (elements < 3) {
    colPropsFirst = {
      lg: { offset: 2, span: 4 },
      md: 5,
      sm: { offset: 1, span: 10 },
    };
  }

  const minOneItemWithImage = downloads.elements.downloads.value.some(
    (item) => (item.elements.image.value.length > 0),
  );

  return (
    <Row className="download-section text-section__fullwidth">
      {downloads.elements.downloads.value.map((asset, index) => (
        <Col {...(index % 2 === 0 ? colPropsFirst : colProps)} key={asset.id}>
          <KontentItemAsset data={asset} usePlaceholder={minOneItemWithImage} />
        </Col>
      ))}
    </Row>
  );
};

export const query = graphql`
    fragment KontentItemDownloadGroup on kontent_item_download_group {
    id
    system {
      codename
    }
    elements {
      downloads {
        value {
          ...KontentItemAsset
        }
      }
    }
  }
`;

KontentItemDownloadGroup.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemDownloadGroup;
