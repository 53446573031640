import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import NewsCard from "./newsCard";

const RelatedNewsCards = ({ excludeId, categories }) => {
  const data = useStaticQuery(graphql`
    {
      allKontentItemArticlePageNews(sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          ...NewsCard
        }
      }
      allKontentItemArticleMigration(sort: {fields: elements___publishdate___value, order: DESC}) {
        nodes {
          ...NewsCardMigrated
        }
      }
    }
  `);

  let filteredData = [];
  data.allKontentItemArticlePageNews.nodes.concat(
    data.allKontentItemArticleMigration.nodes,
  ).forEach((node) => filteredData.push(node));

  filteredData = filteredData.filter((el) => {
    if (el.id === excludeId) {
      return false;
    }
    let filterObj = false;
    el.elements.news_category.value.forEach((cat) => {
      if (categories.includes(cat.codename)) {
        filterObj = true;
      }
    });

    return filterObj;
  });

  if (data.allKontentItemArticlePageNews) {
    return NewsCard({ currentFirst: true, data: filteredData, limit: 3 });
  }

  return null;
};

RelatedNewsCards.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.array]),
  excludeId: PropTypes.string,
};

RelatedNewsCards.defaultProps = {
  categories: [],
  excludeId: null,
};

export default RelatedNewsCards;
