import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

import { IconDeleteInput18, IconSearch24 } from "../../icons";

import "./searchFilter.scss";

const SearchFilter = ({ children, variant }) => {
  const intl = useIntl();
  const content = useRef(null);
  const [filter, setFilter] = useState("");
  const [hasContent, setHasContent] = useState(true);

  const checkContent = () => {
    if (content.current) {
      const contentItem = content.current.getElementsByClassName("search-filter__content-item");

      if (contentItem.length > 0) {
        setHasContent(contentItem[0].children.length > 0);
      }
    }
  };

  useEffect(() => {
    checkContent();
  }, [filter]);

  useEffect(() => {
    checkContent();
  }, []);

  return (
    <div className={classNames("search-filter", { [`search-filter--${variant}`]: variant })}>
      <div className="container--fullwidth search-filter__search-section">
        <Container fluid>
          <div className="search-filter__input">
            <InputGroup className="prepend large">
              <Form.Control
                size="lg"
                placeholder={intl.formatMessage({ id: "search.placeholder.search_for_keywords" })}
                onChange={(event) => {
                  setFilter(event.target.value);
                }}
                value={filter}
              />
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <IconSearch24 />
                </InputGroup.Text>
              </InputGroup.Prepend>
              {filter && filter !== "" && (
                <button
                  type="button"
                  className="btn-clean"
                  onClick={() => {
                    setFilter("");
                  }}
                >
                  <IconDeleteInput18 />
                </button>
              )}
            </InputGroup>
          </div>
        </Container>
      </div>
      <div className="search-filter__content" ref={content}>
        {React.Children.toArray(children).map((child, index) => (
          <div
            key={`search-filter__content-item--${index.toString()}`}
            className="search-filter__content-item"
          >
            {React.cloneElement(child, { filter })}
          </div>
        ))}
        {!hasContent && (
          <Row className="search-filter__content-no-content">
            <Col xs={{ offset: 2, span: 8 }}>
              <FormattedMessage
                id="search.noresult"
                values={{ searchTerm: filter }}
              />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

SearchFilter.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["faq"]),
};

SearchFilter.defaultProps = {
  variant: null,
};

export default SearchFilter;
