import React from "react";

import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import "./imageEyecatcher.scss";

const ImageEyecatcher = (props) => {
  const {
    headline,
    text,
    image,
    button,
  } = props;

  const { width } = useWindowDimensions();

  let tempImage = image;

  if (width >= 576 && width < 768) {
    tempImage = React.cloneElement(image, { aspectRatio: 16 / 9 });
  }

  if (width >= 768) {
    tempImage = React.cloneElement(image, {
      aspectRatio: undefined,
      height: 420,
      width: 830,
    });
  }

  return (
    <div className="text-section__fullwidth">
      <Row className="image-eyecatcher">
        <Col md={7} lg={8} className="image-eyecatcher__image">
          {tempImage}
        </Col>
        <Col md={5} lg={4} className="pr-md-0">
          <div className="image-eyecatcher__content">
            <div className="image-eyecatcher__headline">
              {headline}
            </div>
            {text !== null && (
              <p className="image-eyecatcher__text">
                {text}
              </p>
            )}
            <div className="mt-auto">
              {button}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

ImageEyecatcher.propTypes = {
  button: PropTypes.oneOfType([PropTypes.object]).isRequired,
  headline: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  text: PropTypes.string,
};

ImageEyecatcher.defaultProps = {
  text: null,
};

export default ImageEyecatcher;
