import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setRegistrationData } from "../../../features/registrationSlice";
import { IconFurther18, IconFurther24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepRegistrationGeneralPractitioner = ({ next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const validationSchema = useMemo(() => object().shape({
    gpCity: string()
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    gpPhoneNumber: string()
      .matches(
        /^[\d\s\-+/]{7,20}$/,
        intl.formatMessage({ id: "form.error.phone_pattern" }),
      ),
    gpPracticeName: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.gp.title"
      />
      <Formik
        initialValues={{
          gpCity: registrationData.gpCity,
          gpPhoneNumber: registrationData.gpPhoneNumber,
          gpPracticeName: registrationData.gpPracticeName,
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setRegistrationData({
              gpCity: values.gpCity,
              gpPhoneNumber: values.gpPhoneNumber,
              gpPracticeName: values.gpPracticeName,
            }),
          );

          DataLayer.pushEvent("pp_registration_step_general_practitioner", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="gpPracticeName"
                  label={intl.formatMessage({ id: "registration.gp.practice_name" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="gpCity"
                  label={intl.formatMessage({ id: "registration.gp.city" })}
                  type="text"
                  maxLength={50}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>
              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="gpPhoneNumber"
                  label={intl.formatMessage({ id: "registration.gp.phone_number" })}
                  type="text"
                  maxLength={25}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
              </Col>

              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton type="submit" disabled={isSubmitting}>
                    {values.gpPracticeName === "" && values.gpCity === "" && values.gpPhoneNumber === ""
                      ? (
                        <FormattedMessage id="registration.gp.skip" />
                      )
                      : (
                        <FormattedMessage id="registration.gp.next_button" />
                      )}

                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepRegistrationGeneralPractitioner.stepName = "registration_general_practitioner";

RegistrationDialogStepRegistrationGeneralPractitioner.propTypes = {
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepRegistrationGeneralPractitioner;
