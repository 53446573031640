import React, { Fragment, useEffect, useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import RegistrationDialogStepError from "./registrationDialogStepError";
import RegistrationDialogStepRegistrationAncestryUs from "./registrationDialogStepRegistrationAncestryUs";
import { useSubmitIoRegistrationMutation } from "../../../features/io/ioRegistrationApiSlice";
import { setStepError, setSuccess } from "../../../features/registrationSlice";
import usePrevious from "../../../hooks/usePrevious";
import useURNParams from "../../../hooks/useURNParams";
import { IconError18, IconSubmit18, IconSubmit24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import { dateFactory, format } from "../../../utils/dateHelper";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepOverviewUs = ({ content, next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const lastSuccess = useSelector((state) => state.registration.lastSuccess);
  const stepError = useSelector((state) => state.registration.stepError);
  const dispatch = useDispatch();

  const intl = useIntl();
  const urnParams = useURNParams();

  const [submitIoRegistration, submitIoRegistrationResult] = useSubmitIoRegistrationMutation({ fixedCacheKey: "submitIoRegistrationCacheKey" });

  const prevSubmitIoRegistrationResult = usePrevious(submitIoRegistrationResult);

  const validationSchema = useMemo(() => object().shape({
    electronicSignature: string()
      .trim()
      .required(intl.formatMessage({ id: "form.required" })),
  }), []);

  useEffect(() => {
    if (submitIoRegistrationResult.isSuccess && !prevSubmitIoRegistrationResult.isSuccess) {
      DataLayer.removeUnloadListener();
      DataLayer.pushEvent("pp_registration_step_final", { event_value: "success" });
      DataLayer.pushEvent("pp_registration_step_final_urn", { event_value: urnParams?.refs });
      DataLayer.pushEvent("ppe_registration_success_goal");

      dispatch(
        setSuccess(),
      );

      if (content.successPage) {
        window.skipBeforeUnload = true;
        window.location.href = content.successPage;
      } else {
        next();
      }
    }

    if (submitIoRegistrationResult.isError && !prevSubmitIoRegistrationResult?.isError) {
      DataLayer.pushEvent("pp_registration_step_final", { event_value: "fail" });
    }

    if (submitIoRegistrationResult.isError || prevSubmitIoRegistrationResult?.isError) {
      dispatch(
        setStepError(true),
      );
    }
  }, [submitIoRegistrationResult]);

  if (stepError) {
    return (
      <RegistrationDialogStepError
        content={content}
        error={submitIoRegistrationResult.error}
      />
    );
  }

  const mapping = RegistrationDialogStepRegistrationAncestryUs.getFlatObjectOfAncestries();
  const ancestry = [];
  RegistrationDataHelper.getAncestryFormattedArray(registrationData).forEach((item) => {
    ancestry.push(mapping[item]);
  });

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.overview.title"
      />
      <Row className="mb-36">
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.personal_details" />
          </div>
          <div className="typo-body2">
            {registrationData.firstname} {registrationData.lastname}
            <br />
            {registrationData.birthdate && (
              <>
                {format(dateFactory(registrationData.birthdate))}<br /><br />
              </>
            )}
            {registrationData.socialSecurityNumber && (
              <>
                {registrationData.socialSecurityNumber}<br /><br />
              </>
            )}
            <>
              {registrationData.weightImperialLbs} lbs<br />
              {registrationData.heightImperialFeet}
              &apos; {registrationData.heightImperialInch}&quot;
            </>
          </div>
        </Col>
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.contact_data" />
          </div>
          <div className="typo-body2">
            {(registrationData.apartment) && (
              <>
                {registrationData.apartment}<br />
              </>
            )}
            {registrationData.houseNo} {registrationData.street}<br />
            {registrationData.city} {registrationData.state} {registrationData.postCode}
            <br /><br />
            {registrationData.emailAddress}<br />
            {registrationData.mobileNumber}<br />
          </div>
        </Col>
        {registrationData.po && (
          <Col xs={6} className="registration-dialog-step__overview-group">
            <div className="registration-dialog-step__section-title">
              <FormattedMessage id="registration.address.poBox" />
            </div>
            <div className="typo-body2">
              {registrationData.firstname} {registrationData.lastname}<br />
              PO BOX {registrationData.poBox}<br />
              {`${registrationData.poCity} ${registrationData.poState} ${registrationData.poPostCode}`}<br />
              USA
            </div>
          </Col>
        )}
        <Col lg={6}>
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.ancestry" />
          </div>
          <div className="typo-body2">
            {ancestry.map((a) => (<Fragment key={a}>{a} <br /></Fragment>))}
          </div>
        </Col>
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.alternate_contact_data" />
          </div>
          <div className="typo-body2">
            {registrationData.contactFirstname} {registrationData.contactLastname}
            , {registrationData.contactRelationship}<br />
            {registrationData.contactPhoneNumber}<br />
            {registrationData.contactEmailAddress}<br />
          </div>
        </Col>
      </Row>

      {content && content.overviewText && (
        <div className="registration-dialog-step__overview-text">
          {content.overviewText}
        </div>
      )}

      <Formik
        initialValues={{}}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          // Prevent submitting if last successful submit was less than 60 seconds ago.
          if (((new Date()).getTime() - lastSuccess) / 1000 < 60) {
            dispatch(
              setStepError(true),
            );

            return;
          }

          if (!submitIoRegistrationResult.isSuccess) {
            submitIoRegistration({
              data: RegistrationDataHelper.preparePayload(registrationData),
              urns: urnParams,
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className="registration-dialog-step__overview-text">
                  <p>
                    <b><FormattedMessage id="registration.overview.electronic_signature_title" /></b><br />
                    <FormattedMessage id="registration.overview.electronic_signature_text" />
                  </p>
                </div>
              </Col>

              <Col xs={12} className="mt-18">
                <Form.Group className="required">
                  <Form.Control
                    type="text"
                    name="electronicSignature"
                    value={values.electronicSignature}
                    onChange={handleChange}
                    onBlur={(event) => {
                      handleBlur(event);
                      const { name } = event.target;
                      setFieldValue(name, values?.[name]?.trim() || "");
                    }}
                    isInvalid={(errors.electronicSignature && touched.electronicSignature)}
                  />
                  <Form.Label>
                    <FormattedMessage id="registration.overview.electronic_signature_input" />
                  </Form.Label>
                  {errors.electronicSignature && touched.electronicSignature && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.electronicSignature}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group>
                  <IconButton
                    type="submit"
                    disabled={isSubmitting || submitIoRegistrationResult.isLoading}
                    loading={submitIoRegistrationResult.isLoading}
                  >
                    <FormattedMessage id="registration.overview.button_next" />
                    <AdaptiveIcon
                      sm={<IconSubmit18 />}
                      lg={<IconSubmit24 />}
                    />
                  </IconButton>
                </Form.Group>

                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepOverviewUs.stepName = "registration_final";

RegistrationDialogStepOverviewUs.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepOverviewUs;
