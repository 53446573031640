import React, { useMemo, useState } from "react";

import classNames from "classnames";
import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setPaymentData } from "../../../features/registrationSlice";
import {
  IconError18,
  IconFillForm24,
  IconFurther18,
  IconFurther24,
} from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import AddressInput from "../../addressInput/addressInput";
import IconButton from "../../button/iconButton";
import CollapseBox from "../../collapseBox/collapseBox";
import CleaveFormField from "../../dynamicForm/cleaveFormField/cleaveFormField";
import FormField from "../../dynamicForm/formField/formField";
import RegistrationDataHelper from "../../registrationDialog/registrationDataHelper";
import RegistrationDialogHeadline from "../../registrationDialog/registrationDialogHeadline";

const PaymentDialogStepPersonalInformationPl = ({ next }) => {
  const paymentData = useSelector((state) => state.registration.paymentData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [addressShown, setAddressShown] = useState(paymentData.street !== "");

  const requiredMessage = intl.formatMessage({ id: "form.required" });
  const onlyAlphabeticMessage = intl.formatMessage({ id: "form.error.only_alphabetic_chars" });
  const emailMessage = intl.formatMessage({ id: "registration.additional.error.email" });
  const invalidValueMessage = intl.formatMessage({ id: "form.error.invalid_value" });

  const validationSchema = useMemo(() => object().shape({
    apartment: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(/^[^\u0600-\u06FF]*$/, invalidValueMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    careOfAddress: string()
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .matches(/^[^\u0600-\u06FF]*$/, invalidValueMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    city: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .required(requiredMessage)
      .trim()
      .matches(/^[^\u0600-\u06FF]*$/, invalidValueMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
    emailAddress: string()
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .email(emailMessage),
    firstname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(40, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 40,
        },
      ))
      .trim()
      .matches(/^[^\d\u0600-\u06FF]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    gender: string()
      .required(requiredMessage),
    houseNo: string()
      .required(requiredMessage)
      .max(10, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 10,
        },
      ))
      .matches(
        /^[0-9]+[a-zA-Z0-9 \-/]*$/,
        invalidValueMessage,
      ),
    lastname: string()
      .min(2, requiredMessage)
      .required(requiredMessage)
      .max(100, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 100,
        },
      ))
      .trim()
      .matches(/^[^\d\u0600-\u06FF]+$/, onlyAlphabeticMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, onlyAlphabeticMessage),
    postCode: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(10, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 10,
        },
      ))
      .matches(
        /^(\d{2}-\d{3})$/,
        intl.formatMessage({ id: "form.error.zipcode" }),
      ),
    street: string()
      .required(requiredMessage)
      .min(2, requiredMessage)
      .max(50, intl.formatMessage(
        { id: "form.error.max_length_exact" },
        {
          value: 50,
        },
      ))
      .trim()
      .matches(/^[^\u0600-\u06FF]*$/, invalidValueMessage)
      .matches(RegistrationDataHelper.noneEmojiRegex, invalidValueMessage),
  }), []);

  return (
    <>
      <RegistrationDialogHeadline
        headline={intl.formatMessage({ id: "payment_dialog.personal.title" })}
      />
      <Formik
        initialValues={paymentData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setPaymentData({
              apartment: values.apartment,
              careOfAddress: values.careOfAddress,
              city: values.city,
              emailAddress: values.emailAddress,
              firstname: values.firstname,
              gender: values.gender,
              houseNo: values.houseNo,
              lastname: values.lastname,
              postCode: values.postCode,
              street: values.street,
            }),
          );

          DataLayer.pushEvent("pp_donation_step_personal_information", { event_value: "success" });

          next();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className="typo-label required-sign"><FormattedMessage id="payment_dialog.please_select" /></div>
                <Form.Group>
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "form.sex.male" })}
                    name="gender"
                    value="M"
                    id="gender-male"
                    checked={values.gender === "M"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0", errors.gender && touched.gender ? "is-invalid" : "")}
                  />
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({ id: "form.sex.female" })}
                    name="gender"
                    value="F"
                    id="gender-female"
                    checked={values.gender === "F"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inline
                    className={classNames("mb-0", errors.gender && touched.gender ? "is-invalid" : "")}
                  />
                  {errors.gender && touched.gender && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.gender}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={(event) => {
                    setFieldTouched("gender", true);
                    handleChange(event);
                  }}
                  onBlur={(event) => {
                    setFieldTouched("gender", true);
                    handleBlur(event);
                  }}
                  id="firstname"
                  label={intl.formatMessage({ id: "registration.personal.firstname" })}
                  type="text"
                  maxLength={40}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12} lg={6}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="lastname"
                  label={intl.formatMessage({ id: "registration.personal.lastname" })}
                  type="text"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12}>
                <FormField
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="emailAddress"
                  label={intl.formatMessage({ id: "registration.additional.email" })}
                  type="email"
                  maxLength={100}
                  touched={touched}
                  errors={errors}
                  values={values}
                  required
                />
              </Col>

              <Col xs={12}>
                <AddressInput
                  onResult={(data) => {
                    setAddressShown(true);

                    setFieldValue("street", data.street || "");
                    setFieldValue("houseNo", data.houseno || "");
                    setFieldValue("postCode", data.zipcode || "");
                    setFieldValue("city", data.city || "");
                  }}
                />
              </Col>

              <Col xs={12}>
                <CollapseBox
                  opened={addressShown}
                  label={intl.formatMessage({ id: "address_lookup.fill_manually" })}
                  icon={<IconFillForm24 />}
                >
                  <Row>
                    <Col md={8}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="street"
                        label={intl.formatMessage({ id: "registration.address.street" })}
                        type="text"
                        maxLength={50}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="houseNo"
                        label={intl.formatMessage({ id: "registration.address.houseNo" })}
                        type="text"
                        maxLength={10}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="apartment"
                        label={intl.formatMessage({ id: "registration.address.apartment" })}
                        type="text"
                        maxLength={100}
                        touched={touched}
                        errors={errors}
                        values={values}
                      />
                    </Col>
                    <Col md={6}>
                      <CleaveFormField
                        label={intl.formatMessage({ id: "registration.address.postCode" })}
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        id="postCode"
                        errors={errors}
                        options={{
                          blocks: [2, 3],
                          delimiter: "-",
                          numericOnly: true,
                        }}
                        maxLength={10}
                        required
                      />
                    </Col>
                    <Col xs={12} className="mb-md-0">
                      <FormField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="city"
                        label={intl.formatMessage({ id: "registration.address.city" })}
                        type="text"
                        maxLength={50}
                        touched={touched}
                        errors={errors}
                        values={values}
                        required
                      />
                    </Col>
                  </Row>
                </CollapseBox>
              </Col>
              <ScrollToFieldError />

              <Col xs={12}>
                <Form.Group>
                  <IconButton
                    type="submit"
                    variant="donation"
                    onClick={() => setAddressShown(true)}
                  >
                    <FormattedMessage id="registration.personal.button_next" />
                    <AdaptiveIcon
                      sm={<IconFurther18 />}
                      lg={<IconFurther24 />}
                    />
                  </IconButton>
                </Form.Group>
                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

PaymentDialogStepPersonalInformationPl.stepName = "personal_information";

PaymentDialogStepPersonalInformationPl.propTypes = {
  next: PropTypes.func.isRequired,
};

export default PaymentDialogStepPersonalInformationPl;
