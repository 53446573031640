import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import ProgramTile from "./programTile";

const RelatedProgramTiles = (props) => {
  const {
    excludeId,
    categories,
  } = props;

  const data = useStaticQuery(graphql`
    {
      allKontentItemProgram {
        nodes {
          ...ProgramTile
        }
      }
    }
  `);

  let filteredData = [];
  data.allKontentItemProgram.nodes.forEach((node) => filteredData.push(node));

  filteredData = filteredData.filter((el) => {
    if (el.id === excludeId) {
      return false;
    }
    let filterObj = false;
    el.elements.program_category.value.forEach((cat) => {
      if (categories.includes(cat.codename)) {
        filterObj = true;
      }
    });

    return filterObj;
  });

  if (data.allKontentItemProgram) {
    return ProgramTile({ data: filteredData.slice(0, 10) });
  }

  return null;
};

RelatedProgramTiles.propTypes = {
  categories: PropTypes.oneOfType([PropTypes.array]),
  excludeId: PropTypes.string,
};

RelatedProgramTiles.defaultProps = {
  categories: [],
  excludeId: null,
};

export default RelatedProgramTiles;
