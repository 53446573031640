import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import KontentAssetsImage from "./kontentAssetsImage";
import NavigationLink from "../components/navigationLink/navigationLink";

const KontentItemImageButton = (props) => {
  const {
    data,
  } = props;

  const imageButton = data.value ? data.value[0] : data;

  if (!imageButton || !imageButton.elements) {
    return null;
  }

  return (
    <NavigationLink
      className="no-style img-button"
      href={imageButton.elements.url_extern.value}
      title={imageButton.elements.title.value}
    >
      <KontentAssetsImage
        height={40}
        data={imageButton.elements.image.value.length > 0
          ? imageButton.elements.image.value[0]
          : null}
      />
    </NavigationLink>
  );
};

export const query = graphql`
  fragment KontentItemImageButton on kontent_item_image_button {
    id
    system {
      codename
    }
    elements {
      image {
        value {
          url
          width
          type
          size
          name
          height
          description
        }
      }
      url_extern {
        value
      }
      title {
        value
      }
    }
  }
`;

KontentItemImageButton.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemImageButton;
