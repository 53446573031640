import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import DonationBoxDefault from "./donationBoxDefault";
import DonationBoxLinks from "./donationBoxLinks";
import DonationBoxToggle from "./donationBoxToggle";
import PaymentDialogStepSuccessSepa from "../paymentDialog/steps/paymentDialogStepSuccessSepa";

import "./donationBox.scss";

const getDonationBoxVariant = (variant, options) => {
  switch (variant) {
    case "links":
      return (<DonationBoxLinks {...options} />);
    case "toggle":
      return (<DonationBoxToggle {...options} />);
    default:
      return (<DonationBoxDefault {...options} />);
  }
};

const DonationBox = (props) => {
  const {
    className,
    title,
    text,
    contactLink,
    sepaAmount,
    variant: overwriteVariant,
    ...furtherProps
  } = props;

  const isSepa = useSelector((state) => state.registration.isSepa);

  const [showHeadline, setShowHeadline] = useState(true);

  let variant = "default";

  switch (process.env.GATSBY_SITE) {
    case "us":
    case "cl":
    case "in":
      variant = "links";
      break;
    case "za":
      variant = "toggle";
      break;
    default:
  }
  variant = overwriteVariant || variant;

  return (
    <div
      className={classNames(
        "donation-box",
        [`donation-box--${variant}`],
        className,
        { "d-flex align-self-center h-100 mb-0": isSepa },
      )}
    >
      <div className="donation-box__inner">
        {isSepa
          ? (<PaymentDialogStepSuccessSepa contactLink={contactLink} />)
          : (
            <>
              {showHeadline && (
                <>
                  {title && (
                    <div className="donation-box__title">
                      {title}
                    </div>
                  )}
                  {text && (
                    <div className="donation-box__text">
                      {text}
                    </div>
                  )}
                </>
              )}
              {getDonationBoxVariant(variant, {
                ...furtherProps,
                showHeadline: setShowHeadline,
              })}
            </>
          )}
      </div>
    </div>
  );
};

DonationBox.propTypes = {
  ...DonationBoxLinks.propTypes,
  ...DonationBoxToggle.propTypes,
  ...DonationBoxDefault.propTypes,
  className: PropTypes.string,
  contactLink: PropTypes.string.isRequired,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["default", "links", "toggle"]),
};

DonationBox.defaultProps = {
  ...DonationBoxLinks.defaultProps,
  ...DonationBoxToggle.defaultProps,
  ...DonationBoxDefault.defaultProps,
  className: "",
  text: undefined,
  variant: undefined,
};

export default DonationBox;
