import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import KontentItemImage from "../../templates/kontentItemImage";

import "./heroHeadline.scss";

const HeroHeadline = (props) => {
  const {
    children,
    imageData,
    eyecatcher,
    buttons,
    tabNavigation,
  } = props;

  const hasButtons = buttons && (
    (Array.isArray(buttons) && buttons.length > 0) || !Array.isArray(buttons)
  );

  return (
    <div className="container--fullwidth">
      <div
        className={classNames(
          "hero-headline",
          {
            "hero-headline--buttons": hasButtons && eyecatcher === null,
            "hero-headline--eyecatcher": eyecatcher !== null,
            "hero-headline--tab-navigation": tabNavigation !== null,
          },
        )}
      >
        <div className="hero-headline__gradient">
          <Container fluid>
            <Row>
              <Col md={10} lg={9}>
                <h1 className={classNames({ "typo-h2": tabNavigation }) || undefined}>{children}</h1>
                {hasButtons && eyecatcher === null && <div className="hero-headline--button-container">{buttons}</div>}
              </Col>
            </Row>
            {tabNavigation}
          </Container>
        </div>
        <KontentItemImage data={imageData} width={1920} height={660} imageOnlyWidth className="img-fluid" />
      </div>
      {eyecatcher !== null && (
        <Container fluid className="hero-headline__eyecatcher-container">
          {eyecatcher}
        </Container>
      )}
    </div>
  );
};

HeroHeadline.propTypes = {
  buttons: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.node.isRequired,
  eyecatcher: PropTypes.oneOfType([PropTypes.object]),
  imageData: PropTypes.oneOfType([PropTypes.object]),
  tabNavigation: PropTypes.oneOfType([PropTypes.object]),
};

HeroHeadline.defaultProps = {
  buttons: null,
  eyecatcher: null,
  imageData: null,
  tabNavigation: null,
};

export default HeroHeadline;
