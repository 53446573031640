import React from "react";

import { FormattedMessage } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";

import ArticleHeader from "./articleHeader";

const ArticleHeaderPressRelease = (props) => {
  const {
    title,
    description,
    infoSection,
    image,
    ...itemProps
  } = props;

  return (
    <ArticleHeader
      description={description}
      title={title}
      infoSection={infoSection}
      tag={<span className="typo-label text-primary"><FormattedMessage id="article_teaser.press_release" /></span>}
      backgroundImageStyle={image}
      {...itemProps}
    />
  );
};

ArticleHeaderPressRelease.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  infoSection: PropTypes.node,
  title: PropTypes.string.isRequired,
};

ArticleHeaderPressRelease.defaultProps = {
  image: null,
  infoSection: null,
};

export default ArticleHeaderPressRelease;
