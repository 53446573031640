import React, { useEffect, useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { bool, object } from "yup";

import RegistrationDialogStepError from "./registrationDialogStepError";
import { useSubmitIoRegistrationMutation } from "../../../features/io/ioRegistrationApiSlice";
import { setRegistrationData, setStepError, setSuccess } from "../../../features/registrationSlice";
import usePrevious from "../../../hooks/usePrevious";
import useURNParams from "../../../hooks/useURNParams";
import { IconSubmit18, IconSubmit24 } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import { dateFactory, format } from "../../../utils/dateHelper";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import AdaptiveIcon from "../../adaptiveIcon";
import IconButton from "../../button/iconButton";
import Checkbox from "../../dynamicForm/checkbox/checkbox";
import RegistrationDataHelper from "../registrationDataHelper";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepOverviewPl = ({ content, next }) => {
  const registrationData = useSelector((state) => state.registration.registrationData);
  const lastSuccess = useSelector((state) => state.registration.lastSuccess);
  const stepError = useSelector((state) => state.registration.stepError);
  const dispatch = useDispatch();

  const intl = useIntl();
  const urnParams = useURNParams();

  const [submitIoRegistration, submitIoRegistrationResult] = useSubmitIoRegistrationMutation({ fixedCacheKey: "submitIoRegistrationCacheKey" });

  DataLayer.currentStep = "registration_final";

  const prevSubmitIoRegistrationResult = usePrevious(submitIoRegistrationResult);

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    consent: bool()
      .required(requiredMessage)
      .oneOf([true], requiredMessage),
  }), []);

  useEffect(() => {
    if (submitIoRegistrationResult.isSuccess && !prevSubmitIoRegistrationResult.isSuccess) {
      DataLayer.removeUnloadListener();
      DataLayer.pushEvent("pp_registration_step_final", { event_value: "success" });
      DataLayer.pushEvent("pp_registration_step_final_urn", { event_value: urnParams?.refs });
      DataLayer.pushEvent("ppe_registration_success_goal");

      dispatch(
        setSuccess(),
      );

      if (content.successPage) {
        window.skipBeforeUnload = true;
        window.location.href = content.successPage;
      } else {
        next();
      }
    }

    if (submitIoRegistrationResult.isError && !prevSubmitIoRegistrationResult?.isError) {
      DataLayer.pushEvent("pp_registration_step_final", { event_value: "fail" });
    }

    if (submitIoRegistrationResult.isError || prevSubmitIoRegistrationResult?.isError) {
      dispatch(
        setStepError(true),
      );
    }
  }, [submitIoRegistrationResult]);

  if (stepError) {
    return (
      <RegistrationDialogStepError
        content={content}
        error={submitIoRegistrationResult.error}
      />
    );
  }

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.overview.title"
      />
      <Row className="mb-36">
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.personal_details" />
          </div>
          <div className="typo-body2">
            {registrationData.gender === "M" ? (
              <FormattedMessage id="form.sex.male" />
            ) : (
              <FormattedMessage id="form.sex.female" />
            )} {registrationData.firstname} {registrationData.lastname}<br />
            {format(dateFactory(registrationData.birthdate))}, {registrationData.placeOfBirth}
            <br /><br />
            {registrationData.weightMetric} kg<br />
            {registrationData.heightMetric} cm
          </div>
        </Col>
        <Col xs={6} className="registration-dialog-step__overview-group">
          <div className="registration-dialog-step__section-title">
            <FormattedMessage id="registration.overview.contact_data" />
          </div>
          <div className="typo-body2">
            {registrationData.street} {registrationData.houseNo}
            {registrationData.apartment && (
              <> / {registrationData.apartment}</>
            )}<br />
            {registrationData.postCode} {registrationData.city}
            <br /><br />
            {registrationData.emailAddress}<br />
            {registrationData.mobileNumber}
          </div>
        </Col>
      </Row>

      <Formik
        initialValues={{
          consent: false,
          consentDeclaration: false,
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          // Prevent submitting if last successful submit was less than 60 seconds ago.
          if (((new Date()).getTime() - lastSuccess) / 1000 < 60) {
            dispatch(
              setStepError(true),
            );

            return;
          }

          if (!submitIoRegistrationResult.isSuccess) {
            submitIoRegistration({
              data: RegistrationDataHelper.preparePayload(registrationData),
              urns: urnParams,
            });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              {content && content.overviewText && (
                <Col xs={12} className="registration-dialog-step__overview-text">
                  {content.overviewText}
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12}>
                <Checkbox
                  id="consent"
                  name="consent"
                  checked={values.consent}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                  isInvalid={(errors.consent && touched.consent)}
                  label={intl.formatMessage(
                    { id: "registration.overview.consent" },
                    {
                      link: (
                        <a
                          href="/regulamin"
                          target="_blank"
                          rel="noopener noreferrer"
                          title={intl.formatMessage({ id: "registration.overview.consent_link" })}
                        >
                          <FormattedMessage id="registration.overview.consent_link" />
                        </a>
                      ),
                    },
                  )}
                  errors={errors}
                  feedback={errors.consent}
                  className="checkbox-required"
                  small
                  required
                />
              </Col>
            </Row>
            <Row>
              {(content.optinHeadline || content.optinText) && (
                <>
                  <Col xs={12}>
                    <div className="registration-dialog-step__overview-text">
                      <div className="text--simple">
                        {content.optinHeadline && (
                          <p>
                            <strong>{content.optinHeadline}</strong>
                          </p>
                        )}
                        {content.optinText && (
                          <p>
                            {content.optinText}
                          </p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <Checkbox
                      id="consentDeclaration"
                      name="consentDeclaration"
                      checked={values.consentDeclaration}
                      onChange={(event) => {
                        dispatch(
                          setRegistrationData({ keepInTouchEmail: event.target.checked ? "YES" : "" }),
                        );

                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      isInvalid={(errors.consentDeclaration && touched.consentDeclaration)}
                      label={intl.formatMessage({ id: "registration.consent_declaration_email" })}
                      small
                    />
                  </Col>
                </>
              )}
              <Col xs={12} className="registration-dialog-step__text-revocation">
                {content.revocation}

                <ScrollToFieldError />

                <Form.Group>
                  <IconButton
                    type="submit"
                    disabled={isSubmitting || submitIoRegistrationResult.isLoading}
                    loading={submitIoRegistrationResult.isLoading}
                  >
                    <FormattedMessage id="registration.overview.button_next" />
                    <AdaptiveIcon
                      sm={<IconSubmit18 />}
                      lg={<IconSubmit24 />}
                    />
                  </IconButton>
                </Form.Group>
                <div className="footnote--required">
                  <span className="required-sign" />
                  <FormattedMessage id="registration.required_fields" />
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

RegistrationDialogStepOverviewPl.stepName = "registration_final";

RegistrationDialogStepOverviewPl.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepOverviewPl;
