import { useSelector } from "react-redux";

const useURNParams = () => {
  const secondaryUrn = useSelector((state) => state.tracking.secondaryURN);
  const urn = useSelector((state) => state.tracking.URN || state.tracking.urlURN);

  if (
    (!urn || urn.trim() === "")
    && (!secondaryUrn || secondaryUrn.trim() === "")
  ) {
    return null;
  }

  const refs = [];

  if (urn) {
    refs.push(urn.trim());
  }

  if (secondaryUrn) {
    refs.concat(secondaryUrn.split("\n").map((item) => item.trim()));
  }

  return { refs };
};

export default useURNParams;
