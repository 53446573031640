import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import ArticleTeaser from "../../components/articleTeaser/articleTeaser";
import KontentItemImage from "../kontentItemImage";

const PressReleaseTeaserCard = ({ data, author }) => {
  let articleData = Array.isArray(data) ? data : data.value;

  const articleList = [];

  if (author) {
    articleData = articleData.filter((item) => (
      item.elements.author_name.value.length > 0
      && item.elements.author_name.value[0].codename === author));
  }

  articleData.forEach((news) => {
    articleList.push(
      <ArticleTeaser
        variant="press-release"
        headline={news.elements.headline.value}
        url={news.id}
        category={
          news.elements.news_category.value.length > 0
            ? news.elements.news_category.value[0].name
            : null
        }
        image={(
          <KontentItemImage
            data={news.elements.image}
            width={240}
            height={240}
            fit="crop"
          />
        )}
        date={new Date(news.elements.publishdate.value)}
      />,
    );
  });

  return React.Children.toArray(articleList);
};

export const query = graphql`
  fragment PressReleaseTeaserCard on kontent_item_article_press {
    id
    system {
      codename
    }
    elements {
      author_name {
        value {
          codename
        }
      }
      headline {
        value
      }
      news_category {
        value {
          codename
          name
        }
      }
      image {
        value {
          ...KontentItemImage
        }
      }
      publishdate {
        value
      }
    }
  }
`;

PressReleaseTeaserCard.propTypes = {
  author: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

PressReleaseTeaserCard.propTypes = {
  author: null,
};

export default PressReleaseTeaserCard;
