import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import MoneyPartition from "../../components/moneypartition/moneyPartition";
import KontentItemImage from "../kontentItemImage";

const KontentItemMoneyPartition = ({ data }) => {
  const moneyPartition = data?.value?.length > 0 ? data.value[0] : data;

  if (!moneyPartition || !moneyPartition.elements) {
    return null;
  }

  const accordionItems = [];

  for (let i = 1; i <= 4; i += 1) {
    if (moneyPartition.elements[`accordion_title_${i}`].value.length > 0) {
      accordionItems.push({
        amount: moneyPartition.elements[`amount_${i}`].value,
        image: (
          <KontentItemImage
            data={moneyPartition.elements[`image_${i}`]}
            width={700}
          />
        ),
        subheadline: moneyPartition.elements[`subheading_${i}`].value,
        text: moneyPartition.elements[`text_${i}`].value,
        title: moneyPartition.elements[`accordion_title_${i}`].value,
      });
    }
  }

  return (
    <MoneyPartition
      title={moneyPartition.elements.headline.value}
      accordionItems={accordionItems}
    />
  );
};

export const query = graphql`
  fragment KontentItemMoneyPartition on kontent_item_money_partition {
    id
    elements {
      headline {
        value
      }
      accordion_title_1 {
        value
      }
      accordion_title_2 {
        value
      }
      accordion_title_3 {
        value
      }
      accordion_title_4 {
        value
      }
      amount_1 {
        value
      }
      amount_2 {
        value
      }
      amount_3 {
        value
      }
      amount_4 {
        value
      }
      text_1 {
        value
      }
      text_2 {
        value
      }
      text_3 {
        value
      }
      text_4 {
        value
      }
      subheading_1 {
        value
      }
      subheading_2 {
        value
      }
      subheading_3 {
        value
      }
      subheading_4 {
        value
      }
      image_1 {
        value {
          ...KontentItemImage
        }
      }
      image_2 {
        value {
          ...KontentItemImage
        }
      }
      image_3 {
        value {
          ...KontentItemImage
        }
      }
      image_4 {
        value {
          ...KontentItemImage
        }
      }
    }
  } 
`;

KontentItemMoneyPartition.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemMoneyPartition;
