import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import CampaignCard from "./campaigns/campaignCard";
import LastFourCampaignCards from "./campaigns/lastFourCampaignCards";
import Campaigns from "../components/campaigns/campaigns";

const KontentItemCampaignsSection = ({ data }) => {
  const campaignsSection = data.value[0];

  if (!campaignsSection || !campaignsSection.elements) {
    return null;
  }

  let output = CampaignCard({ data: campaignsSection.elements.linked_campaigns });

  if (output.length < 4) {
    const latestCampaigns = LastFourCampaignCards();

    // Remove duplicates
    output = output.concat(latestCampaigns);
    const tmpOutput = [];
    output.forEach((el) => {
      if (!tmpOutput.some((el2) => el.key === el2.key)) {
        tmpOutput.push(el);
      }
    });

    output = tmpOutput.slice(0, 4);
  }

  return (
    <Campaigns
      headline={campaignsSection.elements.title.value}
      description={campaignsSection.elements.description.value}
      moreUrl={campaignsSection.elements.button_url.value.length > 0
        ? campaignsSection.elements.button_url.value[0].id
        : null}
    >
      {output.map((o) => o)}
    </Campaigns>
  );
};

export const query = graphql`
  fragment KontentItemCampaignsSection on kontent_item_campaigns_section {
    id
    system {
      codename
    }
    elements {
      description {
        value
      }
      title {
        value
      }
      linked_campaigns {
        value {
          ...CampaignCard
        }
      }
      button_url {
        value {
          id
        }
      }
    }
  }
`;

KontentItemCampaignsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemCampaignsSection;
