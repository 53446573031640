import React, { useState } from "react";

import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { setError, setRegistrationData } from "../../../features/registrationSlice";
import DataLayer from "../../../utils/dataLayer";
import Button from "../../button/button";
import RegistrationDialogHeadline from "../registrationDialogHeadline";

const RegistrationDialogStepEligibilityRegistered = ({ content, next }) => {
  const error = useSelector((state) => state.registration.error);
  const dispatch = useDispatch();

  const intl = useIntl();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = (eligible) => {
    setIsSubmitting(true);

    dispatch(
      setRegistrationData({ eligible }),
    );

    if (!eligible) {
      setIsSubmitting(false);
      dispatch(
        setError(true),
      );
    } else {
      next();
    }
  };

  DataLayer.currentStep = "eligibility_registered";

  if (error) {
    return (
      <>
        <RegistrationDialogHeadline
          headline="registration.first_registration.error.title"
        />
        <Row>
          <Col sm={10}>

            {intl.formatMessage({ defaultMessage: " ", id: "registration.first_registration.error.text1" }) !== " " && (
              <p>
                <FormattedMessage id="registration.first_registration.error.text1" />
              </p>
            )}

            <p>
              <FormattedMessage id="registration.first_registration.error.text2" />
            </p>

            {content.donationBox}

            {!content.donationBox && content.errorButtonPrimary}

            {content.errorButtonSecondary}
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <RegistrationDialogHeadline
        headline="registration.first_registration.title"
      />
      <Row>
        <Col xs={12}>
          <Form.Group className="form-group-button">
            <Button
              onClick={() => {
                DataLayer.pushEvent("pp_registration_step_eligibility_registered", { event_value: "success" });
                submit(true);
              }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="registration.first_registration.button_success" />
            </Button>
          </Form.Group>
          <Form.Group className="form-group-button">
            <Button
              onClick={() => {
                DataLayer.pushEvent("pp_registration_step_eligibility_registered", { event_value: "fail" });
                submit(false);
              }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="registration.first_registration.button_fail" />
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

RegistrationDialogStepEligibilityRegistered.stepName = "eligibility_registered";

RegistrationDialogStepEligibilityRegistered.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default RegistrationDialogStepEligibilityRegistered;
