import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { bool, object } from "yup";

import { setPaymentData } from "../../../features/registrationSlice";
import { IconFurther18, IconGiftAid } from "../../../icons";
import DataLayer from "../../../utils/dataLayer";
import IconButton from "../../button/iconButton";
import RegistrationDialogHeadline from "../../registrationDialog/registrationDialogHeadline";

const PaymentDialogStepGiftAid = ({ next }) => {
  const paymentData = useSelector((state) => state.registration.paymentData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const giftAidDonation = (parseFloat(paymentData.amount) * 1.25) % 1 === 0
    ? parseFloat(paymentData.amount) * 1.25
    : (Math.floor(parseFloat(paymentData.amount) * 125).toFixed(2) / 100);

  const validationSchema = useMemo(() => object().shape({
    agreeGiftAid: bool(),
  }), []);

  return (
    <Row className="payment-dialog__gift-aid">
      <Col sm={10}>
        <IconGiftAid />
        <RegistrationDialogHeadline
          headline={intl.formatMessage(
            { id: "payment_dialog.gift_aid_title" },
            { interval: paymentData.interval !== "ONCE" ? intl.formatMessage({ id: `payment_dialog.${paymentData.interval.toLowerCase()}` }).toLowerCase() : "" },
          )}
        />
      </Col>
      <Col sm={9}>
        <div className="payment-dialog__gift-aid-calculator">
          <div className="payment-dialog__calculation">
            <div>
              <div className="typo-title1">
                <FormattedMessage id="currency.amount" values={{ amount: paymentData.amount }} />
              </div>
              <div className="payment-dialog__calculation-description">
                <FormattedMessage id="payment_dialog.from_you" />
              </div>
            </div>
            <div className="typo-title1">+</div>
            <div>
              <div className="typo-title1">
                <FormattedMessage id="payment_dialog.giftAid_percent" />
              </div>
              <div className="payment-dialog__calculation-description">
                <FormattedMessage id="payment_dialog.giftAid" />
              </div>
            </div>
            <div className="typo-title1">=</div>
            <div>
              <div className="typo-title1">
                <FormattedMessage id="currency.amount" values={{ amount: giftAidDonation }} />
              </div>
              <div className="payment-dialog__calculation-description">
                <FormattedMessage id="payment_dialog.donation" />
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col sm={10}>
        <Formik
          validationSchema={validationSchema}
          initialValues={paymentData}
          onSubmit={(values, formikBag) => {
            formikBag.setSubmitting(true);

            dispatch(
              setPaymentData({
                agreeGiftAid: values.agreeGiftAid,
                giftAidAmount: giftAidDonation,
              }),
            );

            DataLayer.pushEvent("pp_donation_step_giftaid", { event_value: "success" });

            next();
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xs={12}>
                  <Form.Check
                    id="agreeGiftAid"
                    name="agreeGiftAid"
                    type="checkbox"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={<FormattedMessage id="payment_dialog.aid_agreement" />}
                    checked={values.agreeGiftAid}
                  />

                </Col>
                <Col xs={12} className="payment-dialog__gift-aid-footnote">
                  <FormattedMessage id="payment_dialog.aid_footnote" />
                </Col>
                <Col xs={12}>
                  <IconButton
                    variant="donation"
                    type="submit"
                  >
                    <FormattedMessage id="virtual_drive_setup.next" /> <IconFurther18 />
                  </IconButton>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
  );
};

PaymentDialogStepGiftAid.stepName = "payment_giftaid";

PaymentDialogStepGiftAid.propTypes = {
  next: PropTypes.func.isRequired,
};

export default PaymentDialogStepGiftAid;
