import React from "react";

import max from "date-fns/max";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import DkmsNumbersList from "../../components/dkmsNumbersList/dkmsNumbersList";
import FactSheetHeader from "../../components/factSheetHeader/factSheetHeader";
import FileDownloadList from "../../components/fileDownloadList/fileDownloadList";
import SEO from "../../components/seo";
import mapGlobalKontentItem from "../../utils/mapGlobalKontentItem";
import KontentItemAsset from "../kontentItemAsset";
import KontentItemFactSheetHeaderData from "../kontentItemFactSheetHeaderData";
import MainLayout from "../layout/mainLayout";
import BreadcrumbGenerator from "../navigation/breadcrumbGenerator";
import SubNavigationGenerator from "../navigation/subNavigationGenerator";
import RichText from "../text/richText";

const KontentItemPressFactSheet = ({ data }) => {
  const { page } = data;

  const lastModifiedDate = max(data.globalsNumberDate.nodes.map(
    (child) => new Date(child.system.last_modified),
  ));

  return (
    <MainLayout>

      <SEO
        title={page.elements.metadata__meta_title.value}
        description={page.elements.metadata__meta_description.value}
        twitterTitle={page.elements.metadata__twitter_title.value}
        twitterSite={page.elements.metadata__twitter_site.value}
        twitterImage={page.elements.metadata__twitter_image.value}
        twitterDescription={page.elements.metadata__twitter_description.value}
        twitterCreator={page.elements.metadata__twitter_creator.value}
        ogTitle={page.elements.metadata__og_title.value}
        ogImage={page.elements.metadata__og_image.value}
        ogDescription={page.elements.metadata__og_description.value}
      />

      <SubNavigationGenerator id={page.id} />

      <FactSheetHeader headline={page.elements.headline.value}>
        {page.elements.fact_sheet_data.value.map((fact) => (
          <KontentItemFactSheetHeaderData data={fact} key={fact.id} />
        ))}
      </FactSheetHeader>

      <BreadcrumbGenerator id={page.id} />

      <RichText data={page.elements.body} isArticle />

      <FileDownloadList headline={page.elements.headline_downloads.value}>
        {page.elements.downloads.value.map((asset) => (
          <KontentItemAsset data={asset} key={asset.id} />
        ))}
      </FileDownloadList>

      <DkmsNumbersList
        headline={page.elements.headline_numbers.value}
        lastModifiedDate={lastModifiedDate}
      >
        {mapGlobalKontentItem(page.elements.dkms_numbers_global.value)}
      </DkmsNumbersList>

    </MainLayout>
  );
};

export const query = graphql`
  query ($navigationId: String!, $id: String!) {
    navigationItem: kontentItemNavigationItem(id: {eq: $navigationId}) {
      elements {
        url_slug {
          value
        }
        title {
          value
        }
      }
    }
    globalsNumberDate: allKontentItemGlobalDkmsFigures {
      nodes {
        system {
          last_modified(formatString: "YYYY-MM-DD[T]HH:mm:ss")
        }
      }
    }
    page: kontentItemPressFactSheet(id: {eq: $id}) {
      id
      elements {
        metadata__meta_description {
          value
        }
        metadata__twitter_title {
          value
        }
        metadata__twitter_site {
          value
        }
        metadata__twitter_image {
          value {
            url
          }
        }
        metadata__twitter_description {
          value
        }
        metadata__twitter_creator {
          value
        }
        metadata__og_title {
          value
        }
        metadata__og_image {
          value {
            url
          }
        }
        metadata__og_description {
          value
        }
        metadata__meta_title {
          value
        }
        headline {
          value
        }
        fact_sheet_data {
          value {
            ...KontentItemFactSheetHeaderData
          }
        }
        headline_downloads {
          value
        }
        downloads {
          value {
            ...KontentItemAsset
          }
        }
        headline_numbers {
          value
        }
        dkms_numbers_global {
          value
        }
        body {
          value
          images {
            width
            url
            image_id
            height
            description
          }
          links {
            link_id
            codename
            type
            url_slug
          }
          type
          name
          modular_content {
            id
            system {
              codename
            }
            internal {
              type
            }
            ...KontentItemLinkCard
            ...KontentItemImage
            ...KontentItemVideoEmbed
            ...KontentItemLinkCardGroup
            ...KontentItemQuote
            ...KontentItemAccordion
            ...KontentItemImageGrid
            ...KontentItemMediaGallery
            ...KontentItemArticlesEmbed
            ...KontentItemRelatedLearnContentEmbed
            ...KontentItemEyecatcher
            ...KontentItemEyecatcherRegistration
            ...KontentItemEyecatcherDonation
            ...KontentItemEyecatcherDonate
            ...KontentItemEyecatcherContact
            ...KontentItemDownloadGroup
            ...KontentItemFaqSection
            ...KontentItemImageCardsGroup
            ...KontentItemEmbedFacebookPost
            ...KontentItemEmbedInstagramPost
            ...KontentItemTweet
            ...KontentItemEmbedExternal
          }
        }
      }
    }
  }
`;

KontentItemPressFactSheet.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default KontentItemPressFactSheet;
