import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import InfoWindow from "./infoWindow";

import "./marker.scss";

const Marker = (props) => {
  const {
    count,
    show,
    small,
    cluster,
    entries,
    onClick,
    variant,
    id,
  } = props;

  return (
    <>
      <div
        id={id}
        className={classNames(
          "marker",
          {
            "marker--active": show,
            "marker--cluster": cluster || (variant === "location" && entries.length > 1),
            "marker--small": small,
            [`marker--${variant}`]: variant,
          },
        )}
        onClick={onClick}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onClick();
            event.stopPropagation();
          }
        }}
        role="button"
        tabIndex="0"
      >
        {(cluster || (variant === "location" && entries.length > 1)) && (
          <>{count > 99 || entries.length > 99 ? "99+" : count || entries.length}</>
        )}
      </div>
      {show && <InfoWindow entries={entries} variant={variant} />}
    </>
  );
};

Marker.propTypes = {
  cluster: PropTypes.bool,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  entries: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
  })),
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  show: PropTypes.bool,
  small: PropTypes.bool,
  variant: PropTypes.oneOf(["event", "location"]),
};

Marker.defaultProps = {
  cluster: false,
  count: null,
  entries: [],
  onClick: null,
  show: false,
  small: false,
  variant: "event",
};

export default Marker;
