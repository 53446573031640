import React, { useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import InfoNavigation from "./infoNavigation";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { IconGlobe18, IconMore18, IconSearchTeaser18 } from "../../icons";
import { kebabCase } from "../../utils/stringUtilHelper";
import Logo from "../logo";
import NavigationLink from "../navigationLink/navigationLink";

const SecondaryNavigation = (props) => {
  const {
    isAsia,
    isGroup,
    searchOnClick,
    allWebsiteOnClick,
  } = props;

  const { width } = useWindowDimensions();
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    {
      secondaryNavigation: kontentItemNavigationItem(system: {codename: {eq: "secondary_navigation"}}) {
        id
        elements {
          elements {
            value {
              id
              preferred_language
              ... on kontent_item_navigation_item {
                id,
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                }
              }
            }
          }
        }
      }
      infoNavigation: kontentItemNavigationItem(system: {codename: {eq: "info_navigation"}}) {
        id
        elements {
          title {
            value
          }
          icon {
            value {
              codename
            }
          }
          elements {
            value {
              id
              typename: __typename
              preferred_language
              ... on kontent_item_navigation_item {
                id
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                }
              }
            }
          }
        }
      }
      infoNavigation2: kontentItemNavigationItem(system: {codename: {eq: "info_navigation_2"}}) {
        id
        elements {
          title {
            value
          }
          icon {
            value {
              codename
            }
          }
          elements {
            value {
              id
              typename: __typename
              preferred_language
              ... on kontent_item_navigation_item {
                id
                elements {
                  title {
                    value
                  }
                  url_slug {
                    value
                  }
                  external_url {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const navbarRef = useRef(null);
  const navInnerRef = useRef(null);

  const [showNavigationItems, setShowNavigationItems] = useState([
    { id: "top-navigation__all-websites-label", show: true },
    { id: "top-navigation__search-label", show: true },
    ...data.secondaryNavigation.elements.elements.value.map(
      (item) => (
        {
          id: `top-navigation__${kebabCase(item.elements.title.value)}`,
          show: true,
        }),
    ).reverse(),
  ]);

  const [navigationItemsWidth, setNavigationItemsWidth] = useState([
    { id: "top-navigation__all-websites-label", width: 0 },
    { id: "top-navigation__search-label", width: 0 },
    ...data.secondaryNavigation.elements.elements.value.map(
      (item) => (
        {
          id: `top-navigation__${kebabCase(item.elements.title.value)}`,
          width: 0,
        }),
    ).reverse(),
  ]);

  const [navInnerWidth, setNavInnerWidth] = useState(0);

  const checkLabel = () => {
    if (navbarRef.current && navInnerRef.current) {
      const navbarWidth = navbarRef.current.getBoundingClientRect().width;

      // From JAD-18847:
      // Add 100 for buffer because scrollwidth is provided incorrectly by the browser.
      // Because the old overwritten font size is used during the first load.
      if (navbarWidth <= navInnerWidth + 100) {
        const tmpShowLabels = [...showNavigationItems];
        let tmpWidth = navInnerWidth;

        tmpShowLabels.forEach((item, i) => {
          // add more icon width 48
          if (navbarWidth <= tmpWidth + 48) {
            tmpWidth -= navigationItemsWidth[i]?.width;

            tmpShowLabels[i].show = false;
          } else {
            tmpShowLabels[i].show = true;
          }
        });

        setShowNavigationItems(tmpShowLabels);
      }
    }
  };

  useEffect(() => {
    if (navInnerRef.current) {
      setNavigationItemsWidth([...navigationItemsWidth.map((item) => {
        const navItem = document.getElementById(item.id);

        if (navItem) {
          return {
            id: item.id,
            width: (navItem.getBoundingClientRect().width * 0.9),
          };
        }

        return null;
      })]);
      setNavInnerWidth(navInnerRef.current.scrollWidth);
    }

    checkLabel();
  }, []);

  useEffect(() => {
    if (navigationItemsWidth.every((el) => el.width === 0) || !navInnerRef) {
      return;
    }

    checkLabel();
  }, [navigationItemsWidth, width]);

  const secondaryNavigation = [];
  const dropdownItems = [];

  if (data.secondaryNavigation) {
    data.secondaryNavigation.elements.elements.value.forEach((item) => {
      const navigationItem = showNavigationItems.find((el) => el.id === `top-navigation__${kebabCase(item.elements.title.value)}`);

      if (navigationItem.show) {
        secondaryNavigation.push((
          <div
            className="nav-item"
            key={item.elements.title.value}
            id={`top-navigation__${kebabCase(item.elements.title.value)}`}
          >
            <NavigationLink {...item} className="nav-link" />
          </div>
        ));
      } else {
        dropdownItems.push((
          <React.Fragment key={item.elements.title.value}>
            <NavigationLink {...item} className="dropdown-item typo-small-label" />
          </React.Fragment>
        ));
      }
    });
  }

  return (
    <Navbar expand="md" className="top-navigation nav-audience-utilities d-none d-lg-block">
      <Navbar.Collapse>
        <div className="d-none d-md-flex">
          <a href="/" title={intl.formatMessage({ id: "navigation.logo_title" })}>
            <Logo breakpoint="lg" />
          </a>
        </div>
        <Nav className="w-100" ref={navbarRef}>
          <div className="ml-auto d-none d-lg-flex" ref={navInnerRef}>
            {secondaryNavigation}

            {dropdownItems.length > 0 && (
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link}>
                  <IconMore18 className="ml-0" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dropdownItems}
                </Dropdown.Menu>
              </Dropdown>
            )}

            <InfoNavigation renderDropdown data={data.infoNavigation} isGroup={isGroup} />
            <InfoNavigation renderDropdown data={data.infoNavigation2} isGroup={isGroup} />

            {!isAsia && (
              <button
                className="search-toggle top-navigation__delimiter"
                onClick={searchOnClick}
                type="button"
                title={intl.formatMessage({ id: "navigation.menu.search" })}
              >
                {showNavigationItems.find((item) => item.id === "top-navigation__search-label")?.show && (
                  <span id="top-navigation__search-label">
                    <FormattedMessage id="navigation.menu.search" />
                  </span>
                )}
                <IconSearchTeaser18 />
              </button>
            )}

            <button
              className={classNames("all-websites-toggle top-navigation__delimiter pr-0", { "border-0": isAsia })}
              onClick={allWebsiteOnClick}
              type="button"
              aria-label={intl.formatMessage({ id: "navigation.dkms_websites" })}
            >
              {showNavigationItems.find((item) => item.id === "top-navigation__all-websites-label")?.show && (
                <span id="top-navigation__all-websites-label">
                  <FormattedMessage id="navigation.dkms_websites" />
                </span>
              )}
              <IconGlobe18 />
            </button>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

SecondaryNavigation.propTypes = {
  allWebsiteOnClick: PropTypes.func.isRequired,
  isAsia: PropTypes.bool,
  isGroup: PropTypes.bool,
  searchOnClick: PropTypes.func.isRequired,
};

SecondaryNavigation.defaultProps = {
  isAsia: false,
  isGroup: false,
};

export default SecondaryNavigation;
