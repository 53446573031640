import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import Button from "../button/button";

import "./toggleButton.scss";

const ToggleButton = (props) => {
  const {
    children,
    className,
    initialState,
    onClick,
    variant,
    value,
    name,
    id,
    checked,
    onChange,
    ...inputProps
  } = props;

  return (
    <div
      className={classNames("toggle-button", className)}
      onClick={onClick}
      role="presentation"
    >
      <input
        id={id}
        name={name}
        value={value}
        type="radio"
        checked={checked}
        onChange={onChange}
        {...inputProps}
      />
      <label htmlFor={id}>
        <Button
          variant={variant}
        >
          {children}
        </Button>
      </label>
    </div>
  );
};

ToggleButton.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  initialState: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.oneOf([
    "registration",
    "donation",
    "learn-area",
    "light",
    "dark",
    "events",
    "faq",
    "black",
    "grey",
  ]),
};

ToggleButton.defaultProps = {
  checked: false,
  children: null,
  className: "",
  id: null,
  initialState: false,
  name: null,
  onChange: () => {},
  onClick: () => {},
  value: "",
  variant: "registration",
};

export default ToggleButton;
