import React, { useEffect, useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import parse from "html-react-parser";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import sanitizeHtml from "sanitize-html";

import LinkButton from "../../components/button/linkButton";
import TimeoutButton from "../../components/button/timeoutButton";
import ContentHeader from "../../components/contentHeader/contentHeader";
import SEO from "../../components/seo";
import { useSubmitDoiConfirmationMutation } from "../../features/io/ioDoiConfirmationApiSlice";
import { HeaderIconThankYouThumbs } from "../../images";
import { notificationService } from "../../services/notification.service";
import getInternalLinkById from "../../utils/getInternalLinkById";
import MainLayout from "../layout/mainLayout";

const DoiConfirmationPage = () => {
  const { settings } = useStaticQuery(graphql`
    {
      settings: kontentItemSiteSettings(system: {codename: {eq: "site_settings"}}) {
        elements {
          doi_success_page {
            value {
              id
            }
          }
        }
      }
    }
  `);

  let successPage;

  if (settings && settings.elements.doi_success_page.value.length > 0) {
    successPage = getInternalLinkById(settings.elements.doi_success_page.value[0].id);
  }

  const [submitDoiConfirmation, submitDoiConfirmationResult] = useSubmitDoiConfirmationMutation();

  const intl = useIntl();

  const [newsletter, setNewsletter] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const doiParam = params.get("newsletter");

    if (doiParam) {
      submitDoiConfirmation({
        data: {
          confirmation: {
            transaction_id: doiParam,
          },
        },
      });
    } else {
      notificationService.error(
        intl.formatMessage({ id: "doi_confirmation.error.inadmissible_link" }),
        {
          autoClose: false,
          dismissButtonText: <FormattedMessage id="doi_confirmation.error.hide" />,
        },
      );
    }
    setNewsletter(doiParam);
  }, []);

  useEffect(() => {
    if (submitDoiConfirmationResult.isError) {
      notificationService.error(
        intl.formatMessage({ id: "doi_confirmation.error" }, { code: submitDoiConfirmationResult?.error?.status || "" }),
        {
          action: (
            <TimeoutButton
              as={LinkButton}
              onClick={() => {
                submitDoiConfirmation({
                  data: {
                    confirmation: {
                      transaction_id: newsletter,
                    },
                  },
                });
                notificationService.clear();
              }}
              intlId="doi_confirmation.error.retry"
              variant="text-dark"
              className="link-button link-button-text"
              loading={submitDoiConfirmationResult.isLoading}
              disabled={submitDoiConfirmationResult.isLoading}
            />
          ),
          autoClose: false,
          dismissButtonText: <FormattedMessage id="doi_confirmation.error.hide" />,
        },
      );
    }

    if (submitDoiConfirmationResult.isSuccess && successPage) {
      window.location.href = `${process.env.GATSBY_SITE_URL}${successPage?.slug}`;
    }
  }, [submitDoiConfirmationResult]);

  return (
    <MainLayout>
      <SEO title="Confirmation" isHiddenForRobots />

      <ContentHeader
        headline={intl.formatMessage({ id: "doi_confirmation.title" })}
        description={parse(sanitizeHtml(intl.formatMessage({ id: "doi_confirmation.description" }), { allowedTags: ["a"] }))}
        media={<HeaderIconThankYouThumbs />}
        button={submitDoiConfirmationResult.isError
          ? (
            <TimeoutButton
              onClick={() => {
                submitDoiConfirmation({
                  data: {
                    confirmation: {
                      transaction_id: newsletter,
                    },
                  },
                });
                notificationService.clear();
              }}
              variant="primary"
              disabled={!newsletter || submitDoiConfirmationResult.isLoading}
              loading={submitDoiConfirmationResult.isLoading}
              intlId="doi_confirmation.error.retry"
            />
          )
          : (
            <Button
              onClick={() => {
                submitDoiConfirmation({
                  data: {
                    confirmation: {
                      transaction_id: newsletter,
                    },
                  },
                });
              }}
              variant="primary"
              loading={submitDoiConfirmationResult.isLoading}
              disabled={!newsletter
                || submitDoiConfirmationResult.isLoading
                || submitDoiConfirmationResult.isSuccess}
            >
              {submitDoiConfirmationResult.isLoading
                ? <Spinner animation="border" variant="dark" />
                : <FormattedMessage id="doi_confirmation.confirm_login" />}
            </Button>
          )}
      />
    </MainLayout>
  );
};

export default DoiConfirmationPage;
