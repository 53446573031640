import React, { useMemo } from "react";

import { Formik } from "formik";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { setSetupData } from "../../../features/registrationSlice";
import { IconError18, IconFurther18 } from "../../../icons";
import KontentItemImage from "../../../templates/kontentItemImage";
import ScrollToFieldError from "../../../utils/formik/scrollToFieldError";
import IconButton from "../../button/iconButton";
import ImageRadioButton from "../../imageRadioButton/imageRadioButton";

const VirtualDriveSetupModalDriveTypePl = ({ content, next }) => {
  const setupData = useSelector((state) => state.registration.setupData);
  const dispatch = useDispatch();

  const intl = useIntl();

  const requiredMessage = intl.formatMessage({ id: "form.required" });

  const validationSchema = useMemo(() => object().shape({
    driveType: string().required(requiredMessage),
    driveTypeValue: string().required(requiredMessage),
  }), []);

  return (
    <Row className="virtual-drive-setup-dialog__drive-motivation">
      <Col sm={10}>
        <h3>
          <FormattedMessage id="virtual_drive_setup.drive_type_title" />
        </h3>
      </Col>
      <Formik
        initialValues={setupData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(values, formikBag) => {
          formikBag.setSubmitting(true);

          dispatch(
            setSetupData({
              driveType: values.driveType,
              driveTypeValue: values.driveTypeValue,
            }),
          );

          next();
        }}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          touched,
          errors,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className="col-12">
            <div className="typo-label mb-12 required-sign">
              <FormattedMessage id="form.please_select.label" />
            </div>
            <Form.Group>
              <Row>
                {setupData.motivation === "patient"
                  && (
                    <Col xs={6} md={4}>
                      <ImageRadioButton
                        label={intl.formatMessage({ id: "virtual_drive_setup.drive_type_public_drive" })}
                        id="public"
                        value="public drive"
                        name="drive_type"
                        checked={values.driveType === intl.formatMessage({ id: "virtual_drive_setup.drive_type_public_drive" })}
                        isInvalid={errors.driveType && touched.driveType}
                        image={(
                          <KontentItemImage
                            data={content.imageRadioButtonPublicDrive}
                            aspectRatio={3 / 4}
                          />
                        )}
                        onClick={() => {
                          setFieldValue("driveType", intl.formatMessage({ id: "virtual_drive_setup.drive_type_public_drive" }));
                          setFieldValue("driveTypeValue", "Public drive");
                          setFieldTouched("driveType");
                        }}
                      />
                    </Col>
                  )}
                <Col xs={6} md={4}>
                  <ImageRadioButton
                    label={intl.formatMessage({ id: "virtual_drive_setup.drive_type_company_drive" })}
                    id="company"
                    value="company drive"
                    name="drive_type"
                    checked={values.driveType === intl.formatMessage({ id: "virtual_drive_setup.drive_type_company_drive" })}
                    isInvalid={errors.driveType && touched.driveType}
                    image={(
                      <KontentItemImage
                        data={content.imageRadioButtonCompanyDrive}
                        aspectRatio={3 / 4}
                      />
                    )}
                    onClick={() => {
                      setFieldValue("driveType", intl.formatMessage({ id: "virtual_drive_setup.drive_type_company_drive" }));
                      setFieldValue("driveTypeValue", "Company drive");
                      setFieldTouched("driveType");
                    }}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <ImageRadioButton
                    label={intl.formatMessage({ id: "virtual_drive_setup.drive_type_university_drive" })}
                    id="university"
                    value="university drive"
                    name="drive_type"
                    checked={values.driveType === intl.formatMessage({ id: "virtual_drive_setup.drive_type_university_drive" })}
                    isInvalid={errors.driveType && touched.driveType}
                    image={(
                      <KontentItemImage
                        data={content.imageRadioButtonUniversityDrive}
                        aspectRatio={3 / 4}
                      />
                    )}
                    onClick={() => {
                      setFieldValue("driveType", intl.formatMessage({ id: "virtual_drive_setup.drive_type_university_drive" }));
                      setFieldValue("driveTypeValue", "University drive");
                      setFieldTouched("driveType");
                    }}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <ImageRadioButton
                    label={intl.formatMessage({ id: "virtual_drive_setup.drive_type_school_drive" })}
                    id="school"
                    value="school drive"
                    name="drive_type"
                    checked={values.driveType === intl.formatMessage({ id: "virtual_drive_setup.drive_type_school_drive" })}
                    isInvalid={errors.driveType && touched.driveType}
                    image={(
                      <KontentItemImage
                        data={content.imageRadioButtonSchoolDrive}
                        aspectRatio={3 / 4}
                      />
                    )}
                    onClick={() => {
                      setFieldValue("driveType", intl.formatMessage({ id: "virtual_drive_setup.drive_type_school_drive" }));
                      setFieldValue("driveTypeValue", "School drive");
                      setFieldTouched("driveType");
                    }}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <ImageRadioButton
                    label={intl.formatMessage({ id: "virtual_drive_setup.drive_type_own_idea_drive" })}
                    id="own-idea"
                    value="own idea"
                    name="drive_type"
                    isInvalid={errors.driveType && touched.driveType}
                    checked={values.driveType === intl.formatMessage({ id: "virtual_drive_setup.drive_type_own_idea_drive" })}
                    image={(
                      <KontentItemImage
                        data={content.imageRadioButtonOwnIdeaDrive}
                        aspectRatio={3 / 4}
                      />
                    )}
                    onClick={() => {
                      setFieldValue("driveType", intl.formatMessage({ id: "virtual_drive_setup.drive_type_own_idea_drive" }));
                      setFieldValue("driveTypeValue", "Own idea");
                      setFieldTouched("driveType");
                    }}
                  />
                </Col>
                <Col xs={12}>
                  {errors.driveType && touched.driveType && (
                    <Form.Text className="invalid-feedback">
                      <IconError18 />{errors.driveType}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
            <Row>
              <Col xs={12}>
                <ScrollToFieldError />

                <Form.Group className="form-group-button">
                  <IconButton type="submit">
                    <FormattedMessage id="virtual_drive_setup.next" /> <IconFurther18 />
                  </IconButton>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Row>
  );
};

VirtualDriveSetupModalDriveTypePl.stepName = "virtual_drive_type";

VirtualDriveSetupModalDriveTypePl.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  next: PropTypes.func.isRequired,
};

export default VirtualDriveSetupModalDriveTypePl;
